export const BASE_PATH = 'v2/channels/';
export const VIEW_PRODUCT_SCOPE = 'bip.channels.view.product';
export const VIEW_CHANNEL_DETAILS_SCOPE = 'bip.channels.view';
export const CHANNEL_ENABLE_PRODUCT_TYPE = 'bip.channels.enable.product';
export const CHANNEL_DISABLE_PRODUCT_TYPE = 'bip.channels.disable.product';
export const CHANNEL_ENABLE_PARTNER = 'bip.channels.enable';
export const CHANNEL_DISABLE_PARTNER = 'bip.channels.disable';
export const CHANNES_VIEW_PARTNER_LIST = 'bip.channels.view.partner-list';

export const CHANNEL_TYPES = [
	{ id: 1, name: 'Center' },
	{ id: 2, name: 'Partner' },
	{ id: 3, name: 'Agent' },
	{ id: 4, name: 'Machine (Inside Office)' },
	{ id: 5, name: 'Machine (Outside Office)' },
	{ id: 6, name: 'Collect' },
	{ id: 7, name: 'Digital (Online/Mobile App)' },
	{ id: 8, name: 'Financial Institution' },
];

export const FEE_COLUMNS = [
	{
		Header: 'Subsidize Fee',
		accessor: 'subsidizedFee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'passOnFee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'totalFixedRate',
	},
	{
		Header: 'Non-shareable Fee',
		accessor: 'nonShareFixedRate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'totalAmountForSfShare',
		width: '200px',
	},
];

export const REGULAR_PERCENTAGE_COLUMNS = [
	{
		Header: 'Partner Share',
		accessor: 'partnerShare',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayadShare',
	},
];

export const RATE_COLUMNS = [
	{
		Header: 'Subsidized Rate',
		accessor: 'subsidizedRate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'passOnRate',
	},
	{
		Header: 'Total Percentage Rate',
		accessor: 'totalPercentageRate',
	},
	{
		Header: 'Non-shareable Percentage',
		accessor: 'nonSharePercentage',
		width: '200px',
	},
	{
		Header: 'Total Percentage for SF Share',
		accessor: 'totalAmountForSfShareRate',
		width: '200px',
	},
];

export const TIER_COLUMNS = [
	{
		Header: 'Tier',
		accessor: 'tierNumber',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lowerLimit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upperLimit',
	},
	{
		Header: 'Subsidize Fee',
		accessor: 'subsidizedFee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'passOnFee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'totalFixedRate',
	},
	{
		Header: 'Non-shareable Fixed Rate',
		accessor: 'nonShareFixedRate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'totalAmountForSfShare',
		width: '200px',
	},
];

export const TIER_RATE_COLUMNS = [
	{
		Header: 'Tier',
		accessor: 'tierNumber',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lowerLimit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upperLimit',
	},
	{
		Header: 'Subsidize Rate',
		accessor: 'subsidizedRate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'passOnRate',
	},
	{
		Header: 'Total Percentage Rate',
		accessor: 'totalPercentageRate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Percentage',
		accessor: 'nonSharePercentage',
		width: '200px',
	},
	{
		Header: 'Total Percentage for SF Share',
		accessor: 'totalAmountForSfShareRate',
		width: '250px',
	},
];

export const CHANNEL_TYPE_COLUMNS = [
	{
		Header: 'Channel Type',
		accessor: 'channelTypeId',
	},
	{
		Header: 'Subsidized Fee',
		accessor: 'subsidizedFee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'passOnFee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'totalFixedRate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Fixed Rate',
		accessor: 'nonShareFixedRate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'totalAmountForSfShare',
		width: '200px',
	},
];

export const CHANNEL_TYPE_RATE_COLUMNS = [
	{
		Header: 'Channel Type',
		accessor: 'channelTypeId',
	},
	{
		Header: 'Subsidized Rate',
		accessor: 'subsidizedRate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'passOnRate',
	},
	{
		Header: 'Total Percentage Rate',
		accessor: 'totalPercentageRate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Percentage',
		accessor: 'nonSharePercentage',
		width: '200px',
	},
	{
		Header: 'Total Percentage for SF Share',
		accessor: 'totalAmountForSfShareRate',
		width: '200px',
	},
];

export const TIER_COLUMNS_FIELD = [
	{
		Header: 'Tier',
		accessor: 'tierNumber',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lowerLimit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upperLimit',
	},
	{
		Header: 'Partner Share',
		accessor: 'partnerShare',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayadShare',
	},
];

export const CHANNEL_TYPE_COLUMS_FIELD = [
	{
		Header: 'Channel Type',
		accessor: 'channelTypeId',
	},
	{
		Header: 'Partner Share',
		accessor: 'partnerShare',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayadShare',
	},
];
