import { Route } from 'react-router-dom';
import BillerProfile from './BillerProfile/BillerProfile';
import BillerStatus from './BillerStatus/BillerStatus';
import Dashboard from './Dashboard/Dashboard';

const BillerManagement: React.FC = () => {
	return (
		<>
			<Route exact path="/biller-management/" component={Dashboard} />
			<Route path="/biller-management/profile" component={BillerProfile} />
			<Route path="/biller-management/status" component={BillerStatus} />
		</>
	);
};
export default BillerManagement;
