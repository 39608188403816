import * as yup from 'yup';
import {
	defaultInvalidCharacter,
	hasSpecialCharacter,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';

import {
	LOCAL,
	INTERNATIONAL,
	COUNTRY,
	AREA,
	AREA_VALUES,
	AREA_ERROR_MSG,
	AREA_LOCAL_MSG,
	REGION,
	REGION_VALUES,
	REGION_ERROR_MSG,
	REGION_LOCAL_MSG,
	STATE_PROVINCE,
	MUNICIPALITY_CITY,
	BARANGAY,
	BUILDING_NAME_NO,
	STREET,
	ZIP_CODE,
} from './const';

import {
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { YupLab } from 'utils/yupLab';
import { YupMessage } from 'types';

const defaultInvalidCharacterMsg = ({ label }: YupMessage): string =>
	`This field only allows alphanumeric characters.`;

const hasSpecialCharacterChannelDetails = (v: string): boolean => {
	const noSpecialChar = new RegExp(
		/[!@#$%^&*()_+{}:"|<>?=:;'/\-[\]\\,.`~]/
	).test(v || '');
	return !noSpecialChar;
};

const ChannelAddressEntrySchema = new YupLab(
	yup.object({
		locationBase: yup.string().oneOf([LOCAL, INTERNATIONAL]).default('LOCAL'),
		countryId: yup
			.number()
			.label(COUNTRY)
			.when('locationBase', {
				is: INTERNATIONAL,
				then: (s) => s.required('Select Country.'),
			}),
		area: yup
			.mixed()
			.label(AREA)
			.when('locationBase', {
				is: LOCAL,
				then: (s) =>
					s.required('Select Area.').oneOf(AREA_VALUES, 'Select Area.'),
			}),
		regionId: yup
			.mixed()
			.label(REGION)
			.nullable(true)
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required('Select Region.'),
			}),
		provinceId: yup
			.mixed()
			.label(STATE_PROVINCE)
			.nullable()
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required('Select State/Province.'),
			}),
		cityId: yup
			.mixed()
			.label(MUNICIPALITY_CITY)
			.nullable()
			.default(null)
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required('Select Municipality/City.'),
			}),
		barangayId: yup
			.mixed()
			.label(BARANGAY)
			.nullable()
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required('Select Barangay.'),
			}),
		buildingName: yup
			.string()
			.label(BUILDING_NAME_NO)
			.nullable(true)
			.when('locationBase', {
				is: LOCAL,
				then: (s) =>
					s
						.required('Input Building Name/No.')
						.matches(
							/^[a-zA-Z0-9 ]+$/,
							'This field only allows alphanumeric characters.'
						)
						.max(MAX_CHAR_255, 'You have reached maximum character input.')
						.test({
							test: (value) => String(value).trim() !== '',
							message: 'Input Building Name/No.',
						}),
			}),
		street: yup
			.string()
			.nullable(true)
			.label(STREET)
			.when('locationBase', {
				is: LOCAL,
				then: (s) =>
					s.max(MAX_CHAR_255, 'You have reached maximum character input'),
			})
			.test('', defaultInvalidCharacterMsg, (v: any) =>
				hasSpecialCharacterChannelDetails(v)
			),
		zipCodeId: yup.string().nullable(true).label(ZIP_CODE),
		id: yup.number().nullable(true),
	})
);

export const defaultAddressValue =
	ChannelAddressEntrySchema.objectSchema.getDefaultFromShape();

export const ChannelAddressSchema = yup
	.array()
	.of(ChannelAddressEntrySchema.objectSchema)
	.default([
		{
			locationBase: 'LOCAL',
			countryId: 175,
			...defaultAddressValue,
		},
	]);

export type ChannelAddressType = ReturnType<
	(typeof ChannelAddressSchema.schema)['cast']
>;
