import { createSlice } from '@reduxjs/toolkit';
import { channelStatuses, channelTypes } from '../../constants/channelType';
import { SLDSDropdownOptionType } from 'types';

interface FormValues {
	primaryInfo: Record<string, unknown>;
	contractDetails: Record<string, unknown>;
	reports: Record<string, unknown>;
  }
  
  interface Filter {
	search: string;
	category: string;
	status: string;
  }
export interface LoaderState {
	isLoading: boolean;
	message: string;
  }

export enum ChannelTabs {
	DetailsTab,
	ProductsTab,
	AuditTrail,
	ChanneIntegration,
}

export enum FormType {
	Channel,
	Branch,
	Terminal,
}

export type GetChannelsParams = {
	search: string | null;
	category: string | null;
	status: string | null;
};
  interface ChannelState {
	channelTypes: SLDSDropdownOptionType[];
	channelStatuses: SLDSDropdownOptionType[];
	search: string;
	channels: any[];
	meta: Record<string, unknown>;
	selectedChannel: any;
	selectedBranch: any;
	selectedTerminal: any;
	activeType: string;
	selectedApproval: any;
	channelFilter: Filter;
	branchFilter: Record<string, unknown>;
	terminalFilter: Record<string, unknown>;
	channelFormValues: FormValues;
	branchFormValues: Record<string, unknown>;
	terminalFormValues: Record<string, unknown>;
	formErrors: Record<string, boolean>;
	loader:  LoaderState;
	activeTab: ChannelTabs,
  }

const initBranchState = {
	name: '',
	posType: '',
	storeType: '',
	storeFormat: '',
	machineLocation: '',
};

const initTerminalState = {
	tpaId: '',
};

const initialState: ChannelState = {
	channelTypes: channelTypes,
	channelStatuses: channelStatuses,
	search: '',
	channels: [],
	meta: {},
	selectedChannel: null,
	selectedBranch: null,
	selectedTerminal: null,
	activeTab: ChannelTabs.DetailsTab,
	activeType: '',
	selectedApproval: null,
	channelFilter: {
		search: '',
		category: '',
		status: '',
	},
	branchFilter: initBranchState,
	terminalFilter: initTerminalState,
	channelFormValues: {
		primaryInfo: {},
		contractDetails: {},
		reports: {},
	},
	branchFormValues: {},
	terminalFormValues: {},
	formErrors: {} as Record<string, boolean>,
	loader: {isLoading: false, message: 'Please wait...'},
};

const channelSlice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		prepare: (state: any, { payload }) => {
			if (payload.channelType != null) {
				state.channelType = payload.channelType;
			}

			if (payload.name != null) {
				state.search = payload.name;
			}
		},
		load: (state, payload: any) => {
			state.channels = payload.result.result;
			state.meta = payload.result.meta;
		},
		error: (state, payload) => {},
		selectChannel: (state: any, { payload }) => {
			state.channels = state.channels.map((channel: any) => {
				if (channel.id === payload.selected) {
					state.selectedChannel = channel;
					return {
						...channel,
						isSelected: true,
					};
				}
				return {
					...channel,
					isSelected: false,
				};
			});
		},
		clearSelectedChannel: (state: any) => {
			state.selectedChannel = null;
		},
		searchChannel: (state, payload) => {
			// #TODO
		},
		selectedChannel: (state: any, { payload }) => {
			state.selectedChannel = payload.channel;
		},
		selectedBranch: (state: any, { payload }) => {
			state.selectedBranch = payload.branch;
		},
		selectedTerminal: (state: any, { payload }) => {
			state.selectedTerminal = payload.terminal;
		},
		activeType: (state: any, { payload }) => {
			state.activeType = payload.type;
		},
		channelListFilter: (state: any, { payload }) => {
			state.channelFilter = payload.channelFilter;
		},
		branchListFilter: (state: any, { payload }) => {
			state.branchFilter = payload.branchFilter;
		},
		terminalListFilter: (state: any, { payload }) => {
			state.terminalFilter = payload.terminalFilter;
		},
		setChannelFormValues: (state: any, { payload }) => {
			state.channelFormValues = payload;
		},
		setBranchFormValues: (state: any, { payload }) => {
			state.branchFormValues = payload;
		},
		setTerminalFormValues: (state: any, { payload }) => {
			state.terminalFormValues = payload;
		},
		resetChannelFormValues: (state) => {
			state.channelFormValues = {
				primaryInfo: {},
				contractDetails: {},
				reports: {},
			};
		},
		setFormErrorStatus: (state, action) => {
			state.formErrors[action.payload.formId] = action.payload.hasError;
		},
		setLoader: (state, action) => {
			state.loader = action.payload;
		},
		setActiveTab: (state, action) => {
			state.activeTab = action.payload.tab;
		},
		resetSelections: (state) => {
			state.selectedChannel = null;
			state.selectedBranch = null;
			state.selectedTerminal = null;
		},
		resetAll: (state) => {
			Object.assign(state, initialState);
			state.channelFilter = {
				search: '',
				category: '',
				status: '',
			};
			state.branchFilter = initBranchState;
			state.terminalFilter = initTerminalState;
		},
	},
});

const getChannels = (payload: GetChannelsParams): any => {
	return {
		types: [prepare.type, load.type, error.type],
		promise: (client: any, state: any) => {
			const { search, category, status } = payload;
			const params = {
				search: search ? search : '',
				category: category ? category : '',
				status: status ? status : '',
			};
			return client.get('/v2/channels/', {
				params,
			});
		},
		payload,
	};
};

export const {
	prepare,
	load,
	error,
	selectChannel,
	clearSelectedChannel,
	searchChannel,
	selectedChannel,
	activeType,
	selectedBranch,
	selectedTerminal,
	channelListFilter,
	branchListFilter,
	terminalListFilter,
	setChannelFormValues,
	setBranchFormValues,
	setTerminalFormValues,
	resetChannelFormValues,
	setFormErrorStatus,
	setLoader,
	setActiveTab,
	resetAll,
} = channelSlice.actions;

export { getChannels };

export default channelSlice.reducer;
