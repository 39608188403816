import HTTP from 'helpers/ApiClient';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { resolveValue } from 'utils/common';

type Bank = {
	id: string;
	name: string;
};

async function getBanks(
	page?: number, 
	limit?: number): 
Promise<Bank[]> {
	const params = { 
		page: resolveValue(page), 
		limit: resolveValue(limit)
	};
	const response = await HTTP.get('/v2/banks', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch banks');
	return list;
}

export function useBanksQuery(
	queryKey: 'banks' | string = 'banks',
	params: { page?: number; limit?: number },
	config?: UseQueryOptions<Bank[]>
): UseQueryResult<Bank[]> {
	return useQuery<Bank[]>(
		[queryKey, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => getBanks(params?.page, params?.limit),
		config
	);
}
