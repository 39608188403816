import { yupResolver } from '@hookform/resolvers/yup';
import { Tabs } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import client from 'helpers/ApiClient';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { setCurrentBillerTab } from 'redux/modules/products';
import { FixMeLater, TPartnerModalConfirmProps } from 'types';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';

import { useList } from 'containers/ChannelManagement/List/hooks';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail.module.css';
import ChannelAuditTrailTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrailTable';
import ChannelAuditTrailStatusSelect from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrailStatusSelect';
import StatusUpdate from 'containers/ChannelManagement/Channel/ChannelStatusSelect/StatusUpdate';
//import ChannelBranchAuditModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/ChannelBranchAuditModal';
import ChannelAuditLogsModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/ChannelAuditLogsModal';
import {
	useGetChannelAudit,
	useGetChannelLogs,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditQuery';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';
import axios from 'axios';
import _ from 'lodash';
import { LoaderState, setLoader } from 'redux/modules/channelList';
import { usePreviousDistinct } from 'react-use';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import ServiceFeeShareModal from './ServiceFeeShareModal';

type Props = {
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

const ChannelAuditTrail: React.FC<Props> = ({ ...props }) => {
	const dispatch = useDispatch();
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [auditDetailId, setAuditDetailId] = useState();
	const { channelFormValues, terminalFormValues } = useTerminal();
	const terminalDetails = useTerminal()?.terminalDetails;
	const [terminalStatus, setTerminalStatus] = useState(
		terminalFormValues?.data?.status
	);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	// export to types.ts
	interface ListSelection {
		id: number;
		name: string;
		logo: string;
		status: string;
		tpaAccount: string;
	}

	const selectedChannel = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedChannel
	);

	const selectedBranch = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedBranch
	);

	const selectedTerminal = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedTerminal
	);

	const activeType: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeType
	);

	const activeTab: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeTab
	);

	const branchDetails = useSelector(
		(state: ReducerStateType) => state.channels.branchFormValues ?? {}
	);

	const [tableFilter, setTableFilter] = useState({
		limit: 25,
		page: 1,
	});

	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};
	const selectedLogs = () => {
		let id: number | undefined = undefined;
		if (activeType === 'terminal') {
			id = auditDetailId;
		} else if (activeType === 'branch') {
			id = auditDetailId;
		} else if (activeType === 'channel') {
			id = auditDetailId;
		}
		return id;
	};
	const selectedId = () => {
		let id: number | undefined = undefined;
		if (activeType === 'terminal') {
			id = selectedTerminal?.id;
		} else if (activeType === 'branch') {
			id = selectedBranch?.id;
		} else if (activeType === 'channel') {
			id = selectedChannel?.id;
		}
		return id;
	};
	const selectorName = () => {
		let nameSelect: string | undefined = undefined;
		if (activeType === 'terminal') {
			nameSelect = selectedTerminal?.name;
		} else if (activeType === 'branch') {
			nameSelect = selectedBranch?.name;
		} else if (activeType === 'channel') {
			nameSelect = selectedChannel?.name;
		}
		return nameSelect;
	};
	const terminalStatusSelector = () => {
		return terminalStatus;
	};

	const {
		data: auditListData,
		isLoading: isQueryLoading,
		isSuccess: isQuerySuccess,
		error,
		refetch: refetchAuditList,
	} = useGetChannelAudit(selectedId(), activeType, tableFilter, activeTab);
	const {
		data: auditDetailData,
		isLoading: isQueryLoadingDetail,
		isSuccess: isQueryLoadingDetailSuccess,
		refetch: refecthAuditData,
	} = useGetChannelLogs(selectedId(), selectedLogs(), activeType);
	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.channels.loader
	);
	const prevIsQueryLoading = usePreviousDistinct(isQueryLoading);
	const prevIsQueryLoadingDetail = usePreviousDistinct(isQueryLoadingDetail);

	useEffect(() => {
		const isLoading = isQueryLoading || isQueryLoadingDetail;
		let message = 'Loading Audit Trail...';

		if (prevIsQueryLoading !== isQueryLoading && isQueryLoading) {
			message = 'Please wait while audit logs are being loaded.';
		} else if (
			prevIsQueryLoadingDetail !== isQueryLoadingDetail &&
			isQueryLoadingDetail
		) {
			message =
				"Please wait while the selected audit log's entries are being loaded.";
		}

		dispatch(setLoader({ isLoading, message }));
	}, [
		dispatch,
		isQueryLoading,
		isQueryLoadingDetail,
		prevIsQueryLoading,
		prevIsQueryLoadingDetail,
	]);

	useEffect(() => {
		setAuditDetailId(undefined);
	}, [
		selectedChannel,
		selectedBranch,
		selectedTerminal,
		isQueryLoading,
		auditListData,
	]);

	useEffect(() => {
		setTerminalStatus(terminalDetails?.status);
	}, [selectedTerminal]);

	useEffect(() => {
		setPage(1);
		setTableFilter({
			...tableFilter,
			limit: 25,
			page: 1,
		});
	}, [selectedChannel, selectedBranch, selectedTerminal]);

	useEffect(() => {
		setTerminalStatus(terminalFormValues?.data?.status);
	}, [useTerminal()?.terminalFormValues]);

	useEffect(() => {
		const isLoading = false;
		const message = 'Loading Audit Trail...';

		dispatch(setLoader({ isLoading, message }));
	}, [isQuerySuccess]);

	useEffect(() => {
		if (activeTab === 'AuditTrail') {
			refetchAuditList();
		}
	}, [selectedId(), activeType, tableFilter, activeTab]);

	useEffect(() => {
		refecthAuditData();
	}, [auditDetailId]);

	useEffect(() => {
		if (
			auditDetailId != undefined &&
			auditDetailId &&
			auditDetailData?.exception &&
			!isQueryLoadingDetail
		) {
			showErrorModal();
		}
	}, [auditDetailData, isQueryLoadingDetail]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isChannelAuditModalVisible,
		valueOn: showChannelAuditModal,
		valueOff: hideChannelAuditModal,
	} = useToggle();

	const {
		value: isServiceFeeModalVisible,
		valueOn: showServiceFeeModal,
		valueOff: hideServiceFeeModal,
	} = useToggle();

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const handleOnClickViewLogs = async (row) => {
		setAuditDetailId(row.original.id);
		if (row.values.action.match(/service fee share/i)) {
			showServiceFeeModal();
		} else {
			showChannelAuditModal();
		}
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			setTableFilter({
				...tableFilter,
				limit: _pageSize,
				page: _page,
			});
		}
	};

	const AUDIT_TRAIL_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time',
			id: 'createdAt',
			sortable: false,
			width: '12%',
			accessor: ({ createdAt }) => formatDate(createdAt),
			isTextAlignCenter: false,
		},
		{
			Header: 'Logged By',
			id: 'loggedBy',
			sortable: false,
			width: '12%',
			isTextAlignCenter: false,
		},
		{
			Header: 'Action',
			id: 'action',
			sortable: false,
			width: '14%',
			isTextAlignCenter: false,
		},
		{
			Header: 'Source IP',
			id: 'ipAddress',
			sortable: false,
			width: '12%',
			isTextAlignCenter: false,
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			width: '40%',
			isTextAlignCenter: true,
		},
		{
			Header: '',
			id: 'logs',
			sortable: false,
			width: '10%',
			Cell: ({ row }) => (
				<div className={styles.actionContainer}>
					<div
						className={cx(styles.action)}
						onClick={() => handleOnClickViewLogs(row)}
						onKeyDown={()=>{return}}
					>
						View Logs
					</div>
				</div>
			),
		},
	];

	return (activeType === 'channel' && selectedChannel.name) ||
		(activeType === 'branch' && selectedBranch) ||
		(activeType === 'terminal' && selectedTerminal) ? (
		<>
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Bad Request!</div>
						<div className={styles.errorBody}>
							There seems to be an issue with the data you requested.
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{!loader.isLoading &&
			((activeType === 'terminal' && selectedTerminal) ||
				(activeType === 'branch' && selectedBranch) ||
				(activeType === 'channel' && selectedChannel)) ? (
				<>
					{activeType && activeType === 'channel' && (
						<>
							<div className={styles.title}>{selectorName()}</div>
							<Grid container gutters="xx-small">
								<Grid column>
									<div className={styles.leftPanel}>
										<div className={styles.status}>
											<div className={styles.statusLabel}>Channel Status:</div>
											<div className={styles.statusField}>
												<StatusUpdate
													value={
														channelFormValues?.contractDetails?.status || ''
													}
													id={selectedChannel.id}
													idType="channels"
													disabled
												/>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</>
					)}
					{activeType && activeType === 'branch' && branchDetails.data && (
						<>
							<div className={styles.title}>{branchDetails.data?.name}</div>
							<Grid container gutters="xx-small">
								<Grid column>
									<div className={styles.leftPanel}>
										<div className={styles.status}>
											<div className={styles.statusLabel}>Branch Status:</div>
											<div className={styles.statusField}>
												<StatusUpdate
													value={branchDetails.data?.status}
													//id={terminalId}
													id={selectedBranch.id}
													idType="channels"
													disabled
												/>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</>
					)}
					{activeType && activeType === 'terminal' && (
						<>
							<div className={styles.title}>{terminalDetails?.tpaId}</div>
							<Grid container gutters="xx-small">
								<Grid column>
									<div className={styles.leftPanel}>
										<div className={styles.status}>
											<div className={styles.statusLabel}>Terminal Status:</div>
											<div className={styles.statusField}>
												<StatusUpdate
													value={terminalStatusSelector() || ''}
													//id={terminalId}
													id={selectedTerminal.id}
													idType="channels"
													disabled
												/>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</>
					)}
					<div className={styles.channelAuditTrailTableContainer}>
						{!loader.isLoading && auditListData ? (
							<ChannelAuditTrailTable
								columns={AUDIT_TRAIL_COLUMNS(handleOnClickViewLogs)}
								data={auditListData.data}
								pageSize={pageSize}
								page={page}
								count={auditListData.meta.total}
								onPageChange={handlePageChange}
							/>
						) : (
							<PanelPlaceholder {...panelPlaceholderProps} />
						)}
					</div>
				</>
			) : (
				<PanelPlaceholder {...panelPlaceholderProps} />
			)}
			{!loader.isLoading &&
			auditDetailData &&
			auditDetailData.exception !== 'TypeError' ? (
				<ChannelAuditLogsModal
					listId={selectedId()}
					detailId={auditDetailId}
					activeType={activeType}
					isOpen={isChannelAuditModalVisible}
					data={auditDetailData.data ? [auditDetailData.data] : []}
					onClose={() => {
						setAuditDetailId(undefined);
						hideChannelAuditModal();
					}}
				/>
			) : (
				<div></div>
			)}
			{isServiceFeeModalVisible &&
				auditDetailData &&
				!auditDetailData.data.exception && (
					<ServiceFeeShareModal
						isOpen={isServiceFeeModalVisible}
						onClose={hideServiceFeeModal}
						data={auditDetailData.data}
					/>
				)}
		</>
	) : (
		<PanelPlaceholder {...panelPlaceholderProps} />
	);
};

export default ChannelAuditTrail;
