import yup, { transformCash } from 'utils/formSchemas/common';

export const collectionServiceAgreementSchema = yup.object({}).shape({
	bondStartDate: yup.mixed().label('Bond Start Date').nullable(),
	bondEndDate: yup.mixed().label('Bond End Date').nullable(),
	settlementAgreement: yup
		.string()
		.label('Settlement Arrangment')
		.required()
		.nullable(true),
	thresholdAmount: yup
		.number()
		.nullable(true)
		.label('Threshold Amount')
		.min(0.01, 'This field only accepts positive numeric values.')
		.transform(transformCash),
	bondType: yup.string().label('Bond Type').nullable(),
	bondAmount: yup
		.number()
		.nullable(true)
		.label('Bond Amount')
		.min(0.01, 'This field only accepts positive numeric values.')
		.transform(transformCash),
	depositConsolidation: yup
		.string()
		.label('Deposit Consolidation')
		.required('Select Deposit Consolidation'),
	depositMode: yup.string().label('Deposit Mode').nullable(),
	accreditationFee: yup
		.number()
		.required('Input Accreditation Fee')
		.label('Accreditation Fee')
		.min(0.01, 'This field only accepts positive numeric values.')
		.transform(transformCash),
	annualFee: yup
		.number()
		.required('Input Annual Fee')
		.default(null)
		.label('Annual Fee')
		.min(0.01, 'This field only accepts positive numeric values.')
		.transform(transformCash),
});
