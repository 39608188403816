import React, { useState } from 'react';
import cx from 'classnames';

import { Button, Tooltip } from '@salesforce/design-system-react';

import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';
import { Switch, SwitchWithTooltip } from 'components/Switch';

import scheduleActive from 'assets/icons/ic-schedule-active.svg';
import scheduleInactive from 'assets/icons/ic-schedule-inactive.svg';
import scheduleOff from 'assets/icons/ic-schedule-off.svg';

import client from 'helpers/ApiClient';
import {
	useErrorModal,
	useLoader,
	useSuccessModal,
	useToggle,
} from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import { LOADING_UPDATE_MSG } from 'constants/loading';

import ScheduleModal from '../ScheduleModal';
import { ActionCellProps, UpdateResp } from '../types';
import styles from './ChannelDetailsBody.module.css';

const ActionCell: React.FC<ActionCellProps> = ({
	name,
	initialToggleValue,
	scheduleType,
	confirmModalContents,
	enabledTerminals,
	totalTerminals,
	fromBranchAccessCtrl = false,
	branchTpaidDetails,
	channelId,
	branchId,
	branchName,
	tpaId,
	tpaName,
	refetchTable,
	api3Status,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [toggleValue, setToggleValue] = useState<boolean>(initialToggleValue);
	const [remarks, setRemarks] = useState<string>('');
	const [isSchedTooltipShown, setIsSchedTooltipShown] = useState(true);
	const [isApiRemarksShown, setIsApiRemarksShown] = useState<boolean>(false);
	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
	} = useSuccessModal();

	const {
		value: isScheduleModalShown,
		valueOn: openScheduleModal,
		valueOff: hideScheduleModal,
	} = useToggle();

	const bfaStatusForBe = toggleValue ? 'ACTIVE' : 'INACTIVE';
	const bfaStatusForFe = toggleValue ? 'Enabled' : 'Disabled';
	const actionText = initialToggleValue ? 'Disable' : 'Enable';

	const confirmModalHeader = `${actionText} ${confirmModalContents?.header}`;
	const confirmBodyHeader = (
		<div>
			Are you sure you want to {actionText.toLowerCase()}{' '}
			<span className={styles.bodyHeaderEmphasis}>
				{confirmModalContents?.bodyHeader}
			</span>
			?
		</div>
	);
	const bodySubHeader = !tpaId
		? '*This change will be applied to the TPA IDs under this Branch.'
		: '';

	const errorModalBody = (
		<div>
			A problem occurred while {actionText.slice(0, -1).toLowerCase() + 'ing '}{' '}
			the {tpaId ? 'terminal' : 'branch'}.
		</div>
	);

	const updateBranchApi = `v2/bfa-admin-portal/channels/${channelId}/branches/${branchId}/${actionText.toLowerCase()}`;
	const updateTpaApi = `v2/bfa-admin-portal/channels/${channelId}/branches/${branchId}/terminals/${tpaId}/${actionText.toLowerCase()}`;

	const disableApi3Tooltip = () => {
		if (tpaId) return 'Terminal';
		return 'Branch';
	};

	let scheduleIcon;
	switch (scheduleType) {
		case 'ACTIVE':
			scheduleIcon = {
				src: scheduleActive,
				alt: 'active schedule icon',
				tooltip: 'Scheduled',
			};
			break;
		case 'ACTIVE_NO_SCHED':
			scheduleIcon = {
				src: scheduleInactive,
				alt: 'inactive schedule icon',
				tooltip: 'No Schedule',
			};
			break;
		case 'CLOSED':
			scheduleIcon = {
				src: scheduleOff,
				alt: 'closed schedule icon',
				tooltip: 'Scheduled',
			};
			break;
		default:
			break;
	}

	const handleToggle = (newValue: boolean) => {
		if (
			(newValue && hasUserPermission('branch.enable')) ||
			(!newValue && hasUserPermission('branch.disable'))
		) {
			setToggleValue(newValue);
			if (!fromBranchAccessCtrl) showConfirmModal();
		}
	};

	const handleUpdate = async () => {
		hideErrorModal();
		showLoadingMessage(LOADING_UPDATE_MSG);

		try {
			const result: UpdateResp = await client.put(
				tpaId ? updateTpaApi : updateBranchApi,
				{ bfaStatus: bfaStatusForBe, remarks }
			);

			if (result?.errors) {
				throw new Error(result?.errors?.message);
			}
			showSuccessMessage(
				<div>
					<span className={styles.successBody}>
						{branchName} {tpaName || ''}{' '}
					</span>{' '}
					is now {bfaStatusForFe.toLowerCase()}
				</div>
			);
		} catch (error) {
			showErrorMessage('Timeout Error!', errorModalBody, null, () =>
				handleUpdate()
			);
		} finally {
			hideLoading();
		}
	};

	return (
		<div>
			<div className={styles.actionBtns}>
				<div
					className={cx(fromBranchAccessCtrl && styles.disabled)}
					onMouseEnter={() =>
						!fromBranchAccessCtrl &&
						api3Status !== 'ACTIVE' &&
						setIsApiRemarksShown(true)
					}
				>
					<SwitchWithTooltip
						name={name}
						checked={toggleValue}
						className={cx(
							styles.tooltip,
							isApiRemarksShown ? 'slds-show' : 'slds-hide'
						)}
						onChange={
							fromBranchAccessCtrl || (api3Status && api3Status !== 'ACTIVE')
								? () => {}
								: handleToggle
						}
						toolTipContent={`This ${disableApi3Tooltip()} is disabled in API 3.0, status is not editable.`}
						isPointerDisabled={api3Status !== 'ACTIVE'}
					/>
				</div>
				{fromBranchAccessCtrl && scheduleIcon && (
					<div onMouseEnter={() => setIsSchedTooltipShown(true)}>
						<Tooltip
							content={scheduleIcon.tooltip}
							align="bottom left"
							dialogClassName={cx(
								styles.tooltip,
								isSchedTooltipShown ? 'slds-show' : 'slds-hide'
							)}
						>
							<Button
								variant="icon"
								onClick={() => {
									openScheduleModal();
									setIsSchedTooltipShown(false);
								}}
							>
								<img src={scheduleIcon.src} alt={scheduleIcon.alt} />
							</Button>
						</Tooltip>
					</div>
				)}
				{fromBranchAccessCtrl && totalTerminals !== undefined && (
					<div className={styles.enabledTpaid}>
						{`${enabledTerminals}/${totalTerminals} TPAID enabled`}
					</div>
				)}
			</div>
			<ConfirmModalWithRemarks
				isOpen={isConfirmModalShown}
				onClose={hideConfirmModal}
				onCancel={() => {
					setToggleValue(!toggleValue);
					hideConfirmModal();
				}}
				modalDisabled={false}
				confirmOnClick={handleUpdate}
				header={confirmModalHeader}
				bodyHeader={confirmBodyHeader}
				bodySubHeader={bodySubHeader}
				remarksOnChange={setRemarks}
				remarksSubLabel="Maximum of 1000 characters only."
				remarksMaxLength={1000}
			/>
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<SuccessModalContainer
				isOpen={isSuccessModalOpen}
				onClose={() => {
					hideSuccessModal();
					refetchTable && refetchTable();
				}}
				successMessage={successMessage}
			/>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			{fromBranchAccessCtrl && branchTpaidDetails && (
				<ScheduleModal
					isOpen={isScheduleModalShown}
					close={() => {
						hideScheduleModal();
					}}
					branchTpaidDetails={branchTpaidDetails}
				/>
			)}
		</div>
	);
};

export default ActionCell;
