import {
	emailAddressForContactDetailsSchema as emailAddress,
	mobileNo,
	telNo,
} from 'containers/ChannelManagement/Channel/ChannelForm/ChannelFormSchema';
import {
	INVALID_TEL_MSG,
	INVALID_TEL_MSG_SINGLE,
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
	TELEPHONE_LABEL,
	TOTAL_LENGTH_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { YupMessage } from 'types';
import {
	defaultOnlyAlphaChar,
	emailErrorMessage,
} from 'utils/formSchemas/common';
import * as yup from 'yup';
import { DEPARTMENT, POSITION } from './const';
import { validateEmail, validateLandlineNumber } from 'utils/validation';

const defaultInvalidCharacterMsg = ({ label }: YupMessage): string =>
	`This field only allows alphanumeric characters.`;

export const ChannelContactDetailsEntrySchema = yup.object({
	name: yup
		.string()
		.nullable(true)
		.required('Input Name of Channel Contact Person.')
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
		.matches(/^[a-zA-Z0-9\s]*$/, {
			excludeEmptyString: true,
			message: defaultOnlyAlphaChar,
		})
		.matches(/^[a-zA-Z0-9].*$/, 'Input Name of Channel Contact Person.'),
	position: yup
		.string()
		.nullable(true)
		.default(null)
		.label(POSITION)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
		// .nullable()
		// .required('Input Position')
		.matches(/^[a-zA-Z0-9\s]*$/, {
			excludeEmptyString: true,
			message: defaultOnlyAlphaChar,
		}),
	department: yup
		.string()
		.nullable(true)
		.default(null)
		.label(DEPARTMENT)
		.max(MAX_CHAR_255)
		// .required('Input Department')
		.matches(/^[a-zA-Z0-9\s]*$/, {
			excludeEmptyString: true,
			message: defaultOnlyAlphaChar,
		}),
	emailAddress: yup
		.array()
		.of(yup.string().email())
		.nullable(true)
		.default(null)
		.label('Channel Contact Email Address')
		.ensure()
		.min(1, ({ label }) => `Input ${label}`)
		.test(
			'isValidEmail',
			emailErrorMessage,
			(v) => !!v?.every((a) => validateEmail(a || ''))
		),
	telNo: yup
		.array()
		.nullable(true)
		.default(null)
		.of(yup.string().nullable())
		.optional()
		.label(TELEPHONE_LABEL)
		.test(
			'isValidLandlineNumber',
			({ value }: YupMessage): string => {
				const count = value?.filter(
					(a: string) => !validateLandlineNumber(a || '')
				).length;

				const totalLength = value
					? value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
					  )
					: 0;

				if (totalLength >= MAX_CHAR_255) {
					return TOTAL_LENGTH_MSG;
				}
				if (count >= 1) {
					return `${count} ${
						count > 1 ? INVALID_TEL_MSG : INVALID_TEL_MSG_SINGLE
					}`;
				}
				return '';
			},
			(v: string[] | undefined): boolean => {
				if (!v) return true;
				return v.every((a) => validateLandlineNumber(a || '', v.length));
			}
		),
	mobileNo: yup
		.array()
		.nullable(true)
		.default(null)
		.of(yup.string())
		.test(
			'isValidNumber',
			'Mobile number should be in +639XXXXXXXXX format.',
			(v) =>
				!!v?.every((a, index) => {
					if (index && index >= 19) {
						return false;
					}

					if (a) {
						return new RegExp(/^(\+?639)\d{9}$/).test(a);
					}
				})
		)
		.ensure()
		.min(1, ({ label }) => `Input Mobile Number.`)
		.max(15)
		.label('Mobile Number'),
});

export const defaultContactDetailsValue =
	ChannelContactDetailsEntrySchema.getDefault();

export const ChannelContactDetailsSchema = yup
	.array()
	.of(ChannelContactDetailsEntrySchema)
	.default([defaultContactDetailsValue]);

export const channelContactDetailsValues = ChannelContactDetailsSchema;
export type PrimartyInfoType = ReturnType<
	(typeof ChannelContactDetailsSchema)['cast']
>;
