import {
	Button,
	Dropdown,
	DropdownTrigger,
	InputIcon,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './DropdownCheckbox.module.css';

import _ from 'lodash';

export type SelectOption = {
	label: string;
	value: any;
	className?: any;
	disabled?: boolean;
};

export type SelectFieldProps = {
	label?: string;
	placeholder?: string;
	onChange?: (value: any) => any;
	className?: string;
	value?: any;
	labelPrefix?: string;
	defaultValue?: any;
	error?: string;
	name?: string;
	options: SelectOption[];
	required?: boolean;
	isLoading?: boolean;
	displayErrorMessage?: boolean;
	disabled?: boolean;
	onOpen?: () => void;
	onBlur?: () => void;
	optional?: boolean;
	getOptionValue?: (option: any) => any;
	getComparator?: (option: SelectOption, value: any) => boolean;
	size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
	inputRef?: any;
	initialArray?: any[];
	referenceCheckedItems?: Array<any>;
	setReferenceCheckedItems?: (c: Array<any>) => void;
	hasBadgeCount?: boolean;
	isReturnAllOnChange?: boolean;
};

const PlainSelectField: React.FC<SelectFieldProps> = ({
	label,
	placeholder,
	onChange,
	onOpen,
	className = '',
	value,
	defaultValue,
	error,
	name,
	options,
	required = false,
	isLoading = false,
	disabled = false,
	size = 'medium',
	optional = false,
	displayErrorMessage = true,
	onBlur,
	inputRef = null,
	hasBadgeCount,
	isReturnAllOnChange = false,

	//reference is for other external state if you want to control it outside
	referenceCheckedItems,
	setReferenceCheckedItems,
	//
}) => {
	const placeText = placeholder !== undefined ? placeholder : label;
	const [currVal, setCurrVal] = useState(defaultValue);
	const [initialized, setInitialized] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setCurrVal(value || defaultValue);
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized && value) {
			setCurrVal(value);
		}
	}, [initialized, value]);

	const formattedOptions = useMemo(() => {
		return (
			!isLoading &&
			options.map((o) => ({
				className: cx(styles.selectItem, {
					[styles.selectItemActive]: value?.value === o.value,
				}),
				...o,
			}))
		);
	}, [isLoading, options, value]);

	const menuStyle = useMemo(
		() => ({ minWidth: dropdownRef.current?.clientWidth }),
		[dropdownRef.current?.clientWidth]
	);

	const [checkedItems, setCheckedItems] = useState<any>(referenceCheckedItems);

	const stateCheckedItems = setReferenceCheckedItems
		? referenceCheckedItems
		: checkedItems;
	const stateSetCheckItems = setReferenceCheckedItems
		? setReferenceCheckedItems
		: setCheckedItems;

	return (
		<div className={cx(styles.container, className)} ref={dropdownRef}>
			{label && (
				<Label required={required} optional={optional}>
					{label}
				</Label>
			)}
			<div tabIndex={0} ref={inputRef}>
				<Dropdown
					name={name}
					id={name}
					align="left"
					width={size}
					tabIndex={-1}
					iconCategory="utility"
					iconName={isLoading ? 'sync' : 'down'}
					iconPosition="right"
					className={styles.dropdownContainer}
					containerClassName={styles.dropdownContainer}
					menuStyle={menuStyle}
					menuPosition="overflowBoundaryElement"
					disabled={disabled || isLoading}
					onBlur={() => onBlur && onBlur()}
					defaultValue={defaultValue}
					onOpen={() => {
						onOpen && onOpen();
					}}
					triggerClassName={cx(styles.select, {
						[styles.error]: !!error,
						[styles.empty]: !value,
						[styles.loading]: isLoading,
					})}
					label={placeText}
					onSelect={(option: SelectOption) => {
						let filteredValues: any = option;
						if (stateCheckedItems && stateSetCheckItems) {
							const findExistingOption = stateCheckedItems.filter(
								(c) => c === option.value
							);
							filteredValues = findExistingOption.length
								? stateCheckedItems.filter((c) => c !== option.value) //remove value of existing checked item
								: [...stateCheckedItems, option.value]; // or add the value of check item
							stateSetCheckItems(filteredValues);
						}
						onChange && onChange(isReturnAllOnChange ? filteredValues : option);
					}}
					options={formattedOptions}
					checkmark
					multiple={true}
					value={stateCheckedItems}
					icons={[
						{
							path: 'utility/search',
							isLeft: true,
							className: styles.inputIcon,
						},
					]}
				>
					{hasBadgeCount && referenceCheckedItems?.length && (
						<DropdownTrigger>
							<Button>
								<div
									className="slds-badge"
									style={{
										marginLeft: '5px',
										backgroundColor: '#0176c0',
										color: 'white',
										height: '20px',
									}}
								>
									{referenceCheckedItems?.length}
								</div>
							</Button>
						</DropdownTrigger>
					)}
				</Dropdown>
			</div>
			{error && displayErrorMessage && (
				<div
					className={cx({
						'slds-has-error': !!error,
					})}
				>
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
		</div>
	);
};

const DropdownCheckbox: React.FC<
	SelectFieldProps & { control?: Control<any> }
> = ({ control, name, defaultValue, disabled, ...rest }) => {
	if (control && name) {
		const { onChange } = rest;

		return (
			<Controller
				control={control}
				name={name}
				render={({ field, fieldState: { error } }) => {
					const { getOptionValue = ({ value }) => value } = rest;
					return (
						<>
							<PlainSelectField
								{...rest}
								{...field}
								onChange={(v) => {
									field.onChange(getOptionValue(v));
									onChange && onChange(getOptionValue(v));
								}}
								defaultValue={defaultValue}
								onBlur={field.onBlur}
								error={error?.message}
								disabled={disabled}
								inputRef={field.ref}
							/>
						</>
					);
				}}
				defaultValue={defaultValue}
			/>
		);
	}
	return (
		<PlainSelectField
			name={name}
			defaultValue={defaultValue}
			disabled={disabled}
			{...rest}
		/>
	);
};

export default DropdownCheckbox;
