import React from 'react';
import { Modal } from '@salesforce/design-system-react/module/components';

import ScheduleForm from './ScheduleForm';
import ScheduleItem from './ScheduleItem';
import { ScheduleModalProps } from '../types';
import styles from './ScheduleModal.module.css';

const ScheduleModal: React.FC<ScheduleModalProps> = ({
	isOpen,
	close,
	branchTpaidDetails,
}) => {
	const { branchName, tpaidName, isOperating, hasSchedule, schedules } =
		branchTpaidDetails;
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={close}
			size="small"
			contentClassName={styles.modalContent}
			containerClassName={styles.modalContainer}
		>
			<div className={styles.modalTitleContainer}>
				<div className={styles.title}>{branchName}</div>
				<div className={styles.title}>{tpaidName}</div>
				<div
					className={styles.statusIcon}
					style={{
						backgroundColor: isOperating ? '#40A434' : '#8b9095',
					}}
				></div>
			</div>

			<div className={styles.disabled}>
				<ScheduleForm tpaidName={tpaidName} disabled />
			</div>

			<div className={styles.title}>Operating Schedule</div>
			<div className={styles.scheduleContainer}>
				{hasSchedule && schedules ? (
					schedules.map((e) => (
						<ScheduleItem
							key={e.startDate + e.startTime + e.endTime}
							schedule={e}
						/>
					))
				) : (
					<div className={styles.emptyList}>
						<i>
							No operating schedule created for this{' '}
							{tpaidName ? 'TPAID' : 'branch'} yet.
						</i>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ScheduleModal;
