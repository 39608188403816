import { ColumnConfig } from "containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/types";

export function createFooterEntry(
	config: ColumnConfig[],
	label: string,
	value: string
) {
	return config.reduce((acc, curr, i) => {
		if (i === 0)
			return {
				...acc,
				[curr.id]: label,
			};

		if (i === config.length - 1)
			return {
				...acc,
				[curr.id]: value,
			};

		return {
			...acc,
			[curr.id]: '',
		};
	}, {});
}
