import * as yup from 'yup';
import {
	restricSpecificSpecialChars,
	ValidateEmailsRecipients,
} from 'utils/formSchemas/common';
import { common } from 'utils/models/common';
import { ValidationError } from 'yup';
export type report_maintenance = {
	reportName?: string;
	fileNameFormat?: string;
	fileType?: string;
	businessReportCategory?: string;
	modeOfTransmission?: string;
	emailRecipients?: string;
	time?: string;
	frequency?: number;
	frequencyType?: string;
	dayOfMonth?: number;
	monthOfYear?: string;
} & common;

const checkDuplicates = (value, context, frequencyType) => {
	let testResult: boolean | ValidationError = true;
	const ctx = context as any;
	if (ctx.from !== undefined) {
		const schedules = ctx.from[1].value.reportScheduleTime;
		const fieldValue = ctx.parent;

		const checkDuplicate = schedules?.filter((el) => {
			if (frequencyType === 'DAILY') {
				return el.time === fieldValue.time;
			} else if (frequencyType === 'MONTHLY') {
				return (
					el.time === fieldValue.time &&
					parseInt(el.dayOfMonth) === parseInt(fieldValue.dayOfMonth)
				);
			} else if (frequencyType === 'YEARLY') {
				return (
					el.time === fieldValue.time &&
					parseInt(el.dayOfMonth) === parseInt(fieldValue.dayOfMonth) &&
					el.monthOfYear === fieldValue.monthOfYear
				);
			}
		});

		if (checkDuplicate.length >= 2) {
			testResult = context.createError({
				message: 'Schedule already exists',
			});
		}
	}
	return testResult;
};

export const report_maintenance_schema = yup.object().shape({
	reportName: yup
		.string()
		.nullable()
		.required('Input Report Name')
		.max(80, 'You can only input a max of 80 characters.')
		.test(
			'isValidReportName',
			'You can only input alphanumeric .,_-()& characters.',
			restricSpecificSpecialChars
		),
	fileNameFormat: yup.string().nullable().optional(),
	fileType: yup.string().nullable().optional(),
	businessReportCategory: yup
		.array()
		.of(yup.number().nullable().required('Select Business Category'))
		.nullable()
		.required('Select Business Category'),
	modeOfTransmission: yup.array().nullable().optional(),
	emailRecipients: yup
		.array()
		.nullable()
		.typeError('Input Email Recipients')
		.when(
			'modeOfTransmission',
			(
				modeOfTransmission: string | string[],
				schema: {
					min: (arg0: number, arg1: string) => any;
					optional: () => any;
				}
			) => {
				if (modeOfTransmission && modeOfTransmission.includes('EMAIL')) {
					return schema.min(1, 'Input Email Recipients');
				} else {
					return schema.optional();
				}
			}
		)
		.test('isValidEmail', ValidateEmailsRecipients),

	reportSchedule: yup.object().shape({
		frequency: yup.number().nullable().required('Input Frequency'),
		frequencyType: yup.string().nullable().required('Input Frequency Type'),
		reportScheduleTime: yup
			.array()
			.when('frequencyType', (frequencyType, s) => {
				if (frequencyType === 'DAILY') {
					return s.of(
						yup.object().shape({
							time: yup
								.string()
								.label('Time')
								.typeError('Select Time')
								.required('Select Time')
								.test({
									name: 'schedule-duplicate-validation-time',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
						})
					);
				} else if (frequencyType === 'MONTHLY') {
					return s.of(
						yup.object().shape({
							dayOfMonth: yup
								.number()
								.label('Day')
								.typeError('Select Day')
								.required('Select Day')
								.test({
									name: 'schedule-duplicate-validation-dayOfMonth',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
							time: yup
								.string()
								.label('Time')
								.typeError('Select Time')
								.required('Select Time')
								.test({
									name: 'schedule-duplicate-validation-time',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
						})
					);
				} else if (frequencyType === 'YEARLY') {
					return s.of(
						yup.object().shape({
							dayOfMonth: yup
								.number()
								.label('Day')
								.typeError('Select Day')
								.required('Select Day')
								.test({
									name: 'schedule-duplicate-validation-dayOfMonth',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
							monthOfYear: yup
								.string()
								.label('Month')
								.typeError('Select Month')
								.required('Select Month')
								.test({
									name: 'schedule-duplicate-validation-monthOfYear',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
							time: yup
								.string()
								.label('Time')
								.typeError('Select Time')
								.required('Select Time')
								.test({
									name: 'schedule-duplicate-validation-time',
									test: (value, ctx) =>
										checkDuplicates(value, ctx, frequencyType),
								}),
						})
					);
				}
			}),
	}),
});

export const remarksValidationSchema = yup.object().shape({
	remarks: yup
		.string()
		.label('Remarks')
		.max(1000, 'You can only input a max of 1000 characters.')
		.required('Input Remarks'),
});
