import React, { useState } from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Button, Tooltip } from '@salesforce/design-system-react';
import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import { ReactComponent as ViewIcon } from 'assets/icons/ic-three-dashes-view.svg';

import client from 'helpers/ApiClient';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import { formatCash, formatDate, strToNumber } from 'utils/common';
import {
	useToggle,
	useTablePagination,
	useErrorModal,
	useLoader,
} from 'utils/hooks';

import TransactionDetails from './TransactionDetails';
import { Transaction, TransactionTableProps } from './types';
import styles from './TransactionMgt.module.css';

const TRANSACTION_COLUMNS = (handleViewTransaction) => [
	{
		Header: 'Date and Time Created',
		id: 'createdAt',
		sortable: true,
		width: '14%',
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Transaction Reference No.',
		id: 'trn',
		sortable: true,
		width: '10%',
	},
	{
		Header: 'Client Reference No.',
		id: 'crn',
		sortable: true,
		width: '10%',
	},
	{
		Header: 'Account No.',
		id: 'arn',
		sortable: true,
		width: '9%',
	},
	{
		Header: 'Channel Partner',
		id: 'channelName',
		sortable: true,
		width: '9%',
	},
	{
		Header: 'Branch Name',
		id: 'branchName',
		sortable: true,
		width: '7%',
	},
	{
		Header: 'TPAID',
		id: 'tpaid',
		sortable: true,
		width: '6%',
	},
	{
		Header: 'Product Type',
		id: 'productType',
		sortable: true,
		width: '10%',
	},
	{
		Header: 'Partner Name',
		id: 'billerName',
		sortable: true,
		width: '9%',
	},
	{
		Header: 'Amount',
		id: 'amount',
		accessor: ({ amount }) => formatCash(strToNumber(amount)),
		sortable: true,
		width: '8%',
	},
	{
		Header: 'Service Fee',
		id: 'fee',
		accessor: ({ fee }) => formatCash(strToNumber(fee)),
		sortable: true,
		width: '7%',
	},
	{
		Header: 'Total Amount',
		id: 'totalAmount',
		accessor: ({ totalAmount }) => formatCash(strToNumber(totalAmount)),
		sortable: true,
		width: '7%',
	},
	{
		Header: 'API 3.0 Status',
		id: 'api3Status',
		sortable: true,
		width: '10%',
		Cell: ({ value }) => {
			let color;
			switch (value) {
				case 'PAYMENT_POSTED':
					color = '#14bb02';
					break;
				case 'PENDING':
				case 'ON_HOLD':
				case 'QUEUED':
				case 'PROCESSING':
					color = '#f37138';
					break;
				case 'FAILED':
					color = '#c94435';
					break;
				default:
					break;
			}
			return <p style={{ color }}>{startCase(value?.toLowerCase())}</p>;
		},
	},
	{
		Header: 'BFA Status',
		id: 'bfaStatus',
		sortable: true,
		width: '9%',
		Cell: ({ value = '' }) => {
			let color;
			switch (value) {
				case 'Success':
					color = '#14bb02';
					break;
				case 'Voided':
					color = '#8B9095';
					break;
				case 'Failed':
					color = '#c94435';
					break;
				default:
					break;
			}
			return <p style={{ color }}>{startCase(value?.toLowerCase())}</p>;
		},
	},
	{
		Header: (props) => <div className={styles.actionHeader}>Actions</div>,
		id: 'actions',
		accessor: (values) => values,
		width: '6%',
		Cell: ({ value }) => {
			return (
				<>
					<div className={cx(styles.actions)}>
						<div className={cx(styles.actionIcon)}>
							<Tooltip
								content="View"
								align="bottom right"
								dialogClassName={styles.tooltip}
							>
								<Button
									onClick={() => handleViewTransaction(value)}
									variant="icon"
								>
									<ViewIcon />
								</Button>
							</Tooltip>
						</div>
					</div>
				</>
			);
		},
	},
];

const TransactionTable: React.FC<TransactionTableProps> = ({
	data,
	handleTableFetch,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [selectedTxn, setselectedTxn] = useState<Transaction>();

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const {
		value: isOpen,
		valueOn: openModal,
		valueOff: closeModal,
	} = useToggle();

	const fetchTransaction = async (transaction) => {
		const { trn } = transaction;
		if (!hasUserPermission('transaction.get')) {
			return;
		}
		hideErrorModal();
		showLoadingMessage();

		try {
			const result = await client.get(
				`/v2/bfa-admin-portal/transactions/${trn}`
			);

			setselectedTxn({ ...transaction, ...result?.data?.data });
			openModal();
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchTransaction(trn)
			);
		} finally {
			hideLoading();
		}
	};

	return (
		<>
			<Table
				columns={TRANSACTION_COLUMNS(fetchTransaction)}
				data={data?.data}
				preHeader={null}
				modalIsOpen={false}
				isAlignToFilter={true}
				isNotEvenRowColor
				{...tablePaginationProps}
			/>
			{isOpen && selectedTxn && (
				<TransactionDetails
					isOpen={isOpen}
					onClose={closeModal}
					transaction={selectedTxn}
				/>
			)}
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</>
	);
};

export default TransactionTable;
