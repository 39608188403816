import { LOCAL } from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/constants';
import {
	AREA,
	BARANGAY,
	BUILDING_NAME_OR_NUMBER,
	BUILDING_NAME_OR_NUMBER_LABEL,
	COUNTRY,
	LOCATION_BASE,
	MUNICIPALITY_OR_CITY,
	MUNICIPALITY_OR_CITY_LABEL,
	REGION,
	STATE_OR_PROVINCE,
	STATE_OR_PROVINCE_LABEL,
	STREET,
	ZIP_CODE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import { noWhiteSpace } from 'utils/common';
import yup, { defaultOnlyAlphaChar } from 'utils/formSchemas/common';

export const branchAddress = yup.object({
	[LOCATION_BASE]: yup.string(),
	[COUNTRY]: yup
		.string()
		.required(`Select ${_.startCase(COUNTRY)}`)
		.label(_.startCase(COUNTRY)),
	[AREA]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(AREA)}`),
	}),
	[REGION]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(REGION)}`),
	}),
	[STATE_OR_PROVINCE]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${STATE_OR_PROVINCE_LABEL}`),
	}),
	[MUNICIPALITY_OR_CITY]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${MUNICIPALITY_OR_CITY_LABEL}`),
	}),
	[BARANGAY]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(BARANGAY)}`),
	}),
	[BUILDING_NAME_OR_NUMBER]: yup
		.string()
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-zA-Z0-9 ]*$/, {
			message: defaultOnlyAlphaChar,
		})
		.label(BUILDING_NAME_OR_NUMBER_LABEL)
		.when(LOCATION_BASE, {
			is: LOCAL,
			then: (schema) => schema.required().test(noWhiteSpace()),
		}),
	[STREET]: yup
		.string()
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-zA-Z0-9 ]*$/, {
			message: defaultOnlyAlphaChar,
		}),
	[ZIP_CODE]: yup.string(),
});
