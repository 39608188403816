import { useMemo, useState, useEffect } from 'react';
import { useFormContext, useFieldArray, Control } from 'react-hook-form';
import moment from 'moment';

import Grid from 'components/Grid/Grid';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import TextField from 'components/Inputs/TextField/TextField';
import TimeInput from 'components/Inputs/TimeField/TimeField';
import Section, { SectionRow } from 'components/Section/Section';
import { UploadSchedule } from 'utils/lookup';
import {
	COVERED_SCHEDULE,
	PartnerReportsFormData,
	TransferProtocolKey,
} from '..';
import styles from './style.module.css';
import { FILE_FORMATS } from '../CsrConfiguration';

type Configuration = 'uploadConfiguration' | 'csrConfiguration';

type NamePrefix = `${TransferProtocolKey}.${Configuration}`;

type Props = {
	name: NamePrefix;
	title: string;
	disabled?: boolean;
};

type TimeEntryProps = {
	name: `${Props['name']}.timestamps.${number}`;
	labelSuffix?: string;
	disabled?: boolean;
};

type InvisibleFieldsProps = {
	name: `${Props['name']}.timestamps.${number}`;
	control: Control<any>;
};

const InvisibleFields: React.FC<InvisibleFieldsProps> = ({ name, control }) => {
	return (
		<div style={{ display: 'none' }}>
			<TextField
				label="Generated ID"
				type="hidden"
				control={control}
				name={`${name}.generateId`}
			/>
			<TextField
				label="Upload ID"
				type="hidden"
				control={control}
				name={`${name}.uploadId`}
			/>
		</div>
	);
};

const TimeEntry: React.FC<TimeEntryProps> = ({
	name,
	labelSuffix,
	disabled,
}) => {
	const { control, watch } = useFormContext<PartnerReportsFormData>();

	function appendSuffix(label: string) {
		if (!labelSuffix) return label;

		return `${label} ${labelSuffix}`;
	}

	return (
		<SectionRow className={styles.timeContainer}>
			<Grid column container vertical className={styles.time}>
				<Grid column container>
					<Grid column size={1} of={2} className={styles.grid}>
						<InvisibleFields control={control} name={name} />
						<TimeInput
							label={appendSuffix('Generation Time')}
							required
							control={control}
							name={`${name}.generationTime`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={2} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Time of Upload')}
							required
							control={control}
							name={`${name}.timeOfUpload`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
				<Grid column container>
					<Grid column size={1} of={3} className={styles.grid}>
						<SelectFieldWithOthers
							label={appendSuffix('Covered Schedule')}
							required
							control={control}
							name={`${name}.coveredSchedule`}
							shouldUnregister
							options={COVERED_SCHEDULE}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Covered Time (from)')}
							required
							control={control}
							name={`${name}.coveredTime.from`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Covered Time (to)')}
							required
							control={control}
							name={`${name}.coveredTime.to`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</SectionRow>
	);
};

const UploadConfiguration: React.FC<Props> = ({
	name,
	title,
	children,
	disabled,
}) => {
	const { control, setValue, watch, getValues } =
		useFormContext<PartnerReportsFormData>();
	const { fields, append, remove } = useFieldArray({
		control,
		name: `${name}.timestamps`,
	});
	const uploadSchedule = watch(`${name}.uploadSchedule`);
	const uploadFrequency = watch(`${name}.uploadFrequency`);
	const [prevUploadPrequency, setPrevUploadFrequency] =
		useState(uploadFrequency);
	const timestamps = watch(`${name}.timestamps`);

	const appendEmptyTimeEntry = (size: number) => {
		if (size > 0) {
			append(
				Array(size).fill({
					coveredSchedule: undefined,
					coveredTime: {
						from: undefined,
						to: undefined,
					},
					generationTime: undefined,
					timeOfUpload: undefined,
				} as any)
			);
		}
	};

	const removeTimeEntry = (size: number, start: number) => {
		const to = size;
		const indices = Array(Number(size))
			.fill(0)
			.map((_, idx) => idx)
			.slice(Number(start), Number(to));
		remove(indices);
	};

	useEffect(() => {
		if (uploadSchedule !== 'NEXT_DAY') return;

		setValue(`${name}.uploadFrequency`, 1);
	}, [uploadSchedule, name]);

	useEffect(() => {
		if (
			(uploadFrequency === 1 || uploadFrequency ===  undefined)  &&
			(timestamps === undefined || (timestamps && timestamps.length === 0))
		) {
			appendEmptyTimeEntry(1);
		}
	}, []);

	useEffect(() => {
		if (uploadFrequency > -1 && uploadFrequency != fields.length) {
			if (uploadFrequency > prevUploadPrequency) {
				const size = Number(uploadFrequency) - Number(prevUploadPrequency);
				appendEmptyTimeEntry(size);
			} else if (uploadFrequency < prevUploadPrequency) {
				removeTimeEntry(prevUploadPrequency, uploadFrequency);
			} else if (
				uploadFrequency < fields.length &&
				uploadFrequency == prevUploadPrequency
			) {
				// condition is for removing excess fields (time entry) on mount
				removeTimeEntry(fields.length, uploadFrequency);
			}

			setPrevUploadFrequency(uploadFrequency);
		}
	}, [uploadFrequency, fields.length]);

	useEffect(()=>{
		if(!disabled && !uploadFrequency) {
			setValue(`${name}.uploadFrequency`, 1)
			setPrevUploadFrequency(1);
		}
	},[disabled, uploadFrequency])

	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3} className={styles.grid}>
					<SelectFieldWithOthers
						label="Filename Format"
						required
						control={control}
						name={`${name}.filenameFormat`}
						options={FILE_FORMATS.map((i) => ({
							label: i,
							value: i,
						}))}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3} className={styles.grid}>
					<SelectFieldWithOthers
						label="Upload Schedule"
						required
						control={control}
						name={`${name}.uploadSchedule`}
						options={UploadSchedule}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3} className={styles.grid}>
					<TextField
						label="Upload Frequency"
						required
						type="number"
						control={control}
						onKeyPress={(e) => {
							if (['e', '.', '-', '+'].indexOf(e.key) > -1) {
								e.preventDefault();
							}
						}}
						name={`${name}.uploadFrequency`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			{fields.map((field, index) => (
				<TimeEntry
					key={field.id}
					labelSuffix={`(${index + 1}/${fields.length})`}
					name={`${name}.timestamps.${index}`}
					disabled={disabled}
				/>
			))}

			{children}
		</Section>
	);
};

export default UploadConfiguration;
