import {
	TerminalAutoSaveResponseType,
	TerminalInfoAutosaveType,
	TerminalInfoPayloadAutosaveType,
	TerminalType,
} from './types';
import { ChannelTypePhysical, ChannelTypeDigital } from 'utils/lookup';
import moment from 'moment';

export const transformAutosaveResponseToTerminalType = (
	response: TerminalAutoSaveResponseType
): TerminalType => {
	const transformed: TerminalType = {
		channelAccountName: response?.data.username,
		spmNumber: response?.data.terminalInfo.spmNumber,
		activationDate: response?.data.terminalInfo.activationDate,
		channelTransactionType: response?.data.terminalInfo.channelTransactionType,
		channelType: [...ChannelTypeDigital, ...ChannelTypePhysical].find(
			(option) => option.value === response?.data.terminalInfo.channelTypeId
		)?.label,
		channelTypeId: response?.data.terminalInfo.channelTypeId,
		posType:
			response?.data.terminalInfo.channelTransactionType === 'DIGITAL'
				? undefined
				: response?.data.terminalInfo.posType,
		settlementSetup: '', // Add the corresponding value if available in the data
		meralcoPaymentCenterId: response?.data.terminalInfo.meralcoPaymentCenterId,
		remarks: response?.data.terminalInfo.remarks,
		tokenId: response?.data.terminalInfo.tokenId,
	};
	return transformed;
};

export const transformTerminalToPayload = (
	response: TerminalType | any,
	accountId: number,
	branchId: number
): TerminalInfoPayloadAutosaveType => {
	const transformed: TerminalInfoPayloadAutosaveType = {
		terminalInfo: {
			accountId,
			branchId,
			spmNumber:
				response?.spmNumber?.replace(/\s/g, '') === ''
					? undefined
					: response.spmNumber,
			activationDate: response.activationDate
				? moment(response.activationDate).format('YYYY-MM-DD')
				: undefined,
			channelTransactionType: response.channelTransactionType,
			channelTypeId: response.channelType,
			channelType: [...ChannelTypeDigital, ...ChannelTypePhysical].find(
				(option) => option.value === response.channelType
			)?.label,
			posType:
				response.channelTransactionType === 'DIGITAL'
					? undefined
					: response.posType,
			meralcoPaymentCenterId: response.meralcoPaymentCenterId,
			tokenId:
				response?.tokenId?.replace(/\s/g, '') === ''
					? undefined
					: response.tokenId,
			remarks:
				response?.remarks?.replace(/\s/g, '') === ''
					? undefined
					: response.remarks,
		},
	};

	return transformed;
};

export const transformTerminalValuesToPayload = (
	response: TerminalType | any,
	accountId: number,
	branchId: number
) => {
	const transformed = {
		accountId,
		branchId,
		spmNumber: response.spmNumber,
		activationDate: moment(response.activationDate).format('YYYY-MM-DD'),
		channelTransactionType: response.channelTransactionType,
		channelTypeId: response.channelType,
		channelType: [...ChannelTypeDigital, ...ChannelTypePhysical].find(
			(option) => option.value === response.channelType
		)?.label,
		posType: response.posType,
		meralcoPaymentCenterId: response.meralcoPaymentCenterId,
		tokenId: response.tokenId,
		remarks: response.remarks,
	};

	return transformed;
};
