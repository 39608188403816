import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import {
	ChannelAccreditation,
	ChannelAccreditationItem,
	CollectionDepositoryAccount,
	CollectionServiceAgreement,
	TermsAndDuration,
	ContractDetailsProps,
	ValueAddedTax,
} from './types';
export const SETTLEMENT_AGREEMENT_OPTIONS: SelectOption[] = [
	{ label: 'Bonded/Conventional', value: 'BOND/CONVENTIONAL' },
	{ label: 'Pre-funded (Wallet)', value: 'PREFUNDED' },
	{ label: 'Hybrid', value: 'HYBRID' },
];

export const BOND_TYPE_OPTIONS: SelectOption[] = [
	{ label: 'Cash-MOA', value: 'CASH-MOA' },
	{ label: 'DOA-TD', value: 'DOA-TD' },
	{ label: 'DOA-SA', value: 'DOA-SA' },
	{ label: 'DOA-LOC', value: 'DOA-LOC' },
	{ label: 'Surety', value: 'SURETY' },
	{ label: 'Others', value: 'OTHERS' },
];

export const DEPOSIT_CONSOLIDATION_OPTIONS: SelectOption[] = [
	{ label: 'Direct to CBCI', value: 'DIRECT_CBCI' },
	{ label: 'Direct to Biller', value: 'DIRECT_BILLER' },
];

export const DEPOSIT_MODE_OPTIONS: SelectOption[] = [
	{ label: 'Over the Counter', value: 'OTC' },
	{ label: 'Digital/Mobile', value: 'DIGITAL/MOBILE' },
];

export const accountType = [
	{ label: 'Savings', value: 'SAVINGS' },
	{ label: 'Current', value: 'CURRENT' },
];

export const IS_AUTO_RENEW_OPTIONS = [
	{ label: 'No', value: '0' },
	{ label: 'Yes', value: '1' },
];

export const RENEWAL_FREQUENCY_OPTIONS = [
	{ label: 'Annually', value: 'ANNUALLY' },
	{ label: 'Semi-Annually', value: 'SEMI_ANNUALLY' },
	{ label: 'Quarterly', value: 'QUARTERLY' },
	{ label: 'Monthly', value: 'MONTHLY' },
	{ label: 'Every N Years', value: 'EVERY_N_YEARS' },
];

export const VAT_TYPE_OPTIONS = [
	{ label: 'VATable', value: 'VATABLE' },
	{ label: 'VAT-Zero', value: 'VAT-ZERO' },
	{ label: 'VAT-Exempt', value: 'VAT-EXEMPT' },
	{ label: 'Non-VAT', value: 'NON-VAT' },
];

export const BUSINESS_LINES_OPTIONS = [
	{
		label: 'Beep',
		value: 'BEEP',
	},
	{
		label: 'Cash Payout',
		value: 'CASH-PAYOUT',
	},
	{
		label: 'E-loading',
		value: 'E-LOADING',
	},
	{
		label: 'Remittance',
		value: 'REMITTANCE',
	},
	{
		label: 'Cash Disbursement',
		value: 'CASH-DISBURSEMENT',
	},
	{
		label: 'CTPL',
		value: 'CTPL',
	},
	{
		label: 'Insurance',
		value: 'INSURANCE',
	},
];

export const ACCREDITATION_REQUIREMENTS_OPTIONS = [
	{
		label: 'BIR Certificate of Registration Form No. 2303',
		name: 'birCertReg',
		required: false,
	},
	{
		label:
			'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
		name: 'corpSecCert',
		required: false,
	},
	{
		label: 'Business Permit/Mayor’s Permit (updated/current year)',
		name: 'businessPermit',
		required: false,
	},
	{ label: 'Company Profile', name: 'companyProfile', required: false },
	{
		label:
			'Government Issued IDs of Board of Directors, including the authorized signatories',
		name: 'govtIds',
		required: false,
	},
	{
		label: 'Latest General Information Sheet (GIS)',
		name: 'genInfoSheets',
		required: false,
	},
	{
		label: 'Non-disclosure Agreement (NDA)',
		name: 'nonDisclosure',
		required: false,
	},
	{
		label: 'SEC Registration Certificate',
		name: 'secRegCert',
		required: false,
	},
	{
		label:
			'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
		name: 'secArticleIncorp',
		required: false,
	},
	{
		label:
			'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
		name: 'certAuthority',
		required: false,
	},
	{
		label: 'Signed Non-disclosure Agreement (NDA)',
		name: 'signedNda',
		required: false,
	},
	{
		label:
			'Audited Financial Statements (with Auditor’s Opinion & Notes to Financial Statements)',
		name: 'auditFinancialStmt',
		required: false,
	},
	{
		label: 'Income Tax Return',
		name: 'incomeTaxReturn',
		required: false,
	},
	{
		label: 'Accomplished CBCI AML Questionnaire',
		name: 'cbciAmlQuestion',
		required: false,
	},
	{
		label:
			'Audited Financial Statements (previous 2 years with Auditor’s Opinion and Notes to Financial Statements)',
		name: 'secondAuditFinStmt',
		required: false,
	},
	{ label: 'Data Privacy: PIA', name: 'dataPrivacyPia', required: false },
	{ label: 'Data Privacy: PAT', name: 'dataPrivacyPat', required: false },
	{
		label: 'Third-Party Security Checklist',
		name: 'thirdPartySecChecklist',
		required: false,
	},
];

export const CollectionDepositoryAccountDefault: CollectionDepositoryAccount = {
	acctName: '',
	acctNo: '',
	acctType: '',
	bankBranch: '',
	bankId: '',
};

export const CollectionServiceAgreementDefault: CollectionServiceAgreement = {
	accreditationFee: null!,
	annualFee: null!,
	bondAmount: null!,
	bondEndDate: null,
	bondStartDate: null,
	bondType: '',
	depositConsolidation: '',
	depositMode: '',
	settlementAgreement: '',
	thresholdAmount: null!,
};
export const TermsAndDurationDefault: TermsAndDuration = {
	executionDate: null,
	frequencyInterval: null,
	autoRenew: null,
	renewalFrequency: null,
	terminationDate: null,
};

export const ValueAddedTaxDefault: ValueAddedTax = {
	vatType: '',
};
export const ChannelAccreditationItemDefault: ChannelAccreditationItem = {
	file: '',
	filename: '',
	label: '',
	name: '',
	objectKey: '',
	status: '',
};

export const ChannelAccreditationDefault: ChannelAccreditation = {
	birCertReg: '',
	corpSecCert: '',
	businessPermit: '',
	companyProfile: '',
	govtIds: '',
	genInfoSheets: '',
	nonDisclosure: '',
	secRegCert: '',
	secArticleIncorp: '',
	certAuthority: '',
	signedNda: '',
	auditFinancialStmt: '',
	incomeTaxReturn: '',
	cbciAmlQuestion: '',
	secondAuditFinStmt: '',
	dataPrivacyPia: '',
	dataPrivacyPat: '',
	thirdPartySecChecklist: '',
};

export const CONTRACT_DETAILS_DEFAULT_VALUE: any = {
	contractDetails: {
		bondStartDate: '',
		bondEndDate: '',
		settlementAgreement: '',
		thresholdAmount: '',
		bondType: '',
		bondAmount: '',
		depositConsolidation: '',
		depositMode: '',
		accreditationFee: '',
		annualFee: '',
		vatType: '',
		vatRate: '12',
		otherBusinessLines: '',
		channelCashCollection: [
			{
				acctName: '',
				acctNo: '',
				acctType: '',
				bankBranch: '',
				bankId: null,
			},
		],
		channelCheckCollection: [
			{
				acctName: '',
				acctNo: '',
				acctType: '',
				bankBranch: '',
				bankId: null,
			},
		],
		channelTermsDuration: {
			executionDate: '',
			autoRenew: '',
			renewalFrequency: 0,
			terminationDate: '',
		},
		channelAccreditation: ChannelAccreditationDefault,
	},
};

export const ContractDetailsIdentifiers = {
	ContractDetails: {
		controlName: 'contractData',
	},
	CollectionServiceAgreement: {
		title: 'Collection Service Agreement, Extension, and NDA',
		controlName: 'contractDetails',
	},
	CashCollection: {
		title: 'Cash Collection Depository Account',
		controlName: 'contractDetails.channelCashCollection',
	},
	CheckCollection: {
		title: 'Check Collection Depository Account',
		controlName: 'contractDetails.channelCheckCollection',
	},
	TermsAndDuration: {
		title: 'Terms and Duration',
		controlName: 'contractDetails.channelTermsDuration',
	},
	ValueAddedTax: {
		title: 'Value-added Tax (VAT)',
		controlName: 'contractDetails',
	},
	OtherBusinessLines: {
		title: 'Other Business Lines',
		controlName: 'otherBusinessLines',
	},
	AccreditationRequirements: {
		title: 'Accreditation Requirements',
		controlName: 'channelAccreditation',
	},
};
