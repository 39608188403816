import Table from 'components/CWSTable/Table';
import React from 'react';

interface Props {
	columns: Array<any>;
	data: Array<any>;
	pageSize: number;
	count: number;
	onPageChange: (page, pageSize) => void;
	onSort: (sortBy, sort) => void;
	sortBy: string;
	sort: string;
	page: number;
	isSearch: boolean;
}

const DraftTable: React.FC<Props> = ({
	columns,
	data,
	pageSize,
	count,
	onPageChange,
	onSort,
	sortBy,
	sort,
	page,
	isSearch,
}) => {
	return (
		<Table
			columns={columns}
			data={data}
			count={count}
			pageSize={pageSize}
			onPageChange={onPageChange}
			onSort={onSort}
			sort={sort}
			sortBy={sortBy}
			page={page}
			isSearch={isSearch}
			preHeader={null}
			modalIsOpen={false}
		/>
	);
};

export default DraftTable;
