import { Route } from 'react-router-dom';
import Reports from './Reports';
import ReportsMaintenance from './ReportMaintenance';

const BusinessReports: React.FC = () => {
	return (
		<>
			<Route
				exact
				component={Reports}
				path="/business-reports/reports"
			/>
			<Route
				path="/business-reports/report-maintenance"
				component={ReportsMaintenance}
			/>
		</>
	);
};

export default BusinessReports;
