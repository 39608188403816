import React, { useState } from 'react';
import styles from './Navigation.module.css';
import { NavigationProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const Navigation: React.FC<NavigationProps> = ({
	tabs,
	content,
	itemKey,
	onClickTab,
}) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const handleTabClick = (index: number, tab: any) => {
		onClickTab(tab, itemKey);
		setActiveTabIndex(index);
	};

	return (
		<div className={styles.tabs}>
			{tabs.map((tab, index) => {
				const radioId = `tab-${index}-${itemKey}`;
				return (
					<React.Fragment key={index}>
						<input
							type="radio"
							className={styles.tabsRadio}
							name={`tabs-example-${index}-${itemKey}`}
							id={radioId}
							checked={activeTabIndex === index}
							onChange={() =>
								handleTabClick(index, {
									tabLabel: tab.title,
									tabName: tab.name,
								})
							}
						/>
						<label htmlFor={radioId} className={styles.tabsLabel}>
							{tab.title}
						</label>
						<div className={styles.tabsContent}>{content}</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Navigation;
