import React from 'react';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from 'containers/ChannelManagement/Permission/style.module.css';
type Props = {
	open: boolean;
	onClose: () => void;
};
const AccessDeniedModal: React.FC<Props> = ({ open, onClose }) => {
	const accessDeniedBodyText = (
		<>
			<div className={styles.confirmationHeader}>Access Denied</div>
			<div className={styles.subtext}>
				{`Sorry, you don't have access to this function.`}
				<br />
				Please contact your admin to grant access
				<br />
				permission. Thank you.
				<br />
				<br />
			</div>
			<PrimaryButton className={styles.successBtn} onClick={onClose}>
				Close
			</PrimaryButton>
		</>
	);
	return (
		<ErrorModal open={open} onClose={onClose}>
			<ErrorModalBody>{accessDeniedBodyText}</ErrorModalBody>
		</ErrorModal>
	);
};
export default AccessDeniedModal;
