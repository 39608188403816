import { useQuery } from 'react-query';
import HTTP, { ApiResponse } from 'helpers/ApiClient';
import useMultipleSelect from 'components/Checkbox/useMultiSelect';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { MIN_SEARCH_TERM_LENGTH } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/constants';

export function useGetCsrColumnsQuery() {
	return useQuery({
		queryKey: ['getCsrColumns'],
		queryFn: async () => {
			return HTTP.get<ApiResponse<CsrColumn[]>>(
				'/v2/channels/utils/csr-columns'
			);
		},
		select: (response) => response.data.data,
		staleTime: Infinity,
	});
}

export function useCsrColumns(pool: CsrColumn[], initialValue: CsrColumn[]) {
	const {
		selectedEntries,
		setSelectedEntries,
		entries,
		isSelected,
		toggle,
		allSelected,
		toggleAll,
		toggleFiltered,
		filterEntries,
		resetEntries,
	} = useMultipleSelect<CsrColumn>(pool ?? [], (entry) => entry.id, {
		initialValue,
		frozenEntries: pool?.filter(({ isRequired }) => isRequired) ?? [],
	});

	const [searchKeyword, setSearchKeyword] = useState('');

	useEffect(() => {
		debounce(() => {
			if (!searchKeyword) {
				resetEntries();
				return;
			}
			if (searchKeyword?.length < MIN_SEARCH_TERM_LENGTH) {
				return;
			}
			filterEntries(({ value }) => {
				const v = value?.toLowerCase();
				const s = searchKeyword?.toLowerCase();
				const match = v?.indexOf(s) !== -1;

				return match;
			});
		}, 400)();
	}, [searchKeyword]);

	return {
		entries,
		selectedEntries,
		setSelectedEntries,
		isSelected,
		toggle,
		allSelected,
		toggleAll,
		toggleFiltered,
		filterEntries,
		resetEntries,
		searchKeyword,
		setSearchKeyword,
	};
}
