import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTabChannel';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState, useEffect } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import ChannelDetails from 'containers/ChannelManagement/Channel/ChannelDetails/ChannelDetails';
import ChannelProductsTabComponent from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProducts';
import ChannelIntegration from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelIntegration';
import ChannelAuditTrail from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail';
import { setActiveTab } from 'redux/modules/channelList';
const VIEW_CHANNEL_SCOPE_NAME = 'bip.get.channel.primary.info';

type Props = {
	refetchChannel?: () => void;
	setIsChannelDirty?: any;
	isChannelDirty?: boolean;
	setIsChannelEditMode?: any;
	isChannelEditMode?: boolean;
};
const ChannelTab: React.FC<Props> = ({
	refetchChannel,
	setIsChannelDirty,
	isChannelDirty,
	setIsChannelEditMode,
	isChannelEditMode,
}) => {
	const scopes: any = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	);

	const dispatch = useDispatch();

	const activeType = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);

	const activeTab = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeTab
	);

	useEffect(() => {
		if (activeType == 'channel' && activeTab === 0) {
			dispatch(setActiveTab({ tab: 'DetailsTab' }));
		}
	}, [activeType]);

	const handleSelectedIndex = (index: number) => {
		if (activeType == 'channel' || activeType == '') {
			if (index === 0) {
				dispatch(setActiveTab({ tab: 'DetailsTab' }));
			} else if (index === 1) {
				dispatch(setActiveTab({ tab: 'ProductsTab' }));
			} else if (index === 2) {
				dispatch(setActiveTab({ tab: 'AuditTrail' }));
			} else if (index === 3) {
				dispatch(setActiveTab({ tab: 'ChanneIntegration' }));
			}
		}
	};

	const viewChannelScope = useMemo(
		() =>
			(scopes as { scope: string }[]).find(
				({ scope }) => scope === VIEW_CHANNEL_SCOPE_NAME
			),
		[scopes]
	);
	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};

	const storage = localStorage.getItem('userInfo');
	let validateTabIndex = true;

	if (storage != null) {
		if (viewChannelScope) {
			validateTabIndex = true;
		} else {
			validateTabIndex = false;
		}
	}
	const primaryTabProps = {
		tabs: [
			{
				title: 'Channel Details',
				component:
					activeTab == 'DetailsTab' ? (
						<ChannelDetails
							setIsChannelDirty={setIsChannelDirty}
							isChannelDirty={isChannelDirty}
							isEditMode={isChannelEditMode}
							setIsEditMode={setIsChannelEditMode}
							refetchChannel={refetchChannel}
						/>
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				scope: {
					permissions: [
						'view.primary-info',
						'view.contract-details',
						'view.reports',
					],
				},
			},
			{
				title: 'Products',
				// lazy load if not yet selected
				component:
					activeTab == 'ProductsTab' ? (
						<ChannelProductsTabComponent />
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				scope: { permissions: ['view.product'] },
			},
			{
				title: 'Audit Trail',
				component:
					activeTab == 'AuditTrail' ? (
						<ChannelAuditTrail initialValues={{}} {...panelPlaceholderProps} />
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				scope: { permissions: ['view.audit-trail'] },
			},
			{
				title: 'Channel Integration',
				component:
					activeTab == 'ChanneIntegration' ? (
						<ChannelIntegration
							data={{}}
							showConfirmationMessage={function (
								header: any,
								message: any,
								onConfirmBtnClick?: (() => void) | undefined,
								onClose?: (() => void) | undefined,
								confirmBtnLabel?: string | undefined,
								closeBtnLabel?: string | undefined
							): void {
								throw new Error('Function not implemented.');
							}}
						/>
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				scope: { permissions: ['view.integration'] },
			},
		],
		defaultIndex: 0,
	};

	// const indexing = primaryTabProps.tabs.splice(
	// 	primaryTabProps.tabs.findIndex((v) => v.title === 'Channel Details'),
	// 	1
	// );
	// const storage = localStorage.getItem('userInfo');
	// if (storage != null) {
	// 	if (viewChannelScope) {
	// 		primaryTabProps.tabs.splice(1, 0, {
	// 			title: 'Channel Details',
	// 			component: <ChannelDetails />,
	// 		});
	// 	} else {
	// 		indexing;
	// 	}
	// }

	return (
		<PrimaryTab
			{...primaryTabProps}
			onSelect={handleSelectedIndex}
			module="channels"
		/>
	);
};

export default ChannelTab;
