import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactDOM from 'react-dom';
import styles from './FullPageLoader.module.css';
import bayadLogo from 'assets/icons/bayad-logo-48x38.png';
import cx from 'classnames';

export default function FullPageLoader({
	open,
	message = 'Please wait while the data is being loaded',
	loaderStyle = 'infinite',
}) {
	if (!open) return null;

	const portalDOM = document.getElementById('portal-root');

	return ReactDOM.createPortal(
		<div className={styles.container}>
			<div className={styles.progressContainer}>
				{message && <div className={styles.message}>{message}</div>}
				<div
					className={cx(
						loaderStyle === 'infinite'
							? styles.progressInfinite
							: styles.progress
					)}
				>
					{loaderStyle === 'infinite' ? (
						<div className={styles.highlightRoundBox}>
							<img className={styles.bayadLogo} src={bayadLogo} />
							<span className={styles.loading}>
								<span className={cx(styles.edge, styles.edgeLeft)}></span>
								<span className={cx(styles.edge, styles.edgeRight)}></span>
							</span>
						</div>
					) : (
						<Percentage open={open} message={message} />
					)}
				</div>
			</div>
		</div>,
		portalDOM
	);
}

function Percentage({ open, message }) {
	const [percentage, setPercentage] = useState(0);
	useEffect(() => {
		setPercentage(0);
		if (open) {
			const min = 50;
			const max = 100;
			const interval = Math.floor(Math.random() * (max - min + 1)) + min;
			const timer = setInterval(() => {
				setPercentage((prevProgress) => {
					if (prevProgress < 100) {
						return prevProgress + 10;
					}
					return prevProgress;
				});
			}, interval);

			return () => {
				clearInterval(timer);
			};
		}
	}, [open]);

	return (
		<CircularProgressbar
			value={percentage}
			text={`${percentage}%`}
			styles={buildStyles({
				textSize: '1.5em',
				pathColor: '#f26122',
				textColor: 'white',
				trailColor: `rgba(0, 0, 0, ${percentage / 100})`,
				backgroundColor: '#3e98c7',
			})}
		/>
	);
}
