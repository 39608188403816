// import { PrimaryInformationFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/types';
// import { PrimaryInfoSaveResponse } from 'containers/ChannelManagement/Channel/ChannelModal/types';
import { UseFormReturn } from 'react-hook-form';
import { FixMeLater } from 'types';
import { PrimaryInfoType } from './Tabs/PrimaryInformation/PrimaryInformationSchema';
import { ContractDetailsType } from './Tabs/ContractDetails/ContractDetailsSchemas';
import { PartnerReportsFormData } from './Tabs/Reports/types';
import { BasicInfoType } from './Tabs/PrimaryInformation/ChannelBasicInformation/ChannelBasicInformationSchema';
import { TabStatus } from '../ChannelModal/types';

export enum FormModeType {
	ADD,
	DRAFT,
	EDIT,
	APPROVAL,
	REJECTED,
}

export type ChannelStatus = 'Engaged' | 'Active' | 'Suspended' | 'Deactivated';

export type FormType<PrimaryInfoType> =
	| UseFormReturn<BasicInfoType>
	| UseFormReturn<ContractDetailsType>
	| UseFormReturn<PartnerReportsFormData>;

export type ChannelFormProps = {
	channelId?: number;
	mode?: FormModeType;
	hasPrimaryViewPermission?: boolean;
	hasContractViewPermission?: boolean;
	hasReportsViewPermission?: boolean;
	selectedIndex: number;
	handleAutoSave?: () => void;
	setSelectedIndex: FixMeLater;
	tabStatus?: TabStatus;
	primaryData: FixMeLater;
	primaryForm: any;
	contractForm: any;
	reportsForm: any;
	isAdd?: boolean;
	isAutoSave?: boolean;
	isChannelDetails?: boolean;
	autoSaveValue?: any;
	disabled?: boolean;
	contentClassName?: string;
	onSubmit?: (
		primaryInfo: PrimaryInfoType,
		contractDetails: ContractDetailsType,
		reports: PartnerReportsFormData
	) => Promise<any>;
	// initialValues: {
	// 	status?: {
	// 		uat?: ChannelStatus;
	// 		staging?: ChannelStatus;
	// 		production?: ChannelStatus;
	// 	};
	// 	primaryInformation?: PrimaryInformationFormData;
	// 	contractDetails?: FixMeLater;
	// };
	onChange?: FixMeLater;
	currentTab?: (index: number) => void;
};

// export type PartialRecord<K extends keyof any, T> = {
// 	[P in K]?: T;
// };

// export type TabStatusValue = { finished?: boolean; disabled?: boolean };

// export type TabStatus = PartialRecord<
// 	keyof ChannelFormProps['initialValues'],
// 	TabStatusValue
// >;
