import React from 'react';

import Grid from 'components/Grid/Grid';
import Checkbox from 'components/Checkbox/Checkbox';
import SLDSDatepicker from 'components/Inputs/Datepicker/SLDSDatepicker';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';

import { ReactComponent as AddIcon } from 'assets/icons/ic-add-mui.svg';

import { ScheduleFormProps } from '../types';
import styles from './ScheduleModal.module.css';

const ScheduleForm: React.FC<ScheduleFormProps> = ({ tpaidName, disabled }) => {
	return (
		<>
			<div className={styles.title}>Add Schedule</div>
			{!tpaidName && (
				<div className={styles.hint}>
					* This schedule will also be applied to all TPAIDs under this branch.
				</div>
			)}
			<div className={styles.checkbox}>
				<Checkbox
					label="Recurring Schedule"
					disabled={disabled}
					// name="isRecurring"
				/>
			</div>
			<Grid container align="spread">
				{/* THIS CODE WILL BE USED WHEN ADD FEATURE IS ENABLED
				{!watch('isRecurring') ? (
					<>
						<Grid column>
							<DatePickerField
								name="startDate"
								placeholder="Start Date"
								size="small"
							/>
						</Grid>
						<Grid column>
							<DatePickerField
								name="endDate"
								placeholder="End Date"
								size="small"
							/>
						</Grid>
					</>
				) : ( */}
				<Grid size={2} of={5}>
					<SLDSDatepicker
						placeholder="Recurring"
						disabled={disabled}
						// name="recurringDays"
					/>
				</Grid>
				{/* )} */}

				<Grid
					container
					size={3}
					of={5}
					align="end"
					verticalAlign="center"
					gutters
				>
					<Grid column>
						<InputWithIcon
							placeholder="Start Time"
							disabled={disabled}
							icons={[
								{
									path: 'utility/clock',
								},
							]}
							// name="startTime"
						/>
					</Grid>
					<Grid column>
						<InputWithIcon
							placeholder="End Time"
							disabled={disabled}
							icons={[
								{
									path: 'utility/clock',
								},
							]}
							// name="endTime"
						/>
					</Grid>
					<Grid column>
						<AddIcon />
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ScheduleForm;
