import client from 'helpers/ApiClient';
import { UseMutationResult, useMutation } from 'react-query';
import { FixMeLater } from 'types';

export const useSaveBranchAutoSave = (): UseMutationResult<
	void,
	unknown,
	FixMeLater,
	unknown
> => {
	return useMutation({
		mutationFn: async (params: FixMeLater) => {
			await client.post('/v2/autosave/branches', params);
		},
	});
};
