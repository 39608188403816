import { useMemo, useEffect, useState, memo } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ServiceFeeSettingsFormData } from '../../ServiceFee/ServiceFeeSettings';
import {
	ServiceFeeField,
	TotalRateFee,
	useConditionalColumn,
} from '../../ServiceFee/ServiceFeesFields';

import Table from 'components/CWSTable/Table';

type PercentageSchemaProps = {
	control: Control<ServiceFeeSettingsFormData>;
	serviceFeeBillingType: ServiceFeeSettingsFormData['billing_type'];
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	withNonShareableAmount: string;
	disabled?: boolean;
	action?: string | undefined;
	modalIsOpen?: boolean;
	viewType?: string;
};

const COLUMN_CONFIG = (
	disabled: PercentageSchemaProps['disabled'],
	control: PercentageSchemaProps['control'],
	getValues: PercentageSchemaProps['getValues'],
	setValue?: PercentageSchemaProps['setValue'],
	serviceFeeBillType?: PercentageSchemaProps['serviceFeeBillingType'],
	withNonShareableAmount?: string,
	action?: PercentageSchemaProps['action']
) => {
	const conditionalColumn = useConditionalColumn({ control });
	const [dynamicWidth, setDynamicWidth] = useState(0);

	useEffect(() => {
		if (withNonShareableAmount == '0')
			setDynamicWidth(100 / defaultColumns.length);
		else setDynamicWidth(100 / [...defaultColumns, nonShareableColumn].length);
	}, [withNonShareableAmount]);

	const nonShareableColumn = {
		Header: conditionalColumn.nonShareable.headerName,
		id: conditionalColumn.nonShareable.id,
		width: `${dynamicWidth}%`,
		Cell: ({ column: { id } }) => (
			<ServiceFeeField
				name={`percentage.${id}`}
				defaultValue={action === 'ADD' ? '0' : undefined}
				control={control}
				placeholder={conditionalColumn.nonShareable.headerName}
				nonShareableColumn={conditionalColumn.nonShareable}
				isAmountField
				disabled={disabled || withNonShareableAmount === '0'}
			/>
		),
	};

	const defaultColumns = [
		{
			Header: 'Subsidized Rate',
			id: 'subsidized_rate',
			Cell: ({ column: { id } }) => (
				<ServiceFeeField
					name={`percentage.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.subsidized.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.SUBSIDIZED}
					disabled={disabled}
					isPercentage
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
			width: `${dynamicWidth}%`,
		},
		{
			Header: 'Pass-On Rate',
			id: 'pass_on_rate',
			width: `${dynamicWidth}%`,
			Cell: ({ column: { id } }) => (
				<ServiceFeeField
					name={`percentage.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.passOn.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.PASS_ON}
					disabled={disabled}
					isPercentage
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
		},
		{
			Header: conditionalColumn.total.headerName,
			id: conditionalColumn.total.id,
			width: `${dynamicWidth}%`,
			Cell: () => (
				<TotalRateFee
					idName={conditionalColumn.total.id}
					name={`percentage`}
					control={control}
					subsidizedName={conditionalColumn.subsidized.id}
					passOnName={conditionalColumn.passOn.id}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
				/>
			),
		},
	];

	if (withNonShareableAmount != '0')
		return [...defaultColumns, nonShareableColumn];

	return defaultColumns;
};

const PercentageSchema: React.FC<PercentageSchemaProps> = ({
	control,
	serviceFeeBillingType,
	withNonShareableAmount,
	disabled,
	getValues,
	setValue,
	action,
	modalIsOpen,
	viewType,
}) => {
	const rows = useMemo(() => [{}], []);

	return (
		<Table
			columns={COLUMN_CONFIG(
				disabled,
				control,
				getValues,
				setValue,
				serviceFeeBillingType || {},
				withNonShareableAmount,
				action
			)}
			showPagination={false}
			data={rows}
			preHeader={null}
			modalIsOpen={modalIsOpen}
			viewType={viewType}
		/>
	);
};

export default memo(PercentageSchema);
