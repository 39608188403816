export const BFA_STATUS_LABEL = {
	ACTIVE: 'enabled',
	INACTIVE: 'disabled',
};

export const BRANCH_ACCESS_STATUS = {
	ACTIVE: 'Active',
	ACTIVE_NO_SCHED: 'Active (No Schedule)',
	CLOSED: 'Closed',
};
