import yup, { transformDateFormat } from 'utils/formSchemas/common';
import moment from 'moment';

export const terminalDetails = yup.object().shape({
	channelAccountName: yup.string().label('Channel Account Name'),
	channelAccountCode: yup.string().label('Channel Account Code'),
	branchName: yup.string().label('Branch Name'),
	branchCode: yup.string().label('Branch Code'),
	tpaId: yup.string().label('TPAID'),
	machineLocation: yup.string().label('Machine Location'),
	spmNumber: yup
		.string()
		.label('SPM Number')
		.test(
			'is-numeric',
			'This field only allows numeric characters.',
			(value) => {
				if (value) {
					return /^[0-9]+$/.test(value);
				}
				return true;
			}
		)
		.test(
			'is-fifteen-number',
			'You can only input a max of 15 characters.',
			(value) => {
				if (value) {
					return value.length === 15 && /^[0-9]+$/.test(value);
				}
				return true;
			}
		),
	activationDate: yup
		.mixed()
		.nullable()
		.label('Activation Date')
		.transform(transformDateFormat)
		.required('Input Activation Date')
		.test(
			'valid-date',
			'Only input numeric characters in mm/dd/yyyy format',
			(value) => {
				if (!value) return true;
				return moment(value, 'MM/DD/YYYY', true).isValid();
			}
		)
		.test(
			'is-today-or-later',
			'Only select date today or a later date.',
			(value) => {
				return (
					!value || moment(value).isAfter(moment().subtract(1, 'day'), 'day')
				);
			}
		),
	channelTransactionType: yup.string().label('Channel Transaction Type'),
	channelType: yup
		.string()
		.label('Channel Type')
		.required('Select a Channel Type'),
	posType: yup
		.string()
		.label('POS Type')
		.nullable(true)
		.test('isRequired', 'Select a POS Type', function (value) {
			const { channelTransactionType } = this.parent;
			if (channelTransactionType === 'PHYSICAL') {
				return value !== null && value !== undefined && value !== '';
			}
			return true;
		}),
	settlementSetup: yup.string().label('Settlement Setup'),
	meralcoPaymentCenterId: yup
		.string()
		.label('Meralco Payment Center Code')
		.required('Select Meralco Payment Center Code'),
	remarks: yup
		.string()
		.label('Remarks')
		.test(
			'is-remarks',
			'You can only input a max of 1000 characters.',
			(value) => {
				if (value) {
					return value.length <= 1000;
				}
				return true;
			}
		),
	tokenId: yup
		.string()
		.label('Token ID Registration')
		.test(
			'is-token-id',
			'You can only input a max of 1000 characters.',
			(value) => {
				if (value) {
					return value.length <= 1000;
				}
				return true;
			}
		),
});
