import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { reportDownloadLink } from 'utils/queries/reports';
import { ReactNode, useCallback, useState } from 'react';
import { useSnackbar, closeSnackbar } from 'notistack';
import { useToggle } from 'utils/hooks';
import { sleep } from 'utils/common';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './index.module.css';
import cx from 'classnames';

type ParamsType = {
	categoryId: number;
	id: number;
};

type SnackBarType = {
	message: string;
};

const notifOption: any = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	style: { backgroundColor: '#FFF', color: '#3b3b3b' }
};

const SnackBar = ({ message }: SnackBarType) => {
	return (
		<div className={cx(styles.customLoaderContainter)}>
			<div className={styles.customLoader} />
			<div>{message}</div>
		</div>
	);
};

export const useDownloadReport = (params: ParamsType) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { enqueueSnackbar } = useSnackbar();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [disable, setDisable] = useState(false);
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => { } });
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const showErrorMessage = useCallback(
		(
			header: any,
			message: any,
			instruction?: any,
			onRetryBtnClick?: () => void
		) => {
			setErrorHeader(header);
			setErrorMessage(message);
			setErrorInstruction(instruction || 'Please try again.');
			showErrorModal();
			setRetryBtnOnClick({
				action: () => {
					hideErrorModal();
					onRetryBtnClick && onRetryBtnClick();
				},
			});
		},
		[hideErrorModal, showErrorModal]
	);

	const handleDownload = async () => {
		setDisable(true);
		let notifId;

		try {
			const res = await reportDownloadLink(params);

			notifId = enqueueSnackbar(
				<SnackBar message={'Your download will begin shortly..'} />,
				notifOption
			);

			await sleep(2000);
			closeSnackbar(notifId);

			const notifId2 = await enqueueSnackbar(
				<SnackBar message={'Your download has started..'} />,
				notifOption
			);

			setTimeout(() => {
				setDisable(false);
				closeSnackbar(notifId2);
				window.open(res?.downloadUrl, '_self');
			}, 1500);
		} catch (e: any) {
			closeSnackbar(notifId);
			if (e.isAxiosError) {
				if (e?.response?.status !== 403) {
					showErrorMessage(
						'Timeout Error!',
						'A problem occurred while loading the data.',
						null,
						() => {
							handleDownload();
						}
					);
				}
			}
			throw e;
		}
	};

	const downloadError = isErrorModalOpen && (
		<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
			<ErrorModalBody>
				<div className={styles.errorHeader}>{errorHeader}</div>
				<div className={styles.errorBody}>{errorMessage}</div>
				<div className={styles.errorFooter}>{errorInstruction}</div>
			</ErrorModalBody>
			<ErrorModalActions>
				<PrimaryButton
					fullWidth
					onClick={() => {
						retryBtnOnClick.action();
					}}
					className={styles.errorModalBtn}
				>
					Retry
				</PrimaryButton>
			</ErrorModalActions>
		</ErrorModal>
	);
	return {
		onClick: handleDownload,
		errorModal: downloadError,
		disable,
	};
};
