import {
	getDateValue,
	setDateValue,
	validateDateRangeFrom,
	validateDateRange as validator
} from 'utils/validation';
import { fetchCategoryTable, DEFAULT_FILTER } from 'redux/modules/reports';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { FILE_TYPES_OPTIONS } from '../constants/file-types';
import { categories } from 'utils/queries/reports';
import { useEffect, useState } from 'react';

import DropdownCheckbox from 'components/Inputs/CustomDropdownChecbox/DropdownCheckbox';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import DatePickerFrom from 'components/Inputs/Datepicker/SLDSDatepicker';
import DatePickerTo from 'components/Inputs/Datepicker/SLDSDatepicker';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './index.module.css';
import cx from 'classnames';
import moment from 'moment';
import _ from 'lodash';

type DateRange = string | null;

const updatePlaceholder = (selectedValues: string[]): string | undefined => {
	if (selectedValues.length === 1) {
		return selectedValues[0];
	} else if (selectedValues.length > 1) {
		return `${selectedValues.length} selected`;
	} else {
		return 'Select File Type';
	}
};

export default function ReportFilters(): JSX.Element {
	const dispatch = useDispatch();
	const state = useSelector((states: RootStateOrAny) => states.reports);

	const categoryType = categories[state.report_category_id];
	const pagination = state[categoryType].pagination;
	const stateFilter = state[categoryType].filter;

	const [hasNoDateFrom, setHasFromDate] = useState<boolean>(true);
	const [hasNoDateTo, setHasToDate] = useState<boolean>(true);
	const [file_types, setFileTypes] = useState<string[]>([]);
	const [filters, setFilters] = useState(stateFilter);

	const validateDateFrom = validateDateRangeFrom(filters.date_from, filters.date_to);
	const hasDateValues = !(hasNoDateFrom === false && hasNoDateTo === false);
	const invalidDateFrom = !!validateDateFrom && hasNoDateTo === false;
	const placeholderDropdown = updatePlaceholder(file_types);

	const hasFileName = _.isEmpty(filters.file_name);
	const hasFileType = file_types.length > 0;
	const dateRangeError: DateRange = validator(
		filters.date_from,
		filters.date_to,
	);

	const handleDropdownSelect = (value) => {
		if (file_types.includes(value)) {
			setFileTypes(file_types.filter((d) => d != value));
		} else {
			setFileTypes([...file_types, value]);
		}
	};

	const handleFilterTable = () => {
		const { count, limit } = pagination;
		const { date_from, date_to, file_name } = filters;

		dispatch(fetchCategoryTable({
			report_name_id: stateFilter.report_name_id,
			categoryId: state.report_category_id,
			is_report_filtering: true,
			offset: 0,
			page: 1,
			count,
			limit,
			file_name,
			file_types,
			date_from,
			date_to,
		}));
	};

	const handleClearFilter = () => {
		setFileTypes([]);
		setHasToDate(true)
		setHasFromDate(true)
		dispatch(fetchCategoryTable({
			...pagination,
			...DEFAULT_FILTER,
			report_name_id: stateFilter.report_name_id,
			categoryId: state.report_category_id,
		}));
	}

	const disableFields = () => {
		if (!hasNoDateFrom && hasNoDateTo) {
			return true
		}

		if (hasNoDateFrom && !hasNoDateTo) {
			return true
		}

		if (dateRangeError) {
			return true
		}

		return ![
			hasDateValues,
			hasFileName,
			!hasFileType
		].includes(false);
	}

	const handleSetDate = (name, value, stateDate) => {
		stateDate(_.isEmpty(value))
		setFilters({
			...filters,
			[name]: setDateValue(
				moment(value).format('YYYY-MM-DD')
			),
		});
	}

	useEffect(() => {
		if (stateFilter) setFilters(stateFilter);
	}, [stateFilter]);

	return (
		<div className={styles.container}>
			<p className={styles.filterTitle}>Filter:</p>
			<div className="slds-grid">
				<div className="slds-col">
					<div className={cx('slds-grid', styles.inputContainer)}>
						<div className="slds-col slds-size_4-of-12">
							<DatePickerFrom
								controlled
								label="Date From"
								placeholder="Date From"
								value={getDateValue(filters.date_from)}
								onChange={(value) => {
									handleSetDate('date_from', value, setHasFromDate);
								}}
								className={cx({
									[styles.errorDtp]: !!validateDateFrom
								})}
							/>
							{(!!validateDateFrom) && (
								<div className={styles.error}>
									{validateDateFrom}
								</div>
							)}
						</div>
						<div className="slds-col slds-size_4-of-12">
							<DatePickerTo
								controlled
								label="Date To"
								placeholder="Date To"
								value={getDateValue(filters.date_to)}
								onChange={(value) => {
									handleSetDate('date_to', value, setHasToDate);
								}}
								className={cx({
									[styles.errorDtp]: invalidDateFrom
								})}
							/>
							{invalidDateFrom && (
								<div className={styles.error}>
									{validateDateFrom}
								</div>
							)}
						</div>
						<div className="slds-col slds-size_4-of-12">
							<label className="slds-form-element__label">File Name</label>
							<InputWithIcon
								id="file-name"
								name="file_name"
								defaultValue={_.get(filters, ['file_name'], null)}
								value={_.get(filters, ['file_name'], null)}
								placeholder="File Name"
								onChange={(value) => {
									setFilters({
										...filters,
										file_name: value,
									});
								}}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<label
								className={cx('slds-form-element__label', styles.dummySpace)}
							>
								File Types
							</label>
							<DropdownCheckbox
								referenceCheckedItems={file_types}
								placeholder={placeholderDropdown}
								options={FILE_TYPES_OPTIONS}
								value={file_types}
								onChange={({ value }) => {
									handleDropdownSelect(value);
								}}
							/>
						</div>
						<div className="slds-col slds-size_10-of-11">
							<label
								className={cx('slds-form-element__label', styles.dummySpace)}
							>
								&nbsp;
							</label>
							<PrimaryButton
								type="button"
								className={styles.outlineButton}
								onClick={() => {
									handleClearFilter();
								}}
							>
								Clear
							</PrimaryButton>
						</div>
						<div className="slds-col slds-size_11-of-11">
							<label
								className={cx('slds-form-element__label', styles.dummySpace)}
							>
								&nbsp;
							</label>
							<PrimaryButton
								type="submit"
								disabled={disableFields()}
								className={styles.filterButton}
								onClick={() => handleFilterTable()}
							>
								Apply
							</PrimaryButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
