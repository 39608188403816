import { Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import UploadErrorStyles from './UploadSuccessErrorModal.module.css';
import { ReactNode } from 'react';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';


type UploadSuccessErrorModalSectionProps = {
	children: ReactNode;
};

export const UploadSuccessErrorModalBody: React.FC<
	UploadSuccessErrorModalSectionProps
> = ({ children, ...props }) => {
	return <div {...props}>{children}</div>;
};
export const UploadSuccessErrorModalActions: React.FC<
	UploadSuccessErrorModalSectionProps
> = ({ children, ...props }) => {
	return <div {...props}>{children}</div>;
};

type UploadSuccessErrorModalProps = {
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
	larger?: boolean;
};

export const UploadSuccessErrorText: React.FC = ({ children }) => (
	<div className={UploadErrorStyles.uploadSuccessErrorText}>{children}</div>
);
export const UploadSuccessErrorSubText: React.FC = ({ children }) => (
	<div className={UploadErrorStyles.uploadSuccessErrorSubText}>{children}</div>
);

const UploadSuccessErrorModal: React.FC<UploadSuccessErrorModalProps> = ({
	open,
	onClose,
	children,
	larger = false,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={UploadErrorStyles.header}
			size="small"
			containerClassName={UploadErrorStyles.modalContainer}
			contentClassName={cx(
				UploadErrorStyles.modal,
				larger ? UploadErrorStyles.larger : ''
			)}
		>
			<Grid vertical verticalAlign="center" className={UploadErrorStyles.container}>
				<Grid column className={UploadErrorStyles.modalBody}>
					<RenderByType type={UploadSuccessErrorModalBody}>{children}</RenderByType>
				</Grid>
				<Grid column className={UploadErrorStyles.modalActions}>
					<RenderByType type={UploadSuccessErrorModalActions}>
						{children}
					</RenderByType>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default UploadSuccessErrorModal;
