import React, { useEffect, useRef, useState } from 'react';
import { startCase } from 'lodash';

import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ExpandedSection from 'components/CWSTable/ExpandableRow/ExpandedSection';

import client from 'helpers/ApiClient';
import { formatParams } from 'utils/common';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import ChannelDetailsTable from './ChannelDetailsTable';
import ActionCell from './ActionCell';
import ExpandableRow from './ExpandableRow';

import {
	ListBranchResp,
	Branch,
	ChannelBranch,
	PaginationOptions,
	TableRef,
} from '../types';
import styles from './ChannelDetailsBody.module.css';

const TABLE_WIDTH = 90 / 3;

const BRANCH_ACCESS_CTRL_COLUMN = (width) => [
	{
		Header: '',
		id: 'expandButton',
		width: '10%',
		Cell: ({ row: { original } }) => {
			return (
				<ExpandedSection
					disabled={!original?.totalTerminals}
					styles={styles}
					className={styles.expandButtonIcon}
				>
					<ExpandableRow
						tabName="branchAccess"
						branchName={original?.name}
						data={original?.terminals}
						toggleActiveValue="ACTIVE"
						fromBranchAccessCtrl
						width={width}
					/>
				</ExpandedSection>
			);
		},
	},
	{
		Header: 'Branch/TPAID',
		id: 'name',
		sortable: true,
		width: `${TABLE_WIDTH}%`,
		Cell: ({ value }) => <div className={styles.idCell}>{value}</div>,
	},
	{
		Header: 'Status',
		id: 'status',
		sortable: true,
		width: `${TABLE_WIDTH}%`,
		Cell: ({ value }) => {
			let className = '';
			switch (value) {
				case 'ACTIVE':
					className = styles.statusEnabled;
					break;
				case 'ACTIVE_NO_SCHED':
					className = styles.statusActiveNoSched;
					break;
				case 'CLOSED':
					className = styles.statusClosed;
					break;
				default:
					break;
			}
			const statusLabel =
				value === 'ACTIVE_NO_SCHED'
					? 'Active (No Schedule)'
					: startCase(value.toLowerCase());
			return <div className={className}>{statusLabel}</div>;
		},
	},
	{
		Header: 'Actions',
		id: 'actions',
		width: `${TABLE_WIDTH}%`,
		Cell: ({ row: { original } }) => (
			<ActionCell
				name={original?.name}
				initialToggleValue={original?.status !== 'ACTIVE_NO_SCHED'}
				scheduleType={original?.status}
				enabledTerminals={original?.enabledTerminals}
				totalTerminals={original?.totalTerminals}
				branchId={original?.id}
				fromBranchAccessCtrl
				branchTpaidDetails={{
					branchName: original?.name,
					isOperating: original?.isOperating,
					hasSchedule: original?.hasSchedule,
					schedules: original?.schedules,
				}}
			/>
		),
	},
];

const ChannelBranchAccess: React.FC<ChannelBranch> = ({ channelId }) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [branches, setBranches] = useState<Branch>();
	const [filter, setFilter] = useState<PaginationOptions>({});
	const [width, setWidth] = useState<number>(0);

	const tableReference = useRef<TableRef>(null);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const fetchBranches = async ({ channelId, params }) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const response: ListBranchResp = await client.get(
				`/v2/bfa-admin-portal/channels/${channelId}/branch-access-control`,
				{ params }
			);

			setBranches(response?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchBranches({ channelId, params })
			);
		} finally {
			hideLoading();
		}
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchBranches({ channelId, params: newFilter });
	};

	const tablePaginationProps = useTablePagination(
		handleTableFetch,
		branches?.meta
	);

	const tableProps = {
		columns: BRANCH_ACCESS_CTRL_COLUMN(width),
		data: branches?.data || [],
		className: styles.tableContainerTabContent,
		isAllRowColorWhite: true,
		ref: tableReference,
		isOverflowHidden: false,
		...tablePaginationProps,
	};
	const filterProps = {
		onSearch: handleTableFetch,
		padding: '0 0 1vw 0',
	};

	useEffect(() => {
		if (hasUserPermission('channel.branch-access-control')) {
			fetchBranches({
				channelId,
				params: {},
			});
		}
	}, []);

	// This is solely intended to obtain the table width for aligning it with the width of the expandedSection component.
	// This will update width when the screen has changed and set to expandedSection > HeadExpandableRow for component resizing.
	useEffect(() => {
		const updateParentWidth = () => {
			if (tableReference.current) {
				setWidth(tableReference.current.clientWidth);
			}
		};

		window.addEventListener('resize', updateParentWidth);
		updateParentWidth();

		return () => {
			window.removeEventListener('resize', updateParentWidth);
		};
	}, []);

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<ChannelDetailsTable tableProps={tableProps} filterProps={filterProps} />
		</div>
	);
};

export default ChannelBranchAccess;
