import folderIcon from "../../assets/icons/ic-folder.svg";
import cx from "classnames";
import styles from './BillerStatus.module.css'

const BillerTabPanelPlaceholder: React.FC = () => {
    return(
        <div
      className={cx(
        "slds-grid slds-grid_align-center slds-grid_vertical-align-center",
        styles.tabMaxheightAndWidth
      )}
    >
        <div>
        <img src={folderIcon} className={styles.placeholderImg} />
        <div className={styles.placeholderTitle}>No Selected Biller</div>
        <div className={styles.placeholderSubTitle}>
          Please select a biller.
        </div>
      </div>
    </div>
    )
}

export default BillerTabPanelPlaceholder;