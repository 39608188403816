import { branchAddress } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchAddressSchema';
import { branchBasicInformation } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchBasicInformationSchema';
import { branchContactDetails } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchContactDetailsSchema';
import { branchOperatingSchedule } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchOperatingScheduleSchema';
import yup from 'utils/formSchemas/common';

export const branchSchema = yup.object({
	branchBasicInformation,
	branchAddress,
	branchContactDetails,
	branchOperatingSchedule,
});
