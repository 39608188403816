import { Modal } from '@salesforce/design-system-react/module/components';
import { ReactComponent as Icon } from 'assets/icons/ic-download3.svg';
import styles from './FileTypeSelectModal.module.css';
import pStyles from './PasswordModal.module.css';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useEffect } from 'react';
import { useToggle } from 'utils/hooks';

type PasswordModalProps = {
	open?: boolean;
	onClose?: () => void;
	password?: string;
	title?: string;
};

const PasswordModal: React.FC<PasswordModalProps> = ({
	open,
	onClose,
	password,
	title,
}) => {
	const { value: isDisabled, valueOn: disable, valueOff: enable } = useToggle();

	useEffect(() => {
		if (open) {
			disable();
			setTimeout(() => {
				enable();
			}, 10000);
		}
	}, [open]);

	const handleClose = () => {
		if (!isDisabled) {
			onClose && onClose();
		}
	};
	return (
		<Modal
			isOpen={open}
			headerClassName={styles.header}
			onRequestClose={handleClose}
			containerClassName={styles.modalContainer}
			contentClassName={cx(styles.modal)}
		>
			<Grid vertical verticalAlign="center" className={styles.container}>
				{Icon && (
					<Grid column className={styles.iconContainer}>
						<Icon />
					</Grid>
				)}
				<div className={styles.modalBody}>
					<Grid column className={styles.downloadText}>
						Download
					</Grid>
					<Grid className={pStyles.confirmText} column>
						{title ? title : 'Wallet Balance Report'} has been successfully sent
						to your email. To open the file, take note of this password:
					</Grid>
					<Grid className={cx(pStyles.password)} column>
						{password}
					</Grid>
					<Grid vertical={false}>
						<Grid className={styles.button}>
							<PrimaryButton
								disabled={isDisabled}
								onClick={() => onClose && onClose()}
							>
								Done
							</PrimaryButton>
						</Grid>
					</Grid>
				</div>
			</Grid>
		</Modal>
	);
};

export default PasswordModal;
