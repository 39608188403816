import {
	AREA,
	BARANGAY,
	BRANCH_ADDRESS,
	LOCATION_BASE,
	MUNICIPALITY_OR_CITY,
	REGION,
	STATE_OR_PROVINCE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';

export const NAME_OF_STATE_OR_PROVINCE = `${BRANCH_ADDRESS}.${STATE_OR_PROVINCE}`;
export const NAME_OF_MUNICIPALITY_OR_CITY = `${BRANCH_ADDRESS}.${MUNICIPALITY_OR_CITY}`;
export const NAME_OF_BARANGAY = `${BRANCH_ADDRESS}.${BARANGAY}`;
export const NAME_OF_LOCATION_BASE = `${BRANCH_ADDRESS}.${LOCATION_BASE}`;
export const NAME_OF_REGION = `${BRANCH_ADDRESS}.${REGION}`;
export const INTERNATIONAL = 'INTERNATIONAL';
export const LOCAL = 'LOCAL';
export const PHILIPPINES_VALUE = '175';
export const NAME_OF_AREA = `${BRANCH_ADDRESS}.${AREA}`;
export const NATIONWIDE = 'NATIONWIDE';
export const DIGITAL = 'DIGITAL';
