import styles from './VoidStatusBadge.module.css';
import cx from 'classnames';

const VoidStatusBadge: React.FC<any> = ({ status, className, ...rest }) => {
	type TStatusObj = {
		colorClass: string;
		name: string;
		code: string;
	};

	const optionStatus: TStatusObj[] = [
		{
			colorClass: 'pill_pending',
			name: 'Pending',
			code: 'PENDING',
		},
		{
			colorClass: 'pill_approved',
			name: 'Approved',
			code: 'APPROVED',
		},
		{
			colorClass: 'pill_rejected',
			name: 'Rejected',
			code: 'REJECT',
		},
		{
			colorClass: 'pill_expired',
			name: 'Expired',
			code: 'EXPIRED',
		},
	];
	const selected = optionStatus.find(
		(option: TStatusObj) => option.code.toLowerCase() === status?.toLowerCase()
	) || { name: status, colorClass: optionStatus[3].colorClass };

	return (
		<>
			{selected.name && (
				<span
					className={cx(
						'slds-badge',
						'slds-float_right',
						styles[selected.colorClass],
						className
					)}
					style={{
						color: 'white',
					}}
					{...rest}
				>
					{`${selected?.name[0].toUpperCase()}${selected?.name
						.slice(1)
						.toLowerCase()}`}
				</span>
			)}
		</>
	);
};
export default VoidStatusBadge;
