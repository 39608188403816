import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './Button.module.css';

type Props = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	className?: string;
	children: ReactNode;
	fullWidth?: boolean;
};

const Button: React.FC<Props> = ({
	children,
	className = '',
	fullWidth = false,
	...rest
}) => {
	return (
		<button
			className={cx(
				'slds-button',
				styles.btn,
				{ [styles.fullWidth]: fullWidth },
				className
			)}
			{...rest}
		>
			{children}
		</button>
	);
};

export default Button;
