import SelectField from 'components/Inputs/SelectField/SelectField';
import cx from 'classnames';
import styles from './PartnerStatusSelect.module.css';
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useWatch } from 'react-hook-form/dist/useWatch';

type Props = {
	value?: string;
	disabled?: boolean;
	control?: any;
	name?: string;
	onSelect?: (val: any) => void;
	excludeOption?: Array<string> | string;
};

type StatusOption = {
	label: string;
	value: string;
	className: string;
	isHidden?: boolean;
};

export const status_option: StatusOption[] = [
	{
		label: 'Active',
		value: 'ACTIVE',
		className: cx(styles.active),
	},
	{
		label: 'Draft',
		value: 'DRAFT',
		className: cx(styles.draft),
	},
	{
		label: 'Approved',
		value: 'APPROVED',
		className: cx(styles.approved),
		isHidden: true,
	},
	{
		label: 'Under Development',
		value: 'UNDER_DEVELOPMENT',
		className: cx(styles.underDevelopment),
	},
	{
		label: 'For Activation',
		value: 'FOR_ACTIVATION',
		className: cx(styles.forActivation),
	},
	{
		label: 'Suspended',
		value: 'SUSPENDED',
		className: cx(styles.suspended),
	},
	{
		label: 'Deactivated',
		value: 'DEACTIVATED',
		className: cx(styles.deactivated),
	},
	{
		label: 'Terminated',
		value: 'TERMINATED',
		className: cx(styles.terminated),
	},
	{
		label: 'Rejected',
		value: 'REJECTED',
		className: cx(styles.rejected),
		isHidden: true,
	},
	{
		label: 'Draft',
		value: 'DRAFT',
		className: cx(styles.draft),
		isHidden: true,
	},
	{
		label: 'For Review',
		value: 'FOR_REVIEW',
		className: cx(styles.forReview),
		isHidden: true,
	},
];

const PartnerStatusSelect: React.FC<Props> = ({
	value,
	disabled,
	control,
	name,
	onSelect,
	excludeOption = '',
}) => {
	const match = status_option.find((option) => option.value == value);

	return (
		<SelectField
			className={cx(styles.select, match ? match.className : '')}
			options={status_option.filter((s) =>
				excludeOption.length ? !excludeOption.includes(s.value) : true
			)}
			size="x-small"
			control={control}
			name={name}
			placeholder="Partner Status"
			buttonClassName={styles.button}
			value={value}
			onChange={(val) => {
				onSelect && onSelect(val.value);
			}}
			disabled={disabled}
		/>
	);
};

export default PartnerStatusSelect;
