import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import TagInput, {
	EmailTagInput, ContactNumberTagInput
} from 'components/TagInput/TagInput';
import { Control } from 'react-hook-form';

export type AccountOfficerFields = {
	name?: string;
	email?: string[];
	contactNumber?: string[];
};

type Props = {
	control: Control<any>;
	disabled?: boolean;
};

const AccountOfficer: React.FC<Props> = ({ control, disabled }) => {
	const name = 'accountOfficer';
	return (
		<Section title="Account Officer">
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						required
						label="Name"
						name={`${name}.name`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<EmailTagInput
						required
						control={control}
						name={`${name}.email`}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<ContactNumberTagInput
						required
						control={control}
						name={`${name}.contactNumber`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default AccountOfficer;
