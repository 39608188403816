import React from 'react';

import Table from 'components/CWSTable/Table';

import { useTablePagination } from 'utils/hooks';

import PartnerDetails from './PartnerDetails';
import { PartnerTabProps } from './types';
import styles from './ProductMgt.module.css';

const PartnerTable: React.FC<PartnerTabProps> = ({
	columns,
	data,
	isPartnerProfileShown,
	hidePartnerProfile,
	selectedPartner,
	handleTableFetch,
}) => {
	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	return (
		<div>
			<Table
				columns={columns}
				data={data?.data}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor
				className={styles.tableContainer}
				{...tablePaginationProps}
			/>
			{isPartnerProfileShown && (
				<PartnerDetails
					onClose={() => {
						hidePartnerProfile && hidePartnerProfile();
						handleTableFetch({});
					}}
					partnerData={selectedPartner}
				/>
			)}
		</div>
	);
};

export default PartnerTable;
