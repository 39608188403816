import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';

import { Button, Tooltip } from '@salesforce/design-system-react';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import Table from 'components/CWSTable/Table';

import client from 'helpers/ApiClient';
import { formatDate } from 'utils/common';
import {
	useErrorModal,
	useLoader,
	useTablePagination,
	useToggle,
} from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import ChannelDetails from './ChannelDetails';
import { ChannelTableProps, Channel } from './types';
import { BFA_STATUS_LABEL } from './constants';
import styles from './ChannelMgt.module.css';

const CHANNEL_COLUMNS = (
	onClickActionBtn: (data: { id: number }) => Promise<void>
) => [
	{
		Header: 'Date Integrated',
		id: 'dateIntegrated',
		sortable: true,
		width: '17%',
		accessor: ({ dateIntegrated }) => formatDate(dateIntegrated),
	},
	{
		Header: 'Channel Account Code',
		id: 'code',
		sortable: true,
		width: '17%',
	},
	{
		Header: 'Channel Account Name',
		id: 'name',
		sortable: true,
		width: '15%',
	},
	{
		Header: 'Settlement Setup',
		id: 'settlementSetup',
		sortable: true,
		width: '14%',
		Cell: ({ value }) => startCase(value?.toLowerCase()),
	},
	{
		Header: 'API 3.0 Status',
		id: 'api3status',
		sortable: true,
		width: '14%',
		Cell: ({ value }) => (
			<div
				className={
					value === 'ACTIVE' ? styles.labelActive : styles.labelInactive
				}
			>
				{startCase(value?.toLowerCase())}
			</div>
		),
	},
	{
		Header: 'BFA Status',
		id: 'bfaStatus',
		sortable: true,
		width: '14%',
		Cell: ({ value }) => (
			<div
				className={
					value === 'ACTIVE' ? styles.labelActive : styles.labelInactive
				}
			>
				{startCase(BFA_STATUS_LABEL[value]) || ''}
			</div>
		),
	},
	{
		Header: 'Action',
		id: 'action',
		width: '9%',
		Cell: ({ row }) => (
			<Tooltip content="View" align="bottom" dialogClassName={styles.tooltip}>
				<Button
					assistiveText={{ icon: 'Icon Bare Small' }}
					className={styles.viewIcon}
					iconCategory="utility"
					iconName="picklist_type"
					iconSize="small"
					iconVariant="bare"
					iconClassName={styles.iconBtn}
					onClick={() => onClickActionBtn(row?.original)}
					variant="icon"
				/>
			</Tooltip>
		),
	},
];

const ChannelTable: React.FC<ChannelTableProps> = ({
	handleTableFetch,
	data,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [selectedChannelData, setSelectedChannelData] = useState<Channel>();

	const [refetchChannelProfile, setRefetchChannelProfile] =
		useState<boolean>(false);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const {
		value: isOpen,
		valueOn: openModal,
		valueOff: closeModal,
	} = useToggle();

	const fetchChannel = async (channel) => {
		if (!hasUserPermission('list.channel.profile')) {
			return;
		}
		hideErrorModal();
		showLoadingMessage();

		try {
			const result = await client.get(
				`/v2/bfa-admin-portal/channels/${channel?.id}/profile`
			);
			setSelectedChannelData({ ...channel, ...result?.data?.data });
			openModal();
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchChannel(channel)
			);
		} finally {
			hideLoading();
			setRefetchChannelProfile(false);
		}
	};

	useEffect(() => {
		if (refetchChannelProfile) {
			fetchChannel(selectedChannelData);
		}
	}, [refetchChannelProfile]);

	return (
		<>
			<Table
				columns={CHANNEL_COLUMNS(fetchChannel)}
				data={data?.data}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor
				className={styles.tableContainer}
				{...tablePaginationProps}
			/>
			{selectedChannelData && (
				<ChannelDetails
					isOpen={isOpen}
					onClose={() => {
						handleTableFetch({});
						closeModal();
					}}
					data={selectedChannelData}
					setRefetchChannelProfile={setRefetchChannelProfile}
					refetchChannelProfile={refetchChannelProfile}
				/>
			)}
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</>
	);
};

export default ChannelTable;
