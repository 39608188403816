import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import { Modal } from '@salesforce/design-system-react/module/components';
import OutlineButton from 'components/Buttons/OutlineButton';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessChannelModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { UseUpdateTerminal } from 'containers/ChannelManagement/Terminal/query';
import { transformTerminalToPayload } from 'containers/ChannelManagement/Terminal/utils';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import { useList } from 'containers/ChannelManagement/List/hooks';
import moment from 'moment';

type Props = {
	mode?: string;
	isAutoSave?: boolean;
	disabled?: boolean;
	autoSaveValue?: any;
	refetchTerminal?: () => void;
};

const FormLogic: React.FC<Props> = ({
	mode,
	disabled,
	refetchTerminal,
	isAutoSave,
	autoSaveValue,
}) => {
	const [isRemarksLimit, setIsRemarksLimit] = useState<boolean>(false);
	const [changeRemarks, setChangeRemarks] = useState<string>('');
	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	const { channelTypeFilter } = useList();

	const { setValue, formState, getValues, clearErrors, reset } =
		useFormContext();

	const formValues = getValues();

	const {
		spmNumber,
		activationDate,
		channelTransactionType,
		channelType,
		posType,
		meralcoPaymentCenterId,
		remarks,
		tokenId,
	} = formValues;

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		channelFormValues,
		branchFormValues,
		terminalFormValues,
		channelDetails,
		branchDetails,
		terminalDetails,
	} = useTerminal();

	useEffect(() => {
		if (mode !== 'DRAFT') {
			reset();
		}
	}, [terminalDetails, disabled]);

	useEffect(() => {
		if (isAutoSave) {
			setValue(
				'channelAccountName',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.name || ''
			);
			setValue(
				'channelAccountCode',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.tpaAccount || ''
			);
			setValue('branchName', branchFormValues?.data?.name || '');
			setValue('branchCode', branchFormValues?.data?.branchCode || '');
			setValue(
				'machineLocation',
				branchFormValues?.data?.branchPrimaryInfos?.machineLocation || ''
			);
			setValue('spmNumber', autoSaveValue?.data?.terminalInfo?.spmNumber || '');
			setValue(
				'activationDate',
				autoSaveValue?.data?.terminalInfo?.activationDate || ''
			);
			setValue(
				'channelTransactionType',
				autoSaveValue?.data?.terminalInfo?.channelTransactionType || ''
			);
			setValue('channelType', autoSaveValue?.data?.terminalInfo?.channelTypeId);
			setValue('posType', autoSaveValue?.data?.terminalInfo?.posType || '');
			setValue(
				'settlementSetup',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.settlementSetup ||
					''
			);
			setValue(
				'meralcoPaymentCenterId',
				autoSaveValue?.data?.terminalInfo?.meralcoPaymentCenterId || ''
			);
			setValue('remarks', autoSaveValue?.data?.terminalInfo?.remarks || '');
			setValue('tokenId', autoSaveValue?.data?.terminalInfo?.tokenId || '');
		}
	}, [
		isAutoSave,
		channelFormValues,
		branchFormValues,
		autoSaveValue,
		channelTypeFilter,
	]);

	useEffect(() => {
		if (formValues.posType === autoSaveValue?.data?.terminalInfo?.posType) {
			setValue('posType', autoSaveValue?.data?.terminalInfo?.posType || '');
		} else {
			setValue('posType', formValues.posType || '');
		}
		if (
			formValues.settlementSetup ===
			channelFormValues?.primaryInfo?.channelPrimaryInfo?.settlementSetup
		) {
			setValue(
				'settlementSetup',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.settlementSetup ||
					''
			);
		}
		if (
			formValues.meralcoPaymentCenterId ===
			autoSaveValue?.data?.terminalInfo?.meralcoPaymentCenterId
		) {
			setValue(
				'meralcoPaymentCenterId',
				autoSaveValue?.data?.terminalInfo?.meralcoPaymentCenterId || ''
			);
		} else {
			setValue(
				'meralcoPaymentCenterId',
				formValues.meralcoPaymentCenterId || ''
			);
		}
	}, [channelTransactionType]);

	useEffect(() => {
		if (mode === 'ADD' && !isAutoSave) {
			setValue(
				'channelAccountName',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.name || ''
			);
			setValue(
				'channelAccountCode',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.tpaAccount || ''
			);
			setValue('branchName', branchFormValues?.data?.name || '');
			setValue('branchCode', branchFormValues?.data?.branchCode || '');
			setValue(
				'machineLocation',
				branchFormValues?.data?.branchPrimaryInfos?.machineLocation || ''
			);
			setValue(
				'channelType',
				branchFormValues?.data?.branchPrimaryInfos?.channelTypeId || ''
			);
			setValue(
				'posType',
				branchFormValues?.data?.branchPrimaryInfos?.posType || ''
			);
			setValue(
				'settlementSetup',
				channelFormValues?.primaryInfo?.channelPrimaryInfo?.settlementSetup ||
					''
			);
			setValue(
				'meralcoPaymentCenterId',
				branchFormValues?.data?.branchPrimaryInfos?.meralcoPaymentCenterId || ''
			);
		} else if (mode === 'EDIT' || mode === 'DRAFT') {
			setValue(
				'channelAccountName',
				terminalFormValues?.data?.channelAccountName || '',
				mode === 'DRAFT' ? { shouldValidate: true } : undefined
			);
			setValue(
				'channelAccountCode',
				terminalFormValues?.data?.channelAccountCode || ''
			);
			setValue('branchName', terminalFormValues?.data?.branchName || '');
			setValue('branchCode', terminalFormValues?.data?.branchCode || '');
			setValue('tpaId', terminalFormValues?.data?.tpaId || '');
			setValue(
				'machineLocation',
				terminalFormValues?.data?.machineLocation || ''
			);
			setValue('channelType', terminalFormValues?.data?.channelTypeId || '');
			setValue('posType', terminalFormValues?.data?.posType || '');
			setValue(
				'settlementSetup',
				terminalFormValues?.data?.settlementSetup || ''
			);
		}
	}, [
		channelFormValues,
		branchFormValues,
		terminalFormValues,
		channelTransactionType,
		channelTypeFilter,
		disabled,
	]);

	useEffect(() => {
		if (mode === 'ADD' && !isAutoSave) {
			setValue(
				'channelTransactionType',
				branchFormValues?.data?.branchPrimaryInfos?.channelTransactionType || ''
			);
		} else if (mode === 'EDIT' || mode === 'DRAFT') {
			setValue('spmNumber', terminalFormValues?.data?.spmNumber || '');
			setValue(
				'activationDate',
				terminalFormValues?.data?.activationDate || ''
			);
			setValue(
				'channelTransactionType',
				terminalFormValues?.data?.channelTransactionType || ''
			);
			setValue(
				'meralcoPaymentCenterId',
				terminalFormValues?.data?.meralcoPaymentCenterId || ''
			);
			setValue('remarks', terminalFormValues?.data?.remarks || '');
			setValue('tokenId', terminalFormValues?.data?.tokenId || '');
		}
	}, [
		branchFormValues?.data?.branchPrimaryInfos?.channelTransactionType,
		terminalFormValues,
		disabled,
	]);

	useEffect(() => {
		if (mode === 'ADD' && !isAutoSave) {
			if (
				channelTransactionType ===
					branchFormValues?.data?.branchPrimaryInfos?.channelTransactionType &&
				!formState.dirtyFields.channelTransactionType
			) {
				setValue(
					'channelType',
					branchFormValues?.data?.branchPrimaryInfos?.channelTypeId
				);
				clearErrors('channelType');
				clearErrors('posType');
			} else {
				clearErrors('channelType');
				clearErrors('posType');
				setValue('channelType', undefined);
				setValue('posType', undefined);
			}
		} else if (mode === 'EDIT' || mode === 'DRAFT') {
			if (
				channelTransactionType ===
					terminalFormValues?.data?.channelTransactionType &&
				!formState.dirtyFields.channelTransactionType
			) {
				setValue('channelType', terminalFormValues?.data?.channelTypeId);
				clearErrors('channelType');
				clearErrors('posType');
			} else {
				clearErrors('channelType');
				clearErrors('posType');
				setValue('channelType', undefined);
				setValue('posType', undefined);
			}
		}
	}, [channelTransactionType]);

	useEffect(() => {
		if (changeRemarks.length > 1000) {
			setIsRemarksLimit(true);
		} else {
			setIsRemarksLimit(false);
		}
	}, [changeRemarks]);

	useEffect(() => {
		if (mode === 'ADD' && !isAutoSave) return;
		if (
			moment(activationDate, 'MM/DD/YYYY').format('YYYY-MM-DD') ==
			terminalFormValues?.data?.activationDate
		) {
			clearErrors('activationDate');
		}
		if (disabled) {
			clearErrors();
		}
	}, [activationDate, formState.errors.activationDate, disabled]);

	useEffect(() => {
		if (
			terminalFormValues?.data?.spmNumber === spmNumber &&
			(terminalFormValues?.data?.activationDate === activationDate ||
				terminalFormValues?.data?.activationDate ==
					moment(activationDate, 'MM/DD/YYYY').format('YYYY-MM-DD')) &&
			terminalFormValues?.data?.channelTransactionType ===
				channelTransactionType &&
			terminalFormValues?.data?.channelTypeId === channelType &&
			terminalFormValues?.data?.posType === posType &&
			terminalFormValues?.data?.meralcoPaymentCenterId ===
				meralcoPaymentCenterId &&
			terminalFormValues?.data?.remarks === remarks &&
			terminalFormValues?.data?.tokenId === tokenId
		) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [formValues]);

	const saveTerminal = (
		<>
			<hr className={styles.footerDivider} />
			<div className={styles.footer}>
				<div className={styles.footerRightPanel}>
					<PrimaryButton
						className={styles.btnSave}
						onClick={showConfirmModal}
						disabled={
							isFormValid ||
							!!formState.errors.spmNumber ||
							!!formState.errors.activationDate ||
							!!formState.errors.remarks ||
							!!formState.errors.tokenId ||
							!formValues.activationDate ||
							!formValues.channelType ||
							(channelTransactionType === 'PHYSICAL' && !formValues.posType) ||
							!formState.isDirty
						}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</>
	);

	const confirmModalBodyText = (
		<>
			<br />
			Are you sure you want to save the changes made?
			<br />
			if yes, please input remarks.
			<br />
			<div className={styles.intergrationModalBody}>
				<div className={styles.remarksLabel}>
					Remarks
					<abbr className="slds-required" title="required">
						*
					</abbr>
				</div>
				<div className="slds-form-element__control">
					<textarea
						className={'slds-textarea' + ' ' + styles.remarks}
						name="remarks"
						onChange={(e) => {
							setChangeRemarks(e.target.value);
						}}
						placeholder="Remarks"
					/>
					<em className={styles.remarksSubText}>
						*Maximum of 1000 characters only.
					</em>
					{isRemarksLimit && (
						<div
							className={'slds-form-element__help' + ' ' + styles.remarksError}
						>
							<div>You can only input a max of 1000 characters.</div>
						</div>
					)}
				</div>
			</div>
		</>
	);

	const terminalPayload = transformTerminalToPayload(
		formValues,
		channelDetails.id,
		branchDetails.id
	);

	const { terminalInfo } = terminalPayload;

	const handleSave = async () => {
		hideConfirmModal();
		showLoading();
		await UseUpdateTerminal(terminalDetails.id, terminalInfo, changeRemarks)
			.then(() => {
				hideLoading();
				showSuccessModal();
				setIsRemarksLimit(false);
				setChangeRemarks('');
				reset();
			})
			.catch(() => {
				hideLoading();
				showErrorModal();
			});
	};

	const footer = (
		<div className={styles.footer}>
			<div className={styles.footerRightPanel}>
				<OutlineButton
					onClick={() => {
						hideConfirmModal();
						setChangeRemarks('');
					}}
					className={styles.btn}
				>
					Cancel
				</OutlineButton>
				<PrimaryButton
					onClick={handleSave}
					className={styles.btn}
					disabled={
						!changeRemarks ||
						isRemarksLimit ||
						changeRemarks.replace(/\s/g, '') === ''
					}
				>
					Confirm
				</PrimaryButton>
			</div>
		</div>
	);

	const successModalBodyText = (
		<>
			<div className={styles.confirmationSaveChanges}>Success!</div>
			<div className={styles.subtext}>
				<br />
				The changes you made were successfully saved!
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={() => {
					hideSuccessModal();
					refetchTerminal?.();
				}}
			>
				Done
			</PrimaryButton>
		</>
	);

	const errorModalBodyText = (
		<>
			<div className={styles.confirmationHeader}>Timeout Error!</div>
			<div className={styles.subtext}>
				A problem occurred while saving your changes.
				<br />
				Please try again.
				<br />
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={() => {
					hideErrorModal();
					handleSave();
				}}
			>
				Retry
			</PrimaryButton>
		</>
	);

	return (
		<>
			{mode === 'EDIT' && !disabled && saveTerminal}
			{isConfirmModalShowing && (
				<Modal
					isOpen={isConfirmModalShowing}
					onRequestClose={() => {
						hideConfirmModal();
						setChangeRemarks('');
					}}
					heading="Save Changes"
					containerClassName={styles.confirmModalContainer}
					footer={footer}
				>
					<div className={styles.intergrationConfirmationBody}>
						{confirmModalBodyText}
					</div>
				</Modal>
			)}
			{isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={() => {
						hideSuccessModal();
						refetchTerminal?.();
					}}
				>
					<SuccessModalBody>{successModalBodyText}</SuccessModalBody>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>{errorModalBodyText}</ErrorModalBody>
				</ErrorModal>
			)}
			{isLoading && (
				<FullPageLoader
					open={isLoading}
					message={'Please wait while changes are being saved.'}
				/>
			)}
		</>
	);
};

export default FormLogic;
