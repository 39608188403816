import { Modal } from '@salesforce/design-system-react/module/components';
import PrimaryButton from '../Buttons/PrimaryButton';
import styles from './ApiBatchResponseModal.module.css';
import cx from 'classnames';
import { ReactComponent as SuccessIcon } from 'assets/icons/ic-success.svg';
import { ReactComponent as FailedIcon } from '../../assets/icons/ic-failed.svg';

type Props = {
	open?: boolean;
	onClose: () => void;
	disableClose?: boolean;
	closeButton?: {
		name?: string;
		event?: () => void;
	};
	disableOnClick?: boolean;
	data: {
		label: string;
		status: string;
	}[];
};

const ApiBatchResponseModal: React.FC<Props> = ({
	open,
	onClose = () => {},
	data,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			size="small"
			containerClassName={styles.modal}
			ariaHideApp={false}
		>
			<div className="slds-grid slds-grid_vertical slds-gutters">
				<div className="slds-col slds-align-middle  slds-size_3-of-4">
					<div
						className={cx(
							styles.headerText,
							'slds-p-around_large',
							'slds-text-align--center'
						)}
					>
						We were able to save{' '}
						{data.filter((v) => v.status == 'Success').length} out of{' '}
						{data.length}.
					</div>
				</div>
				<div className="slds-col slds-align-middle  slds-size_3-of-4">
					<div
						className={cx(
							styles.bodyText,
							'slds-p-around_large',
							'slds-text-align--center'
						)}
					>
						To save the remaining forms, please go back and click save.
						Otherwise, you may close this to continue. Thank you.
					</div>
				</div>
				<div className="slds-col slds-align-middle  slds-size_3-of-4">
					<table
						className={cx(
							styles.apiBatchTable,
							'slds-table slds-table_cell-buffer'
						)}
					>
						<thead>
							<tr className="slds-line-height_reset">
								<th scope="col">
									<div className="slds-truncate" title="Tab">
										Tab
									</div>
								</th>
								<th scope="col">
									<div className="slds-truncate" title="Status">
										Status
									</div>
								</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody className={cx(styles.apiBatchTableBody)}>
							{data.map((v) => (
								<tr className="slds-hint-parent" key={v.label}>
									<td data-label={v.label}>
										<div className="slds-truncate" title={v.label}>
											{v.label}
										</div>
									</td>
									<td data-label={v.status}>
										<span className="slds-truncate" title={v.status}>
											{v.status}
										</span>
									</td>
									<td data-label={v.status + 'icon'}>
										<span className="slds-truncate" title={v.status + 'icon'}>
											<span className={styles.svg}>
												{v.status == 'Success' ? (
													<SuccessIcon className={styles.successIcon} />
												) : (
													<FailedIcon className={styles.failedIcon} />
												)}
											</span>
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="slds-col slds-align-middle slds-m-vertical_medium">
					<PrimaryButton
						className={cx(
							'slds-button slds-button_neutral slds-button_stretch',
							styles.errorModalBtn
						)}
						onClick={onClose}
					>
						Close
					</PrimaryButton>
				</div>
			</div>
		</Modal>
	);
};

export default ApiBatchResponseModal;
