import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';
export const UseGetChannelCertificateList = (
	id: number | undefined,
	params: any,
	tab: string | undefined
) => {
	return useQuery(
		['list', id, params, tab],
		async () => {
			try {
				if (
					id != undefined &&
					id != null &&
					id >= -1 &&
					tab === 'ChanneIntegration'
				) {
					const response = await client.get(
						//`/v2/channels/89/public-credentials`,
						`/v2/channels/${id}/public-credentials`,
						{ params }
					);
					return response.data;
				}
			} catch (error) {
				console.error('Error fetching certificate data:', error);
				throw error;
			}
		},
		{ staleTime: 10000 }
	);
};

export const useDownloadChannelCertificate = (
	id: number | undefined,
	params: any,
	tab: string | undefined
) => {
	return useQuery(
		['download', id, params, tab],
		async () => {
			const validator = { bucket_key: '' };
			console.log('CERTIFICATE DOWNLOAD PARAMS');
			console.log(params);
			try {
				if (
					JSON.stringify(params) !== JSON.stringify(validator) &&
					id != undefined &&
					id >= -1 &&
					tab === 'ChanneIntegration'
				) {
					const response = await client.get(
						`/v2/channels/${id}/public-credentials/download`,
						{ params }
					);
					return response.data.data;
				}
			} catch (error) {
				console.error('Error downloading certificate:', error);
				throw error;
			}
		},
		{ staleTime: 10000 }
	);
};

export const useGeneratePublicKey = (id: number | undefined) => {
	return useQuery(
		['download', id],
		async () => {
			if (id != undefined && id != null && id >= -1) {
				try {
					const response = await client.post(
						'/v2/channels/public-credentials/generate',
						{
							channelId: id, //89
							//channelId: 89, //89
						}
					);
					return response.data;
				} catch (error) {
					throw new Error('Error generating public certificate: ' + error);
				}
			}
		},
		{ staleTime: 12000 }
	);
};
