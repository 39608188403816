import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectField';
import BankSelect from 'components/Inputs/SelectField/Types/BankSelect/BankSelect/BankSelect';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { Control } from 'react-hook-form';
import { accountTypeOptions } from './const';
/**
 * @param
 * name - Used as the schema name
 * title - Title display
 * control - react-hook-form control
 * disable? (optional) -
 */
type Props = {
	name: string;
	title?: string;
	control?: any | Control<any>;
	disabled?: boolean;
};

const CollectionDepositoryAccountForm = ({
	control,
	disabled = false,
	name,
	title,
}: Props): JSX.Element => {
	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3}>
					<BankSelect
						label="Bank Name"
						placeholder="Select Bank Name"
						name={`${name}.bankId`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Bank Branch"
						placeholder="Bank Branch"
						name={`${name}.bankBranch`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Account Name"
						placeholder="Account Name"
						name={`${name}.acctName`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Account Number"
						placeholder="Account Number"
						name={`${name}.acctNo`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Account Type"
						placeholder="Select Account Type"
						name={`${name}.acctType`}
						control={control}
						disabled={disabled}
						options={accountTypeOptions}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default CollectionDepositoryAccountForm;
