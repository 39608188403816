import { noWhiteSpace } from 'utils/common';
import { common } from './common';
import * as yup from 'yup';

export type product_address = {
	location_base?: string;
	country_id?: string;
	address_category?: string;
	province_id?: string;
	city_id?: string;
	barangay_id?: string;
	building_no?: string;
	street?: string;
	zip_code_id?: string;
	region_id?: string;
	country_obj?: any;
	province_obj?: any;
	city_obj?: any;
	barangay_obj?: any;
	zip_code_obj?: any;
	region_obj?: any;
} & common;

export const product_address_schema = yup.object().shape({
	location_base: yup
		.string()
		.nullable()
		.oneOf(['LOCAL', 'INTERNATIONAL'])
		.required('Select Location Base'),
	country_id: yup
		.string()
		.nullable()
		.label('Country')
		.when('location_base', {
			is: 'INTERNATIONAL',
			then: (s) => s.required('Select Country'),
		}),
	address_category: yup
		.string()
		.nullable()
		.label('Address Category')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select an Address Category'),
		}),
	province_id: yup
		.string()
		.nullable()
		.label('Province')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select State/Province'),
		}),
	city_id: yup
		.string()
		.nullable()
		.label('Municipality/City')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Municipality/City'),
		}),
	barangay_id: yup
		.string()
		.nullable()
		.label('Barangay')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Barangay'),
		}),
	building_no: yup
		.string()
		.nullable()
		.label('Building Name/No.')
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-z|A-Z|0-9 ]{1,255}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		})
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required(),
		})
		.test(noWhiteSpace()),
	street: yup
		.string()
		.nullable()
		.label('Street')
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-z|A-Z|0-9 ]{1,255}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		})
		.test(noWhiteSpace()),
	zip_code_id: yup
		.string()
		.nullable()
		.label('Zip Code')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Zip Code'),
		}),
});
