import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce as _debounce } from 'lodash';

import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import ModalButton from 'components/Buttons/ModalButton/ModalButton';
import Grid from 'components/Grid/Grid';

import { ReactComponent as AuditTrailIcon } from 'assets/icons/ic-audit-trail.svg';

import { useToggle } from 'utils/hooks';
import { formatDate } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import transactionFilterSchema from 'utils/formSchemas/bfaAdminPortal/transactionFilter';

import TransactionAuditTrail from './TransactionAuditTrail';
import TransactionFilter from './TransactionFilter';
import { TransactionFilterOptions, TransactionHeaderProps } from './types';
import styles from './TransactionMgt.module.css';

const TransactionHeader: React.FC<TransactionHeaderProps> = ({
	handleFilterSubmit,
	onDownload,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const {
		value: isAuditTrailOpen,
		valueOn: openAuditTrail,
		valueOff: closeAuditTrail,
	} = useToggle();

	const AUDIT_TRAIL_COLUMNS = [
		{
			Header: 'Date and Time',
			id: 'createdDate',
			sortable: false,
			width: '24%',
			accessor: ({ createdDate }) => formatDate(createdDate),
		},
		{
			Header: 'Logged By',
			id: 'loggedBy',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Action',
			id: 'action',
			sortable: false,
			width: '25%',
		},
		{
			Header: 'Source IP',
			id: 'sourceIP',
			sortable: false,
			width: '15%',
		},
		{
			Header: 'Details',
			id: 'details',
			sortable: false,
			width: '20%',
		},
	];

	const initialValues = {
		bfaStatus: undefined,
		branchName: undefined,
		channelPartner: undefined,
		dateFrom: undefined,
		dateTo: undefined,
		partnerName: undefined,
		productType: undefined,
		status: undefined,
		tpaid: undefined,
	};

	const { getValues, resetField, trigger, watch, ...useFormParams } =
		useForm<TransactionFilterOptions>({
			mode: 'all',
			resolver: yupResolver(transactionFilterSchema),
			defaultValues: initialValues,
		});

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const allValues = getValues() || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...allValues, search: value });
			}
		}, 500),
		[]
	);

	// Retriggers the validation
	useEffect(() => {
		trigger('dateTo');
	}, [watch('dateFrom')]);

	useEffect(() => {
		trigger('dateFrom');
	}, [watch('dateTo')]);

	return (
		<>
			<Filter
				title="Transaction List"
				padding={'1.2vmax 0.7vmax 1.2vmax 0.7vmax'}
			>
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={12}
							of={12}
							align="end"
							gutters="xx-small"
						>
							<Grid container column size={6} of={12} align="end">
								<InputWithIcon
									onChange={handleSearch}
									placeholder="Search Transaction Ref No. and Account No."
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
								/>
							</Grid>
							<Grid
								container
								column
								size={2}
								of={10}
								className={styles.downloadContainer}
							>
								<DownloadButton
									onClick={() => {
										if (hasUserPermission('list.transaction.download')) {
											onDownload();
										}
									}}
									className={styles.downloadButton}
								/>
							</Grid>
							<Grid container column size={2} of={10} align="end">
								<ModalButton
									onClick={() => {
										if (hasUserPermission('transaction.audit-trail')) {
											openAuditTrail();
										}
									}}
									name="Audit&nbsp;Trail"
									icon={<AuditTrailIcon />}
									className={styles.auditTrailButton}
								/>
								{isAuditTrailOpen && (
									<TransactionAuditTrail
										title="Audit Trail"
										columns={AUDIT_TRAIL_COLUMNS}
										onClose={closeAuditTrail}
										isOpen={isAuditTrailOpen}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<TransactionFilter
						handleFilterSubmit={handleFilterSubmit}
						resetField={resetField}
						{...useFormParams}
					/>
				</FilterInner>
			</Filter>
		</>
	);
};

export default TransactionHeader;
