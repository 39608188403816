import styles from './Filter.module.css';
import { get as _get } from 'lodash';

type Props = {
	name: string;
	errors?: any;
};

const ErrorComponent: React.FC<Props> = ({ name, errors }) => {
	if (!errors) return null;
	const error = _get(errors, `${name}.message`, null);

	if (!error) return null;

	return <div className={styles.error}>{error}</div>;
};

export default ErrorComponent;
