import React, { ReactNode, useState } from 'react';
import cx from 'classnames';
import { Modal } from '@salesforce/design-system-react';

import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './ConfirmModalWithRemarks.module.css';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onCancel?: () => void;
	modalDisabled: boolean;
	confirmOnClick: any;
	remarksOnChange?: (e: any) => void;
	bodyHeader: string | ReactNode;
	bodySubHeader?: string | ReactNode;
	header: string | ReactNode;
	remarksSubLabel: string;
	remarksMaxLength?: number;
	size?: string;
	isReasonFormat?: boolean;
	placeholder?: string;
	statusHeader?: string | ReactNode;
	actionText?: string;
	blockedCharsRegex?: string;
	className?: any;
};

const ConfirmModalWithRemarks: React.FC<Props> = ({
	isOpen,
	onClose,
	onCancel,
	modalDisabled,
	confirmOnClick,
	remarksOnChange,
	bodyHeader,
	bodySubHeader,
	header,
	remarksSubLabel,
	remarksMaxLength = 250,
	size,
	isReasonFormat = false,
	placeholder,
	statusHeader,
	actionText,
	blockedCharsRegex,
	className = '',
}) => {
	const [error, setError] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(false);
	const [hasFocus, setHasFocus] = useState<boolean>(false);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			onRequestClose={() => {
				setError('');
				setIsValid(false);
				onClose();
				onCancel && onCancel();
			}}
			disable={modalDisabled}
			headerClassName={styles.modalHeaderContainer}
			containerClassName={cx(
				isReasonFormat ? styles.modalReasonContainer : styles.modalContainer,
				className
			)}
			contentClassName={styles.modal}
			heading={
				<h2
					className={
						isReasonFormat ? styles.modalReasonHeading : styles.modalHeading
					}
				>
					<div className={styles.textHeaderContainer}>
						<div>{header}</div>
						<div className={styles.statusHeader}>{statusHeader}</div>
					</div>
				</h2>
			}
			size={size}
			footer={
				<div className={styles.modalFooter}>
					<OutlineButton
						onClick={() => {
							onCancel && onCancel();
							setError('');
							setIsValid(false);
						}}
						className={styles.modalBtn}
					>
						Cancel
					</OutlineButton>
					<PrimaryButton
						disabled={!isValid}
						onClick={() => {
							confirmOnClick && confirmOnClick();
							onClose();
							setError('');
							setIsValid(false);
						}}
						className={styles.modalBtn}
					>
						{isReasonFormat ? actionText : 'Confirm'}
					</PrimaryButton>
				</div>
			}
		>
			<div className={styles.modalBodyContainer}>
				{(bodyHeader || bodySubHeader) && (
					<div className={styles.modalBodyHeader}>
						{bodyHeader && <div>{bodyHeader}</div>}
						{bodySubHeader && <div>{bodySubHeader}</div>}
					</div>
				)}
				<div className={styles.modalBodyContent}>
					<div className="slds-form-element__control">
						<label className="slds-form-element__label">
							{isReasonFormat ? (
								<div
									className={cx(
										isReasonFormat && styles.modalReasonBodyContentlabel
									)}
								>
									Reason:
									<abbr className="slds-required" title="required">
										*
									</abbr>
								</div>
							) : (
								<div className={styles.modalRemarkslabel}>
									<abbr className="slds-required" title="required">
										*
									</abbr>
									Remarks
								</div>
							)}
						</label>
						<>
							<textarea
								placeholder={placeholder || ''}
								name="remarks"
								className={cx(
									'slds-textarea',
									error.length > 0 && hasFocus
										? styles.errorRemarks
										: styles.modalRemarks
								)}
								maxLength={remarksMaxLength ? remarksMaxLength * 2 : 2000}
								onChange={(e) => {
									const remarks = e?.target.value;
									const regex =
										blockedCharsRegex && new RegExp(blockedCharsRegex);

									remarksOnChange && remarksOnChange(remarks);

									const restrictedCharFound = regex && remarks.match(regex);

									if (remarks)
										if (remarks.length > remarksMaxLength) {
											setError(
												`You can only input max ${remarksMaxLength} characters.`
											);
											setIsValid(false);
										} else if (
											restrictedCharFound &&
											restrictedCharFound.length > 0
										) {
											setError('Invalid Character(s)');
											setIsValid(false);
										} else {
											setError('');
											setIsValid(true);
										}

									if (!remarks.replace(/\s/g, '').length) {
										setError('');
										setIsValid(false);
									}
									e.preventDefault();
								}}
								onFocus={() => setHasFocus(true)}
							/>
							{error.length > 0 && (
								<div
									className={cx({
										'slds-has-error': !!error,
									})}
								>
									<div className={cx(styles.helper, 'slds-form-element__help')}>
										{error}
									</div>
								</div>
							)}
						</>
					</div>
					{!(error.length > 0) && (
						<em
							className={
								isReasonFormat
									? styles.modalReasonRemarksSubLabel
									: styles.modalRemarksSubLabel
							}
						>
							{remarksSubLabel}
						</em>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmModalWithRemarks;
