import { PHYSICAL } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import {
	BRANCH_CODE,
	BRANCH_NAME,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE_ID,
	CIGNAL_TELLER_CODE,
	MACHINE_LOCATION,
	MERALCO_PAYMENT_CENTER_CODE,
	PLDT_TELLER_CODE,
	POS_TYPE,
	SETTLEMENT_SETUP,
	STORE_FORMAT,
	STORE_TYPE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import { REMARKS_CHANGES } from 'containers/ChannelManagement/Drafts/constants';
import _ from 'lodash';
import { noWhiteSpace } from 'utils/common';
import yup, { defaultOnlyAlphaChar } from 'utils/formSchemas/common';

export const branchBasicInformation = yup.object({
	[CHANNEL_ACCOUNT_NAME]: yup.string(),
	[CHANNEL_ACCOUNT_CODE]: yup.string(),
	[BRANCH_NAME]: yup
		.string()
		.required()
		.label(_.startCase(BRANCH_NAME))
		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[a-zA-Z0-9 ]*$/, {
			message: defaultOnlyAlphaChar,
		})
		.test(noWhiteSpace()),
	[BRANCH_CODE]: yup.string(),
	[CHANNEL_TRANSACTION_TYPE]: yup
		.string()
		.required('Select ' + _.startCase(CHANNEL_TRANSACTION_TYPE))
		.label(_.startCase(CHANNEL_TRANSACTION_TYPE)),
	[CHANNEL_TYPE_ID]: yup
		.string()
		.required('Select Channel Type')
		.label('Channel Type'),
	[POS_TYPE]: yup
		.string()
		.label('POS Type')
		.when(CHANNEL_TRANSACTION_TYPE, {
			is: PHYSICAL,
			then: (schema) => schema.required('Select POS Type'),
		}),
	[SETTLEMENT_SETUP]: yup.string(),
	[STORE_TYPE]: yup
		.string()
		.required('Select ' + _.startCase(STORE_TYPE))
		.label(_.startCase(STORE_TYPE)),
	[STORE_FORMAT]: yup
		.string()
		.required('Select ' + _.startCase(STORE_FORMAT))
		.label(_.startCase(STORE_FORMAT)),
	[MACHINE_LOCATION]: yup
		.string()
		.required('Select ' + _.startCase(MACHINE_LOCATION))
		.label(_.startCase(MACHINE_LOCATION)),
	[PLDT_TELLER_CODE]: yup
		.string()
		.max(3, 'You can only input a max of 3 alphanumeric characters.')
		.matches(/^[a-zA-Z0-9 ]*$/, {
			message: defaultOnlyAlphaChar,
		}),
	[CIGNAL_TELLER_CODE]: yup
		.string()
		.max(3, 'You can only input a max of 3 alphanumeric characters.')
		.matches(/^[a-zA-Z0-9 ]*$/, {
			message: defaultOnlyAlphaChar,
		}),
	[MERALCO_PAYMENT_CENTER_CODE]: yup
		.string()
		.required('Select ' + _.startCase(MERALCO_PAYMENT_CENTER_CODE))
		.label(_.startCase(MERALCO_PAYMENT_CENTER_CODE)),
	[REMARKS_CHANGES]: yup
		.string()
		.label('Remarks')
		.when(['$mode', '$isToValidateRemarksChanges'], {
			is: (mode: string, isRemarksRequired: boolean) =>
				isRemarksRequired && mode === 'EDIT',
			then: (schema) =>
				schema
					.required()
					.max(1000, 'You can only input a max of 1000 characters.')
					.test(noWhiteSpace()),
		}),
});
