import React, { useEffect, useState } from 'react';
import moment from 'moment';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SummaryCard from 'components/SummaryCards';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';

import { ReactComponent as ActiveUserIcon } from 'assets/icons/ic-active-users.svg';
import { ReactComponent as InactiveUserIcon } from 'assets/icons/ic-inactive-users.svg';
import { ReactComponent as TotalUserIcon } from 'assets/icons/ic-total-users.svg';

import client from 'helpers/ApiClient';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import { formatParams } from 'utils/common';
import {
	useErrorModal,
	useSuccessModal,
	useLoader,
	useSnackbar,
} from 'utils/hooks';

import UserHeader from './UserHeader';
import UserTable from './UserTable';

import { UserListResp, UsersFilterOptions } from './types';
import styles from './UserMgt.module.css';

const UserMgt: React.FC = () => {
	const [data, setData] = useState<UserListResp>();
	const [summaryCount, setSummaryCount] = useState<any>({});
	const [filterParams, setFilterParams] = useState<UsersFilterOptions>({});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
		doneBtnOnClick,
	} = useSuccessModal();

	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const { showSnackbar, closeSnackbar } = useSnackbar();

	const SUMMARY_DATA = [
		{
			label: 'Total Number of BFA Users',
			value: summaryCount?.total ? summaryCount?.total?.toLocaleString() : 0,
			icon: <TotalUserIcon />,
			color: '#f26122',
		},
		{
			label: 'Total Number of Active Users',
			value: summaryCount?.active ? summaryCount?.active?.toLocaleString() : 0,
			icon: <ActiveUserIcon />,
			color: '#4cb483',
		},
		{
			label: 'Total Number of Inactive Users',
			value: summaryCount?.inactive
				? summaryCount?.inactive?.toLocaleString()
				: 0,
			icon: <InactiveUserIcon />,
			color: '#bc224c',
		},
	];

	async function fetchUserData(values: UsersFilterOptions = {}) {
		const { page = 1, limit = 25, ...rest } = values;

		const result: any = await client.get('/v2/bfa-admin-portal/users/list', {
			params: { page, limit, ...rest },
		});

		return result.data;
	}

	const fetchUserList = async (params: UsersFilterOptions = {}) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const [result, summaryResult]: any = await Promise.all([
				fetchUserData(params),
				client.get('/v2/bfa-admin-portal/users/count'),
			]);

			if (result?.error) {
				throw new Error(result?.error?.message);
			} else {
				setData(result);
			}
			if (summaryResult?.error) {
				throw new Error(summaryResult?.error?.message);
			} else {
				setSummaryCount(summaryResult?.data);
			}
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchUserList(params)
			);
		} finally {
			hideLoading();
		}
	};

	const handleFilterSubmit = async (params: UsersFilterOptions = {}) => {
		const { limit, sort, sortBy } = filterParams;
		const newFilter = formatParams({ limit, sort, sortBy, ...params });
		setFilterParams(newFilter);
		fetchUserList(newFilter);
	};

	const handleDownload = async () => {
		const { page, pageSize, limit, ...downloadParams } = filterParams;

		hideErrorModal();
		showSnackbar('Your download will begin shortly', true);

		try {
			const result: any = await client.get(
				'/v2/bfa-admin-portal/users/list/download',
				{
					params: downloadParams,
				}
			);

			if (result?.error) {
				throw new Error(result?.error?.message);
			} else {
				closeSnackbar();
				showSnackbar('Your download has started');
				window.open(result?.data.data, '_self');
			}
		} catch (error) {
			closeSnackbar();
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while downloading the data.',
				null,
				() => handleDownload()
			);
		}
	};

	const handleSubmitStatus = async (values: any, reasons?: string) => {
		hideErrorModal();
		showLoadingMessage();

		const { username, status } = values;

		try {
			const result: any = await client.put(
				`/v2/bfa-admin-portal/users/${username}/${
					status === 'BLOCKED' ? 'unblock' : 'block'
				}`,
				{ reason: reasons }
			);
			if (result?.error) {
				throw new Error(result?.error?.message);
			}
			showSuccessMessage(
				<p>
					You {status !== 'BLOCKED' ? 'blocked' : 'unblocked'}{' '}
					<span className={styles.bodyHeaderEmphasis}>{username}</span>.
				</p>,
				() => {
					fetchUserList(filterParams);
					hideSuccessModal();
				}
			);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				<>
					A problem occurred while{' '}
					{status === 'BLOCKED' ? 'unblocking' : 'blocking'}{' '}
					<span className={styles.bodyHeaderEmphasis}>{username}</span>.
				</>,
				null,
				() => handleSubmitStatus(values)
			);
		} finally {
			hideLoading();
		}
	};

	const handleTableFetch = (params: UsersFilterOptions) => {
		const newFilter = formatParams({ ...filterParams, ...params });
		setFilterParams(newFilter);
		fetchUserList(newFilter);
	};

	useEffect(() => {
		if (hasUserPermission('list.user')) {
			fetchUserList();
		}
	}, []);

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<SummaryCard data={SUMMARY_DATA} />
			<UserHeader
				onSubmit={handleFilterSubmit}
				onDownload={handleDownload}
				showErrorMessage={showErrorMessage}
			/>
			<UserTable
				data={data}
				handleTableFetch={handleTableFetch}
				handleSubmitStatus={handleSubmitStatus}
			/>

			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => retryBtnOnClick.action()}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} className={styles.successModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
		</>
	);
};

export default UserMgt;
