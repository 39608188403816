import { useEffect } from 'react';
import { Modal } from '@salesforce/design-system-react';
import { setCurrentBillerTab } from 'redux/modules/products';
import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import { useState } from 'react';
import PanelPlaceholder from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/Placeholders/PanelPlaceholder/PanelPlaceholder';
import folderIcon from 'assets/icons/ic-folder.svg';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/ChannelAuditLogsModal.module.css';
import { channelLogsModalTabs } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/channel_logs_modal_tabs';
import ChannelAuditTableModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/ChannelAuditTableModal';
import { remappedTerminalDataHandler } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TerminalAuditDataHandler';
import { remappedBranchDataHandler } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/BranchAuditDataHandler';
import { remappedChannelDataHandler } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditDataHandler';
import cx from 'classnames';

type TabLabelProps = {
	label: string;
	done?: boolean;
	disabled?: boolean;
	action?: string;
};

export const TabLabel: React.FC<TabLabelProps> = ({
	label,
	done,
	disabled,
	action,
}) => {
	return (
		<div
			className={cx(
				styles.tabLabel,
				action !== 'VIEW' &&
					styles.tabLabel + ' ' + { [styles.tabDisabled]: disabled }
			)}
		>
			{label}
		</div>
	);
};

type Props = {
	action?: string;
	tabStatus?: TabStatus;
	activeType?: any;
	listId?: any;
	detailId?: any;
	data?: any;
	onClose?: () => void;
	isOpen: boolean;
	currentLogTab?: string;
	setCurrentBillerTab: (v?: string) => void;
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type Tabs = {
	primaryInformation?: any;
	contractDetails?: any;
	reports?: any;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

export type TabStatus = PartialRecord<keyof Tabs, TabStatusValue>;

const initTabStatus: TabStatus = {
	primaryInformation: { disabled: false },
	contractDetails: { disabled: false },
	reports: { disabled: false },
};

const ChannelBranchAuditPlaceholder: React.FC<{ title: string }> = ({
	title,
}) => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>{title}</div>
				<div className={styles.placeholderSubTitle}>
					There are no changes in this tab.
				</div>
			</div>
		</div>
	);
};

const ChannelAuditLogsModal: React.FC<Props> = ({
	tabStatus = initTabStatus,
	activeType,
	listId,
	detailId,
	data,
	currentLogTab,
	isOpen,
	onClose = () => {},
	...props
}) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [auditTab, setAuditTab] = useState('');

	function findTabLabel(selected: number): string {
		const idx = channelLogsModalTabs.findIndex((tab) => tab.index == selected);
		return channelLogsModalTabs[idx]?.label || '';
	}

	function findIndexName(selected: number): string {
		const idx = channelLogsModalTabs.findIndex((tab) => tab.index == selected);
		return channelLogsModalTabs[idx].name;
	}

	const panelPlaceholderProps = {
		title: auditTab || 'Primary Information',
		subtitle: 'There are no changes in this tab.',
		className: styles.tabMaxheightAndWidth,
	};
	const [showView, setShowView] = useState(false);

	useEffect(() => {
		const index = channelLogsModalTabs.find(
			(tab) => tab.name === currentLogTab
		);

		setShowView(true);
	}, [currentLogTab, props.action, showView]);

	useEffect(() => {
		if (!currentLogTab) return;

		const isViewMode = props.action === 'VIEW';

		if (!isViewMode) {
			setShowView(true);
			return;
		}

		setShowView(true);
	}, []);

	//CHANNEL TAB
	const channelApprovedServiceFeeData = remappedChannelDataHandler(
		data[0],
		'approvedServiceFee',
		'reportsField'
	);
	const channelRejectedServiceFeeData = remappedChannelDataHandler(
		data[0],
		'rejectedServiceFee',
		'reportsField'
	);
	const channelUpdatedData = remappedChannelDataHandler(
		data[0],
		'updated',
		'reportsField'
	);

	const channelDisabledProductData = remappedChannelDataHandler(
		data[0],
		'disabledProduct',
		'reportsField'
	);

	const channelEnabledProductData = remappedChannelDataHandler(
		data[0],
		'enabledProduct',
		'reportsField'
	);

	const channelDisabledPartnerData = remappedChannelDataHandler(
		data[0],
		'disabledPartner',
		'reportsField'
	);

	const channelEnabledPartnerData = remappedChannelDataHandler(
		data[0],
		'enabledPartner',
		'reportsField'
	);

	const channelRejectedNewData = remappedChannelDataHandler(
		data[0],
		'rejectedNewChannel',
		'reportsField'
	);

	const channelApprovedNewData = remappedChannelDataHandler(
		data[0],
		'approvedNewChannel',
		'reportsField'
	);

	const channelUpdatedNewData = remappedChannelDataHandler(
		data[0],
		'updatedNewChannel',
		'reportsField'
	);

	const channelStatusData = remappedChannelDataHandler(
		data[0],
		'status',
		'reportsField'
	);
	const primaryInfoData = remappedChannelDataHandler(
		data[0],
		'basicInfo',
		'reportsField'
	);
	const channelAddressData = remappedChannelDataHandler(
		data[0],
		'channelAddress',
		'reportsField'
	);
	const channelContactDetailsData = remappedChannelDataHandler(
		data[0],
		'channelContactDetails',
		'reportsField'
	);
	const channelOfficerData = remappedChannelDataHandler(
		data[0],
		'channelOfficer',
		'reportsField'
	);
	const channelContractDetailsData = remappedChannelDataHandler(
		data[0],
		'channelContractDetails',
		'reportsField'
	);
	const channelVatData = remappedChannelDataHandler(
		data[0],
		'channelVat',
		'reportsField'
	);
	const otherBusinessSectionData = remappedChannelDataHandler(
		data[0],
		'otherBusinessSection',
		'reportsField'
	);
	const channelCashCollectionData = remappedChannelDataHandler(
		data[0],
		'channelCashCollection',
		'reportsField'
	);
	const channelCheckCollectionData = remappedChannelDataHandler(
		data[0],
		'channelCheckCollection',
		'reportsField'
	);
	const channelAccreditationData = remappedChannelDataHandler(
		data[0],
		'channelAccreditation',
		'reportsField'
	);
	const channelTermsDurationData = remappedChannelDataHandler(
		data[0],
		'channelTermsDuration',
		'reportsField'
	);
	const channelReportsSftpData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'sftp'
	);
	const channelReportsSftpUcData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'sftp_uc'
	);
	const channelReportsSftpCsrData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'sftpCsr'
	);
	const channelReportsSmtpData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'smtp'
	);
	const channelReportsSmtpUcData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'smtp_uc'
	);
	const channelReportsSmtpCsrData = remappedChannelDataHandler(
		data[0],
		'channelReports',
		'smtpCsr'
	);
	const channelDisabledBranchData = remappedChannelDataHandler(
		data[0],
		'disabledBranch',
		'reportsField'
	);
	const channelEnabledBranchData = remappedChannelDataHandler(
		data[0],
		'enabledBranch',
		'reportsField'
	);
	const channelDisabledTerminalData = remappedChannelDataHandler(
		data[0],
		'disabledTerminal',
		'reportsField'
	);
	const channelEnabledTerminalData = remappedChannelDataHandler(
		data[0],
		'enabledTerminal',
		'reportsField'
	);

	//BRANCH TAB
	const branchStatusData = remappedBranchDataHandler(
		data[0],
		activeType,
		'status',
		'reportsField'
	);
	const branchBasicInfoData = remappedBranchDataHandler(
		data[0],
		activeType,
		'basicInfo',
		'reportsField'
	);
	const branchAddressData = remappedBranchDataHandler(
		data[0],
		activeType,
		'branchAddress',
		'reportsField'
	);
	const branchContactDetailsData = remappedBranchDataHandler(
		data[0],
		activeType,
		'branchContactDetails',
		'reportsField'
	);
	const branchOperatingScheduleData = remappedBranchDataHandler(
		data[0],
		activeType,
		'branchOperatingSchedule',
		'reportsField'
	);

	//TERMINAL TAB
	const terminalStatusData = remappedTerminalDataHandler(
		data[0],
		activeType,
		'status',
		'reportsField'
	);
	const terminalsData = remappedTerminalDataHandler(
		data[0],
		activeType,
		'terminals',
		'reportsField'
	);
	const terminalIntegrationData = remappedTerminalDataHandler(
		data[0],
		activeType,
		'integration',
		'reportsField'
	);

	const partnerColumnsForModal = () => [
		{
			Header: 'Partner',
			id: 'product',
			sortable: true,
			width: '17.5%',
		},
		{
			Header: 'Product Types',
			id: 'productType',
			sortable: false,
			width: '16.5%',
		},
		{
			Header: 'Branch',
			id: 'branchName',
			sortable: true,
			width: '16.5%',
		},
		{
			Header: 'TPAID',
			id: 'terminalName',
			sortable: true,
			width: '16.5%',
		},
		{
			Header: 'Old Value',
			id: 'old_value',
			sortable: false,
			width: '16.5%',
		},
		{
			Header: 'New Value',
			id: 'new_value',
			sortable: false,
			width: '16.5%',
		},
	];

	const productColumnsForModal = () => [
		{
			Header: 'Partner',
			id: 'productName',
			sortable: true,
			width: '17.5%',
		},
		{
			Header: 'Product Types',
			id: 'productType',
			sortable: false,
			width: '16.5%',
		},
		{
			Header: 'Branch',
			id: 'branchName',
			sortable: true,
			width: '16.5%',
		},
		{
			Header: 'TPAID',
			id: 'terminalName',
			sortable: true,
			width: '16.5%',
		},
		{
			Header: 'Old Value',
			id: 'oldValue',
			sortable: false,
			width: '16.5%',
		},
		{
			Header: 'New Value',
			id: 'newValue',
			sortable: false,
			width: '16.5%',
		},
	];
	return (
		<>
			{activeType &&
			activeType == 'channel' &&
			// (channelUpdatedData || channelDisabledData) ? (
			(channelDisabledProductData ||
				channelEnabledProductData ||
				channelDisabledPartnerData ||
				channelEnabledPartnerData ||
				channelDisabledTerminalData ||
				channelEnabledTerminalData ||
				channelDisabledBranchData ||
				channelEnabledBranchData) ? (
				<>
					<Modal
						size="medium"
						isOpen={isOpen}
						ariaHideApp={false}
						onRequestClose={onClose}
						contentClassName={styles.ChannelBranchAuditModal}
						appElement={document.getElementById('app')}
						containerClassName={styles.channelBranchAuditModalContainer}
						headerClassName={styles.channelBranchAuditModalHeaderContainer}
						heading={
							<div className={styles.header}>
								<div className={styles.headerLeftPanel}>
									<div className={styles.headerTitle}>View Logs</div>
								</div>
								<div className={styles.headerRightPanel}></div>
							</div>
						}
					>
						{/* {channelUpdatedData ? (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelUpdatedData}
								columns={updatedColumnsForModal}
							/>
						) : (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelDisabledData}
								columns={updatedColumnsForModal}
							/>
						)} */}
						{channelDisabledProductData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelDisabledProductData}
								columns={productColumnsForModal}
							/>
						)}

						{channelEnabledProductData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelEnabledProductData}
								columns={productColumnsForModal}
							/>
						)}

						{channelDisabledTerminalData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelDisabledTerminalData}
								columns={productColumnsForModal}
							/>
						)}

						{channelEnabledTerminalData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelEnabledTerminalData}
								columns={productColumnsForModal}
							/>
						)}

						{channelDisabledBranchData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelDisabledBranchData}
								columns={productColumnsForModal}
							/>
						)}

						{channelEnabledBranchData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelEnabledBranchData}
								columns={productColumnsForModal}
							/>
						)}

						{channelDisabledPartnerData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelDisabledPartnerData}
								columns={partnerColumnsForModal}
							/>
						)}

						{channelEnabledPartnerData && (
							<ChannelAuditTableModal
								isOpen={true}
								data={channelEnabledPartnerData}
								columns={partnerColumnsForModal}
							/>
						)}
					</Modal>
				</>
			) : (
				<>
					<Modal
						size="medium"
						isOpen={isOpen}
						ariaHideApp={false}
						onRequestClose={onClose}
						contentClassName={styles.ChannelBranchAuditModal}
						appElement={document.getElementById('app')}
						containerClassName={styles.channelBranchAuditModalContainer}
						headerClassName={styles.channelBranchAuditModalHeaderContainer}
						heading={
							<div className={styles.header}>
								<div className={styles.headerLeftPanel}>
									<div className={styles.headerTitle}>View Logs</div>
								</div>
								<div className={styles.headerRightPanel}></div>
							</div>
						}
					>
						{channelApprovedServiceFeeData &&
							!channelRejectedServiceFeeData &&
							!channelRejectedNewData &&
							!channelApprovedNewData &&
							!channelUpdatedNewData && (
								<ChannelAuditTableModal
									isOpen={true}
									data={channelApprovedServiceFeeData}
								/>
							)}
						{channelRejectedServiceFeeData &&
							!channelApprovedServiceFeeData &&
							!channelRejectedNewData &&
							!channelApprovedNewData &&
							!channelUpdatedNewData && (
								<ChannelAuditTableModal
									isOpen={true}
									data={channelRejectedServiceFeeData}
								/>
							)}
						{channelRejectedNewData &&
							!channelApprovedServiceFeeData &&
							!channelRejectedServiceFeeData &&
							!channelApprovedNewData &&
							!channelUpdatedNewData && (
								<ChannelAuditTableModal
									isOpen={true}
									data={channelRejectedNewData}
								/>
							)}
						{channelApprovedNewData &&
							!channelApprovedServiceFeeData &&
							!channelRejectedServiceFeeData &&
							!channelRejectedNewData &&
							!channelUpdatedNewData && (
								<ChannelAuditTableModal
									isOpen={true}
									data={channelApprovedNewData}
								/>
							)}
						{channelUpdatedNewData &&
							!channelApprovedServiceFeeData &&
							!channelRejectedServiceFeeData &&
							!channelApprovedNewData &&
							!channelRejectedNewData && (
								<ChannelAuditTableModal
									isOpen={true}
									data={channelUpdatedNewData}
								/>
							)}
						{activeType && activeType == 'channel' ? (
							<Tabs
								className={styles.tabs}
								onSelect={(i: number) => {
									setSelectedTabIndex(i);
									const auditTab = findIndexName(i);

									setAuditTab(findTabLabel(i));
									setCurrentBillerTab(auditTab);
								}}
								selectedIndex={selectedTabIndex}
							>
								<TabsPanel
									className={styles.tabsLabel}
									label={
										<TabLabel
											label="Primary Information"
											disabled={!primaryInfoData && !channelAddressData}
											done={tabStatus.primaryInformation?.finished || true}
											action={props.action}
										/>
									}
								>
									{(showView && primaryInfoData) ||
									channelAddressData ||
									channelContactDetailsData ||
									channelOfficerData ||
									channelStatusData ? (
										<div className={cx(styles.content)}>
											{channelStatusData && (
												<>
													<h1>Channel Status</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchStatusData}
													/>
												</>
											)}
											{primaryInfoData && (
												<>
													<h1>Channel Basic Information</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={primaryInfoData}
													/>
												</>
											)}

											{channelAddressData && (
												<>
													<h1>Channel Address</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelAddressData}
													/>
												</>
											)}

											{channelContactDetailsData && (
												<>
													<h1>Channel Contact Details</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelContactDetailsData}
													/>
												</>
											)}

											{channelOfficerData && (
												<>
													<h1>Account Officer</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelOfficerData}
													/>
												</>
											)}
										</div>
									) : (
										<PanelPlaceholder {...panelPlaceholderProps} />
									)}
								</TabsPanel>

								<TabsPanel
									className={styles.tabsLabel}
									label={
										<TabLabel
											label="Contract Details"
											done={tabStatus.reports?.finished || true}
											action={props.action}
										/>
									}
									disabled={tabStatus.contractDetails?.disabled}
								>
									{(showView && channelContractDetailsData) ||
									channelVatData ||
									otherBusinessSectionData ||
									channelCashCollectionData ||
									channelCheckCollectionData ||
									channelAccreditationData ||
									channelTermsDurationData ? (
										<div className={cx(styles.content)}>
											{channelContractDetailsData && (
												<>
													<h1>
														Collection Service Agreement, Extension, and NDA
													</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelContractDetailsData}
													/>
												</>
											)}

											{channelCashCollectionData && (
												<>
													<h1>Cash Collection Depository Account</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelCashCollectionData}
													/>
												</>
											)}

											{channelCheckCollectionData && (
												<>
													<h1>Check Collection Depository Account</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelCheckCollectionData}
													/>
												</>
											)}

											{channelTermsDurationData && (
												<>
													<h1>Terms and Durations</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelTermsDurationData}
													/>
												</>
											)}

											{channelVatData && (
												<>
													<h1>Channel VAT</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelVatData}
													/>
												</>
											)}

											{otherBusinessSectionData && (
												<>
													<h1>Other Business Lines</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={otherBusinessSectionData}
													/>
												</>
											)}

											{channelAccreditationData && (
												<>
													<h1>Accreditation Requirements</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelAccreditationData}
													/>
												</>
											)}
										</div>
									) : (
										<PanelPlaceholder {...panelPlaceholderProps} />
									)}
								</TabsPanel>

								<TabsPanel
									className={styles.tabsLabel}
									label={
										<TabLabel
											label="Reports"
											done={tabStatus.reports?.finished || true}
											action={props.action}
										/>
									}
									disabled={tabStatus.reports?.disabled}
								>
									{(showView && channelReportsSftpData) ||
									channelReportsSmtpData ||
									channelReportsSftpUcData ||
									channelReportsSmtpUcData ||
									channelReportsSftpCsrData ||
									channelReportsSmtpCsrData ? (
										<div className={cx(styles.content)}>
											{channelReportsSftpData && (
												<>
													<h1>SFTP / FTP</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSftpData}
													/>
												</>
											)}

											{channelReportsSftpCsrData && (
												<>
													<h1>
														SFTP/FTP - Channel Collection Summary Report CCSR
														Configuration
													</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSftpCsrData}
													/>
												</>
											)}

											{channelReportsSftpUcData && (
												<>
													<h1>SFTP/FTP - Upload Configuration</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSftpUcData}
													/>
												</>
											)}

											{channelReportsSmtpData && (
												<>
													<h1>SMTP</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSmtpData}
													/>
												</>
											)}

											{channelReportsSmtpCsrData && (
												<>
													<h1>
														SMTP - Channel Collection Summary Report CCSR
														Configuration
													</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSmtpCsrData}
													/>
												</>
											)}

											{channelReportsSmtpUcData && (
												<>
													<h1>SMTP - Upload Configuration</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={channelReportsSmtpUcData}
													/>
												</>
											)}
										</div>
									) : (
										<PanelPlaceholder {...panelPlaceholderProps} />
									)}
								</TabsPanel>
							</Tabs>
						) : (
							<div className={cx(styles.content)}>
								{activeType && activeType == 'branch' ? (
									<>
										<div className={cx(styles.content)}>
											{branchStatusData && (
												<>
													<h1>Branch Status</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchStatusData}
													/>
												</>
											)}
											{branchBasicInfoData && (
												<>
													<h1>Branch Basic Information</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchBasicInfoData}
													/>
												</>
											)}

											{branchAddressData && (
												<>
													<h1>Branch Address</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchAddressData}
													/>
												</>
											)}

											{branchContactDetailsData && (
												<>
													<h1>Branch Contact Details</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchContactDetailsData}
													/>
												</>
											)}

											{branchOperatingScheduleData && (
												<>
													<h1>Operating Schedule Data</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={branchOperatingScheduleData}
													/>
												</>
											)}
										</div>
									</>
								) : activeType && activeType == 'terminal' ? (
									<>
										<div className={cx(styles.content)}>
											{terminalStatusData && (
												<>
													<h1>Terminal Status</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={terminalStatusData}
													/>
												</>
											)}
											{terminalsData && (
												<>
													<h1>Terminal Information</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={terminalsData}
													/>
												</>
											)}
											{terminalIntegrationData && (
												<>
													<h1>Integration</h1>
													<ChannelAuditTableModal
														isOpen={true}
														data={terminalIntegrationData}
													/>
												</>
											)}
										</div>
									</>
								) : (
									<div></div>
								)}
							</div>
						)}
					</Modal>
				</>
			)}
		</>
	);
};

export default ChannelAuditLogsModal;
