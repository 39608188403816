import { createSlice } from '@reduxjs/toolkit';

export type CommonSliceState = {
	loaderIsOpen: boolean;
	loaderMessage: string;
};

const initialState: CommonSliceState = {
	loaderIsOpen: false,
	loaderMessage: '',
};

const accessCheck = createSlice({
	name: 'common',
	initialState,
	reducers: {
		toggleLoader: (state) => {
			state.loaderIsOpen = !state.loaderIsOpen;
		},
		setLoader: (state, { payload }) => {
			state.loaderIsOpen = payload;
		},
		setLoaderMessage: (state, { payload }) => {
			state.loaderMessage = payload;
		},
	},
});

export const { toggleLoader, setLoaderMessage, setLoader } =
	accessCheck.actions;

export default accessCheck.reducer;
