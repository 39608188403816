import { Dropdown } from './commonTypes';

export const productStatuses: Dropdown[] = [
	{ label: 'Approved', value: 'APPROVED' },
	{ label: 'Under Development', value: 'UNDER_DEVELOPMENT' },
	{ label: 'For Activation', value: 'FOR_ACTIVATION' },
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Suspendend', value: 'SUSPENDED' },
	{ label: 'Deactivated', value: 'DEACTIVATED' },
	{ label: 'Terminated', value: 'TERMINATED' },
];
