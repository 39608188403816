import AccreditationRequirements from './AccreditationRequirements/AccreditationRequirements';
import CollectionDepositoryAccount from './CollectionDepositoryAccount/CollectionDepositoryAccount';
import CollectionServiceAgreement from './CollectionServiceAgreement/CollectionServiceAgreement';
import { ContractDetailsIdentifiers as contract } from './ContractDetailsConstants';
import OtherBusinessLines from './OtherBusinessLines/OtherBusinessLines';
import TermsAndDuration from './TermsAndDuration/TermsAndDuration';
import ValueAddedTax from './ValueAddedTax/ValueAddedTax';
import styles from './style.module.css';
import { ContractDetailsProps } from './types';

const ContractDetailsTab: React.FC<ContractDetailsProps> = ({
	disabled = false,
	isChannelDetails,
	channelId,
	mode,
	isAutoSave,
}) => {
	return (
		<div className={styles.container}>
			<CollectionServiceAgreement
				title={contract.CollectionServiceAgreement.title}
				name={contract.CollectionServiceAgreement.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<CollectionDepositoryAccount
				title={contract.CashCollection.title}
				name={contract.CashCollection.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<CollectionDepositoryAccount
				title={contract.CheckCollection.title}
				name={contract.CheckCollection.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<TermsAndDuration
				title={contract.TermsAndDuration.title}
				name={contract.TermsAndDuration.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<ValueAddedTax
				title={contract.ValueAddedTax.title}
				name={contract.ValueAddedTax.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<OtherBusinessLines
				title={contract.OtherBusinessLines.title}
				name={contract.OtherBusinessLines.controlName}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<AccreditationRequirements
				isChannelDetails={isChannelDetails}
				title={contract.AccreditationRequirements.title}
				name={contract.AccreditationRequirements.controlName}
				mode={mode}
				channelId={channelId}
				disabled={disabled}
				isAutoSave={isAutoSave}
			/>
		</div>
	);
};

export default ContractDetailsTab;
