import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import IconResolver from '../../../helpers/IconResolver';
import styles from './InputWithIcon.module.css';
import { Controller } from 'react-hook-form';

type Props = {
	icons: any;
	placeholder: string;
	className: string;
	name?: string;
	label?: string;
	id?: string;
	hasOptional?: boolean;
	[rest: string]: any;
};

function checkIfLeftOrRight(icons: any, flag: (value: any) => boolean) {
	return icons != null && icons.length === 1 && icons.some(flag);
}

const PlainInputWithIcon = ({
	icons,
	placeholder,
	className,
	error = {},
	label,
	id,
	name,
	onChange,
	field = {},
	ref,
	getOptionValue,
	hasOptional = false,
	...rest
}: any) => {
	return (
		<div className="slds-form-element">
			{label ? (
				<label
					className={cx(styles.selectLabel, 'slds-form-element__label')}
					htmlFor={name || id}
				>
					{label}
					{hasOptional && <span className={styles.optional}> (Optional)</span>}
				</label>
			) : null}
			<div
				className={cx('slds-form-element__control', {
					'slds-input-has-icon': icons != null,
					'slds-input-has-icon_right': checkIfLeftOrRight(
						icons,
						(value) => !value.isLeft
					),
					'slds-input-has-icon_left': checkIfLeftOrRight(
						icons,
						(value) => value.isLeft
					),
					'slds-input-has-icon_left-right': icons && icons.length === 2,
				})}
			>
				{icons &&
					icons.map((icon: any, index: number) => {
						return (
							<IconResolver
								key={`input-icon-${index}`}
								className={cx(
									styles.icon,
									'slds-input__icon',
									'slds-icon-text-default',
									{ 'slds-input__icon_right': !icon.isLeft },
									{ 'slds-input__icon_left': icon.isLeft },
									icon.className
								)}
								path={icon.path}
							/>
						);
					})}
				<input
					type="text"
					ref={ref}
					placeholder={placeholder}
					className={cx(
						'slds-input',
						styles.input,
						className,
						error?.message && styles.errorInputIcon
					)}
					onChange={(e) => {
						field?.onChange?.(e.target.value);
						onChange?.(getOptionValue?.(e.target.value) ?? e.target.value);
					}}
					{...rest}
				/>
				{error?.message && (
					<div
						className={cx({
							'slds-has-error': !!error?.message,
						})}
					>
						<div className={cx(styles.helper, 'slds-form-element__help')}>
							{error?.message}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
const InputWithIcon = React.forwardRef<HTMLInputElement, Props>(
	(
		{
			name = '',
			control,
			defaultValue,
			icons,
			placeholder,
			className,
			label,
			id,
			onChange,
			...rest
		},
		ref
	) => {
		const { getOptionValue = ({ value }) => value } = rest;
		if (name && control) {
			return (
				<Controller
					control={control}
					name={name}
					render={({ field, fieldState: { error, isDirty } }) => {
						return (
							<PlainInputWithIcon
								name={name}
								icons={icons}
								placeholder={placeholder}
								className={className}
								label={label}
								id={id}
								onChange={onChange}
								ref={ref}
								getOptionValue={getOptionValue}
								field={field}
								error={error}
								value={isDirty ? field.value : ''}
								{...rest}
							/>
						);
					}}
					defaultValue={defaultValue}
				/>
			);
		}

		return (
			<PlainInputWithIcon
				name={name}
				icons={icons}
				placeholder={placeholder}
				className={className}
				label={label}
				id={id}
				onChange={onChange}
				ref={ref}
				getOptionValue={getOptionValue}
				{...rest}
			/>
		);
	}
);

export default InputWithIcon;
