import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectFieldChannel';
import TextField from 'components/Inputs/TextField/TextFieldChannel';
import Section, { SectionRow } from 'components/Section/Section';
import { useFormContext } from 'react-hook-form';
import { VAT_TYPE_OPTIONS } from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';

type Props = {
	title: string;
	name: string;
	disabled: boolean;
};
const ValueAddedTax: React.FC<Props> = ({ title, name, disabled }) => {
	const { control, watch } = useFormContext<ContractDetailsType>();
	const { contractDetails } = watch();
	const { vatType } = contractDetails || {};

	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="VAT Type"
						name={`${name}.vatType`}
						control={control}
						disabled={disabled}
						placeholder="Select VAT Type"
						options={VAT_TYPE_OPTIONS}
					/>
				</Grid>
				{vatType === 'VATABLE' && (
					<Grid column size={1} of={3}>
						<TextField
							required
							control={control}
							name={`${name}.vatRate`}
							label="Vat Rate"
							disabled={true}
							placeholder="12%"
							isPercent
						/>
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default ValueAddedTax;
