import React, { ReactNode } from 'react';

import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from '../SuccessModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './SuccessModalContainer.module.css';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	successMessage?: ReactNode | string;
};

const SuccessModalContainer: React.FC<Props> = ({
	isOpen,
	onClose,
	successMessage,
}) => {
	return (
		<SuccessModal
			open={isOpen}
			onClose={onClose}
			className={styles.successModalContainer}
		>
			<SuccessModalBody>
				<SuccessText>
					<div className={styles.successHeader}>Success!</div>
					<div className={styles.successBody}>{successMessage}</div>
				</SuccessText>
			</SuccessModalBody>
			<SuccessModalActions>
				<PrimaryButton
					className={styles.successModalBtn}
					onClick={() => {
						onClose();
					}}
				>
					Done
				</PrimaryButton>
			</SuccessModalActions>
		</SuccessModal>
	);
};

export default SuccessModalContainer;
