import { filterArrayValues, handleArrayWithSpaceValues } from 'utils/filter';
import { isEmpty } from 'lodash';
import { decrypt } from 'utils/codeGenerator';
import { POST_TYPE_OPTIONS } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';

export const remappedBranchDataHandler = (
	data: any,
	type: string | undefined,
	result: string | undefined,
	reportsField: string | undefined
) => {
	if (type == 'branch') {
		if (!data?.auditDetail) {
			return;
		}
		const auditDetail = data?.auditDetail;
		if (!auditDetail) {
			return;
		}

		if (result === 'status') {
			if (!data?.auditDetail) {
				return;
			}
			const status = auditDetail.status;
			if (!status) {
				return;
			}

			const statusArray: any[] = [];
			statusArray.push({
				field_name: 'Status',
				old_values: status.oldValue,
				new_values: status.newValue,
			});

			if (statusArray.length > 0) {
				return statusArray;
			} else {
				return;
			}
		}

		if (result === 'basicInfo') {
			if (!data?.auditDetail?.branchPrimaryInfos) {
				return;
			}
			const branchPrimaryInfos = data.auditDetail?.branchPrimaryInfos;
			if (!branchPrimaryInfos) {
				return;
			}

			const branchPrimaryInfosArray: any[] = [];

			const fieldsMapping = {
				channelAccountName: 'Channel Account Name',
				channelTpaAccount: 'Channel Account Code',
				name: 'Branch Name',
				branchCode: 'Branch Code',
				channelTransactionType: 'Channel Transaction Type',
				channelType: 'Channel Type',
				settlementSetup: 'Settlement Setup',
				storeType: 'Store Type',
				storeFormat: 'Store Format',
				machineLocation: 'Machine Location',
				posType: 'POS Type',
				meralcoPaymentCenterCode: 'Meralco Payment Center Code',
				meralcoCode: 'code',
				meralcoName: 'name',
				meralcoDescription: 'description',
				pldtCode: 'PLDT Teller Code',
				cignalCode: 'Cignal Teller Code',
				//meralcoPaymentCenterId: 'Meralco Payment Center ID',
			};

			for (const [key, value] of Object.entries(fieldsMapping)) {
				const fieldValue = branchPrimaryInfos[key];
				// if (fieldValue) {
				// 	branchPrimaryInfosArray.push({
				// 		field_name: value,
				// 		old_values: fieldValue.oldValue,
				// 		new_values: fieldValue.newValue,
				// 	});
				// }
				if (
					fieldValue &&
					key !== 'meralcoCode' &&
					key !== 'meralcoName' &&
					key !== 'meralcoDescription'
				) {
					branchPrimaryInfosArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				} else if (key === 'meralcoCode' && branchPrimaryInfos.meralcoCode) {
					if (branchPrimaryInfos.meralcoCode.oldValue != '-') {
						branchPrimaryInfosArray.push({
							field_name: fieldsMapping.meralcoPaymentCenterCode,
							old_values:
								branchPrimaryInfos.meralcoCode.oldValue +
								': ' +
								branchPrimaryInfos.meralcoName.oldValue +
								' - ' +
								branchPrimaryInfos.meralcoDescription.oldValue,
							new_values:
								branchPrimaryInfos.meralcoCode.newValue +
								': ' +
								branchPrimaryInfos.meralcoName.newValue +
								' - ' +
								branchPrimaryInfos.meralcoDescription.newValue,
						});
					} else {
						branchPrimaryInfosArray.push({
							field_name: fieldsMapping.meralcoPaymentCenterCode,
							old_values: branchPrimaryInfos.meralcoCode.oldValue,
							new_values:
								branchPrimaryInfos.meralcoCode.newValue +
								': ' +
								branchPrimaryInfos.meralcoName.newValue +
								' - ' +
								branchPrimaryInfos.meralcoDescription.newValue,
						});
					}
				}
			}

			// if (branchPrimaryInfosArray.length > 0) {
			// 	return branchPrimaryInfosArray;
			// } else {
			// 	return;
			// }

			if (branchPrimaryInfosArray.length > 0) {
				branchPrimaryInfosArray.forEach((entry) => {
					if (entry.field_name === 'POS Type') {
						if (entry.new_values !== '-')
							entry.new_values = POST_TYPE_OPTIONS.find(
								(item) => item.value === entry.new_values
							)?.label;
						if (entry.old_values !== '-')
							entry.old_values = POST_TYPE_OPTIONS.find(
								(item) => item.value === entry.old_values
							)?.label;
					}
				});
				return branchPrimaryInfosArray;
			} else {
				return;
			}
		}

		if (result === 'branchAddress') {
			if (!data?.auditDetail?.branchPrimaryInfos?.branchAddresses) {
				return;
			}
			const branchAddresses =
				data.auditDetail?.branchPrimaryInfos?.branchAddresses;
			if (!branchAddresses || branchAddresses.length === 0) {
				return;
			}

			const branchAddressArray: any[] = [];

			for (const address of branchAddresses) {
				const addressFieldsMapping = {
					locationBase: 'Location Base',
					country: 'Country',
					area: 'Area',
					region: 'Region',
					street: 'Street',
					province: 'Province',
					city: 'City',
					barangay: 'Barangay',
					zipCode: 'Zip Code',

					buildingName: 'Building Name',
				};

				for (const [key, value] of Object.entries(addressFieldsMapping)) {
					const fieldValue = address[key];
					if (fieldValue) {
						branchAddressArray.push({
							field_name: value,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
				}
			}

			if (branchAddressArray.length > 0) {
				return branchAddressArray;
			} else {
				return;
			}
		}

		if (result === 'branchContactDetails') {
			if (!data?.auditDetail?.branchPrimaryInfos?.branchContactDetails) {
				return;
			}
			const branchContactDetails =
				data.auditDetail?.branchPrimaryInfos?.branchContactDetails;
			if (!branchContactDetails || branchContactDetails.length === 0) {
				return;
			}

			const branchContactDetailsArray: any[] = [];

			for (const contact of branchContactDetails) {
				const contactFieldsMapping = {
					name: 'Name',
					position: 'Position',
					department: 'Department',
					emailAddress: 'Email Address',
					telNo: 'Telephone Number',
					mobileNo: 'Mobile Number',
				};

				for (const [key, value] of Object.entries(contactFieldsMapping)) {
					const fieldValue = contact[key];
					if (fieldValue) {
						if (fieldValue.oldValue === '') {
							fieldValue.oldValue = '-';
						}
						if (
							(value === 'Telephone Number' ||
								value === 'Email Address' ||
								value === 'Mobile Number') &&
							(fieldValue.oldValue === '-' || fieldValue.oldValue === '') &&
							fieldValue.newValue.length > 0
						) {
							branchContactDetailsArray.push({
								field_name: value,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else if (
							(value === 'Telephone Number' ||
								value === 'Email Address' ||
								value === 'Mobile Number') &&
							fieldValue.oldValue.length > 1 &&
							fieldValue.newValue.length > 0
						) {
							branchContactDetailsArray.push({
								field_name: value,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else if (
							value !== 'Telephone Number' &&
							value !== 'Email Address' &&
							value !== 'Mobile Number'
						) {
							branchContactDetailsArray.push({
								field_name: value,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						}
					}
				}
			}

			// if (branchContactDetailsArray.length > 0) {
			// 	return branchContactDetailsArray;
			// } else {
			// 	return;
			// }

			if (branchContactDetailsArray.length > 0) {
				branchContactDetailsArray.forEach((entry) => {
					if (
						entry.field_name === 'Email Address' ||
						entry.field_name === 'Mobile Number' ||
						entry.field_name === 'Telephone Number'
					) {
						if (entry.new_values !== '-' && entry.new_values.length !== 0)
							entry.new_values = entry.new_values.split(',').join(', ');
						if (entry.old_values !== '-')
							entry.old_values = entry.old_values.split(',').join(', ');
					}
				});
				return branchContactDetailsArray;
			} else {
				return;
			}
		}

		if (result === 'branchOperatingSchedule') {
			if (!data?.auditDetail?.branchPrimaryInfos?.branchContactDetails) {
				return;
			}
			const branchOperatingSchedule =
				data.auditDetail?.branchPrimaryInfos?.branchOperatingSchedule;
			if (!branchOperatingSchedule || branchOperatingSchedule.length === 0) {
				return;
			}

			const convertTime = (time: string) => {
				if (typeof time !== 'string') {
					console.error('Invalid input: Expected a string');
					return time;
				}
				if (!/^(0?\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(time)) {
					console.error('Not a TIME Data: ', time);
					return time;
				}
				return time.slice(0, -3).padStart(5, '0');
			};

			const branchOperatingScheduleArray: any[] = [];

			for (const schedule of branchOperatingSchedule) {
				const scheduleFieldsMapping = {
					branchId: 'Branch ID',
					days: 'Days',
					startTime: 'Start Time',
					endTime: 'End Time',
					taxPayerType: 'Tax Payer Type',
					activationDate: 'Activation Date',
					depositConsolidation: 'Deposit Consolidation',
					remarks: 'Remarks',
				};

				for (const [key, value] of Object.entries(scheduleFieldsMapping)) {
					const fieldValue = schedule[key];
					if (fieldValue) {
						branchOperatingScheduleArray.push({
							field_name: value,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
				}
			}

			// if (branchOperatingScheduleArray.length > 0) {
			// 	return branchOperatingScheduleArray;
			// } else {
			// 	return;
			// }

			if (branchOperatingScheduleArray.length > 0) {
				branchOperatingScheduleArray.forEach((entry) => {
					if (
						entry.field_name === 'Start Time' ||
						entry.field_name === 'End Time'
					) {
						if (entry.new_values !== '-')
							entry.new_values = convertTime(entry.new_values);
						if (entry.old_values !== '-')
							entry.old_values = convertTime(entry.old_values);
					}
				});
				return branchOperatingScheduleArray;
			} else {
				return;
			}
		}
	}

	return;
};
