import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Input from '@salesforce/design-system-react/components/input';
import { SalesforceInputProps } from 'containers/ChannelManagement/Branch/BranchForm/SalesforceReactHookFormFields/SalesforceProps';
import { Controller, ControllerProps } from 'react-hook-form';

export type SalesforceReactHookFormInputProps = {
	controllerProps?: Omit<ControllerProps, 'render'>;
	salesforceInputProps?: SalesforceInputProps;
};

const SalesforceReactHookFormInput: React.FC<
	SalesforceReactHookFormInputProps
> = ({ controllerProps, salesforceInputProps }) => {
	return (
		<>
			<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
				{controllerProps ? (
					<Controller
						{...controllerProps}
						render={({
							field: { onChange, ref, value, name, onBlur },
							fieldState: { error },
						}) => (
							<Input
								value={value}
								name={name}
								errorText={error?.message}
								{...salesforceInputProps}
								onChange={(event, { value }) => {
									salesforceInputProps?.onChange?.(event, value);
									onChange(value);
								}}
								onBlur={(event) => {
									salesforceInputProps?.onBlur?.(event);
									onBlur();
								}}
								inputRef={(node) => {
									salesforceInputProps?.inputRef?.(node);
									ref(node);
								}}
							/>
						)}
					/>
				) : (
					<Input {...salesforceInputProps} />
				)}
			</IconSettings>
		</>
	);
};
export default SalesforceReactHookFormInput;
