import { Dropdown } from './commonTypes';
import HTTP from 'helpers/ApiClient';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { resolveValue } from 'utils/common';

const industries: Dropdown[] = [
	{ label: 'Airlines', value: 'Airlines' },
	{ label: 'Internet/Cable TV', value: 'Internet/Cable TV' },
	{ label: 'Cash-in/Wallets', value: 'Cash-in/Wallets' },
	{ label: 'Charity', value: 'Charity' },
	{ label: 'Corporate Payments', value: 'Corporate Payments' },
	{ label: 'Credit Cards', value: 'Credit Cards' },
	{ label: 'Distribution', value: 'Distribution' },
	{ label: 'E-Commerce', value: 'E-Commerce' },
	{ label: 'Electricity', value: 'Electricity' },
	{ label: 'Government', value: 'Government' },
	{ label: 'Hospitality', value: 'Hospitality' },
	{ label: 'Insurance', value: 'Insurance' },
	{ label: 'Loans', value: 'Loans' },
	{ label: 'Memorial', value: 'Memorials' },
	{ label: 'Payment Gateway', value: 'Payment Gateway' },
	{ label: 'Real Estate', value: 'Real Estate' },
	{ label: 'Remittance', value: 'Remittance' },
	{ label: 'School', value: 'School' },
	{ label: 'Telecoms', value: 'Telecoms' },
	{ label: 'Toll', value: 'Toll' },
	{ label: 'Transportation', value: 'Transportation' },
	{ label: 'Water', value: 'Water' },
	{ label: 'Others', value: 'Others' },
];

async function getIndustries():
Promise<any[]> {
	const response = await HTTP.get('/v2/products/categories');
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch categories');
	return list;
}

export function useIndustryQuery(
	queryKey: 'categories' | string = 'categories',
	params: { page?: number; limit?: number },
	config?: UseQueryOptions<any[]>
): UseQueryResult<any[]> {
	return useQuery<any[]>(
		[queryKey, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => getIndustries(),
		config
	);
}

export default industries;
