import cx from 'classnames';
import { Modal } from '@salesforce/design-system-react';

import { useTablePagination } from 'utils/hooks';

import Table from 'components/CWSTable/Table';
import { UserAuditTrailListProp } from 'containers/BfaAdminPortal/UserMgt/types';

import styles from './AuditTrailList.module.css';

const AuditTrailList: React.FC<UserAuditTrailListProp> = ({
	columns,
	viewLogsColumn,
	title,
	onCloseViewLogs,
	isViewLogsOpen,
	auditTrailData,
	viewLogsData,
	hasDetails = false,
	hasViewLogsDetails = false,
	handleTableFetch,
}) => {
	const tablePagination = useTablePagination(
		handleTableFetch,
		auditTrailData?.meta
	);

	return (
		<>
			{title && <div className={cx(styles.title)}>{title}</div>}
			<Table
				columns={columns}
				data={auditTrailData?.data}
				isNotEvenRowColor={false}
				preHeader={null}
				modalIsOpen={false}
				scrollable={true}
				hasDetails={hasDetails}
				{...tablePagination}
				className={styles.auditTrailLogs}
			/>
			{isViewLogsOpen && (
				<Modal
					ariaHideApp={false}
					isOpen={isViewLogsOpen}
					onRequestClose={onCloseViewLogs}
					size="medium"
					align="top"
				>
					<div className={cx(styles.customModalHeader)}>View Logs</div>
					<Table
						columns={viewLogsColumn}
						data={viewLogsData?.data}
						showPagination={false}
						isNotEvenRowColor={false}
						preHeader={null}
						modalIsOpen={false}
						hasDetails={hasViewLogsDetails}
						className={styles.auditTrailViewLogs}
					/>
				</Modal>
			)}
		</>
	);
};

export default AuditTrailList;
