import { useState } from 'react';
import { isEmpty } from 'lodash';

import ActionCell from './ActionCell';
import ExpandableRow from './ExpandableRow';

import styles from './PartnerDetailsBody.module.css';

const HeadExpandableRow = ({
	data,
	toggleActiveValue,
	partnerId,
	channelId,
	setRefetchData,
	width,
}) => {
	const [expandState, setExpandState] = useState<string[]>([]);

	const getToggleValue = (status: string) => {
		return status === toggleActiveValue ? true : false;
	};

	const getStatusStyles = (status) => {
		if (status === 'ENABLED' || status === 'ACTIVE') {
			return styles.statusEnabled;
		} else if (status === 'DISABLED' || status === 'INACTIVE') {
			return styles.statusDisabled;
		} else if (status === 'CLOSED') {
			return styles.statusClosed;
		}
	};

	const handleExpandRow = (id) => {
		if (expandState?.includes(id)) {
			setExpandState((current) => current.filter((value) => value !== id));
		} else {
			setExpandState([...expandState, id]);
		}
	};

	return (
		<table
			className={styles.expandedSection}
			style={{
				position: 'relative',
				width: `${width - 2}px`,
				backgroundColor: '#dadfe2',
			}}
			key={`${partnerId}-${channelId}`}
		>
			<tbody>
				{data?.map((branch, id) => {
					return (
						<>
							<tr
								className={styles.expandedSectionRow}
								key={`${branch?.branch}${id}`}
							>
								<td
									className={styles.expandedSectionTd}
									key={id}
									style={{ width: `10%` }}
								>
									<button
										key={id}
										onClick={() => handleExpandRow(id)}
										disabled={isEmpty(branch?.tpadis)}
										className={styles.expandedButton}
									>
										<span
											className="material-symbols-outlined"
											style={{
												transform: `rotate(${
													expandState?.includes(id) ? 0 : 180
												}deg)`,
												transition: 'all 0.25s',
											}}
											key={id}
										>
											expand_less
										</span>
									</button>
								</td>
								<th
									className={styles.expandedSectionTdName}
									key={branch?.branch}
									style={{ width: `23%` }}
								>
									{branch?.branch}
								</th>
								<td
									className={getStatusStyles(branch?.status)}
									data-label={branch?.status}
									key={branch?.status}
									style={{ width: `30%` }}
								>
									{branch?.status === 'ACTIVE' ? 'Enabled' : 'Disabled'}
								</td>
								<td
									className={styles.expandedActionCell}
									data-label={id}
									key={`head-action-${id}`}
									style={{ width: `30%` }}
								>
									<div
										style={{
											marginLeft: '30%',
										}}
									>
										<ActionCell
											name={`head-action-${id}`}
											initialToggleValue={getToggleValue(branch?.status)}
											confirmModalContents={{
												header: 'Branch',
												bodySubHeader: 'Branch',
												bodyHeader: `${branch?.branch}`,
											}}
											isBodySubHeader
											isBodySubSubHeader
											partnerId={partnerId}
											channelId={channelId}
											branchId={branch?.branchId}
											setRefetchData={setRefetchData}
										/>
									</div>
								</td>
							</tr>
							{expandState?.includes(id) && (
								<tr
									style={{
										backgroundColor: '#F3FAFF',
									}}
									key={`${branch?.branch}${id + 1}`}
								>
									<td colSpan={4} key={id}>
										<table key={id}>
											<ExpandableRow
												branchName={branch?.branch}
												data={branch?.tpadis}
												toggleActiveValue="ACTIVE"
												isExpandedRow
												partnerId={partnerId}
												channelId={channelId}
												branchId={branch?.branchId}
												setRefetchData={setRefetchData}
											/>
										</table>
									</td>
								</tr>
							)}
						</>
					);
				})}
			</tbody>
		</table>
	);
};

export default HeadExpandableRow;
