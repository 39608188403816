import Checkbox from 'components/Checkbox/Checkbox';
import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import SelectField from 'components/Inputs/SelectField/SelectFieldChannel';
import TextField from 'components/Inputs/TextField/TextFieldChannel';
import TimeField from 'components/Inputs/TimeField/TimeField';
import Section, { SectionRow } from 'components/Section/Section';
import {
	DEPOSIT_CONSOLIDATION_OPTIONS,
	TAX_PAYER_TYPE_OPTIONS,
} from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/constants';
import { OperatingScheduleProps } from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/types';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

const OperatingSchedule: React.FC<OperatingScheduleProps> = ({
	setCurrentSection,
	setPrevSection,
	updateAutoSave,
	isDisabled,
}) => {
	const parentPathName = 'branchOperatingSchedule.schedule';
	const { control, setValue, clearErrors } = useFormContext<BranchType>();

	const { fields } = useFieldArray({
		control,
		name: parentPathName,
	});

	const schedule = useWatch({
		control,
		name: 'branchOperatingSchedule.schedule',
	});

	const controlledFields = fields.map((field, i) => ({
		...field,
		...schedule?.[i],
	}));

	const filter = controlledFields?.filter((item) => item.isSelected);
	let isNoSelectedDay = Boolean(filter?.length);
	isNoSelectedDay = !isNoSelectedDay;

	return (
		<>
			<Section
				onClick={() => {
					setCurrentSection((prevState) => {
						setPrevSection(prevState);
						setCurrentSection('OperatingSchedule');
					});

					updateAutoSave();
				}}
				title={
					<>
						<div>
							Operating Schedule<span style={{ color: 'red' }}>*</span>
						</div>
						{isNoSelectedDay && (
							<div className="slds-has-error">
								<div className={'slds-form-element__help'}>
									{'Select an operating schedule and set start and end time'}
								</div>
							</div>
						)}
					</>
				}
			>
				{controlledFields.map((field, index) => (
					<SectionRow key={field.id}>
						<Grid column size={2} of={12}>
							<Checkbox
								label={field.day}
								control={control}
								name={`${parentPathName}.${index}.isSelected`}
								disabled={isDisabled}
								onChange={() => {
									const startTime =
										`branchOperatingSchedule.schedule.${index}.startTime` as const;
									const endTime =
										`branchOperatingSchedule.schedule.${index}.endTime` as const;
									setValue(startTime, ' ', {
										shouldDirty: true,
									});
									clearErrors(startTime);
									setValue(endTime, ' ', {
										shouldDirty: true,
									});
									clearErrors(endTime);
								}}
							></Checkbox>
						</Grid>
						<Grid column size={2} of={12}>
							<TimeField
								label=""
								required
								control={control}
								name={`${parentPathName}.${index}.startTime`}
								placeholder="Start Time"
								disabled={isDisabled || !field.isSelected}
							/>
						</Grid>
						<div>to</div>
						<Grid column size={2} of={12}>
							<TimeField
								label={''}
								required
								control={control}
								name={`${parentPathName}.${index}.endTime`}
								placeholder="End Time"
								disabled={isDisabled || !field.isSelected}
							/>
						</Grid>
					</SectionRow>
				))}
				<br />
				<SectionRow>
					<Grid column size={4} of={12}>
						<SelectField
							label="Deposit Consolidation"
							name={`branchOperatingSchedule.depositConsolidation`}
							control={control}
							placeholder="Select Deposit Consolidation"
							options={DEPOSIT_CONSOLIDATION_OPTIONS}
							disabled={isDisabled}
						></SelectField>
					</Grid>
					<Grid column size={4} of={12}>
						<SelectField
							label="Tax Payer Type"
							name={`branchOperatingSchedule.taxPayerType`}
							control={control}
							placeholder="Select Tax Payer Type"
							required
							options={TAX_PAYER_TYPE_OPTIONS}
							disabled={isDisabled}
						/>
					</Grid>
					<Grid column size={4} of={12}>
						<DatePickerField
							label="Activation Date"
							name={`branchOperatingSchedule.activationDate`}
							control={control}
							required
							placeholder="MM/DD/YYYY"
							disabled={isDisabled}
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={12} of={12}>
						<TextField
							label="Remarks"
							name={`branchOperatingSchedule.remarks`}
							multiLine
							rows={5}
							control={control}
							disabled={isDisabled}
						/>
					</Grid>
				</SectionRow>
			</Section>
		</>
	);
};

export default OperatingSchedule;
