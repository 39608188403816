import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './ReportMaintenanceFilter.module.css';

import { useForm } from 'react-hook-form';

import Filter, { FilterInner } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import DropdownCheckbox from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import Label from 'components/Inputs/Label/Label';
import TextField from 'components/Inputs/TextField/TextField';
import {
	ReportMaintenaceFilterProps,
	ReportMaintenanceFilterParamProps,
} from './common/types';

const ReportMaintenanceFilter: React.FC<ReportMaintenaceFilterProps> = ({
	setFilterParams,
	frequencyOptions,
	frequencyTypeOptions,
	updatedByOptions,
	hasViewPermission,
	setIsSubmitted,
	resetVisibleData,
}) => {
	const [selectedFreqType, setSelectedFreqType] = useState<string[]>([]);
	const [selectedFrequency, setSelectedFrequency] = useState<number[]>([]);
	const [selectedLastUpdatedBy, setSelectedLastUpdatedBy] = useState<string[]>(
		[]
	);
	const initialValues = {
		createdAt: '',
		reportName: '',
		frequencyType: [],
		frequency: [],
		lastUpdatedBy: [],
		updatedAt: '',
	};

	const {
		getValues,
		control,
		reset,
		formState: { isDirty },
		setValue,
	} = useForm<ReportMaintenanceFilterParamProps>({
		mode: 'all',
		defaultValues: initialValues,
	});

	const handleClearDropDowns = () => {
		setSelectedFreqType([]);
		setSelectedFrequency([]);
		setSelectedLastUpdatedBy([]);
	};

	const onReset = () => {
		handleClearDropDowns();
		reset();
		resetVisibleData();
	};

	const onSubmit = () => {
		setFilterParams({
			...getValues(),
		});
		setIsSubmitted(true);
	};

	useEffect(() => {
		if (!isDirty) {
			handleClearDropDowns();
			reset();
			resetVisibleData();
		}
	}, [isDirty]);

	const handleSelectFreqType = ({ value }) => {
		const currentVal: string[] = getValues('frequencyType') || [];

		if (currentVal.indexOf(value) > -1) {
			const index = currentVal.indexOf(value);
			currentVal.splice(index, 1);
		} else {
			currentVal.push(value);
		}
		setValue('frequencyType', currentVal, { shouldDirty: true });
	};

	const handleSelectFrequency = ({ value }) => {
		const currentVal: number[] = getValues('frequency') || [];

		if (currentVal.indexOf(value) > -1) {
			const index = currentVal.indexOf(value);
			currentVal.splice(index, 1);
		} else {
			currentVal.push(value);
		}
		setValue('frequency', currentVal, { shouldDirty: true });
	};

	const handleSelectLastUpdatedBy = ({ value }) => {
		const currentVal: string[] = getValues('lastUpdatedBy') || [];

		if (currentVal.indexOf(value) > -1) {
			const index = currentVal.indexOf(value);
			currentVal.splice(index, 1);
		} else {
			currentVal.push(value);
		}
		setValue('lastUpdatedBy', currentVal, { shouldDirty: true });
	};

	const updatePlaceholder = (
		filterType: 'freqType' | 'frequency' | 'updatedBy',
		selectedValues: string[] | number[]
	): string | undefined => {
		if (selectedValues.length === 1) {
			return selectedValues[0].toString();
		} else if (selectedValues.length > 1) {
			return `${selectedValues.length} selected`;
		} else {
			if (filterType === 'freqType') {
				return 'Freq. Type';
			} else if (filterType === 'frequency') {
				return 'Frequency';
			} else {
				return 'Updated By';
			}
		}
	};

	const placeholderFrequencyType = updatePlaceholder(
		'freqType',
		selectedFreqType
	);

	const placeholderFrequency = updatePlaceholder(
		'frequency',
		selectedFrequency
	);

	const placeholderLastUpdatedBy = updatePlaceholder(
		'updatedBy',
		selectedLastUpdatedBy
	);

	return (
		<>
			<div className={styles.filterContainer}>
				<Filter title="">
					<FilterInner>
						<div
							className={cx(
								'slds-grid',
								'slds-grid_align-spread',
								'slds-grid_vertical-align-start',
								styles.filters
							)}
						>
							<div className={cx('slds-col', 'slds-size_2-of-12')}>
								<DatePickerField
									control={control}
									placeholder="MM/DD/YYYY"
									label="Date Created"
									name="createdAt"
								/>
							</div>
							<div
								className={cx(
									'slds-col',
									'slds-size_2-of-12',
									styles.textFieldLabel
								)}
							>
								<TextField
									label="Report Name"
									control={control}
									name="reportName"
								/>
							</div>
							<div
								className={cx(
									'slds-col',
									'slds-size_2-of-12',
									styles.multiSelectLabel
								)}
							>
								<DropdownCheckbox
									label="Frequency Type"
									placeholder={placeholderFrequencyType}
									options={frequencyTypeOptions}
									control={control}
									onChange={(v) => handleSelectFreqType(v)}
									referenceCheckedItems={selectedFreqType}
									setReferenceCheckedItems={setSelectedFreqType}
								/>
							</div>
							<div
								className={cx(
									'slds-col',
									'slds-size_2-of-12',
									styles.multiSelectLabel
								)}
							>
								<DropdownCheckbox
									label="Frequency"
									placeholder={placeholderFrequency}
									options={frequencyOptions}
									control={control}
									onChange={(v) => handleSelectFrequency(v)}
									referenceCheckedItems={selectedFrequency}
									setReferenceCheckedItems={setSelectedFrequency}
								/>
							</div>
							<div className={cx('slds-col', 'slds-size_2-of-12')}>
								<DatePickerField
									control={control}
									placeholder="MM/DD/YYYY"
									label="Updated At"
									name="updatedAt"
								/>
							</div>
							<div
								className={cx(
									'slds-col',
									'slds-size_2-of-12',
									styles.multiSelectLabel
								)}
							>
								<DropdownCheckbox
									label="Updated By"
									placeholder={placeholderLastUpdatedBy}
									options={updatedByOptions}
									control={control}
									onChange={(v) => handleSelectLastUpdatedBy(v)}
									referenceCheckedItems={selectedLastUpdatedBy}
									setReferenceCheckedItems={setSelectedLastUpdatedBy}
								/>
							</div>

							<Grid
								container
								column
								size={4}
								of={12}
								className={styles.innerButtons}
							>
								<Grid
									column
									size={4}
									of={8}
									className={styles.clearFilterBtnContainer}
								>
									<OutlineButton fullWidth onClick={() => onReset()}>
										Clear
									</OutlineButton>
								</Grid>
								<Grid column size={4} of={8}>
									<PrimaryButton
										type="submit"
										disabled={!isDirty}
										onClick={() => {
											onSubmit && onSubmit();
										}}
										fullWidth
									>
										Apply
									</PrimaryButton>
								</Grid>
							</Grid>
						</div>
					</FilterInner>
				</Filter>
			</div>
		</>
	);
};

export default ReportMaintenanceFilter;
