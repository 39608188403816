import DownloadBtn from '../ReportTableRowButton/DownloadButton';
import ViewBtn from '../ReportTableRowButton/ViewButton';
import formatBytes from 'utils/fileSizeConverter';

import styles from './products.module.css';
import moment from 'moment';

const formatDate = (date: string) =>
	moment(date).format('MM/DD/YYYY hh:mm:ss A');
interface ICell {
	value: any;
	row: any;
	cell?: any;
}

export const TABLE_COLUMNS = [
	{
		Header: 'Date & Time Created',
		accessor: ({ createdAt }: { createdAt: string }): string =>
			formatDate(createdAt),
		id: 'createdAt',
		width: '22.5%',
	},
	{
		Header: 'File Name',
		id: 'fileName',
		width: '22.5%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.fileName}>
				{value.substr(0, value.lastIndexOf('.')) || value}
			</div>
		),
	},
	{
		Header: 'File Type',
		id: 'fileType',
		width: '22.5%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.fileType}>{value}</div>
		),
	},
	{
		Header: 'File Size',
		id: 'fileSize',
		width: '22.5%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.column}>{formatBytes(value)}</div>
		),
	},
	{
		Header: (): JSX.Element => (
			<div className={styles.actionHeader}>Actions</div>
		),
		id: 'actions',
		accessor: (values: any) => values,
		Cell: ({ value }: ICell): JSX.Element => {
			return (
				<div id="td-actions" className={styles.actions}>
					<DownloadBtn data={value} />
					<ViewBtn data={value} />
				</div>
			);
		},
	},
];
