import folderIcon from 'assets/icons/ic-folder.svg';
import cx from 'classnames';
import styles from './PanelPlaceholder.module.css';

type Props = {
	title: string;
	subtitle: string;
};

const PanelPlaceholder: React.FC<Props> = ({ title, subtitle }) => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>{title}</div>
				<div className={styles.placeholderSubTitle}>{subtitle}</div>
			</div>
		</div>
	);
};

export default PanelPlaceholder;
