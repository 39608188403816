import { Route } from 'react-router-dom';
import CashFlow from './CashFlow/CashFlow';
import WalletAdjustment from './WalletAdjustment/WalletAdjustment';
import WalletList from './WalletList/WalletList';
import WalletReplenishment from './WalletReplenishment/WalletReplenishment';

const WalletManagement: React.FC = () => {
	return (
		<>
			<Route
				exact
				path="/wallet-system/management/cash-flow"
				component={CashFlow}
			/>
			<Route
				exact
				path="/wallet-system/management/adjustment"
				component={WalletAdjustment}
			/>
			<Route
				exact
				path="/wallet-system/management/replenishment"
				component={WalletReplenishment}
			/>
			<Route
				exact
				path="/wallet-system/management/list"
				component={WalletList}
			/>
		</>
	);
};

export default WalletManagement;
