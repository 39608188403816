export const rmtProducts = [
	'Buy Load',
	'Remittance',
	'Cash-out / Pay-out',
	'Cash-in / Top-up',
	'e-CTPL',
	'Instasurance',
	'Bayad ATM',
	'Bayad Med-assist',
	'Bayad Travel',
];

type Products = {
	id: number;
	name: string;
	code: string;
	status: string;
	created_at: Date;
};

export const isRmtProduct = (
	productId: number,
	products: Products[]
): boolean => {
	let isRmtProduct = true;
	//filter products to contain rmt producst only
	const filteredProducts = products.filter((item) =>
		rmtProducts.includes(item.name)
	);
	//transform the products to contain id's only
	const rmpProductsIds = filteredProducts.map((item) => item.id);
	//check if active product is an rmt product
	isRmtProduct = rmpProductsIds.includes(productId);
	return isRmtProduct;
};
