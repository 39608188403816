import client from 'helpers/ApiClient';
import { useMutation, useQuery } from 'react-query';

export const useUpdateStatus = (params: {
	remarks: string;
	id?: number | string;
	idType: string | 'channels' | 'branches' | 'terminals';
	status:
		| 'APPROVED'
		| 'ACTIVE'
		| 'UNDER_DEVELOPMENT'
		| 'FOR_ACTIVATION'
		| 'SUSPENDED'
		| 'DEACTIVATED'
		| 'TERMINATED';
}) => {
	const transformParams = {
		remarks: params.remarks,
		status: params.status,
	};
	return useMutation(async () => {
		const response = await client.put(
			`/v2/${params.idType}/edit-status/${params.id}`,
			{ ...transformParams }
		);
		return response;
	});
};
