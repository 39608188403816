import { common } from './common';
import * as yup from 'yup';
import { DateValue } from 'components/Inputs/Datepicker/SelectDatepicker';
import {
	validateContactNumber,
	validateEmail,
	validateMobile,
	validateLandlineNumber,
	validateNumberLengthBetween,
} from 'utils/validation';
import { YupMessage } from 'types';
import {
	transformDate,
	transformDateFormat,
	validateDateSchema,
} from 'utils/formSchemas/common';
import moment from 'moment';
import { defaultMax255CharSpiel } from 'utils/formSchemas/common';
import { noWhiteSpace } from 'utils/common';

export type product_dpo_detail = {
	name?: string;
	email?: string[];
	contact_no?: string[];
	location_base?: string;
	country_id?: string;
	building_name?: string;
	street?: string;
	barangay_id?: string;
	city_id?: string;
	province_id?: string;
	zip_code_id?: string;
	region_id?: string;
	execution_date?: string | DateValue;
	termination_date?: string | DateValue;
	country_obj?: any;
	province_obj?: any;
	city_obj?: any;
	barangay_obj?: any;
	zip_code_obj?: any;
	region_obj?: any;
} & common;

export const product_dpo_detail_schema = yup.object().shape({
	name: yup
		.string()
		.nullable()
		.label('Name')
		.max(255)
		.matches(/^(?!\s+$).*/, {
			message: `Input Name`,
		})
		.matches(/^(?!\s+$)/, {
			message: `Input Name`,
		})
		.required('Input Name')
		.test(noWhiteSpace),
	email: yup
		.array()
		.nullable()
		.of(yup.string().nullable())
		.ensure()
		.label('Email Address')
		.min(1, ({ label }) => `Input ${label}`)
		.test(
			'isValidEmail',
			({ value }: YupMessage): string => {
				const count = value?.filter(
					(a: string) => !validateEmail(a || '')
				).length;
				if (!count) return '';
				if (count === 1) {
					return `${count} input is invalid email format`;
				}
				return `${count} inputs are invalid email format`;
			},
			(v) => !!v?.every((a) => validateEmail(a || ''))
		)
		.test('characterLimit', defaultMax255CharSpiel, (value) => {
			const totalCharacters = value?.join('').length || 0;
			return totalCharacters <= 255;
		}),
	contact_no: yup
		.array()
		.nullable()
		.of(yup.string().nullable())
		.ensure()
		.label('Contact Number')
		.min(1, ({ label }) => `Input ${label}`)
		.test(
			'isValidContactNumber',
			({ value }: YupMessage): string => {
				const count = value?.filter(
					(a: string) => !validateNumberLengthBetween(a, 10, 15)
				).length;
				if (count >= 1) {
					return `${count} ${
						count > 1 ? 'inputs are' : 'input is'
					} invalid contact number format`;
				}
				return '';
			},
			(v) => !!v?.every((a) => validateNumberLengthBetween(a || '', 10, 15))
		)
		.test('characterLimit', defaultMax255CharSpiel, (value) => {
			const totalCharacters = value?.join('').length || 0;
			return totalCharacters <= 255;
		}),
	location_base: yup
		.string()
		.nullable()
		.oneOf(['LOCAL', 'INTERNATIONAL'])
		.required('Select Location Base'),
	country_id: yup
		.string()
		.nullable()
		.label('Country')
		.when('location_base', {
			is: 'INTERNATIONAL',
			then: (s) => s.required('Select Country'),
		}),
	province_id: yup
		.string()
		.nullable()
		.label('Province')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select State/Province.'),
		}),
	city_id: yup
		.string()
		.nullable()
		.label('Municipality/City')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Municipality/City'),
		}),
	barangay_id: yup
		.string()
		.nullable()
		.label('Barangay')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Barangay'),
		}),
	building_no: yup
		.string()
		.nullable()
		.label('Building Name/No.')
		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[a-z|A-Z|0-9 ]{1,255}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		})
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required(),
		})
		.test(noWhiteSpace()),
	street: yup
		.string()
		.nullable()
		.label('Street')
		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[a-z|A-Z|0-9 ]{1,255}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		})
		.optional(),
	zip_code_id: yup
		.string()
		.nullable()
		.label('Zip Code')
		.when('location_base', {
			is: 'LOCAL',
			then: (s) => s.required('Select Zip Code'),
		}),
	execution_date: yup
		.mixed()
		.nullable()
		.label('Execution Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required()
		.meta({ placeholder: 'MM/DD/YYYY' }),

	termination_date: yup
		.mixed()
		.nullable()
		.label('Termination Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required(),
});
