import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Modal,
} from '@salesforce/design-system-react/module/components';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import cx from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationModal from 'components/ChannelConfirmationModal/ConfirmationModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import SuccessModal from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/SuccessModal';
import ChannelForm from 'containers/ChannelManagement/Channel/ChannelForm/ChannelForm';
import {
	ChannelModalProps,
	FormState,
	Status,
	TabStatus,
} from 'containers/ChannelManagement/Channel/ChannelModal/types';
import ErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/modules/channelList';
import { ReducerStateType } from 'redux/modules/reducers';
import { useToggle } from 'utils/hooks';
import {
	useCreateChannelModal,
	useResubmitRejectedChannel,
	useSaveChannelAutoSave,
	useSaveDraft,
	useUpdateChannelModal,
	useUpdateDraft,
} from '../ChannelForm/ChannelFormQuery';
import { CONTRACT_DETAILS_DEFAULT_VALUE } from '../ChannelForm/Tabs/ContractDetails/ContractDetailsConstants';
import {
	ContractDetailsSchema,
	ContractDetailsType,
} from '../ChannelForm/Tabs/ContractDetails/ContractDetailsSchemas';
import {
	PrimaryInfoType,
	objectSchema,
	primartyInfoInitlValues,
} from '../ChannelForm/Tabs/PrimaryInformation/PrimaryInformationSchema';
import {
	ReportsSchemaType,
	reportsDefaultValue,
	reportsSchema,
} from '../ChannelForm/Tabs/Reports/ReportsTabSchema';
import { FormModeType } from '../ChannelForm/types';
import {
	formatDraftPayload,
	formatPayload,
	formatPayloadSubmit,
	formatPayloadResubmit,
} from '../ChannelForm/utils';
import styles from './ChannelModal.module.css';
import { FixMeLater } from 'types';
import { AxiosError } from 'axios';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import StatusUpdate from '../ChannelStatusSelect/StatusUpdate';

const Footer: React.FC<{
	onClick?: () => void;
	handleDirectionInput;
	disableSave?: boolean | undefined;
	disabledButtons?: boolean;
	isPrimarySuccess?: boolean;
	onApproveClick?: () => void;
	onRejectClick?: () => void;
	mode: FormModeType;
	showApproveModal;
	showRejectModal;
	selectedIndex: number;
	hasApprovePermission?: boolean;
	hasRejectPermission?: boolean;
	channelStatus?: string;
	stateDisabledForm?: boolean;
}> = ({
	disableSave,
	onClick,
	showApproveModal,
	showRejectModal,
	handleDirectionInput,
	selectedIndex = 1,
	hasApprovePermission = false,
	hasRejectPermission = false,
	channelStatus = '',
	stateDisabledForm,
}) => {
	return (
		<>
			<div
				className={
					selectedIndex !== 0 ? styles.footerSpaceBetween : styles.footer
				}
			>
				{selectedIndex !== 0 && (
					<div className={styles.footerLeftPanel}>
						<>
							<PrimaryButton
								onClick={() => {
									handleDirectionInput('back');
								}}
								className={styles.btn}
							>
								Back
							</PrimaryButton>
						</>
					</div>
				)}
				<div className={styles.footerRightPanel}>
					{channelStatus === 'FOR_REVIEW' && (
						<>
							<Button
								className={cx(
									styles.btn,
									styles.btnReject,
									hasRejectPermission ? '' : styles.displayNone
								)}
								onClick={() => {
									showRejectModal();
								}}
							>
								<RejectIcon className={styles.btnRejectIcon} /> Reject
							</Button>

							<Button
								className={cx(
									styles.btn,
									styles.btnApprove,
									hasApprovePermission ? '' : styles.displayNone
								)}
								onClick={() => {
									showApproveModal();
								}}
							>
								<ApproveIcon className={styles.btnApproveIcon} /> Approve
							</Button>
						</>
					)}
					<>
						{selectedIndex !== 2 && (
							<PrimaryButton
								onClick={() => handleDirectionInput('next')}
								className={styles.btn}
							>
								Next
							</PrimaryButton>
						)}

						{(channelStatus === '' || channelStatus === 'REJECTED') &&
							!stateDisabledForm && (
								<PrimaryButton
									disabled={disableSave}
									onClick={() => {
										if (onClick) {
											onClick();
										}
									}}
									className={styles.btn}
								>
									Submit
								</PrimaryButton>
							)}
					</>
				</div>
			</div>
		</>
	);
};

const initialState = {
	isValid: false,
	isDirty: false,
};

export const ChannelModalContext = React.createContext<{
	state: FormState;
}>({ state: initialState });

const ChannelModal: React.FC<ChannelModalProps> = ({
	autoSaveValue,
	deleteChannelAutoSave,
	setIsAutoSave,
	isAutoSave,
	draftsData,
	approvalData,
	refetchAutoSave,
	open,
	onClose,
	disabledForm = false,
	mode,
	channelId = 0,
	showApproveModal,
	showRejectModal,
	hasApprovePermission,
	notAutosavedUpdatedAt,
	notAutosavedUpdatedBy,
	hasRejectPermission,
	origin,
	channelStatus = '',
}) => {
	const dispatch = useDispatch();

	const _userInfo = useSelector<ReducerStateType, FixMeLater>(
		(state) => state.userInfo
	);

	const [isSaveAsDraft, setIsSaveAsDraft] = useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [updatedAt, setUpdatedAt] = useState('');
	const [updatedBy, setUpdatedBy] = useState('');
	const [isValidating, setIsValidating] = useState(true);

	const [tabStatus, setTabStatus] = useState<TabStatus>({
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: true, finished: false },
		reports: { disabled: true, finished: false },
	});

	const status = useRef<Status | ''>('');
	const confirmationHeader = useRef<string | ReactElement>('');
	const confirmationMessage = useRef<string | ReactElement>('');
	const confirmationCancelBtnLabel = useRef<string>('');
	const confirmationConfirmBtnLabel = useRef<string>('');
	const errorHeader = useRef<string>('');
	const errorMessage = useRef<JSX.Element>(<></>);
	const errorButton = useRef<string>('');
	const successMessage = useRef<string | ReactElement>('');
	const successOkayLabel = useRef<string>('');

	const [stateDisabledForm, setStateDisabledForm] =
		useState<boolean>(disabledForm);

	let primaryData: PrimaryInfoType;
	let contractData: ContractDetailsType;
	let reportData: ReportsSchemaType;

	if (isAutoSave) {
		const primaryInfoData = {
			primaryInfo: autoSaveValue?.data?.channelPrimaryInfo,
		};

		primaryData = primaryInfoData;
		contractData = {
			contractDetails: autoSaveValue?.data?.channelContractDetails,
		};
		reportData = autoSaveValue?.data?.channelReports;
	} else if (approvalData) {
		primaryData = { primaryInfo: approvalData?.data?.channelPrimaryInfo };
		contractData = approvalData?.data?.channelContractDetails;
		reportData = approvalData?.data?.channelReports;
	} else if (draftsData) {
		primaryData = draftsData.primaryInfo;

		contractData = draftsData.contractDetails;
		reportData = draftsData.reports;
	} else {
		primaryData = primartyInfoInitlValues;
		contractData = CONTRACT_DETAILS_DEFAULT_VALUE;
		reportData = reportsDefaultValue;
	}

	const primaryForm = useForm<PrimaryInfoType>({
		mode: 'all',
		resolver: yupResolver(objectSchema),
		defaultValues: primaryData,
	});

	const contractForm = useForm<ContractDetailsType>({
		mode: 'all',
		resolver: yupResolver(ContractDetailsSchema),
		defaultValues: contractData,
	});

	const reportsForm = useForm<ReportsSchemaType>({
		mode: 'all',
		resolver: yupResolver(reportsSchema),
		defaultValues: reportData,
	});

	const updateCurrentRefs = (
		currentStatus: Status,
		currentConfirmationHeader: string | ReactElement,
		currentConfirmationMessage: string | ReactElement,
		currentConfirmationCancelBtnLabel: string,
		currentConfirmationConfirmBtnLabel: string
	) => {
		status.current = currentStatus;
		confirmationHeader.current = currentConfirmationHeader;
		confirmationMessage.current = currentConfirmationMessage;
		confirmationCancelBtnLabel.current = currentConfirmationCancelBtnLabel;
		confirmationConfirmBtnLabel.current = currentConfirmationConfirmBtnLabel;
	};

	const submitChannel = useCreateChannelModal();
	const updateAutoSave = useSaveChannelAutoSave();
	const updateChannel = useUpdateChannelModal(channelId);
	const resubmitChannel = useResubmitRejectedChannel(channelId);
	const saveDraft = useSaveDraft();
	const updateDraft = useUpdateDraft(channelId);

	const { control: primaryControl } = primaryForm;
	const { control: contractControl } = contractForm;
	const { control: reportsControl } = reportsForm;

	const primaryFormListener = useWatch({ control: primaryControl });
	const contractFormListener = useWatch({ control: contractControl });
	const otherBusinessLinesListener = useWatch({
		control: contractControl,
		name: 'contractDetails.otherBusinessLines',
	});
	const reportsListener = useWatch({ control: reportsControl });

	const handleAutoSave = () => {
		if (mode === FormModeType.ADD) {
			const payload = formatPayload(selectedIndex, {
				primaryForm,
				contractForm,
				reportsForm,
			});

			updateAutoSave.mutate(payload);

			refetchAutoSave();
		}
	};

	const handleDirectionInput = (direction: string) => {
		if (direction === 'next') {
			setSelectedIndex((prevState) => prevState + 1);
		} else if (direction === 'back') {
			setSelectedIndex((prevState) => prevState - 1);
		}
		handleAutoSave();
	};

	const updateUpdatedAt = () => {
		if (autoSaveValue && autoSaveValue.data.updatedAt && isAutoSave) {
			setUpdatedAt(
				moment
					.unix(autoSaveValue.data.updatedAt)
					.format('MM/DD/YYYY hh:mm:ss a') || ''
			);
			setUpdatedBy(autoSaveValue.data.username || '');
		} else if (mode === FormModeType.EDIT || mode === FormModeType.APPROVAL) {
			setUpdatedBy(approvalData.data.username);
			setUpdatedAt(
				moment(approvalData.data.updatedAt).format('MM/DD/YYYY hh:mm:ss a')
			);
		} else {
			setUpdatedBy(_userInfo.username);
			setUpdatedAt(moment().format('MM/DD/YYYY hh:mm:ss a'));
		}
	};

	const validateField = useCallback(
		debounce(async (isValidating: boolean) => {
			if (mode !== FormModeType.DRAFT) {
				updateUpdatedAt();
			} else if (mode === FormModeType.DRAFT) {
				setUpdatedBy(notAutosavedUpdatedBy);
				setUpdatedAt(
					moment(notAutosavedUpdatedAt).format('MM/DD/YYYY hh:mm:ss a')
				);
			}

			const primaryFormErrors = primaryForm.formState.errors;
			const contractFormErrors = contractForm.formState.errors;
			const contractDetailsErrors = reportsForm.formState.errors;

			if (
				isValidating &&
				(isAutoSave ||
					mode === FormModeType.DRAFT ||
					mode === FormModeType.EDIT ||
					mode === FormModeType.REJECTED ||
					mode === FormModeType.APPROVAL)
			) {
				await primaryForm.trigger();
				await contractForm.trigger();
				await reportsForm.trigger();

				primaryForm.clearErrors();
				contractForm.clearErrors();
				reportsForm.clearErrors();
			}

			const isPrimaryValid = primaryForm.formState.isValid;
			const isContractValid = contractForm.formState.isValid;
			const isReportsValid = reportsForm.formState.isValid;

			const newTabStatus = {
				primaryInformation: {
					disabled: !isPrimaryValid,
					finished: isPrimaryValid,
				},
				contractDetails: {
					disabled: !isContractValid,
					finished: isContractValid,
				},
				reports: { disabled: !isReportsValid, finished: isReportsValid },
			};

			if (newTabStatus !== tabStatus) {
				setTabStatus(newTabStatus);
			}

			setIsValidating(false);
		}, 2000),
		[]
	);

	useEffect(() => {
		validateField(isValidating);
	}, [
		primaryFormListener,
		contractFormListener,
		reportsListener,
		otherBusinessLinesListener,
	]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const {
		value: isShowErrorModal,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	useEffect(() => {
		if (isSaveAsDraft) {
			const saveAsDraft = async () => {
				const channelName = primaryForm.getValues(
					'primaryInfo.channelAccountName' // <- required
				);

				if (!channelName) {
					errorHeader.current = 'Incomplete Channel Details';
					errorMessage.current = (
						<>
							<p>Channel Account Name is required to save this as draft.</p>
							<p>Please input the Channel Account Name under the</p>
							<p>Primary Information tab.</p>
						</>
					);
					errorButton.current = 'Okay';
					showErrorModal();
					return;
				}

				dispatch(
					setLoader({
						isLoading: true,
						message: 'Please wait...',
					})
				);
				try {
					const payload = formatDraftPayload(0, {
						primaryForm,
						contractForm,
						reportsForm,
					});
					if (mode === FormModeType.ADD) {
						await saveDraft.mutateAsync(payload, {
							onSuccess: () => {
								successMessage.current = (
									<>
										<p>
											Your work was saved as draft. You may continue <br></br>
											your progress anytime by going to <b>Drafts</b> <br></br>
											and selecting the channel under the draft list.
										</p>
									</>
								);
								successOkayLabel.current = 'Done';
								showSuccessModal();
								deleteChannelAutoSave();
								dispatch(
									setLoader({
										isLoading: false,
										message: 'Please wait...',
									})
								);
							},
							onError: () => {
								errorHeader.current = 'Timeout Error!';
								errorMessage.current = (
									<>
										<p>A problem occurred while saving as draft.</p>
										<p>Please try again.</p>
									</>
								);
								dispatch(
									setLoader({
										isLoading: false,
										message: 'Please wait...',
									})
								);
								showErrorModal();
							},
						});
					} else {
						updateDraft.mutateAsync(payload, {
							onSuccess: () => {
								successMessage.current = (
									<>
										<p>
											Your work was saved as draft. You may continue <br></br>
											your progress anytime by going to <b>Drafts</b> <br></br>
											and selecting the channel under the draft list.
										</p>
									</>
								);
								successOkayLabel.current = 'Done';
								dispatch(
									setLoader({
										isLoading: false,
										message: 'Please wait...',
									})
								);
								showSuccessModal();
							},
							onError: () => {
								errorHeader.current = 'Timeout Error!';
								errorMessage.current = (
									<>
										<p>A problem occurred while saving as draft.</p>
										<p>Please try again.</p>
									</>
								);
								dispatch(
									setLoader({
										isLoading: false,
										message: 'Please wait...',
									})
								);
								showErrorModal();
							},
						});
					}
				} catch (error) {
					errorHeader.current = 'Timeout Error!';
					errorMessage.current = (
						<>
							<p>A problem occurred while saving as draft.</p>
							<p>Please try again.</p>
						</>
					);
					showErrorModal();
				}
				hideLoading();
			};

			saveAsDraft();
			setIsSaveAsDraft(false);
		}
	}, [
		primaryForm,
		contractForm,
		reportsForm,
		hideLoading,
		isSaveAsDraft,
		showErrorModal,
		showLoading,
		showSuccessModal,
		mode,
		saveDraft,
		updateDraft,
	]);

	// function dispatch(arg0: any) {
	// 	throw new Error('Function not implemented.');
	// }

	const handleConfirmBtnClick = async () => {
		hideErrorModal();
		hideConfirmationModal();
		if (status.current === 'DRAFTING' || status.current === 'EXITING') {
			setIsSaveAsDraft(true);
		}

		if (status.current === 'SUBMITTING') {
			dispatch(
				setLoader({
					isLoading: true,
					message: 'Please wait while we submit the channel for approval.',
				})
			);
			const payload = formatPayloadSubmit(0, {
				primaryForm,
				contractForm,
				reportsForm,
			});

			await submitChannel.mutateAsync(payload, {
				onSuccess: () => {
					dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));
					successMessage.current =
						'Your created channel request was submitted for approval.';
					successOkayLabel.current = 'Okay';

					deleteChannelAutoSave();
					showSuccessModal();
				},
				onError: () => {
					dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));
					errorHeader.current = 'Timeout Error!';
					errorMessage.current = (
						<>
							<p>A problem occurred while submitting your request.</p>
							<p>Please try again.</p>
						</>
					);
					showErrorModal();
				},
			});
			setIsSubmit(true);
		} else if (status.current === 'RESUBMITTING') {
			dispatch(
				setLoader({
					isLoading: true,
					message: 'Please wait while we submit the channel for approval.',
				})
			);

			const payload = formatPayloadResubmit(0, {
				primaryForm,
				contractForm,
				reportsForm,
			});

			resubmitChannel.mutateAsync(payload, {
				onSuccess: () => {
					dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));
					successMessage.current =
						'Your updated channel request was submitted for approval.';
					successOkayLabel.current = 'Okay';
					showSuccessModal();
				},
				onError: (error: any) => {
					if (error?.response.data.code == 101) {
						dispatch(
							setLoader({
								isLoading: false,
								message: 'Please wait...',
							})
						);
						return;
					} else if (error == undefined || error) {
						dispatch(
							setLoader({
								isLoading: false,
								message: 'Please wait...',
							})
						);
						errorHeader.current = 'Timeout Error!';
						errorMessage.current = (
							<>
								<p>A problem occurred while saving.</p>
								<p>Please try again.</p>
							</>
						);
						showErrorModal();
					}
				},
			});
		} else if (status.current === 'SUBMITDRAFT') {
			dispatch(
				setLoader({
					isLoading: true,
					message: 'Please wait while we submit the channel for approval.',
				})
			);
			const payload = formatPayloadSubmit(0, {
				primaryForm,
				contractForm,
				reportsForm,
			});

			await updateChannel.mutateAsync(payload, {
				onSuccess: () => {
					dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));
					successMessage.current =
						'Your created channel request was submitted for approval.';
					successOkayLabel.current = 'Okay';

					showSuccessModal();
				},
				onError: (error) => {
					dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));
					errorHeader.current = 'Timeout Error!';
					errorMessage.current = (
						<>
							<p>A problem occurred while submitting your request.</p>
							<p>Please try again.</p>
						</>
					);
					showErrorModal();
				},
			});
		}
	};

	const { hasEditDraftPermission, hasSaveDraftPermission } = useTerminal();

	const { touchedFields: primaryTouchedFields } = primaryForm.formState;
	const { touchedFields: contractTouchedFields } = contractForm.formState;
	const { touchedFields: reportsTouchedFields } = reportsForm.formState;
	const confirmMessageElement = (
		<>
			<div className={styles.bodyHeader}>
				<p>{confirmationMessage.current}</p>
			</div>
		</>
	);

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={async () => {
					const isPrimaryDirty = Object.keys(primaryTouchedFields).length > 0;
					const isContractDirty = Object.keys(contractTouchedFields).length > 0;
					const isReportsDirty = Object.keys(reportsTouchedFields).length > 0;

					if (origin === 'APPROVAL') {
						return onClose?.();
					}

					if (isPrimaryDirty || isContractDirty || isReportsDirty) {
						if (mode === FormModeType.ADD) {
							updateCurrentRefs(
								'EXITING',
								'Are you done?',
								`You can continue your progress by saving this as draft,
								otherwise you may need to create a new registration.
								Would you like to save as draft?`,
								'No',
								'Yes, save as draft'
							);
						} else {
							updateCurrentRefs(
								'EXITING',
								'Save as Draft',
								'Would you like to save as draft?',
								'No',
								'Yes, save as draft'
							);
						}
						showConfirmationModal();
					} else {
						setIsAutoSave?.(false);
						onClose?.();
					}
				}}
				headerClassName={styles.headerContainer}
				size="medium"
				containerClassName={styles.modalContainer}
				contentClassName={styles.modal}
				heading={
					<div className={styles.header}>
						<div className={styles.titleLeftPanel}>
							<div className={styles.titleTextContainer}>
								{mode === FormModeType.APPROVAL
									? 'Channel Approval'
									: 'Add New Channel'}
							</div>
							{(mode === FormModeType.APPROVAL ||
								mode === FormModeType.REJECTED) && (
								<div className={styles.channelStatus}>
									<p>Channel Status: </p>
									<div className={styles.statusPill}>
										<StatusUpdate disabled={true} value={channelStatus} />
									</div>
								</div>
							)}
						</div>
						<div className={styles.titleRightPanel}>
							{((channelStatus === 'REJECTED' && stateDisabledForm) ||
								channelStatus === '') && (
								<div className={styles.titleActionBtnContainer}>
									<OutlineButton
										onClick={() => {
											if (!hasSaveDraftPermission) {
												return showPermissionErrorModal();
											}
											if (stateDisabledForm && !hasEditDraftPermission) {
												return showPermissionErrorModal();
											}
											if (stateDisabledForm) {
												setStateDisabledForm(false);
												return;
											}
											updateCurrentRefs(
												'DRAFTING',
												'Save as Draft',
												'Would you like to save this as draft?',
												'Back',
												'Yes'
											);
											showConfirmationModal();
										}}
										className={styles.btn}
									>
										<SaveDraftIcon className={styles.btnIcon} />
										{stateDisabledForm
											? 'Edit Channel Details'
											: 'Save as Draft'}
									</OutlineButton>
								</div>
							)}
							<div className={styles.titleActionTextContainer}>
								<em className={styles.titleActionText}>
									{'Last updated at ' + updatedAt}
									{' by ' + updatedBy}
								</em>
							</div>
						</div>
					</div>
				}
				footer={
					<Footer
						onClick={async () => {
							const payload = formatPayloadSubmit(0, {
								primaryForm,
								contractForm,
								reportsForm,
							});

							if (
								mode === FormModeType.ADD ||
								mode === FormModeType.DRAFT ||
								mode === FormModeType.EDIT ||
								mode === FormModeType.REJECTED
							) {
								const channelName = primaryForm.getValues(
									'primaryInfo.channelAccountName' // <- required
								);

								const messagetitle = (
									<p className={styles.confirmTitle}>
										Submit Channel for Approval
									</p>
								);

								const message = (
									<p className={styles.confirmBody}>
										Are you sure you want to submit the created channel <br />
										<span style={{ fontWeight: 'bold' }}>
											({channelName})
										</span>{' '}
										for approval?
									</p>
								);

								let formMode: Status = 'SUBMITTING';

								if (mode === FormModeType.ADD) {
									formMode = 'SUBMITTING';
								}
								if (mode === FormModeType.DRAFT) {
									formMode = 'SUBMITDRAFT';
								}
								if (mode === FormModeType.REJECTED) {
									formMode = 'RESUBMITTING';
								}
								updateCurrentRefs(
									formMode,
									messagetitle,
									message,
									'Cancel',
									'Confirm'
								);
								showConfirmationModal();
							}
							// else {
							// 	updateChannel.mutate(payload);
							// }
						}}
						selectedIndex={selectedIndex}
						disabledButtons={stateDisabledForm}
						handleDirectionInput={handleDirectionInput}
						disableSave={Object.values(tabStatus).some(
							(section) => section.disabled === true
						)}
						isPrimarySuccess={tabStatus?.primaryInformation?.finished}
						mode={mode} // Pass the mode prop here
						showApproveModal={showApproveModal}
						showRejectModal={showRejectModal}
						hasApprovePermission={hasApprovePermission}
						hasRejectPermission={hasRejectPermission}
						channelStatus={channelStatus}
						stateDisabledForm={stateDisabledForm}
					/>
				}
			>
				<div className={styles.body}>
					<ChannelForm
						isAdd={true}
						handleAutoSave={handleAutoSave}
						selectedIndex={selectedIndex}
						setSelectedIndex={setSelectedIndex}
						channelId={channelId}
						primaryData={primaryData}
						primaryForm={primaryForm}
						contractForm={contractForm}
						reportsForm={reportsForm}
						isAutoSave={isAutoSave}
						autoSaveValue={autoSaveValue}
						contentClassName={styles.bodyContent}
						disabled={mode === FormModeType.APPROVAL || stateDisabledForm}
						mode={mode}
						tabStatus={tabStatus}
					/>
				</div>
			</Modal>
			;
			{isLoading && (
				<FullPageLoader open={isLoading} message={'Please wait...'} />
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading={confirmationHeader.current}
					message={confirmMessageElement}
					onClose={() => {
						hideConfirmationModal();
					}}
					onCancelBtnClick={() => {
						hideConfirmationModal();
						if (status.current === 'EXITING') {
							if (mode === FormModeType.ADD) {
								deleteChannelAutoSave();
							}
							setIsAutoSave?.(false);
							onClose?.();
							// reset();
						}
					}}
					onConfirmBtnClick={() => {
						if (
							confirmationConfirmBtnLabel.current === 'Yes, save as draft' &&
							!hasSaveDraftPermission
						) {
							return showPermissionErrorModal();
						}
						handleConfirmBtnClick();
					}}
					cancelBtnLabel={confirmationCancelBtnLabel.current}
					confirmBtnLabel={confirmationConfirmBtnLabel.current}
					containerClassName={styles.containerWidth}
				/>
			)}
			{isShowErrorModal && (
				<ErrorModal
					open={isShowErrorModal}
					onClose={hideErrorModal}
					errorHeader={errorHeader.current}
					errorMessage={errorMessage.current}
					errorButton={errorButton.current}
					onRetry={() => {
						handleConfirmBtnClick();

						if (errorButton.current.length > 0) {
							if (status.current === 'DRAFTING') {
								setIsSaveAsDraft(true);
							}
							if (status.current === 'SUBMITTING') {
								setIsSubmit(true);
							}
						}
					}}
				/>
			)}
			{isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={hideSuccessModal}
					successMessage={successMessage.current}
					onOkay={() => {
						hideSuccessModal();
						onClose?.();
					}}
					okayLabel={successOkayLabel.current}
				></SuccessModal>
			)}
			{hasPermission && (
				<AccessDeniedModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				/>
			)}
		</>
	);
};

export default ChannelModal;
