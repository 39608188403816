import yup, { transformCash } from 'utils/formSchemas/common';
import { collectionServiceAgreementSchema } from './CollectionServiceAgreement/CollectionServiceAgreementSchema';
import { collectionDepositoryAccountSchema } from 'components/CollectionDepositoryAccount/CollectionDepositoryAccountScema';
import { termsAndDurationSchema } from './TermsAndDuration/TermsAndDurationSchema';
import { valueAddedTaxSchema } from './ValueAddedTax/ValueAddedTaxSchema';
import { channelAccreditationSchema } from './AccreditationRequirements/AccreditationRequirementsSchema';
import { YupLab } from 'utils/yupLab';
import { object } from 'yup';
import moment from 'moment';

const wrapCollectionServiceAgreementSchema = new YupLab(
	collectionServiceAgreementSchema
);

const wrapCollectionSchema = new YupLab(collectionDepositoryAccountSchema);
const defaultCollectionValue =
	wrapCollectionSchema.objectSchema.getDefaultFromShape();
const wrapCollectionSchemaArray = new YupLab(
	yup
		.array()
		.of(wrapCollectionSchema.objectSchema)
		.default([defaultCollectionValue])
);

const wrapTermsAndDurationSchema = new YupLab(termsAndDurationSchema);

const wrapValueAddedTax = new YupLab(valueAddedTaxSchema);
const wrapChannelAccreditationSchema = new YupLab(channelAccreditationSchema);

export const objectSchema = yup.object({
	accreditationFee: yup
		.string()
		.required('Input Accreditation Fee')
		.nullable(true)
		.label('Accreditation Fee')
		.test(
			'positive-number',
			'Value should be 0 or any positive number.',
			(value) => {
				const convertValue = value === undefined || !value ? '' : String(value);
				if (parseFloat(convertValue) >= 0) {
					return true;
				} else if (convertValue == '') {
					return true;
				} else {
					return false;
				}
			}
		)
		.test(
			'maximum-100-million',
			'The maximum amount is 100M.',
			(value) => Number(value) <= 100000000
		),
	// .min(0.01, 'This field only accepts positive numeric values.')
	// .transform(transformCash),
	annualFee: yup
		.string()
		.required('Input Annual Fee')
		.nullable(true)
		// .default(null)
		.label('Annual Fee')
		.test(
			'positive-number',
			'Value should be 0 or any positive number.',
			(value) => {
				const convertValue = value === undefined || !value ? '' : String(value);
				if (parseFloat(convertValue) >= 0) {
					return true;
				} else if (convertValue == '') {
					return true;
				} else {
					return false;
				}
			}
		)
		.test(
			'maximum-100-million',
			'The maximum amount is 100M.',
			(value) => Number(value) <= 100000000
		),
	// .min(0.01, 'This field only accepts positive numeric values.')
	// .transform(transformCash),
	bondAmount: yup
		// .number()
		.string()
		.nullable(true)
		.label('Bond Amount')
		.test('greater-than-zero', 'Value should be greater than 0.', (value) => {
			const convertValue = value === undefined || !value ? '' : String(value);
			if (parseFloat(convertValue) > 0) {
				return true;
			} else if (convertValue == '') {
				return true;
			} else {
				return false;
			}
		})
		.test(
			'maximum-1-billion',
			'The maximum amount is 1B.',
			(value) => Number(value) <= 1000000000
		),
	bondEndDate: yup
		.string()
		.label('Bond End Date')
		.nullable(true)
		.default(null)
		.test('valid-date', 'Input date in this format: MM/DD/YYYY.', (value) => {
			if (!value || value === 'Invalid date') return true;
			return moment(value, 'MM/DD/YYYY', true).isValid();
		})
		.test(
			'is-later-than-bondEndDate',
			'Input Bond End Date later than Bond Start Date.',
			function (value) {
				const { bondStartDate } = this.parent;
				if (bondStartDate === 'Invalid date' || !bondStartDate || !value)
					return true;
				const startDate = moment(bondStartDate, 'MM/DD/YYYY');
				const endDate = moment(value, 'MM/DD/YYYY');
				return endDate.isAfter(startDate);
			}
		),
	bondStartDate: yup
		.string()
		.label('Bond Start Date')
		.nullable(true)
		.default(null)
		.test('valid-date', 'Input date in this format: MM/DD/YYYY.', (value) => {
			if (!value || value === 'Invalid date') return true;
			return moment(value, 'MM/DD/YYYY', true).isValid();
		})
		.test(
			'is-earlier-than-bondEndDate',
			'Input Bond Start Date earlier than Bond End Date.',
			function (value) {
				const { bondEndDate } = this.parent;
				if (bondEndDate === 'Invalid date' || !bondEndDate || !value)
					return true;
				const startDate = moment(value, 'MM/DD/YYYY');
				const endDate = moment(bondEndDate, 'MM/DD/YYYY');

				return startDate.isBefore(endDate);
			}
		),
	bondType: yup
		.string()
		.label('Bond Type')
		.required('Select Bond Type.')
		.nullable(true)
		.default(null),
	depositConsolidation: yup
		.string()
		.label('Deposit Consolidation')
		.required('Select Deposit Consolidation.')
		.nullable(true)
		.default(null),
	depositMode: yup.string().label('Deposit Mode').nullable(true).default(null),
	otherBusinessLines: yup
		.mixed()
		.required('Select Other Business Lines')
		.test('arrayOrString', 'Select Other Business Lines', (value) => {
			if (!value || value === '') return false;

			if (Array.isArray(value)) {
				return value.length > 0;
			} else if (typeof value === 'string') {
				return true;
			}
			return false;
		})
		.label('Business Lines'),
	settlementAgreement: yup
		.string()
		.label('Settlement Agreement')
		.required('Select a Settlement Agreement.')
		.nullable(true)
		.default(null),
	thresholdAmount: yup
		.string()
		.nullable(true)
		// .default(null)
		.label('Threshold Amount')
		.test('greater-than-zero', 'Value should be greater than 0.', (value) => {
			const convertValue = value === undefined || !value ? '' : String(value);
			if (parseFloat(convertValue) > 0) {
				return true;
			} else if (convertValue == '') {
				return true;
			} else {
				return false;
			}
		})
		.test(
			'maximum-1-billion',
			'The maximum amount is 1B.',
			(value) => Number(value) <= 1000000000
		),
	vatType: yup
		.string()
		.nullable(true)
		.label('VAT Type')
		.required('Select VAT Type')
		.nullable(true)
		.default(null),
	vatRate: yup.string().nullable(true).label('VAT Type'),
	channelCashCollection: wrapCollectionSchemaArray.arraySchema,
	channelCheckCollection: wrapCollectionSchemaArray.arraySchema,
	channelAccreditation: wrapChannelAccreditationSchema.schema,
	channelTermsDuration: wrapTermsAndDurationSchema.schema,
});

export const ContractDetailsSchema = yup.object({
	contractDetails: objectSchema,
});

export const warpContractDetailsSchema = new YupLab(ContractDetailsSchema);
// export const contractDetailsInitValues = warpContractDetailsSchema.schema.cast(
// 	{}
// );

export type ContractDetailsType = ReturnType<
	typeof warpContractDetailsSchema.schema.cast
>;
