import { DatePicker, Input, InputIcon } from '@salesforce/design-system-react';
import cx from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectFieldChannel';
import { DraftsFilterProps } from 'containers/ChannelManagement/Drafts/DraftsFilter/types';
import {
	BRANCH_NAME,
	CHANNEL_ACCOUNT_NAME,
	DATE_CREATED,
	DATE_PLACE_HOLDER,
	DATE_UPDATED,
	SEARCH,
	TPAID,
} from 'containers/ChannelManagement/Drafts/constants';
import _ from 'lodash';
import moment from 'moment';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './DraftsFilter.module.css';
import SalesforceReactHookFormInput from 'containers/ChannelManagement/Branch/BranchForm/SalesforceReactHookFormFields/SalesforceReactHookFormInput';

const DraftsFilter: React.FC<DraftsFilterProps> = ({
	onFilterClick,
	onClearAllFiltersClick,
	onSearchChange,
	searchAvailability,
	channelAccountNameIsLoading,
	channelAccountNameOptions,
	branchNameIsLoading,
	branchNameOptions,
	tpaidIsLoading,
	tpaidOptions,
}) => {
	const { control } = useFormContext();
	const inputDom = useRef<HTMLInputElement>();
	return (
		<>
			<Filter title="Drafts">
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={11}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={7} of={12}>
								<SalesforceReactHookFormInput
									controllerProps={{ name: SEARCH }}
									salesforceInputProps={{
										disabled: searchAvailability,
										iconLeft: (
											<InputIcon
												assistiveText={{
													icon: 'Search',
												}}
												name="search"
												category="utility"
											/>
										),
										placeholder: 'Search Channel Name / Branch / TPAID',
										onChange() {
											onSearchChange();
											console.log(inputDom.current);
										},
										inputRef(v) {
											inputDom.current = v;
										},
									}}
								></SalesforceReactHookFormInput>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<div
						className={cx(
							'slds-grid',
							'slds-grid_align-spread',
							'slds-grid_vertical-align-start',
							styles.filters
						)}
					>
						<div className={cx('slds-col', 'slds-size_2-of-12')}>
							<Controller
								name={DATE_CREATED}
								render={({
									field: { onChange, onBlur, value },
									fieldState: { error },
								}) => (
									<DatePicker
										formatter={(date) => {
											return date ? moment(date).format(DATE_PLACE_HOLDER) : '';
										}}
										onChange={(event, data) => {
											onChange(data.formattedDate);
										}}
										input={
											<Input
												label={_.startCase(DATE_CREATED)}
												placeholder={DATE_PLACE_HOLDER}
												errorText={error?.message}
												value={value}
												onBlur={onBlur}
											></Input>
										}
									/>
								)}
							/>
						</div>
						<div className={cx('slds-col', 'slds-size_2-of-12')}>
							<Controller
								name={DATE_UPDATED}
								render={({
									field: { onChange, onBlur, value },
									fieldState: { error },
								}) => (
									<DatePicker
										formatter={(date) => {
											return date ? moment(date).format(DATE_PLACE_HOLDER) : '';
										}}
										onChange={(event, data) => {
											onChange(data.formattedDate);
										}}
										input={
											<Input
												label={_.startCase(DATE_UPDATED)}
												placeholder={DATE_PLACE_HOLDER}
												errorText={error?.message}
												value={value}
												onBlur={onBlur}
											></Input>
										}
									/>
								)}
							/>
						</div>
						<div className={cx('slds-col', 'slds-size_3-of-12')}>
							<div className={styles.filterContainer}>
								<label
									className={cx(styles.selectLabel, 'slds-form-element__label')}
								>
									Channel Account Name
								</label>
								<SelectField
									name={CHANNEL_ACCOUNT_NAME}
									control={control}
									placeholder={'Channel Account Name'}
									isLoading={channelAccountNameIsLoading}
									options={channelAccountNameOptions}
								/>
							</div>
						</div>
						<div className={cx('slds-col', 'slds-size_3-of-12')}>
							<div className={styles.filterContainer}>
								<label
									className={cx(styles.selectLabel, 'slds-form-element__label')}
								>
									Branch Name
								</label>
								<SelectField
									control={control}
									name={BRANCH_NAME}
									placeholder={'Branch Name'}
									isLoading={branchNameIsLoading}
									options={branchNameOptions}
								/>
							</div>
						</div>
						<div className={cx('slds-col', 'slds-size_2-of-12')}>
							<div className={styles.filterContainer}>
								<label
									className={cx(styles.selectLabel, 'slds-form-element__label')}
								>
									TPAID
								</label>
								<SelectField
									control={control}
									name={TPAID}
									placeholder={'TPAID'}
									isLoading={tpaidIsLoading}
									options={tpaidOptions}
								/>
							</div>
						</div>
						<Grid
							container
							column
							size={5}
							of={12}
							verticalAlign="end"
							align="spread"
							className={styles.innerButtons}
						>
							<Grid column size={4} of={7}>
								<OutlineButton fullWidth onClick={onClearAllFiltersClick}>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={3} of={7}>
								<PrimaryButton
									disabled={false}
									onClick={onFilterClick}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</div>
				</FilterInner>
			</Filter>
		</>
	);
};

export default DraftsFilter;
