import { FormProvider, Path, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import yup, {
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';
import { FILE_FORMATS } from './CsrConfiguration';
import { csrColumnSchema } from './CsrConfiguration/CsrColumnCheckbox';
import SFTP from './SFTP';
import Divider from 'components/PartnerForm/Divider';
import SMTP from './SMTP';
import { SetStateAction, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	TProductState,
	setAutoSaveValues,
	setEditing,
	setReports,
	setSubmittingAll,
	updateValidForm,
} from 'redux/modules/products';
import { usePrevious, usePreviousDistinct } from 'react-use';
import client from 'helpers/ApiClient';
import { debounce, isEmpty } from 'lodash';
import { ExcludeAutoSave } from 'components/PartnerDetails/PartnerDetails';
import { useToggle } from '../../../../utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import { ValidationError, string } from 'yup';
import { LoaderState } from 'components/PartnerModal/PartnerModal';
import { omit as _omit } from 'lodash';
import styles from './style.module.css';

const DEFAULT_MAX_CHARS = 255;

export type TransferProtocolKey = 'sftp' | 'smtp';

export const COVERED_SCHEDULE = [
	{
		label: 'T+0 (Same Day)',
		value: 'T+0',
	},
	{
		label: 'T-1',
		value: 'T+1',
	},
	{
		label: 'T-2',
		value: 'T+2',
	},
	{
		label: 'T-3',
		value: 'T+3',
	},
];

const requiredStringWithMax = yup.string().max(DEFAULT_MAX_CHARS).required();

const transferProtocolInformation = yup.object({
	host: requiredStringWithMax.label('Host'),
	port: requiredStringWithMax.label('Port'),
	username: requiredStringWithMax.label('Username'),
	password: requiredStringWithMax.label('Password'),
	path: requiredStringWithMax.label('Path'),
});

const filenameFormat = yup
	.string()
	.label('File Name Format')
	.oneOf(FILE_FORMATS)
	.required(selectDefaultRequiredTemplate);

const uploadSchedule = yup
	.string()
	.label('Upload Schedule')
	.oneOf(['SAME', 'NEXT'])
	.required(selectDefaultRequiredTemplate);

const uploadFrequency = yup
	.number()
	.label('Upload Frequency')
	.typeError('Input numeric value from 1-25')
	.min(1, 'Input value greater than 0')
	.max(25, ({ max }) => `Input value less than or equal to ${max}`)
	.required()
	.default(1);

const checkTimeStampInterval = (generationTimeVal, timeOfUploadVal) => {
	if (generationTimeVal && timeOfUploadVal) {
		timeOfUploadVal = moment(timeOfUploadVal, 'HH:mm').format('hh:mm A');
		generationTimeVal = moment(generationTimeVal, 'HH:mm').format('hh:mm A');

		let timeDiff: number;
		const timeOfUploadDiff = moment(timeOfUploadVal, 'hh:mm A');
		const generationTimeDiff = moment(generationTimeVal, 'hh:mm A');
		if (timeOfUploadVal.includes('AM') && generationTimeVal.includes('PM')) {
			timeDiff = timeOfUploadDiff
				.add('1', 'day')
				.diff(generationTimeDiff, 'minutes');
		} else timeDiff = timeOfUploadDiff.diff(generationTimeDiff, 'minutes');
		return timeDiff < 30 ? false : true;
	}
	return true;
};

const uploadConfiguration = yup.object({
	filenameFormat,
	uploadSchedule,
	uploadFrequency,
	timestamps: yup
		.array()
		.of(
			yup.object({
				generateId: yup.number(),
				uploadId: yup.number(),
				generationTime: yup
					.string()
					.label('Generation Time')
					.required()
					.when('timeOfUpload', (timeOfUpload, s) => {
						return s.test(
							'generationTime-custom-validation',
							'Generation Time and Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
							(value) => checkTimeStampInterval(value, timeOfUpload)
						);
					})
					.test({
						name: 'generationTime-custom-validation',
						test: (value, context) => {
							let testResult: boolean | ValidationError = true;

							//-------------Unique Validation-------------
							//converted as 'any' because I need to use the 'from' variable
							//updating yup to latest version can access 'from' directly
							const ctx = context as any;
							//'from' is an array holding all the parents schema and value
							if (ctx.from !== undefined) {
								//access the timestamps array values
								const timestamps = ctx.from[1].value.timestamps;
								//get the index of the current iteration while validating each item in the array
								//other item in the array will be validated as well but whatever the result is ignored
								const idx = ctx.options.index as number;
								//remove the current item to avoid self comparing later
								const timestampsFiltered = timestamps?.filter(
									(item, index) => index !== idx
								);
								//transform array to contains array of codes only
								const codesArray = timestampsFiltered?.map(
									(item) => item.generationTime
								);
								//check if current value already exist
								if (codesArray?.includes(value as string)) {
									testResult = context.createError({
										message: 'Selected time already used.',
									});
								}
							}

							return testResult;
						},
					}),
				timeOfUpload: yup
					.string()
					.nullable()
					.label('Time of Upload')
					.required()
					.test({
						name: 'timeOfUpload-custom-validation',
						test: (value, context) => {
							let testResult: boolean | ValidationError = true;
							//-------------Time Interval Validation-------------
							const generationTime = context.parent.generationTime;
							const timeOfUpload = value;
							if (!checkTimeStampInterval(generationTime, timeOfUpload))
								testResult = context.createError({
									message:
										'Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
								});

							//-------------Unique Validation-------------
							//converted as 'any' because I need to use the 'from' variable
							//updating yup to latest version can access 'from' directly
							const ctx = context as any;
							//'from' is an array holding all the parents schema and value
							if (ctx.from !== undefined) {
								//access the timestamps array values
								const timestamps = ctx.from[1].value.timestamps;
								//get the index of the current iteration while validating each item in the array
								//other item in the array will be validated as well but whatever the result is ignored
								const idx = ctx.options.index as number;
								//remove the current item to avoid self comparing later
								const timestampsFiltered = timestamps?.filter(
									(item, index) => index !== idx
								);
								//transform array to contains array of codes only
								const codesArray = timestampsFiltered?.map(
									(item) => item.timeOfUpload
								);
								//check if current value already exist
								if (codesArray?.includes(value as string)) {
									testResult = context.createError({
										message: 'Selected time already used.',
									});
								}
							}

							return testResult;
						},
					}),
				coveredSchedule: yup
					.string()
					.label('Covered Schedule')
					.required(selectDefaultRequiredTemplate),
				coveredTime: yup.object({
					from: string()
						.label('Covered Time From')
						.required('Enter covered time'),
					to: string().label('Covered Time To').required('Enter covered time'),
				}),
			})
		)
		.min(1),
});

const csrConfiguration = uploadConfiguration.shape({
	columns: yup.array().of(csrColumnSchema),
});

const baseSchema = yup.object({
	csrConfiguration,
	uploadConfiguration,
});

const sftpSchema = yup.object({
	baseInfo: transferProtocolInformation,
	csrConfiguration,
	uploadConfiguration,
});

const smtpSchema = baseSchema.shape({
	senderEmail: yup.string().label('Sender Email Address').email(),
	receiverEmail: multipleEmailSchema.label('Receiver Email Address'),
});

export const schema = yup.object({
	sftp: sftpSchema,
	smtp: smtpSchema,
});

export type PartnerReportsFormData = yup.InferType<typeof schema>;
export const DEFAULT_SENDER_EMAIL = 'online@bayad.com';

export const mapReports = (v: any): any => {
	const formatSchedule = (arr: Array<any>) =>
		arr.map((v = {}) => ({
			pairId: v.pairId || null,
			generateId: v.generateId || null,
			uploadId: v.uploadId || null,
			covered_schedule: v?.coveredSchedule,
			covered_time_from: v?.coveredTime?.from,
			covered_time_to: v?.coveredTime?.to,
			generation_time: v?.generationTime,
			upload_time: v?.timeOfUpload,
		}));

	const ommitObj = (o) => _omit(o, ['reportEvents', 'reportColumns']);
	const {
		sftp: {
			baseInfo: sftpBaseInfo,
			csrConfiguration: {
				columns: sftpColumns = [],
				fileNameFormat: sftpCsrFileFormat,
				timestamps: sftpCsrTimestamps = [],
				...sftpCsrRest
			},
			uploadConfiguration: {
				fileNameFormat: sftpUploadFileFormat,
				timestamps: sftpUploadTimestamps = [],
				...sftpUploadRest
			},
			...sftpRest
		},
		smtp: {
			baseInfo: smtpBaseInfo,
			csrConfiguration: {
				columns: smtpColumns = [],
				fileNameFormat: smtpCsrFileFormat,
				timestamps: smtpCsrTimestamps = [],
				...smtpCsrRest
			},
			uploadConfiguration: {
				fileNameFormat: smtpUploadFileFormat,
				timestamps: smtpUploadTimestamps = [],
				...smtpUploadRest
			},
			receiverEmail,
			senderEmail,
		},

		...rest
	} = v;

	return {
		sftp: {
			...sftpBaseInfo,
			csrConfig: {
				csrColumns: sftpColumns.map(({ id }) => id),
				filename_format: sftpCsrFileFormat,
				schedule: formatSchedule(sftpCsrTimestamps),
				...ommitObj(sftpCsrRest),
			},
			uploadConfig: {
				filename_format: sftpUploadFileFormat,
				schedule: formatSchedule(sftpUploadTimestamps),
				...ommitObj(sftpUploadRest),
			},
			...sftpRest,
		},
		smtp: {
			csrConfig: {
				csrColumns: smtpColumns.map(({ id }) => id),
				filenameFormat: smtpCsrFileFormat,
				schedule: formatSchedule(smtpCsrTimestamps),
				...ommitObj(smtpCsrRest),
			},
			uploadConfig: {
				filenameFormat: smtpUploadFileFormat,
				schedule: formatSchedule(smtpUploadTimestamps),
				...ommitObj(smtpUploadRest),
			},
			receivers: receiverEmail,
			sender: senderEmail || DEFAULT_SENDER_EMAIL,
		},
		...rest,
	};
};
const ReportsTab: React.FC<{
	setReports: (v?: any) => void;
	disabled?: boolean;
	currentPartnerTab?: string;
	data?: any;
	action?: string;
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['autoSaveValues'];
	productId?: string | number;
	products: Array<any>;
	setLastAutoSaved?: SetStateAction<any>;
	onSubmit?: (values: any, saveType: string | LoaderState | null) => void;
	fromAutoSave: boolean;
}> = ({
	setReports,
	disabled,
	currentPartnerTab,
	data = {},
	action,
	autoSaveValues,
	products,
	productId,
	setLastAutoSaved,
	onSubmit,
	fromAutoSave,
}) => {
	const dispatch = useDispatch();
	const [loaderMessage, setLoaderMessage] = useState('');
	const statusSubmitting = useSelector<ReducerStateType>(
		(state) => state.products.isSubmittingAll
	);
	const drafting = useSelector<ReducerStateType>(
		(state) => state.products.drafting
	);
	const editing = useSelector<ReducerStateType>(
		(state) => state.products.editing
	);

	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const previousTab = usePreviousDistinct(currentPartnerTab);

	const defaultValues = {
		smtp: {
			csrConfiguration: {
				uploadFrequency: 1,
			},
			uploadConfiguration: {
				uploadFrequency: 1,
			},
			senderEmail: DEFAULT_SENDER_EMAIL,
		},
		sftp: {
			uploadConfiguration: {
				uploadFrequency: 1,
			},
			csrConfiguration: {
				uploadFrequency: 1,
			},
		},
		...data?.reports,
	};

	const form = useForm<PartnerReportsFormData>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues,
	});

	const {
		handleSubmit,
		getValues,
		formState: { isDirty, isValid, isValidating, touchedFields, errors },
		reset,
		clearErrors,
	} = form;

	useEffect(() => {
		if (drafting) {
			const data = structuredClone({ ...getValues(), isDirty, isValid });
			setReports(mapReports(data));
		}
	}, [drafting]);

	useEffect(() => {
		if (editing) {
			handleSubmit((v) => {
				setReports(mapReports({ ...v, isDirty, isValid }));
			})();
			if (!isValid) {
				const data = structuredClone({
					...getValues(),
					isDirty,
					isValid,
				});
				setReports(mapReports(data));
			}
			dispatch(setEditing(false));
		}
	}, [editing]);

	useEffect(() => {
		if (disabled) return;

		const {
			handleSubmit,
			clearErrors,
			getValues,
			formState: { isDirty, isValid },
		} = form;

		clearErrors();
		if (
			statusSubmitting ||
			previousTab === 'reports' ||
			form.formState.isValid
		) {
			handleSubmit((v) => {
				const validData = {
					...mapReports(v),
					isDirty,
					isValid,
				};

				setReports(validData);
			})();
			if (!isValid) {
				const data = structuredClone({
					...getValues(),
					isDirty,
					isValid,
				});

				!isValid && setReports(mapReports(data));
			}
			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab, form.formState.isValid, getValues]);

	const prev = usePrevious(currentPartnerTab);

	useEffect(() => {
		if (disabled || (action && ExcludeAutoSave.includes(action))) return;
		if (isDirty && prev === 'reports') {
			scoffoledData();
		}
	}, [currentPartnerTab]);

	useEffect(() => {
		if (
			disabled ||
			(action && ExcludeAutoSave.includes(action)) ||
			!fromAutoSave
		)
			return;

		window['reportsAuto'] = ({ checkIfDirty = false }) => {
			if (checkIfDirty) {
				if (isDirty) scoffoledData();
				return;
			}
			scoffoledData();
		};

		window['verifyReports'] = () => handleSubmit(() => {})();

		// get latest auto save values if has autosave in BE
		if (!isEmpty(autoSaveValues))
			client.get('v2/autosave/products').then(({ data }) => {
				const values = data.data.reports.originalValues;
				reset(values);
				setAutoSaveValues({});
			});
	}, []);

	const scoffoledData = async () => {
		onSubmit && (await onSubmit(null, LoaderState.ShowLoader));
		const { getValues: data } = form;

		const productType = products.find((p) => p.id == productId)?.code;
		const payload = mapReports(data());

		client
			.post(`/v2/autosave/products`, {
				reports: {
					...payload,
					originalValues: getValues(),
				},
				productType,
			})
			.then(({ data }) => {
				setLastAutoSaved({
					date: data?.data?.created_at,
					username: data?.data?.username,
				});
				onSubmit && onSubmit(null, LoaderState.HideLoader);
			});
	};

	const revalidateTimeStamps = async (keys) => {
		const { key1, key2, key3 } = keys;

		key3.forEach((timestampKeys) => {
			const timeOfUploadVal =
				getValues()[key1][key2].timestamps[timestampKeys].timeOfUpload;
			const generationTimeVal =
				getValues()[key1][key2].timestamps[timestampKeys].generationTime;
			const isIntervalValid = checkTimeStampInterval(
				generationTimeVal,
				timeOfUploadVal
			);

			const timeStampErrorString = JSON.stringify(
				errors[key1][key2].timestamps[timestampKeys] || ''
			);

			const hasSameTimeError = timeStampErrorString.includes(
				'Selected time already used.'
			);

			if (!hasSameTimeError && isIntervalValid) {
				clearErrors(
					`${key1}.${key2}.timestamps[${timestampKeys}]` as Path<PartnerReportsFormData>
				);
			}
		});

		hideLoader();
	};

	useEffect(() => {
		debounce(() => {
			dispatch(
				updateValidForm({
					formTab: 'reports',
					isValid,
					isDirty,
					isLoaded: true,
				})
			);
		}, 900)();
	}, [isValid, isDirty, dispatch]);

	//revalidate timestamps
	useEffect(() => {
		debounce(() => {
			const errorFieldsString = JSON.stringify(errors);
			if (!isValidating)
				if (
					Object.keys(errors).length > 0 &&
					(errorFieldsString.includes('timeOfUpload') ||
						errorFieldsString.includes('generationTime'))
				) {
					const touchedFieldsString = JSON.stringify(touchedFields);
					const key1 = touchedFieldsString
						.replace(/{|}|"/g, '')
						.replace(/:/g, '.')
						.split('.')[0];
					const key2 = touchedFieldsString
						.replace(/{|}|"/g, '')
						.replace(/:/g, '.')
						.split('.')[1];

					if (key1 && key2) {
						console.log(touchedFields, touchedFields[key1][key2], 'keys');
						const key3 = Object.keys(touchedFields[key1][key2].timestamps);
						setLoaderMessage(
							'Validating Report Timestamp (Generation Time/Time Of Upload)...'
						);
						showLoader();

						setTimeout(() => {
							revalidateTimeStamps({ key1, key2, key3 });
							hideLoader();
						}, 500);
					}
				}
		}, 1000)();
	}, [isValidating]);

	// For reseting the form when refetched (tagging as dirty false in PartnerDetails)
	const isTabRefetched = useSelector<ReducerStateType>(
		(state) => state.products.reports?.isRefetched
	);

	useEffect(() => {
		// reset form if refetched
		if (isTabRefetched) {
			reset(defaultValues, { keepDirty: false });
			updateValidForm({ formTab: 'reports', isDirty, isRefetched: false });
		}
	}, [isTabRefetched, reset]);

	return (
		<div>
			<div>
				{isLoading && (
					<FullPageLoader open={isLoading} message={loaderMessage} />
				)}
				<FormProvider {...form}>
					<SFTP disabled={disabled} />
					<Divider />
					<SMTP disabled={disabled} />
				</FormProvider>
			</div>
		</div>
	);
};

export default connect(
	(state: any) => ({
		currentPartnerTab: state.products.currentBillerTab,
		autoSaveValues: state.products.autoSaveValues,
		productId: state.sidebar.itemId,
		products: state.sidebar.products,
	}),
	{ setReports, setAutoSaveValues }
)(ReportsTab);
