import React, { useState, useEffect } from 'react';
import { AccordionItemProps } from '../../types';
import ToggleButton from 'containers/ChannelManagement/Channel/ChannelProducts/ProductTypeContainer/ProductScrollableList/ToggleButton';
import styles from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion.module.css';
import cx from 'classnames';
import IconResolver from 'helpers/IconResolver';
const AccordionItem: React.FC<AccordionItemProps> = ({
	index,
	subIndex,
	isSubExpanded,
	listItemClass,
	accordionBtnClass,
	expandedClass,
	subSectionItemClass,
	handleSubAccordionClick,
	subSection,
	toggleButtonClass,
	toggleBtnDivClass,
	hasEditPermission = false,
	section,
	handleToggleOnClick,
	handleToggleOnChange,
	accordionContent2Class,
	lineDeviderClass,
}) => {
	const [isLoading, setIsLoading] = useState(true);

	const onClickSubSection = (index: number, subIndex: number) => {
		handleSubAccordionClick(index, subIndex);
	};

	useEffect(() => {
		if (isSubExpanded) {
			setTimeout(() => {
				setIsLoading(false);
			}, 1600);
		}
	}, [isSubExpanded, subSection.terminals]);

	return (
		<li key={subIndex} className={listItemClass}>
			<button
				className={cx(accordionBtnClass, {
					[expandedClass]: isSubExpanded,
				})}
				onClick={() => onClickSubSection(index, subIndex)}
				aria-controls={`accordion-item-${index}-${subIndex}`}
				aria-expanded={isSubExpanded}
				role="switch"
			>
				<div className={subSectionItemClass}>
					<span className="slds-current-color slds-col">
						<IconResolver
							className={cx('slds-icon_xx-small', 'slds-p-left_xx-small')}
							path={`utility/${isSubExpanded ? 'chevronup' : 'chevrondown'}`}
						/>
					</span>
					<strong>{subSection.name}</strong>
				</div>
			</button>
			<div className={cx('slds-clearfix', toggleButtonClass)}>
				<div className={cx('slds-float_right', toggleBtnDivClass)}>
					<ToggleButton
						isDisabled={
							hasEditPermission
							//&& (section.isEnabled !== 'Enabled' ? true : false)
						}
						isChecked={subSection.enabled}
						id={subIndex}
						onClickBtn={() =>
							//for branch toggle
							handleToggleOnClick(index, subIndex, NaN, subSection, 'branch')
						}
						onChangeBtn={handleToggleOnChange}
					/>
				</div>
			</div>
			{isSubExpanded && (
				<div
					id={`accordion-item-${index}-${subIndex}`}
					className={accordionContent2Class}
				>
					{isLoading ? (
						<div key={subIndex} className={styles.noItem}>
							Please wait while we load the terminal list
						</div>
					) : (
						<>
							{subSection.terminals.length > 0 ? (
								subSection.terminals.map((subsubsection, subSubIndex) => (
									<div
										key={subSubIndex}
										style={{ marginLeft: '20px', marginTop: '5px' }}
									>
										{subsubsection.tpa_id}
										<div className={cx('slds-clearfix', toggleButtonClass)}>
											<div className="slds-float_right">
												<ToggleButton
													isDisabled={
														hasEditPermission
														// && !subSection.enabled || (section.isEnabled !== 'Enabled' ? true : false)
													}
													isChecked={subsubsection.enabled}
													id={subSubIndex}
													onClickBtn={() =>
														// for terminal toggle
														handleToggleOnClick(
															index,
															subIndex,
															subSubIndex,
															subsubsection,
															'terminal'
														)
													}
													onChangeBtn={handleToggleOnChange}
												/>
											</div>
										</div>
									</div>
								))
							) : (
								<div key={subIndex} className={styles.noItem}>
									No Terminals available
								</div>
							)}
						</>
					)}
				</div>
			)}
			<hr className={lineDeviderClass} />
		</li>
	);
};

export default AccordionItem;
