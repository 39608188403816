import yup from '../common';

const userFilterSchema = yup.object().shape({
	dateFrom: yup
		.date()
		.nullable()
		.max(yup.ref('dateTo'), `You’ve selected invalid date`)
		.max(new Date(), `You’ve selected invalid date`),
	dateTo: yup
		.date()
		.nullable()
		.min(yup.ref('dateFrom'), `You’ve selected invalid date`)
		.when('dateFrom', (dateFrom, schema) =>
			dateFrom ? schema.required() : schema.nullable()
		),
	username: yup.string().nullable().min(3, 'Minimum of 3 charaters only.'),
	branch: yup.string().nullable(),
	userType: yup.string().nullable(),
	status: yup.string().nullable(),
	search: yup.string().nullable(),
});

export default userFilterSchema;
