import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { reportAuditTrail, reportViewReport } from 'utils/queries/reports';
import _ from 'lodash';

const properties = {
	data: [],
	data2: [],
	columns: [],
	second_columns: [],

	summary_headers: [],
	second_summary_headers: [],

	has_second_sheet: false,
	modal_title: null,
	modal_open: false,
	loading: false,
	error: false,
	pagination: {
		page: 1,
		count: 0,
		offset: 0,
		limit: 25,
	},
	sorting: {
		sort_by: 'DESC',
		sort: 'created_at',
	},
};

const initialState = {
	view_report: properties,
	audit_trail: properties,
	tabname: 1,
	loading: false,
	error: false,
	modal_open: false,
	modal_title: null,
	report_file_id: null,
	fileName: null,
};

const fetchAuditTrail = createAsyncThunk(
	'report-view/fetchAuditTrail',
	reportAuditTrail
);

const fetchViewReport = createAsyncThunk(
	'report-view/fetchViewReport',
	reportViewReport
);

const handleSummaryHeader = (header: any[]) => {
	const summary_header: any = [];

	for (let ind = 0; ind <= header.length; ind++) {
		const col: any = header.filter((d, i) => d.index == ind);
		if (col.length > 1) {
			summary_header.push({
				text: col[0].value,
				value: col[1].value,
			});
		} else if (col.length > 0) {
			summary_header.push({
				text: col[0].value,
			});
		}
	}

	return summary_header;
};

const reportViewer = createSlice({
	name: 'reportViewer',
	initialState,
	reducers: {
		setClearReport: (state) => {
			state.view_report = properties;
			state.audit_trail = properties;
			state.modal_open = false;
		},
		setModalState: (state, { payload: { fileName, open } }) => {
			state.modal_title = fileName;
			state.modal_open = open;
		},
		setErrorFalse: (state) => {
			state.view_report.error = false;
			state.audit_trail.error = false;
		},
	},
	extraReducers: (builder) => {
		// ----------------- VIEW REPORT TABLE ----------------- //
		builder
			.addCase(fetchViewReport.pending, (state) => {
				state.view_report.data = [];
				state.view_report.columns = [];
				state.view_report.summary_headers = [];
				state.view_report.loading = true;
				state.view_report.error = false;
			})
			.addCase(fetchViewReport.fulfilled, (state: any, { payload }) => {
				const [table1, table2] = payload?.data?.data || [];
				let summary_header: any[] = [];

				if (!_.isEmpty(table1)) {
					const header = table1?.summary_header;
					summary_header = handleSummaryHeader(header);
				}

				if (!_.isEmpty(table2)) {
					state.view_report.has_second_sheet = true;
				}

				// First Sheet / Table 1
				state.view_report.summary_headers = summary_header;
				state.view_report.columns = table1?.report_headers;
				state.view_report.data = table1?.reports;

				// Second Sheet / Table 2
				state.view_report.second_columns = table2?.report_headers;
				state.view_report.data2 = table2?.reports;

				state.modal_title = payload?.data?.file_name;
				state.view_report.loading = false;
				state.modal_open = true;
			})
			.addCase(fetchViewReport.rejected, (state, { payload }) => {
				state.view_report.loading = false;
				const errorStatus = _.get(payload, 'request.status');
				if (errorStatus !== 403) {
					state.view_report.error = true;
				} else {
					state.view_report.error = false;
				}
			});

		// ----------------- AUDIT TABLE ----------------- //
		builder
			.addCase(fetchAuditTrail.pending, (state, { meta: { arg } }) => {
				state.audit_trail.loading = true;
				state.audit_trail.error = false;

				state.audit_trail.pagination.count = 0;
				state.audit_trail.data = [];
				state.audit_trail.pagination = {
					limit: arg.limit,
					offset: arg.offset,
					page: arg.page,
					count: arg.count,
				};
			})
			.addCase(fetchAuditTrail.fulfilled, (state, { payload }) => {
				const meta = payload?.data?.meta;
				state.audit_trail.pagination.count = meta.total;
				state.audit_trail.data = payload?.data?.data;
				state.audit_trail.loading = false;
				state.modal_open = true;
			})
			.addCase(fetchAuditTrail.rejected, (state, { payload }) => {
				const errorStatus = _.get(payload, 'request.status');
				state.audit_trail.loading = false;
				if (errorStatus !== 403) {
					state.audit_trail.error = true;
				} else {
					state.audit_trail.error = false;
				}
			});
	},
});

export default reportViewer.reducer;
export const { setClearReport, setModalState, setErrorFalse } =
	reportViewer.actions;
export { fetchAuditTrail, fetchViewReport };
