import React, { ReactNode } from 'react';
import { ExpandableButton } from './ExpandableButton';
import useOpenController from '../../ExpandableRow/useOpenController';

type ExpandedSectionProps = {
	disabled?: boolean;
	children: ReactNode;
	styles?: {
		sectionOpen?: string;
		sectionClosed?: string;
		expandButton?: string;
	};
	className?: any;
	isOppositeIcon?: boolean;
};

const ExpandedSection: React.FC<ExpandedSectionProps> = ({
	disabled = false,
	isOppositeIcon = false,
	children,
	className,
	styles,
}) => {
	const { isOpen, toggle } = useOpenController(false);
	return (
		<div className={isOpen ? styles?.sectionOpen : styles?.sectionClosed}>
			<tbody>
				<tr>
					<td className={styles?.expandButton}>
						<ExpandableButton
							isOpen={isOppositeIcon ? !isOpen : isOpen}
							toggle={toggle}
							disabled={disabled}
							className={className}
						/>
					</td>
				</tr>
			</tbody>
			{isOpen && !disabled && <div>{children}</div>}
		</div>
	);
};

export default ExpandedSection;
