import { TABLE_AUDIT_TRAIL_COLUMNS } from 'components/Reports/constants/audit-trail.table';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { fetchAuditTrail } from 'redux/modules/report-viewer';
import { useEffect } from 'react';

import Table from 'components/CWSTable/Table';
import useBus from 'use-bus';

export default function ViewReport(): JSX.Element {
	const TABLE_COLUMNS = TABLE_AUDIT_TRAIL_COLUMNS;
	const dispatch = useDispatch();
	const {
		pagination,
		sorting: { sort, sort_by }
	} = useSelector(
		(states: RootStateOrAny) => states.reportViewer.audit_trail
	);
	const reports = useSelector(
		(states: RootStateOrAny) => states.reportViewer.audit_trail
	);
	const {
		report_category_id: categoryId,
		report_file_id
	} = useSelector(
		(states: RootStateOrAny) => states.reports
	);

	useEffect(() => handleFetchTable(), []);
	useBus('refetch/audit-trail', () => handleFetchTable());

	const handleFetchTable = (otherObj = {}) => {
		dispatch(
			fetchAuditTrail({
				...pagination,
				...otherObj,
				categoryId,
				report_file_id,
			})
		);
	};

	const handlePageChange = (page, limit, offset = 0) => {
		let _offset = offset; // start at zero
		if (page > 0) {
			_offset = (page - 1) * limit;
		}

		handleFetchTable({
			page,
			limit,
			offset: _offset,
		});
	};

	return (
		<div
			id="container"
			style={{ padding: '0.5vmax' }}
		>
			<Table
				scrollable
				sort={sort}
				sortBy={sort_by}
				isReportGeneration
				data={reports.data}
				modalIsOpen={false}
				columns={TABLE_COLUMNS}
				page={pagination.page}
				count={pagination.count}
				pageSize={pagination.limit}
				onPageChange={handlePageChange}
			/>
		</div>
	);
}
