import React, { useState } from 'react';
import moment from 'moment';

import { SwitchWithTooltip } from 'components/Switch';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import volumeIcon from 'assets/icons/ic-card.svg';
import collectionIcon from 'assets/icons/ic-transaction.svg';
import defaultLogo from 'assets/icons/img-logo.svg';

import client from 'helpers/ApiClient';
import {
	useErrorModal,
	useLoader,
	useSuccessModal,
	useToggle,
} from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import { LOADING_UPDATE_MSG } from 'constants/loading';

import { ChannelDetailsBodyProps, UpdateChannelResp } from '../types';
import styles from './ChannelDetailsHeader.module.css';

const totalCountFormat = (v: any) => {
	return v.toLocaleString();
};

const ChannelDetailsHeader: React.FC<ChannelDetailsBodyProps> = ({
	data,
	setRefetchData,
}) => {
	const {
		id,
		name,
		bfaStatus,
		api3status: api3Status,
		logo,
		totalVolume,
		totalCollection,
	} = data || {};
	const initialToggleValue = bfaStatus === 'ACTIVE' ? true : false;

	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [channelToggleStatus, setChannelToggleStatus] =
		useState<boolean>(initialToggleValue);
	const [remarks, setRemarks] = useState<string>('');
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
	} = useSuccessModal();

	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const bfaStatusForFe = channelToggleStatus ? 'Enabled' : 'Disabled';

	const actionText = initialToggleValue ? 'Disable' : 'Enable';
	const modalHeader = `${actionText} Channel`;
	const bodyHeader = (
		<div>
			Are you sure you want to {actionText.toLowerCase()}{' '}
			<span className={styles.bodyHeaderEmphasis}>{name}</span>?
		</div>
	);

	const errorModalBody = `A problem occurred while ${
		channelToggleStatus ? 'enabling' : 'disabling'
	} the channel.`;

	const handleUpdate = async () => {
		hideErrorModal();
		showLoadingMessage(LOADING_UPDATE_MSG);

		try {
			const result: UpdateChannelResp = await client.put(
				`v2/bfa-admin-portal/channels/${id}/${actionText.toLowerCase()}`,
				{
					remarks,
				}
			);
			if (result?.error) {
				throw new Error(result?.error?.message);
			}
			showSuccessMessage(
				<div>
					<span className={styles.bodyHeaderEmphasis}>{name}</span> is now{' '}
					{bfaStatusForFe.toLowerCase()}.
				</div>
			);
		} catch (error) {
			showErrorMessage('Timeout Error!', `${errorModalBody}`, null, () =>
				handleUpdate()
			);
		} finally {
			hideLoading();
		}
	};

	const handleOnChange = (newValue) => {
		if (
			(channelToggleStatus && hasUserPermission('channel.disable')) ||
			(!channelToggleStatus && hasUserPermission('channel.enable'))
		) {
			setChannelToggleStatus(newValue);
			showConfirmModal();
		}
	};

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<div className={styles.headerContainer}>
				<div className={styles.leftHeaderContainer}>
					<div className={styles.logoFieldContainer}>
						<img src={logo || defaultLogo} />
					</div>
					<div className={styles.channelDetails}>
						<div className={styles.channelNameContainer}>
							<div className={styles.channelName}>{name}</div>
							<SwitchWithTooltip
								name="channelStatus"
								checked={channelToggleStatus}
								onChange={api3Status !== 'ACTIVE' ? () => {} : handleOnChange}
								toolTipContent={
									api3Status === 'ACTIVE'
										? actionText
										: `This Channel is disabled in API 3.0, status is not editable.`
								}
								isPointerDisabled={api3Status !== 'ACTIVE'}
							/>
						</div>
						<div className={styles.statusContainer}>
							<div className={styles.statusLabel}>Channel Status: </div>
							<div
								className={
									channelToggleStatus
										? styles.statusEnabled
										: styles.statusDisabled
								}
							>
								{bfaStatusForFe}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.rightHeaderContainer}>
					<div className={styles.transactionContainer}>
						<div className={styles.transactionIcon}>
							<img src={volumeIcon} alt="total volume icon" />
						</div>
						<div>
							<div className={styles.transactionHeaderText}>
								Total Number of Volume
							</div>
							<div
								className={styles.transactionHeaderSubText}
							>{`YTD as of ${String(moment().format('MMMM D, YYYY'))}`}</div>
							<div className={styles.transactionVolume}>
								{totalCountFormat(totalVolume || 0)}
							</div>
						</div>
					</div>
					<div className={styles.transactionContainer}>
						<div className={styles.transactionIcon}>
							<img src={collectionIcon} alt="total collection icon" />
						</div>
						<div>
							<div className={styles.transactionHeaderText}>
								Total Number of Collection
							</div>
							<div className={styles.transactionHeaderSubText}>
								{`YTD as of ${String(moment().format('MMMM D, YYYY'))}`}
							</div>
							<div className={styles.transactionCollection}>
								{totalCountFormat(totalCollection || 0)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ConfirmModalWithRemarks
				isOpen={isConfirmModalShown}
				onClose={hideConfirmModal}
				onCancel={() => {
					setChannelToggleStatus(!channelToggleStatus);
					hideConfirmModal();
				}}
				modalDisabled={false}
				confirmOnClick={handleUpdate}
				header={modalHeader}
				bodyHeader={bodyHeader}
				remarksOnChange={setRemarks}
				remarksSubLabel="Maximum of 1000 characters only."
				remarksMaxLength={1000}
			/>
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={() => {
					hideErrorModal();
					setRefetchData && setRefetchData(true);
				}}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<SuccessModalContainer
				isOpen={isSuccessModalOpen}
				onClose={() => {
					hideSuccessModal();
					setRefetchData && setRefetchData(true);
				}}
				successMessage={successMessage}
			/>
		</div>
	);
};
export default ChannelDetailsHeader;
