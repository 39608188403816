import { Modal } from '@salesforce/design-system-react/module/components';
import { BillersTable, billersColumnConfig } from '../../components';
import { useForm, Controller } from 'react-hook-form';
import {
	handleFilterSubmit,
	convertBillersToOptions,
	filterActionCreator,
	filterActionCreatorCount,
	searchBillersActionCreator,
	changeShowAll,
} from '../../redux/modules/transaction';
import {
	validateDateRange,
	validateAlphaNumericWithSpecialChars,
	validateDecimal,
	isTransactionIsDisabled,
} from '../../utils/validation';
import { connect } from 'react-redux';

import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import SLDSDatepicker from '../Inputs/Datepicker/SLDSDatepicker';
import LookupField from '../Inputs/LookupField/LookupField';
import styles from './TransactionSubFilter.module.css';
import PrimaryButton from '../Buttons/PrimaryButton';
import Select from '../Inputs/Select/Select';
import cx from 'classnames';
import React from 'react';

function TransactionSubFilter({
	filterActionCreator,
	filterActionCreatorCount,
	searchBillersActionCreator,
	billers,
	count,
	changeShowAll,
	showBillers,
	isGetBillersLoading,
}) {
	const {
		register,
		getValues,
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		mode: 'onTouched',
	});

	const [dropdown, selectDropdown] = React.useState([]);
	const watchAllFields = watch('filter');

	return (
		<>
			<Modal
				isOpen={showBillers}
				onRequestClose={() => {
					changeShowAll({ show: false });
				}}
				size="small"
			>
				<BillersTable columns={billersColumnConfig(setValue, changeShowAll)} />
			</Modal>
			<form
				onSubmit={handleSubmit((data) => {
					handleFilterSubmit(data, dropdown, filterActionCreator);
					handleFilterSubmit(data, dropdown, filterActionCreatorCount);
				})}
			>
				<div className={styles.container}>
					<p className={styles.filterTitle}>Filter:</p>
					<div className="slds-grid">
						<div className="slds-col slds-size_11-of-12">
							<div className={cx('slds-grid', styles.inputContainer)}>
								<div className="slds-col">
									<Controller
										name="filter.dateFrom"
										control={control}
										rules={{
											validate: (v) =>
												validateDateRange(v, getValues('filter.dateTo')),
										}}
										render={({ field: { onChange, ...rest } }) => {
											return (
												<SLDSDatepicker
													{...rest}
													placeholder="Date From"
													label="Date From"
													className={cx({
														[styles.errorDtp]:
															errors.filter && errors.filter.dateFrom,
													})}
													onBlur={(event) => {
														event.stopPropagation();
														onChange(event.currentTarget.value);
													}}
												/>
											);
										}}
									/>
									{errors.filter && errors.filter.dateFrom && (
										<div className={styles.error}>
											{errors.filter.dateFrom.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<Controller
										name="filter.dateTo"
										control={control}
										rules={{
											validate: (v) =>
												validateDateRange(getValues('filter.dateTo'), v),
										}}
										render={({ field: { onChange, ...rest } }) => {
											return (
												<SLDSDatepicker
													{...rest}
													placeholder="Date To"
													label="Date To"
													className={cx({
														[styles.errorDtp]:
															errors.filter && errors.filter.dateTo,
													})}
													onBlur={(event) => {
														event.stopPropagation();
														onChange(event.currentTarget.value);
													}}
												/>
											);
										}}
									/>
									{errors.filter && errors.filter.dateTo && (
										<div className={styles.error}>
											{errors.filter.dateTo.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<Controller
										name="filter.tpaid"
										control={control}
										rules={{
											maxLength: {
												value: 4,
												message: 'Invalid value',
											},
											validate: (v) => validateAlphaNumericWithSpecialChars(v),
										}}
										render={({ field }) => (
											<InputWithIcon
												{...field}
												placeholder="TPAID"
												id="tpid"
												label="TPAID"
												className={cx({
													[styles.errorInput]:
														errors.filter && errors.filter.tpaid,
												})}
											/>
										)}
									/>
									{errors.filter && errors.filter.tpaid && (
										<div className={styles.error}>
											{errors.filter.tpaid.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<Controller
										name="filter.accountNo"
										control={control}
										rules={{
											maxLength: {
												value: 20,
												message: 'Invalid value',
											},
											validate: (v) => validateAlphaNumericWithSpecialChars(v),
										}}
										render={({ field }) => (
											<InputWithIcon
												{...field}
												placeholder="Account No"
												label="Account No"
												id="accountNo"
												className={cx({
													[styles.errorInput]:
														errors.filter && errors.filter.accountNo,
												})}
											/>
										)}
									/>
									{errors.filter && errors.filter.accountNo && (
										<div className={styles.error}>
											{errors.filter.accountNo.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<Controller
										name="filter.clientid"
										control={control}
										rules={{
											validate: (v) => validateAlphaNumericWithSpecialChars(v),
										}}
										render={({ field }) => (
											<InputWithIcon
												{...field}
												placeholder="Client ID"
												label="Client ID"
												id="clientid"
												className={cx({
													[styles.errorInput]:
														errors.filter && errors.filter.clientid,
												})}
											/>
										)}
									/>
									{errors.filter && errors.filter.clientid && (
										<div className={styles.error}>
											{errors.filter.clientid.message}
										</div>
									)}
								</div>
								<div
									className="slds-col slds-size_2-of-12"
									style={{ width: '22%' }}
								>
									<label
										className="slds-form-element__label"
										htmlFor="billerName"
									>
										Biller Name
									</label>
									<Controller
										name="filter.billerId"
										control={control}
										render={({ field }) => (
											<LookupField
												{...field}
												isLoading={isGetBillersLoading}
												options={convertBillersToOptions(billers).slice(0, 10)}
												hasSeeMore={count > 10}
												seeMoreClick={() => {
													changeShowAll({
														show: true,
													});
												}}
												placeholder="Biller Name"
												onInputChange={(data) => {
													if (data.length > 2 || data === '') {
														searchBillersActionCreator(data);
													}
												}}
											/>
										)}
									/>
								</div>
								<div className="slds-col">
									<Controller
										name="filter.amount"
										control={control}
										rules={{
											validate: (v) => validateDecimal(v),
										}}
										render={({ field }) => (
											<InputWithIcon
												{...field}
												placeholder="Amount"
												label="Amount"
												id="amount"
												className={cx({
													[styles.errorInput]:
														errors.filter && errors.filter.amount,
												})}
											/>
										)}
									/>
									{errors.filter && errors.filter.amount && (
										<div className={styles.error}>
											{errors.filter.amount.message}
										</div>
									)}
								</div>
								<div className="slds-col" style={{ width: '19.9%' }}>
									<label className="slds-form-element__label" htmlFor="status">
										Status
									</label>
									<Select
										placeholder="Status"
										onChange={(e) =>
											selectDropdown({
												...dropdown,
												status: e,
											})
										}
										options={[
											{ label: '', value: undefined },
											{ label: 'Success', value: 1 },
											{ label: 'Failed', value: 2 },
											{ label: 'Voided', value: 3 },
											{ label: 'Processing', value: 4 },
											{
												label: 'Re-Processing',
												value: 5,
											},
										]}
									/>
								</div>
								<div className="slds-col" style={{ width: '19.9%' }}>
									<label
										className="slds-form-element__label"
										htmlFor="apiVersion"
									>
										Biller Type
									</label>
									<Select
										placeholder="Biller Type"
										value={3}
										onChange={(e) =>
											selectDropdown({
												...dropdown,
												apiVersion: e,
											})
										}
										options={[
											{ label: 'Direct 3.0', value: 3 },
											{ label: 'Routed 1.0', value: 1 },
										]}
										controlled
									/>
								</div>
							</div>
						</div>
						<div className="slds-col">
							<PrimaryButton
								type="submit"
								className={styles.filterButton}
								disabled={
									errors.filter ||
									isTransactionIsDisabled(watchAllFields, dropdown)
								}
							>
								Filter
							</PrimaryButton>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}

export default connect(
	(state) => ({
		billers: state.transaction.billers.searchResult,
		count: state.transaction.billers.pagination.count,
		showBillers: state.transaction.billers.showAll,
		isGetBillersLoading: state.transaction.billers.isLoading,
	}),
	{
		filterActionCreator,
		filterActionCreatorCount,
		searchBillersActionCreator,
		changeShowAll,
	}
)(TransactionSubFilter);
