import React from 'react';
import Table from 'components/ChannelTable/Table';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentialsTable.module.css';
interface Props {
	columns: Array<any>;
	data: Array<any>;
	pageSize: number;
	count: number;
	onPageChange: (page, pageSize) => void;
	page: number;
}

const ChannelCredentialsTable: React.FC<Props> = ({
	columns,
	data,
	pageSize,
	count,
	onPageChange,
	page,
}) => {
	return (
		<Table
			page={page}
			data={data}
			count={count}
			preHeader={null}
			scrollable={true}
			columns={columns}
			modalIsOpen={false}
			pageSize={pageSize}
			onPageChange={onPageChange}
			className={styles.withPreHeader}
		/>
	);
};

export default ChannelCredentialsTable;
