import React from "react";
import cx from "classnames";

export default function IconResolver({ className, path }) {
  const [kind, icon] = path.split("/");
  return (
    <svg className={cx("slds-icon", className)} aria-hidden="true">
      <use
        xlinkHref={`/lib/lightningdesignsystem/assets/icons/${kind}-sprite/svg/symbols.svg#${icon}`}
      ></use>
    </svg>
  );
}
