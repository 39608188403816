import cx from 'classnames';
import styles from './style.module.css';
import { CsrReportTableProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/CsrReportTable/types';
import { useColumnConfig } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/CsrReportTable/hooks';

const CsrReportTable: React.FC<CsrReportTableProps> = ({ entries }) => {
	const { config, data } = useColumnConfig(entries);
	return (
		<div className={styles.table}>
			{config.map(({ header, id }, i) => (
				<div key={i}>
					<div
						className={cx(styles.column, styles.th, {
							[styles.left]: i === 0,
							[styles.right]: i === config.length - 1,
						})}
					>
						{header}
					</div>
					{data.map((row, j) => {
						return (
							<div
								className={cx(styles.column, styles.td, {
									[styles.left]: i === 0,
									[styles.right]: i === config.length - 1,
								})}
								key={j}
							>
								{row[id]}
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
};

export default CsrReportTable;
