import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Checkbox } from '@salesforce/design-system-react';

import cx from 'classnames';
import { filter, includes } from 'lodash';
import moment from 'moment';
import HTTP from 'helpers/ApiClient';
import axios from 'axios';

import AttachmentCard from './components/AttachmentCard';
import VoidStatusBadge from './components/VoidStatusBadge';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import Button from 'components/Buttons/Button';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import Divider from 'components/PartnerForm/Divider';
import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';
import DragDropIcon from 'assets/icons/img-dragdrop.svg';
import IconResolver from '../../helpers/IconResolver';
import { MdDescription } from 'react-icons/md';
import FileThumbnail from 'components/FilePreview/FileThumbnail';

import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';

import styles from './VoidRequestModal.module.css';
import TextareaAutosize from 'react-textarea-autosize';

type VoidRequestModalProps = {
	isDisplayOnly?: boolean;
	isOpen: boolean;
	selectedVoidRequest: any; // TODO: change to specific type
	onClose: () => void;
	onVoidStatusChange: (voidRequest: any, value: 'Approved' | 'Reject') => void;
};

const CHECKBOX_FIELDS_REASON = [
	{
		id: 0,
		label: 'Altered Check',
		value: 'ALTERED_CHECK',
	},
	{
		id: 1,
		label: 'Wrong Payment Type',
		value: 'WRONG_PAYMENT_TYPE',
	},
	{
		id: 2,
		label: 'Cancelled Payment',
		value: 'CANCELLED_PAYMENT',
	},
	{
		id: 3,
		label: 'Already paid by the Customer',
		value: 'ALREADY_PAID_BY_THE_CUSTOMER',
	},
	{
		id: 4,
		label: 'Cash Encoded as Check',
		value: 'CASH_ENCODED_AS_CHECK',
	},
	{
		id: 5,
		label: 'Voided/Cancelled by Biller',
		value: 'VOIDED_CANCELLED_BY_BILLER',
	},
	{
		id: 6,
		label: 'Check Encoded as Cash',
		value: 'CHECK_ENCODED_AS_CASH',
	},
	{
		id: 7,
		label: 'For Refund/Reversal',
		value: 'FOR_REFUND_REVERSAL',
	},
	{
		id: 8,
		label: 'Post Dated Check',
		value: 'POST_DATED_CHECK',
	},
	{
		id: 9,
		label: 'Customer Error',
		value: 'CUSTOMER_ERROR',
	},
	{
		id: 10,
		label: 'Staled Check',
		value: 'STALED_CHECK',
	},
	{
		id: 11,
		label: 'Customer Initiated Request',
		value: 'CUSTOMER_INITIATED_REQUEST',
	},
	{
		id: 12,
		label: 'Unsigned Check',
		value: 'UNSIGNED_CHECK',
	},
	{
		id: 13,
		label: 'Teller Error',
		value: 'TELLER_ERROR',
	},
	{
		id: 14,
		label: 'Wrong Bank Code',
		value: 'WRONG_BANK_CODE',
	},
	{
		id: 15,
		label: 'No Payment Received',
		value: 'NO_PAYMENT_RECEIVED',
	},
	{
		id: 16,
		label: 'Wrong Check Number',
		value: 'WRONG_CHECK_NUMBER',
	},
	{
		id: 17,
		label: 'Erroneous Deposit',
		value: 'ERRONEOUS_DEPOSIT',
	},
	{
		id: 18,
		label: 'Wrong Amount in Words & Figures',
		value: 'WRONG_AMOUNT_IN_WORDS_&_FIGURES',
	},
	{
		id: 19,
		label: 'Over Deposit',
		value: 'OVER_DEPOSIT',
	},
	{
		id: 20,
		label: 'Wrong Amount',
		value: 'WRONG_AMOUNT',
	},
	{
		id: 21,
		label: 'Under Deposit',
		value: 'UNDER_DEPOSIT',
	},

	{
		id: 22,
		label: 'Wrong Biller',
		value: 'WRONG_BILLER',
	},
	{
		id: 23,
		label: 'Double Posting',
		value: 'DOUBLE_POSTING',
	},
	{
		id: 24,
		label: 'Wrong Bill Presented',
		value: 'WRONG_BILL_PRESENTED',
	},
	{
		id: 25,
		label: 'System Error',
		value: 'SYSTEM_ERROR',
	},
	{
		id: 26,
		label: 'Wrong System Date',
		value: 'WRONG_SYSTEM_DATE',
	},
	{
		id: 27,
		label: 'No Teller Code',
		value: 'NO_TELLER_CODE',
	},
	{
		id: 28,
		label: 'Wrong Customer Account / Reference No.',
		value: 'WRONG_CUSTOMER_ACCOUNT_OR_REFERENCE_NO',
	},
	{
		id: 29,
		label: 'Unique Constraint',
		value: 'UNIQUE_CONSTRAINT',
	},
	{
		id: 30,
		label: 'Invalid Account No.',
		value: 'INVALID_ACCOUNT_NO',
	},
	{
		id: 31,
		label: 'Others',
		value: 'OTHERS',
	},
];

/**
 * Void request modal
 * @component
 */
const VoidRequestModal: FC<VoidRequestModalProps> = ({
	isDisplayOnly,
	isOpen,
	selectedVoidRequest: voidRequest,
	onClose,
	onVoidStatusChange,
}) => {
	const [viewFileModal, setViewFileModal] = useState(false);

	// form control
	const [isSelectAllReasonChecked, setIsSelectAllReasonChecked] =
		useState(false);

	useEffect(() => {
		setIsSelectAllReasonChecked(false);
		if (
			CHECKBOX_FIELDS_REASON.length === voidRequest?.reason?.split(',')?.length
		) {
			setIsSelectAllReasonChecked(true);
		}
	}, [voidRequest?.reason]);

	// field states
	const [acceptedFiles, setAcceptedFiles] = useState([]); // raw files for form
	const [selectedFile, setSelectedFile] = useState<any>();
	const [attachments, setAttachments] = useState<any>();
	const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
	const [reasonSearchInput, setReasonSearchInput] = useState('');

	/** Handles on file drop */
	const onDrop = useCallback((files: any) => {
		if (files.length > 10) {
			// TODO: add file validatiosn
			// TODO: replace alert
			window.alert('You are only allowed to upload 10 files');
			return;
		}
		setAcceptedFiles(acceptedFiles.concat(files));
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		open: openFileBrowser,
	} = useDropzone({
		onDrop,
		noClick: true,
		disabled: isDisplayOnly,
	});

	const memoDepositDate = useMemo(() => {
		const depositDate = voidRequest?.deposit_date;
		if (!depositDate || !moment(depositDate).isValid()) {
			return null;
		}

		return moment(depositDate).format('MM/DD/YYYY');
	}, [voidRequest?.deposit_date]);

	/** Triggers on eye button click */
	const handleFileView = (index: number) => {
		setSelectedFile(attachments[index]);
		setViewFileModal(true);
	};

	const handleFileRemove = (index: number) => {
		setAcceptedFiles(acceptedFiles.filter((v, i) => i !== index));
		return;
	};

	/** Triggers on download button click */
	const handleFileDownload = (index: number) => {
		axios
			.get(attachments[index].url, {
				responseType: 'blob',
				headers: {
					'Cache-Control': 'no-cache',
				},
			})
			.then((response) => {
				const f = attachments[index].file_name.substring(
					attachments[index].file_name.lastIndexOf('/') + 1
				);
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', f);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	/** Triggers upon void request checkbox click to select void request reasons */
	const handleSelectReason = (value: string) => {
		if (value === 'none') {
			setSelectedReasons([]);
			return;
		}

		if (value === 'all') {
			setSelectedReasons(
				CHECKBOX_FIELDS_REASON.map((v) => v?.value).filter(
					(v) => v !== 'OTHERS'
				)
			);
			return;
		}

		if (includes(selectedReasons, value)) {
			setSelectedReasons(selectedReasons.filter((v) => v !== value));
			return;
		}

		setSelectedReasons((prevState) => prevState.concat(value));
	};

	useEffect(() => {
		handleSelectReason(isSelectAllReasonChecked ? 'all' : 'none');
	}, [isSelectAllReasonChecked]);

	useEffect(() => {
		// clears data upon modal closing
		return () => {
			setAcceptedFiles([]);
		};
	}, []);

	const [isVoidRequestLoading, setIsVoidRequestLoading] = useState(false);

	const fetchAttachmentDetails = async () => {
		setIsVoidRequestLoading(true);
		if (voidRequest.attachments && voidRequest.attachments.length > 0) {
			setAttachments(voidRequest.attachments);
		}
		setTimeout(() => {
			setIsVoidRequestLoading(false);
		}, 1000);
	};

	useEffect(() => {
		if (voidRequest && isOpen) {
			fetchAttachmentDetails();
		}
	}, [voidRequest, isOpen]);

	/** Memoized state to filter out checkboxes based on search input */
	const filteredCheckboxReason = useMemo(() => {
		if (reasonSearchInput !== '') {
			return filter(CHECKBOX_FIELDS_REASON, ({ label }) => {
				return includes(label.toLowerCase(), reasonSearchInput.toLowerCase());
			});
		}
		return CHECKBOX_FIELDS_REASON;
	}, [reasonSearchInput]);

	const isAttachmentImage = (type) => includes(['png', 'jpg', 'jpeg'], type);
	const isAttachmentFile = (type) => includes(['pdf'], type);

	const isBtnFooterDisabled = ['approved', 'reject'].includes(
		voidRequest?.status?.toLowerCase()
	);

	return (
		<>
			<Modal
				ariaHideApp={false}
				containerClassName={styles.modalContainter}
				isOpen={isOpen}
				onRequestClose={onClose}
				headerClassName={styles.headerStyle}
				header={
					<Section className={cx(styles.modalContent, styles.section)}>
						<Section
							className={styles.sectionContainerHeader}
							title="Void Transaction"
							titleClassName={styles.header}
						/>
						<Section className={styles.sectionContainerSubHeader}>
							<SectionRow className={styles.sectionRowContainer}>
								<Grid column size={1} of={1} className={styles.gridContainer}>
									<span>Void Reference ID:</span>
									<span className={styles.referenceId}>
										{voidRequest?.reference_id}
									</span>
								</Grid>
							</SectionRow>
							<SectionRow className={styles.sectionRowContainer}>
								<Grid column size={1} of={1} className={styles.gridContainer}>
									<span>Void Request Status:</span>
									<VoidStatusBadge
										status={voidRequest?.status}
										className={styles.status}
									/>
								</Grid>
							</SectionRow>
						</Section>
						<Section
							className={styles.sectionContainerHeader}
							title="Void Request Information"
							titleClassName={styles.parentSectionTitle}
						/>
					</Section>
				}
				footer={
					<>
						{isDisplayOnly && (
							<div className={styles.confirmCheckbox}>
								<Checkbox
									className={styles.checked}
									checked={isDisplayOnly}
									disabled={isDisplayOnly}
								/>
								<div>
									I will take full responsibility in case the customer has any
									dispute in the posted transaction versus the validated amount
									in the statement of account or payment form. I will not hold
									Bayad Center liable for the difference, if any.
								</div>
							</div>
						)}
						{['pending', 'approved', 'reject'].includes(
							voidRequest?.status?.toLowerCase()
						) && (
							<>
								<hr className={styles.horizontalLine} />
								<div className={styles.footerContainer}>
									<Button
										className={styles.decline}
										onClick={() => onVoidStatusChange(voidRequest, 'Reject')}
										disabled={isBtnFooterDisabled}
									>
										<RejectIcon
											className={cx(styles.rejectIcon, {
												[styles.rejectIconDisabled]: isBtnFooterDisabled,
											})}
										/>
										Reject
									</Button>
									<Button
										className={styles.approve}
										onClick={() => onVoidStatusChange(voidRequest, 'Approved')}
										disabled={isBtnFooterDisabled}
									>
										<ApproveIcon
											className={cx(styles.approveIcon, {
												[styles.approveIconDisabled]: isBtnFooterDisabled,
											})}
										/>
										Approve
									</Button>
								</div>
							</>
						)}
					</>
				}
			>
				<Section className={cx(styles.modalContent, styles.informationSection)}>
					<Section>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Transaction Date
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{moment(voidRequest?.transaction_date).format(
									'MM/DD/YYYY hh:mm:ss A'
								)}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Transaction Reference No.
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.transaction_reference_number}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Branch
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.branch}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								TPAID
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.tpaid}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Biller
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.biller_name}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Mode of Payment
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.payment_method}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Amount
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.amount}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Account Number
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.account_number}
							</Grid>
						</SectionRow>

						{voidRequest?.payment_method.toLowerCase() !== 'cash' && (
							<>
								<SectionRow>
									<Grid
										column
										size={1}
										of={3}
										className={styles.informationLabel}
									>
										Bank Name
									</Grid>
									<Grid
										column
										size={2}
										of={3}
										className={styles.informationData}
									>
										<span>:</span>
										{voidRequest?.bank_name}
									</Grid>
								</SectionRow>
								<SectionRow>
									<Grid
										column
										size={1}
										of={3}
										className={styles.informationLabel}
									>
										Check Number
									</Grid>
									<Grid
										column
										size={2}
										of={3}
										className={styles.informationData}
									>
										<span>:</span>
										{voidRequest?.check_no}
									</Grid>
								</SectionRow>
							</>
						)}

						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Date of Request
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{moment(voidRequest?.request_date).format(
									'MM/DD/YYYY hh:mm:ss A'
								)}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Biller Type
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.biller_type}
							</Grid>
						</SectionRow>

						{voidRequest?.biller_type?.toLowerCase() === 'non-rtp' && (
							<SectionRow>
								<Grid
									column
									size={1}
									of={3}
									className={styles.informationLabel}
								>
									For Within the Day Cancellation?
								</Grid>
								<Grid column size={2} of={3} className={styles.informationData}>
									<span>:</span>
									{voidRequest?.for_cancelation_within_day === 0 ? 'No' : 'Yes'}
								</Grid>
							</SectionRow>
						)}

						<SectionRow>
							<Grid column size={1} of={3} className={styles.informationLabel}>
								Bayad Center Name
							</Grid>
							<Grid column size={2} of={3} className={styles.informationData}>
								<span>:</span>
								{voidRequest?.channel_name}
							</Grid>
						</SectionRow>
					</Section>

					<Section>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									disabled={isDisplayOnly}
									labelClassName={styles.sectionLabel}
									label="Depository Bank Account No."
									placeholder="Depository Bank Account No."
									value={voidRequest?.depository_account_number}
									optional
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<TextField
									disabled={isDisplayOnly}
									labelClassName={styles.sectionLabel}
									label="Date of Deposit"
									value={memoDepositDate}
									placeholder="Date of Deposit"
									optional
									iconRight={
										<IconResolver
											key="input-icon-1"
											className={cx(
												'slds-input__icon',
												'slds-icon-text-default'
											)}
											path="utility/event"
										/>
									}
								/>
							</Grid>
						</SectionRow>
					</Section>

					<Section
						title="Void Reason"
						titleClassName={styles.sectionLabel}
						className={styles.sectionSpacing}
					>
						<SectionRow>
							<Grid column size={1} of={1}>
								<div className={cx(styles.card, styles.reasonWrapper)}>
									<Checkbox
										className={cx(styles.checkedBox, {
											[styles.isChecked]: isSelectAllReasonChecked,
										})}
										labels={{
											label: 'Select all',
										}}
										disabled={isDisplayOnly}
										checked={isSelectAllReasonChecked}
										onChange={(e) =>
											setIsSelectAllReasonChecked(e?.target?.checked)
										}
									/>
									<div className={cx(styles.reasonContainer)}>
										<InputWithIcon
											placeholder="Search"
											fullWidth
											value={reasonSearchInput}
											onChange={(e) => {
												setReasonSearchInput(e?.target?.value);
											}}
											disabled={isDisplayOnly}
											className={styles.searchBar}
											icons={[
												{
													path: 'utility/search',
													isLeft: true,
												},
											]}
										/>

										<div className="slds-grid slds-wrap">
											{filteredCheckboxReason?.map((item) => {
												const isChecked = includes(
													voidRequest?.reason
														?.toUpperCase()
														.replace(/ /g, '_')
														.split(','),
													item?.value.toUpperCase()
												);
												return (
													<>
														<Grid
															column
															size={1}
															of={2}
															className={styles.checkedBoxContainer}
															key={voidRequest?.account_number}
														>
															<Checkbox
																className={cx(styles.checkedBox, {
																	[styles.isChecked]: isChecked,
																})}
																disabled={isDisplayOnly}
																checked={isChecked}
																value={item?.value}
																onChange={() => handleSelectReason(item?.value)}
															/>
															<div>{item?.label}</div>
														</Grid>

														{item.value === 'OTHERS' && isChecked && (
															<>
																<Grid
																	column
																	size={1}
																	of={2}
																	className={styles.checkedBoxContainer}
																/>
																<Grid
																	column
																	size={1}
																	of={2}
																	className={styles.checkedBoxContainer}
																>
																	<TextareaAutosize
																		className={cx(
																			'sdls-input',
																			styles.fieldStyle,
																			styles.othersInput
																		)}
																		disabled
																		value={voidRequest?.others}
																	/>{' '}
																</Grid>
															</>
														)}
													</>
												);
											})}
										</div>
									</div>
								</div>
							</Grid>
						</SectionRow>
					</Section>

					<Section
						title="Upload Attachments"
						titleClassName={styles.sectionLabel}
						className={styles.sectionSpacing}
					>
						<SectionRow>
							<Grid column size={1} of={1}>
								<div className={cx(styles.uploadContainer)} {...getRootProps()}>
									<input {...getInputProps()} hidden />
									<div style={{ marginBottom: '10px' }}>
										<img
											src={DragDropIcon}
											alt="image"
											style={{ width: '10%', height: 'auto' }}
										/>
									</div>

									<span className={cx(styles.dragAndDropText)}>
										{isDragActive
											? 'Drop your files here'
											: 'Drag and drop files here or click "Choose File" button'}
									</span>
									<div style={{ margin: '10px 0px 10px 0px' }}>
										<span className={cx(styles.outline)}>Choose File</span>
									</div>
									{attachments?.length > 0 && (
										<div className={cx(styles.attachmentContainer)}>
											{attachments?.map((file: any, index: number) => {
												return (
													<div className="slds-col">
														<AttachmentCard
															url={file.url}
															fileName={file.file_name}
															type={file.type}
															onView={() => handleFileView(index)}
															onRemove={() => handleFileRemove(index)}
															onDownload={() => handleFileDownload(index)}
															isDisplayOnly={isDisplayOnly}
														/>
													</div>
												);
											})}
										</div>
									)}
								</div>
								<div
									className={cx(
										'slds-grid',
										'slds-grid_vertical',
										styles.textHelper
									)}
								>
									<div className={styles.textHelperLabel}>
										<p>*Maximum file size per attachment: </p>
										<span>5MB</span>
									</div>
									<div className={styles.textHelperLabel}>
										<p>*Maximum number of attachment: </p>
										<span>10</span>
									</div>
									<div className={styles.textHelperLabel}>
										<p>*Allowed file types: </p>
										<span>.doc, .docx, .pdf, .jpg, .jpeg, or .png</span>
									</div>
								</div>
							</Grid>
						</SectionRow>
					</Section>

					<Divider className={styles.dividerTop} />

					<Section
						title="Void Requestor Information"
						titleClassName={styles.largeSectionTitle}
					>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									disabled={isDisplayOnly}
									labelClassName={styles.sectionLabel}
									label="Name of Void Requestor"
									value={voidRequest?.requestor_name}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<TextField
									disabled={isDisplayOnly}
									labelClassName={styles.sectionLabel}
									label="Contact Number of Void Requestor"
									value={voidRequest?.contact_number}
								/>
							</Grid>
						</SectionRow>
					</Section>

					<Divider className={styles.dividerBottom} />

					<Section
						title="Incident Report"
						titleClassName={styles.largeSectionTitle}
					>
						<SectionRow>
							<Grid column size={1} of={1}>
								<TextField
									disabled={isDisplayOnly}
									labelClassName={styles.sectionLabel}
									label="Incident Report"
									placeholder="Test Incident Report"
									value={voidRequest?.incident_report}
									multiLine
									textAreaHeight="90px"
								/>
							</Grid>
						</SectionRow>
					</Section>
				</Section>
			</Modal>

			{viewFileModal && (
				<Modal
					ariaHideApp={false}
					maxWidth="sm"
					isOpen={viewFileModal}
					onRequestClose={() => setViewFileModal(false)}
				>
					<div style={{ margin: '10px 10px 0px 10px' }}>
						{selectedFile?.file_name}
					</div>
					<section
						className={
							isAttachmentFile(selectedFile?.type.replace('.', ''))
								? styles.previewFile
								: styles.previewImage
						}
					>
						{selectedFile &&
						isAttachmentImage(selectedFile?.type.replace('.', '')) ? (
							<img
								src={selectedFile.url}
								alt="preview"
								className={styles.imagePreview}
							/>
						) : isAttachmentFile(selectedFile?.type.replace('.', '')) ? (
							<FileThumbnail
								filePath={selectedFile.url}
								fileType={selectedFile.type.replace('.', '')}
							/>
						) : (
							<div className={styles.previewPlaceholder}>
								<div style={{ textAlign: 'center' }}>
									<MdDescription
										style={{ fontSize: '50px', color: 'var(--gray)' }}
									/>
									<div
										style={{
											fontSize: '12.5px',
											fontFamily: 'Poppins-Regular',
											marginTop: '5%',
										}}
									>
										This file has no preview.
									</div>
								</div>
							</div>
						)}
					</section>
				</Modal>
			)}
			
			<FullPageLoader
				open={isVoidRequestLoading}
				message="Please wait while void request is being loaded"
			/>
		</>
	);
};

export default VoidRequestModal;
