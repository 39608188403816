import { YupMessage } from 'types';
import { noWhiteSpace } from 'utils/common';
import yup, {
	defaultMax255CharSpiel,
	defaultOnlyAlphaChar,
	yupValidateEmails,
} from 'utils/formSchemas/common';
import {
	validateLandlineNumberChannelMgt,
	validateLandlineNumber,
	validateMobile,
} from 'utils/validation';

export const branchContactDetails = yup
	.array()
	.ensure()
	.of(
		yup.object({
			name: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-zA-Z0-9 ]*$/, {
					message: defaultOnlyAlphaChar,
				})
				.label('Name of Branch Contact Person')
				.test(noWhiteSpace()),
			position: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-zA-Z0-9 ]*$/, {
					message: defaultOnlyAlphaChar,
				})
				.label('Position')
				.test(noWhiteSpace()),
			department: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-zA-Z0-9 ]*$/, {
					message: defaultOnlyAlphaChar,
				})
				.test(noWhiteSpace())
				.label('Department'),
			emailAddress: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.ensure()
				.label('Email Address')
				.min(1, `Input Branch Contact Email Address`)
				.test('isValidEmail', yupValidateEmails),
			telephoneNumber: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.optional()
				.label('Telephone Number')
				.test(
					'isValidLandlineNumber',
					({ value }: YupMessage): string => {
						const count = value?.filter(
							(a: string) => !validateLandlineNumber(a || '')
						).length;

						const totalLength = value
							? value.reduce(
									(acc: number, val: string) => acc + (val ? val.length : 0),
									0
							  )
							: 0;

						if (totalLength >= 255) {
							return 'You can only input a max of 255 characters.';
						}
						if (count >= 1) {
							return `${count} ${
								count > 1 ? 'inputs are' : 'input is'
							} invalid telephone number format`;
						}
						return '';
					},
					(v: string[] | undefined): boolean => {
						if (!v) return true;
						return v.every((a) => validateLandlineNumber(a || '', v.length));
					}
				),
			// .test(
			// 	'isValidLandlineNumber',
			// 	({ value }: YupMessage): string => {
			// 		const count = value?.filter(
			// 			(a: string) => !validateLandlineNumberChannelMgt(a)
			// 		).length;

			// 		const reducedValue = value.reduce(
			// 			(acc: number, val: string) => acc + (val ? val.length : 0),
			// 			0
			// 		);

			// 		const totalLength = value ? reducedValue : 0;

			// 		if (totalLength >= 255) {
			// 			return 'You can only input a max of 255 characters.';
			// 		}
			// 		if (count >= 1) {
			// 			return `${count} ${
			// 				count > 1 ? 'inputs are' : 'input is'
			// 			} invalid telephone number format`;
			// 		}
			// 		return '';
			// 	},
			// 	(v): boolean => {
			// 		if (!v) return true;
			// 		return v.every((a) =>
			// 			validateLandlineNumberChannelMgt(a, v.length)
			// 		);
			// 	}
			// ),
			mobileNumber: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.ensure()
				.label('Mobile Number')
				.min(1, ({ label }) => `Input ${label}`)
				.test(
					'isValidMobileNumber',
					({ value }: YupMessage): string => {
						const count = value?.filter(
							(a: string) => !validateMobile(a || '')
						).length;

						const reducedValue = value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
						);
						const totalLength = value ? reducedValue : 0;

						if (totalLength >= 255) {
							return 'You can only input a max of 255 characters.';
						}
						if (!count) return '';

						return 'Mobile number should be in +639XXXXXXXXX format.';
					},
					(v): boolean => {
						if (!v) return true;
						return v.every((a) => validateMobile(a || '', v.length));
					}
				),
		})
	);
