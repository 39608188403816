import cx from 'classnames';
import { CsrColumn } from '../CsrColumnCheckbox';
import styles from './style.module.css';

type CsrReportTableProps = {
	entries: CsrColumn[];
};

type ColumnConfig = {
	header: string;
	id: string;
};

function createFooterEntry(
	config: ColumnConfig[],
	label: string,
	value: string
) {
	return config.reduce((acc, curr, i) => {
		if (i === 0)
			return {
				...acc,
				[curr.id]: label,
			};

		if (i === config.length - 1)
			return {
				...acc,
				[curr.id]: value,
			};

		return {
			...acc,
			[curr.id]: '',
		};
	}, {});
}

function useColumnConfig(entries: CsrColumn[]) {
	const sorted = entries.sort((a, b) => a.ordinal - b.ordinal);

	const config = sorted?.map(({ code: id, value: header }) => ({
		id,
		header,
	}));

	const data = sorted.reduce(
		(acc, curr) => ({
			...acc,
			[curr.code]: curr.sample,
		}),
		{}
	) as Record<string, string>;

	const blank = config.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {});
	const totalCasCollections = createFooterEntry(
		config,
		'Total Cash Collections',
		data.CASH_AMOUNT
	);
	const totalCheckCollections = createFooterEntry(
		config,
		'Total Check Collections',
		data.CHECK_AMOUNT
	);
	const grandTotalCollections = createFooterEntry(
		config,
		'Grand Total Collections',
		data.TOTAL_AMOUNT
	);
	const grandTotalCount = createFooterEntry(
		config,
		'Grand Total Transaction Count',
		'1'
	);
	const footer = [
		blank,
		totalCasCollections,
		totalCheckCollections,
		grandTotalCollections,
		grandTotalCount,
	];

	return { config, data: [data, ...footer] };
}

function CsrReportTable({ entries }: CsrReportTableProps) {
	const { config, data } = useColumnConfig(entries);
	return (
		<div className={styles.table}>
			{config.map(({ header, id }, i) => (
				<div key={i}>
					<div
						className={cx(styles.column, styles.th, {
							[styles.left]: i === 0,
							[styles.right]: i === config.length - 1,
						})}
					>
						{header}
					</div>
					{data.map((row, j) => {
						return (
							<div
								className={cx(styles.column, styles.td, {
									[styles.left]: i === 0,
									[styles.right]: i === config.length - 1,
								})}
								key={j}
							>
								{row[id]}
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
}

export default CsrReportTable;
