import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { createFooterEntry } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/utils';
import { FixMeLater } from 'types';

export function useColumnConfig(entries: CsrColumn[]): FixMeLater {
	const sorted = entries.sort((a, b) => a.ordinal - b.ordinal);

	const config: FixMeLater = sorted?.map(({ code: id, value: header }) => ({
		id,
		header,
	}));

	const data = sorted.reduce(
		(acc, curr: FixMeLater) => ({
			...acc,
			[curr.code]: curr.sample,
		}),
		{}
	) as Record<string, string>;

	const blank = config.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {});
	const totalCasCollections = createFooterEntry(
		config,
		'Total Cash Collections',
		data.CASH_AMOUNT
	);
	const totalCheckCollections = createFooterEntry(
		config,
		'Total Check Collections',
		data.CHECK_AMOUNT
	);
	const totalConvenienceFee = createFooterEntry(
		config,
		'Total Convenience Fee (CF)',
		data.SERVICE_FEE
	);
	const grandTotalConvenienceFee = createFooterEntry(
		config,
		'Grand Total Collections + CF',
		data.TOTAL_COLLECTED
	);
	const grandTotalCollections = createFooterEntry(
		config,
		'Grand Total Collections',
		data.TOTAL_AMOUNT
	);
	const grandTotalCount = createFooterEntry(
		config,
		'Grand Total Transaction Count',
		'1'
	);
	const footer = [
		blank,
		totalCasCollections,
		totalCheckCollections,
		totalConvenienceFee,
		grandTotalConvenienceFee,
		grandTotalCollections,
		grandTotalCount,
	];

	return { config, data: [data, ...footer] };
}
