import React, { useEffect, useState, useRef } from 'react';
import styles from 'containers/ChannelManagement/List/style.module.css';
import { Button } from '@salesforce/design-system-react';
import { branchListFilter } from 'redux/modules/channelList';
import { AiOutlineClose, AiOutlineCaretDown } from 'react-icons/ai';
import { BranchDropdownCheckboxProps } from 'containers/ChannelManagement/List/types';
import { useList } from 'containers/ChannelManagement/List/hooks';
import Checkbox from 'components/Checkbox/Checkbox';

const BranchDropdownCheckbox: React.FC<BranchDropdownCheckboxProps> = ({
	branchFilter,
	dispatch,
}) => {
	const [posType, setPosType] = useState<Array<any>>([]);
	const [storeType, setStoreType] = useState<Array<any>>([]);
	const [storeFormat, setStoreFormat] = useState<Array<any>>([]);
	const [machineLocation, setMachineLocation] = useState<Array<any>>([]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const { loader } = useList();

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		if (!loader.isLoading) {
			window.addEventListener('click', handleOutsideClick);
		}
		return () => {
			if (!loader.isLoading) {
				window.removeEventListener('click', handleOutsideClick);
			}
		};
	}, [loader.isLoading]);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const watchCheckbox = () => {
		if (
			posType.length === 6 &&
			storeType.length === 2 &&
			storeFormat.length === 2 &&
			machineLocation.length === 2
		) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	};

	useEffect(() => {
		watchCheckbox();
	}, [posType, storeType, storeFormat, machineLocation]);

	const handleSelectAll = () => {
		setSelectAll(!selectAll);
		if (!selectAll) {
			setPosType(['ASENSO', 'BFA', 'PCS', 'BIOS', 'KIOSK', 'FRONT_END']);
			setStoreType(['FULL-BOOTH', 'BOOTH']);
			setStoreFormat(['STANDALONE', 'MALL-BASED']);
			setMachineLocation(['INSIDE', 'OUTSIDE']);
			dispatch(
				branchListFilter({
					branchFilter: {
						name: branchFilter.name,
						posType: 'ASENSO|BFA|PCS|BIOS|KIOSK|FRONT_END',
						storeType: 'BOOTH|FULL-BOOTH',
						storeFormat: 'MALL-BASED|STANDALONE',
						machineLocation: 'INSIDE|OUTSIDE',
					},
				})
			);
		} else {
			setPosType([]);
			setStoreType([]);
			setStoreFormat([]);
			setMachineLocation([]);
			dispatch(
				branchListFilter({
					branchFilter: {
						name: branchFilter.name,
						posType: '',
						storeType: '',
						storeFormat: '',
						machineLocation: '',
					},
				})
			);
		}
	};

	const clearBranchFilter = () => {
		setSelectAll(false);
		setPosType([]);
		setStoreType([]);
		setStoreFormat([]);
		setMachineLocation([]);
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: '',
					storeType: '',
					storeFormat: '',
					machineLocation: '',
				},
			})
		);
	};

	const handlePosTypeCheckbox = (value) => {
		if (posType.includes(value)) {
			setPosType(posType.filter((item) => item !== value));
		} else {
			setPosType([...posType, value]);
		}
	};

	const handleStoreTypeCheckbox = (value) => {
		if (storeType.includes(value)) {
			setStoreType(storeType.filter((item) => item !== value));
		} else {
			setStoreType([...storeType, value]);
		}
	};

	const handleStoreFormatCheckbox = (value) => {
		if (storeFormat.includes(value)) {
			setStoreFormat(storeFormat.filter((item) => item !== value));
		} else {
			setStoreFormat([...storeFormat, value]);
		}
	};

	const handleMachineLocationCheckbox = (value) => {
		if (machineLocation.includes(value)) {
			setMachineLocation(machineLocation.filter((item) => item !== value));
		} else {
			setMachineLocation([...machineLocation, value]);
		}
	};

	useEffect(() => {
		dispatch(
			branchListFilter({
				branchFilter: {
					name: branchFilter.name,
					posType: posType.join('|'),
					storeType: branchFilter.storeType,
					storeFormat: branchFilter.storeFormat,
					machineLocation: branchFilter.machineLocation,
				},
			})
		);
	}, [posType]);

	useEffect(() => {
		dispatch(
			branchListFilter({
				branchFilter: {
					name: branchFilter.name,
					posType: branchFilter.posType,
					storeType: storeType.join('|'),
					storeFormat: branchFilter.storeFormat,
					machineLocation: branchFilter.machineLocation,
				},
			})
		);
	}, [storeType]);

	useEffect(() => {
		dispatch(
			branchListFilter({
				branchFilter: {
					name: branchFilter.name,
					posType: branchFilter.posType,
					storeType: branchFilter.storeType,
					storeFormat: storeFormat.join('|'),
					machineLocation: branchFilter.machineLocation,
				},
			})
		);
	}, [storeFormat]);

	useEffect(() => {
		dispatch(
			branchListFilter({
				branchFilter: {
					name: branchFilter.name,
					posType: branchFilter.posType,
					storeType: branchFilter.storeType,
					storeFormat: branchFilter.storeFormat,
					machineLocation: machineLocation.join('|'),
				},
			})
		);
	}, [machineLocation]);

	return (
		<>
			<div ref={dropdownRef}>
				<Button className={styles.filterButton} onClick={handleToggle}>
					Filter
					<AiOutlineCaretDown className={styles.filterIcon} />
				</Button>
				{isOpen && (
					<div className={styles.panel}>
						<div className={styles.topPanel}>
							<label className={styles.selectAll}>
								<Checkbox
									label="Select All"
									type="checkbox"
									checked={selectAll}
									onChange={handleSelectAll}
								/>
							</label>
							<label className={styles.clearAll}>
								<button
									className={styles.clearButton}
									onClick={clearBranchFilter}
								>
									<AiOutlineClose className={styles.clearIcon} />
									Clear All Fields
								</button>
							</label>
						</div>
						<div className={styles.panelContent}>
							<div className={styles.panelBody}>
								<div className={styles.posCol}>
									<div>
										<b>POS Type</b>
									</div>
									<Checkbox
										label="Asenso"
										type="checkbox"
										checked={posType.includes('ASENSO')}
										onChange={() => handlePosTypeCheckbox('ASENSO')}
									/>
									<Checkbox
										label="BFA"
										type="checkbox"
										checked={posType.includes('BFA')}
										onChange={() => handlePosTypeCheckbox('BFA')}
									/>
									<Checkbox
										label="PCS"
										type="checkbox"
										checked={posType.includes('PCS')}
										onChange={() => handlePosTypeCheckbox('PCS')}
									/>
									<Checkbox
										label="BIOS"
										type="checkbox"
										checked={posType.includes('BIOS')}
										onChange={() => handlePosTypeCheckbox('BIOS')}
									/>
									<Checkbox
										label="Kiosk/Self-Service"
										type="checkbox"
										checked={posType.includes('KIOSK')}
										onChange={() => handlePosTypeCheckbox('KIOSK')}
									/>
									<Checkbox
										label="Own Front-end System"
										type="checkbox"
										checked={posType.includes('FRONT_END')}
										onChange={() => handlePosTypeCheckbox('FRONT_END')}
									/>
								</div>
								<div className={styles.storeCol}>
									<div>
										<b>Store Type</b>
									</div>
									<Checkbox
										label="Full Booth"
										type="checkbox"
										checked={storeType.includes('FULL-BOOTH')}
										onChange={() => handleStoreTypeCheckbox('FULL-BOOTH')}
									/>
									<Checkbox
										label="Booth"
										type="checkbox"
										checked={storeType.includes('BOOTH')}
										onChange={() => handleStoreTypeCheckbox('BOOTH')}
									/>

									<div style={{ marginTop: '60px', marginBottom: '20px' }}>
										<b>Store Format</b>
									</div>
									<Checkbox
										label="Standalone"
										type="checkbox"
										checked={storeFormat.includes('STANDALONE')}
										onChange={() => handleStoreFormatCheckbox('STANDALONE')}
									/>
									<Checkbox
										label="Mall-based"
										type="checkbox"
										checked={storeFormat.includes('MALL-BASED')}
										onChange={() => handleStoreFormatCheckbox('MALL-BASED')}
									/>
								</div>
								<div className={styles.machineLocCol}>
									<div>
										<b>Machine Location</b>
									</div>
									<Checkbox
										label="Inside Biller Office"
										type="checkbox"
										checked={machineLocation.includes('INSIDE')}
										onChange={() => handleMachineLocationCheckbox('INSIDE')}
									/>
									<Checkbox
										label="Outside Biller Office"
										type="checkbox"
										checked={machineLocation.includes('OUTSIDE')}
										onChange={() => handleMachineLocationCheckbox('OUTSIDE')}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default BranchDropdownCheckbox;
