import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  data: {
    details: null,
    logs: []
  },
  showModal: false
}

const branchWallet = createSlice({
  name: "branchWallet",
  initialState,
  reducers: {
    prep: (state, payload) => {
    },
    load: (state, payload) => {
      state.data.details = {
        ...payload.result.data,
        created_at: moment(new Date(payload.result.data.created_at)).format(
          "MM/DD/YYYY h:mm:ss a"
        ),
      };
      state.data.logs = payload.result.logs;
      state.showModal = true
    },
    error: () => {
      console.log("Error");
    },
    closeModal: (state) => {
      state.showModal = false
    }
  }
})

const { prep, load, error, closeModal } = branchWallet.actions;

const createBranchWallet = (client, state) => {
  return client.get(`/v1/transactions/${state.data}`);
};
function createWalletActionCreator(data) {
  return {
    types: [prep.type, load.type, error.type],
    promise: createBranchWallet,
    data
  };
}

export default branchWallet.reducer;
export { createWalletActionCreator, closeModal };