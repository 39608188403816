import styles from './AuditTrail.module.css';
import cx from 'classnames';

import React, { useEffect, useState } from 'react';

import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';

import { showAccessDeniedModal } from 'redux/modules/access';
import { useDispatch } from 'react-redux';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import AuditTrailLogs from './AuditTrailLogs';
import Table from 'components/CWSTable/Table';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import PrimaryButton from 'components/Buttons/PrimaryButton';

type Props = {
	auditTrailData: any;
	reportid: string;
	hasAuditTrailLogsPermission: boolean;
	tabName: string;
};

const AuditTrail: React.FC<Props> = ({
	auditTrailData,
	reportid,
	hasAuditTrailLogsPermission,
	tabName,
}) => {
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [allData, setAllData] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [showLogs, setShowLogs] = useState(false);
	const [selectAuditTrailID, setsSelectAuditTrailID] = useState<
		string | number
	>(0);

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const handleOnClickViewLogs = async (row) => {
		setShowLogs(true);
		const audit_id = row.original.id;
		setsSelectAuditTrailID(audit_id);
	};

	const handleCloseLogs = () => setShowLogs(false);

	useEffect(() => {
		setAllData(auditTrailData);
		setCount(auditTrailData.length);
		const tabledata = auditTrailData.slice(0, 25);
		setData(tabledata);
	}, [auditTrailData]);

	const handlePageChange = async (_page, _pageSize) => {
		setPage(_page);
		setPageSize(_pageSize);
		const newPageSize = _pageSize * _page;
		const newPage = _page - 1;
		const tabledata = allData.slice(newPage * _pageSize, newPageSize);
		setData(tabledata);
	};

	const AUDIT_TRAIL_COLUMNS = [
		{
			Header: 'Date & Time',
			id: 'created_at',
			sortable: false,
			width: '20%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Logged By',
			id: 'logged_by',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Action',
			id: 'action',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Source IP',
			id: 'ip_address',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			width: '16%',
		},
		{
			Header: '',
			id: 'logs',
			sortable: false,
			width: '16%',
			Cell: ({ row }) => (
				<div className={styles.actionContainer}>
					<div
						className={cx(styles.action)}
						onClick={() => {
							if (!hasAuditTrailLogsPermission) {
								dispatch(showAccessDeniedModal());
								return;
							}
							handleOnClickViewLogs(row);
						}}
						onKeyDown={()=>{return}}
					>
						View Logs
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<div className={styles.tableContainer}>
				<Table
					columns={AUDIT_TRAIL_COLUMNS}
					data={data}
					page={page}
					count={count}
					pageSize={pageSize}
					onPageChange={handlePageChange}
					preHeader={undefined}
					modalIsOpen={undefined}
					isNotEvenRowColor={undefined}
					sort={'desc'}
					sortBy={'createdAt'}
				></Table>
			</div>
			{showLogs && (
				<AuditTrailLogs
					modalOpen={showLogs}
					onClose={handleCloseLogs}
					logid={selectAuditTrailID}
					reportid={reportid}
					tabName={tabName}
				/>
			)}
		</>
	);
};

export default AuditTrail;
