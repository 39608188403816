import {
	Radio,
	RadioGroup as LDRadioGroup,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './RadioGroup.module.css';
import { isEmpty } from 'lodash';

export type RadioOption = {
	label: string;
	value: any;
};

type Props = {
	label?: any;
	onChange?: any;
	disabled?: boolean;
	name?: string;
	value?: string;
	defaultValue?: string;
	options: RadioOption[];
	error?: string;
	required?: boolean;
	isVertical?: boolean;
	centerHorizontally?: boolean;
	indented?: boolean;
	subText?: string;
	isHorizontalLabel?: boolean;
};

const PlainRadioGroup: React.FC<Props> = ({
	value,
	options,
	disabled,
	name,
	label,
	error,
	onChange,
	required,
	isVertical = false,
	centerHorizontally,
	indented = false,
	subText = '',
	isHorizontalLabel,
}) => {
	return (
		<div
			className={cx(
				isVertical ? styles.containerVertical : styles.containerHorizontal,
				centerHorizontally && isVertical == false
					? styles.centerHorizontally
					: null
			)}
		>
			<div className={cx(error && styles.selectError)}>
				<LDRadioGroup
					labels={!isHorizontalLabel && !required && { label }}
					label={label}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						onChange && onChange(event.target.value)
					}
					className={cx({ [styles.horizontalRadio]: isHorizontalLabel })}
					name={name}
				>
					<div>
						{!isHorizontalLabel && required && (
							<Label required={true}>{label} </Label>
						)}
						{!!subText && (
							<div className={styles.subtext}>
								<em>{subText}</em>
							</div>
						)}
						<div
							className={cx(
								required ? styles.radioOptions : styles.radioOptionsBelow,
								indented && styles.indented
							)}
						>
							{isHorizontalLabel && (
								<Label
									required={required}
									className={isHorizontalLabel ? styles.horizontalLabel : ''}
								>
									{label}
								</Label>
							)}
							{options?.map(({ label, value: v }, i) => (
								<Radio
									disabled={disabled}
									key={i}
									labels={{ label }}
									value={v}
									checked={value == v}
									className={cx(styles.radio, {
										[styles.checked]: value == v,
										[styles.horizontalRadio]: isHorizontalLabel,
									})}
								/>
							))}
						</div>
					</div>
				</LDRadioGroup>
			</div>

			{error && (
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioGroup: React.FC<Props & { control?: Control<any> }> = ({
	control,
	name,
	defaultValue,
	...rest
}) => {
	if (control && name) {
		const { onChange } = rest;
		return (
			<Controller
				name={name}
				render={({ field, fieldState: { error } }) => (
					<PlainRadioGroup
						{...field}
						{...rest}
						error={error?.message}
						onChange={(v) => {
							field.onChange(v);
							onChange && onChange(v);
						}}
					/>
				)}
				control={control}
				defaultValue={defaultValue}
			/>
		);
	}

	return <PlainRadioGroup {...rest} />;
};

export default RadioGroup;
