import { createSlice, Reducer } from '@reduxjs/toolkit';

export type FormReducerType = {
	status: {
		isValid: boolean;
		isDirty: boolean;
		isDisabled: boolean;
		submitting: boolean;
		submittingPI: boolean;
		submittingCD: boolean;
		submittingSFS: boolean;
		submittingBR: boolean;
		submittingRP: boolean;
		submittingBU: boolean;
		submittingDraft: boolean;
		values: any;
	};
	billerUploadValue: any;
};

const initialState: FormReducerType = {
	status: {
		isValid: false,
		isDirty: false,
		isDisabled: true,
		submitting: false,
		submittingPI: false,
		submittingCD: false,
		submittingSFS: false,
		submittingBR: false,
		submittingRP: false,
		submittingBU: false,
		submittingDraft: false,
		values: {},
	},
	billerUploadValue: {},
};	
const form = createSlice({
	name: 'form',
	initialState,
	reducers: {
		update: (state, { payload }: any) => {
			state.status = {...state.status, ...payload};
		},
		updateValues: (state, { payload }: any) => {
			state.status.values = payload;
		},
		updateBillerUpload: (state, { payload }: any) => {
			state.billerUploadValue = payload;
		},
		submitPI: (state) => {
			state.status.submittingPI = true;
		},
		submittedPI: (state) => {
			state.status.submittingPI = false;
		},
		submitCD: (state) => {
			state.status.submittingCD = true;
		},
		submittedCD: (state) => {
			state.status.submittingCD = false;
		},
		submitSFS: (state) => {
			state.status.submittingSFS = true;
		},
		submittedSFS: (state) => {
			state.status.submittingSFS = false;
		},
		submitBR: (state) => {
			state.status.submittingBR = true;
		},
		submittedBR: (state) => {
			state.status.submittingBR = false;
		},
		submitRP: (state) => {
			state.status.submittingRP = true;
		},
		submittedRP: (state) => {
			state.status.submittingRP = false;
		},
		submitBU: (state) => {
			state.status.submittingBU = true;
		},
		submittedBU: (state) => {
			state.status.submittingBU = false;
		},
		submit: (state) => {
			state.status.submitting = true;
		},
		submitted: (state) => {
			state.status.submitting = false;
		},
		submitDraft: (state) => {
			state.status.submittingDraft = true;
		},
		submittedDraft: (state) => {
			state.status.submittingDraft = false;
		},
		updateIsDisabled: (state, { payload })=> {
			state.status.isDisabled = payload ;
		}
	},
});

const {
	update,
	submit,
	submitted,
	updateValues,
	updateBillerUpload,
	submitPI,
	submittedPI,
	submitCD,
	submittedCD,
	submitSFS,
	submittedSFS,
	submitBR,
	submittedBR,
	submitRP,
	submittedRP,
	submitBU,
	submittedBU,
	submitDraft,
	submittedDraft,
	updateIsDisabled,
} = form.actions;

export {
	update,
	submit,
	submitted,
	updateValues,
	updateBillerUpload,
	submitPI,
	submittedPI,
	submitCD,
	submittedCD,
	submitSFS,
	submittedSFS,
	submitBR,
	submittedBR,
	submitRP,
	submittedRP,
	submitBU,
	submittedBU,
	submitDraft,
	submittedDraft,
	updateIsDisabled,
};

export default form.reducer;
