import { ReactElement } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import styles from './Alert.module.css';
import { handleKeyDown } from 'utils/utils';

type AlertProps = {
	iconLeft?: () => ReactElement<any>;
	bodyText?: string;
	cancelFn?: (v?: any) => void;
	confirmFn: (v?: any) => void;
};
const Alert: React.FC<AlertProps> = ({
	iconLeft,
	bodyText,
	cancelFn,
	confirmFn,
}) => {
	return (
		<div className={styles.alertContainer}>
			<span
				style={{
					fontFamily: 'normal normal normal 12px/18px Poppins-Regular',
					color: '#3b3b3b',
				}}
			>
				{iconLeft && <span style={{ marginRight: '16px' }}>{iconLeft()}</span>}{' '}
				{bodyText}
			</span>
			<div
				style={{
					marginLeft: 'auto',
				}}
			>
				<a className={styles.alertCancel} onClick={cancelFn} onKeyDown={(e) => handleKeyDown(e, cancelFn)}>
					Cancel
				</a>
				<a className={styles.alertConfirm} onClick={confirmFn} onKeyDown={(e) => handleKeyDown(e, confirmFn)}>
					Confirm
				</a>
				<CloseIcon
					className={styles.alertCloseIcon}
					onClick={cancelFn}
					onKeyDown={(e) => handleKeyDown(e, cancelFn)}
					height={12}
					width={12}
				/>
			</div>
		</div>
	);
};

export default Alert;
