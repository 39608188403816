import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import Checkbox from 'components/Checkbox/Checkbox';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import Actions from '../../Actions';
import ChannelApprovalTabFilter, {
	getUniqueValues,
} from '../../ApprovalTabFilter';
import {
	AccessDeniedBodyText,
	ApproveErrorModal,
	ApproveRejectModal,
	ApproveSuccessModal,
	PreHeader,
} from '../../ChannelApprovalModals';
import {
	UseApproveRejectServiceFee,
	UseGetServiceFeeData,
	useGetFeeApprovalList,
	UseGetProductTypeFilter,
	UseGetSFSFilters,
} from '../../ChannelApprovalQuery';
import {
	APPROVAL_LIST_FILTER,
	LOADING_MESSAGE,
	SERVICE_FEE_TABLE_COLUMNS,
	TABLE_FILTER,
} from '../../constants';
import styles from '../../styles.module.css';
import {
	ChannelFilter,
	ErrorMessage,
	OptionValues,
	ServiceFeeApprovalRowData,
} from '../../types';
import ServiceFeeApprovalModal from './ServiceFeeApprovalModal';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import { useList } from 'containers/ChannelManagement/List/hooks';
import { useDispatch } from 'react-redux';
import { useChannelPermission } from 'containers/ChannelManagement/Permission/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';

type Props = {
	name: string;
	selectedIndex: number;
};
const ServiceFeeApprovalTab: React.FC<Props> = ({ name, selectedIndex }) => {
	const { watch, reset, control, getValues, resetField } =
		useFormContext<ChannelFilter>();

	const [tableFilter, setTableFilter] = useState({ ...TABLE_FILTER });
	const [approvalListParam, setApprovalListParam] = useState<
		typeof APPROVAL_LIST_FILTER | typeof TABLE_FILTER
	>({ ...APPROVAL_LIST_FILTER, ...TABLE_FILTER });
	const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
		header: '',
		message: '',
		type: '',
		isCommonError: true,
	});
	const [serviceFeeData, setServiceFeeData] = useState<any>({});
	const [successMessage, setSuccessMessage] = useState<any>('');
	const [optionValues, setOptionValues] = useState<OptionValues>({});
	const [approvalRemarks, setApprovalRemarks] = useState<string>('');
	const [isAllRowsSelected, setAllRowsSelected] = useState<any>(false);
	const [channelPrimaryInfo, setChannelPrimaryInfo] = useState<any>({});
	const [responseTableFilter, setResponseTableFilter] = useState<any>();
	const [tableData, setTableData] = useState<ServiceFeeApprovalRowData[]>([]);
	const [hideParentCheckbox, setHideParentCheckbox] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<ServiceFeeApprovalRowData[]>(
		[]
	);
	const [selectedRow, setSelectedRow] = useState<ServiceFeeApprovalRowData[]>(
		[]
	);
	const [searchChanged, setSearchChanged] = useState<boolean>(false);
	const [checkBoxLength, setCheckBoxLength] = useState<number>(0);
	const dispatch = useDispatch();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();
	const {
		value: isServiceFeeDetailsOpen,
		valueOn: showServiceFeeDetailsModal,
		valueOff: hideServiceFeeDetailsModal,
	} = useToggle();
	const {
		value: isApprove,
		valueOn: showApprove,
		valueOff: hideApprove,
	} = useToggle();
	const {
		value: isReject,
		valueOn: showReject,
		valueOff: hideReject,
	} = useToggle();
	const {
		value: isError,
		valueOn: showIsError,
		valueOff: hideIsError,
	} = useToggle();
	const {
		value: isSuccess,
		valueOn: showIsSuccess,
		valueOff: hideIsSuccess,
	} = useToggle();
	const {
		value: isBatchApprove,
		valueOn: showBatchApprove,
		valueOff: hideBatchApprove,
	} = useToggle();
	const {
		value: isBatchReject,
		valueOn: showBatchReject,
		valueOff: hideBatchReject,
	} = useToggle();
	const hideIsSuccessAndChannelDetails = () => {
		hideIsSuccess();
		approvalListQuery?.refetch();
	};
	const approvalListQuery = useGetFeeApprovalList(approvalListParam);
	const filterDebounce = useCallback(
		debounce(() => {
			const { serviceFeeTabFilter } = getValues();
			setApprovalListParam((prevValues) => ({
				...prevValues,
				...serviceFeeTabFilter,
				...tableFilter,
			}));
			approvalListQuery.refetch();
		}, 500),
		[tableFilter]
	);

	useEffect(() => {
		resetField('serviceFeeTabFilter');
		reset();
	}, [selectedIndex]);

	useEffect(() => {
		const getProductTypeFilter = async () => {
			await UseGetProductTypeFilter()
				.then((res) => {
					const optionsProductType: SelectOption[] = res.map((element) => ({
						label: element.name,
						value: element.id,
					}));
					setOptionValues((prev) => ({
						...prev,
						optionsProductType: optionsProductType,
					}));
				})
				.catch((err) => {
					setErrorMessage({
						header: '',
						message: 'Something went wrong.',
						isCommonError: true,
					});
					showIsError();
				});
			await UseGetSFSFilters()
				.then((res) => {
					const optionsSubmittedBy = res.submittedByList.map((element) => ({
						label: element.submittedBy,
						value: element.submittedBy,
					}));
					const optionsChannelAccount = res.channelList.map((element) => ({
						label: element.channelAccountName,
						value: element.channelAccountName,
					}));
					const optionsPartner = res.partnerList.map((element) => ({
						label: element.partner,
						value: element.partner,
					}));
					setOptionValues((prev) => ({
						...prev,
						optionsPartner,
						optionsSubmittedBy,
						optionsChannelAccount,
					}));
				})
				.catch((err) => {
					setErrorMessage({
						header: '',
						message: 'Something went wrong.',
						isCommonError: true,
					});
					showIsError();
				});
		};
		if (selectedIndex == 1) {
			filterDebounce();
			getProductTypeFilter();
		} else return;
	}, [tableFilter, selectedIndex]);

	useEffect(() => {
		if (watch('serviceFeeTabFilter.search')) {
			if (watch('serviceFeeTabFilter.search')?.length > 2) {
				filterDebounce();
				setTableFilter((prev) => ({ ...prev, page: 1 }));
			}
			setSearchChanged(true);
		}
		if (searchChanged && watch('serviceFeeTabFilter.search') == '') {
			filterDebounce();
			setTableFilter((prev) => ({ ...prev, page: 1 }));
		}
	}, [watch('serviceFeeTabFilter.search')]);

	useEffect(() => {
		if (selectedIndex == 1) {
			if (approvalListQuery.isSuccess) {
				const { data, ...tableFilter } = approvalListQuery.data;
				if (tableFilter.total) {
					setResponseTableFilter({ ...tableFilter });
				}
				setTableData(
					data.map((element) => {
						const checkedRows = selectedRows.find(
							(item) => item.id === element.id
						);

						if (checkedRows) {
							return { ...element, checked: checkedRows.checked };
						}

						return { ...element, checked: false };
					})
				);
				setHideParentCheckbox(
					data.findIndex((element) => element.status === 'FOR_REVIEW') < 0
				);
				setCheckBoxLength(
					data.filter((element) => element.status == 'FOR_REVIEW').length
				);
				setOptionValues((prev) => ({
					...prev,
					optionsSubmittedBy: getUniqueValues(data, 'submittedBy'),
					optionsChannelAccount: getUniqueValues(data, 'channelAccountName'),
					optionsPartner: getUniqueValues(data, 'partner'),
				}));
				hideLoading();
			}
			if (approvalListQuery.isFetching) {
				setTableData([]);
				showLoading();
			}
			if (approvalListQuery.isError) {
				showIsError();
				setErrorMessage({
					header: 'Network Error',
					message: '',
					isCommonError: true,
				});
				hideLoading();
			}
		}
	}, [
		approvalListQuery.isLoading,
		approvalListQuery.isSuccess,
		approvalListQuery.isError,
		approvalListQuery.isFetching,
	]);

	const handleAction = async (
		action: string,
		value: ServiceFeeApprovalRowData
	) => {
		setSelectedRow([{ ...value, checked: true }]);
		if (action == 'details') {
			if (hasFeesApprovalDetailsChannelPermission) {
				showLoading();
				try {
					const [serviceFeeData] = await Promise.all([
						UseGetServiceFeeData(value.sfsIdentifier),
					]);
					hideLoading();

					setServiceFeeData(serviceFeeData.data);
					showServiceFeeDetailsModal();
				} catch (error) {
					setErrorMessage({
						header: 'Network Error',
						message: '',
						isCommonError: true,
					});
					showIsError();
					hideLoading();
				}
			} else dispatch(showAccessDeniedModal());
		}
		if (action == 'approve') {
			if (hasFeesApprovalApproveChannelPermission) {
				showApprove();
			} else dispatch(showAccessDeniedModal());
		}
		if (action == 'reject') {
			if (hasFeesApprovalRejectChannelPermission) {
				showReject();
			} else dispatch(showAccessDeniedModal());
		}
	};

	const approve = async (
		remarks: string,
		isRetry: boolean,
		isApprove: boolean,
		isBatch: boolean | undefined = false
	) => {
		setApprovalRemarks(remarks);
		const sfsIdentifier = [selectedRow[0]?.sfsIdentifier];
		const sfsIdentifiers = await Promise.all(
			selectedRows.map((element) => {
				return element.sfsIdentifier;
			})
		);
		if (sfsIdentifier.length > 0 || sfsIdentifiers.length > 0) {
			showLoading();
			await UseApproveRejectServiceFee(
				isBatch ? sfsIdentifiers : sfsIdentifier,
				remarks,
				isApprove
			)
				.then(() => {
					hideLoading();
					hideServiceFeeDetailsModal();
					if (isBatch) {
						setSuccessMessage(
							<>
								<strong>Service Fee Share</strong> requests are{' '}
								{isApprove ? 'Approved' : 'Rejected'}.
							</>
						);
						showIsSuccess();
						isApprove ? hideBatchApprove() : hideBatchReject();
					}
					if (!isBatch) {
						setSuccessMessage(
							<>
								<strong>Service Fee Share</strong> is now{' '}
								{isApprove ? 'Approved' : 'Rejected'}
							</>
						);
						showIsSuccess();
						isApprove ? hideApprove() : hideReject();
					}
					setSelectedRow([]);
					setSelectedRows([]);
				})
				.catch((response) => {
					hideLoading();
					setErrorMessage({
						header: 'Something went wrong.',
						message: '',
						isBatch: isBatch,
						type: isApprove ? 'Approved' : 'Rejected',
						isCommonError: false,
					});

					if (!isRetry) showIsError();
				})
				.finally(() => {
					setAllRowsSelected(false);
				});
		}
		return;
	};

	const retry = (param, isBatch) => {
		approve(approvalRemarks, true, param == 'Approved', isBatch);
	};

	const updatedColumn = SERVICE_FEE_TABLE_COLUMNS.map((element) => {
		if (element.id === 'action') {
			return {
				...element,
				Header: () => <div className={cx(styles.centerAlign)}>Action</div>,
				accessor: (values: any) => values,
				Cell: ({ value }) => {
					return (
						<Actions
							value={value}
							action={handleAction}
							showApproveButton={hasFeesApprovalApproveChannelPermission}
							showDetailsButton={hasFeesApprovalDetailsChannelPermission}
							showRejectButton={hasFeesApprovalRejectChannelPermission}
						/>
					);
				},
			};
		}
		if (element.id === 'status') {
			return {
				...element,
				accessor: ({ status }) =>
					status
						.toLowerCase()
						.split('_')
						.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
						.join(' '),
				Cell: ({ value }) => {
					return (
						<div className={styles[value.replaceAll(' ', '')]}>{value}</div>
					);
				},
			};
		}
		if (element.id === 'checked') {
			return {
				...element,
				Header: () => {
					return (
						<div>
							<Checkbox
								label=""
								type="checkbox"
								onChange={(v) => {
									setSelectedRows([]);
									const updateData = tableData.map((element, index) => {
										if (element.status == 'FOR_REVIEW' && index < 10) {
											if (v) {
												setSelectedRows((prev) => {
													return [...prev, { ...element, checked: v }];
												});
											}
											return { ...element, checked: v };
										}
										return { ...element, checked: false };
									});
									setAllRowsSelected(v);
									setTableData(updateData);
								}}
								checked={isAllRowsSelected}
								disabled={
									!(
										hasFeesApprovalApproveChannelPermission ||
										hasFeesApprovalRejectChannelPermission
									)
								}
								className={
									hideParentCheckbox ? cx('slds-is-visually-empty') : ''
								}
							/>
						</div>
					);
				},
				accessor: (values: any) => values,
				Cell: ({ value }) => {
					if (value.status == 'FOR_REVIEW') {
						return (
							<div>
								<Checkbox
									label=""
									type="checkbox"
									onChange={(v) => {
										if (!v || selectedRows.length < 10) {
											const updateData = tableData.map((element) => {
												if (value.id == element.id) {
													return { ...element, checked: v };
												}
												return element;
											});
											setTableData(updateData);
											if (v) {
												setSelectedRows((prev) => {
													return [...prev, { ...value, checked: v }];
												});
											} else {
												setSelectedRows((prev) => {
													return prev.filter((item) => item.id !== value.id);
												});
											}
										} else {
											showIsError();
											setErrorMessage({
												header: '',
												message:
													'Only max of 10 request can be processed at a time',
												isCommonError: true,
											});
										}
									}}
									checked={value.checked}
									disabled={
										!(
											hasFeesApprovalApproveChannelPermission ||
											hasFeesApprovalRejectChannelPermission
										)
									}
								/>
							</div>
						);
					} else return null;
				},
			};
		}
		return element;
	});

	const {
		hasFeesApprovalListChannelPermission,
		hasFeesApprovalDetailsChannelPermission,
		hasFeesApprovalApproveChannelPermission,
		hasFeesApprovalRejectChannelPermission,
	} = useChannelPermission();

	useEffect(() => {
		if (!hasFeesApprovalListChannelPermission) {
			dispatch(showAccessDeniedModal());
		}
	}, []);

	const { channelTypeFilter } = useList();

	return (
		<>
			{hasFeesApprovalListChannelPermission ? (
				<>
					<ChannelApprovalTabFilter
						control={control}
						name={name}
						reset={reset}
						filter={() => {
							filterDebounce();
							setTableFilter((prev) => ({ ...prev, page: 1 }));
						}}
						optionValues={optionValues}
					/>
					<Table
						columns={updatedColumn}
						data={tableData}
						count={responseTableFilter?.total}
						pageSize={responseTableFilter?.pageSize}
						page={responseTableFilter?.page}
						sortBy="createdAt"
						sort="desc"
						onPageChange={(page, limit) => {
							setTableFilter((prev) => ({ ...prev, page, limit }));
							filterDebounce();
						}}
						onSort={(sortBy, sort) => {
							setTableFilter((prev) => ({
								...prev,
								sortBy,
								sort: sort.toLowerCase(),
							}));
							filterDebounce();
						}}
						isNotEvenRowColor={false}
						modalIsOpen={false}
						preHeader={
							selectedRows.length > 0 && (
								<PreHeader
									selectedRows={selectedRows}
									showApprove={showBatchApprove}
									showReject={showBatchReject}
									hasApprovePermission={hasFeesApprovalApproveChannelPermission}
									hasRejectPermission={hasFeesApprovalRejectChannelPermission}
									setAllRowsSelected={setAllRowsSelected}
									targetLength={checkBoxLength > 10 ? 10 : checkBoxLength}
								/>
							)
						}
						withRowField={false}
					/>
					{isLoading && (
						<FullPageLoader open={isLoading} message={LOADING_MESSAGE} />
					)}
					{isServiceFeeDetailsOpen && (
						<ServiceFeeApprovalModal
							isOpen={isServiceFeeDetailsOpen}
							onRequestClose={hideServiceFeeDetailsModal}
							data={{ serviceFeeData, channelPrimaryInfo }}
							selectedRow={selectedRow[0]}
							showApproveModal={showApprove}
							showRejectModal={showReject}
							hasApprovePermission={hasFeesApprovalApproveChannelPermission}
							hasRejectPermission={hasFeesApprovalRejectChannelPermission}
							channelTypes={channelTypeFilter}
						/>
					)}
					{isApprove && (
						<ApproveRejectModal
							isOpen={isApprove}
							hideApprove={hideApprove}
							approveReject={approve}
							rowData={selectedRow}
							isApprove={true}
							isBatchApprove={false}
							headerLabel="Service Fee Share"
						/>
					)}
					{isReject && (
						<ApproveRejectModal
							isOpen={isReject}
							hideApprove={hideReject}
							approveReject={approve}
							rowData={selectedRow}
							isApprove={false}
							isBatchApprove={false}
							headerLabel="Service Fee Share"
						/>
					)}
					{isError && (
						<ApproveErrorModal
							errorMessage={errorMessage}
							hideErrorModal={hideIsError}
							isErrorModalShown={isError}
							retry={retry}
						/>
					)}
					{isSuccess && (
						<ApproveSuccessModal
							hideSuccessModal={hideIsSuccessAndChannelDetails}
							isSuccessModalShown={isSuccess}
							successMessage={successMessage}
						/>
					)}
					{isBatchApprove && (
						<ApproveRejectModal
							isOpen={isBatchApprove}
							hideApprove={hideBatchApprove}
							approveReject={approve}
							rowData={selectedRows}
							isApprove={true}
							isBatchApprove={true}
						/>
					)}
					{isBatchReject && (
						<ApproveRejectModal
							isOpen={isBatchReject}
							hideApprove={hideBatchReject}
							approveReject={approve}
							rowData={selectedRows}
							isApprove={false}
							isBatchApprove={true}
						/>
					)}
				</>
			) : null}
		</>
	);
};

export default ServiceFeeApprovalTab;
