import { useForm } from 'react-hook-form';
import styles from './PrimaryInformation.module.css';
import PartnerAddress from './PartnerAddress/PartnerAddress';
import BasicInformation from './BasicInformation/BasicInformation';
import PartnerContactDetails from './PartnerContactDetails/PartnerContactDetails';
import AccountOfficer from './AccountOfficer/AccountOfficer';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	TProductState,
	setEditing,
	setPrimaryInfo,
	updateValidForm,
	setTrackPrimaryInfo,
} from 'redux/modules/products';
import { PartnerFormProps } from 'components/PartnerForm/PartnerForm';
import client from 'helpers/ApiClient';
import { debounce, isEmpty } from 'lodash';
import { setAutoSaveValues } from 'redux/modules/products';
import { product_primary_info } from 'utils/models/product_primary_info';
import { product_affiliate } from 'utils/models/product_affiliate';
import { product_address } from 'utils/models/product_address';
import { product_contact_detail } from 'utils/models/product_contact_detail';
import { product_officer } from 'utils/models/product_officer';
import { primary_info_schema, product } from 'utils/models/product';
import { ExcludeAutoSave } from 'components/PartnerDetails/PartnerDetails';
import { setSubmittingAll, setDrafting } from 'redux/modules/products';
import { AddressDefaultValue, ContactDefaultValue } from 'utils/lookup';
import { usePreviousDistinct } from 'react-use';
import { replaceNoValuesObj, resolveRecord } from 'utils/common';
import { isRmtProduct } from 'constants/rmtProducts';
import { TPartnerModalConfirmProps } from 'types';

export type PrimaryInformationFormData = {
	code?: string; // merchant id
	product_code_type?: string;
	product_code?: string;
	logo_url?: string;
	product_affiliates?: product_affiliate[];
	product_addresses?: product_address[];
	product_contact_details?: product_contact_detail[];
	product_officer?: product_officer;
	product_primary_info?: product_primary_info;
} & product_primary_info;
type Props = {
	action?: string;
	data?: any;
	disabled?: boolean;
	onSubmit?: PartnerFormProps['onSubmit'];
	currentPartnerTab?: string;
	lastActionButton: TProductState['lastActionButton'];
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['autoSaveValues'];
	setSubmittingAll?: any;
	setDrafting?: any;
	setEditing?: any;
	setPrimaryInfo: any;
	errorMessage?: string;
	handleChanges?: any;
	productId: number;
	products: any;
	showConfirmationMessage?: TPartnerModalConfirmProps;
	setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	fromAutoSave: boolean;
};
const PrimaryInformation: React.FC<Props> = ({
	action,
	data = {},
	disabled,
	onSubmit = () => {},
	currentPartnerTab,
	lastActionButton,
	autoSaveValues,
	setAutoSaveValues,
	setSubmittingAll,
	setDrafting,
	setEditing,
	setPrimaryInfo,
	errorMessage,
	productId,
	products,
	showConfirmationMessage,
	setIsDisabled,
	fromAutoSave,
}) => {
	const dispatch = useDispatch();

	const submittingDraft = useSelector<ReducerStateType>(
		(state) => state.form.status.submittingDraft
	);
	const statusSubmitting = useSelector<ReducerStateType>(
		(state) => state.products.isSubmittingAll
	);
	const drafting = useSelector<ReducerStateType>(
		(state) => state.products.drafting
	);
	const editing = useSelector<ReducerStateType>(
		(state) => state.products.editing
	);

	const previousTab = usePreviousDistinct(currentPartnerTab);

	const defaultValues = {
		...data.primary_info,
		product_addresses: resolveRecord(data?.primary_info?.product_addresses, [
			AddressDefaultValue,
		]),
		product_contact_details: resolveRecord(
			data?.primary_info?.product_contact_details,
			[ContactDefaultValue]
		),
	};

	const {
		control,
		setValue,
		getValues,
		clearErrors,
		resetField,
		formState: { isDirty, isValid, errors },
		handleSubmit,
		reset,
		setError,
		watch,
		trigger,
	} = useForm<product>({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(primary_info_schema),
		context: { isRmtProduct: isRmtProduct(productId, products) },
	});

	const productShortName = watch('product_primary_info.product_short_name');
	const productCodeType = watch('product_code_type');
	const logo = watch('logo');

	useEffect(() => {
		setPrimaryInfo(structuredClone({ ...getValues(), isDirty, isValid }));
	}, [logo]);

	useEffect(() => {
		debounce(() => {
			dispatch(
				updateValidForm({
					formTab: 'primaryInfo',
					isValid,
					isDirty,
					isLoaded: true,
				})
			);
		}, 900)();
	}, [isValid, isDirty, dispatch]);

	const selectedProductId = getValues().product_primary_info?.product_id;

	useEffect(() => {
		if (errorMessage && errorMessage === 'Merchant ID is already existing') {
			setError('code', {
				type: 'custom',
				message: errorMessage,
			});
		}
	}, [errorMessage, errors]);

	useEffect(() => {
		if (drafting) {
			setPrimaryInfo(structuredClone({ ...getValues(), isDirty, isValid }));
			setDrafting(false);
		}
	}, [drafting]);

	useEffect(() => {
		if (editing) {
			if (getValues('code')) {
				handleSubmit((v) => {
					const validData = structuredClone({
						...v,
						isDirty,
						isValid,
					});

					const filteredValue = replaceNoValuesObj(validData);
					setPrimaryInfo(filteredValue);
				})();

				if (!isValid) {
					const data = structuredClone({
						...getValues(),
						isDirty,
						isValid,
					});

					const filteredValue = replaceNoValuesObj(data);
					setPrimaryInfo(filteredValue);
				}
				setEditing(false);
			}
		}
	}, [editing]);

	useEffect(() => {
		if (disabled) return;
		clearErrors();
		if (statusSubmitting || previousTab === 'primary-info') {
			handleSubmit((v) => {
				const validData = structuredClone({ ...v, isDirty, isValid });
				setPrimaryInfo(validData);
			})();

			if (!isValid) {
				const data = structuredClone({ ...getValues(), isDirty, isValid });
				setPrimaryInfo(data);
			}

			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab]);

	useEffect(() => {
		if (disabled) return;
		if (
			previousTab !== 'primary-info' ||
			!onSubmit ||
			!action ||
			ExcludeAutoSave.includes(action)
		)
			return;
		if (isDirty) {
			onSubmit(getValues(), 'AUTOSAVE');
		}
	}, [currentPartnerTab]);

	useEffect(() => {
		if (
			disabled ||
			(action && ExcludeAutoSave.includes(action)) ||
			!fromAutoSave
		)
			return;

		// get latest auto save values if has autosave in BE
		if (!isEmpty(autoSaveValues)) setIsDisabled(true);
		client.get('v2/autosave/products').then(({ data }) => {
			const { logo_url, originalValues: values } = data.data.primary_info;
			const {
				code,
				product_primary_info,
				product_affiliates,
				product_addresses,
				product_contact_details,
				product_officer,
				product_code_type,
				logo,
			} = values;

			const productCode = product_primary_info?.product_code;
			const formatForm = {
				code,
				product_primary_info: {
					...product_primary_info,
					product_code: productCode,
					logo_url,
					logo,
				},
				product_affiliates,
				product_addresses,
				product_contact_details,
				product_officer,
				product_code_type,
				product_code: productCode,
			};
			reset(formatForm);
			setAutoSaveValues({});
			setIsDisabled(false);
		});
	}, []);

	useEffect(() => {
		if (submittingDraft) {
			handleSubmit(
				async (values) => {
					if (onSubmit) {
						const response = (await onSubmit(
							values,
							lastActionButton
						)) as unknown as any;

						if (response.status === 'FAILED') {
							const {
								errors: { message },
							} = response;

							let alreadyFocused = false;

							if (message.includes('Merchant ID already exists')) {
								setError(
									'code',
									{
										type: 'manual',
										message: 'Merchant ID is already existing',
									},
									{ shouldFocus: true }
								);
								alreadyFocused = true;
							}
							if (message.includes('product_code')) {
								setError(
									'product_code',
									{
										type: 'manual',
										message: 'Partner Code is already existing',
									},
									{ shouldFocus: !alreadyFocused }
								);
							}
						} else {
							reset(data, { keepValues: true });
						}
					} else {
						reset(data, { keepValues: true });
					}
				},
				(errors, e) => {
					console.log(errors, e);
				}
			)();
		}
	}, [dispatch, handleSubmit, onSubmit, submittingDraft]);

	useEffect(() => {
		debounce(() => {
			const data = structuredClone({ ...getValues(), isDirty, isValid });
			dispatch(setTrackPrimaryInfo(data));
		}, 800)();
	}, [productShortName]);

	useEffect(() => {
		debounce(() => {
			trigger('product_code');
		}, 800)();
	}, [productCodeType]);

	// For reseting the form when refetched
	const isTabRefetched = useSelector<ReducerStateType>(
		(state) => state.products.primaryInfo?.isRefetched
	);

	useEffect(() => {
		// reset form if refetched
		if (isTabRefetched) {
			reset(defaultValues, { keepDirty: false });
			updateValidForm({ formTab: 'primaryInfo', isDirty, isRefetched: false });
		}
	}, [isTabRefetched, reset]);
	//

	return (
		<div className={styles.container}>
			<BasicInformation
				control={control}
				resetField={resetField}
				setValue={setValue}
				setError={setError}
				disabled={disabled}
				data={data}
				action={action}
				selectedProductId={selectedProductId}
				showConfirmationMessage={showConfirmationMessage}
			/>
			<hr className={styles.divider} />
			<PartnerAddress
				control={control}
				isDirty={isDirty}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				data={data}
				disabled={disabled}
				resetField={resetField}
				action={action}
				getValues={getValues}
				watch={watch}
				trigger={trigger}
			/>
			<hr className={styles.divider} />
			<PartnerContactDetails
				control={control}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				data={data}
				disabled={disabled}
				resetField={resetField}
				action={action}
			/>
			<hr className={styles.divider} />
			<AccountOfficer
				control={control}
				disabled={disabled}
				data={data.product_officer}
			/>
		</div>
	);
};

export default connect(
	(state: any) => ({
		lastActionButton: state.products.lastActionButton,
		currentPartnerTab: state.products.currentBillerTab,
		autoSaveValues: state.products.autoSaveValues,
		selectedBiller: state.products.selectedBiller,
		productId: state.sidebar.itemId,
		products: state.sidebar.products,
	}),
	{
		setAutoSaveValues,
		setSubmittingAll,
		setDrafting,
		setEditing,
		setPrimaryInfo,
	}
)(PrimaryInformation);
