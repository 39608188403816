import cx from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import logo from '../../assets/images/logo/logo-bayadIP@157x126.png';
import styles from './Sidebar.module.css';
import SidebarLink from '../SidebarLink/SidebarLink';
import {
	getProductLinks,
	MenuItem as MenuItemType,
	SideBarSlice,
	toggleMenuItem as toggleMenuItemAc,
	toggleSubMenuItem as toggleSubMenuItemAc,
} from '../../redux/modules/sidebar';
import IconResolver from '../../helpers/IconResolver';
import { useHistory, useLocation } from 'react-router-dom';
import { OnClick } from 'types';
import { ReducerStateType } from '../../redux/modules/reducers';
import { useEffect } from 'react';
import { setProductCategoryBaseOnPath } from 'redux/modules/products';

type IconProps = {
	collapsed?: boolean;
	label: string;
	collapsedIcon: string;
	expandedIcon: string;
};

const Icon: React.FC<IconProps> = ({
	collapsed,
	label,
	collapsedIcon,
	expandedIcon,
}) => (
	<img
		className={styles.icon}
		src={collapsed ? collapsedIcon : expandedIcon}
		alt={`${label} Icon`}
	/>
);

type SubMenu = {
	id: string;
	link: string;
	name: string;
	onCollapsibleSubmenuClick?: (id: string) => void;
};

type MenuItemProps = Omit<MenuItemType, 'id'> & {
	mainId: string;
	onClick: OnClick;
	onCollapsibleSubmenuClick: (id: string) => void;
	link?: string;
};

const MenuItem: React.FC<MenuItemProps> = ({
	label,
	icon,
	collapsed,
	onClick,
	mainId,
	onCollapsibleSubmenuClick,
	submenus,
	link,
}) => {
	const history = useHistory();
	return (
		<li className="slds-accordion__list-item">
			<section
				className={cx('slds-accordion__section', styles.accordionSection, {
					'slds-is-open': !collapsed,
				})}
			>
				<div className="slds-accordion__summary">
					<button
						className={cx(
							'slds-button',
							'slds-button_stretch',
							'slds-button_reset',
							'slds-accordion__summary-action',
							styles.accordionButton,
							collapsed ? styles.collapsed : styles.expanded
						)}
						aria-controls="referenceId-45"
						aria-expanded="true"
						title="Accordion summary"
						onClick={() => {
							onClick();
							if (link) {
								history.push(link);
							}
						}}
					>
						<div
							className={cx(
								styles.navTitle,
								collapsed ? styles.navTitleCollapsed : styles.navTitleExpanded,
								'slds-grid',
								'slds-grid_align-start',
								styles.fullWidth
							)}
						>
							<div
								className={cx(
									'slds-grid',
									'slds-grid_align-spread',
									'slds-grid_vertical-align-center',

									styles.fullWidth
								)}
							>
								<div className="slds-col slds-grid slds-grid_vertical-align-center">
									{icon && (
										<Icon
											label={label}
											collapsed={collapsed}
											collapsedIcon={icon.collapsed}
											expandedIcon={icon.expanded}
										/>
									)}
									<div>{label}</div>
								</div>
								<span className="slds-current-color slds-col">
									<IconResolver
										className={cx('slds-icon_xx-small', 'slds-p-left_xx-small')}
										path={`utility/${
											collapsed ? 'chevronright' : 'chevrondown'
										}`}
									/>
								</span>
							</div>
						</div>
					</button>
				</div>
				<div className="slds-accordion__content" id="referenceId-45">
					<ul className={styles.navSection}>
						{submenus.map((data, index) => (
							<SidebarLink
								key={'link-' + index}
								mainId={mainId}
								{...data}
								onCollapsibleSubmenuClick={onCollapsibleSubmenuClick}
							/>
						))}
					</ul>
				</div>
			</section>
		</li>
	);
};

interface SideBarProps {
	getProductLinks: () => void;
}
const SideBar: React.FC<SideBarProps> = ({ getProductLinks }) => {
	const { pathname } = useLocation();

	const sidebar = useSelector<ReducerStateType, SideBarSlice>(
		(state) => state.sidebar
	);
	const dispatch = useDispatch();
	const toggleMenuItem = (id: string) => dispatch(toggleMenuItemAc(id));
	const toggleSubMenuItem = (menuItemId: string, submenuItemId: string) =>
		dispatch(toggleSubMenuItemAc({ menuItemId, submenuItemId }));

	useEffect(() => {
		getProductLinks();
	}, []);

	useEffect(() => {
		dispatch(setProductCategoryBaseOnPath(pathname));
	}, [dispatch, pathname]);

	return (
		<nav className={cx('slds-nav-vertical', styles.navContainer)} id="side-nav">
			<div>
				<div
					className={cx(
						styles.bayadLogoContainer,
						'slds-grid',
						'slds-grid_align-center'
					)}
				>
					<img src={logo} alt="Bayad IP Logo" />
				</div>
				<div
					className={cx('slds-nav-vertical__section', styles.navListContainer)}
				>
					<ul className="slds-accordion">
						{sidebar.menuItems.map(
							({ id, label, submenus, icon, collapsed, link }) => (
								<MenuItem
									key={id}
									mainId={id}
									label={label}
									submenus={submenus}
									icon={icon}
									link={link}
									collapsed={collapsed}
									onClick={() => toggleMenuItem(id)}
									onCollapsibleSubmenuClick={(submenuId) =>
										toggleSubMenuItem(id, submenuId)
									}
								/>
							)
						)}
					</ul>
				</div>
			</div>
			<div className={styles.version}>Bayad Integrated Platform ver 1.0</div>
		</nav>
	);
};

export default connect(() => {}, { getProductLinks })(SideBar);
