import { YupMessage } from 'types';
import yup, {
	defaultOnlyAlphaChar,
	multipleContactNumberSchema,
	multipleEmailSchema,
} from 'utils/formSchemas/common';
import {
	validateContactNumber,
	validateLandlineNumber,
} from 'utils/validation';

import {
	CHANNEL_CONTACT_LABEL,
	EMAIL_LABEL,
	INVALID_TEL_MSG,
	INVALID_TEL_MSG_SINGLE,
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
	TELEPHONE_LABEL,
	TOTAL_LENGTH_MSG,
} from './const';

export const name = yup
	.string()
	.label('Account Officer Name')
	.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
	.required()
	.matches(/^[a-z|A-Z|0-9\s]*$/, {
		excludeEmptyString: true,
		message: defaultOnlyAlphaChar,
	});

export const emailAddressForContactDetailsSchema = multipleEmailSchema.label(
	'Channel Contact Email Address'
);

export const emailAddressForOfficerSchema =
	multipleEmailSchema.label(EMAIL_LABEL);

export const telNo = yup
	.array()
	.nullable()
	.of(yup.string().nullable())
	.optional()
	.label(TELEPHONE_LABEL)
	.test(
		'isValidLandlineNumber',
		({ value }: YupMessage): string => {
			const count = value?.filter(
				(a: string) => !validateLandlineNumber(a || '')
			).length;

			const totalLength = value
				? value.reduce(
						(acc: number, val: string) => acc + (val ? val.length : 0),
						0
				  )
				: 0;

			if (totalLength >= MAX_CHAR_255) {
				return TOTAL_LENGTH_MSG;
			}
			if (count >= 1) {
				return `${count} ${
					count > 1 ? INVALID_TEL_MSG : INVALID_TEL_MSG_SINGLE
				}`;
			}
			return '';
		},
		(v: string[] | undefined): boolean => {
			if (!v) return true;
			return v.every((a) => validateLandlineNumber(a || '', v.length));
		}
	);

export const mobileNo = yup
	.array()
	.of(yup.string())
	.test(
		'isValidNumber',
		'Mobile number should be in +639XXXXXXXXX format.',
		(v) => !!v?.every((a) => validateContactNumber(a || ''))
	)
	.ensure()
	.min(1, ({ label }) => `Input Mobile Number`)
	.max(15)
	.label(CHANNEL_CONTACT_LABEL);
