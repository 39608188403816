import { Route } from 'react-router-dom';
import Products from './Products';

const ProductManagement: React.FC = () => {
	return (
		<>
			<Route path="/products/management" component={Products} />
		</>
	);
};

export default ProductManagement;
