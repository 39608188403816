import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isSelecltAll: false,
	isSelectIndividual: false,
};

const approval = createSlice({
	name: 'approvalSelect',
	initialState,
	reducers: {
		setSelectAll: (state: any, payload: any) => {
			state.isSelecltAll = payload;
		},
		setSelectIndividual: (state: any, payload: any) => {
			state.isSelectIndividual = payload;
		},
	},
});

const { setSelectAll, setSelectIndividual } = approval.actions;

export default approval.reducer;

export { setSelectAll, setSelectIndividual };
