import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import { Control, UseFormSetValue } from 'react-hook-form';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TimeField from 'components/Inputs/TimeField/TimeField';
import { upload_schedule } from 'constants/upload_schedule';
import { deposit_schedule } from 'constants/deposit_schedule';
import { ContractDetailsFormData } from '../../ContractDetails';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
};

const CollectionRemittance = <T extends ContractDetailsFormData>({
	control,
	disabled,
}: Props<T>): JSX.Element => {
	const name = 'product_contract';
	return (
		<Section title="Collection Upload & Remittance">
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Upload Schedule"
						placeholder="Select Upload Schedule"
						name={`${name}.upload_schedule`}
						control={control}
						disabled={disabled}
						options={upload_schedule}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TimeField
						required
						label="Time of Upload"
						placeholder="Time of Upload"
						control={control}
						name={`${name}.upload_time`}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Deposit Schedule"
						placeholder="Select Deposit Schedule"
						control={control}
						name={`${name}.deposit_schedule`}
						disabled={disabled}
						options={deposit_schedule}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default CollectionRemittance;
