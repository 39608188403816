import moment from 'moment';
import { noWhiteSpace } from 'utils/common';
import yup from 'utils/formSchemas/common';

export const branchOperatingSchedule = yup.object({
	schedule: yup
		.array()
		.of(
			yup.object({
				day: yup.string(),
				startTime: yup
					.string()
					.nullable()
					.when('isSelected', {
						is: true,
						then: (schema) => schema.required().test(noWhiteSpace()),
					})
					.label('Start Time'),
				endTime: yup
					.string()
					.nullable()
					.when('isSelected', {
						is: true,
						then: (schema) => schema.required().test(noWhiteSpace()),
					})
					.label('End Time'),
				isSelected: yup.boolean(),
			})
		)
		.test({
			test: (value) => {
				const filter = value?.filter((item) => item.isSelected);
				return Boolean(filter?.length);
			},
			message: 'Select an operating schedule and set start and end time',
		}),
	depositConsolidation: yup.string(),
	taxPayerType: yup
		.string()
		.required('Select Tax Payer Type')
		.label('Tax Payer Type'),
	activationDate: yup
		.string()
		.required()
		.label('Activation Date')
		.matches(/^\d{2}\/\d{2}\/\d{4}$/, {
			excludeEmptyString: false,
			message: `Only input numeric characters in MM/DD/YYYY format`,
		})
		.when(['$isActivationDateDirty', '$mode'], {
			is: (isActivationDateDirty: boolean | undefined, mode: string) =>
				!(!isActivationDateDirty && mode === 'EDIT'),
			then: (schema) =>
				schema.test({
					test: (value) => {
						const valueMoment = moment(value).format('MM/DD/YYYY');
						const nowMoment = moment().format('MM/DD/YYYY');
						const result = moment(valueMoment).isSameOrAfter(nowMoment);
						return result;
					},
					message: 'Only select date today or a later date.',
				}),
		}),
	remarks: yup
		.string()
		.max(1000, 'You can only input a max of 1000 characters.'),
});
