import { useMemo, useEffect, useState, memo } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ServiceFeeSettingsFormData } from '../../ServiceFee/ServiceFeeSettings';
import {
	ServiceFeeField,
	TotalRateFee,
	useConditionalColumn,
} from '../../ServiceFee/ServiceFeesFields';

import Table from 'components/CWSTable/Table';

type SpecialSchemaProps = {
	control: Control<ServiceFeeSettingsFormData>;
	serviceFeeBillingType: ServiceFeeSettingsFormData['billing_type'];
	withNonShareableAmount?: string;
	disabled?: boolean;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	action?: string | undefined;
	modalIsOpen?: boolean;
	viewType: string;
};

type ChannelType =
	| 'CENTER'
	| 'PARTNER'
	| 'AGENT'
	| 'MACHINE_INSIDE'
	| 'MACHINE_OUTSIDE'
	| 'COLLECT'
	| 'DIGITAL'
	| 'FINANCIAL_INSTI';

const arrChannelType: Array<{
	label: string;
	value: ChannelType;
	id: string;
}> = [
	{
		label: 'Center',
		value: 'CENTER',
		id: 'center',
	},
	{
		label: 'Partner',
		value: 'PARTNER',
		id: 'partner',
	},
	{
		label: 'Agent',
		value: 'AGENT',
		id: 'agent',
	},
	{
		label: 'Machine (Inside Office)',
		value: 'MACHINE_INSIDE',
		id: 'machineInside',
	},
	{
		label: 'Machine (Outside Office)',
		value: 'MACHINE_OUTSIDE',
		id: 'machineOutside',
	},
	{
		label: 'Collect',
		value: 'COLLECT',
		id: 'collect',
	},
	{
		label: 'Digital (Online/Mobile App)',
		value: 'DIGITAL',
		id: 'digital',
	},
	{
		label: 'Financial Institution',
		value: 'FINANCIAL_INSTI',
		id: 'financialInsti',
	},
];

export type SpecialItemType = {
	subsidizedFee?: number;
	passOnFee?: number;
	totalFixedFee?: number;
	nonShareablePercentage?: number;
};

const COLUMN_CONFIG = (
	disabled: SpecialSchemaProps['disabled'],
	control: SpecialSchemaProps['control'],
	serviceFeeBillType: SpecialSchemaProps['serviceFeeBillingType'],
	getValues: SpecialSchemaProps['getValues'],
	setValue?: SpecialSchemaProps['setValue'],
	withNonShareableAmount?: SpecialSchemaProps['withNonShareableAmount'],
	action?: SpecialSchemaProps['action']
) => {
	const conditionalColumn = useConditionalColumn({ control });
	const [dynamicWidth, setDynamicWidth] = useState(0);
	const nonShareableColWidth = 25;

	useEffect(() => {
		if (withNonShareableAmount == '0')
			setDynamicWidth(nonShareableColWidth / defaultColumns.length);
		else setDynamicWidth(0);
	}, [withNonShareableAmount]);

	const nonShareableColumn = {
		Header: conditionalColumn.nonShareable.headerName,
		id: conditionalColumn.nonShareable.id,
		width: `${nonShareableColWidth}%`,
		Cell: ({ row: { index }, column: { id } }) => (
			<ServiceFeeField
				name={`special.${index}.${id}`}
				defaultValue={action === 'ADD' ? '0' : undefined}
				control={control}
				placeholder={conditionalColumn.nonShareable.headerName}
				nonShareableColumn={conditionalColumn.nonShareable}
				isAmountField
				disabled={disabled || withNonShareableAmount === '0'}
			/>
		),
	};

	const defaultColumns = [
		{
			Header: 'Channel Type',
			id: 'channelType',
			Cell: ({ row: { original } }) => <div>{original.label}</div>,
			width: `${35 + dynamicWidth}%`,
		},
		{
			Header: conditionalColumn.subsidized.headerName,
			id: conditionalColumn.subsidized.id,
			width: `${10 + dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<ServiceFeeField
					name={`special.${index}.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.subsidized.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.SUBSIDIZED}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
		},
		{
			Header: conditionalColumn.passOn.headerName,
			id: conditionalColumn.passOn.id,
			width: `${10 + dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<ServiceFeeField
					name={`special.${index}.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.passOn.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.PASS_ON}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
		},
		{
			Header: conditionalColumn.total.headerName,
			id: conditionalColumn.total.id,
			width: `${15 + dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<TotalRateFee
					idName={conditionalColumn.total.id}
					name={`special.${index}`}
					control={control}
					subsidizedName={conditionalColumn.subsidized.id}
					passOnName={conditionalColumn.passOn.id}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
				/>
			),
		},
	];

	if (withNonShareableAmount != '0')
		return [...defaultColumns, nonShareableColumn];

	return defaultColumns;
};

const SpecialSchema: React.FC<SpecialSchemaProps> = ({
	control,
	withNonShareableAmount,
	serviceFeeBillingType,
	disabled,
	getValues,
	setValue,
	action,
	modalIsOpen,
	viewType,
}) => {
	const rows = useMemo(() => arrChannelType, []);
	return (
		<Table
			columns={COLUMN_CONFIG(
				disabled,
				control,
				serviceFeeBillingType || {},
				getValues,
				setValue,
				withNonShareableAmount,
				action
			)}
			showPagination={false}
			data={rows}
			preHeader={null}
			modalIsOpen={modalIsOpen}
			viewType={viewType}
		/>
	);
};

export default memo(SpecialSchema);
