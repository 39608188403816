import { Viewer } from '@react-pdf-viewer/core';
import React from 'react';
import styles from './FilePreview.module.css';

import '@react-pdf-viewer/core/lib/styles/index.css';

export const ImageFileTypes = ['png', 'jpg', 'jpeg', 'gif'];
export const PreviewableFileTypes = ImageFileTypes.concat('pdf');

type ImagePreviewProps = {
	url: string;
	alt?: string;
};
const ImagePreview: React.FC<ImagePreviewProps> = ({
	url: filePath,
	alt = 'preview',
}) => <img src={filePath} alt={alt} className={styles.imagePreview} />;

const FileThumbnail: React.FC<{
	fileType: string;
	filePath: string;
}> = ({ fileType, filePath }) => {
	const f = fileType.toLocaleLowerCase();

	if (ImageFileTypes.includes(f)) {
		return <ImagePreview url={filePath} />;
	}

	if (f === 'pdf') {
		return (
			<div className={styles.pdfThumbnailContainer}>
				<Viewer fileUrl={filePath} />
			</div>
		);
	}

	return null;
};

export default React.memo(FileThumbnail);
