import { common } from './common';
import * as yup from 'yup';

export type product_service_fee_tiers = {
	tier_number?: number;
	lower_limit?: number;
	upper_limit?: number;
	channel_type_id?: number;
	subsidized_fee?: number;
	subsidized_rate?: number;
	pass_on_fee?: number;
	pass_on_rate?: number;
	total_fixed_rate?: number;
	total_percentage_rate?: number;
	non_share_fixed_rate?: number;
	non_share_percentage?: number;
} & common;

export const product_service_fee_tiers_schema = yup.object().shape({});
