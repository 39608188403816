import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import styles from './index.module.css';

import ProductReports from './Products/ProductReports';
import ChannelReports from './Channel/ChannelReports';
import ReconciliationReports from './Reconciliation/ReconciliationReports';
import FinanceReports from './Finance/FinanceReports';
import AccountingReports from './Accounting/AccountingReports';
import RPAReports from './RPA/RPAReports';
import OtherReport from './Others/Others';
import { setCategory } from 'redux/modules/report-maintenance';
import { ReportMaintenanceProps } from '../common/types';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import useBus from 'use-bus';

const ReportMaintenanceTab: React.FC<ReportMaintenanceProps> = ({
	callback,
	children,
}) => {
	const dispatch = useDispatch();
	const selectedIndex = useSelector(
		(states: RootStateOrAny) => states.reportMaintenance.category_id
	);

	const ReportTabs = {
		tabs: [
			{
				title: 'Product',
				component: <ProductReports />,
			},
			{
				title: 'Channel',
				scope: 'channel',
				component: selectedIndex === 1 ? <ChannelReports /> : <></>,
			},
			{
				title: 'Reconciliation',
				scope: 'reconciliation',
				component: selectedIndex === 2 ? <ReconciliationReports /> : <></>,
			},
			{
				title: 'Finance',
				scope: 'finance',
				component: selectedIndex === 3 ? <FinanceReports /> : <></>,
			},
			{
				title: 'Accounting',
				scope: 'accounting',
				component: selectedIndex === 4 ? <AccountingReports /> : <></>,
			},
			{
				title: 'RPA',
				scope: 'rpa',
				component: selectedIndex === 5 ? <RPAReports /> : <></>,
			},
			{
				title: 'Other Reports',
				scope: 'other',
				component: selectedIndex === 6 ? <OtherReport /> : <></>,
			},
		],
		defaultIndex: 0,
	};

	useBus(
		'refetch/report-details',
		() => {
			callback();
		},
		[selectedIndex]
	);

	return (
		<div className={'slds-col'}>
			<Tabs
				variant="scoped"
				id="tabs-report-maintenance-category"
				className={styles.tabs}
				defaultSelectedIndex={selectedIndex}
				selectedIndex={selectedIndex}
				onSelect={(index: number) => {
					dispatch(setCategory(index));
				}}
			>
				{ReportTabs.tabs.map((tab, index: number) => (
					<TabsPanel key={index} label={tab.title}>
						{tab.component}
						{children}
					</TabsPanel>
				))}
			</Tabs>
		</div>
	);
};

export default ReportMaintenanceTab;
