import { Tooltip } from '@salesforce/design-system-react/module/components';
import { Button, IconSettings } from '@salesforce/design-system-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { showAccessDeniedModal } from 'redux/modules/access';
import { dispatch as busDispatch } from 'use-bus';
import { useDispatch } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';

import ReportDetails from '../ReportMaintenance/ReportDetails';
import styles from './ReportsMaintenanceTable.module.css';
import Table from 'components/CWSTable/Table';

import cx from 'classnames';
interface Props {
	setHasUpdatedData: (value: boolean) => void;
	data: Array<any>;
	pageSize?: number;
	count: number;
	onPageChange?: (page, pageSize) => void;
	onPageSizeChange?: (limit) => void;
	onSort?: (sortBy, sort) => void;
	sortBy?: string;
	sort?: string;
	page: number;
	tabname: string;
	isConfirmationModalOpen: boolean;
	updateStatusConfirmationModal: (
		header: string | ReactNode,
		message: string | ReactNode,
		status: boolean,
		reportName: string,
		reportId: string | number
	) => void;
	hasAuditTrailListPermission: boolean;
	hasAuditTrailLogsPermission: boolean;
	hasEnableStatusPermission: boolean;
	hasDisableStatusPermission: boolean;
	hasViewDetailsPermission: boolean;
	hasEditPermission: boolean;
}

const Cell: React.FC<{ value: any }> = ({ value }) => (
	<div className={styles.rowBreak}>{value}</div>
);

const ReportsMaintenanceTable: React.FC<Props> = ({
	data,
	pageSize,
	count,
	onPageChange,
	onPageSizeChange,
	onSort,
	sortBy,
	sort,
	page,
	updateStatusConfirmationModal,
	setHasUpdatedData,
	tabname,
	hasAuditTrailListPermission,
	hasAuditTrailLogsPermission,
	hasEnableStatusPermission,
	hasDisableStatusPermission,
	hasViewDetailsPermission,
	hasEditPermission,
}) => {
	const dispatch = useDispatch();

	const [selectedId, setSelectedId] = useState('');
	const [selectedData, setSelectedData] = useState<any[]>([]);
	const [formData, setFormData] = useState<any[]>([]);

	useEffect(() => {
		setFormData(data);
	}, [data]);

	const handleRowSelect = (id: string) => {
		setSelectedId((prevId) => (prevId === id ? '' : id));
	};

	const getSelectedData = () => {
		return formData?.filter((item) => item.id === selectedId);
	};

	useEffect(() => {
		const formData = getSelectedData();
		setSelectedData(formData ? formData[0] : null);
	}, [selectedId]);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	useEffect(() => {
		if (!isModalOpen) {
			setSelectedId('');
		}
	}, [isModalOpen]);

	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const headerColumns: Array<any> = [
		{
			Header: 'Date & Time Created',
			id: 'createdAt',
			width: '14%',
			accessor: ({ createdAt }) => formatDate(createdAt),
		},
		{
			Header: 'Report Name',
			id: 'reportName',
			width: '27%',
			Cell,
		},
		{
			Header: 'Frequency Type',
			id: 'frequencyType',
			width: '11%',
			Cell: ({ row }) => {
				return (
					<div>
						{row.original.reportSchedule !== undefined
							? row.original.reportSchedule.frequencyType
									.toString()
									.charAt(0)
									.toUpperCase() +
							  row.original.reportSchedule.frequencyType.slice(1).toLowerCase()
							: 'No Data Available'}
					</div>
				);
			},
		},
		{
			Header: 'Frequency',
			id: 'frequency',
			width: '8%',
			Cell: ({ row }) => {
				return (
					<div>
						{row.original.reportSchedule !== undefined
							? row.original.reportSchedule.frequency
							: 'No Data Available'}
					</div>
				);
			},
		},
		{
			Header: 'Updated By',
			id: 'lastUpdatedBy',
			width: '10%',
			Cell,
		},
		{
			Header: 'Updated At',
			id: 'updatedAt',
			width: '14%',
			accessor: ({ updatedAt }) => formatDate(updatedAt),
		},
		{
			Header: 'Status',
			id: 'isActive',
			width: '6%',
			Cell: ({ row }) => {
				return (
					<div
						className={
							row.values.isActive
								? styles.enabledStatusFont
								: styles.disabledStatusFont
						}
					>
						{row.values.isActive ? 'Enabled' : 'Disabled'}
					</div>
				);
			},
		},
		{
			Header: () => <div className={styles.actionHeader}>Actions</div>,
			id: 'action',
			width: '10%',
			Cell: ({ row }) => (
				<>
					<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
						<div className={cx(styles.actionCell, 'slds-grid')}>
							<div className="slds-col slds-grid slds-grid_align-left slds-grid_vertical-align-center">
								<div className={styles.toggleButtonContainer}>
									<label className="slds-checkbox_toggle slds-grid">
										<input
											checked={row.values.isActive}
											onChange={(e) => {}}
											onClick={(e) => {
												const isActive = row.values.isActive;

												if (isActive && !hasDisableStatusPermission) {
													dispatch(showAccessDeniedModal());
													return;
												}

												if (!isActive && !hasEnableStatusPermission) {
													dispatch(showAccessDeniedModal());
													return;
												}

												let header: string | ReactNode,
													message: string | ReactNode;

												if (isActive) {
													header = `Disable ${tabname} Report`;
													message = (
														<div>
															Are you sure you want to disable
															<div className={styles.modalReportName}>
																{row.values.reportName}?
															</div>
														</div>
													);
												} else if (!isActive) {
													header = `Enable ${tabname} Report`;
													message = (
														<div>
															Are you sure you want to enable
															<div className={styles.modalReportName}>
																{row.values.reportName}?
															</div>
														</div>
													);
												}

												updateStatusConfirmationModal(
													header,
													message,
													row.values.isActive,
													row.values.reportName,
													row.original.id
												);
											}}
											type="checkbox"
											name="checkbox-toggle-16"
											value="checkbox-toggle-16"
											aria-describedby="checkbox-toggle-16"
										/>
										<span
											id="checkbox-toggle-16"
											className="slds-checkbox_faux_container"
											aria-live="assertive"
										>
											<span
												className={cx(
													'slds-checkbox_faux',
													styles.toggleButton
												)}
											></span>
											<span className="slds-checkbox_on"></span>
											<span className="slds-checkbox_off"></span>
										</span>
									</label>
								</div>
								<Tooltip
									role="tooltip"
									content="View"
									variant="list-item"
									align="bottom right"
									triggerClassName={styles.toolTip}
								>
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.crossIcon}
										iconName="picklist_type"
										iconCategory="utility"
										iconVariant="bare"
										iconSize="small"
										variant="icon"
										onClick={(e) => {
											if (!hasViewDetailsPermission) {
												dispatch(showAccessDeniedModal());
												return;
											}

											const id = row.original.id;
											handleRowSelect(id);
											toggleModal();
											showLoader();
										}}
									/>
								</Tooltip>
							</div>
						</div>
					</IconSettings>
				</>
			),
		},
	];

	return (
		<div className={styles.tableContainer}>
			{isModalOpen && (
				<ReportDetails
					setHasUpdatedData={setHasUpdatedData}
					reportId={selectedId}
					open={isModalOpen}
					onClose={toggleModal}
					tabName={tabname}
					hasViewDetailsPermission={hasViewDetailsPermission}
					hasEditPermission={hasEditPermission}
					hasAuditTrailListPermission={hasAuditTrailListPermission}
					hasAuditTrailLogsPermission={hasAuditTrailLogsPermission}
				/>
			)}

			<Table
				columns={headerColumns}
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				onSort={onSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				modalIsOpen={false}
				isNotEvenRowColor={undefined}
			></Table>
		</div>
	);
};

export default ReportsMaintenanceTable;
