import { transformResponseToChannelDetails } from 'containers/ChannelManagement/Channel/ChannelDetails/utils';
import client from 'helpers/ApiClient';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { ProductType, UtilsChannelFilters } from './types';

export const UseGetChannelApprovalList = (params: any) => {
	const transformParams = {
		...params,
		createdAt: params.createdAt
			? moment(params.createdAt).format('YYYY/MM/DD')
			: '',
		updatedAt: params.updatedAt
			? moment(params.updatedAt).format('YYYY/MM/DD')
			: '',
	};
	return useQuery(
		['getApprovalList', params],
		async () => {
			const response = await client.get('/v2/channels/approvals', {
				params: transformParams,
			});
			return response.data;
		},
		{ initialData: { data: [] } }
	);
};

export const useGetFeeApprovalList = (params: any) => {
	const transformParams = {
		...params,
		createdAt: params.createdAt
			? moment(params.createdAt).format('YYYY/MM/DD')
			: '',
		updatedAt: params.updatedAt
			? moment(params.updatedAt).format('YYYY/MM/DD')
			: '',
	};
	return useQuery(
		['getApprovalList', params],
		async () => {
			const response = await client.get('/v2/channels/fees/approvals', {
				params: transformParams,
			});
			return response.data;
		},
		{ initialData: { data: [] } }
	);
};

export const UseGetChannelPrimaryInfo = async (
	params: string | number | null | undefined
) => {
	if (params) {
		const response = await client.get(`/v2/channels/${params}/primary-info`);
		return response.data;
	} else return new Error('Invalid Channel ID');
};

export const UseGetChannelContract = async (params: number | null) => {
	const response = await client.get(`/v2/channels/${params}/contract-details`);
	return response.data;
};

export const UseGetChannelReports = async (params: number | null) => {
	const response = await client.get(`/v2/channels/${params}/reports`);
	return response.data;
};

export const UseApproveRejectChannel = async (
	ids,
	remarks,
	isApprove: boolean
) => {
	const response = await client.post(
		`/v2/channels/${isApprove ? 'approve' : 'reject'}`,
		{ channelIds: ids, remarks: remarks }
	);
	return response;
};

export const UseApproveRejectServiceFee = async (
	sfsIdentifier,
	remarks,
	isApprove: boolean
) => {
	const response = await client.post(
		`/v2/channels/fees/${isApprove ? 'approve' : 'reject'}`,
		{ ids: sfsIdentifier, remarks }
	);
	return response;
};

export const UseGetServiceFeeData = async (sfsIdentifier?: string) => {
	const response = await client.get(
		`/v2/channels/fees/approvals/${sfsIdentifier}`
	);
	return response.data;
};

export const getDraftDetails = async (channelId?: number) => {
	const response = await client.get(`/v2/channels/${channelId}/draft-details`);
	return response.data;
};

export const transformChannelFormValues = (data: any) => {
	data &&
		!isEmpty(data.primaryInfo) &&
		!isEmpty(data.contractDetails) &&
		!isEmpty(data.reports) &&
		transformResponseToChannelDetails(
			data.primaryInfo,
			data.contractDetails,
			data.reports
		);
};

export const UseGetProductTypeFilter = async () => {
	const response = await client
		.get('/v2/product-types')
		.then((res) => {
			const productTypeList: ProductType[] = res.data.data;
			return productTypeList;
		});
	return response;
};

export const UseGetChannelFilters = async (params: any) => {
	const transformParams = {
		...params,
		createdAt: params.createdAt
			? moment(params.createdAt).format('YYYY/MM/DD')
			: '',
		updatedAt: params.updatedAt
			? moment(params.updatedAt).format('YYYY/MM/DD')
			: '',
	};
	const response = await client
		.get('/v2/channels/utils/approval/channel',{
			params: transformParams,
		})
		.then((res) => {
			const filterList: UtilsChannelFilters = res.data
			return filterList
		})
	return response
}

export const UseGetSFSFilters = async () => {
	const response = await client
		.get('/v2/channels/utils/approval/sfs')
		.then((res) => {
			const filterList: UtilsChannelFilters = res.data
			return filterList
		})
	return response
}