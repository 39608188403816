import yup from '../common';

const voidListSchema = yup.object().shape({
	filter: yup.object().shape({
		dateFrom: yup
			.date()
			.nullable()
			.max(yup.ref('dateTo'), `You’ve selected invalid date`)
			.max(new Date(), `You’ve selected invalid date`),
		dateTo: yup
			.date()
			.nullable()
			.min(yup.ref('dateFrom'), `You’ve selected invalid date`)
			.when('dateFrom', (dateFrom, schema) =>
				dateFrom ? schema.required() : schema.nullable()
			),
	}),
});

export default voidListSchema;
