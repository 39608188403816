import { Modal } from '@salesforce/design-system-react';

import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import { ExpandedViewProps } from './types';
import styles from './LookupFieldWithExpanded.module.css';

const OPTIONS_TABLE = (
	handleSelect: ({ label, value }) => void,
	displayName: string
) => [
	{
		Header: displayName,
		id: 'name',
		width: '100%',
		Cell: ({ cell, value }) => {
			const { id, name } = cell.row.values;
			return (
				<div
					{...cell.getCellProps()}
					onClick={() => {
						handleSelect({
							label: name,
							value: id,
						});
					}}
					onKeyDown={()=>{return}}
					className={styles.row}
				>
					{value}
				</div>
			);
		},
	},
	{ id: 'id', hidden: true, Header: 'ID' },
];

const ExpandedView: React.FC<ExpandedViewProps> = ({
	open,
	onClose,
	data,
	count,
	page,
	limit = 25,
	onPageChange,
	onSelect,
	displayName,
	isLoading,
}) => {
	const handleSelect = (value) => {
		onSelect(value);
		onClose();
	};

	return (
		<>
			<FullPageLoader
				open={open && isLoading}
				message="Please wait while options are being loaded"
			/>
			<Modal
				ariaHideApp={false}
				containerClassName={styles.container}
				isOpen={open}
				onRequestClose={onClose}
				contentClassName={styles.content}
			>
				<Table
					columns={OPTIONS_TABLE(handleSelect, displayName)}
					data={data}
					count={count}
					page={page}
					pageSize={limit}
					onPageChange={onPageChange}
					showPageSizeOptions={false}
					tableBodyProps={{
						style: { maxHeight: '425px', overflowY: 'auto' },
					}}
					preHeader={null}
					modalIsOpen={false}
					isNotEvenRowColor
				/>
			</Modal>
		</>
	);
};

export default ExpandedView;
