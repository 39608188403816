import { Button, IconSettings, Tooltip } from '@salesforce/design-system-react';
import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import {
	getForBranch,
	getForTerminal,
} from 'containers/ChannelManagement/Branch/BranchForm/utils';
import BranchModal from 'containers/ChannelManagement/Branch/BranchModal';
import { transformBranchResponseToBranchType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/utils';
import { FormModeType } from 'containers/ChannelManagement/Channel/ChannelForm/types';
import ChannelModal from 'containers/ChannelManagement/Channel/ChannelModal/ChannelModal';
import { DraftsTableProps } from 'containers/ChannelManagement/Drafts/DraftsTable/types';
import {
	getForChannelContractDetails,
	getForChannelPrimaryInfo,
	getForChannelReports,
	getForDraftDetails,
} from 'containers/ChannelManagement/Drafts/DraftsTable/utils';
import ErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import {
	BRANCH_NAME,
	CHANNEL_ACCOUNT_NAME,
	DATE_CREATED,
	DATE_UPDATED,
	REMARKS,
	STATUS,
	SUBMITTED_BY,
	TPAID,
	UPDATED_BY,
} from 'containers/ChannelManagement/Drafts/constants';
import TerminalModal2 from 'containers/ChannelManagement/Terminal/Modal/TerminalModal';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	LoaderState,
	selectedBranch,
	selectedChannel,
	selectedTerminal,
	setBranchFormValues,
	setChannelFormValues,
	setTerminalFormValues,
} from 'redux/modules/channelList';
import { FixMeLater } from 'types';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import styles from './DraftsTable.module.css';
import { ReducerStateType } from 'redux/modules/reducers';
import { transformResponseToChannelDetails } from 'containers/ChannelManagement/Approval/utils';

export const Cell: React.FC<{ value: string }> = ({ value }) => {
	return <div className={styles.rowBreak}>{value}</div>;
};

const DraftsTable: React.FC<DraftsTableProps> = ({
	tableStates,
	onPageChange,
	onSort,
	onCloseOfModals,
}) => {
	const dispatch = useDispatch();

	const {
		value: isAddChannelModalShowing,
		valueOn: showChannelModal,
		valueOff: hideChannelModal,
	} = useToggle();
	const {
		value: isAddBranchModalShowing,
		valueOn: showBranchModal,
		valueOff: hideBranchModal,
	} = useToggle();
	const {
		value: isAddTerminalModalShowing,
		valueOn: showTerminalModal,
		valueOff: hideTerminalModal,
	} = useToggle();

	const {
		value: isShowErrorModal,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();
	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const channelId = useRef<number>(0);
	const branchId = useRef<number>(0);
	const terminalId = useRef<number>(0);

	const channelDraftsData = useRef<FixMeLater>();
	const branchDraftsData = useRef<BranchType>();
	const terminalDraftsData = useRef<FixMeLater>(null);

	const updatedAt = useRef<string>('');
	const updatedBy = useRef<string>('');

	const [requestShowChannelModal, setRequestShowChannelModal] =
		useState<boolean>(false);

	const [requestShowBranchModal, setRequestShowBranchModal] =
		useState<boolean>(false);

	const [requestShowTerminalModal, setRequestShowTerminalModal] =
		useState<boolean>(false);

	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.channels.loader
	);

	useEffect(() => {
		if (requestShowChannelModal) {
			const fetchChannelDetails = async () => {
				showLoading();

				try {
					const draftsData = await getForDraftDetails(channelId.current);

					const transformedData = transformResponseToChannelDetails(
						draftsData.data,
						draftsData.meta
					);

					channelDraftsData.current = {
						primaryInfo: {
							primaryInfo: transformedData.data.channelPrimaryInfo,
						},
						contractDetails: transformedData.data.channelContractDetails,
						reports: transformedData.data.channelReports,
					};

					updatedAt.current =
						moment
							.utc(transformedData.data.updatedAt)
							.format('MM/DD/YYYY hh:mm:ss a') || '';

					updatedBy.current = transformedData.data.updatedBy || '';

					showChannelModal();
				} catch (error) {
					showErrorModal();
				}
				hideLoading();
			};

			fetchChannelDetails();
			setRequestShowChannelModal(false);
		}
	}, [
		hideLoading,
		requestShowChannelModal,
		showChannelModal,
		showErrorModal,
		showLoading,
	]);

	useEffect(() => {
		if (requestShowBranchModal) {
			const fetchBranchDetails = async () => {
				showLoading();

				try {
					const runInParallel = Promise.all([
						getForChannelPrimaryInfo(channelId.current),
						getForBranch(branchId.current),
					]);

					const allResults = await runInParallel;

					const channelResult = allResults[0];
					const branchResult = allResults[1];

					branchDraftsData.current = transformBranchResponseToBranchType(
						branchResult.data,
						channelResult.data.data.channelPrimaryInfo
					);

					updatedAt.current =
						moment
							.utc(branchResult.data.data.branchPrimaryInfos.updatedAt)
							.format('MM/DD/YYYY hh:mm:ss a') || '';

					updatedBy.current =
						branchResult.data.data.branchPrimaryInfos.updatedBy || '';

					showBranchModal();
				} catch (error) {
					showErrorModal();
				}
				hideLoading();
			};

			fetchBranchDetails();
			setRequestShowBranchModal(false);
		}
	}, [
		hideLoading,
		requestShowBranchModal,
		showBranchModal,
		showErrorModal,
		showLoading,
	]);

	useEffect(() => {
		if (requestShowTerminalModal) {
			const fetchAndUpdateSelectedChannel = async () => {
				showLoading();

				try {
					const runInParallel = Promise.all([
						getForChannelPrimaryInfo(channelId.current),
						getForChannelContractDetails(channelId.current),
						getForChannelReports(channelId.current),
						getForBranch(branchId.current),
						getForTerminal(terminalId.current),
					]);

					const allResults = await runInParallel;

					const channelPrimaryInfo = allResults[0];
					const channelContractDetails = allResults[1];
					const channelReports = allResults[2];
					const branchResult = allResults[3];
					const terminalResult = allResults[4];

					terminalDraftsData.current = terminalResult;

					//Channel
					dispatch(
						setChannelFormValues({
							primaryInfo: channelPrimaryInfo.data.data,
							contractDetails: channelContractDetails.data.data,
							reports: channelReports.data.data,
						})
					);
					dispatch(
						selectedChannel({
							id: channelId.current,
						})
					);

					//Branch
					dispatch(setBranchFormValues(branchResult.data));
					dispatch(selectedBranch({ id: branchId.current }));

					//Terminal
					dispatch(setTerminalFormValues(terminalResult.data));
					dispatch(selectedTerminal(terminalId.current));

					//Updated At and By
					updatedAt.current =
						moment
							.utc(terminalResult.data.data.updatedAt)
							.format('MM/DD/YYYY hh:mm:ss a') || '';
					updatedBy.current = terminalResult.data.data.updatedBy || '';

					showTerminalModal();
				} catch (error) {
					showErrorModal();
				}

				hideLoading();
			};

			fetchAndUpdateSelectedChannel();
			setRequestShowTerminalModal(false);
		}
	}, [
		dispatch,
		hideLoading,
		hideTerminalModal,
		requestShowTerminalModal,
		showErrorModal,
		showLoading,
		showTerminalModal,
	]);

	const DRAFT_COLUMNS: Array<FixMeLater> = [
		{
			Header: 'Date & Time Created',
			id: DATE_CREATED,
			sortable: true,
			width: '14%',
			accessor: (columns) => formatDate(columns[DATE_CREATED] || ''),
		},
		{
			Header: 'Submitted By',
			id: SUBMITTED_BY,
			sortable: true,
			width: '10%',
			Cell,
		},
		{
			Header: 'Channel Account Name',
			id: CHANNEL_ACCOUNT_NAME,
			sortable: true,
			width: '11%',
			Cell,
		},
		{
			Header: 'Branch Name',
			id: BRANCH_NAME,
			sortable: true,
			width: '10%',
			Cell,
		},
		{
			Header: 'TPAID',
			id: TPAID,
			sortable: true,
			width: '7%',
			Cell,
		},
		{
			Header: 'Date Updated',
			id: DATE_UPDATED,
			sortable: true,
			width: '14%',
			accessor: (columns) => formatDate(columns[DATE_UPDATED] || ''),
		},
		{
			Header: 'Updated By',
			id: UPDATED_BY,
			sortable: true,
			width: '9%',
			Cell,
		},
		{
			Header: 'Status',
			id: STATUS,
			width: '9%',
			sortable: false,
			Cell: ({ value }) => {
				return <div className={styles.redStatus}>{value}</div>;
			},
		},
		{
			Header: 'Remarks',
			id: REMARKS,
			width: '11%',
			sortable: false,
			Cell: ({ value }) => {
				return (
					<>
						<Tooltip
							id="tooltip"
							align="bottom left"
							content={value}
							triggerClassName={styles.toolTip}
							position="overflowBoundaryElement"
						>
							<div>{value}</div>
						</Tooltip>
					</>
				);
			},
		},
		{
			Header: () => <div className={cx(styles.centerAlign)}>Action</div>,
			id: 'actions',
			width: '5%',
			accessor: (values) => values,
			Cell: ({ value }) => {
				return (
					<>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx('slds-grid')}>
								<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										iconCategory="utility"
										iconName="picklist_type"
										iconSize="small"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn, styles.iconOrange)}
										onClick={() => {
											if (value.level === 'channel') {
												channelId.current = value.channelId;
												setRequestShowChannelModal(true);
											}

											if (value.level === 'branch') {
												channelId.current = value.channelId;
												branchId.current = value.branchId;
												setRequestShowBranchModal(true);
											}

											if (value.level === 'terminal') {
												channelId.current = value.channelId;
												branchId.current = value.branchId;
												terminalId.current = value.terminalId;
												setRequestShowTerminalModal(true);
											}
										}}
										variant="icon"
									/>
								</div>
							</div>
						</IconSettings>
					</>
				);
			},
		},
	];

	return (
		<>
			<Table
				columns={DRAFT_COLUMNS}
				data={tableStates.data}
				count={tableStates.count}
				pageSize={tableStates.pageSize}
				sort={tableStates.sortOrder}
				sortBy={tableStates.sortBy}
				page={tableStates.page}
				onPageChange={onPageChange}
				onSort={onSort}
				preHeader={''}
				modalIsOpen={false}
				isNotEvenRowColor={false}
			/>
			{loader.isLoading && (
				<FullPageLoader open={loader.isLoading} message={loader.message} />
			)}
			{isAddChannelModalShowing && (
				<ChannelModal
					open={isAddChannelModalShowing}
					onClose={() => {
						onCloseOfModals();
						hideChannelModal();
					}}
					draftsData={channelDraftsData.current}
					channelId={channelId.current}
					disabledForm={true}
					mode={FormModeType.DRAFT}
					notAutosavedUpdatedAt={updatedAt.current}
					notAutosavedUpdatedBy={updatedBy.current}
				/>
			)}
			{isAddBranchModalShowing && (
				<BranchModal
					open={isAddBranchModalShowing}
					onClose={() => {
						onCloseOfModals();
						hideBranchModal();
					}}
					selectedChannelId={channelId.current}
					draftsData={branchDraftsData.current}
					branchId={branchId.current}
					disabledForm={true}
					mode="DRAFT"
					notAutosavedUpdatedAt={updatedAt.current}
					notAutosavedUpdatedBy={updatedBy.current}
				/>
			)}
			{isAddTerminalModalShowing && (
				// <TerminalModal
				// 	open={isAddTerminalModalShowing}
				// 	onClose={() => {
				// 		onCloseOfModals();
				// 		hideTerminalModal();
				// 	}}
				// 	disabledForm={true}
				// 	draftsData={terminalDraftsData.current}
				// 	mode="DRAFT"
				// 	terminalId={terminalId.current}
				// 	notAutosavedUpdatedAt={updatedAt.current}
				// 	notAutosavedUpdatedBy={updatedBy.current}
				// />
				<TerminalModal2
					open={isAddTerminalModalShowing}
					onClose={() => {
						onCloseOfModals();
						hideTerminalModal();
					}}
					disabledForm={true}
					draftsData={terminalDraftsData.current}
					mode="DRAFT"
					terminalId={terminalId.current}
					notAutosavedUpdatedAt={updatedAt.current}
					notAutosavedUpdatedBy={updatedBy.current}
				/>
			)}
			{isLoading && (
				<FullPageLoader
					open={isLoading}
					message={
						"Please wait while the selected request's details is being loaded"
					}
				/>
			)}
			{isShowErrorModal && (
				<ErrorModal
					open={isShowErrorModal}
					onClose={hideErrorModal}
					errorHeader={'Timeout Error!'}
					errorMessage={'Failed to fetch data.'}
					onRetry={() => {
						hideErrorModal();
					}}
				/>
			)}
		</>
	);
};

export default DraftsTable;
