import { ReactNode, useEffect } from 'react';
import {
	Control,
	Path,
	PathValue,
	UseFormSetValue,
	useWatch,
	useFieldArray,
	FieldArray,
	ArrayPath,
} from 'react-hook-form';

import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import Button from 'components/Buttons/Button';
import TextField from 'components/Inputs/TextField/TextField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { ReactComponent as DeleteTransparentIcon } from 'assets/icons/ic-delete-transparent.svg';
import { rate_type } from 'constants/rate_type';
import { ContractDetailsFormData } from '../../ContractDetails';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import styles from './OtherPenalties.module.css';
import AmountField from 'components/Inputs/TextField/AmountField';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
	isAutoSaveLoading: boolean 
};

const MAX_OTHER_PENALTIES = 10;

const OtherPenalties = <T extends ContractDetailsFormData>({
	control,
	disabled,
	setValue,
	isAutoSaveLoading,
}: Props<T>): JSX.Element => {
	const name = 'product_contract.other_penalties';

	const otherPenalties = useWatch({
		control,
		name: name as Path<T>,
	});

	const {
		fields: penaltyFields,
		append: appendPenalty,
		remove: removePenalty,
	} = useFieldArray({
		control,
		name: name as ArrayPath<T>,
	});

	const limitReached: boolean = penaltyFields.length >= MAX_OTHER_PENALTIES;

	if (!penaltyFields.length) {
		appendPenalty({} as FieldArray<T, ArrayPath<T>>);
	}

	return (
		<Section title="Other Penalties">
			<SectionRow>
				<Grid size={3} of={3} verticalAlign="start">
					{penaltyFields.map((field: { id?: string }, index) => {
						const penaltyName =
							(otherPenalties && otherPenalties[index]?.penalty_name) || false;
						const penaltyType =
							(otherPenalties && otherPenalties[index]?.penalty_type) || false;

						return (
							<Grid
								key={field.id}
								container
								className={styles.penaltiesContainer}
							>
								<Grid column size={1} of={3}>
									<TextField
										optional
										label="Other Penalties"
										placeholder="Other Penalties"
										name={`${name}.${index}.penalty_name`}
										control={control}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={3}>
									<SelectField
										optional={!penaltyName}
										required={Boolean(penaltyName)}
										label="Other Penalties Type"
										placeholder="Select Other Penalties Type"
										control={control}
										name={`${name}.${index}.penalty_type`}
										disabled={disabled}
										options={rate_type}
										onChange={(selected) =>{
											if(isAutoSaveLoading) return;
											(selected != penaltyType) &&
												setValue(
													`${name}.${index}.penalty_rate` as Path<T>,
													'' as PathValue<T, Path<T>>
												)
											}
										}
									/>
								</Grid>
								{penaltyType && (
									<Grid column size={1} of={3}>
										<AmountField
											required
											label="Other Penalties Rate"
											placeholder={`0.00${
												penaltyType == 'PERCENTAGE' ? '%' : ''
											}`}
											name={`${name}.${index}.penalty_rate`}
											control={control}
											disabled={disabled}
											formatValue={TextFieldFormatter.amountFormat}
											// shouldUnregister
										/>
									</Grid>
								)}
								<Grid column verticalAlign="end">
									{!disabled && (
										<Button
											disabled={disabled}
											className={styles.deletePenaltyButton}
											onClick={() => {
												removePenalty(index);
											}}
										>
											<DeleteTransparentIcon />
										</Button>
									)}
								</Grid>
							</Grid>
						);
					})}
					<Grid column className={styles.addButtoContainer}>
						{!disabled && (
							<Button
								fullWidth
								className={styles.addButton}
								disabled={limitReached}
								onClick={(evt) => {
									appendPenalty({} as FieldArray<T, ArrayPath<T>>);
								}}
							>
								Add Another Penalty +
							</Button>
						)}
						{limitReached && !disabled && (
							<p className={styles.limitMsg}>
								{`Maximum of ${MAX_OTHER_PENALTIES} entries only`}
							</p>
						)}
					</Grid>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default OtherPenalties;
