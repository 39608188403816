import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import {
	BRANCH_BASIC_INFORMATION,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE_ID,
	POS_TYPE,
	SETTLEMENT_SETUP,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';

export const POST_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'ASENSO',
		label: 'Asenso',
	},
	{
		value: 'BFA',
		label: 'BFA',
	},
	{
		value: 'PCS',
		label: 'PCS',
	},
	{
		value: 'BIOS',
		label: 'BIOS',
	},
	{
		value: 'KIOSK',
		label: 'Kiosk/Self-Service',
	},
	{
		value: 'FRONT_END',
		label: 'Own Front-end System',
	},
];

export const STORE_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'FULL-BOOTH',
		label: 'Full Booth',
	},
	{
		value: 'BOOTH',
		label: 'Booth',
	},
];

export const STORE_FORMAT_OPTIONS: SelectOption[] = [
	{
		value: 'STANDALONE',
		label: 'Standalone',
	},
	{
		value: 'MALL-BASED',
		label: 'Mall-based',
	},
];

export const MACHINE_LOCATION_OPTION: SelectOption[] = [
	{
		value: 'INSIDE',
		label: 'Inside Biller Office',
	},
	{
		value: 'OUTSIDE',
		label: 'Outside Biller Office',
	},
];

export const CHANNEL_TRANSACTION_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'DIGITAL',
		label: 'Digital',
	},
	{
		value: 'PHYSICAL',
		label: 'Physical',
	},
];

export const SETTLEMENT_SETUP_OPTIONS: SelectOption[] = [
	{
		value: 'PREFUNDED',
		label: 'Prefunded',
	},
	{
		value: 'BONDED',
		label: 'Bonded',
	},
];

export const NAME_OF_CHANNEL_ACCOUNT_NAME = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_ACCOUNT_NAME}`;
export const NAME_OF_CHANNEL_ACCOUNT_CODE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_ACCOUNT_CODE}`;
export const NAME_OF_CHANNEL_TRANSACTION_TYPE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_TRANSACTION_TYPE}`;
export const NAME_OF_CHANNEL_TYPE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_TYPE_ID}`;
export const NAME_OF_POS_TYPE = `${BRANCH_BASIC_INFORMATION}.${POS_TYPE}`;
export const NAME_OF_SETTLEMENT_SETUP = `${BRANCH_BASIC_INFORMATION}.${SETTLEMENT_SETUP}`;
export const PHYSICAL = 'PHYSICAL';
