import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { Modal } from '@salesforce/design-system-react';

import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { ResetPassReason } from 'utils/lookup';

import { ResetPassProp, UserResetPasswordProps } from '../types';
import styles from './UserResetPasswordModal.module.css';

const UserResetPasswordModal: React.FC<UserResetPasswordProps> = ({
	onCloseModal,
	cellValue,
	initialValues = {},
	isOpen,
}) => {
	const { control, watch, reset } = useForm<ResetPassProp>({
		mode: 'all',
		defaultValues: { confirm: initialValues },
	});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const closeAllModal = () => {
		hideErrorModal();
		hideSuccessModal();
		onCloseModal();
		reset();
	};

	const handlePasswordReset = async () => {
		hideErrorModal();
		hideSuccessModal();
		showLoadingMessage();

		try {
			const apiUrl = 'v2/bfa-admin-portal/users/reset';

			const reason = control._formValues.confirm.reason.split(',');

			const requestBody = {
				platform: process.env.REACT_APP_APP_KEY,
				passExpireDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
				templateName: reason[1],
				reason: reason[0],
				recipientUserId: cellValue?.id,
				user_token: localStorage.getItem('token'),
			};

			await client.post(apiUrl, requestBody).then((response) => {
				if (response?.data?.data.length === 0) {
					return;
				}

				if (response?.data?.error) {
					throw new Error(response?.data?.error?.message);
				}

				showSuccessModal();
				reset();
			});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while sending the verification link.',
				null,
				() => {
					hideSuccessModal();
					handlePasswordReset();
				}
			);
		} finally {
			hideLoading();
		}
	};

	const closeSuccessModal = () => {
		onCloseModal();
		hideSuccessModal();
	};

	const fullName = `${cellValue?.firstName} ${cellValue?.lastName}`;
	const header = `${fullName} (${cellValue?.username})`;
	const verification = `A verification link will be sent to ${fullName}'s email (${cellValue?.email}) to reset his/her password. Do you want to proceed?`;

	const shouldDisableSubmission = (values) => {
		const { reason } = values;
		return ![reason].some((v) => v);
	};

	const allValues = watch('confirm');

	const statusText =
		cellValue?.verifiedDate !== null ? (
			<p className={styles.statusVerifiedText}>Verified User</p>
		) : (
			<p className={styles.statusUnverifiedText}>User is not yet verified</p>
		);

	return (
		<>
			{!isErrorModalShown && isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={closeSuccessModal}
					className={styles.successModal}
				>
					<SuccessModalBody>
						<SuccessText>
							<div>Success!</div>
						</SuccessText>
						<div className={styles.subTextNext}>
							Verification link succcessfully sent to{' '}
							<span className={styles.bodyHeaderEmphasis}>
								{cellValue?.email}
							</span>
						</div>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.doneButton}
							onClick={closeSuccessModal}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}

			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={closeAllModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => retryBtnOnClick.action()}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			<FullPageLoader open={isLoading} message={loadingMessage} />

			{!isLoading && !isSuccessModalOpen && !isErrorModalShown && isOpen && (
				<Modal
					containerClassName={styles.modalSize}
					ariaHideApp={false}
					isOpen={isOpen}
					onRequestClose={closeAllModal}
				>
					<div className={styles.resetModal}>
						<div className={styles.header}>
							<div className={styles.customHeader}>{header}</div>
							<div>{statusText}</div>
						</div>
						<div className={styles.passwordReset}>Password Reset</div>
						<FilterSelect
							control={control}
							placeholder="Select Reason"
							name="confirm.reason"
							options={ResetPassReason}
							label="Reason:"
							style={{
								height: '42px',
								border: '1px solid #3B3B3B',
							}}
						/>
						<div className={styles.verification}>{verification}</div>
						<div className={styles.footer}>
							<OutlineButton
								onClick={closeAllModal}
								className={styles.cancelBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								onClick={handlePasswordReset}
								className={styles.unblockBtn}
								disabled={shouldDisableSubmission(allValues)}
							>
								Confirm
							</PrimaryButton>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default UserResetPasswordModal;
