import { Component } from "react";
import { connect } from "react-redux";

class ReportSummary extends Component {
  render() {
    return <></>;
  }
}

export default connect()(ReportSummary);
