import React, { useEffect, useState } from 'react';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader } from 'utils/hooks';
import { formatParams } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import ChannelTable from './ChannelTable';
import ChannelHeader from './ChannelHeader';
import {
	ChannelListResp,
	ChannelListData,
	ChannelsFilterOptions,
} from './types';

const ChannelMgt: React.FC = () => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<ChannelListData>();
	const [filter, setFilter] = useState<ChannelsFilterOptions>({});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const fetchChannelList = async (params?: ChannelsFilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const result: ChannelListResp = await client.get(
				'/v2/bfa-admin-portal/channels',
				{ params }
			);

			setData(result?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchChannelList(params)
			);
		} finally {
			hideLoading();
		}
	};

	const handleFilterSubmit = async (params: ChannelsFilterOptions) => {
		const { limit, sort, sortBy } = filter;
		const newFilter = formatParams({ limit, sort, sortBy, ...params });
		setFilter(newFilter);
		fetchChannelList(newFilter);
	};

	const handleTableFetch = (params: ChannelsFilterOptions) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchChannelList(newFilter);
	};

	useEffect(() => {
		if (hasUserPermission('list.channel')) {
			fetchChannelList();
		}
	}, []);

	return (
		<>
			<ChannelHeader handleFilterSubmit={handleFilterSubmit} />
			<ChannelTable data={data} handleTableFetch={handleTableFetch} />
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</>
	);
};

export default ChannelMgt;
