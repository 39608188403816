import BasicInformation from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelBasicInformation/ChannelBasicInformation';
import ChannelAddress from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelAddress/ChannelAddress';
import ChannelContactDetails from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelContactDetails/ChannelContactDetails';
import AccountOfficer from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelOfficer/ChannelOfficer';
import { PrimaryInformationTabProps } from 'containers/ChannelManagement//Channel/ChannelForm/Tabs/PrimaryInformation/types';
import styles from './style.module.css';
import { useState } from 'react';

const PrimaryInformationTab: React.FC<PrimaryInformationTabProps> = ({
	primaryData,
	disabled,
	isAdd,
	isAutoSave,
	autoSaveValue,
}) => {
	const [didDeleteChannel, setDidDeleteChannel] = useState(false);

	return (
		<div className={styles.container}>
			<BasicInformation
				disabled={disabled}
				isAdd={isAdd}
				autoSaveValue={autoSaveValue}
				isAutoSave={isAutoSave}
			/>
			<hr className={styles.divider} />
			<ChannelAddress
				primaryData={primaryData}
				setDidDeleteChannel={setDidDeleteChannel}
				didDeleteChannel={didDeleteChannel}
				maxEntries={20}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<ChannelContactDetails maxEntries={20} disabled={disabled} />
			<hr className={styles.divider} />
			<AccountOfficer disabled={disabled} />
		</div>
	);
};

export default PrimaryInformationTab;
