import React from 'react';
import styles from './ProductTypeContainer.module.css';
import ProductScrollableList from './ProductScrollableList/ProductScrollableList';
import cx from 'classnames';
import { ProductTypeContainerProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';

const ProductTypeContainer: React.FC<ProductTypeContainerProps> = ({
	name,
	channel,
	activePage,
}) => {
	return (
		<div>
			<div className="slds-grid ">
				<div className="slds-col slds-size_1-of-3 ">
					<div
						className={cx(
							// 'slds-col slds-small-size_5-of-12 slds-large-size_3-of-12',
							styles.listContainer
						)}
					>
						<div className={styles.containerTitle}>{name}</div>
						<ProductScrollableList channel={channel} activePage={activePage} />
					</div>
				</div>
				<div className={'slds-col slds-size_2-of-3 slds-align-middle'}>
					<div className={styles.placeHolderTest}>
						<PanelPlaceholder
							title="No Selected Product"
							subtitle="Please select a product."
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductTypeContainer;
