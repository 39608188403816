export const customErrorMessage = (message, size) => {
	if (message.includes('Non-Shareable')) {
		return <p style={{ fontSize: '8px', textAlign: 'start' }}>{message}</p>;
	}
	const fontSize = size === '' || size === undefined ? '12px' : size;
	return <p style={{ fontSize, textAlign: 'start' }}>{message}</p>;
};

export const customPlaceHolder = (message, size) => {
	if (message.includes('Non-Shareable')) {
		return <p style={{ fontSize: '8px', textAlign: 'start' }}>{message}</p>;
	}
	const fontSize = size === '' || size === undefined ? '12px' : size;
	return <p style={{ fontSize, textAlign: 'start' }}>{message}</p>;
};
