import {
	ButtonGroup,
	Button,
} from '@salesforce/design-system-react/module/components';
import Select from '../Inputs/Select/Select';
import styles from './Table.module.css';
import cx from 'classnames';
import { pagination } from 'utils/common';

const defaultPageSizeOptions = [
	{ label: '25', value: 25 },
	{ label: '50', value: 50 },
	{ label: '100', value: 100 },
];

export default function Pagination({
	page,
	count,
	canPreviousPage,
	canNextPage,
	pageOptions = [],
	previousPage,
	pageSizeOptions = defaultPageSizeOptions,
	gotoPage,
	nextPage,
	handlePageCount,
	pageIndexProps = 1,
	pageSize,
	showPageSizeOptions = true,
	showFrom,
	goToFirst,
	goToLast,
	pageCount,
}) {
	const fromPage = pageSize * pageIndexProps + 1;
	const _toPage = pageSize * (pageIndexProps + 1);
	const toPage = _toPage <= count ? _toPage : count;

	const pageOptionsRevise = pageOptions.length ? pageOptions.slice(1) : [];
	pageOptionsRevise.push(pageCount);

	const computedPagination = pagination(
		pageIndexProps < 0 ? 1 : pageIndexProps + 1,
		pageOptionsRevise.length
	);
	return (
		<div
			className={cx(
				'slds-grid',
				'slds-grid_align-spread',
				styles.paginationContainer
			)}
		>
			<div className={cx('slds-col', styles.paginationInfoContainer)}>
				<span
					className={cx(
						styles.paginationInfo,
						showFrom && styles.paginationWithoutFrom
					)}
				>
					{count == 0
						? `Showing 0 of 0`
						: showFrom
						? `Showing ${fromPage}-${toPage} of ${count}`
						: `Showing ${toPage} of ${count}`}
				</span>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					height: 'fit-content',
				}}
			>
				{showPageSizeOptions && (
					<div className={styles.limitContainer}>
						<Select
							placeholder={pageSize}
							onChange={(data) => handlePageCount(data)}
							options={pageSizeOptions}
						/>
					</div>
				)}
				<ButtonGroup className={styles.pageOptions}>
					<Button
						label="<<"
						onClick={goToFirst}
						className={styles.pageBtn}
						disabled={pageIndexProps <= 0}
					/>
					<Button
						label="Previous"
						disabled={!canPreviousPage || pageIndexProps <= 0}
						onClick={previousPage}
						className={styles.pageBtn}
					/>
					<div
						style={{
							display: 'flex',
							maxWidth: '300px',
						}}
					>
						{computedPagination.list.map((p) => {
							return (
								<Button
									key={p}
									label={p}
									className={cx(styles.pageBtn, {
										[styles.pageActive]: pageIndexProps === p - 1,
									})}
									onClick={() => {
										gotoPage(p - 1);
									}}
								/>
							);
						})}
					</div>
					<Button
						label="Next"
						disabled={!canNextPage || pageIndexProps >= pageOptions.length - 1}
						onClick={nextPage}
						className={styles.pageBtn}
					/>
					<Button
						label=">>"
						onClick={goToLast}
						className={styles.pageBtn}
						disabled={pageIndexProps >= pageOptions.length - 1}
					/>
				</ButtonGroup>
			</div>
		</div>
	);
}
