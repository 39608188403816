import React, { ReactNode } from 'react';
import cx from 'classnames';

import Path from 'components/Path';
import RenderByType from 'components/common/RenderByType';

import FilterInner from './FilterInner';
import FilterOuter from './FilterOuter';
import styles from './Filter.module.css';

type Section = {
	children: ReactNode;
};
const Inner: React.FC<Section> = ({ children }) => (
	<RenderByType type={FilterInner}>{children}</RenderByType>
);
const Outer: React.FC<Section> = ({ children }) => (
	<RenderByType type={FilterOuter}>{children}</RenderByType>
);

type FilterContainerProps = {
	title: string;
	children: React.ReactNode;
	padding?: string;
	showPath?: string;
	hidePath?: () => void;
	className?: any;
};

const FilterContainer: React.FC<FilterContainerProps> = ({
	children,
	title,
	showPath,
	hidePath = () => undefined,
	className = '',
	...props
}) => {
	return (
		<div
			className={cx('slds-card', styles.paperContainer, className)}
			style={{ padding: props.padding || '' }}
		>
			<div className="">
				{showPath && (
					<Path module="Products" partner={showPath} hidePath={hidePath} />
				)}
				<div className="slds-grid sls-grid_align-spread">
					<div className={cx('slds-col', styles.title)}>{title}</div>
					<div className="slds-col">
						<Outer>{children}</Outer>
					</div>
				</div>
			</div>
			<Inner>{children}</Inner>
		</div>
	);
};

export default FilterContainer;
