import { UseChannelTabQueryKey } from 'containers/ChannelManagement/Channel/ChannelIntegration/channel';

type TPartnerModalTabs = {
	index: number;
	name: string;
	formTab: UseChannelTabQueryKey;
	label?: string;
};
export const channelIntegrationTabs: Array<TPartnerModalTabs> = [
	{
		index: 0,
		name: 'channel-credentials',
		formTab: 'channelCredentials',
		label: 'Channel Credentials',
	},
	{
		index: 1,
		name: 'channel-certificate',
		formTab: 'channelCertificate',
		label: 'Channel Certificate',
	},
];

export const findPartnerTab = (name: string): TPartnerModalTabs | undefined => {
	return channelIntegrationTabs.find((v) => v.name === name);
};
