import { ReactComponent as PreviewIcon } from 'assets/icons/ic-preview-file.svg';
import { fetchViewReport, setModalState } from 'redux/modules/report-viewer';
import { Tooltip } from "@salesforce/design-system-react/module/components";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { fetchAuditTrail } from 'redux/modules/report-viewer';
import { setReportFile } from 'redux/modules/reports';

import styles from './index.module.css';
import cx from 'classnames';

const ViewBtn = ({ data }) => {
	const dispatch = useDispatch();
	const categoryId = useSelector(
		(states: RootStateOrAny) => states.reports.report_category_id
	);

	const handleFetchViewReport = ({ id, fileSize, fileName }) => {
		const hasGreaterReportSize = fileSize >= 3145728;
		dispatch(setReportFile({
			id,
			fileSize
		}));

		if (hasGreaterReportSize) {
			// to check if ever there's no permission
			dispatch(fetchAuditTrail({
				categoryId,
				report_file_id: id,
			}));

			dispatch(setModalState({
				fileName,
				open: true
			}));
		} else {
			dispatch(fetchViewReport({
				report_file_id: id,
				categoryId,
			}));
		}
	};

	return (
		<div className={cx(styles.actionCell, 'slds-grid')}>
			<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
				<Tooltip
					role="tooltip"
					content="Preview"
					variant="list-item"
					align="bottom right"
					triggerClassName={styles.toolTip}
				>
					<a onClick={() => handleFetchViewReport(data)} onKeyDown={()=>{return}}>
						<PreviewIcon />
					</a>
				</Tooltip>
			</div>
		</div>
	);
};

export default ViewBtn;
