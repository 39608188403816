import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import {
	ReportMaintenanceFilterParamProps,
	ReportMaintenanceDataTypes,
} from './common/types';
import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setCategory } from 'redux/modules/report-maintenance';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useHasUserPermission } from 'utils/permissions';
import { restrictSpecialChars } from 'utils/common';
import { useToggle } from 'utils/hooks';

import FilterSection from '../../../containers/BusinessReports/ReportMaintenance/ReportMaintenanceFilter';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModalReports';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ReportsMaintenanceTable from './ReportsMaintenanceTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import folderIcon from '../../../assets/icons/ic-folder.svg';
import ReportMaintenanceTab from './Tabs';
import styles from './index.module.css';
import HTTP from 'helpers/ApiClient';
import moment from 'moment';
import cx from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { remarksValidationSchema } from './models/product_report_maintenance';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';

const TAB_NAMES = {
	0: 'Product',
	1: 'Channel',
	2: 'Reconciliation',
	3: 'Finance',
	4: 'Accounting',
	5: 'RPA',
	6: 'Other',
};

async function getReportMaintenanceData(selectedTabIndex: number) {
	try {
		const result = await HTTP.get(
			`v2/report-maintenance/${TAB_NAMES[
				selectedTabIndex
			].toLowerCase()}/report-details`
		);

		return result.status === 200 && result.data ? result.data : [];

		// return result.data;
	} catch (e) {
		return e;
	}
}

const NoReportAvailableSection: React.FC = () => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>No Reports Available</div>
			</div>
		</div>
	);
};

const ReportMaintenance: React.FC = () => {
	const remarksForm = useForm({
		mode: 'all',
		resolver: yupResolver(remarksValidationSchema),
	});
	const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
		<Grid container gutters={gutters} className={styles.vspaced}>
			{children}
		</Grid>
	);
	const dispatch = useDispatch();
	const selectedTabIndex = useSelector(
		(states: RootStateOrAny) => states.reportMaintenance.category_id
	);
	const [loaderMessage, setLoaderMessage] = useState('');
	const [allData, setAllData] = useState<ReportMaintenanceDataTypes>([]);
	const [frequencyFilter, setFrequencyFilter] = useState<Array<any>>([]);
	const [frequencyTypeFilter, setFrequencyTypeFilter] = useState<Array<any>>(
		[]
	);
	const [lastUpdatedByFilter, setLastUpdatedByFilter] = useState<Array<any>>(
		[]
	);
	const [data, setData] = useState<ReportMaintenanceDataTypes>([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [filterParams, setFilterParams] =
		useState<ReportMaintenanceFilterParamProps>({});
	const [hasUpdatedData, setHasUpdatedData] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [confirmationHeader, setConfirmationHeader] = useState<
		ReactNode | string
	>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [errorStatusRemarks, setErrorStatusRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const handleChangeOfRemarks = (event) =>
		setIsRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	const statusRemarksField = React.createRef<HTMLTextAreaElement>();
	const hasUserPermission = useHasUserPermission('report-maintenance');
	const {
		hasViewListPermission,
		hasViewDetailsPermission,
		hasEditPermission,
		hasEnableStatusPermission,
		hasDisableStatusPermission,
		hasAuditTrailListPermission,
		hasAuditTrailLogsPermission,
	} = useMemo(() => {
		return {
			hasViewListPermission: hasUserPermission(
				`${TAB_NAMES[selectedTabIndex].toLowerCase()}.view-report-details-list`
			),
			hasViewDetailsPermission: hasUserPermission(
				`${TAB_NAMES[selectedTabIndex].toLowerCase()}.view-report-details`
			),
			hasEditPermission: hasUserPermission(
				`${TAB_NAMES[selectedTabIndex].toLowerCase()}.edit-report-details`
			),
			hasEnableStatusPermission: hasUserPermission(
				`${TAB_NAMES[
					selectedTabIndex
				].toLowerCase()}.report-detail-status-enable`
			),
			hasDisableStatusPermission: hasUserPermission(
				`${TAB_NAMES[
					selectedTabIndex
				].toLowerCase()}.report-detail-status-disable`
			),
			hasAuditTrailListPermission: hasUserPermission(
				`${TAB_NAMES[
					selectedTabIndex
				].toLowerCase()}.view-report-details.audit-trail`
			),
			hasAuditTrailLogsPermission: hasUserPermission(
				`${TAB_NAMES[
					selectedTabIndex
				].toLowerCase()}.view-specific-report-detail.audit-trail`
			),
		};
	}, [hasUserPermission, selectedTabIndex]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	useEffect(() => {
		remarksForm.setValue('remarks', '');
	}, [remarksForm]);
	const validateRemarks = async () => {
		try {
			await remarksForm.trigger();
			return true;
		} catch {
			return false;
		}
	};
	const isRemarksValid = remarksForm.formState.isValid;
	const dirtyFields = useWatch({
		control: remarksForm.control,
		name: 'remarks',
		defaultValue: false,
	});
	const hasfilteredData = Object.values(filterParams).length > 0;

	useEffect(() => {
		if (dirtyFields.remarks) {
			validateRemarks();
		}
	}, [dirtyFields.remarks]);

	const showSuccessMessage = (
		message: string | ReactNode,
		onDoneBtnClick?: () => void
	) => {
		setSuccessMessage(message);
		setDoneBtnOnClick({
			action: () => {
				remarksForm.reset();
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
		showSuccessModal();
	};

	const showErrorMessage = (
		message: string | ReactNode,
		onRetryBtnClick?: () => Promise<void>
	) => {
		setErrorMessage(message);
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
		showErrorModal();
	};

	const showConfirmationMessage = (
		header: string | ReactNode,
		message: string | ReactNode,
		onConfirmBtnClick?: () => void,
		onClose?: () => void
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		setConfirmBtnOnClick({
			action: () => {
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				remarksForm.reset();
				hideConfirmationModal();
				onClose && onClose();
			},
		});
		showConfirmationModal();
	};

	const updateStatusConfirmationModal = (
		header: string | ReactNode,
		message: string | ReactNode,
		status: boolean,
		reportName: string,
		reportId: string | number
	) => {
		showConfirmationMessage(
			<h2 className={styles.approveHeading}>{header}</h2>,
			<div>
				<div className={styles.updateStatusBody1}>{message}</div>
				<div className={styles.updateStatusBody2}>
					<div
						className={
							errorStatusRemarks.hasError
								? 'slds-form-element slds-has-error'
								: 'slds-form-element'
						}
					>
						<Row gutters>
							<Grid column size={1} of={1}>
								<TextField
									control={remarksForm.control}
									name="remarks"
									label="Remarks"
									remarks
									required
								></TextField>
							</Grid>
						</Row>
					</div>
					<em className={styles.updateStatusSubLabel}>
						*Maximum of 1000 characters only.
					</em>
				</div>
			</div>,
			() => {
				handleStatusUpdate(status, reportName, reportId);
			},
			() => {
				setErrorStatusRemarks({ hasError: false, status: '' });
			}
		);
	};

	const fetchReportMaintenanceData = async (
		retry = false,
		withLoader = true,
		tabIndex = selectedTabIndex
	) => {
		setLoaderMessage(
			`Please wait while the ${TAB_NAMES[tabIndex]} Report Maintenance is being loaded`
		);

		const doRequest = async () => {
			try {
				const filterParamsValue = Object.values(filterParams).some(
					(el) => el && el.length > 0
				);

				if (!hasViewListPermission) {
					dispatch(showAccessDeniedModal());
					setAllData([]);
					setData([]);
					return;
				}

				withLoader && showLoading();
				const result = await getReportMaintenanceData(tabIndex);

				if (typeof result.data !== 'object') {
					showErrorMessage(
						<div>
							<div>A problem occured while loading the data.</div>
							<div>Please try again.</div>
						</div>,
						() => doRequest()
					);
					return;
				}

				setAllData(result.data);
				setPage(1);
				setPageSize(25);
				setCount(result.data.length);

				if (filterParamsValue) handleFilter(result.data);
				else {
					const tabledata = result.data.slice(0, 25);
					setData(tabledata);
				}

				updateFilterOptions(result.data);
			} catch (e: any) {
				if (typeof e === 'object') {
					if (e.includes('403')) {
						dispatch(showAccessDeniedModal());
						return;
					}
				}
				if (withLoader) {
					showErrorMessage(
						<div>
							<div>A problem occured while loading the data.</div>
							<div>Please try again.</div>
						</div>,
						() => doRequest()
					);
					return;
				}
				throw e;
			} finally {
				withLoader && hideLoading();
			}
		};

		if (retry) {
			await doRequest();
			return;
		}

		await doRequest();
	};

	const updateFilterOptions = (filterdata) => {
		if (filterdata.length > 0) {
			const frequencyOptionsList = filterdata
				.map(({ reportSchedule: { frequency } }) => {
					return frequency;
				})
				.filter(function (item, pos, self) {
					return self.indexOf(item) == pos;
				})
				.sort((a, b) => a - b)
				.map((item) => {
					return { label: item, value: item };
				});

			const frequencyTypeOptionsList = filterdata
				.map(({ reportSchedule: { frequencyType } }) => {
					const lowerCaseFrequencyType = frequencyType.toLowerCase();
					const firstLetterCapital =
						lowerCaseFrequencyType.charAt(0).toUpperCase() +
						lowerCaseFrequencyType.slice(1);
					return firstLetterCapital;
				})
				.filter(function (item, pos, self) {
					return self.indexOf(item) == pos;
				})
				.sort()
				.map((item) => {
					return { label: item, value: item };
				});

			const lastUpdatedByOptionsList = filterdata
				.map(({ lastUpdatedBy }) => {
					return lastUpdatedBy;
				})
				.filter(function (item, pos, self) {
					return self.indexOf(item) == pos;
				})
				.map((item) => {
					return { label: item, value: item };
				});

			setFrequencyFilter(frequencyOptionsList);
			setFrequencyTypeFilter(frequencyTypeOptionsList);
			setLastUpdatedByFilter(lastUpdatedByOptionsList);
		}
	};

	const handleFilter = (dataParams) => {
		const {
			createdAt: _createdAt,
			frequencyType: _frequencyType,
			frequency: _frequency,
			lastUpdatedBy: _lastUpdatedBy,
			updatedAt: _updatedAt,
			reportName: _reportName,
		}: ReportMaintenanceFilterParamProps = filterParams;

		const fieldWithValue = Object.keys(filterParams).filter((key) => {
			return filterParams[key].length > 0;
		});

		const filteredData = dataParams.filter(
			({
				createdAt,
				updatedAt,
				reportSchedule: { frequencyType, frequency },
				lastUpdatedBy,
				reportName,
			}) => {
				const reportNameFilter = _reportName?.trim().toLowerCase() || null;
				const createdAtFilter = moment(_createdAt).format('MM/DD/YYYY') || null;
				const updatedAtFilter = moment(_updatedAt).format('MM/DD/YYYY') || null;

				const reportNameData = reportName.trim().toLowerCase();
				const createdAtData = moment(createdAt).format('MM/DD/YYYY');
				const updatedAtData = moment(updatedAt).format('MM/DD/YYYY');

				const _frequencyTypeLowerCase = _frequencyType?.map((el) =>
					el.toLowerCase()
				);

				const _lastUpdatedByLowerCase = _lastUpdatedBy?.map((el) =>
					el.toLowerCase()
				);

				const filterDataWithTrue = [
					reportNameFilter && reportNameData.includes(reportNameFilter)
						? true
						: false,
					_lastUpdatedBy && _lastUpdatedBy.length > 0
						? _lastUpdatedByLowerCase?.includes(lastUpdatedBy.toLowerCase())
						: false,
					createdAtFilter === createdAtData,
					updatedAtFilter === updatedAtData,
					_frequency && _frequency.length > 0
						? _frequency?.includes(frequency)
						: false,
					_frequencyType && _frequencyType.length > 0
						? _frequencyTypeLowerCase?.includes(frequencyType.toLowerCase())
						: false,
				].filter((val) => {
					return val === true;
				}).length;

				return filterDataWithTrue === fieldWithValue.length;
			}
		);

		setData(filteredData || []);
		setCount(filteredData.length);
	};

	const handlePageChange = (_page: number, _pageSize: number) => {
		if (page === _page) return;
		setPage(_page);
		setPageSize(_pageSize);
		const newPageSize = _pageSize * _page;
		const newPage = _page - 1;
		const tabledata = !hasfilteredData
			? allData.slice(newPage * _pageSize, newPageSize)
			: data.slice(newPage * _pageSize, newPageSize);
		setData(tabledata);
	};

	const handlePageSizeChange = (limit: number) => {
		if (pageSize === limit) return;
		setPageSize(limit);
		const newPageSize = limit * page;
		const newPage = page - 1;

		const tabledata = !hasfilteredData
			? allData.slice(newPage * limit, newPageSize)
			: data.slice(newPage * limit, newPageSize);
		setData(tabledata);
	};

	const handleStatusUpdate = async (
		status: boolean,
		reportName: string,
		reportId: string | number
	) => {
		const remarks = remarksForm.getValues('remarks').replace(/`/g, '');
		const statusString = status ? 'disable' : 'enable';

		setLoaderMessage('Please wait while the status is being updated.');
		showLoading();
		const method = 'put';
		const url = `/v2/report-maintenance/${TAB_NAMES[
			selectedTabIndex
		].toLowerCase()}/report-details/${reportId}/status/${statusString}`;
		const param = {
			remarks,
		};

		const successMessage = (
			<div>
				{reportName} is now {!status ? 'enabled.' : 'disabled.'}
			</div>
		);

		HTTP[method](url, param)
			.then(() => {
				setHasUpdatedData(true);
				showSuccessMessage(successMessage, () => {
					hideSuccessModal && hideSuccessModal();
				});
			})
			.catch((e: any) => {
				if (typeof e === 'object' && e.includes('403')) {
					dispatch(showAccessDeniedModal());
					return;
				}

				const errorMsg = (
					<div>
						A problem occurred while trying to update report status
						<div>Please try again</div>
					</div>
				);
				showErrorMessage(errorMsg, () =>
					handleStatusUpdate(status, reportName, reportId)
				);
			})
			.finally(() => {
				hideConfirmationModal();
				hideLoading();
			});
	};

	const resetVisibleData = () => {
		const data = allData.length > 0 ? allData.slice(0, 25) : [];
		setData(data);
		setPage(1);
		setPageSize(25);
		setCount(allData.length);
		setFilterParams({});
	};

	useEffect(() => {
		fetchReportMaintenanceData();
	}, [selectedTabIndex]);

	// set back to zero
	useEffect(() => {
		return () => {
			dispatch(setCategory(0));
		};
	}, []);

	useEffect(() => {
		const filterParamsValue = Object.values(filterParams).some(
			(el) => el && el.length > 0
		);
		if (isSubmitted && !hasUpdatedData && filterParamsValue) {
			handleFilter(allData);
			setIsSubmitted(false);
		} else if (!isSubmitted && hasUpdatedData) {
			fetchReportMaintenanceData();
			setHasUpdatedData(false);
		}
	}, [isSubmitted, hasUpdatedData]);

	return (
		<>
			<FullPageLoader open={isLoading} message={loaderMessage} />
			<div className={cx('slds-card', styles.paperContainer)}>
				<div className={cx(styles.title)}>Report Maintenance</div>
				<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
					<ReportMaintenanceTab
						callback={() => {
							showErrorMessage(
								<div>
									<div>A problem occured while loading the data.</div>
									<div>Please try again.</div>
								</div>,
								() => fetchReportMaintenanceData()
							);
						}}
					>
						{allData && allData.length > 0 ? (
							<>
								<FilterSection
									setFilterParams={setFilterParams}
									frequencyOptions={frequencyFilter}
									hasViewPermission={hasViewListPermission}
									frequencyTypeOptions={frequencyTypeFilter}
									updatedByOptions={lastUpdatedByFilter}
									setIsSubmitted={setIsSubmitted}
									resetVisibleData={resetVisibleData}
								/>
								<ReportsMaintenanceTable
									setHasUpdatedData={setHasUpdatedData}
									data={data}
									count={count}
									pageSize={pageSize}
									onPageChange={handlePageChange}
									onPageSizeChange={handlePageSizeChange}
									sort={'desc'}
									sortBy={'createdAt'}
									page={page}
									tabname={TAB_NAMES[selectedTabIndex]}
									updateStatusConfirmationModal={updateStatusConfirmationModal}
									isConfirmationModalOpen={false}
									hasAuditTrailListPermission={hasAuditTrailListPermission}
									hasAuditTrailLogsPermission={hasAuditTrailLogsPermission}
									hasEnableStatusPermission={hasEnableStatusPermission}
									hasDisableStatusPermission={hasDisableStatusPermission}
									hasViewDetailsPermission={hasViewDetailsPermission}
									hasEditPermission={hasEditPermission}
								/>
							</>
						) : (
							<>
								<NoReportAvailableSection />
							</>
						)}
					</ReportMaintenanceTab>
				</div>
			</div>
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} larger={true}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successBodyWithPadding}>
								{successMessage}
							</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div>
							<b>Timeout Error!</b>
						</div>
						<div className={styles.errorBody}>{errorMessage}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					isDisabled={isLoading}
					isPrimaryBtnDisabled={!isRemarksValid}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
				/>
			)}
		</>
	);
};

export default ReportMaintenance;
