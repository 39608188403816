import cx from 'classnames';

export type SizeOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Alignment = 'spread' | 'end' | 'start' | 'space' | 'center';

export type TGrid = {
	container?: boolean;
	vertical?: boolean;
	gutters?:
		| boolean
		| 'xxx-small'
		| 'xx-small'
		| 'x-small'
		| 'small'
		| 'medium'
		| 'large';
	column?: boolean;
	size?: SizeOptions;
	of?: SizeOptions;
	align?: Alignment;
	verticalAlign?: Alignment;
	className?: string;
};

const Grid: React.FC<TGrid> = ({
	container,
	vertical,
	column,
	size,
	of,
	children,
	gutters,
	align,
	verticalAlign,
	className = '',
}) => {
	return (
		<div
			className={cx(
				{
					[`slds-grid${vertical ? '_vertical' : ''}`]: container,
					[`slds-size_${size}-of-${of}`]: size && of,
					[`slds-grid_align-${align}`]: align,
					[`slds-grid_vertical-align-${verticalAlign}`]: verticalAlign,
					'slds-col': column,
					'slds-gutters': typeof gutters === 'boolean' && gutters,
					[`slds-gutters_${gutters}`]: typeof gutters === 'string',
				},
				className
			)}
		>
			{children}
		</div>
	);
};

export default Grid;
