import Grid from 'components/Grid/Grid';
import PasswordField from 'components/Inputs/PasswordField/PasswordField';
import TextField from 'components/Inputs/TextField/TextFieldChannel';
import Section, { SectionRow } from 'components/Section/Section';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useFormContext } from 'react-hook-form';
import { FileTransferProtocolProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/FileTransferProtocol/types';

const FileTransferProtocol: React.FC<FileTransferProtocolProps> = ({
	name,
	disabled,
	title,
}) => {
	const { control, setValue } = useFormContext<PartnerReportsFormData>();
	const namePrefix = `sftp` as const;
	return (
		<Section title={title}>
			<SectionRow verticalAlign="start">
				<Grid column size={1} of={3}>
					<TextField
						label="Host"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.host`}
						onChange={(value) =>
							setValue(`${namePrefix}.host` as never, value as never, {
								shouldTouch: true,
							})
						}
					/>
				</Grid>
				<Grid container column verticalAlign="start" size={1} of={3}>
					<TextField
						label="Port"
						required
						disabled={disabled}
						control={control}
						name={`${name}.port`}
						onChange={(value) =>
							setValue(`${namePrefix}.port` as never, value as never, {
								shouldTouch: true,
							})
						}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Username"
						required
						disabled={disabled}
						control={control}
						name={`${name}.username`}
						onChange={(value) =>
							setValue(`${namePrefix}.username` as never, value as never, {
								shouldTouch: true,
							})
						}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<PasswordField
						label="Password"
						required
						disabled={disabled}
						control={control}
						name={`${name}.password`}
						onChange={(value) =>
							setValue(`${namePrefix}.password` as never, value as never, {
								shouldTouch: true,
							})
						}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Path"
						required
						disabled={disabled}
						control={control}
						name={`${name}.path`}
						onChange={(value) =>
							setValue(`${namePrefix}.path` as never, value as never, {
								shouldTouch: true,
							})
						}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default FileTransferProtocol;
