import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import { Control, UseFormSetValue, Path, useWatch } from 'react-hook-form';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import { boolean } from 'constants/boolean';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { ContractDetailsFormData } from '../../ContractDetails';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import AmountField from 'components/Inputs/TextField/AmountField';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
};

const OneTimeFee = <T extends ContractDetailsFormData>({
	control,
	disabled,
}: Props<T>): JSX.Element => {
	const name = 'product_contract' as const;
	const oneTimeFee = useWatch({
		control,
		name: `${name}.one_time_fee` as Path<T>,
	});

	return (
		<Section title="One Time Fee">
			<SectionRow>
				<Grid column size={1} of={3}>
					<RadioGroup
						required
						label="One Time Fee"
						name={`${name}.one_time_fee`}
						control={control}
						disabled={disabled}
						options={boolean}
					/>
				</Grid>
				{oneTimeFee == '1' && (
					<>
						<Grid column size={1} of={3}>
							<AmountField
								required
								label="One Time Fee Amount"
								placeholder="0.00"
								name={`${name}.one_time_fee_amount`}
								control={control}
								disabled={disabled}
								formatValue={TextFieldFormatter.amountFormat}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<DatePickerField
								required
								label="Date of Collection"
								name={`${name}.collection_date`}
								control={control}
								disabled={disabled}
								placeholder="MM/DD/YYYY"
							/>
						</Grid>
					</>
				)}
			</SectionRow>
		</Section>
	);
};

export default OneTimeFee;
