import Section, { SectionRow } from 'components/Section/Section';
import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import styles from './BillerContactDetails.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import Button from 'components/Buttons/Button';
import TagInput, {
	EmailTagInput,
	ContactNumberTagInput
} from 'components/TagInput/TagInput';
import { PrimaryInformationSectionProps } from '../../PrimaryInformation';
import { useFieldArray, useWatch } from 'react-hook-form';
import Grid from 'components/Grid/Grid';
import { orderBy as _orderBy, uniqBy as _uniqBy } from 'lodash';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import { useState } from 'react';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { useMemo } from 'react';
import {
	LocalBillerAddress,
	TBillerAddress,
} from '../BillerAddress/BillerAddress';
import { useEffect } from 'react';

type TBillerContactDetails = {
	name?: string;
	areaHandled?: string;
	position?: string;
	department?: string;
	email?: string[];
	contactNumber?: string[];
};

export type BillerContactDetailsFields = TBillerContactDetails[];

const BillerContactDetails: React.FC<
	PrimaryInformationSectionProps & {
		maxEntries?: number;
		disabled?: boolean;
		inChannel?: boolean;
	}
> = ({ control, maxEntries, setValue, disabled, inChannel }) => {
	const name = 'billerContactDetails';
	const watchFields = useWatch({
		control,
		name,
	}) as BillerContactDetailsFields;

	const watchAddresses = useWatch({
		control,
		name: 'billerAddress',
		defaultValue: [],
	});
	const [activeEntry, setActiveEntry] = useState<
		{ index: number; value: TBillerContactDetails } | undefined
	>(undefined);
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});
	const controlledFields = fields.map((field, i) => ({
		...field,
		...watchFields[i],
	}));

	const {
		value: isContactDetailsErrorModalOpen,
		valueOn: openContactDetailsErrorModal,
		valueOff: closeContactDetailsErrorModal,
	} = useToggle();

	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			openContactDetailsErrorModal();
			return;
		}

		append({});
	};

	const handleConfirmDeleteEntry = (
		index: number,
		value: TBillerContactDetails
	) => {
		setActiveEntry({ index, value });
		openConfirmDeleteModal();
	};

	const handleDeleteAddress = () => {
		activeEntry && remove(activeEntry.index);
		closeConfirmDeleteModal();
	};

	const areaHandledCities = useMemo<SelectOption[]>(() => {
		const cities = watchAddresses
			.filter((address: TBillerAddress) => {
				return address.locationBase === 'LOCAL';
			})
			.map(
				(address: LocalBillerAddress & { cityObj: SelectOption }) =>
					address?.cityObj
			)
			.filter((c: any) => !!c);
		return _orderBy(
			_uniqBy<SelectOption>(cities, 'value'),
			[(city) => city.label.toLowerCase()],
			['asc']
		);
	}, [watchAddresses]);

	useEffect(() => {
		controlledFields.forEach((f, i) => {
			if (f.areaHandled) {
				const selectedEntryIsRemoved = areaHandledCities.find(
					(a) => a.value === f.areaHandled
				);
				if (selectedEntryIsRemoved) {
					setValue(`${name}.${i}.areaHandled`, '');
				}
			}
		});
	}, [areaHandledCities, controlledFields, setValue]);

	return (
		<>
			{controlledFields.map((field, index) => {
				const n = `${name}.${index}`;
				return (
					<Section
						key={field.id}
						title={() => (
							<Grid container gutters align="spread">
								<Grid column size={1} of={3}>
									Biller Contact Details
									{index > 0 && ` ${index + 1}`}
								</Grid>
								<Grid column>
									{index === 0 ? (
										<Button
											fullWidth
											onClick={handleAddEntry}
											className={styles.addButton}
											disabled={disabled}
										>
											<AddIcon /> Add Contact Details
										</Button>
									) : (
										<Button
											onClick={() => handleConfirmDeleteEntry(index, field)}
											disabled={disabled}
										>
											<DeleteIcon />
										</Button>
									)}
								</Grid>
							</Grid>
						)}
					>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									label="Name"
									required
									name={`${n}.name`}
									control={control}
									defaultValue={field.name}
									disabled={disabled}
								/>
							</Grid>
							{inChannel == true ? (
								<></>
							) : (

								<Grid column size={1} of={2}>
									<SelectField
										label="Area Handled"
										options={areaHandledCities.map(
											(city: SelectOption) => city
										)}
										optional
										name={`${n}.areaHandled`}
										control={control}
										defaultValue={field.areaHandled}
										disabled={disabled || areaHandledCities.length === 0}
									/>
								</Grid>
							)}
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									label="Position"
									required
									name={`${n}.position`}
									control={control}
									defaultValue={field.position}
									disabled={disabled}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<TextField
									label="Department"
									required
									name={`${n}.department`}
									control={control}
									defaultValue={field.department}
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
						<SectionRow>
							<div className="slds-col slds-size_1-of-2">
								<EmailTagInput
									required
									control={control}
									name={`${n}.email`}
									defaultValue={field.email}
									disabled={disabled}
								/>
							</div>
							<Grid column size={1} of={2}>
								<ContactNumberTagInput
									control={control}
									required
									name={`${n}.contactNumber`}
									defaultValue={field.contactNumber}
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
					</Section>
				);
			})}

			<ErrorModal
				open={isContactDetailsErrorModalOpen}
				onClose={closeContactDetailsErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>Failed to Add Biller Contact Details</ErrorText>
					<ErrorSubText>
						You have reached max number of accepted Biller Contact Details
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={closeContactDetailsErrorModal}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>

			{activeEntry && (
				<ConfirmModal
					open={isConfirmDeleteModalOpen}
					disableClose={false}
					onClose={() => {
						setActiveEntry(undefined);
						closeConfirmDeleteModal();
					}}
					headerText={
						activeEntry &&
						`Delete Biller Contact Details ${activeEntry.index + 1}`
					}
					bodyText={[
						() => (
							<>
								Are you sure you want to delete{' '}
								<span className={styles.confirmTextName}>
									Biller Contact Details {activeEntry.index + 1}
								</span>
								?
							</>
						),
					]}
					confirmButton={{
						name: 'Delete',
						event: handleDeleteAddress,
					}}
					cancelButton={{
						name: 'Cancel',
						event: closeConfirmDeleteModal,
					}}
				/>
			)}
		</>
	);
};

export default BillerContactDetails;
