import { UsePartnerTabQueryKey } from 'utils/queries/partner';

type TPartnerModalTabs = {
	index: number;
	name: string;
	formTab: UsePartnerTabQueryKey;
	label?: string;
};
export const partnerModalTabs: Array<TPartnerModalTabs> = [
	{
		index: 0,
		name: 'primary-info',
		formTab: 'primaryInfo',
		label: 'Primary Information',
	},
	{
		index: 1,
		name: 'contract-details',
		formTab: 'contractDetails',
		label: 'Contract Details',
	},
	{
		index: 2,
		name: 'service-fee',
		formTab: 'serviceFeeSettings',
		label: 'Service Fee Settings',
	},
	{
		index: 3,
		name: 'business-rules',
		formTab: 'businessRules',
		label: 'Business Rules',
	},
	{ index: 4, name: 'reports', formTab: 'reports', label: 'Reports' },
];

export const findPartnerTab = (name: string): TPartnerModalTabs | undefined => {
	return partnerModalTabs.find((v) => v.name === name);
};
