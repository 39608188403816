import cx from 'classnames';
import { Modal } from '@salesforce/design-system-react';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import Button from 'components/Buttons/Button';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorText,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import React, { useState, useEffect } from 'react';
import { restrictSpecialChars } from 'utils/common';
import styles from './styles.module.css';
import { ErrorMessage } from './types';
type ApproveRejectProps = {
	isOpen;
	hideApprove;
	approveReject;
	rowData: Array<any>;
	isApprove;
	isBatchApprove: boolean;
	headerLabel?: string;
};
export const ApproveRejectModal: React.FC<ApproveRejectProps> = ({
	isOpen = false,
	hideApprove,
	approveReject,
	rowData,
	isApprove,
	isBatchApprove,
	headerLabel,
}) => {
	const [remarks, setRemarks] = useState<string>('');
	const [isRemarksLimit, setIsRemarksLimit] = useState<boolean>(false);
	useEffect(() => {
		if (remarks.length > 1000) {
			setIsRemarksLimit(true);
		} else {
			setIsRemarksLimit(false);
		}
	}, [remarks]);

	const headingFunction = () => {
		if (rowData.length == 1) {
			return `${isApprove ? 'Approve' : 'Reject'} ${
				headerLabel ? headerLabel : 'Request'
			}`;
		} else {
			return `Batch ${isApprove ? 'Approve' : 'Reject'}`;
		}
	};
	const handleConfirm = (action: boolean) => {
		if (action) {
			approveReject(remarks, false, isApprove, isBatchApprove);
		} else {
			hideApprove();
		}
	};
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={hideApprove}
			ariaHideApp={false}
			headerClassName={''}
			containerClassName={styles.modalContainer}
			contentClassName={''}
			heading={headingFunction()}
			footer={
				<Footer
					disabled={
						remarks.length === 0 ||
						remarks.length > 1000 ||
						remarks.replace(/\s/g, '') === ''
					}
					action={handleConfirm}
				/>
			}
		>
			{rowData.length > 1 && (
				<div className={styles.bodyHeader}>
					<span>
						Are you sure you want to {isApprove ? 'Approve' : 'Reject'} the
						selected <strong>{rowData.length}</strong> requests?
					</span>
				</div>
			)}

			{rowData.length == 1 && (
				<div className={styles.bodyHeader}>
					<span>
						Are you sure you want to {isApprove ? 'Approve' : 'Reject'}{' '}
						<strong>
							{headerLabel
								? headerLabel
								: rowData[0].name || rowData[0].channelAccountName}
						</strong>
						?
						<br />
						If yes, please input remarks.
					</span>
				</div>
			)}
			<div className={styles.bodyContent}>
				<div className="slds-form-element">
					<label className="slds-form-element__label">
						Remarks
						<abbr className="slds-required" title="required">
							*
						</abbr>
					</label>
					<div className="slds-form-element__control">
						<textarea
							className={'slds-textarea' + ' ' + styles.remarks}
							value={remarks}
							onChange={(value) => setRemarks(value.target.value)}
						></textarea>
					</div>
				</div>
				<em className={styles.remarksSubLabel}>
					*Maximum of 1000 characters only.
				</em>
				{isRemarksLimit && (
					<div
						className={'slds-form-element__help' + ' ' + styles.remarksError}
					>
						<div>You can only input a max of 1000 characters.</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

type RejectProps = {
	name?;
};
export const RejectModal: React.FC<RejectProps> = ({ name }) => {
	return <h1>Reject!</h1>;
};

type FooterProps = {
	disabled;
	action;
};
const Footer: React.FC<FooterProps> = ({ disabled, action }) => {
	return (
		<div className={styles.approvalModalFooter}>
			<OutlineButton
				onClick={() => {
					action(false);
				}}
				className={styles.approvalModalBtn}
			>
				Cancel
			</OutlineButton>
			<PrimaryButton
				onClick={() => {
					action(true);
				}}
				className={styles.approvalModalBtn}
				disabled={disabled}
			>
				Confirm
			</PrimaryButton>
		</div>
	);
};
type ApproveErrorModalProps = {
	isErrorModalShown;
	hideErrorModal;
	errorMessage: ErrorMessage;
	retry?;
};
export const ApproveErrorModal: React.FC<ApproveErrorModalProps> = ({
	isErrorModalShown,
	hideErrorModal,
	errorMessage,
	retry,
}) => {
	return (
		<ErrorModal
			open={isErrorModalShown}
			onClose={hideErrorModal}
			ariaHideApp={false}
		>
			<ErrorModalBody>
				<ErrorText>
					<div className={styles.errorHeader}>{errorMessage.header}</div>
					<div className={styles.errorBody}>{errorMessage.message}</div>
				</ErrorText>
			</ErrorModalBody>
			<ErrorModalActions>
				<PrimaryButton
					fullWidth
					onClick={() => {
						errorMessage.isCommonError
							? hideErrorModal()
							: retry(errorMessage.type, errorMessage.isBatch);
					}}
					className={styles.errorModalBtn}
				>
					{errorMessage.isCommonError ? 'Okay' : 'Retry'}
				</PrimaryButton>
			</ErrorModalActions>
		</ErrorModal>
	);
};
type ApproveSuccessModalProps = {
	isSuccessModalShown;
	hideSuccessModal;
	successMessage;
};
export const ApproveSuccessModal: React.FC<ApproveSuccessModalProps> = ({
	isSuccessModalShown,
	hideSuccessModal,
	successMessage,
}) => {
	return (
		<SuccessModal
			open={isSuccessModalShown}
			onClose={hideSuccessModal}
			ariaHideApp={false}
		>
			<SuccessModalBody>
				<SuccessText>
					<div className={styles.successHeader}>Success!</div>
					<div className={styles.successBody}>{successMessage}</div>
				</SuccessText>
			</SuccessModalBody>
			<SuccessModalActions>
				<PrimaryButton
					className={styles.successModalBtn}
					onClick={() => hideSuccessModal()}
				>
					Done
				</PrimaryButton>
			</SuccessModalActions>
		</SuccessModal>
	);
};
type PreHeaderProps = {
	selectedRows: Array<any>;
	showApprove?;
	showReject?;
	hasApprovePermission?;
	hasRejectPermission?;
	setAllRowsSelected: React.Dispatch<any>;
	targetLength;
};
export const PreHeader: React.FC<PreHeaderProps> = ({
	selectedRows,
	showApprove,
	showReject,
	hasApprovePermission,
	hasRejectPermission,
	setAllRowsSelected,
	targetLength = 10,
}) => {
	if (selectedRows.length > 0) {
		if (selectedRows.length == targetLength) {
			setAllRowsSelected(true);
		} else {
			setAllRowsSelected(false);
		}
		return (
			<>
				<div className={styles.leftAlign}>
					<label className={styles.preHeaderLabel}>
						{selectedRows.length}
						{selectedRows.filter((row) => row.checked).length === 1
							? ' request'
							: ' requests'}{' '}
						selected
					</label>
				</div>

				<Button
					className={cx(
						styles.btnApprove,
						hasApprovePermission ? '' : styles.displayNone
					)}
					onClick={() => {
						showApprove();
					}}
				>
					<ApproveIcon className={styles.btnApproveIcon} /> Approve
				</Button>

				<Button
					className={cx(
						styles.btnReject,
						hasRejectPermission ? '' : styles.displayNone
					)}
					onClick={() => {
						showReject();
					}}
				>
					<RejectIcon className={styles.btnRejectIcon} /> Reject
				</Button>
			</>
		);
	} else return null;
};

export const AccessDeniedBodyText: React.FC<{ hidePermissionErrorModal }> = ({
	hidePermissionErrorModal,
}) => {
	return (
		<>
			<div className={styles.confirmationHeader}>Access Denied</div>
			<div className={styles.subtext}>
				{`Sorry, you don't have access to this function.`}
				<br />
				Please contact your admin to grant access
				<br />
				permission. Thank you.
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={hidePermissionErrorModal}
			>
				Close
			</PrimaryButton>
		</>
	);
};
