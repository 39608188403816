import { Modal } from '@salesforce/design-system-react';
import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import { useEffect, useState } from 'react';
import styles from './ChannelAuditTrail.module.css';
type Props = {
	isOpen: boolean;
	onClose: any;
	data?: any;
};

type BayadShares = {
	key: string;
	bayadShare: {
		oldValue: string;
		newValue: string;
	};
};
type PartnerShares = {
	key: string;
	partnerShare: {
		oldValue: string;
		newValue: string;
	};
};
type TableRow = {
	fieldName?: string;
	fieldKey?: string;
	status?: string;
	oldValue: string;
	newValue: string;
};
const ServiceFeeShareModal: React.FC<Props> = ({
	isOpen = false,
	onClose,
	data,
}) => {
	const columnsUpdate = [
		{
			Header: 'Field Name',
			id: 'fieldName',
			width: '13%',
			Cell: ({ value }) => {
				return <strong>{value}</strong>;
			},
		},
		{
			Header: '',
			id: 'fieldKey',
			width: '29%',
		},
		{
			Header: 'Old Values',
			id: 'oldValue',
			width: '29%',
		},
		{
			Header: 'New Values',
			id: 'newValue',
			width: '29%',
		},
	];
	const columnsApprove = [
		{
			Header: 'Field Name',
			id: 'status',
			width: '40%',
		},
		{
			Header: 'Old Values',
			id: 'oldValue',
			width: '30%',
		},
		{
			Header: 'New Values',
			id: 'newValue',
			width: '30%',
		},
	];
	const [customData, setCustomData] = useState<TableRow[]>([]);
	const BayadShareRow = {
		fieldName: 'Bayad Share',
		fieldKey: '',
		newValue: '',
		oldValue: '',
	};
	const PartnerShareRow = {
		fieldName: 'Partner Share',
		fieldKey: '',
		newValue: '',
		oldValue: '',
	};
	const titleCase = (str) => {
		return str
			.toLowerCase()
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	useEffect(() => {
		if (data.action.includes('Update')) {
			const isTier = data.result.schemeType == 'TIER';
			const bayadShare = data.result.bayadShares.map((element: BayadShares) => {
				return {
					fieldName: '',
					fieldKey: isTier ? `Tier ${element.key}` : element.key,
					newValue: element.bayadShare.newValue,
					oldValue: element.bayadShare.oldValue,
				};
			});
			const partnerShare = data.result.partnerShares.map(
				(element: PartnerShares) => {
					return {
						fieldName: '',
						fieldKey: isTier ? `Tier ${element.key}` : element.key,
						newValue: element.partnerShare.newValue,
						oldValue: element.partnerShare.oldValue,
					};
				}
			);
			setCustomData([
				PartnerShareRow,
				...partnerShare,
				BayadShareRow,
				...bayadShare,
			]);
		} else {
			const status = data.result.channel.status;
			setCustomData([
				{
					status: 'Status',
					oldValue: titleCase(status.oldValue),
					newValue: titleCase(status.newValue),
				},
			]);
		}
	}, []);

	return (
		<>
			<Modal isOpen={isOpen} onRequestClose={onClose} size="medium">
				<div className={cx(styles.headerTitle, styles.paperContainer)}>
					View Logs
				</div>
				{data.action.includes('Update') ? (
					<Table
						className={styles.paperContainer}
						columns={columnsUpdate}
						data={customData}
						showPagination={false}
						preHeader={null}
						modalIsOpen={false}
						isNotEvenRowColor={false}
					/>
				) : (
					<Table
						className={styles.paperContainer}
						columns={columnsApprove}
						isNotEvenRowColor={false}
						modalIsOpen={false}
						preHeader={null}
						showPagination={false}
						data={customData}
					/>
				)}
			</Modal>
		</>
	);
};

export default ServiceFeeShareModal;
