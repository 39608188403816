import yup, {
	multipleEmailSchema,
	multipleEmailSchemaCustom,
	recepientEmailSchemaCustom,
	selectDefaultRequiredTemplate,
} from '../common';

const sftpInformationSchema = yup.object().shape({
	username: yup.string().label('Username').required().max(255),
	password: yup.string().label('Password').required().max(255),
	host: yup.string().label('Host').required().max(255),
	port: yup.number().label('Port').required().max(255),
	path: yup.string().label('Path').required().max(255),

	csrConfigFileFormat: yup
		.string()
		.label('Filename Format')
		.required(selectDefaultRequiredTemplate),
	csrConfigUploadSched: yup
		.string()
		.label('Upload Schedule')
		.required(selectDefaultRequiredTemplate),
	csrConfigGenerationTime: yup
		.string()
		.label('Generation Time')
		.required(selectDefaultRequiredTemplate)
		.max(255),
	csrConfigUploadFreq: yup
		.number()
		.label('Upload Frequency')
		.required()
		.typeError('Input numeric value from 1-99')
		.min(1, 'Input value greater than 0')
		.max(99, 'Input value less than or equal to 99'),
	csrConfigUploadTime: yup
		.string()
		.label('Time of Upload')
		.required(selectDefaultRequiredTemplate)
		.max(255),

	uploadConfigFileFormat: yup
		.string()
		.label('Filename Format')
		.required(selectDefaultRequiredTemplate),
	uploadConfigUploadSched: yup
		.string()
		.label('Upload Schedule')
		.required(selectDefaultRequiredTemplate),
	uploadConfigGenerationTime: yup
		.string()
		.label('Generation Time')
		.required(selectDefaultRequiredTemplate)
		.max(255),
	uploadConfigUploadFreq: yup
		.number()
		.label('Upload Frequency')
		.required()
		.typeError('Input numeric value from 1-99')
		.min(1, 'Input value greater than 0')
		.max(99, 'Input value less than or equal to 99'),
	uploadConfigUploadTime: yup
		.string()
		.label('Time of Upload')
		.required(selectDefaultRequiredTemplate)
		.max(255),
});

const smtpInformationSchema = yup.object().shape({
	csrConfigFileFormat: yup
		.string()
		.label('Filename Format')
		.required(selectDefaultRequiredTemplate),
	csrConfigUploadSched: yup
		.string()
		.label('Upload Schedule')
		.required(selectDefaultRequiredTemplate),
	csrConfigGenerationTime: yup
		.string()
		.label('Generation Time')
		.required(selectDefaultRequiredTemplate)
		.max(255),
	csrConfigUploadFreq: yup
		.number()
		.label('Upload Frequency')
		.required()
		.typeError('Input numeric value from 1-99')
		.min(1, 'Input value greater than 0')
		.max(99, 'Input value less than or equal to 99'),
	csrConfigUploadTime: yup
		.string()
		.label('Time of Upload')
		.required(selectDefaultRequiredTemplate)
		.max(255),

	uploadConfigFileFormat: yup
		.string()
		.label('Filename Format')
		.required(selectDefaultRequiredTemplate),
	uploadConfigUploadSched: yup
		.string()
		.label('Upload Schedule')
		.required(selectDefaultRequiredTemplate),
	uploadConfigGenerationTime: yup
		.string()
		.label('Generation Time')
		.required(selectDefaultRequiredTemplate)
		.max(255),
	uploadConfigUploadFreq: yup
		.number()
		.label('Upload Frequency')
		.required()
		.typeError('Input numeric value from 1-99')
		.min(1, 'Input value greater than 0')
		.max(99, 'Input value less than or equal to 99'),
	uploadConfigUploadTime: yup
		.string()
		.label('Time of Upload')
		.required(selectDefaultRequiredTemplate)
		.max(255),
	senderEmails: multipleEmailSchema.label('Sender Email Address').required(),
	receiverEmails: recepientEmailSchemaCustom
		.label('Receiver Email Address')
		.required('Input Recipient Email Address'),
});

const billerUploadSettingsSchema = yup.object().shape({
	sftpInformation: sftpInformationSchema,
	smtpInformation: smtpInformationSchema,
});

export default billerUploadSettingsSchema;
