import { Component } from 'react';
import styles from './Dropdown.module.css';
import { logOut } from '../../utils/logout';
import cx from 'classnames';

import { IconSettings, Dropdown } from '@salesforce/design-system-react';
import { connect } from 'react-redux';
import client from '../../helpers/ApiClient';

class DropdownMenu extends Component {
	constructor() {
		super();
		this.logout = this.logout.bind(this);
		this.generateLink = this.generateLink.bind(this);
	}

	logout() {
		logOut();
	}

	generateLink() {
		const otpData = {
			username: this.props.userInfo.username,
			client_id: process.env.REACT_APP_APP_KEY,
			back_url: `${process.env.REACT_APP_URL}/`,
			callback_url: `${process.env.REACT_APP_URL}/change-password`,
		};
		client
			.post('/v1/otp/generate', otpData)
			.then((data) => {
				const generateOTPUrl = data['data']['data']['data'];
				window.location = generateOTPUrl;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		return (
			<div>
				<IconSettings iconPath=" /lib/lightningdesignsystem/assets/icons">
					<Dropdown
						assistiveText={{ icon: 'More Options' }}
						align="right"
						iconCategory="utility"
						iconName="down"
						iconVariant="border-filled"
						triggerClassName={styles.icon}
						id="right-align-with-icon"
						width="x-small"
						onSelect={(selected) => {
							if (selected.label === 'Logout') {
								this.logout();
							} else {
								this.generateLink();
							}
						}}
						options={[
							{
								label: 'Change Password',
								value: 'A0',
								leftIcon: {
									category: 'utility',
									name: 'key',
								},
							},
							{
								label: 'Logout',
								value: 'A1',
								leftIcon: {
									category: 'utility',
									name: 'logout',
								},
							},
						]}
						className={cx(styles.dropdown, styles.logout, styles.logoutIcon)}
						buttonClassName={styles.button}
					/>
				</IconSettings>
			</div>
		);
	}
}
export default connect((state) => ({ userInfo: state.userInfo }))(DropdownMenu);
