import Section, { SectionRow } from 'components/Section/Section';
import RadioGroup, {
	RadioOption,
} from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import { Control, FieldPath, useFieldArray, useWatch } from 'react-hook-form';
import {
	PrimaryInformationFormData,
	PrimaryInformationSectionProps,
} from '../../PrimaryInformation';
import { useEffect, useState } from 'react';
import Button from 'components/Buttons/Button';
import styles from './BillerAddress.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import TextField from 'components/Inputs/TextField/TextField';
import { useRef } from 'react';
import { Country, useCountryQuery } from 'utils/queries/location';
import ProvinceSelect from './Select/ProvinceSelect';
import CountrySelect from './Select/CountrySelect';
import CitySelect from './Select/CitySelect';
import BarangaySelect from './Select/BarangaySelect';
import Grid from 'components/Grid/Grid';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import ConfirmModal from 'components/Modal/ConfirmModal';
import {
	AddressCategory,
	AddressType,
	AddressDefaultValue,
} from 'utils/lookup';

export type BarangayOptionValue = {
	id: string;
	zipCode: { name: string; id: string };
};

export type LocalBillerAddress = {
	locationBase: 'LOCAL';
	country?: string;
	province?: string;
	city?: string;
	barangay?: any;
	category?: string;
	street?: string;
	buildingNo?: string;
	zipCode?: string;
};

export type InternationalBillerAddress = {
	locationBase?: 'INTERNATIONAL';
	country?: SelectOption;
};

export type LocationBase =
	| LocalBillerAddress['locationBase']
	| InternationalBillerAddress['locationBase'];

export type TBillerAddress = LocalBillerAddress | InternationalBillerAddress;
export type BillerAddressFields = TBillerAddress[];

type WithControl<T> = {
	control: Control<T>;
};

type AddressEntryProps<T, U> = WithControl<any> & {
	field: U;
	name: FieldPath<T>;
	disabled?: boolean;
	index: number;
	onAdd: () => void;
	onRemove: (index: number) => void;
	onCitySelect?: (option: SelectOption, index: number) => void;
	canAdd?: boolean;
	setValue: PrimaryInformationSectionProps['setValue'];
	clearErrors: PrimaryInformationSectionProps['clearErrors'];
};

function useClearFieldWatcher(
	field: TBillerAddress,
	index: number,
	name: string,
	setValue: PrimaryInformationSectionProps['setValue'],
	clearErrors: PrimaryInformationSectionProps['clearErrors']
) {
	const { province, city } = field as LocalBillerAddress;
	const { data: countries } = useCountryQuery();

	const n = `${name}.${index}` as `${typeof name}.${number}`;

	useEffect(() => {
		if (field.locationBase === 'LOCAL') {
			setValue(n, {
				locationBase: 'LOCAL',
				city: undefined,
				barangay: undefined,
				zipCode: '',
			});
		}
	}, [province]);

	useEffect(() => {
		if (field.locationBase === 'LOCAL') {
			setValue(n, {
				locationBase: 'LOCAL',
				barangay: undefined,
				zipCode: '',
			});
		}
	}, [city]);

	useEffect(() => {
		const rest = {
			city: undefined,
			barangay: undefined,
			zipCode: '',
			category: undefined,
			province: undefined,
			street: undefined,
			buildingNo: undefined,
		};
		if (field.locationBase === 'LOCAL') {
			const { id: value } = countries?.find(
				({ country_code }) => country_code === 'PH'
			) || { id: '1', name: 'Philippines' };

			setValue(
				n,
				{
					locationBase: 'LOCAL',
					country: value,
					...rest,
				},
				{ shouldDirty: false }
			);
		} else {
			setValue(
				n,
				{
					locationBase: 'INTERNATIONAL',
					country: undefined,
					...rest,
				},
				{ shouldDirty: false }
			);
		}
		clearErrors(n);
	}, [countries, field.locationBase]);
}

const AddressEntry: React.FC<
	AddressEntryProps<PrimaryInformationFormData, any>
> = ({
	control,
	field,
	disabled,
	index,
	name: n,
	onAdd,
	onRemove,
	canAdd = !disabled,
	setValue,
	clearErrors,
	onCitySelect,
}) => {
	const name = `${n}.${index}` as FieldPath<BillerAddressFields>;

	useClearFieldWatcher(
		field as TBillerAddress,
		index,
		n,
		setValue,
		clearErrors
	);

	const handleCitySelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value == v);
		onCitySelect && onCitySelect(match, index);
	};

	const handleBarangaySelect = (value: any, options: SelectOption[]) => {
		const match = options.find(({ value: v }) => v.id == value);

		if (match) {
			console.log(`${name}.zipCode`, match.value.zipCode.name);

			setValue(`${name}.zipCode`, match.value.zipCode.name);
			setValue(`${name}.barangayObj`, match);
		}
	};

	return (
		<div className={styles.address}>
			<SectionRow align="spread">
				<Grid column size={1} of={3}>
					<RadioGroup
						label="Location Base"
						disabled={disabled}
						options={AddressType}
						name={`${name}.locationBase`}
						control={control}
						defaultValue={field.locationBase}
					/>
				</Grid>
				<Grid column>
					{index === 0 ? (
						<Button
							fullWidth
							onClick={onAdd}
							className={styles.addButton}
							disabled={!canAdd}
						>
							<AddIcon /> Add Biller Address
						</Button>
					) : (
						<Button onClick={() => onRemove(index)} disabled={disabled}>
							<DeleteIcon />
						</Button>
					)}
				</Grid>
			</SectionRow>
			{field.locationBase === 'LOCAL' ? (
				<>
					<SectionRow>
						<Grid column size={1} of={3}>
							<SelectField
								label="Address Country"
								disabled
								required
								placeholder="Philippines"
								name={name}
								value={''}
								options={[]}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<SelectField
								label="Address Category"
								disabled={disabled}
								control={control}
								name={`${name}.category`}
								required
								defaultValue={field.category}
								options={AddressCategory.map((i) => ({
									label: i,
									value: i,
								}))}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<ProvinceSelect
								disabled={disabled}
								control={control}
								name={name}
								value={field.province}
								defaultValue={field.province}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<CitySelect
								control={control}
								name={name}
								provinceId={field.province}
								disabled={!field.province || disabled}
								defaultValue={field.city}
								onChange={(v: any, options: any) =>
									handleCitySelect(v, options, index)
								}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<BarangaySelect
								control={control}
								name={name}
								cityId={field.city}
								disabled={!field.city || disabled}
								defaultValue={field.barangay}
								onChange={handleBarangaySelect}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<TextField
								label="Street"
								disabled={disabled}
								control={control}
								name={`${name}.street`}
								defaultValue={field.street}
								required
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<TextField
								label="Building Name/No."
								disabled={disabled}
								control={control}
								name={`${name}.buildingNo`}
								defaultValue={field.buildingNo}
								required
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<TextField
								label="Zip Code"
								disabled={disabled}
								control={control}
								name={`${name}.zipCode`}
								defaultValue={field.zipCode}
								required
								readOnly
							/>
						</Grid>
					</SectionRow>
				</>
			) : (
				<SectionRow>
					<Grid column size={1} of={3}>
						<CountrySelect
							control={control}
							disabled={disabled}
							name={name}
							countryFilter={(country: Country) =>
								country.country_code !== 'PH'
							}
							defaultValue={disabled ? field.country : { label: 'Country' }}
						/>
					</Grid>
				</SectionRow>
			)}
		</div>
	);
};

const BillerAddress: React.FC<
	PrimaryInformationSectionProps & {
		maxEntries?: number;
		disabled?: boolean;
	}
> = ({
	control,
	setValue,
	maxEntries,
	clearErrors,
	initialValues,
	disabled,
}) => {
	const name = 'billerAddress';
	const [activeEntry, setActiveEntry] = useState<
		{ index: number; value: TBillerAddress } | undefined
	>(undefined);
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const watchAddresses = useWatch({ name: 'billerAddress', control });

	const controlledFields = fields.map((field, i) => ({
		...field,
		...watchAddresses[i],
	}));

	const lastEntryRef = useRef<HTMLDivElement>(null);
	const {
		value: isAddressErrorModalOpen,
		valueOn: openAddressErrorModal,
		valueOff: closeAddressErrorModal,
	} = useToggle();
	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			openAddressErrorModal();
			return;
		}

		append(AddressDefaultValue);
	};

	const handleConfirmDeleteEntry = (index: number, value: TBillerAddress) => {
		setActiveEntry({ index, value });
		openConfirmDeleteModal();
	};

	const handleDeleteAddress = () => {
		activeEntry && remove(activeEntry.index);
		closeConfirmDeleteModal();
	};

	const handleCitySelect = (option: SelectOption, index: number) => {
		setValue(`billerAddress.${index}.cityObj`, option);
	};

	return (
		<>
			{controlledFields.map((field, i) => (
				<Section
					title={`Biller Address${i > 0 ? ` ${i + 1}` : ''}`}
					key={field.id}
				>
					<AddressEntry
						control={control}
						disabled={disabled}
						field={field}
						index={i}
						name={name}
						onAdd={handleAddEntry}
						onRemove={(index) =>
							handleConfirmDeleteEntry(index, field as TBillerAddress)
						}
						setValue={setValue}
						clearErrors={clearErrors}
						onCitySelect={handleCitySelect}
					/>
					{i === controlledFields.length - 1 && <div ref={lastEntryRef} />}
				</Section>
			))}
			<ErrorModal
				open={isAddressErrorModalOpen}
				onClose={closeAddressErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>Failed to Add Biller Address</ErrorText>
					<ErrorSubText>
						You have reached max number of accepted biller address
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeAddressErrorModal}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>

			{activeEntry && (
				<ConfirmModal
					open={isConfirmDeleteModalOpen}
					disableClose={false}
					onClose={() => {
						setActiveEntry(undefined);
						closeConfirmDeleteModal();
					}}
					headerText={
						activeEntry && `Delete Biller Address ${activeEntry.index + 1}`
					}
					bodyText={[
						() => (
							<>
								Are you sure you want to delete{' '}
								<span className={styles.confirmTextName}>
									Biller Address {activeEntry.index + 1}
								</span>
								?
							</>
						),
					]}
					confirmButton={{
						name: 'Delete',
						event: handleDeleteAddress,
					}}
					cancelButton={{
						name: 'Cancel',
						event: closeConfirmDeleteModal,
					}}
				/>
			)}
		</>
	);
};

export default BillerAddress;
