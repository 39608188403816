import React, { Component } from 'react';
import cx from 'classnames';
import styles from './ChangePassword.module.css';
import userIcon from '../../assets/icons/ic-avatar.svg';
import PrimaryButton from '../Buttons/PrimaryButton';
import IconResolver from '../../helpers/IconResolver';
import { connect } from 'react-redux';
import client from '../../helpers/ApiClient';
import ConfirmModal from '../Modal/ConfirmModal';
import ResponseModal from '../Modal/ResponseModal';
import { logOut } from '../../utils/logout';
import successIcon from '../../assets/icons/ic-success.svg';
import failedIcon from '../../assets/icons/ic-failed.svg';

import {
	maskPassword,
	onFieldInput,
	newPasswordFieldChecker,
	onSaveButtonClick,
	newPasswordValidation,
} from '../../redux/modules/changePassword';
import { collapseMenu } from '../../redux/modules/sidebar';

class ChangePassword extends Component {
	constructor() {
		super();
		this.savePassword = this.savePassword.bind(this);
		this.logout = this.logout.bind(this);
		this.enableSaveButton = this.enableSaveButton.bind(this);
		this.onRetryButton = this.onRetryButton.bind(this);
	}

	componentDidMount() {
		this.props.collapseMenu();
	}

	enableSaveButton() {
		if (
			this.props.data.oldPassword !== '' &&
			this.props.isPasswordMatch &&
			this.props.passwordGuide.hasAlphaCharacter &&
			this.props.passwordGuide.hasNumericCharacter &&
			this.props.passwordGuide.hasSpecialCharacter &&
			this.props.passwordGuide.hasUpperCase &&
			this.props.passwordGuide.hasLowerCase
		) {
			return false;
		}
		return true;
	}

	onRetryButton() {
		this.props.newPasswordValidation(undefined);
	}

	savePassword() {
		this.props.onSaveButtonClick();
		const password = {
			current_password: this.props.data.oldPassword,
			new_password: this.props.data.newPassword,
			confirm_password: this.props.data.confirmNewPassword,
		};
		const authHeader = {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		};

		const changePasswordUrl =
			process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/password/change';
		const response = client
			.post(changePasswordUrl, password, { headers: authHeader })
			.then((data) => {
				if (data['status'] === 200) {
					this.props.newPasswordValidation(true);
					const data = {
						username: this.props.userInfo.username,
						platform: process.env.REACT_APP_APP_KEY,
					};
					client
						.post('/v1/password/change/email', data)
						.then((data) => {
							console.log(data);
						})
						.catch((err) => {
							console.log(err);
						});
				}
			})
			.catch((err) => {
				this.props.newPasswordValidation(false);
			});
	}

	logout() {
		logOut();
	}

	render() {
		const confirmMessage = {
			open: this.props.isSaveButtonClicked,
			headerText: 'Change Password',
			bodyText: [
				"You're about to change your password.",
				'Are you sure you want to proceed?',
			],
			cancelButton: {
				name: 'Cancel',
				event: this.props.onSaveButtonClick,
			},
			confirmButton: {
				name: 'Update',
				event: this.savePassword,
			},
		};

		const openModal =
			this.props.isNewPasswordValid !== undefined ? true : false;
		const valid = {
			isOpen: openModal,
			icon: successIcon,
			bodyHeader: "Success! You've changed your password.",
			bodyText: 'Click below to go to the login screen.',
			respondButton: {
				name: 'Login',
				event: this.logout,
			},
		};
		const invalid = {
			isOpen: openModal,
			icon: failedIcon,
			bodyHeader: "Oops! We couldn't change your password!",
			bodyText:
				'The new password did not meet the requirements or was the same as your previous passwords.',
			respondButton: {
				name: 'Retry',
				event: this.onRetryButton,
			},
		};
		const response = this.props.isNewPasswordValid ? valid : invalid;

		return (
			<>
				<div className="slds-align_absolute-center" style={{ marginTop: '6%' }}>
					<div style={{ width: '100%' }}>
						<div
							className="slds-grid slds-grid_align-center"
							style={{ height: 'auto' }}
						>
							<div className="slds-cols slds-size_10-of-12">
								<div className="slds-grid">
									<div className="slds-cols slds-size_1-of-8"></div>
									<div
										className={cx(
											styles.mainDiv,
											'slds-cols',
											'slds-size_6-of-8'
										)}
									>
										<div className={cx(styles.fontPoppins, 'slds-grid')}>
											<div className="slds-cols slds-size_1-of-2">
												<div
													style={{
														padding: '30px',
														paddingTop: '70px',
													}}
												>
													<div
														className={cx(
															'slds-align_absolute-center',
															'slds-p-around_large'
														)}
													>
														<img
															style={{
																width: '50%',
															}}
															src={userIcon}
															alt="User"
														/>
													</div>
													<div
														style={{
															textAlign: 'center',
														}}
													>
														<span
															style={{
																fontSize: '28px',
																display: 'block',
																color: '#3B3B3B',
															}}
															className={cx(styles.fontWavehaus)}
														>
															{`${this.props.userInfo.firstname} ${this.props.userInfo.middlename} ${this.props.userInfo.lastname}`}
														</span>
														<span
															style={{
																fontSize: '14px',
																display: 'block',
																color: '#8B9095',
															}}
														>
															{this.props.userInfo.username}
														</span>
													</div>
												</div>
											</div>
											<div className="slds-cols slds-size_1-of-2">
												<div
													style={{
														padding: '30px 30px 30px 0',
														marginRight: '50px',
													}}
												>
													<div
														className={cx(
															styles.fontWavehaus,
															'slds-p-bottom_x-small'
														)}
													>
														<span
															style={{
																fontSize: '18px',
															}}
														>
															Change Password
														</span>
													</div>
													<div
														className={cx(
															'slds-form-element',
															'slds-p-bottom_small'
														)}
													>
														<label
															className={cx(
																styles.passwordLabel,
																'slds-form-element__label'
															)}
															htmlFor="old_password"
														>
															Old Password
														</label>
														<div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
															<input
																className={cx(
																	'slds-input',
																	styles.passwordInput
																)}
																type={
																	this.props.mask.oldPasswordMask
																		? 'text'
																		: 'password'
																}
																id="oldPassword"
																name="oldPassword"
																placeholder="Old Password"
																autocomplete="off"
																onCopy={(ev) => {
																	ev.preventDefault();
																	return false;
																}}
																onBlur={(ev) => {
																	this.props.onFieldInput({
																		name: ev.target.name,
																		val: ev.target.value,
																	});
																}}
															/>
															<button
																name="oldPasswordMask"
																className={cx(
																	'slds-button',
																	'slds-button_icon',
																	'slds-input__icon',
																	'slds-input__icon_right',
																	styles.mask
																)}
																onClick={(ev) => {
																	this.props.maskPassword(ev.target.name);
																}}
															>
																<IconResolver
																	className={cx(
																		'slds-button__icon',
																		'slds-icon-text-light'
																	)}
																	path={
																		this.props.mask.oldPasswordMask
																			? 'utility/preview'
																			: 'utility/hide'
																	}
																/>
																<span className="slds-assistive-text">
																	Clear
																</span>
															</button>
														</div>
													</div>
													<div
														className={cx(
															'slds-form-element',
															'slds-p-bottom_small'
														)}
													>
														<label
															className={cx(
																styles.passwordLabel,
																'slds-form-element__label'
															)}
															htmlFor="new_password"
														>
															New Password
														</label>
														<div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
															<input
																className={cx(
																	'slds-input',
																	styles.passwordInput
																)}
																type={
																	this.props.mask.newPasswordMask
																		? 'text'
																		: 'password'
																}
																id="newPassword"
																name="newPassword"
																placeholder="New Password"
																autocomplete="off"
																onCopy={(ev) => {
																	ev.preventDefault();
																	return false;
																}}
																onChange={(ev) => {
																	this.props.newPasswordFieldChecker({
																		val: ev.target.value,
																	});
																}}
																onBlur={(ev) => {
																	this.props.onFieldInput({
																		name: ev.target.name,
																		val: ev.target.value,
																	});
																}}
															/>
															<button
																name="newPasswordMask"
																className={cx(
																	'slds-button',
																	'slds-button_icon',
																	'slds-input__icon',
																	'slds-input__icon_right',
																	styles.mask
																)}
																onClick={(ev) => {
																	this.props.maskPassword(ev.target.name);
																}}
															>
																<IconResolver
																	className={cx(
																		'slds-button__icon',
																		'slds-icon-text-light'
																	)}
																	path={
																		this.props.mask.newPasswordMask
																			? 'utility/preview'
																			: 'utility/hide'
																	}
																/>
																<span className="slds-assistive-text">
																	Clear
																</span>
															</button>
														</div>
													</div>

													<div
														className={styles.passwordReferenceGuide}
														style={{
															display: this.props.passwordGuide.display
																? 'block'
																: 'none',
														}}
													>
														<div>
															<span
																style={{
																	display: 'block',
																}}
															>
																Requires mixed character sets of
															</span>
															<span
																style={{
																	display: 'block',
																}}
															>
																*{' '}
																<span
																	style={{
																		color: this.props.passwordGuide
																			.hasAlphaCharacter
																			? '#14BB02'
																			: '#D93025',
																	}}
																>
																	alpha
																</span>
																,
															</span>
															<span
																style={{
																	display: 'block',
																}}
															>
																*{' '}
																<span
																	style={{
																		color: this.props.passwordGuide
																			.hasNumericCharacter
																			? '#14BB02'
																			: '#D93025',
																	}}
																>
																	numeric
																</span>
																,
															</span>
															<span
																style={{
																	display: 'block',
																}}
															>
																*{' '}
																<span
																	style={{
																		color: this.props.passwordGuide
																			.hasSpecialCharacter
																			? '#14BB02'
																			: '#D93025',
																	}}
																>
																	special
																</span>{' '}
																and
															</span>
															<span
																style={{
																	display: 'block',
																}}
															>
																* mixed case (
																<span
																	style={{
																		color: this.props.passwordGuide.hasUpperCase
																			? '#14BB02'
																			: '#D93025',
																	}}
																>
																	uppercase{' '}
																</span>
																&
																<span
																	style={{
																		color: this.props.passwordGuide.hasLowerCase
																			? '#14BB02'
																			: '#D93025',
																	}}
																>
																	{' '}
																	lowercase
																</span>
																)
															</span>
														</div>
													</div>
													<div
														className={cx(
															'slds-form-element',
															'slds-p-bottom_small'
														)}
													>
														<label
															className={cx(
																styles.passwordLabel,
																'slds-form-element__label'
															)}
															htmlFor="confirm_new_password"
														>
															Confirm New Password
														</label>
														<div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
															<input
																className={cx(
																	'slds-input',
																	styles.passwordInput,
																	this.props.isPasswordMatch !== undefined &&
																		!this.props.isPasswordMatch
																		? styles.errorPasswordInput
																		: ''
																)}
																type={
																	this.props.mask.confirmNewPasswordMask
																		? 'text'
																		: 'password'
																}
																id="confirmNewPassword"
																name="confirmNewPassword"
																placeholder="Confirm New Password"
																autocomplete="off"
																onCopy={(ev) => {
																	ev.preventDefault();
																	return false;
																}}
																onBlur={(ev) => {
																	this.props.onFieldInput({
																		name: ev.target.name,
																		val: ev.target.value,
																	});
																}}
															/>
															<button
																name="confirmNewPasswordMask"
																className={cx(
																	'slds-button',
																	'slds-button_icon',
																	'slds-input__icon',
																	'slds-input__icon_right',
																	styles.mask
																)}
																title="Mask"
																onClick={(ev) => {
																	this.props.maskPassword(ev.target.name);
																}}
															>
																<IconResolver
																	className={cx(
																		'slds-button__icon',
																		'slds-icon-text-light'
																	)}
																	path={
																		this.props.mask.confirmNewPasswordMask
																			? 'utility/preview'
																			: 'utility/hide'
																	}
																/>
																<span className="slds-assistive-text">
																	Clear
																</span>
															</button>
														</div>
														{this.props.isPasswordMatch !== undefined &&
															!this.props.isPasswordMatch && (
																<small
																	style={{
																		color: '#FF0000',
																	}}
																>
																	Passwords do not match.
																</small>
															)}
													</div>

													<PrimaryButton
														disabled={this.enableSaveButton()}
														onClick={this.props.onSaveButtonClick}
														className={
															'slds-button_stretch slds-m-top_small slds-m-bottom_small'
														}
													>
														Save
													</PrimaryButton>
												</div>
											</div>
										</div>
									</div>
									<div className="slds-cols slds-size_1-of-8"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.props.isSaveButtonClicked && <ConfirmModal {...confirmMessage} />}
				{this.props.isNewPasswordValid !== undefined && (
					<ResponseModal {...response} />
				)}
			</>
		);
	}
}

export default connect(
	(state) => ({
		userInfo: state.userInfo,
		mask: state.changePassword.mask,
		data: state.changePassword.data,
		isPasswordMatch: state.changePassword.isPasswordMatch,
		passwordGuide: state.changePassword.passwordGuide,
		isSaveButtonClicked: state.changePassword.isSaveButtonClicked,
		isNewPasswordValid: state.changePassword.isNewPasswordValid,
	}),
	{
		maskPassword,
		onFieldInput,
		newPasswordFieldChecker,
		onSaveButtonClick,
		newPasswordValidation,
		collapseMenu,
	}
)(ChangePassword);
