import React from 'react';
import cx from 'classnames';
import Button from '../Button';
import styles from './ModalButton.module.css';

export default function ModalButton({
	className = '',
	name = 'Modal Button',
	onClick = () => {},
	fullWidth = false,
	icon,
}) {
	return (
		<Button
			onClick={onClick}
			fullWidth={fullWidth}
			className={cx(
				'slds-button',
				'slds-button_neutral',
				styles.modalButton,
				className
			)}
		>
			<div className={cx(styles.modalItem)}>
				<div className="slds-button__icon slds-button__icon_left">
					{icon || 'icon'}
				</div>
				<div>{name}</div>
			</div>
		</Button>
	);
}
