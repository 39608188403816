import BranchAddress from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/BranchAddress';
import BranchBasicInformation from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/BranchBasicInformation';
import BranchContactDetails from 'containers/ChannelManagement/Branch/BranchForm/BranchContactDetails/BranchContactDetails';
import OperatingSchedule from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/OperatingSchedule';
import {
	BranchFormProps,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';
import Divider from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/Divider/Divider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSaveBranchAutoSave } from '../query';
import { transformBranchFormToPayload } from './utils';

const BranchForm: React.FC<BranchFormProps> = ({
	isModal = false,
	branchBasicInformationProp,
	branchAddress,
	branchContactDetails,
	operatingScheduleProps,
	refetch,
	mode,
}) => {
	const { getValues } = useFormContext<BranchType>();
	const [currentSection, setCurrentSection] = useState('');
	const [prevSection, setPrevSection] = useState('');
	const branchFormValues = getValues();
	const updateAutoSave = useSaveBranchAutoSave();

	const handleAutoSave = async () => {
		if (currentSection !== prevSection && isModal && mode === 'ADD') {
			await updateAutoSave.mutateAsync(
				transformBranchFormToPayload(
					branchFormValues,
					'ACTIVE',
					branchBasicInformationProp?.selectedChannelId
				)
			);
			refetch();
		}
	};
	return (
		<>
			<BranchBasicInformation
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={handleAutoSave}
				{...branchBasicInformationProp}
			/>
			<Divider></Divider>
			<BranchAddress
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={handleAutoSave}
				{...branchAddress}
			></BranchAddress>
			<Divider></Divider>
			<BranchContactDetails
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={handleAutoSave}
				maxEntries={5}
				{...branchContactDetails}
			/>
			<Divider></Divider>
			<OperatingSchedule
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={handleAutoSave}
				{...operatingScheduleProps}
			></OperatingSchedule>
		</>
	);
};

export default BranchForm;
