import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import styles from './Table.module.css';

import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';
import NumberFormatter from '../Format/NumberFormatter';
import Pagination from './Pagination';
import { headerCss, bodyCss } from './TableCss';

const SortIcon = ({ isSortedDesc }) => {
	if (isSortedDesc === undefined) return <FaSort />;

	if (isSortedDesc) return <FaSortDown />;
	return <FaSortUp />;
};

const Thead = ({
	children,
	column: { isSorted, isSortedDesc, canSort, toggleSortBy },
	...props
}) => {
	return (
		<div
			className={cx(styles.th, { [styles.sortable]: canSort })}
			onClick={canSort ? () => toggleSortBy(!isSortedDesc) : undefined}
			onKeyDown={() => {
				return;
			}}
		>
			{children}
			{canSort && (
				<div className={styles.sortIcon}>
					<SortIcon isSorted={isSorted} isSortedDesc={isSortedDesc} />
				</div>
			)}
		</div>
	);
};

const renderHeader = (column) => (props) =>
	(
		<Thead {...props}>
			{typeof column.Header === 'string' ? column.Header : column.Header(props)}
		</Thead>
	);

const renderCell = ({ cell, value, numeric }) => {
	if (numeric) {
		return (
			<div {...cell.getCellProps()}>
				<NumberFormatter value={value} />
			</div>
		);
	}
	return <div {...cell.getCellProps()}>{value}</div>;
};

const defaultColumnProps = ({ numeric, ...column }) => ({
	width: '10%',
	Cell: (props) => renderCell({ numeric, ...props }),
	sortable: false,
	accessor: (value) => value[column.id],
	numeric: false,
});

const generateColumns = (columns) => {
	return columns
		.map((column) => ({
			...defaultColumnProps(column),
			...column,
		}))
		.map(({ sortable, hidden, ...column }) => ({
			...column,
			disableSortBy: !sortable,
			Header: renderHeader(column),
		}));
};

const TableHead = ({
	headerGroups,
	modalIsOpen,
	isNotEvenRowColor,
	enableReportGeneration,
	rows,
	viewType,
}) => {
	return (
		<div className={styles.thead}>
			{headerGroups.map((headerGroup, headerGroupIndex) => (
				<div
					{...headerGroup.getHeaderGroupProps()}
					key={headerGroupIndex}
					className={cx(
						!!enableReportGeneration && styles.custom_tr,
						styles.tr,
						isNotEvenRowColor === false && styles.trEvenColumn
					)}
				>
					{headerGroup.headers.map((column, headerColumnIndex) => {
						let cellLength = headerGroup.headers.length;
						let css = headerCss(
							modalIsOpen,
							rows,
							cellLength,
							column,
							headerColumnIndex,
							headerGroup,
							viewType
						);
						return (
							<div
								{...column.getHeaderProps()}
								key={headerColumnIndex}
								style={css}
							>
								{column.render('Header')}
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
};

const TableBody = ({
	rows,
	prepareRow,
	hasRowField = false,
	modalIsOpen,
	isNotEvenRowColor,
	isAllRowColorWhite,
	hasScroll = false,
	viewType,
	hasDetails = false,
	tableWidthRef = null,
	headerGroups,
	hasLoadMore = false,
	visibleData = null,
	handleLoadMore = (e) => {},
	noOverrideCssBody,
	...props
}) => {
	if (rows.length === 0) {
		let width = 0;

		headerGroups.forEach((headerGroup) =>
			headerGroup.headers.forEach((column) => {
				width += Number(column.width.replace('%', ''));
			})
		);

		return (
			<>
				{Array(10)
					.fill()
					.map((_, index) => (
						<div
							key={index}
							className={cx(styles.tr, styles.emptyRow)}
							style={{
								width: `${width}%`,
							}}
						>
							{index === 4 && 'No results found.'}
						</div>
					))}
			</>
		);
	}

	return (
		<div
			{...props}
			className={cx({ [styles.tbody]: hasScroll })}
			ref={tableWidthRef}
		>
			{rows.map((row, rowIndex) => {
				prepareRow(row);
				return (
					<div
						key={rowIndex}
						{...row.getRowProps()}
						className={cx(
							styles.tr,
							isNotEvenRowColor === false && styles.trEvenColumn,
							!isAllRowColorWhite && styles.tableRow,
							!isAllRowColorWhite && styles.tableRowLastTd,
							isAllRowColorWhite && styles.tableRowTd,
							{
								[styles.trHeightForFields]: !!hasRowField,
							}
						)}
					>
						{row.cells.map((cell, cellIndex) => {
							let cellLength = row.cells.length;
							let css = bodyCss(
								modalIsOpen,
								cellLength,
								cell,
								cellIndex,
								viewType,
								hasDetails
							);

							if (modalIsOpen && !noOverrideCssBody) {
								css = {
									minWidth: cellLength <= 4 ? cell.column.width : '20%',
									width: cellLength <= 4 ? cell.column.width : '20%',
									maxWidth: cellLength <= 4 ? cell.column.width : '20%',
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									textAlign: 'center',
									tableLayout: 'fixed',
								};
							}

							if (hasDetails) {
								css = {
									minWidth: cell.column.width,
									width: cell.column.width,
									maxWidth: cell.column.width,
									whiteSpace: 'pre-line',
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
								};
							}

							if (cell.column.id === 'remarks' && hasDetails) {
								css = {
									...css,
									fontSize: '1.6329vh',
								};
							}

							return (
								<div
									{...cell.getCellProps()}
									style={css}
									key={cellIndex}
									className={cx(
										styles.td,
										row.values[props.row?.label] != props.row?.value
											? styles[props.row?.css]
											: ''
									)}
								>
									{cell.render('Cell')}
								</div>
							);
						})}
					</div>
				);
			})}

			{hasLoadMore && visibleData?.length > 0 && (
				<div
					className={styles.loadMoreBtn}
					onClick={handleLoadMore}
					onKeyDown={() => {
						return;
					}}
				>
					Load More
				</div>
			)}
		</div>
	);
};

export default function Table({
	columns,
	data = [],
	pageSize: initPageSize = 25,
	onPageChange = (_page, _size) => {},
	onSort = (_sortBy, _sort) => {},
	manualPagination = true,
	count = 0,
	sortBy: initSortBy = '',
	sort: initSort = 'asc',
	page: initPageIndex = 1,
	showPagination = true,
	showPageSizeOptions = true,
	tableBodyProps = {},
	showFrom = true,
	className = '',
	preHeader = false,
	withRowField = false,
	modalIsOpen,
	viewType,
	isAllRowColorWhite = false,
	overFlow = 'auto',
	isAlignToFilter = false,
	hasDetails = false,
	tableWidthRef = null,
	isNotEvenRowColor = null,
	scrollable = false,
	onPageSizeChange = (limit) => {},
	onPaginate = null,
	hasLoadMore = false,
	handleLoadMore = (e) => {},
	visibleData = null,
	isReportGeneration = false,
	noOverrideCssBody,
}) {
	const memoColumn = useMemo(() => generateColumns(columns), [columns]);
	const memoData = useMemo(() => data, [data]);
	const pageCount = Math.ceil(count / initPageSize);
	const [initialized, setInitialized] = useState(false);
	const hiddenColumns = columns
		.filter(({ hidden }) => hidden)
		.map(({ id }) => id);

	useEffect(() => {
		setInitialized(true);
	}, []);

	const tableProps = useTable(
		{
			columns: memoColumn,
			data: memoData,
			initialState: {
				pageIndex: initPageIndex - 1,
				sortBy: useMemo(
					() => [
						{
							id: initSortBy,
							desc: initSort.toLowerCase() === 'desc',
						},
					],
					[]
				),
				pageSize: initPageSize,
				hiddenColumns,
			},
			manualSortBy: true,
			manualPagination,
			pageCount,
		},
		useSortBy,
		usePagination,
		useFlexLayout
	);

	const {
		state: { pageIndex, pageSize, sortBy },
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = tableProps;

	const pageProps = {
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		pageSize,
		showFrom,
		goToFirst: () => gotoPage(0),
		goToLast: () => gotoPage(pageCount - 1),
	};

	useEffect(() => {
		if (initialized) {
			onPageChange(pageIndex + 1, pageSize);
		}
	}, [pageIndex, pageSize]);

	useEffect(() => {
		if (initialized && onPaginate instanceof Function) {
			onPaginate(pageIndex + 1);
		}
	}, [pageIndex]);

	useEffect(() => {
		if (initialized) {
			const [{ id, desc } = {}] = sortBy;
			onSort(id, desc ? 'DESC' : 'ASC');
		}
	}, [sortBy]);

	useEffect(() => {
		if (initPageIndex - 1 !== pageIndex) {
			gotoPage(initPageIndex - 1);
		}
	}, [initPageIndex]);
	return (
		<div
			className={cx(
				'slds-card',
				isReportGeneration
					? styles.paperContainerReports
					: styles.paperContainer,
				isAlignToFilter ? styles.tableAlignToFilter : className
			)}
		>
			<div
				{...getTableProps()}
				style={{
					overflowX: overFlow,
				}}
				className={cx(preHeader && styles.withPreHeader)}
			>
				{preHeader && <div className={styles.preHeader}>{preHeader}</div>}
				<TableHead
					headerGroups={headerGroups}
					modalIsOpen={modalIsOpen}
					rows={page}
					viewType={viewType}
					isNotEvenRowColor={isNotEvenRowColor}
					enableReportGeneration={isReportGeneration}
				/>
				<TableBody
					{...getTableBodyProps(tableBodyProps)}
					rows={page}
					prepareRow={prepareRow}
					hasRowField={withRowField}
					modalIsOpen={modalIsOpen}
					hasScroll={scrollable}
					viewType={viewType}
					isNotEvenRowColor={isNotEvenRowColor}
					isAllRowColorWhite={isAllRowColorWhite}
					hasDetails={hasDetails}
					tableWidthRef={tableWidthRef}
					headerGroups={headerGroups}
					hasLoadMore={hasLoadMore}
					visibleData={visibleData}
					handleLoadMore={handleLoadMore}
					noOverrideCssBody={noOverrideCssBody}
				/>
			</div>
			{showPagination && (
				<div className={styles.pagination}>
					<Pagination
						{...pageProps}
						showPageSizeOptions
						pageIndexProps={initPageIndex - 1}
						count={count}
						pageCount={pageCount}
						handlePageCount={(value) => {
							setPageSize(value);
							if (onPageSizeChange instanceof Function) {
								onPageSizeChange(value);
							}
						}}
						canNextPage={true}
						canPreviousPage={true}
					/>
				</div>
			)}
		</div>
	);
}
