import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { useBanksQuery } from 'utils/queries/bank';

type BankSelectProps = {
	name: string;
	label?: string;
	disabled?: boolean;
	value?: any;
	defaultValue?: any;
	control?: any;
	onChange?: any;
	required?: boolean;
	placeholder?: string;
};

const BankSelect: React.FC<BankSelectProps> = ({
	control,
	name,
	label = 'Bank',
	disabled,
	onChange,
	defaultValue,
	required,
	placeholder,
	...rest
}) => {
	const { data, isLoading } = useBanksQuery('banks', {}, { enabled: true });
	const options = useMemo(
		() =>
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [],
		[data]
	);
	return (
		<SelectField
			label={label}
			control={control}
			placeholder={placeholder}
			name={name}
			required={required}
			isLoading={isLoading}
			defaultValue={defaultValue}
			onChange={(v: any) => onChange && onChange(v, options)}
			options={options}
			{...rest}
			disabled={disabled}
		/>
	);
};

export default BankSelect;
