export const transformAccreditation = (data) => {
	return [
		{
			label: 'BIR Certificate of Registration Form No. 2303',
			filename: data.birCertReg?.filename
				? data.birCertReg.filename
				: removeUUIDFromString(data.birCertReg) || '',
			realFilename: data.birCertReg,
			name: 'birCertReg',
			status: data.birCertReg?.status
				? data.birCertReg.status
				: data.birCertReg
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
			filename: data.corpSecCert?.filename
				? data.corpSecCert.filename
				: removeUUIDFromString(data.corpSecCert) || '',
			realFilename: data.corpSecCert,
			name: 'corpSecCert',
			status: data.corpSecCert?.status
				? data.corpSecCert.status
				: data.corpSecCert
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Business Permit/Mayor’s Permit (updated/current year)',
			filename: data.businessPermit?.filename
				? data.businessPermit.filename
				: removeUUIDFromString(data.businessPermit) || '',
			realFilename: data.businessPermit,
			name: 'businessPermit',
			status: data.businessPermit?.status
				? data.businessPermit.status
				: data.businessPermit
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Company Profile',
			name: 'companyProfile',
			filename: data.companyProfile?.filename
				? data.companyProfile.filename
				: removeUUIDFromString(data.companyProfile) || '',
			realFilename: data.companyProfile,
			status: data.companyProfile?.status
				? data.companyProfile.status
				: data.companyProfile
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'Government Issued IDs of Board of Directors, including the authorized signatories',
			filename: data.govtIds?.filename
				? data.govtIds.filename
				: removeUUIDFromString(data.govtIds) || '',
			realFilename: data.govtIds,
			name: 'govtIds',
			status: data.govtIds?.status
				? data.govtIds.status
				: data.govtIds
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Latest General Information Sheet (GIS)',
			filename: data.genInfoSheets?.filename
				? data.genInfoSheets.filename
				: removeUUIDFromString(data.genInfoSheets) || '',
			realFilename: data.genInfoSheets,
			name: 'genInfoSheets',
			status: data.genInfoSheets?.status
				? data.genInfoSheets.status
				: data.genInfoSheets
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Non-disclosure Agreement (NDA)',
			filename: data.nonDisclosure?.filename
				? data.nonDisclosure.filename
				: removeUUIDFromString(data.nonDisclosure) || '',
			realFilename: data.nonDisclosure,
			name: 'nonDisclosure',
			status: data.nonDisclosure?.status
				? data.nonDisclosure.status
				: data.nonDisclosure
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'SEC Registration Certificate',
			filename: data.secRegCert?.filename
				? data.secRegCert.filename
				: removeUUIDFromString(data.secRegCert) || '',
			realFilename: data.secRegCert,
			name: 'secRegCert',
			status: data.secRegCert?.status
				? data.secRegCert.status
				: data.secRegCert
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
			filename: data.secArticleIncorp?.filename
				? data.secArticleIncorp.filename
				: removeUUIDFromString(data.secArticleIncorp) || '',
			realFilename: data.secArticleIncorp,
			name: 'secArticleIncorp',
			status: data.secArticleIncorp?.status
				? data.secArticleIncorp.status
				: data.secArticleIncorp
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
			filename: data.certAuthority?.filename
				? data.certAuthority.filename
				: removeUUIDFromString(data.certAuthority) || '',
			realFilename: data.certAuthority,
			name: 'certAuthority',
			status: data.certAuthority?.status
				? data.certAuthority.status
				: data.certAuthority
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Signed Non-disclosure Agreement (NDA)',
			filename: data.signedNda?.filename
				? data.signedNda.filename
				: removeUUIDFromString(data.signedNda) || '',
			realFilename: data.signedNda,
			name: 'signedNda',
			status: data.signedNda?.status
				? data.signedNda.status
				: data.signedNda
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'Audited Financial Statements (with Auditor’s Opinion & Notes to Financial Statements)',
			filename: data.auditFinancialStmt?.filename
				? data.auditFinancialStmt.filename
				: removeUUIDFromString(data.auditFinancialStmt) || '',
			realFilename: data.auditFinancialStmt,
			name: 'auditFinancialStmt',
			status: data.auditFinancialStmt?.status
				? data.auditFinancialStmt.status
				: data.auditFinancialStmt
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Income Tax Return',
			filename: data.incomeTaxReturn?.filename
				? data.incomeTaxReturn.filename
				: removeUUIDFromString(data.incomeTaxReturn) || '',
			realFilename: data.incomeTaxReturn,
			name: 'incomeTaxReturn',
			status: data.incomeTaxReturn?.status
				? data.incomeTaxReturn.status
				: data.incomeTaxReturn
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Accomplished CBCI AML Questionnaire',
			filename: data.cbciAmlQuestion?.filename
				? data.cbciAmlQuestion.filename
				: removeUUIDFromString(data.cbciAmlQuestion) || '',
			realFilename: data.cbciAmlQuestion,
			name: 'cbciAmlQuestion',
			status: data.cbciAmlQuestion?.status
				? data.cbciAmlQuestion.status
				: data.cbciAmlQuestion
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label:
				'Audited Financial Statements (previous 2 years with Auditor’s Opinion and Notes to Financial Statements)',
			filename: data.secondAuditFinStmt?.filename
				? data.secondAuditFinStmt.filename
				: removeUUIDFromString(data.secondAuditFinStmt) || '',
			realFilename: data.secondAuditFinStmt,
			name: 'secondAuditFinStmt',
			status: data.secondAuditFinStmt?.status
				? data.secondAuditFinStmt.status
				: data.secondAuditFinStmt
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Data Privacy: PIA',
			name: 'dataPrivacyPia',
			filename: data.dataPrivacyPia?.filename
				? data.dataPrivacyPia.filename
				: removeUUIDFromString(data.dataPrivacyPia) || '',
			realFilename: data.dataPrivacyPia,
			status: data.dataPrivacyPia?.status
				? data.dataPrivacyPia.status
				: data.dataPrivacyPia
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Data Privacy: PAT',
			name: 'dataPrivacyPat',
			filename: data.dataPrivacyPat?.filename
				? data.dataPrivacyPat.filename
				: removeUUIDFromString(data.dataPrivacyPat) || '',
			realFilename: data.dataPrivacyPat,
			status: data.dataPrivacyPat?.status
				? data.dataPrivacyPat.status
				: data.dataPrivacyPat
				? 'Uploaded'
				: '',
			required: false,
		},
		{
			label: 'Third-Party Security Checklist',
			filename: data.thirdPartySecChecklist?.filename
				? data.thirdPartySecChecklist.filename
				: removeUUIDFromString(data.thirdPartySecChecklist) || '',
			realFilename: data.thirdPartySecChecklist,
			name: 'thirdPartySecChecklist',
			status: data.thirdPartySecChecklist?.status
				? data.thirdPartySecChecklist.status
				: data.thirdPartySecChecklist
				? 'Uploaded'
				: '',
			required: false,
		},
	];
};

const removeUUIDFromString = (inputString: string) => {
	if (!inputString) return;
	const uuidPattern =
		/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;

	const outputString = inputString.replace(uuidPattern, '').substring(1);

	return outputString;
};
