import { filterArrayValues, handleArrayWithSpaceValues } from 'utils/filter';
import { isEmpty } from 'lodash';
import { decrypt } from 'utils/codeGenerator';
import {
	BusinessGroupChannel,
	PartnerShipTypes,
	UploadSchedule,
} from 'utils/lookup';
import {
	SETTLEMENT_AGREEMENT_OPTIONS,
	RENEWAL_FREQUENCY_OPTIONS,
	DEPOSIT_CONSOLIDATION_OPTIONS,
	DEPOSIT_MODE_OPTIONS,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/ContractDetails/ContractDetailsConstants';
//import { upload_schedule } from 'constants/upload_schedule';
import { POST_TYPE_OPTIONS } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';

export const remappedChannelDataHandler = (
	data: any,
	result: string | undefined,
	reportsField: string | undefined
) => {
	// if (data?.action.toLowerCase().includes('enabled')) {
	// } else
	// if (data?.action.toLowerCase().includes('disabled')) {
	// } else if (data?.action.toLowerCase().includes('updated')) {
	// } else {
	// if (
	// 	result === 'updated' &&
	// 	data?.action.toLowerCase().includes('updated')
	// ) {
	// 	const channelStatus = data?.result?.channel;
	// 	const status = channelStatus.status;
	// 	if (!status) {
	// 		return;
	// 	}

	// 	const updatedArray: any[] = [];
	// 	updatedArray.push({
	// 		partner: '-',
	// 		product_types: '-',
	// 		branch: '-',
	// 		tpaid: '-',
	// 		old_value: status.oldValue,
	// 		new_value: status.newValue,
	// 	});

	// 	if (updatedArray.length > 0) {
	// 		return updatedArray;
	// 	} else {
	// 		return;
	// 	}
	// }
	if (
		result === 'approvedServiceFee' &&
		data?.action &&
		data?.action.toLowerCase().includes('approved service fee share')
	) {
		if (!data?.result?.channel) {
			return;
		}
		const channelStatus = data?.result?.channel;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}

	if (
		result === 'rejectedServiceFee' &&
		data?.action &&
		data?.action.toLowerCase().includes('rejected service fee share')
	) {
		if (!data?.result?.channel) {
			return;
		}
		const channelStatus = data?.result?.channel;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}
	// if (result === 'disabled') {
	// 	console.log('disable product channel level1');
	// }

	if (
		result === 'disabledProduct' &&
		data?.action &&
		data?.action.toLowerCase().includes('disabled product type')
	) {
		if (!data?.result?.productType) {
			console.error('Products data is missing');
			return [];
		}

		const productType = data.result.productType || '-';
		const oldValue = data.result.status.oldValue || '-';
		const newValue = data.result.status.newValue || '-';
		const productName = '-';
		const branchName = '-';
		const terminalName = '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});
		if (data.result.products) {
			data.result.forEach((product: any) => {
				// const productName = product.product || '-';
				// const productType = data.result.productType || '-';
				// const branchName = '-';
				// const terminalName = '-';
				// const oldValue = data.result.status.oldValue || '-';
				// const newValue = data.result.status.newValue || '-';

				// remappedData.push({
				// 	productName,
				// 	productType,
				// 	branchName,
				// 	terminalName,
				// 	oldValue,
				// 	newValue,
				// });

				product.branches.forEach((branch: any) => {
					const branchName = branch.name || '-';
					const branchStatusOldValue = branch.status.oldValue || '-';
					const branchStatusNewValue = branch.status.newValue || '-';

					const terminals = branch.terminals || [];

					if (terminals.length > 0) {
						terminals.forEach((terminal: any) => {
							const terminalName = terminal.name || '-';
							const terminalStatusOldValue = terminal.status.oldValue || '-';
							const terminalStatusNewValue = terminal.status.newValue || '-';

							remappedData.push({
								productType,
								productName,
								branchName,
								terminalName,
								branchStatusOldValue,
								branchStatusNewValue,
								terminalStatusOldValue,
								terminalStatusNewValue,
								oldValue,
								newValue,
							});
						});
					} else {
						remappedData.push({
							productType,
							productName,
							branchName,
							terminalName: '-',
							branchStatusOldValue,
							branchStatusNewValue,
							terminalStatusOldValue: '-',
							terminalStatusNewValue: '-',
							oldValue,
							newValue,
						});
					}
				});

				if (product.branches.length === 0) {
					remappedData.push({
						productType,
						productName,
						branchName: '-',
						branchStatusOldValue: '-',
						branchStatusNewValue: '-',
						terminalStatusOldValue: '-',
						terminalStatusNewValue: '-',
						oldValue,
						newValue,
					});
				}
			});
		}

		return remappedData;
	}

	if (
		result === 'enabledProduct' &&
		data?.action &&
		data?.action.toLowerCase().includes('enabled product type')
	) {
		if (!data?.result?.productType) {
			console.error('Products data is missing');
			return [];
		}

		const productType = data.result.productType || '-';
		const oldValue = data.result.status.oldValue || '-';
		const newValue = data.result.status.newValue || '-';
		const productName = '-';
		const branchName = '-';
		const terminalName = '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});

		if (data.result.products) {
			data.result.products.forEach((product: any) => {
				const productName = product.product || '-';
				const productType = data.result.productType || '-';
				const branchName = '-';
				const terminalName = '-';
				const oldValue = data.result.status.oldValue || '-';
				const newValue = data.result.status.newValue || '-';

				remappedData.push({
					productName,
					productType,
					branchName,
					terminalName,
					oldValue,
					newValue,
				});

				product.branches.forEach((branch: any) => {
					const branchName = branch.name || '-';
					const branchStatusOldValue = branch.status.oldValue || '-';
					const branchStatusNewValue = branch.status.newValue || '-';

					const terminals = branch.terminals || [];

					if (terminals.length > 0) {
						terminals.forEach((terminal: any) => {
							const terminalName = terminal.name || '-';
							const terminalStatusOldValue = terminal.status.oldValue || '-';
							const terminalStatusNewValue = terminal.status.newValue || '-';

							remappedData.push({
								productType,
								productName,
								branchName,
								terminalName,
								branchStatusOldValue,
								branchStatusNewValue,
								terminalStatusOldValue,
								terminalStatusNewValue,
								oldValue,
								newValue,
							});
						});
					} else {
						remappedData.push({
							productType,
							productName,
							branchName,
							terminalName: '-',
							branchStatusOldValue,
							branchStatusNewValue,
							terminalStatusOldValue: '-',
							terminalStatusNewValue: '-',
							oldValue,
							newValue,
						});
					}
				});

				if (product.branches.length === 0) {
					remappedData.push({
						productType,
						productName,
						branchName: '-',
						branchStatusOldValue: '-',
						branchStatusNewValue: '-',
						terminalStatusOldValue: '-',
						terminalStatusNewValue: '-',
						oldValue,
						newValue,
					});
				}
			});
		}

		return remappedData;
	}

	if (
		result === 'disabledPartner' &&
		data?.action &&
		data?.action
			.replace(/\s/g, '')
			.includes(
				`DisabledProductPartner${data?.result?.product?.replace(
					/\s/g,
					''
				)}ChannelPartnerLevel`
			)
	) {
		if (!data?.result) {
			console.error('No disabled data found.');
			return [];
		}

		const product = data.result.product || '-';
		const productType = data.result.productType || '-';
		const old_value = data.result.status.oldValue || '-';
		const new_value = data.result.status.newValue || '-';
		const branchName = '-';
		const terminalName = '-';

		const remappedData: any[] = [];

		remappedData.push({
			product,
			productType,
			branchName,
			terminalName,
			old_value,
			new_value,
		});

		if (data.result.branches) {
			data.result.branches.forEach((branch: any) => {
				const branchName = branch.branch || '-';
				const branchStatusOldValue = branch.status.oldValue || '-';
				const branchStatusNewValue = branch.status.newValue || '-';

				const terminals = branch.terminals || [];

				terminals.forEach((terminal: any) => {
					const terminalName = terminal.terminal || '-';
					const terminalStatusOldValue = terminal.status.oldValue || '-';
					const terminalStatusNewValue = terminal.status.newValue || '-';

					remappedData.push({
						product,
						productType,
						branchName,
						branchStatusOldValue,
						branchStatusNewValue,
						terminalName,
						terminalStatusOldValue,
						terminalStatusNewValue,
						old_value,
						new_value,
					});
				});

				if (terminals.length === 0) {
					remappedData.push({
						product,
						productType,
						branchName,
						branchStatusOldValue,
						branchStatusNewValue,
						terminalName: '-',
						terminalStatusOldValue: '-',
						terminalStatusNewValue: '-',
						old_value,
						new_value,
					});
				}
			});
		}

		return remappedData;
	}

	if (
		result === 'enabledPartner' &&
		data?.action &&
		data?.action
			.replace(/\s/g, '')
			.includes(
				`EnabledProductPartner${data?.result?.product?.replace(
					/\s/g,
					''
				)}ChannelPartnerLevel`
			)
	) {
		if (!data?.result) {
			console.error('No enabled data found.');
			return;
		}

		const product = data.result.product || '-';
		const productType = data.result.productType || '-';
		const old_value = data.result.status.oldValue || '-';
		const new_value = data.result.status.newValue || '-';
		const branchName = '-';
		const terminalName = '-';

		const remappedData: any[] = [];

		remappedData.push({
			product,
			productType,
			branchName,
			terminalName,
			old_value,
			new_value,
		});

		if (data.result.branches) {
			data.result.branches.forEach((branch: any) => {
				const branchName = branch.branch || '-';
				const branchStatusOldValue = branch.status.oldValue || '-';
				const branchStatusNewValue = branch.status.newValue || '-';

				const terminals = branch.terminals || [];

				terminals.forEach((terminal: any) => {
					const terminalName = terminal.terminal || '-';
					const terminalStatusOldValue = terminal.status.oldValue || '-';
					const terminalStatusNewValue = terminal.status.newValue || '-';

					remappedData.push({
						product,
						productType,
						branchName,
						branchStatusOldValue,
						branchStatusNewValue,
						terminalName,
						terminalStatusOldValue,
						terminalStatusNewValue,
						old_value,
						new_value,
					});
				});

				if (terminals.length === 0) {
					remappedData.push({
						product,
						productType,
						branchName,
						branchStatusOldValue,
						branchStatusNewValue,
						terminalName: '-',
						terminalStatusOldValue: '-',
						terminalStatusNewValue: '-',
						old_value,
						new_value,
					});
				}
			});
		}

		return remappedData;
	}

	if (
		result === 'rejectedNewChannel' &&
		data?.action &&
		data?.action.toLowerCase().includes('rejected new channel')
	) {
		if (!data?.result?.channel) {
			return;
		}
		const channelStatus = data?.result?.channel;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}

	if (
		result === 'approvedNewChannel' &&
		data?.action &&
		data?.action.toLowerCase().includes('approved new channel')
	) {
		if (!data?.result?.channel) {
			return;
		}
		const channelStatus = data?.result?.channel;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}

	if (
		result === 'updatedNewChannel' &&
		data?.action &&
		data?.action.toLowerCase().includes('updated channel status')
	) {
		if (!data?.result?.channel) {
			return;
		}
		const channelStatus = data?.result?.channel;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}

	if (result === 'status') {
		if (!data?.result) {
			return;
		}
		const channelStatus = data?.result;
		if (!channelStatus.status) {
			return;
		}
		const status = channelStatus.status;

		const statusArray: any[] = [];
		statusArray.push({
			field_name: 'Status',
			old_values: status.oldValue,
			new_values: status.newValue,
		});

		if (statusArray.length > 0) {
			return statusArray;
		} else {
			return;
		}
	}

	if (result === 'basicInfo') {
		if (!data?.result?.channelPrimaryInfo) {
			return;
		}
		const channelPrimaryInfo = data.result?.channelPrimaryInfo;

		const channelPrimaryInfoArray: any[] = [];

		const fieldsMapping = {
			name: 'Name',
			logo: 'Logo',
			channelAccountName: 'Channel Account Name',
			inCfs: 'Customer Frontline Solution (CFS)',
			channelAccountCode: 'Channel Account Code',
			businessGroup: 'Business Group',
			partnershipType: 'Partnership Type',
			channelTransactionType: 'Channel Transaction Type',
			channelType: 'Channel Type',
			posType: 'POS Type',
			settlementSetup: 'Settlement Setup',
			businessType: 'Business Type',
			otherBusinessType: 'Other Business Type',
			tin: 'Tin',
		};

		for (const [key, value] of Object.entries(fieldsMapping)) {
			const fieldValue = channelPrimaryInfo[key];
			if (fieldValue) {
				channelPrimaryInfoArray.push({
					field_name: value,
					old_values: fieldValue.oldValue,
					new_values: fieldValue.newValue,
				});
			}
		}

		if (channelPrimaryInfoArray.length > 0) {
			channelPrimaryInfoArray.forEach((entry) => {
				if (entry.field_name === 'POS Type') {
					if (entry.new_values !== '-')
						entry.new_values = POST_TYPE_OPTIONS.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = POST_TYPE_OPTIONS.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
				if (entry.field_name === 'Customer Frontline Solution (CFS)') {
					if (entry.new_values !== '-')
						entry.new_values = entry.new_values === '1' ? 'Yes' : 'No';
					if (entry.old_values !== '-')
						entry.old_values = entry.old_values === '1' ? 'Yes' : 'No';
				}
				if (entry.field_name === 'Business Group') {
					if (entry.new_values !== '-')
						entry.new_values = BusinessGroupChannel.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = BusinessGroupChannel.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
				if (entry.field_name === 'Partnership Type') {
					if (entry.new_values !== '-')
						entry.new_values = PartnerShipTypes.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = PartnerShipTypes.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
			});
			return channelPrimaryInfoArray;
		} else {
			return;
		}
	}

	if (result === 'channelAddress') {
		if (!data?.result?.channelPrimaryInfo?.channelAddresses) {
			return;
		}
		const channelAddresses = data.result?.channelPrimaryInfo?.channelAddresses;
		if (!channelAddresses || channelAddresses.length === 0) {
			return;
		}

		const channelAddressArray: any[] = [];

		for (let i = 0; i < channelAddresses.length; i++) {
			const address = channelAddresses[i];
			const addressFieldsMapping = {
				locationBase: 'Location Base',
				country: 'Country',
				area: 'Area',
				region: 'Region',
				province: 'State/Province',
				city: 'Municipality/City',
				barangay: 'Barangay',
				street: 'Street',
				buildingName: 'Building Name',
				zipCode: 'Zip Code',
			};

			for (const [key, value] of Object.entries(addressFieldsMapping)) {
				const fieldValue = address[key];
				if (fieldValue) {
					let field_name = value;
					if (channelAddresses.length > 1) {
						field_name += ` (${i + 1}/${channelAddresses.length})`;
					}
					channelAddressArray.push({
						field_name,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
		}

		if (channelAddressArray.length > 0) {
			return channelAddressArray;
		} else {
			return;
		}
	}

	// if (result === 'channelContactDetails') {
	// 	if (!data?.result?.channelPrimaryInfo?.channelContactDetails) {
	// 		return;
	// 	}
	// 	const channelContactDetails =
	// 		data.result?.channelPrimaryInfo?.channelContactDetails;
	// 	if (!channelContactDetails || channelContactDetails.length === 0) {
	// 		return;
	// 	}

	// 	const channelContactDetailsArray: any[] = [];

	// 	for (const contact of channelContactDetails) {
	// 		const contactFieldsMapping = {
	// 			name: 'Name',
	// 			department: 'Department',
	// 			position: 'Position',
	// 			emailAddress: 'Email Address',
	// 			telNo: 'Telephone Number',
	// 			mobileNo: 'Mobile Number',
	// 		};

	// 		for (const [key, value] of Object.entries(contactFieldsMapping)) {
	// 			const fieldValue = contact[key];
	// 			if (fieldValue) {
	// 				channelContactDetailsArray.push({
	// 					field_name: value,
	// 					old_values: fieldValue.oldValue,
	// 					new_values: fieldValue.newValue,
	// 				});
	// 			}
	// 		}
	// 	}

	// 	if (channelContactDetailsArray.length > 0) {
	// 		return channelContactDetailsArray;
	// 	} else {
	// 		return;
	// 	}
	// }

	if (result === 'channelContactDetails') {
		if (!data?.result?.channelPrimaryInfo?.channelContactDetails) {
			return;
		}
		const channelContactDetails =
			data.result?.channelPrimaryInfo?.channelContactDetails;
		if (isEmpty(channelContactDetails[0])) {
			return;
		}

		const contactFieldsMapping = {
			name: 'Name',
			department: 'Department',
			position: 'Position',
			emailAddress: 'Email Address',
			mobileNo: 'Mobile Number',
			telNo: 'Telephone Number',
		};

		const channelContactDetailsArray: any[] = [];

		for (let i = 0; i < channelContactDetails.length; i++) {
			const contact = channelContactDetails[i];

			for (const [key, value] of Object.entries(contactFieldsMapping)) {
				const fieldValue = contact[key];
				if (fieldValue) {
					let field_name = value;
					if (channelContactDetails.length > 1) {
						field_name += ` (${i + 1}/${channelContactDetails.length})`;
					}

					if (
						value === 'Email Address' ||
						value === 'Mobile Number' ||
						value === 'Telephone Number'
					) {
						if (fieldValue.oldValue !== '-') {
							fieldValue.oldValue = fieldValue.oldValue.replace(/,/g, ', ');
						}

						if (fieldValue.newValue !== '-') {
							fieldValue.newValue = fieldValue.newValue.replace(/,/g, ', ');
						}

						channelContactDetailsArray.push({
							field_name,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					} else {
						channelContactDetailsArray.push({
							field_name,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
				}
			}
		}

		return channelContactDetailsArray;
	}

	if (result === 'channelOfficer') {
		if (!data?.result?.channelPrimaryInfo?.channelOfficer) {
			return;
		}
		const channelOfficer = data.result?.channelPrimaryInfo?.channelOfficer;
		if (isEmpty(channelOfficer)) {
			return;
		}

		const channelOfficerArray: any[] = [];

		const officerFieldsMapping = {
			name: 'Name',
			emailAddress: 'Email Address',
			mobileNo: 'Mobile Number',
			telNo: 'Telephone Number',
		};

		for (const [key, value] of Object.entries(officerFieldsMapping)) {
			const fieldValue = channelOfficer[key];
			if (fieldValue) {
				// channelOfficerArray.push({
				// 	field_name: value,
				// 	old_values: fieldValue.oldValue,
				// 	new_values: fieldValue.newValue,
				// });

				if (
					value === 'Email Address' ||
					value === 'Mobile Number' ||
					value === 'Telephone Number'
				) {
					if (fieldValue.oldValue !== '-') {
						fieldValue.oldValue = fieldValue.oldValue.replace(/,/g, ', ');
					}

					if (fieldValue.newValue !== '-') {
						fieldValue.newValue = fieldValue.newValue.replace(/,/g, ', ');
					}

					channelOfficerArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				} else {
					channelOfficerArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
		}

		return channelOfficerArray;
	}

	if (result === 'channelContractDetails') {
		if (!data?.result?.channelContractDetails) {
			return;
		}
		const channelContractDetails = data.result?.channelContractDetails;
		if (!channelContractDetails) {
			return;
		}

		const channelContractDetailsArray: any[] = [];

		const contractFieldsMapping = {
			bondStartDate: 'Bond Start Date',
			bondEndDate: 'Bond End Date',
			settlementAgreement: 'Settlement Agreement',
			thresholdAmount: 'Threshold Amount',
			bondType: 'Bond Type',
			bondAmount: 'Bond Amount',
			depositConsolidation: 'Deposit Consolidation',
			depositMode: 'Deposit Mode',
			accreditationFee: 'Accreditation Fee',
			annualFee: 'Annual Fee',
			// vatType: 'VAT Type',
			// vatRate: 'VAT Rate',
			// otherBusinessLines: 'Other Business Lines',
		};

		for (const [key, value] of Object.entries(contractFieldsMapping)) {
			const fieldValue = channelContractDetails[key];
			if (fieldValue) {
				channelContractDetailsArray.push({
					field_name: value,
					old_values: fieldValue.oldValue,
					new_values: fieldValue.newValue,
				});
			}
		}

		if (channelContractDetailsArray.length > 0) {
			channelContractDetailsArray.forEach((entry) => {
				if (entry.field_name === 'Settlement Agreement') {
					if (entry.new_values !== '-')
						entry.new_values = SETTLEMENT_AGREEMENT_OPTIONS.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = SETTLEMENT_AGREEMENT_OPTIONS.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
				if (entry.field_name === 'Deposit Consolidation') {
					if (entry.new_values !== '-')
						entry.new_values = DEPOSIT_CONSOLIDATION_OPTIONS.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = DEPOSIT_CONSOLIDATION_OPTIONS.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
				if (entry.field_name === 'Deposit Mode') {
					if (entry.new_values !== '-')
						entry.new_values = DEPOSIT_MODE_OPTIONS.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = DEPOSIT_MODE_OPTIONS.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
				if (
					entry.field_name === 'Threshold Amount' ||
					entry.field_name === 'Bond Amount' ||
					entry.field_name === 'Accreditation Fee' ||
					entry.field_name === 'Annual Fee'
				) {
					if (entry.new_values !== '-')
						entry.new_values = entry.new_values.toLocaleString('en-US', {
							style: 'decimal',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});
					if (entry.old_values !== '-')
						entry.old_values = entry.old_values.toLocaleString('en-US', {
							style: 'decimal',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});
				}
			});
			return channelContractDetailsArray;
		} else {
			return;
		}
	}

	if (result === 'channelVat') {
		if (!data?.result?.channelContractDetails?.channelVat) {
			return;
		}
		const channelVat = data.result?.channelContractDetails?.channelVat;
		const channelVatArray: any[] = [];

		const vatFieldsMapping = {
			vatType: 'VAT Type',
			vatRate: 'VAT Rate',
		};

		for (const [key, value] of Object.entries(vatFieldsMapping)) {
			const fieldValue = channelVat[key];
			if (fieldValue) {
				channelVatArray.push({
					field_name: value,
					old_values: fieldValue.oldValue,
					new_values: fieldValue.newValue,
				});
			}
		}

		if (channelVatArray.length > 0) {
			return channelVatArray;
		} else {
			return;
		}
	}

	if (result === 'otherBusinessSection') {
		if (!data?.result?.channelContractDetails?.otherBusinessSection) {
			return;
		}
		const otherBusinessSection =
			data.result?.channelContractDetails?.otherBusinessSection;

		const otherBusinessSectionArray: any[] = [];

		const businessSectionFieldsMapping = {
			otherBusinessLines: 'Other Business Lines',
		};

		for (const [key, value] of Object.entries(businessSectionFieldsMapping)) {
			const fieldValue = otherBusinessSection[key];
			if (fieldValue) {
				otherBusinessSectionArray.push({
					field_name: value,
					old_values: fieldValue.oldValue,
					new_values: fieldValue.newValue,
				});
			}
		}

		if (otherBusinessSectionArray.length > 0) {
			return otherBusinessSectionArray;
		} else {
			return;
		}
	}

	// if (result === 'channelCashCollection') {
	// 	if (!data?.result?.channelContractDetails?.channelCashCollection) {
	// 		return;
	// 	}
	// 	const cashCollection =
	// 		data.result?.channelContractDetails?.channelCashCollection;
	// 	const channelCashCollectionArray: any[] = [];

	// 	if (cashCollection && cashCollection.length > 0) {
	// 		for (const collection of cashCollection) {
	// 			const cashCollectionFieldsMapping = {
	// 				bank: 'Bank Name',
	// 				bankBranch: 'Bank Branch',
	// 				acctName: 'Account Name',
	// 				acctNo: 'Account Number',
	// 				acctType: 'Account Type',
	// 			};

	// 			for (const [key, value] of Object.entries(
	// 				cashCollectionFieldsMapping
	// 			)) {
	// 				const fieldValue = collection[key];
	// 				if (fieldValue) {
	// 					channelCashCollectionArray.push({
	// 						field_name: value,
	// 						old_values: fieldValue.oldValue,
	// 						new_values: fieldValue.newValue,
	// 					});
	// 				}
	// 			}
	// 		}
	// 	}

	// 	if (channelCashCollectionArray.length > 0) {
	// 		return channelCashCollectionArray;
	// 	} else {
	// 		return;
	// 	}
	// }

	if (result === 'channelCashCollection') {
		if (!data?.result?.channelContractDetails?.channelCashCollection) {
			return;
		}
		const cashCollection =
			data.result?.channelContractDetails?.channelCashCollection;
		const channelCashCollectionArray: any[] = [];

		if (cashCollection && cashCollection.length > 0) {
			for (let i = 0; i < cashCollection.length; i++) {
				const collection = cashCollection[i];
				const cashCollectionFieldsMapping = {
					bank: 'Bank Name',
					bankBranch: 'Bank Branch',
					acctName: 'Account Name',
					acctNo: 'Account Number',
					acctType: 'Account Type',
				};

				for (const [key, value] of Object.entries(
					cashCollectionFieldsMapping
				)) {
					const fieldValue = collection[key];
					if (fieldValue) {
						let field_name = value;
						if (cashCollection.length > 1) {
							field_name += ` (${i + 1}/${cashCollection.length})`;
						}
						channelCashCollectionArray.push({
							field_name,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
				}
			}
		}

		if (channelCashCollectionArray.length > 0) {
			return channelCashCollectionArray;
		} else {
			return;
		}
	}

	// if (result === 'channelCheckCollection') {
	// 	if (!data?.result?.channelContractDetails?.channelCheckCollection) {
	// 		return;
	// 	}
	// 	const checkCollection =
	// 		data.result?.channelContractDetails?.channelCheckCollection;
	// 	const channelCheckCollectionArray: any[] = [];

	// 	if (checkCollection && checkCollection.length > 0) {
	// 		for (const collection of checkCollection) {
	// 			const checkCollectionFieldsMapping = {
	// 				bank: 'Bank Name',
	// 				bankBranch: 'Bank Branch',
	// 				acctName: 'Account Name',
	// 				acctNo: 'Account Number',
	// 				acctType: 'Account Type',
	// 			};

	// 			for (const [key, value] of Object.entries(
	// 				checkCollectionFieldsMapping
	// 			)) {
	// 				const fieldValue = collection[key];
	// 				if (fieldValue) {
	// 					channelCheckCollectionArray.push({
	// 						field_name: value,
	// 						old_values: fieldValue.oldValue,
	// 						new_values: fieldValue.newValue,
	// 					});
	// 				}
	// 			}
	// 		}
	// 	}

	// 	if (channelCheckCollectionArray.length > 0) {
	// 		return channelCheckCollectionArray;
	// 	} else {
	// 		return;
	// 	}
	// }

	if (result === 'channelCheckCollection') {
		if (!data?.result?.channelContractDetails?.channelCheckCollection) {
			return;
		}
		const checkCollection =
			data.result?.channelContractDetails?.channelCheckCollection;
		const channelCheckCollectionArray: any[] = [];

		if (checkCollection && checkCollection.length > 0) {
			for (let i = 0; i < checkCollection.length; i++) {
				const collection = checkCollection[i];
				const checkCollectionFieldsMapping = {
					bank: 'Bank Name',
					bankBranch: 'Bank Branch',
					acctName: 'Account Name',
					acctNo: 'Account Number',
					acctType: 'Account Type',
				};

				for (const [key, value] of Object.entries(
					checkCollectionFieldsMapping
				)) {
					const fieldValue = collection[key];
					if (fieldValue) {
						let field_name = value;
						if (checkCollection.length > 1) {
							field_name += ` (${i + 1}/${checkCollection.length})`;
						}
						channelCheckCollectionArray.push({
							field_name,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
				}
			}
		}

		if (channelCheckCollectionArray.length > 0) {
			return channelCheckCollectionArray;
		} else {
			return;
		}
	}

	if (result === 'channelAccreditation') {
		if (!data?.result?.channelContractDetails?.channelAccreditation) {
			return;
		}
		const accreditation =
			data.result?.channelContractDetails?.channelAccreditation;
		const channelAccreditationArray: any[] = [];

		if (accreditation) {
			const accreditationFieldsMapping = {
				birCertReg: 'BIR Certificate of Registration Form No. 2303',
				corpSecCert:
					'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
				businessPermit: 'Business Permit/Mayor’s Permit (updated/current year)',
				companyProfile: 'Company Profile',
				govtIds:
					'Government Issued IDs of Board of Directors, including the authorized signatories',
				genInfoSheets: 'Latest General Information Sheet (GIS)',
				nonDisclosure: 'Non-disclosure Agreement (NDA)',
				secRegCert: 'SEC Registration Certificate',
				secArticleIncorp:
					'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
				certAuthority:
					'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
				signedNda: 'Signed Non-Disclosure Agreement (NDA)',
				auditFinancialStmt:
					'Audited Financial Statements (with Auditor’s Opinion & Notes to Financial Statements)',
				incomeTaxReturn: 'Income Tax Return',
				cbciAmlQuestion: 'Accomplished CBCI AML Questionnaire',
				secondAuditFinStmt: `Audited Financial Statements (previous 2 years with Auditor's Opinion and Notes to Financial Statements)`,
				dataPrivacyPia: 'Data Privacy PIA',
				dataPrivacyPat: 'Data Privacy: PAT',
				thirdPartySecChecklist: 'Third-Party Security Checklist',
			};

			for (const [key, value] of Object.entries(accreditationFieldsMapping)) {
				const fieldValue = accreditation[key];
				if (fieldValue) {
					channelAccreditationArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
		}

		if (channelAccreditationArray.length > 0) {
			return channelAccreditationArray;
		} else {
			return;
		}
	}

	if (result === 'channelTermsDuration') {
		if (!data?.result?.channelContractDetails?.channelTermsDuration) {
			return;
		}
		const termsDuration =
			data.result?.channelContractDetails?.channelTermsDuration;
		const channelTermsDurationArray: any[] = [];

		if (termsDuration) {
			const termsDurationFieldsMapping = {
				executionDate: 'Execution Date',
				autoRenew: 'Is Auto Renew',
				terminationDate: 'Termination Date',
				renewalFrequency: 'Renewal Frequency',
				frequencyInterval: 'Frequency Interval',
			};

			for (const [key, value] of Object.entries(termsDurationFieldsMapping)) {
				const fieldValue = termsDuration[key];
				if (fieldValue) {
					channelTermsDurationArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
		}

		if (channelTermsDurationArray.length > 0) {
			channelTermsDurationArray.forEach((entry) => {
				if (entry.field_name === 'Is Auto Renew') {
					if (entry.new_values !== '-')
						entry.new_values = entry.new_values === '1' ? 'Yes' : 'No';
					if (entry.old_values !== '-')
						entry.old_values = entry.old_values === '1' ? 'Yes' : 'No';
				}
				if (entry.field_name === 'Termination Date') {
					if (entry.old_values !== '-')
						entry.old_values =
							entry.old_values === '0000-00-00' ? '-' : entry.old_values;
				}
				if (entry.field_name === 'Renewal Frequency') {
					// if (entry.new_values !== 'EVERY_N_YEARS')
					// 	channelTermsDurationArray.pop();
					if (entry.new_values !== '-')
						entry.new_values = RENEWAL_FREQUENCY_OPTIONS.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = RENEWAL_FREQUENCY_OPTIONS.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
			});
			const terminationDate = channelTermsDurationArray.filter(
				(entry) => entry.field_name === 'Termination Date'
			);
			if (
				terminationDate[0]?.new_values === '0000-00-00' &&
				terminationDate[0]?.old_values === '-'
			) {
				return channelTermsDurationArray.filter(
					(entry) => entry.field_name !== 'Termination Date'
				);
			}
			return channelTermsDurationArray;
		} else {
			return;
		}
	}

	if (result === 'channelReports') {
		if (!data?.result?.channelReports) {
			return;
		}
		const channelReports = data.result?.channelReports;
		if (!channelReports || channelReports.length === 0) {
			return;
		}

		const channelSftpConfigFields = [
			{ key: 'host', label: 'Host' },
			{ key: 'port', label: 'Port' },
			{ key: 'username', label: 'Username' },
			{ key: 'password', label: 'Password' },
			{ key: 'path', label: 'Path' },
		];

		const channelSmtpConfigFields = [
			{ key: 'sender', label: 'Sender Email Address' },
			{ key: 'receivers', label: 'Receiver Email Address' },
		];

		const channelReportColumnsMapping = [
			{ key: 'filenameFormat', label: 'Filename Format' },
			{ key: 'uploadSchedule', label: 'Upload Schedule' },
			{ key: 'uploadFrequency', label: 'Upload Frequency' },
			{ key: 'reportColumns', label: 'Reports Columns' },
		];

		const channelReportEventsFields = [
			{ key: 'eventTime', label: 'Generation Time' },
			{ key: 'coveredSchedule', label: 'Covered Schedule' },
			{ key: 'coveredTimeFrom', label: 'Covered Time From' },
			{ key: 'coveredTimeTo', label: 'Covered Time To' },
		];

		const fetchReport = (type) =>
			channelReports?.find((r) => r?.transmissionType === type) || {};

		if (
			fetchReport('SFTP')?.config === null &&
			fetchReport('SMTP')?.config === null
		) {
			return;
		}

		const fetchReportSMTP = fetchReport('SMTP');
		const fetchReportSFTP = fetchReport('SFTP');

		const decryptedConfig = (report) =>
			decrypt(report?.config, process.env.REACT_APP_APP_KEY, true, true);

		const sftpValue = decryptedConfig(fetchReportSFTP);
		const smtpValue = decryptedConfig(fetchReportSMTP);

		const reportConfigData = (smtpSftp, fieldType) =>
			fieldType.flatMap(
				(field) =>
					channelReports
						?.flatMap((smtpSftp) => {
							if (isEmpty(smtpSftp?.config)) return null;

							const decryptedConfig = decrypt(
								smtpSftp?.config,
								process.env.REACT_APP_APP_KEY,
								true,
								true
							);

							return {
								field_name: field.label,
								old_values: decryptedConfig?.[field.key]?.old_value.replaceAll(
									',',
									', '
								),
								new_values: decryptedConfig?.[field.key]?.new_value.replaceAll(
									',',
									', '
								),
							};
						})
						.filter(filterArrayValues) ?? []
			);

		const csrDefaults = {
			filenameFormat: '',
			uploadFrequency: '',
			uploadSchedule: '',
			schedule: [],
			csrColumns: [],
		};

		const convertTime = (time: string) => {
			if (typeof time !== 'string') {
				console.error('Invalid input: Expected a string');
				return time;
			}
			if (!/^(0?\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(time)) {
				console.error('Not a TIME Data: ', time);
				return time;
			}
			return time.slice(0, -3).padStart(5, '0');
		};

		const getTransmissionData = (type, fields, data) => {
			return fields.map((field) =>
				data
					?.filter((detail) => detail?.transmissionType === type)
					.map((detail) => {
						const pairs: any[] = [];
						const events = detail?.reportEvents ?? [];

						// Group UPLOAD and GENERATE events into pairs
						for (let i = 0; i < events.length; i += 2) {
							const uploadEvent = events[i];
							const generateEvent = events[i + 1];

							const eventIndex = i / 2 + 1;
							//const eventLabel = `${eventIndex}/${events.length / 2}`;
							if (uploadEvent && generateEvent) {
								const eventLabel = `${uploadEvent.pairId}/${detail?.uploadFrequencyLabel}`;
								const label = field.label;
								let eventLabelPair = `${label} (${eventLabel})`;

								//GENERATE
								if (field.key === 'eventTime') {
									if (
										generateEvent.eventType &&
										generateEvent.eventType == 'GENERATE'
									) {
										eventLabelPair = `Generation Time (${eventLabel})`;
									}
								}
								if (
									convertTime(
										handleArrayWithSpaceValues(
											generateEvent[field.key]?.newValue
										)
									)
								) {
									pairs.push({
										index: eventIndex,
										field_name: eventLabelPair,
										old_values: convertTime(
											handleArrayWithSpaceValues(
												generateEvent[field.key]?.oldValue
											)
										),
										new_values: convertTime(
											handleArrayWithSpaceValues(
												generateEvent[field.key]?.newValue
											)
										),
									});
								}

								//UPLOAD
								if (field.key === 'eventTime') {
									if (
										uploadEvent.eventType &&
										uploadEvent.eventType == 'UPLOAD'
									) {
										eventLabelPair = `Time of Upload (${eventLabel})`;
									}
								}

								if (
									convertTime(
										handleArrayWithSpaceValues(uploadEvent[field.key]?.newValue)
									)
								) {
									pairs.push({
										index: eventIndex,
										field_name: eventLabelPair,
										old_values: convertTime(
											handleArrayWithSpaceValues(
												uploadEvent[field.key]?.oldValue
											)
										),
										new_values: convertTime(
											handleArrayWithSpaceValues(
												uploadEvent[field.key]?.newValue
											)
										),
									});
								}
							}
						}
						return pairs;
					})
			);
		};

		const csrSftpArray: any[] = [];
		const csrSftpUcArray: any[] = [];
		const csrSmtpArray: any[] = [];
		const csrSmtpUcArray: any[] = [];

		const specificFieldNames = [
			'Filename Format',
			'Upload Schedule',
			'Upload Frequency',
			'Generation Time',
			'Time of Upload',
		];

		const processReportColumnData = (fieldType) =>
			fieldType.flatMap((field) =>
				channelReports
					?.map((channelReports) => {
						const fieldValue = channelReports?.[field.key];
						if (field.key === 'reportColumns' && fieldValue) {
							if (fieldValue.oldValue === '[]') {
								fieldValue.oldValue = '-';
							} else {
								fieldValue.oldValue = fieldValue.oldValue.replace(/\[|\]/g, '');
							}
							if (fieldValue.newValue === '[]') {
								fieldValue.newValue = '-';
							} else {
								fieldValue.newValue = fieldValue.newValue.replace(/\[|\]/g, '');
							}
						}

						if (
							field.key === 'uploadSchedule' &&
							fieldValue &&
							(channelReports.transmissionType == 'SFTP' ||
								channelReports.transmissionType == 'SMTP')
						) {
							if (fieldValue.newValue !== '-') {
								if (fieldValue.newValue === 'SAME') {
									fieldValue.newValue = 'Same Day';
								} else if (fieldValue.newValue === 'NEXT') {
									fieldValue.newValue = 'Next Day';
								}
							}
							if (fieldValue.oldValue !== '-') {
								if (fieldValue.oldValue === 'SAME') {
									fieldValue.oldValue = 'Same Day';
								} else if (fieldValue.oldValue === 'NEXT') {
									fieldValue.oldValue = 'Next Day';
								}
							}
						}

						if (fieldValue && channelReports.transmissionType == 'SFTP') {
							csrSftpArray.push({
								field_name: field.label,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else if (
							fieldValue &&
							channelReports.transmissionType == 'SMTP'
						) {
							csrSmtpArray.push({
								field_name: field.label,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else if (
							fieldValue &&
							channelReports.transmissionType == 'SFTP_UC'
						) {
							csrSftpUcArray.push({
								field_name: field.label,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else if (
							fieldValue &&
							channelReports.transmissionType == 'SMTP_UC'
						) {
							csrSmtpUcArray.push({
								field_name: field.label,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						} else {
							return null;
						}
					})
					.filter(Boolean)
			);

		const csrResultData = processReportColumnData(channelReportColumnsMapping);

		if (reportsField === 'sftp') {
			const channelSftpArray: any[] = [];
			// const sftpCsrConfig = csrSftpConfig;
			// if (sftpCsrConfig !== null) {
			// 	channelSftpArray.push(sftpCsrConfig);
			// }

			const sftpReportConfigData = reportConfigData(
				fetchReportSFTP,
				channelSftpConfigFields
			);

			if (sftpReportConfigData.length > 0) {
				channelSftpArray.push(...sftpReportConfigData);
			}

			if (channelSftpArray.length > 0) {
				return channelSftpArray;
			} else {
				return;
			}
		} else if (reportsField === 'smtp') {
			const channelSmtpArray: any[] = [];
			// const smtpCsrConfig = csrSmtpConfig;
			// if (smtpCsrConfig !== null) {
			// 	channelSmtpArray.push(smtpCsrConfig);
			// }

			const smtpReportConfigData = reportConfigData(
				fetchReportSMTP,
				channelSmtpConfigFields
			);

			if (smtpReportConfigData.length > 0) {
				channelSmtpArray.push(...smtpReportConfigData);
			}

			if (channelSmtpArray.length > 0) {
				return channelSmtpArray;
			} else {
				return;
			}
		} else if (reportsField === 'sftpCsr') {
			// const sftpCsr = processReportColumnData(
			// 	fetchReportSFTP,
			// 	channelReportColumnsMapping
			// );

			const channelSFTPData = getTransmissionData(
				'SFTP',
				channelReportEventsFields,
				channelReports
			);

			const flattenedArray = channelSFTPData.flatMap(
				(innerArray) => innerArray
			);

			const flattenedArray2 = flattenedArray.flatMap(
				(innerArray) => innerArray
			);
			flattenedArray2.forEach((entry) => {
				if (entry.field_name === 'Upload Schedule') {
					if (entry.new_values !== '-')
						entry.new_values = UploadSchedule.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = UploadSchedule.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
			});

			const filteredData = flattenedArray2.filter((item, index) => {
				if (index % 2 === 0 && item.old_values !== '' && item.new_values !== '')
					return true;
				return specificFieldNames.some((fieldName) =>
					item.field_name.includes(fieldName)
				);
			});

			if (flattenedArray2.length > 0) {
				csrSftpArray.push(...filteredData);
			}

			if (csrSftpArray.length > 0) {
				return csrSftpArray.slice().sort((a, b) => a.index - b.index);
			} else {
				return;
			}
		} else if (reportsField === 'smtpCsr') {
			// const smtpCsr = processReportColumnData(
			// 	fetchReportSMTP,
			// 	channelReportColumnsMapping
			// );

			const channelSMTPData = getTransmissionData(
				'SMTP',
				channelReportEventsFields,
				channelReports
			);

			const flattenedArray = channelSMTPData.flatMap(
				(innerArray) => innerArray
			);

			const flattenedArray2 = flattenedArray.flatMap(
				(innerArray) => innerArray
			);

			flattenedArray2.forEach((entry) => {
				if (entry.field_name === 'Upload Schedule') {
					if (entry.new_values !== '-')
						entry.new_values = UploadSchedule.find(
							(item) => item.value === entry.new_values
						)?.label;
					if (entry.old_values !== '-')
						entry.old_values = UploadSchedule.find(
							(item) => item.value === entry.old_values
						)?.label;
				}
			});

			const filteredData = flattenedArray2.filter((item, index) => {
				if (index % 2 === 0 && item.old_values !== '' && item.new_values !== '')
					return true;
				return specificFieldNames.some((fieldName) =>
					item.field_name.includes(fieldName)
				);
			});

			if (flattenedArray2.length > 0) {
				csrSmtpArray.push(...filteredData);
			}
			if (csrSmtpArray.length > 0) {
				return csrSmtpArray.slice().sort((a, b) => a.index - b.index);
			} else {
				return;
			}
		} else if (reportsField === 'smtp_uc') {
			// const sftpCsr = processReportColumnData(
			// 	fetchReportSFTP,
			// 	channelReportColumnsMapping
			// );

			const channelSMTPData_UC = getTransmissionData(
				'SMTP_UC',
				channelReportEventsFields,
				channelReports
			);

			const flattenedArray = channelSMTPData_UC.flatMap(
				(innerArray) => innerArray
			);

			const flattenedArray2 = flattenedArray.flatMap(
				(innerArray) => innerArray
			);

			if (flattenedArray2.length > 0) {
				csrSmtpUcArray.push(...flattenedArray2);
			}

			if (csrSmtpUcArray.length > 0) {
				csrSmtpUcArray.forEach((entry) => {
					if (entry.field_name === 'Upload Schedule') {
						if (entry.new_values !== '-')
							entry.new_values = UploadSchedule.find(
								(item) => item.value === entry.new_values
							)?.label;
						if (entry.old_values !== '-')
							entry.old_values = UploadSchedule.find(
								(item) => item.value === entry.old_values
							)?.label;
					}
				});

				const filteredData = csrSmtpUcArray.filter((item, index) => {
					if (
						index % 2 === 0 &&
						item.old_values !== '' &&
						item.new_values !== ''
					)
						return true;
					return specificFieldNames.some((fieldName) =>
						item.field_name.includes(fieldName)
					);
				});
				return filteredData.slice().sort((a, b) => a.index - b.index);
			} else {
				return;
			}
		} else if (reportsField === 'sftp_uc') {
			// const sftpCsr = processReportColumnData(
			// 	fetchReportSFTP,
			// 	channelReportColumnsMapping
			// );

			const channelSFTPData_UC = getTransmissionData(
				'SFTP_UC',
				channelReportEventsFields,
				channelReports
			);

			const flattenedArray = channelSFTPData_UC.flatMap(
				(innerArray) => innerArray
			);

			const flattenedArray2 = flattenedArray.flatMap(
				(innerArray) => innerArray
			);

			if (flattenedArray2.length > 0) {
				csrSftpUcArray.push(...flattenedArray2);
			}

			if (csrSftpUcArray.length > 0) {
				csrSftpUcArray.forEach((entry) => {
					if (entry.field_name === 'Upload Schedule') {
						if (entry.new_values !== '-')
							entry.new_values = UploadSchedule.find(
								(item) => item.value === entry.new_values
							)?.label;
						if (entry.old_values !== '-')
							entry.old_values = UploadSchedule.find(
								(item) => item.value === entry.old_values
							)?.label;
					}
				});

				const filteredData = csrSftpUcArray.filter((item, index) => {
					if (
						index % 2 === 0 &&
						item.old_values !== '' &&
						item.new_values !== ''
					)
						return true;
					return specificFieldNames.some((fieldName) =>
						item.field_name.includes(fieldName)
					);
				});
				return filteredData.slice().sort((a, b) => a.index - b.index);
			} else {
				return;
			}
		} else {
			return;
		}
	}

	//}
	if (
		result === 'disabledTerminal' &&
		data?.action &&
		data?.result?.product &&
		data?.action
			.replace(/\s/g, '')
			.includes(
				`DisabledProductPartner${data?.result?.product?.replace(
					/\s/g,
					''
				)}TerminalLevel`
			)
	) {
		if (!data?.result) {
			return [];
		}

		const productName = data.result.product || '-';
		const productType = data.result.productType || '-';
		const branchName = data.result.branch || '-';
		const terminalName = data.result.terminal || '-';
		const oldValue = data.result.status.oldValue || '-';
		const newValue = data.result.status.newValue || '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});

		return remappedData;
	}

	if (
		result === 'enabledTerminal' &&
		data?.action &&
		data?.result?.product &&
		data?.action
			.replace(/\s/g, '')
			.includes(
				`EnabledProductPartner${data?.result?.product?.replace(
					/\s/g,
					''
				)}TerminalLevel`
			)
	) {
		if (!data?.result) {
			return [];
		}

		const productName = data.result.product || '-';
		const productType = data.result.productType || '-';
		const branchName = data.result.branch || '-';
		const terminalName = data.result.terminal || '-';
		const oldValue = data.result.status.oldValue || '-';
		const newValue = data.result.status.newValue || '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});

		return remappedData;
	}

	//}
	if (
		result === 'disabledBranch' &&
		data?.action &&
		data?.result?.product &&
		data?.action
			.toLowerCase()
			.includes(
				`disabled product partner ${data?.result?.product.toLowerCase()} branch level`
			)
	) {
		if (!data?.result) {
			return [];
		}

		const productName = data?.result?.product || '-';
		const productType = data?.result?.productType || '-';
		const branchName = data?.result?.branch || '-';
		const terminalName = data?.result?.terminal || '-';
		const oldValue = data?.result?.status.oldValue || '-';
		const newValue = data?.result?.status.newValue || '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});

		const terminals = data?.result?.terminals || [];

		terminals.forEach((terminal: any) => {
			const terminalName = terminal.terminal || '-';
			const terminalStatusOldValue = terminal.status.oldValue || '-';
			const terminalStatusNewValue = terminal.status.newValue || '-';

			remappedData.push({
				productName,
				productType,
				branchName,
				terminalName,
				terminalStatusOldValue,
				terminalStatusNewValue,
				oldValue,
				newValue,
			});
		});

		if (terminals.length === 0) {
			remappedData.push({
				productName,
				productType,
				branchName,
				terminalName: '-',
				terminalStatusOldValue: '-',
				terminalStatusNewValue: '-',
				oldValue,
				newValue,
			});
		}

		return remappedData;
	}

	if (
		result === 'enabledBranch' &&
		data?.action &&
		data?.result?.product &&
		data?.action
			.toLowerCase()
			.includes(
				`enabled product partner ${data?.result?.product.toLowerCase()} branch level`
			)
	) {
		if (!data?.result) {
			return [];
		}

		const productName = data.result.product || '-';
		const productType = data.result.productType || '-';
		const branchName = data.result.branch || '-';
		const terminalName = data.result.terminal || '-';
		const oldValue = data.result.status.oldValue || '-';
		const newValue = data.result.status.newValue || '-';

		const remappedData: any[] = [];

		remappedData.push({
			productName,
			productType,
			branchName,
			terminalName,
			oldValue,
			newValue,
		});

		const terminals = data?.result?.terminals || [];

		terminals.forEach((terminal: any) => {
			const terminalName = terminal.terminal || '-';
			const terminalStatusOldValue = terminal.status.oldValue || '-';
			const terminalStatusNewValue = terminal.status.newValue || '-';

			remappedData.push({
				productName,
				productType,
				branchName,
				terminalName,
				terminalStatusOldValue,
				terminalStatusNewValue,
				oldValue,
				newValue,
			});
		});

		if (terminals.length === 0) {
			remappedData.push({
				productName,
				productType,
				branchName,
				terminalName: '-',
				terminalStatusOldValue: '-',
				terminalStatusNewValue: '-',
				oldValue,
				newValue,
			});
		}

		return remappedData;
	}

	return;
};

// export const actionChecker = (data: any) => {
// 	const showAllChannelTabs = [
// 		'Added Channel Profile',
// 		'Saved draft',
// 		'Edited draft',
// 		'Added Profile',
// 		'Approved New Channel',
// 		'Rejected New Channel',
// 	].map((actions) => actions.toUpperCase());

// 	const showChannelPrimaryInfo = [
// 		'Edited Channel Details - Primary Info',
// 		'Edited details',
// 		'Enable Product Channel Level',
// 		'Disable Product Channel Level',
// 		'Enable Partner Channel Level',
// 		'Disable Partner Channel Level',
// 		'Enable Branch Channel Level',
// 		'Disable Branch Channel Level',
// 		'Enable TPAID Channel Level',
// 		'Disable TPAID Channel Level',
// 		'Added Service Fee Share',
// 		'Edited Service Fee Share',
// 	].map((actions) => actions.toUpperCase());

// 	const showChannelBasicInfo = [
// 		'Updated Channel status',
// 		'Edited Channel details',
// 		'Edited Channel details',
// 		'Approved new Channel',
// 		'Rejected new Channel',
// 		'Updated Status',
// 		'Added Terminal Profile',
// 		'Updated Terminal status',
// 		'Edited Terminal details',
// 		'Updated Channel status',
// 		'Updated Branch status',
// 	].map((actions) => actions.toUpperCase());

// 	const showChannelContractDetails = [
// 		'Approved Service Fee Share',
// 		'Rejected Service Fee Share',
// 		'Edited Channel Details - Contract Details',
// 	].map((actions) => actions.toUpperCase());

// 	const showChannelReports = [
// 		'Downloaded file',
// 		'Edited Channel Details - Reports',
// 		'Send Channel Client Credential',
// 		'Download Channel Public Certificate',
// 		'Regenerate Channel Client Credential',
// 	].map((actions) => actions.toUpperCase());
