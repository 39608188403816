import ActionCell from './ActionCell';

import styles from './PartnerDetailsBody.module.css';

const ExpandableRow = ({
	branchName,
	data,
	toggleActiveValue,
	isExpandedRow = false,
	partnerId,
	channelId,
	branchId,
	setRefetchData,
}) => {
	const getToggleValue = (status: string) => {
		return status === toggleActiveValue ? true : false;
	};

	const mappedData = () => {
		return data?.map(({ name, status, tpid }) => ({
			id: name,
			status,
			tpaId: tpid,
		}));
	};

	const getStatusStyles = (status) => {
		if (status === 'ENABLED' || status === 'ACTIVE') {
			return styles.statusEnabled;
		} else if (status === 'DISABLED' || status === 'INACTIVE') {
			return styles.statusDisabled;
		} else if (status === 'CLOSED') {
			return styles.statusClosed;
		}
	};

	return (
		<tbody>
			{mappedData().map(({ id, status, tpaId }) => (
				<tr key={`${id}-${tpaId}`}>
					<td
						colSpan={2}
						className={styles.expandedIdCell}
						data-label={id}
						key={`${id}-${tpaId}`}
						style={{ width: `29.2%` }}
					>
						{id}
					</td>
					<td
						className={getStatusStyles(status)}
						data-label={status}
						key={`${status}-${tpaId}`}
						style={{ width: `23%` }}
					>
						{status === 'ACTIVE' ? 'Enabled' : 'Disabled'}
					</td>
					<td
						className={styles.expandedActionCell}
						data-label={tpaId}
						key={`action-${tpaId}`}
						style={{ width: `30%` }}
					>
						<div
							style={{
								marginLeft: '25%',
							}}
						>
							<ActionCell
								name={`action-${tpaId}`}
								initialToggleValue={getToggleValue(status)}
								confirmModalContents={{
									header: 'TPAID',
									bodyHeader: `${branchName} ${id}`,
								}}
								partnerId={partnerId}
								channelId={channelId}
								branchId={branchId}
								tpaId={tpaId}
								setRefetchData={setRefetchData}
							/>
						</div>
					</td>
				</tr>
			))}
		</tbody>
	);
};

export default ExpandableRow;
