import { common } from './common';
import * as yup from 'yup';
import { DateValue } from 'components/Inputs/Datepicker/SelectDatepicker';
import {
	transformDateFormat,
	validateDateSchema,
} from 'utils/formSchemas/common';

export type product_terms_duration = {
	execution_date?: string | DateValue;
	auto_renew?: string;
	renewal_frequency_type?: string;
	renewal_frequency?: string;
	termination_date?: string | DateValue;
} & common;

export const product_terms_duration_schema = yup.object().shape({
	execution_date: yup
		.mixed()
		.nullable()
		.label('Execution Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required('Input value for Execution Date'),
	auto_renew: yup
		.string()
		.nullable()
		.label('Auto-Renew')
		.required('Select value for Auto-Renew.')
		.oneOf(['1', '0']),
	renewal_frequency_type: yup
		.string()
		.nullable()
		.label('Renewal Frequency')
		.oneOf(['ANNUALLY', 'EVERY_N_YEARS'])
		.when('auto_renew', {
			is: '1',
			then: (s) => s.required('Select Renewal Frequency.'),
		}),
	renewal_frequency: yup
		.number()
		.nullable()
		.label('Frequency Interval')
		.min(0, 'This field only accepts positive numeric values.')
		.max(100, 'This field only accepts up to 100.')
		.when('renewal_frequency_type', {
			is: 'EVERY_N_YEARS',
			then: (s) => s.required('Input Frequency Interval.'),
		}),
	termination_date: yup
		.mixed()
		.nullable()
		.label('Termination Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.when('auto_renew', {
			is: '0',
			then: (s) => s.required(),
		}),
});
