import React from 'react';
import downloadIcon from '../../../assets/icons/ic-download.svg';
import styles from './DownloadButton.module.css';
import cx from 'classnames';
import Button from '../Button';

export default function DownloadButton({
	className = '',
	name = 'Download',
	onClick = () => {},
	fullWidth = false,
}) {
	return (
		<Button
			onClick={onClick}
			fullWidth={fullWidth}
			className={cx(
				'slds-button',
				'slds-button_neutral',
				styles.downloadButton,
				className
			)}
		>
			<img
				src={downloadIcon}
				className="slds-button__icon slds-button__icon_left"
				alt="Download Icon"
			/>
			{name}
		</Button>
	);
}
