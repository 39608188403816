import Divider from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/Divider/Divider';
import SFTP from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/SFTP';
import SMTP from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/SMTP';
import { ReportsTabProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import styles from './style.module.css';

const ReportsTab: React.FC<ReportsTabProps> = ({
	disabled,
	disabledEmail,
	channelId,
}) => {
	return (
		<div className={styles.container}>
			<SFTP disabled={disabled} channelId={channelId} />
			<Divider />
			<SMTP
				disabled={disabled}
				channelId={channelId}
				disabledEmail={disabledEmail}
			/>
		</div>
	);
};

export default ReportsTab;
