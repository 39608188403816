import { TABLE_COLUMNS } from 'components/Reports/constants/products.table';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTable } from "redux/modules/reports";
import { categories } from 'utils/queries/reports';

import ReportPlaceholder from 'components/Reports/ReportPlaceholder';
import ReportFilters from 'components/Reports/ReportFilters';
import ReportName from 'components/Reports/ReportName';
import Table from 'components/CWSTable/Table';

type propType = {
	categoryId: number;
	hasReportNameField?: boolean;
	tablePlaceHolderDisplay?: number;
};

export default function ReportsTabContent({ categoryId }: propType): JSX.Element {
	const dispatch = useDispatch();
	const states = useSelector((states: RootStateOrAny) => states.reports);
	const reports = states[categories[categoryId]];

	const hasReportNameOptions = reports.report_names.length > 0;
	const hasReportNameId = reports.filter.report_name_id !== 0;
	const { sort, sort_by } = reports.sorting;

	const isFiltering = reports.is_report_filtering;
	const pagination = reports.pagination;
	const filters = reports.filter;

	const handleFetchTable = (otherObj) => {
		if (!isFiltering) {
			dispatch(
				fetchCategoryTable({
					...filters,
					...pagination,
					...otherObj,
					categoryId,
				})
			);
		}
	};

	const handlePageChange = (page, limit, offset = 0) => {
		let _offset = offset; // start at zero
		if (page > 0) {
			_offset = (page - 1) * limit;
		}

		handleFetchTable({
			page,
			limit,
			offset: _offset,
		});
	};

	return (
		<div
			id="container"
			style={{ padding: '0.5vmax' }}
		>
			{hasReportNameOptions && (
				<ReportName
					options={reports.report_names}
					value={String(reports.filter.report_name_id)}
					onSelect={(value) => handleFetchTable({
						report_name_id: value,
					})}
				/>
			)}
			{hasReportNameOptions && !hasReportNameId && (
				<ReportPlaceholder display={1} />
			)}
			{!hasReportNameOptions && <ReportPlaceholder display={2} />}
			{hasReportNameId && (
				<>
					<ReportFilters />
					<Table
						scrollable
						sort={sort}
						sortBy={sort_by}
						onSort={undefined}
						isReportGeneration
						modalIsOpen={false}
						preHeader={undefined}
						columns={TABLE_COLUMNS}
						page={pagination.page}
						count={pagination.count}
						pageSize={pagination.limit}
						data={reports.report_files}
						isNotEvenRowColor={undefined}
						onPageChange={handlePageChange}
						onPageSizeChange={(limit) => handlePageChange(1, limit, 0)}
					/>
				</>
			)}
		</div>
	);
}
