import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';
import HTTP from 'helpers/ApiClient';
import {
	BASE_PATH,
	VIEW_PRODUCT_SCOPE,
	CHANNES_VIEW_PARTNER_LIST,
	VIEW_CHANNEL_DETAILS_SCOPE,
} from 'containers/ChannelManagement/Channel/ChannelProducts/const';

export const useGetChannelProduct = (id: number) => {
	return useQuery(VIEW_PRODUCT_SCOPE, async () => {
		const response = await client.get(BASE_PATH + `${id}/products/types`);
		return response.data;
	});
};

export const useGetChannelDetails = (id: string) => {
	return useQuery(VIEW_CHANNEL_DETAILS_SCOPE, async () => {
		const response = await client.get(BASE_PATH + `${id}`);
		return response.data;
	});
};

export const useGetPartnerList = (channelId: number, productType: number) => {
	return useQuery(CHANNES_VIEW_PARTNER_LIST, async () => {
		const response = await client.get(
			BASE_PATH + `${channelId}/products/${productType}`
		);
		return response.data;
	});
};

export const useGetPartnerListByType = (
	channelId: number,
	productType: number
) => {
	return useQuery('bip.channels.view.partner-list-type', async () => {
		const response = await client.get(
			BASE_PATH + `${channelId}/products/types/${productType}`
		);
		return response.data;
	});
};

export const enableDisablePartner = async (
	channelId: any,
	partnerId: any,
	isEnable: boolean,
	params: any
) => {
	const action = isEnable ? 'enable' : 'disable';
	return await HTTP.put(
		BASE_PATH + `${channelId}/products/${partnerId}/${action}`,
		params
	);
};

export const enableDisableBranch = async (
	branchId: any,
	partnerId: any,
	isEnable: boolean,
	params: any
) => {
	const action = isEnable ? 'enable' : 'disable';
	return await HTTP.put(
		`v2/branches/${branchId}/products/${partnerId}/${action}`,
		params
	);
};

export const enableDisableTerminal = async (
	terminalId: any,
	partnerId: any,
	isEnable: boolean,
	params: any
) => {
	const action = isEnable ? 'enable' : 'disable';
	return await HTTP.put(
		`v2/terminals/${terminalId}/products/${partnerId}/${action}`,
		params
	);
};

export const saveUpdateShare = async (
	payload: any,
	channelId: string,
	productId: string
) => {
	return await HTTP.post(
		BASE_PATH + `${channelId}/products/${productId}/fees`,
		payload
	);
};

export const getProductServiceFee = async (
	channelId: number,
	productId: number
) => {
	try {
		const response = await HTTP.get(
			BASE_PATH + `${channelId}/products/${productId}/fees`
		);
		if (response) {
			return response;
		} else {
			throw new Error('Response data is missing or invalid.');
		}
	} catch (error) {
		console.error('Error fetching product service fee:', error.message);
		throw error;
	}
};
