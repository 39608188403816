export function getLocalStorage(
	key: string,
	defaultValue: any = undefined
): any {
	const value = localStorage.getItem(key);

	if (!value) return defaultValue;

	return JSON.parse(value);
}

export function setLocalStorage(key: string, value: any): void {
	if (!key) return;

	localStorage.setItem(key, value);
}

export function deleteLocalStorage(key: string): void {
	localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
	localStorage.clear();
}

export function getSessionStorage(
	key: string,
	defaultValue: any = undefined
): any {
	const value = sessionStorage.getItem(key);

	if (!value) return defaultValue;

	return JSON.parse(value);
}

export function setSessionStorage(key: string, value: any): void {
	if (!key) return;

	const v = value ? JSON.stringify(value) : value;

	sessionStorage.setItem(key, v);
}

export function deleteSessionStorage(key: string): void {
	sessionStorage.removeItem(key);
}

export function clearSessionStorage(): void {
	sessionStorage.clear();
}

export function clearAllStorage(): void {
	clearLocalStorage();
	clearSessionStorage();
}
