import React, { useState, useEffect } from 'react';
import styles from './SessionTimeoutModal.module.css';
import { refresh } from '../../utils/sessionTimeout';
import { logOut } from '../../utils/logout';
import {
	Modal,
	Button,
} from '@salesforce/design-system-react/module/components';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useToggle } from 'utils/hooks';

type Props = {
	toggleModal: () => void;
};

const SessionTimeoutModal: React.FC<Props> = ({ toggleModal }) => {
	const [remainingTime, setRemainingTime] = useState(60);

	useEffect(() => {
		const timer = setInterval(() => {
			setRemainingTime(remainingTime - 1);
		}, 1000);

		if (remainingTime === 0) logout(true);

		return () => clearInterval(timer);
	}, [remainingTime]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const handleKeepMeLoggedIn = async () => {
		showLoading();
		await refresh();
		window.resetTimeout = true;
		hideLoading();
		toggleModal();
	};

	const logout = (isExpired) => {
		logOut(isExpired);
		toggleModal();
	};

	const content = (
		<section>
			<div className="slds-m-around_medium">
				<h5 className={styles.sessionHeader}>
					Your session is about to expire!
				</h5>
				<div className={styles.sessionText}>
					You will be logged out in{' '}
					<span style={{ color: '#F26122' }}>{remainingTime}</span> seconds.
					<br /> Do you want to stay logged in?
				</div>
			</div>
		</section>
	);

	return (
		<>
			<FullPageLoader
				open={isLoading}
				message="Refreshing Token, Please wait."
			/>
			<Modal
				disableClose
				footer={[
					<Button
						key="refresh-key"
						label="Yes, Keep Me Logged In"
						className={styles.sessionButton}
						onClick={handleKeepMeLoggedIn}
					/>,
					<Button
						key="logout"
						label="No, Logout"
						className={styles.sessionButton}
						onClick={() => logout(false)}
					/>,
				]}
				prompt="error"
				isOpen={true}
			>
				{content}
			</Modal>
		</>
	);
};

export default SessionTimeoutModal;
