import {
	ButtonGroup,
	Button,
} from '@salesforce/design-system-react/module/components';
import Select from '../Inputs/Select/Select';
import styles from './Table.module.css';
import cx from 'classnames';

const defaultPageSizeOptions = [
	{ label: '25', value: 25 },
	{ label: '50', value: 50 },
	{ label: '100', value: 100 },
];

export default function Pagination({
	page,
	count,
	canPreviousPage,
	canNextPage,
	pageOptions,
	previousPage,
	pageSizeOptions = defaultPageSizeOptions,
	gotoPage,
	nextPage,
	handlePageCount,
	pageIndexProps,
	isLimitConfigurable = false,
}) {
	return (
		<div
			className={cx(
				'slds-grid',
				'slds-grid_align-spread',
				styles.paginationContainer
			)}
		>
			<div className={cx('slds-col', styles.paginationInfoContainer)}>
				<span className={styles.paginationInfo}>
					Showing {page.length} of {count}
				</span>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
				}}
			>
				{isLimitConfigurable && (
					<div
						style={{ marginRight: '20px' }}
						className={styles.limitContainer}
					>
						<Select
							placeholder='25'
							onChange={(data) => handlePageCount(data)}
							options={pageSizeOptions}
						/>
					</div>
				)}
				<ButtonGroup className={styles.pagination}>
					<Button
						label='Previous'
						disabled={!canPreviousPage}
						onClick={() => previousPage()}
					/>
					{pageOptions.map((num) => {
						return (
							<Button
								key={num}
								disabled={pageIndexProps === num}
								label={num + 1}
								className={cx({
									[styles.pageActive]: pageIndexProps === num,
								})}
								onClick={() => {
									gotoPage(num);
								}}
							/>
						);
					})}
					<Button
						label='Next'
						disabled={!canNextPage}
						onClick={() => {
							nextPage();
						}}
					/>
				</ButtonGroup>
			</div>
		</div>
	);
}
