import React, { useState, useEffect, useMemo } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { connect, useSelector } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import _, { isEmpty } from 'lodash';
import { IconSettings, Button } from '@salesforce/design-system-react';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessFileDownloadModal, {
	SuccessFileDownloadModalBody,
	SuccessFileDownloadText,
	SuccessFileDownloadModalActions,
} from 'components/Modal/SuccessFileDownloadModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import PartnerAuditModal from 'components/PartnerAuditModal/PartnerAuditModal';
import ChannelCertificateTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Certificate/ChannelCertificateTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentials.module.css';
import cx from 'classnames';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import BasicInformation, {
	BasicInformationFields,
} from 'components/BillerForm/Tabs/PrimaryInformation/sections/BasicInformation/BasicInformation';
import {
	UseGetChannelCertificateList,
	useDownloadChannelCertificate,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Certificate/ChannelCertificateQuery';

type GetAuditTrailArgs = {
	page?: number;
	limit?: number;
};

interface ICell {
	value: any;
	row: any;
	cell?: any;
}

export type ChannelCredentialsFormData = {
	basicInformation?: BasicInformationFields;
};
type Props = {
	initialValues: ChannelCredentialsFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

// const ChannelIntegration = ({
// 	selectedBiller: selectedBranchPerCategory,
// 	product_type_id,
// }) => {

const ChannelCredentials: React.FC<Props> = ({
	initialValues,
	disabled,
	onSubmit,
}) => {
	interface ListSelection {
		id: number;
		name: string;
		logo: string;
		status: string;
		tpaAccount: string;
	}

	const selectedChannel = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedChannel
	);

	const activeTab: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeTab
	);

	const hasUserPermission = useHasUserPermission('products');

	// const selectedBiller = useMemo(
	// 	() => selectedBranchPerCategory[productCategory],
	// 	[productCategory, selectedBranchPerCategory]
	// );
	const selectedBiller = initialValues;

	const product_id = selectedBiller;
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [transformFileName, setTransformFileName] = useState([]);
	const [fileSuccessModalOnClick, setFileSuccessModalOnClick] = useState({
		action: () => {
			showSuccessFileDownloadModal();
		},
	});
	const [successModalOnClick, setSuccessModalOnClick] = useState({
		action: () => {
			showSuccessModal();
		},
	});
	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessFileDownloadModalShown,
		valueOn: showSuccessFileDownloadModal,
		valueOff: hideSuccessFileDownloadModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isPartnerAuditModalVisible,
		valueOn: showPartnerAuditModal,
		valueOff: hidePartnerAuditModal,
	} = useToggle();

	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};
	const [certificateName, setCertificateName] = useState({
		bucket_key: '',
	});

	const [tableFilter, setTableFilter] = useState({
		limit: 25,
		page: 1,
	});

	const selectedId = () => {
		let id: number | undefined = undefined;
		id = selectedChannel?.id;
		return id;
	};

	const [data, setData] = useState([]);
	const [auditDetailsList, setAuditDetailsList] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);

	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	const hasChannelUserPermission = useHasUserPermission('channels');

	const { hasViewChannelPublicCertificate, hasViewChannelClientCredentials } =
		useMemo(() => {
			return {
				hasViewChannelPublicCertificate: hasChannelUserPermission(
					'list.public.certificate'
				),
				hasViewChannelClientCredentials: hasChannelUserPermission(
					'list.client-credentials'
				),
			};
		}, [hasChannelUserPermission]);

	let getChannelCertificateList;
	if (hasViewChannelPublicCertificate) {
		getChannelCertificateList = UseGetChannelCertificateList(
			selectedId(),
			tableFilter,
			activeTab
		);
	}

	const {
		data: certificateListData,
		isLoading: isQueryLoading,
		isSuccess: isQuerySuccess,
		error: certError,
		refetch: refetchCertificatesList,
	} = getChannelCertificateList || {};

	const {
		data: certificateDownload,
		isLoading: isDownloadQueryLoading,
		error: certDownloadError,
		refetch: refetchDownload,
		isSuccess: isDownloadCert,
	} = useDownloadChannelCertificate(selectedId(), certificateName, activeTab);

	useEffect(() => {
		if (isDownloadCert && certificateDownload) {
			location.href = certificateDownload;
			showSuccessFileDownloadModal();
		}
	}, [isDownloadCert, certificateDownload]);

	useEffect(() => {
		if (
			certificateListData === undefined &&
			isQuerySuccess &&
			activeTab === 'ChannelIntegration'
		) {
			showErrorMessage('There is some error with your request.');
		}
	}, [certificateListData, isQuerySuccess]);

	useEffect(() => {
		refetchDownload();
	}, [certificateName]);

	useEffect(() => {
		if (selectedId() && certificateDownload === null && isDownloadCert) {
			showErrorMessage('There is some error with your request.');
		}
	}, [certificateDownload, isDownloadCert]);

	useEffect(() => {
		refetchCertificatesList?.();
	}, [tableFilter]);

	const handleOnClickViewLogs = async (row) => {
		setCertificateName({
			...certificateName,
			bucket_key: row.original.fileName,
		});
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			setTableFilter({
				...tableFilter,
				limit: _pageSize,
				page: _page,
			});
		}
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	useEffect(() => {
		if (certificateListData && certificateListData.data) {
			setTransformFileName(
				certificateListData.data.map((item) => {
					let fileNameWithoutExt = item.fileName.replace(/\.txt$/, ''); // Remove .txt at the end
					if (
						fileNameWithoutExt.includes('AM') ||
						fileNameWithoutExt.includes('PM')
					) {
						fileNameWithoutExt = fileNameWithoutExt.slice(0, -8); // Remove last 8 characters if AM or PM present
					}
					return {
						...item,
						fileNameDisplay: fileNameWithoutExt,
					};
				})
			);
		}
	}, [certificateListData]);

	const CERTIFICATE_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time Generated',
			id: 'createdAt',
			sortable: false,
			width: '40%',
			accessor: ({ createdAt }) => formatDate(createdAt),
		},
		{
			Header: 'Certificate',
			id: 'fileNameDisplay',
			sortable: false,
			width: '30%',
		},
		{
			Header: (): JSX.Element => (
				<div className={styles.actionHeader}>Action</div>
			),
			id: 'actions',
			width: '30%',
			Cell: ({ row }: ICell): JSX.Element => {
				const isLatestRow = row.index == 0;
				return (
					<div id="td-actions" className={styles.actions}>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx(styles.actionCell, 'slds-grid')}>
								<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.downloadIcon}
										iconCategory="utility"
										iconName="download"
										iconSize="medium"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn)}
										onClick={() => {
											handleOnClickViewLogs(row);
										}}
										variant="icon"
									/>
								</div>
							</div>
						</IconSettings>
					</div>
				);
			},
		},
	];

	const mockData = [
		{
			id: 1,
			created_at: '2023-07-01T12:34:56Z',
			certificate: 'KYSHANF331',
		},
		{
			id: 2,
			created_at: '2023-07-02T08:15:30Z',
			certificate: 'KYSQDGE153',
		},
		// Add more sample data objects as needed
	];

	return (
		<>
			{isSuccessFileDownloadModalShown && (
				<SuccessFileDownloadModal
					open={isSuccessFileDownloadModalShown}
					onClose={hideSuccessFileDownloadModal}
				>
					<SuccessFileDownloadModalBody>
						<SuccessFileDownloadText>
							<div className={styles.successHeader}>
								File is being Downloaded
							</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessFileDownloadText>
					</SuccessFileDownloadModalBody>
					<SuccessFileDownloadModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessFileDownloadModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessFileDownloadModalActions>
				</SuccessFileDownloadModal>
			)}

			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Timeout Error!</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{!isQueryLoading && certificateListData ? (
				<>
					<div className={styles.channelCeretificateTableContainer}>
						<ChannelCertificateTable
							columns={CERTIFICATE_COLUMNS(handleOnClickViewLogs)}
							// data={data}
							// count={count}
							data={transformFileName}
							count={transformFileName.length}
							pageSize={pageSize}
							page={page}
							onPageChange={handlePageChange}
						/>
					</div>
				</>
			) : (
				<PanelPlaceholder {...panelPlaceholderProps} />
			)}
			<PartnerAuditModal
				isOpen={isPartnerAuditModalVisible}
				data={auditDetailsList}
				onClose={() => {
					hidePartnerAuditModal();
				}}
			/>
		</>
	);
};

export default ChannelCredentials;
