import React, { useState, useMemo, useEffect } from 'react';
import { useToggle } from 'utils/hooks';
import channelProductStyle from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProducts.module.css';
import partnerDetailsStyles from 'components/PartnerDetails/PartnerDetails.module.css';
import successModalStyles from 'components/Modal/SuccessModal.module.css';
import errorModalStyles from 'components/Modal/ErrorModal.module.css';
import customModalStyles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useHasUserPermission } from 'utils/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/modules/channelList';
import { showAccessDeniedModal } from 'redux/modules/access';
import { convertDate } from 'utils/date';
import cx from 'classnames';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import ChannelProductModal from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal';
import {} from 'redux/modules/channelList';
import PartnerProductDetails from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/PartnerProductDetails';
import ProductTypeContainer from 'containers/ChannelManagement/Channel/ChannelProducts/ProductTypeContainer/ProductTypeContainer';
import { ChannelProductsTabComponentProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import client from 'helpers/ApiClient';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import { ReducerStateType } from 'redux/modules/reducers';
import StatusUpdate from '../ChannelStatusSelect/StatusUpdate';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorChannelModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const ChannelProductsTabComponent: React.FC<
	ChannelProductsTabComponentProps
> = () => {
	const selectedChannelId = useSelector<ReducerStateType, string>(
		(state) =>
			state.channels.selectedChannel && state.channels.selectedChannel.id
	);

	const [loadingMessage, setLoadingMessage] = useState('');
	const [retryGetProductTypes, setRetryGetProductTypes] =
		useState<boolean>(false);
	const [channelDetails, setChannelDetails] = useState<{
		data: {
			id: number | null;
			callbackUrl: string | null;
			createdAt: string;
			updatedAt: string;
			channelAddresses: any[];
			channelContactDetails: any[];
			channelPrimaryInfo: {
				channelTransactionType: string | null;
				channelType: string | null;
				logo: string | null;
				name: string;
				status: string;
				tpaAccount: string;
				tpaAccountType: string;
				description: string;
				updatedBy: string;
			};
		};
		products: [];
	}>({
		data: {
			id: null,
			callbackUrl: null,
			createdAt: '',
			updatedAt: '',
			channelAddresses: [],
			channelContactDetails: [],
			channelPrimaryInfo: {
				channelTransactionType: null,
				channelType: null,
				logo: null,
				name: '',
				status: '',
				tpaAccount: '',
				tpaAccountType: '',
				description: '',
				updatedBy: '',
			},
		},
		products: [],
	});

	useEffect(() => {
		dispatch(
			setLoader({
				isLoading: false,
				message: 'Please Wait...',
			})
		);

		setLoadingMessage('Please wait while data are being loaded');
		showLoader();
		const getProductTypes = async () => {
			try {
				if (selectedChannelId) {
					const response = await client.get(
						BASE_PATH + `${selectedChannelId}/products/types`
					);
					const products = response.data.data;
					setChannelDetails((prevData) => ({ ...prevData, products }));
					setTimeout(() => {
						hideLoader();
					}, 1600);
				}
			} catch (error) {
				// timeout error
				hideLoader();
				showErrorResponse();
			}
		};
		getProductTypes();

		return () => {
			setChannelDetails((prevData) => ({ ...prevData, products: [] }));
		};
	}, [selectedChannelId, retryGetProductTypes]);

	useEffect(() => {
		const fetchClientData = async () => {
			if (selectedChannelId) {
				const response = await client.get(
					`/v2/channels/${selectedChannelId}/primary-info`
				);
				const channelData = response.data;
				setChannelDetails((prevData) => ({
					...prevData,
					data: channelData.data,
				}));
			}
		};
		fetchClientData();
		return () => {
			setChannelDetails({
				data: {
					id: null,
					callbackUrl: null,
					createdAt: '',
					updatedAt: '',
					channelAddresses: [],
					channelContactDetails: [],
					channelPrimaryInfo: {
						channelTransactionType: null,
						channelType: null,
						logo: null,
						name: '',
						status: '',
						tpaAccount: '',
						tpaAccountType: '',
						description: '',
						updatedBy: '',
					},
				},
				products: [],
			});
		};
	}, [selectedChannelId]);

	const [isChannelProductActive, setChannelProductActive] = useState({
		isActive: true,
		product: {},
	});

	const {
		value: isErrorResponse,
		valueOn: showErrorResponse,
		valueOff: hideErrorResponse,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const styles = {
		...partnerDetailsStyles,
		...successModalStyles,
		...errorModalStyles,
		...channelProductStyle,
		...customModalStyles,
	};

	const dispatch = useDispatch();

	const hasUserPermission = useHasUserPermission('channels');

	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const handleChangeOfRemarks = (event) =>
		setIsRemarksEmpty(
			/^\s+$/.test(event.target.value) || event.target.value === ''
		);
	const { hasChannelListPermission, hasEditStatusPermission } = useMemo(() => {
		return {
			hasEditStatusPermission: hasUserPermission('edit.status'),
			hasEditPermision: hasUserPermission('edit'),
			hasChannelListPermission: hasUserPermission('list'),
		};
	}, [hasUserPermission]);

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [channelStatus, setChannelStatus] = useState('');
	const [remarks, setRemarks] = useState('');
	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});
	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};
	const updatedAt = convertDate(channelDetails.data.updatedAt, 'ISO');
	const createdAt = convertDate(channelDetails.data.createdAt, 'ISO');
	const updatedBy = channelDetails.data.channelPrimaryInfo.updatedBy;

	const onSelectStatus = (val) => {
		if (val != channelDetails.data.channelPrimaryInfo.status) {
			if (!hasEditStatusPermission) {
				dispatch(showAccessDeniedModal());
				return;
			}
			setChannelStatus(val);
			setShowConfirmationModal(true);
		}
	};

	const handleCancelBtnClick = () => {
		hideModal();
	};
	const handleConfirmBtnClick = () => {
		const updateStatus = channelDetails;
		updateStatus.data.channelPrimaryInfo.status = channelStatus;
		setChannelDetails(updateStatus);
		const params = { remarks: remarks };
		//api call: channel update-status
		//hideModal();
	};

	const hideModal = () => {
		setShowConfirmationModal(false);
		setRemarks('');
		setChannelStatus('');
		setIsRemarksEmpty(true);
		setErrorRemarks({ hasError: false, status: '' });
	};

	const setActivePage = (set: boolean, product: any) => {
		setChannelProductActive({ isActive: set, product: product });
	};

	useEffect(() => {
		if (selectedChannelId) {
			setChannelProductActive({ isActive: true, product: {} });
		}
	}, [selectedChannelId]);

	return selectedChannelId ? (
		<div className={styles.container}>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			<div className={styles.header}>
				{hasChannelListPermission && !isLoading && (
					<div className={styles.leftPanel}>
						<div className={styles.title}>
							{channelDetails.data.channelPrimaryInfo.name}
						</div>
						<div className={styles.status}>
							<div className={styles.statusLabel}>Channel Status:</div>
							<div className={styles.statusField}>
								<StatusUpdate
									value={channelDetails.data.channelPrimaryInfo.status}
									id={channelDetails.data.id}
									idType="channels"
									disabled
									onClose={(success) => {
										console.log('refetch Channel status value <<');
									}}
								/>
							</div>
						</div>
					</div>
				)}
				{/* {hasChannelListPermission && (
					<div className={styles.rightPanel}>
						<div className={cx(styles.dates, styles.contentSpace)}>
							<em className={styles.dateText}>
								{updatedAt && 'Last updated at ' + updatedAt}
								{updatedBy && updatedBy !== '-' && ' by ' + updatedBy}
							</em>
							<em className={styles.dateText}>
								{createdAt && 'Date created: ' + createdAt}
							</em>
						</div>
					</div>
				)} */}
			</div>
			<>
				{isChannelProductActive.isActive ? (
					<>
						{channelDetails.data.id &&
							channelDetails.products &&
							!isLoading && (
								<div>
									<ProductTypeContainer
										name="Product List"
										channel={channelDetails}
										activePage={setActivePage}
									/>
								</div>
							)}
					</>
				) : (
					<div className={styles.body}>
						<PartnerProductDetails
							tab={isChannelProductActive.product}
							activePage={setActivePage}
							data={channelDetails.data}
						/>
					</div>
				)}
			</>

			{showConfirmationModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					// isDisabled={isLoading}
					heading={
						<h2 className={styles.customHeading}>Update Channel Status?</h2>
					}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to update{' '}
							<strong>{channelDetails.data.channelPrimaryInfo.name}</strong>{' '}
							{'to '}
							<strong>{channelStatus}</strong>?
						</h2>
					}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				/>
			)}

			{isErrorResponse && (
				<ErrorModal
					open={isErrorResponse}
					onClose={hideErrorResponse}
					className={styles.errorContainer}
				>
					<ErrorModalBody>
						<ErrorText>
							<label className={styles.errorHeader}>Timeout Error!</label>
						</ErrorText>
						<ErrorSubText>
							<span className={styles.errorMessage}>
								A problem occurred while loading the data.
								<br />
								Please try again.
							</span>
						</ErrorSubText>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								// set retry get product types
								setRetryGetProductTypes(!retryGetProductTypes);
								hideErrorResponse();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</div>
	) : (
		<PanelPlaceholder {...panelPlaceholderProps} />
	);
};

export default ChannelProductsTabComponent;
