import {
	Control,
	useForm,
	UseFormClearErrors,
	UseFormSetValue,
	useWatch,
} from 'react-hook-form';
import styles from './ContractDetails.module.css';
import CollectionRemittance from './sections/CollectionRemittance/CollectionRemittance';
import OneTimeFee from './sections/OneTimeFee/OneTimeFee';
import UploadPenalty from './sections/UploadPenalty/UploadPenalty';
import DepositPenalty from './sections/DepositPenalty/DepositPenalty';
import OtherPenalties from './sections/OtherPenalties/OtherPenalties';
import CashCollectionDepositoryAccount from './sections/CashCollectionDepositoryAccount/CashCollectionDepositoryAccount';
import CheckCollectionDepositoryAccount from './sections/CheckCollectionDepositoryAccount/CheckCollectionDepositoryAccount';
import TermsAndDuration from './sections/TermsAndDuration/TermsAndDuration';
import DataSharingAgreement from './sections/DataSharingAgreement/DataSharingAgreement';
import Bond from './sections/Bond/Bond';
import AccreditationRequirements from './sections/AccreditationRequirements/AccreditationRequirements';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { contract_details_schema } from 'utils/models/product';
import client from 'helpers/ApiClient';
import { debounce, isEmpty } from 'lodash';
import {
	TProductState,
	setAutoSaveValues,
	setContractDetails,
	setSubmittingAll,
	setDrafting,
	setEditing,
	updateValidForm,
} from 'redux/modules/products';
import { usePreviousDistinct } from 'react-use';
import yup from 'utils/formSchemas/common';
import { DATE_FORMAT_ISO_8601, formatDate } from 'utils/common';
import { FixMeLater } from 'types';
import { LoaderState } from 'components/PartnerModal/PartnerModal';
import { ExcludeAutoSave } from 'components/PartnerDetails/PartnerDetails';

export type ContractDetailsSectionProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	data?: any;
	control: Control<any>;
};

type Props = {
	action?: string;
	disabled?: boolean;
	onSubmit?: (values: any, saveType: string | LoaderState | null) => void;
	data?: any;
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['autoSaveValues'];
	currentPartnerTab?: string;
	setContractDetails?: any;
	setSubmittingAll?: any;
	setDrafting?: any;
	setEditing?: any;
	products: Array<any>;
	productId: any;
	setLastAutoSaved?: SetStateAction<any>;
	setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	fromAutoSave: boolean;
};

export type ContractDetailsFormData = yup.InferType<
	typeof contract_details_schema
>;

const ContractDetails: React.FC<Props> = ({
	action,
	disabled,
	currentPartnerTab,
	data = {},
	setContractDetails,
	setSubmittingAll,
	setDrafting,
	setEditing,
	autoSaveValues,
	setAutoSaveValues,
	products,
	productId,
	setLastAutoSaved,
	onSubmit,
	fromAutoSave,
	currentPartnerStatus,
}) => {
	const dispatch = useDispatch();

	const [isAutoSaveLoading, setAutoSaveLoading] = useState(false);

	const inquireType =
		!['DRAFT', 'FOR_REVIEW', 'REJECTED'].includes(data.status) &&
		(data.id || data.product_contract.product_accreditation.product_id)
			? 'product_document_active'
			: 'product_document_draft';

	const statusSubmitting = useSelector<ReducerStateType>(
		(state) => state.products.isSubmittingAll
	);
	const drafting = useSelector<ReducerStateType>(
		(state) => state.products.drafting
	);
	const editing = useSelector<ReducerStateType>(
		(state) => state.products.editing
	);
	const previousTab = usePreviousDistinct(currentPartnerTab);

	const defaultValues = useMemo(
		() => ({
			...data?.product_contract,
			product_dpo_detail: {
				location_base: 'LOCAL',
				country_id: 175,
				...data?.product_contract?.product_dpo_detail,
			},
		}),
		[data?.product_contract]
	);

	const currentSelectedProduct = products.find(
		(product) => product.id === productId
	);

	const {
		control,
		setValue,
		getValues,
		clearErrors,
		resetField,
		formState: { isDirty, isValid, errors },
		handleSubmit,
		setError,
		reset,
		trigger,
	} = useForm<ContractDetailsFormData>({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(contract_details_schema),
		context: { currentSelectedProduct },
	});

	const formatFormContract = (v: Record<string, any>) => {
		const {
			product_contract: { collection_date, ...contractRest },
			product_accreditation = {},
			product_terms_duration: {
				execution_date: termsExDate,
				termination_date: termsTermDate,
				renewal_frequency_type = null,
				renewal_frequency,
				...termRest
			},
			product_dpo_detail: {
				country_id = 175,
				building_no,
				execution_date: dpoExDate,
				termination_date: dpoTermDate,
				zip_code_id: dpoZipCode,
				...dpoDetailRest
			},
			product_dsa_bond: {
				execution_date: bondExDate,
				termination_date: bondTermDate,
				...bondDetailRest
			},
			...rest
		} = v;

		// Hotfix for penalty_type being NaN
		let other_penalties = contractRest?.other_penalties || [];
		if (Array.isArray(other_penalties))
			other_penalties = other_penalties.map((v) => {
				if ([NaN, 'NaN', null].includes(v?.penalty_type))
					v.penalty_type = undefined;
				return v;
			});
		//

		return {
			...contractRest,
			other_penalties,
			collection_date: formatDate(collection_date, DATE_FORMAT_ISO_8601),
			product_accreditations: product_accreditation,
			product_terms_duration: {
				execution_date: formatDate(termsExDate, DATE_FORMAT_ISO_8601),
				termination_date: formatDate(termsTermDate, DATE_FORMAT_ISO_8601),
				renewal_frequency_type,
				renewal_frequency: renewal_frequency_type
					? renewal_frequency_type === 'ANNUALLY'
						? 1
						: renewal_frequency
					: undefined,
				...termRest,
			},
			product_dpo_details: {
				country_id,
				building_no,
				execution_date: formatDate(dpoExDate, DATE_FORMAT_ISO_8601),
				termination_date: formatDate(dpoTermDate, DATE_FORMAT_ISO_8601),
				zip_code: dpoZipCode?.label,
				...dpoDetailRest,
			},
			product_dsa_bonds: {
				execution_date: formatDate(bondExDate, DATE_FORMAT_ISO_8601),
				termination_date: formatDate(bondTermDate, DATE_FORMAT_ISO_8601),
				...bondDetailRest,
			},
			...rest,
		};
	};

	const accreditations = useWatch({
		control,
		name: 'product_accreditation' as FixMeLater,
	});

	useEffect(() => {
		if (drafting) {
			const values = formatFormContract(
				structuredClone({ ...getValues(), isValid, isDirty })
			);
			setContractDetails(values);
			setDrafting(false);
		}
	}, [drafting]);

	useEffect(() => {
		if (editing) {
			handleSubmit(
				(v) => {
					const validData = structuredClone({
						...v,
						isDirty,
						isValid,
					});
					setContractDetails(formatFormContract(validData));
				},
				(e) => console.log(e)
			)();

			const data = structuredClone({
				...getValues(),
				isDirty,
				isValid,
			});
			!isValid && setContractDetails(formatFormContract(data));
			setEditing(false);
		}
	}, [editing, isValid, isDirty]);

	useEffect(() => {
		if (
			disabled ||
			(action && ExcludeAutoSave.includes(action)) ||
			!fromAutoSave
		)
			return;
		if (!isEmpty(autoSaveValues)) {
			setAutoSaveLoading(true);
			client
				.get('v2/autosave/products')
				.then(({ data }) => {
					const values = data.data.contract_details.originalValues;
					reset(values);
					setAutoSaveValues({});
				})
				.then(() => {
					setAutoSaveLoading(false);
				});
		}
	}, []);

	const scoffoledData = async () => {
		onSubmit && (await onSubmit(null, LoaderState.ShowLoader));
		const data = getValues();
		const productType = products.find((p) => p.id === productId)?.code;

		client
			.post(`/v2/autosave/products`, {
				contractDetails: {
					...data,
					accreditations: { ...data?.product_accreditation },
					originalValues: getValues(),
				},
				productType,
			})
			.then(({ data }) => {
				setLastAutoSaved({
					date: data?.data?.created_at,
					username: data?.data?.username,
				});
				onSubmit && onSubmit(null, LoaderState.HideLoader);
			});
	};

	useEffect(() => {
		if (disabled || (action && ExcludeAutoSave.includes(action))) return;
		if (isDirty && previousTab == 'contract-details') {
			scoffoledData();
		}
	}, [currentPartnerTab, isDirty, previousTab]);

	useEffect(() => {
		if (disabled) return;
		clearErrors();
		if (statusSubmitting || previousTab === 'contract-details') {
			handleSubmit((v) => {
				const validData = structuredClone({ ...v, isDirty, isValid });
				setContractDetails(formatFormContract(validData));
			})();
			const data = structuredClone({ ...getValues(), isDirty, isValid });
			!isValid && setContractDetails(formatFormContract(data));
			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab]);

	useEffect(() => {
		debounce(() => {
			dispatch(
				updateValidForm({
					formTab: 'contractDetails',
					isValid,
					isDirty,
					isLoaded: true,
				})
			);
		}, 900)();
	}, [isValid, isDirty, dispatch]);

	// For reseting the form when refetched
	const isTabRefetched = useSelector<ReducerStateType>(
		(state) => state.products.contractDetails?.isRefetched
	);

	useEffect(() => {
		// reset form if refetched
		if (isTabRefetched) {
			reset(defaultValues, { keepDirty: false });
			updateValidForm({
				formTab: 'contractDetails',
				isDirty,
				isRefetched: false,
			});
		}
	}, [isTabRefetched, reset]);
	//

	return (
		<div className={styles.container}>
			<CollectionRemittance
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<OneTimeFee control={control} setValue={setValue} disabled={disabled} />
			<hr className={styles.divider} />
			<UploadPenalty
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<DepositPenalty
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<OtherPenalties
				control={control}
				setValue={setValue}
				disabled={disabled}
				isAutoSaveLoading={isAutoSaveLoading}
			/>
			<hr className={styles.divider} />
			<CashCollectionDepositoryAccount
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<CheckCollectionDepositoryAccount
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<TermsAndDuration
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<DataSharingAgreement
				control={control}
				setValue={setValue}
				disabled={disabled}
				clearErrors={clearErrors}
				isDirty={isDirty}
				resetField={resetField}
				getValue={getValues}
				trigger={trigger}
			/>
			<hr className={styles.divider} />
			<Bond control={control} setValue={setValue} disabled={disabled} />
			<hr className={styles.divider} />
			<AccreditationRequirements
				control={control}
				setValue={setValue}
				getValue={getValues}
				clearErrors={clearErrors}
				setError={setError}
				disabled={disabled}
				errors={errors}
				onLoadData={accreditations ?? {}}
				inquireType={inquireType}
				id={data.id || data.product_contract.product_accreditation.product_id}
				action={action}
				currentPartnerStatus={currentPartnerStatus}
			/>
		</div>
	);
};

export default connect(
	(state: any) => ({
		lastActionButton: state.products.lastActionButton,
		currentPartnerTab: state.products.currentBillerTab,
		autoSaveValues: state.products.autoSaveValues,
		selectedBiller: state.products.selectedBiller,
		productId: state.sidebar.itemId,
		products: state.sidebar.products,
	}),
	{
		setAutoSaveValues,
		setSubmittingAll,
		setDrafting,
		setEditing,
		setContractDetails,
	}
)(ContractDetails);
