import { createSlice } from "@reduxjs/toolkit";

const initialState = {userInfo: null};

const userInfo = createSlice({
    name: "userInfo",
    initialState,
    reducers: { },
});

export default userInfo.reducer;
