import React, { ReactNode } from 'react';
import cx from 'classnames';
import Grid, { SizeOptions } from 'components/Grid/Grid';

import styles from './SummaryCards.module.css';

type Props = {
	data: {
		label: string;
		value: string | number;
		icon: ReactNode;
		color: string;
	}[];
	footer?: string;
	className?: any;
};

const SummaryCards: React.FC<Props> = ({ data, footer, className = '' }) => {
	// length must not be greater than 12, Grid component have max 12 size option
	const cardCount = data?.length && data?.length > 12 ? 12 : data?.length;
	return (
		<div className={cx('slds-card', styles.paperContainer, className)}>
			<Grid container align="spread" gutters="xx-small">
				{data?.map(({ label, value, icon, color }, key) => (
					<Grid column size={1} of={cardCount as SizeOptions} key={key}>
						<div
							className={styles.cardContainer}
							style={{ border: `2px solid ${color}` }}
						>
							{cardCount < 3 ? (
								<div className={styles.largeIcon}>
									<div className={styles.liCardIcon}>{icon}</div>
									<div className={styles.liCardContent}>
										<div className={styles.liCardLabel}>{label}</div>
										<div className={styles.liCardValue}>{value}</div>
									</div>
								</div>
							) : (
								<>
									<div className={styles.cardLabel}>{label}</div>
									<div className={styles.cardContent}>
										<div>{icon}</div>
										<div className={styles.cardValue}>{value}</div>
									</div>
								</>
							)}
						</div>
					</Grid>
				))}
			</Grid>
			{footer && <div className={styles.paperContainerFooter}>{footer}</div>}
		</div>
	);
};

export default SummaryCards;
