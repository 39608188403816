import { Controller } from 'react-hook-form';
import cx from 'classnames';
import styles from './Filter.module.css';
import Select from '../../../components/Inputs/Select/Select';

const FilterSelect = ({
	name,
	label,
	control,
	onChange = () => {},
	options,
	menuClassName = '',
	placeholder = '',
	style = {},
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<div className={styles.filterContainer}>
						<label
							className={cx(styles.selectLabel, 'slds-form-element__label')}
							htmlFor={name}
						>
							{label}
						</label>
						<Select
							menuClassName={menuClassName}
							placeholder={placeholder || label}
							onChange={onChange}
							options={options}
							controlled
							{...field}
							style={style}
						/>
					</div>
				)}
			/>
		</>
	);
};

export default FilterSelect;
