import styles from '../ScrollableList/PrimaryScrollableList/PrimaryScrollableList.module.css';
import cx from 'classnames';
import React, { useState } from 'react';
import { SLDSDropdownOptionType } from 'types';
import DropdownCheckbox from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import { productStatuses } from 'constants/productStatuses';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { Button, Input, InputIcon } from '@salesforce/design-system-react';
import { Control } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { setIsSampleValues } from '../../redux/modules/products';
import OutlineButton from 'components/Buttons/OutlineButton';
import { useDebounce } from 'react-use';

type Props = {
	primaryButtonOnClick: any;
	primaryButtonTitle: string;
	listTitle: string;
	dropdownOptions: SLDSDropdownOptionType[];
	dropdownOnSelect: (value: any, search: any, statusItemChecked: any) => void;
	filterByStatuses: (
		value: any,
		search: any,
		categoriesItemChecked: any
	) => void;
	searchFieldOnChange: (
		value: any,
		statusItemChecked: any,
		categoriesItemChecked: any
	) => void;
	searchPlaceholder: string;
	clearFilter: (search: string) => void;
	filterControl?: Control<any>;
	hasPartnerListPermission?: boolean;
};

const PartnerScrollableList: React.FC<Props> = ({
	primaryButtonOnClick,
	primaryButtonTitle,
	listTitle,
	dropdownOptions,
	dropdownOnSelect: dropdownOnSelect,
	filterByStatuses,
	searchFieldOnChange,
	searchPlaceholder,
	children,
	clearFilter,
	filterControl,
	hasPartnerListPermission,
}) => {
	const [search, setSearch] = useState<string>('');

	const [categoriesItemChecked, setCategoriesItemChecked] = useState<
		Array<any>
	>([]);
	const [statusItemChecked, setStatusItemChecked] = useState<Array<any>>([]);

	const hasBadges = categoriesItemChecked.length && statusItemChecked.length;

	const resetSearch = () => {
		searchFieldOnChange('', statusItemChecked, categoriesItemChecked);
		setSearch('');
	};

	const clearDropdowns = () => {
		setCategoriesItemChecked([]);
		setStatusItemChecked([]);
	};

	const dispatch = useDispatch();

	// Fetch Partner list
	useDebounce(
		// Also triggered on load
		() => {
			if (search.length >= 3) {
				searchFieldOnChange(search, statusItemChecked, categoriesItemChecked);
				return;
			}
			if (search === '') {
				searchFieldOnChange(search, statusItemChecked, categoriesItemChecked);
			}
		},
		600,
		[search]
	);

	return (
		<div
			className={cx(
				'slds-col slds-small-size_5-of-12 slds-large-size_3-of-12',
				styles.listContainer
			)}
		>
			<div className="slds-grid slds-grid_align-spread">
				<div className={cx('slds-size_3-of-4', styles.partnerTitle)}>
					{listTitle}
				</div>
				<PrimaryButton
					className="slds-col slds-size_1-of-1"
					onClick={primaryButtonOnClick}
				>
					{primaryButtonTitle}
				</PrimaryButton>
				{process.env?.REACT_APP_ENV?.includes('dev') && (
					<OutlineButton
						onClick={() => {
							dispatch(setIsSampleValues(true));
							primaryButtonOnClick();
						}}
						className={styles.btn}
					>
						AT
					</OutlineButton>
				)}
			</div>
			<div className={styles.dropdownContainer}>
				<div
					style={{ display: 'flex', paddingTop: '0.65vh' }}
					className={styles.dropdownBorderless}
				>
					<DropdownCheckbox
						className={cx(hasBadges && styles.dropdownNoMargin)}
						placeholder="Categories"
						name="filter.category"
						options={dropdownOptions}
						control={filterControl}
						onChange={(v) =>
							dropdownOnSelect(v.join('|'), search, statusItemChecked)
						}
						referenceCheckedItems={categoriesItemChecked}
						setReferenceCheckedItems={setCategoriesItemChecked}
						hasBadgeCount
						isReturnAllOnChange
						disabled={!hasPartnerListPermission}
					/>
					<DropdownCheckbox
						placeholder="Status"
						name="filter.status"
						options={productStatuses}
						control={filterControl}
						onChange={(v) =>
							filterByStatuses(v.join('|'), search, categoriesItemChecked)
						}
						referenceCheckedItems={statusItemChecked}
						setReferenceCheckedItems={setStatusItemChecked}
						hasBadgeCount
						isReturnAllOnChange
						disabled={!hasPartnerListPermission}
					/>
					<Button
						style={{ marginLeft: 'auto', padding: hasBadges ? 0 : 'auto' }}
						onClick={() => {
							clearFilter(search);
							clearDropdowns();
						}}
						disabled={!hasPartnerListPermission}
					>
						<CloseIcon height="10px" width="10px" />
					</Button>
				</div>
			</div>
			<div style={{ marginTop: '12px' }}>
				<Input
					placeholder={searchPlaceholder}
					className={cx(styles.searchBar, styles.partnerSearch)}
					type="search"
					iconLeft={
						!search.length && (
							<InputIcon
								style={{
									height: '13.5px',
									width: '13.5px',
									transform: 'scale(1.5)',
								}}
								assistiveText={{
									icon: 'search',
								}}
								name="search"
								category="utility"
							/>
						)
					}
					iconRight={
						search.length && (
							<InputIcon
								assistiveText={{
									icon: 'Clear',
								}}
								name="clear"
								category="utility"
								onClick={resetSearch}
							/>
						)
					}
					value={search}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const val = e.target.value;
						setSearch(val);
					}}
					disabled={!hasPartnerListPermission}
				/>
			</div>
			<div className={styles.scrollableContainer}>{children}</div>
		</div>
	);
};

export default connect(() => ({}), {})(PartnerScrollableList);
