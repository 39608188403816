import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './Accordion.module.css';
import cx from 'classnames';
import ToggleButton from '../../ProductTypeContainer/ProductScrollableList/ToggleButton';
import { useHasUserPermission } from 'utils/permissions';
import IconResolver from 'helpers/IconResolver';
import { AccordionProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import client from 'helpers/ApiClient';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ic-warning.svg';

export const SnackBar = ({
	message,
	key,
}: {
	message: string;
	key: string;
}): JSX.Element => {
	return (
		<div className={cx(styles.customLoaderContainter)}>
			<div>
				<WarningIcon className={styles.warningIcon} height={14} width={14} />
			</div>

			<div>{message}</div>
			<div className={styles.closeBtnDiv}>
				<CloseIcon
					className={styles.alertCloseIcon}
					onClick={() => closeSnackbar(key)}
					height={12}
					width={12}
				/>
			</div>
		</div>
	);
};

const Accordion: React.FC<AccordionProps> = ({
	title,
	enabled = false,
	onExpandAction,
	product,
	partner,
	content,
	accordionKey,
	onClickBtn,
	onChangeBtn,
	channel,
	sectionData,
	isPartnerEnabled,
	hasViewEnableDisablePermission,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [partnerDetails, setPartnerDetails] = useState<any>([]);
	const hasUserPermission = useHasUserPermission('channels');
	const hasEditPermission = hasUserPermission('edit');

	const notifRef = useRef();

	useEffect(() => {
		if (channel) {
			setIsExpanded(false);
			setPartnerDetails([]);
		}
	}, [channel]);

	useEffect(() => {
		if (isExpanded && partner) {
			setPartnerDetails(partner);
		}
	}, [isExpanded, partner]);

	// const [partnerBranchAndTerminal, setPartnerBranchAndTerminal] = useState([]);
	const getPartnerBranchAndTerminal = useCallback(async () => {
		if (partner) {
			try {
				//set loading here
				if (hasViewEnableDisablePermission) {
					const response = await client.get(
						BASE_PATH + `${channel.id}/products/${partner.productId}`
					);
					setPartnerDetails((prevData) => ({
						...prevData,
						data: response.data.data,
					}));
				} else {
					setPartnerDetails((prevData) => ({
						...prevData,
						data: { branches: [{ id: 0, name: 'access denied' }] },
					}));
				}
			} catch (error) {
				console.error('Error fetching partners branch and terminal: ', error);
			}
		}
	}, [channel.id, partner.productId]);

	useEffect(() => {
		if (isExpanded && partnerDetails && partnerDetails.data) {
			sectionData(partnerDetails, partner.productId);
		}
	}, [isExpanded, partnerDetails, partner.productId, sectionData]);

	useEffect(() => {
		if (isExpanded) {
			getPartnerBranchAndTerminal();
		}
		// Cleanup function to reset partnerDetails
		return () => {
			setPartnerDetails(partner);
		};
	}, [isExpanded, getPartnerBranchAndTerminal]);

	const handleAccordionClick = () => {
		onExpandAction(accordionKey, isExpanded);
		setIsExpanded(!isExpanded);
	};

	const handleToggleOnClick = () => {
		// for product partner toggle
		if (!isPartnerEnabled)
			notifRef.current = enqueueSnackbar(
				<SnackBar
					message={`${product.name} is Disabled. Enable the product to edit the partner status.`}
					key={title}
				/>,
				{
					key: title,
					preventDuplicate: true,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center',
					},
				}
			) as never;
		onClickBtn(accordionKey);
	};

	const handleInputChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onChangeBtn(accordionKey, e);
	};
	return (
		<>
			<section
				key={`${channel.id}-${accordionKey}`}
				className={styles.accordionSection}
			>
				<button
					className={cx(styles.accordionButton, {
						[styles.expanded]: isExpanded,
					})}
					onClick={handleAccordionClick}
					aria-controls={`accordion-item-${channel.id}-${accordionKey}`}
					aria-expanded={isExpanded}
					role="switch"
				>
					<div className={styles.sectionLabel}>
						<span className="slds-current-color slds-col">
							<IconResolver
								className={cx('slds-icon_xx-small', 'slds-p-left_xx-small')}
								path={`utility/${isExpanded ? 'chevronup' : 'chevrondown'}`}
							/>
						</span>
						<strong>{title}</strong>
					</div>
				</button>
				<div className={cx('slds-clearfix', styles.toggleButton)}>
					<div className="slds-float_right">
						<ToggleButton
							isDisabled={hasEditPermission}
							isChecked={enabled}
							id={accordionKey}
							onClickBtn={handleToggleOnClick}
							onChangeBtn={handleInputChange}
						/>
					</div>
				</div>

				{isExpanded && (
					<div
						id={`accordion-item-${accordionKey}`}
						className={styles.accordionContent}
					>
						<div>{content}</div>
					</div>
				)}
			</section>
		</>
	);
};

export default Accordion;
