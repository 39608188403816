import React from 'react';
import { startCase } from 'lodash';

import ActionCell from './ActionCell';

import { ExpandableRowProps } from '../types';
import { BFA_STATUS_LABEL, BRANCH_ACCESS_STATUS } from '../constants';
import styles from './ChannelDetailsBody.module.css';

const ExpandableRow: React.FC<ExpandableRowProps> = ({
	tabName,
	data,
	channelId,
	branchId,
	branchName,
	toggleActiveValue,
	confirmModalContents,
	fromBranchAccessCtrl = false,
	refetchTable,
	width = 0,
}) => {
	const getToggleValue = (status: string) => {
		return startCase(status.toLowerCase()) === toggleActiveValue ? true : false;
	};

	const mappedData = () => {
		return (
			data &&
			data.map(
				({
					id,
					tpa,
					status,
					bfaStatus,
					api3status,
					isOperating,
					hasSchedule,
					schedules,
				}) => ({
					id,
					name: tpa,
					status: tabName === 'branchAccess' ? status : bfaStatus,
					tpaId: tpa,
					branchTpaidDetails: {
						branchName: branchName || '',
						isOperating,
						hasSchedule,
						schedules,
					},
					api3Status: api3status,
				})
			)
		);
	};

	const getStatusStyles = (status) => {
		if (status === 'ENABLED' || status === 'ACTIVE') {
			return styles.expandedStatusEnabled;
		} else if (status === 'DISABLED' || status === 'INACTIVE') {
			return styles.expandedStatusDisabled;
		} else if (status === 'ACTIVE_NO_SCHED') {
			return styles.expandedStatusActiveNoSched;
		} else if (status === 'CLOSED') {
			return styles.expandedStatusClosed;
		}
	};

	const getStatus = (status: string) => {
		if (fromBranchAccessCtrl) return BRANCH_ACCESS_STATUS[status.toUpperCase()];

		return startCase(BFA_STATUS_LABEL[status.toUpperCase()]);
	};

	return (
		<table
			className={styles.expandedSection}
			style={{
				position: 'relative',
				width: `${width - 2}px`,
			}}
		>
			<tbody>
				{mappedData() &&
					mappedData().map(
						({ id, name, status, api3Status, tpaId, branchTpaidDetails }) => (
							<tr className={styles.expandedSectionRow} key={id}>
								<td
									className={styles.expandedIdCell}
									data-label={name}
									key={id}
								>
									{name}
								</td>
								<td
									className={getStatusStyles(status.toUpperCase())}
									data-label={status}
									key={status}
								>
									{getStatus(status)}
								</td>
								<td
									className={styles.expandedActionCell}
									data-label={name}
									key={`action-${id}`}
								>
									<ActionCell
										name={`action-${id}`}
										initialToggleValue={
											fromBranchAccessCtrl
												? status !== 'ACTIVE_NO_SCHED'
												: getToggleValue(status)
										}
										channelId={channelId}
										branchId={branchId}
										branchName={branchName}
										tpaId={id}
										tpaName={tpaId}
										api3Status={api3Status}
										confirmModalContents={{
											...confirmModalContents,
											header: 'TPAID',
											bodyHeader: `${branchName} ${tpaId}`,
										}}
										fromBranchAccessCtrl={fromBranchAccessCtrl}
										scheduleType={status}
										branchTpaidDetails={{
											...branchTpaidDetails,
											tpaidName: name || '',
										}}
										refetchTable={refetchTable}
									/>
								</td>
							</tr>
						)
					)}
			</tbody>
		</table>
	);
};

export default ExpandableRow;
