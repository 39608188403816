import React, { useState } from 'react';

import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';

import PartnerBranchTpa from './PartnerBranchTpa';
import PartnerAuditTrail from './PartnerAuditTrail';

import { PartnerDetailsBodyProps } from '../types';
import styles from './PartnerDetailsBody.module.css';

const PRIMARY_TABS = (
	data,
	activeTab,
	refetchData,
	setRefetchData,
	fetchPartnerCounts
) => {
	return [
		{
			title: 'TPA Account/Branch/TPAID',
			component: activeTab === 0 && (
				<PartnerBranchTpa
					partnerId={data?.id}
					productId={data?.productId}
					refetchData={refetchData}
					setRefetchData={setRefetchData}
					fetchPartnerCounts={fetchPartnerCounts}
				/>
			),
		},
		{
			title: 'Audit Trail',
			component: activeTab === 1 && (
				<PartnerAuditTrail
					partnerId={data?.id}
					refetchData={refetchData}
					setRefetchData={setRefetchData}
				/>
			),
		},
	];
};

const PartnerDetailsBody: React.FC<PartnerDetailsBodyProps> = ({
	data,
	refetchData,
	setRefetchData,
	fetchPartnerCounts,
}) => {
	// used to ensure shown tab is the only tab that renders
	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<div className={styles.tabContainer}>
			<PrimaryTab
				tabs={PRIMARY_TABS(
					data,
					activeTab,
					refetchData,
					setRefetchData,
					fetchPartnerCounts
				)}
				onSelect={(index) => setActiveTab(index)}
				className={styles.tabPanel}
			/>
		</div>
	);
};
export default PartnerDetailsBody;
