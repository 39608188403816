import folderIcon from '../../assets/icons/ic-folder.svg';
import cx from 'classnames';
import styles from './PartnerList.module.css';

const PartnerTabPanelPlaceholder: React.FC = () => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>No Selected Partner</div>
				<div className={styles.placeholderSubTitle}>
					Please select a partner.
				</div>
			</div>
		</div>
	);
};

export default PartnerTabPanelPlaceholder;
