import {
	Control,
	useForm,
	UseFormClearErrors,
	UseFormSetValue,
} from 'react-hook-form';
import styles from './BillerUploadSettings.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import billerUploadSettingsSchema from 'utils/formSchemas/billerForm/billerUploadSetting';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	submittedBU,
	update as updateAC,
	updateBillerUpload as updateBU,
} from 'redux/modules/form';
import SFTP from './sections/SFTP/Sftp';
import SMTP from './sections/SMTP/Smtp';
import { billerUploadSettings } from './sections/SFTP/Sftp';
import { PrimaryInformationFormData } from '../PrimaryInformation/PrimaryInformation';

export type BillerUploadSettingsFormData = {
	billerUploadSettings?: billerUploadSettings;
};

export type BillerUploadSettingsSectionProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	initialValues: PrimaryInformationFormData;
	control: Control<any>;
};

type Props = {
	initialValues: BillerUploadSettingsFormData;
	disabled?: boolean;
	onSave?: (values: any) => void;
	onSubmit?: (values: any) => void;
	billerUploadSubmit?: (values: any) => void;
	showSuccess: () => void;
	showFail: () => void;
};

const BillerUploadSettings: React.FC<Props> = ({
	initialValues,
	disabled,
	onSubmit,
	onSave,
	showSuccess,
	showFail,
	billerUploadSubmit,
}) => {
	const {
		control,
		setValue,
		clearErrors,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		setError,
	} = useForm<BillerUploadSettingsFormData>({
		mode: 'all',
		defaultValues: initialValues,
		resolver: yupResolver(billerUploadSettingsSchema),
	});

	const dispatch = useDispatch();
	const submittingBU = useSelector<ReducerStateType>(
		(state) => state.form.status.submittingBU
	);

	function timeFormat12Hr(time: any) {
		const dt = new Date('1970-01-01 ' + time);
		let hours = dt.getHours();
		const AmOrPm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12 || 12;
		const minutes = dt.getMinutes();
		const finalTime =
			(hours < 10 ? '0' + hours : hours) + ':' + minutes + ' ' + AmOrPm;
		return finalTime;
	}

	function setToBillerUploadSettingsObj(val) {
		const saveValue = {
			billerUploadSFTP: {
				host: val.sftpInformation.host,
				username: val.sftpInformation.username,
				password: val.sftpInformation.password,
				path: val.sftpInformation.path,
				port: val.sftpInformation.port,
				csrConfig: {
					fileFormat: val.sftpInformation.csrConfigFileFormat,
					generationTime: timeFormat12Hr(
						val.sftpInformation.csrConfigGenerationTime
					),
					uploadSched: val.sftpInformation.csrConfigUploadSched,
					uploadFreq: val.sftpInformation.csrConfigUploadFreq,
					uploadTime: timeFormat12Hr(val.sftpInformation.csrConfigUploadTime),
				},
				uploadConfig: {
					fileFormat: val.sftpInformation.uploadConfigFileFormat,
					generationTime: timeFormat12Hr(
						val.sftpInformation.uploadConfigGenerationTime
					),
					uploadSched: val.sftpInformation.uploadConfigUploadSched,
					uploadFreq: val.sftpInformation.uploadConfigUploadFreq,
					uploadTime: timeFormat12Hr(
						val.sftpInformation.uploadConfigUploadTime
					),
				},
			},
			billerUploadSMTP: {
				senderEmails: val.smtpInformation.senderEmails,
				receiverEmails: val.smtpInformation.receiverEmails,
				csrConfig: {
					fileFormat: val.smtpInformation.csrConfigFileFormat,
					generationTime: timeFormat12Hr(
						val.smtpInformation.csrConfigGenerationTime
					),
					uploadSched: val.smtpInformation.csrConfigUploadSched,
					uploadFreq: val.smtpInformation.csrConfigUploadFreq,
					uploadTime: timeFormat12Hr(val.smtpInformation.csrConfigUploadTime),
				},
				uploadConfig: {
					fileFormat: val.smtpInformation.uploadConfigFileFormat,
					generationTime: timeFormat12Hr(
						val.smtpInformation.uploadConfigGenerationTime
					),
					uploadSched: val.smtpInformation.uploadConfigUploadSched,
					uploadFreq: val.smtpInformation.uploadConfigUploadFreq,
					uploadTime: timeFormat12Hr(
						val.smtpInformation.uploadConfigUploadTime
					),
				},
			},
		};

		return saveValue;
	}

	useEffect(() => {
		if (submittingBU) {
			handleSubmit(
				async (values) => {
					const val: any = values;

					if (onSave) {
						onSave(values);
						dispatch(updateBU(setToBillerUploadSettingsObj(val)));
						// showSuccess();
					} else {
						dispatch(updateBU(initialValues));
						reset(initialValues, { keepValues: true });
					}
				},
				(errors, e) => {
					dispatch(updateBU(initialValues));
					showFail();
					console.log(errors, e);
				}
			)();
			dispatch(submittedBU());
		}
	}, [dispatch, handleSubmit, onSubmit, submittingBU]);

	useEffect(() => {
		dispatch(updateAC({ isValid, isDirty }));
	}, [isValid, isDirty, dispatch]);

	return (
		<div className={styles.container}>
			<SFTP control={control} setValue={setValue} disabled={disabled} />

			<hr className={styles.divider} />

			<SMTP control={control} setValue={setValue} disabled={disabled} />
		</div>
	);
};

export default BillerUploadSettings;
