import styles from './Filter.module.css';
import { Control, Controller, Validate } from 'react-hook-form';
import ErrorComponent from './Error';
import SLDSDatepicker from 'components/Inputs/Datepicker/SLDSDatepicker';
import { useMemo, useRef } from 'react';

export function validateDateFrom(
	dateFrom: string | undefined,
	dateTo: string | undefined,
	clearError: () => void,
	message: string | undefined = 'Invalid date input'
): string | undefined {
	if (!dateFrom && dateTo) {
		return message;
	}

	if (dateTo && dateFrom) {
		return new Date(dateFrom) > new Date(dateTo) ? message : undefined;
	}
	clearError();
	return undefined;
}

export function validateDateTo(
	dateFrom: string | undefined,
	dateTo: string | undefined,
	clearError: () => void,
	message: string | undefined = 'Invalid date input'
): string | undefined {
	if (dateFrom && !dateTo) {
		return message;
	}

	if (dateFrom && dateTo) {
		return new Date(dateFrom) > new Date(dateTo) ? message : undefined;
	}
	clearError();
	return undefined;
}

type Props = {
	errors: any;
	name: string;
	label: string;
	control: Control<any>;
	validate?: any;
	placeholder?: string;
};

const DatePicker: React.FC<Props> = ({
	errors,
	label,
	name,
	control,
	validate,
	placeholder,
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);

	const menuStyle = useMemo(
		() => ({
			minWidth: dropdownRef.current?.clientWidth,
		}),
		[dropdownRef.current?.clientWidth]
	);
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={{ validate: (v) => validate && validate(v) }}
				render={({ field: { onChange, ...rest } }) => {
					return (
						<div ref={dropdownRef}>
							<SLDSDatepicker
								{...rest}
								placeholder={placeholder ? placeholder : label}
								className={styles.datePicker}
								label={label}
								controlled
								menuStyle={menuStyle}
								onBlur={(event) => {
									event.stopPropagation();
									onChange(event.currentTarget.value);
								}}
							/>
						</div>
					);
				}}
			/>
			<ErrorComponent name={name} errors={errors} />
		</>
	);
};

export default DatePicker;
