const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    zIndex: 3,
    height: 4,
    width: '80%',
    backgroundColor: "white",
    margin: 'auto'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '#f26122',
    borderRadius: 'inherit',
  }

  return (
    <>
      {completed < 100 && (
        <div style={containerStyles}>
          <div style={fillerStyles}>
          </div>
        </div>
      )}
    </>
  );
};

  export default ProgressBar;
