import Grid from 'components/Grid/Grid';
import { EmailTagInput } from 'components/TagInput/TagInputChannel';
import { SmtpProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/types';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useFormContext } from 'react-hook-form';
import styles from './style.module.css';

const EmailAddressFields: React.FC<SmtpProps> = ({
	disabled,
	disabledEmail,
}) => {
	const { control, setValue, getValues } =
		useFormContext<PartnerReportsFormData>();
	return (
		<div className={styles.emailAddressContainer}>
			<Grid container gutters>
				<Grid column size={1} of={2}>
					<EmailTagInput
						required
						control={control}
						name="smtp.sender"
						label="Sender Email Address"
						disabled={disabledEmail || disabled}
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<EmailTagInput
						required
						control={control}
						name="smtp.receivers"
						label="Receiver Email Address"
						disabled={disabled}
						onChange={(value) => {
							setValue(
								'smtp.receivers' as never,
								(value || getValues()?.smtp.receivers) as never,
								{
									shouldTouch: true,
								}
							);
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default EmailAddressFields;
