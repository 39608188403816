import Grid from 'components/Grid/Grid';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import Section, { SectionRow } from 'components/Section/Section';
import { Control, UseFormSetValue } from 'react-hook-form';
import styles from './AmountValidation.module.css';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
};

const AmountValidation: React.FC<Props> = ({ control, disabled, setValue }) => {
	const name = 'product_business_rule';
	return (
		<Section title="Amount Validation">
			<SectionRow>
				<Grid column container size={1} of={2}>
					<Section title="Cash" titleClassName={styles.sectionSubTitle}>
						<Grid container size={1} of={1}>
							<Grid column size={1} of={2}>
								<AmountField
									required
									label="Minimum Amount"
									placeholder={'0.00'}
									name={`${name}.min_amount_cash`}
									control={control}
									disabled={disabled}
									fixedTextRight="PHP"
									formatValue={TextFieldFormatter.amountFormat}
									isNoDashAllowed={true}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<AmountField
									required
									label="Maximum Amount"
									placeholder={'0.00'}
									name={`${name}.max_amount_cash`}
									control={control}
									disabled={disabled}
									fixedTextRight="PHP"
									formatValue={TextFieldFormatter.amountFormat}
									isNoDashAllowed={true}
								/>
							</Grid>
						</Grid>
					</Section>
				</Grid>
				<Grid column container size={1} of={2}>
					<Section title="Check" titleClassName={styles.sectionSubTitle}>
						<Grid container size={1} of={1}>
							<Grid column size={1} of={2}>
								<AmountField
									required
									label="Minimum Amount"
									placeholder={'0.00'}
									name={`${name}.min_amount_check`}
									control={control}
									disabled={disabled}
									fixedTextRight="PHP"
									formatValue={TextFieldFormatter.amountFormat}
									isNoDashAllowed={true}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<AmountField
									required
									label="Maximum Amount"
									placeholder={'0.00'}
									name={`${name}.max_amount_check`}
									control={control}
									disabled={disabled}
									fixedTextRight="PHP"
									formatValue={TextFieldFormatter.amountFormat}
									isNoDashAllowed={true}
								/>
							</Grid>
						</Grid>
					</Section>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default AmountValidation;
