import { common } from './common';
import * as yup from 'yup';
import { DateValue } from 'components/Inputs/Datepicker/SelectDatepicker';
import {
	transformCash,
	transformDateFormat,
	validateDateSchema,
} from 'utils/formSchemas/common';
import { noWhiteSpace } from 'utils/common';

export type product_dsa_bond = {
	bond_type?: string;
	other_bond_type?: string;
	bond_amount?: number;
	bond_premium_type?: string;
	bond_premium_rate?: number;
	bond_renewal?: string;
	prefunded_amount?: number;
	prefunded_threshold_amt?: number;
	execution_date?: string | DateValue;
	termination_date?: string | DateValue;
} & common;

export const product_dsa_bond_schema = yup.object().shape({
	product_type: yup.string().nullable().label('Product Type'),
	bond_type: yup
		.string()
		.nullable()
		.label('Bond Type')
		.required('Select Bond Type.'),
	other_bond_type: yup
		.string()
		.nullable()
		.label('Other Bond Type')
		.max(50, 'You can only input a max of 50 characters.')
		.when('bond_type', {
			is: 'OTHERS',
			then: (s) => s.required('Input Other Bond Type').test(noWhiteSpace()),
		}),
	bond_amount: yup
		.number()
		.nullable()
		.label('Bond Amount')
		.min(0.01, 'This field only accepts positive numeric values.')
		.required('Input Bond Amount.')
		.transform(transformCash),
	bond_premium_type: yup
		.string()
		.nullable()
		.label('Bond Premium Type')
		.required('Select Bond Premium Type.'),
	bond_premium_rate: yup
		.number()
		.nullable()
		.label('Bond Premium Rate')
		.when('bond_premium_type', {
			is: 'AMOUNT',
			then: (s) =>
				s
					.required('Input Bond Premium Rate.')
					.min(0.01, 'Value should be greater than 0.'),
		})
		.when('bond_premium_type', {
			is: 'PERCENTAGE',
			then: (s) =>
				s
					.required('Input Bond Premium Rate.')
					.min(0, 'Value should be from 0-100.')
					.max(100, 'Value should be from 0-100.'),
		})
		.transform(transformCash),
	bond_renewal: yup
		.string()
		.nullable()
		.label('Bond Renewal')
		.required('Select Bond Renewal.'),
	prefunded_amount: yup
		.number()
		.nullable()
		.optional()
		.label('Prefunded Amount')
		.min(0.01, 'This field only accepts positive numeric values.')
		.when('$currentSelectedProduct.name', {
			is: 'Buy Load',
			then: (s) => s.required(),
		})
		.transform(transformCash),
	prefunded_threshold_amt: yup
		.number()
		.nullable()
		.optional()
		.label('Prefunded Threshold Amount')
		.min(0.01, 'This field only accepts positive numeric values.')
		.when('$currentSelectedProduct.name', {
			is: 'Buy Load',
			then: (s) => s.required(),
		})
		.transform(transformCash),
	execution_date: yup
		.mixed()
		.nullable()
		.label('Execution Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required(),
	termination_date: yup
		.mixed()
		.nullable()
		.label('Termination Date')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required(),
});
