import { PropsWithChildren } from 'react';
import Checkbox from './Checkbox';
import styles from './Checkbox.module.css';
import cx from 'classnames';

type Props = PropsWithChildren<{
	className?: string;
	onSelectAll?: () => void;
	selectAllLabel?: string;
	showSelectAll?: boolean;
	disabled?: boolean;
	selectAll?: boolean;
}>;
const MultiCheckboxContainer: React.FC<Props> = ({
	className,
	children,
	onSelectAll = () => {},
	selectAll,
	selectAllLabel = 'Select All',
	showSelectAll = true,
	disabled,
}) => {
	return (
		<div className={cx(styles.checkboxContainer, className)}>
			{showSelectAll && (
				<div className={styles.selectAllContainer}>
					<Checkbox
						checked={selectAll}
						onChange={onSelectAll}
						label={selectAllLabel}
						disabled={disabled}
						className={disabled ? styles.disabledChkBox : ''}
					/>
				</div>
			)}
			<div className={styles.checklistBody}>{children}</div>
		</div>
	);
};

export default MultiCheckboxContainer;
