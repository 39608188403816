import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
	data: [],
	count: 0,
	filter: {
		dateFromAndTo: '',
		tpaid: '',
		accountNo: '',
		billerId: null,
		amount: '',
		status: 0,
		apiVersion: 3,
	},
	billers: {
		showAll: false,
		searchResult: [],
		search: 'search',
		isLoading: false,
		pagination: {
			page: 0,
			limit: 25,
			pageCount: 0,
			count: 0,
		},
	},
	pagination: {
		page: 1,
		limit: 25,
		pageCount: 0,
	},
	sorting: {
		sort_by: 'DESC',
		sort: 'created_at',
	},
	search: '',
	isWarningModalOpen: false,
	isLoading: false,
	isCountLoading: false,
};

const transaction = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		filterPrep: (state, payload) => {
			state.filter = { ...payload.newState };
			state.pagination.page = 1;
			state.isLoading = true;
			state.isCountLoading = true;
		},
		load: (state, payload) => {
			state.data = payload.result.data.map((data) => ({
				...data,
				created_at: moment(new Date(data.created_at)).format(
					'MM/DD/YYYY h:mm:ss a'
				),
			}));
			state.isLoading = false;
		},
		loadCount: (state, payload) => {
			state.pagination.pageCount = Math.ceil(
				payload.result.data.count / state.pagination.limit
			);
			state.count = payload.result.data.count;
			state.isCountLoading = false;
		},
		loadBillers: (state, payload) => {
			state.billers.searchResult = payload.result.data;
			state.billers.pagination.count = payload.result.count;
			state.billers.pagination.pageCount = Math.ceil(
				payload.result.count / state.billers.pagination.limit
			);
			state.isLoading = false;
			state.billers.isLoading = false;
			state.isCountLoading = false;
		},
		error: (state) => {
			console.log('Error');
			state.isLoading = false;
			state.isCountLoading = false;
			state.billers.isLoading = false;
		},
		paginationPrep: (state, payload) => {
			state.pagination.page = payload.newState.pageIndex;
			state.pagination.limit = payload.newState.limit;
			state.isLoading = true;
			state.isCountLoading = true;
		},
		pageCountPrep: (state, payload) => {
			state.pagination.limit = payload.newState.limit;
			state.pagination.page = 0;
			state.isCountLoading = true;
		},
		searchPrep: (state, payload) => {
			state.billers.search = payload.search;
			state.billers.isLoading = true;
		},
		searchPaginationPrep: (state, payload) => {
			state.billers.pagination.page = payload.page;
			state.isLoading = true;
			state.isCountLoading = true;
		},
		changeShowAll: (state, { payload }) => {
			state.billers.showAll = payload.show;
		},
		mainsearchPrep: (state, { newState }) => {
			state.search = newState.search;
			state.isLoading = true;
			state.isCountLoading = true;
		},
		sortPrep: (state, { newState }) => {
			state.sorting.sort = newState.column;
			state.sorting.sort_by = newState.sort;
			state.isLoading = true;
			state.isCountLoading = true;
		},
		toggleIsWarningModalOpen: (state, { payload }) => {
			state.isWarningModalOpen = payload;
		},
	},
});

const {
	filterPrep,
	paginationPrep,
	pageCountPrep,
	searchPrep,
	load,
	loadCount,
	loadBillers,
	error,
	changeShowAll,
	searchPaginationPrep,
	mainsearchPrep,
	sortPrep,
	toggleIsWarningModalOpen,
} = transaction.actions;

const getTransaction = (client, state) => {
	return client.get('/v1/transactions', {
		params: { ...prepareParams(state.transaction) },
	});
};

const getTransactionCount = (client, state) => {
	return client.get('v1/transactions/count', {
		params: { ...prepareParams(state.transaction) },
	});
};

function filterActionCreator(data, loadType) {
	return {
		types: [loadType, load.type, error.type],
		promise: getTransaction,
		newState: data,
	};
}

function filterActionCreatorCount(data, loadType) {
	return {
		types: [loadType, loadCount.type, error.type],
		promise: getTransactionCount,
		newState: data,
	};
}

const getBillers = (client, state) => {
	return client.get('/v1/billers', {
		params: {
			search: state.transaction.billers.search,
			page: state.transaction.billers.pagination.page + 1,
		},
	});
};

function searchBillersActionCreator(search) {
	return {
		types: [searchPrep.type, loadBillers.type, error.type],
		promise: getBillers,
		search,
	};
}

function billersPaginationActionCreator(page) {
	return {
		types: [searchPaginationPrep.type, loadBillers.type, error.type],
		promise: getBillers,
		page,
	};
}

function convertBillersToOptions(billers) {
	return billers.map((data) => ({ value: data.id, label: data.name }));
}

function handleFilterSubmit(formData, dropdown, submit) {
	const newData = {};
	if (formData.filter.dateFrom && formData.filter.dateTo) {
		newData.dateFromAndTo =
			moment(new Date(formData.filter.dateFrom)).format('YYYY/M/D') +
			'-' +
			moment(new Date(formData.filter.dateTo)).format('YYYY/M/D');
	}

	['tpaid', 'accountNo', 'billerId', 'amount', 'clientid'].forEach((data) => {
		if (formData.filter[data]) {
			newData[data] = formData.filter[data];
		}
	});
	newData.status = dropdown.status;
	newData.apiVersion = dropdown.apiVersion;

	submit(newData, filterPrep.type);
}

function handleSortBy(data, submit, submitCount) {
	submit(data, sortPrep.type);
	submitCount(data, sortPrep.type);
}

function handlePagination(data, submit, submitCount) {
	submit(data, paginationPrep.type);
	submitCount(data, paginationPrep.type);
}

function handleSearch(data, submit, submitCount) {
	submit(data, mainsearchPrep.type);
	submitCount(data, mainsearchPrep.type);
}

function handlePageCount(data, submit, submitCount) {
	submit(data, pageCountPrep.type);
	submitCount(data, pageCountPrep.type);
}

function prepareParams(state) {
	const transaction = {
		...state.filter,
		...state.pagination,
		...state.sorting,
		search: state.search,
	};

	const params = {};
	[
		'dateFromAndTo',
		'tpaid',
		'clientid',
		'accountNo',
		'billerId',
		'amount',
		'status',
		'apiVersion',
		'search',
		'sort',
		'sort_by',
		'limit',
		'page',
	].forEach((data) => {
		if (transaction[data] && transaction[data] !== '') {
			params[data] = transaction[data];
			if (data === 'status') {
				params[data] = params[data] - 1;
			} else if (data === 'billerId') {
				params[data] = params[data].value;
			}
		}
	});

	return params;
}

export default transaction.reducer;

export {
	handleFilterSubmit,
	handlePagination,
	handlePageCount,
	searchBillersActionCreator,
	filterActionCreator,
	filterActionCreatorCount,
	convertBillersToOptions,
	changeShowAll,
	billersPaginationActionCreator,
	handleSearch,
	handleSortBy,
	toggleIsWarningModalOpen,
};
