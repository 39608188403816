import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { Country, useCountryQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';

type CountrySelectProps = LocationSelectProps & {
	countryFilter?: (country: Country) => boolean;
	options?: SelectOption;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
	control,
	name,
	label = 'Country',
	disabled,
	defaultValue,
	value,
	countryFilter = () => true,
	options: opts,
	onChange,
	...rest
}) => {
	const { data } = useCountryQuery(
		'countries',
		{},
		{
			initialData: [{ id: '1', name: 'Philippines', country_code: 'PH' }],
		}
	);

	const options = useMemo(() => {
		if (opts) return opts;
		return (
			data?.filter(countryFilter).map(({ id: value, name: label }) => ({
				label,
				value,
			})) || []
		);
	}, [countryFilter, data, opts]);

	return (
		<>
			<SelectField
				label={label}
				control={control}
				name={name || `${name}.country_id`}
				required
				options={options}
				disabled={disabled}
				value={value}
				{...rest}
				defaultValue={defaultValue}
			/>
		</>
	);
};

export default CountrySelect;
