export interface IScope {
	id: number;
	scope: string;
	resource: string;
	method: string;
}

interface IPermission {
	scope: string;
	resource: string;
}

export default function verifyPermission(
	scopes: Array<IScope>,
	permission: IPermission
): boolean {
	return !!scopes.find(
		(obj) =>
			obj.scope === permission.scope && obj.resource === permission.resource
	);
}
