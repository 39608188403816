import React, {
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { LOADING_LIST_MSG } from 'constants/loading';

type Toggle = {
	value: boolean;
	valueOn: () => void;
	valueOff: () => void;
	toggleValue: () => void;
};
export const useToggle = (initialValue = false): Toggle => {
	const [value, setValue] = useState(initialValue);
	const valueOn = () => setValue(true);
	const valueOff = () => setValue(false);
	const toggleValue = () => setValue(!value);
	return {
		value,
		valueOn,
		valueOff,
		toggleValue,
	};
};

type UseStateInitialValue<T> = T | null | undefined;

type SingleSelection<T> = {
	value: UseStateInitialValue<T>;
	setValue: React.Dispatch<React.SetStateAction<UseStateInitialValue<T>>>;
	resetValue: () => void;
	clearValue: () => void;
};
type SingleSelectionArgs<T> = {
	initialValue?: any;
	valueOnClear?: UseStateInitialValue<T>;
};
export const useSingleSelection = <T>({
	initialValue = undefined,
	valueOnClear = undefined,
}: SingleSelectionArgs<T> = {}): SingleSelection<T> => {
	const [value, setValue] = useState<UseStateInitialValue<T>>(initialValue);
	return {
		value,
		setValue,
		resetValue: () => setValue(initialValue),
		clearValue: () => setValue(valueOnClear),
	};
};

interface DropdownProps {
	isJoin?: boolean;
	joinChar?: string;
	triggerFn?: (v: any) => any;
}

type DropdownType = {
	value: Array<any>;
	onChange: (v: any) => Array<any> | string;
};

export const useDropdownState = ({
	isJoin = false,
	joinChar = '|',
	triggerFn,
}: DropdownProps): DropdownType => {
	const [ddState, setDDstate] = useState<Array<any>>([]);

	return {
		value: ddState,
		onChange: (v?: string | number) => {
			let array: Array<string | number | undefined> = [];
			if (setDDstate.length > 0) {
				array = [...ddState];
				const index = array.indexOf(v);

				if (index !== -1) {
					array.splice(index, 1);
					setDDstate(array);
				} else {
					array.push(v);
					setDDstate(array);
				}
			} else {
				array.push(v);
				setDDstate(array);
			}
			const returnValue = isJoin ? array.join(joinChar) : array;

			triggerFn && triggerFn(returnValue);

			return isJoin ? array.join(joinChar) : array;
		},
	};
};

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export const useLoader = () => {
	const [loadingMessage, setLoadingMessage] = useState(LOADING_LIST_MSG);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const showLoadingMessage = useCallback(
		(message?: string) => {
			if (message) {
				setLoadingMessage(message);
			}
			showLoading();
		},
		[showLoading]
	);

	return {
		isLoading,
		loadingMessage,
		showLoadingMessage,
		hideLoading,
	};
};

export const useErrorModal = () => {
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState<{
		action: () => void;
	}>({ action: () => {} });

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const showErrorMessage = useCallback(
		(
			header: ReactNode,
			message: ReactNode,
			instruction?: ReactNode,
			onRetryBtnClick?: () => void
		) => {
			setErrorHeader(header);
			setErrorMessage(message);
			setErrorInstruction(instruction || 'Please try again.');
			showErrorModal();
			setRetryBtnOnClick({
				action: () => {
					onRetryBtnClick && onRetryBtnClick();
				},
			});
		},
		[]
	);

	return {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	};
};

export const useSuccessModal = () => {
	const [successMessage, setSuccessMessage] = useState<ReactNode | string>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState<{
		action: () => void;
	}>({ action: () => {} });

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const showSuccessMessage = useCallback(
		(message: ReactNode, onDoneBtnClick?: () => void) => {
			setSuccessMessage(message);
			showSuccessModal();
			setDoneBtnOnClick({
				action: () => {
					onDoneBtnClick && onDoneBtnClick();
				},
			});
		},
		[]
	);

	return {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
		doneBtnOnClick,
	};
};

export const useSnackbar = () => {
	const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
	const showSnackbar = (message: string, showLoader = false) => {
		enqueueSnackbar(message, {
			variant: 'snackbar',
			showLoader,
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'right',
			},
		});
	};
	return { showSnackbar, closeSnackbar };
};

export const useTablePagination = (onSubmit: (params: any) => void, meta) => {
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(25);
	const [sortBy, setSortBy] = useState<string>();
	const [sort, setSort] = useState<string>();
	const [filterParams, setFilterParams] = useState({});

	const onPageChange = async (_page, _pageSize) => {
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			const newParams = { ...filterParams, page: _page, limit: _pageSize };
			setFilterParams(newParams);
			await onSubmit(newParams);
		}
	};

	const onSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		setPage(1);
		const newParams = { ...filterParams, sortBy, sort, page: 1 };
		setFilterParams(newParams);
		await onSubmit(newParams);
	};

	useEffect(() => {
		if (meta?.page !== page) setPage(meta?.page);
	}, [meta]);

	return {
		page,
		pageSize,
		sort,
		sortBy,
		count: meta?.total || meta?.count || 0,
		onPageChange,
		onSort,
	};
};
