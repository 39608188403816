import { common } from './common';
import * as yup from 'yup';
import { YupMessage } from 'types';
import { validateLandlineNumber, validateMobile } from 'utils/validation';
import {
	defaultOnlyAlphaChar,
	yupValidateEmails,
} from 'utils/formSchemas/common';

export type product_officer = {
	name?: string;
	email?: string[];
	tel_no?: string[];
	mobile_no?: string[];
} & common;

export const product_officer_schema = yup.object().shape({
	name: yup
		.string()
		.nullable()
		.label('Name')
		.max(255, 'You can only input a max of 255 alphanumeric characters.')
		.required('Input Name')
		.matches(/^(?!\s+$).*/, {
			message: `Input Name`,
		})
		.matches(/^[a-z|A-Z|0-9\s]{1,255}$/, {
			excludeEmptyString: false,
			message: defaultOnlyAlphaChar,
		}),
	email: yup
		.array()
		.nullable()
		.of(yup.string().nullable())
		.ensure()
		.label('Email Address')
		.min(1, ({ label }) => `Input ${label}`)
		.test('isValidEmail', yupValidateEmails),
	tel_no: yup
		.array()
		.nullable()
		.of(yup.string().nullable())
		.optional()
		.label('Telephone Number')
		.test(
			'isValidLandlineNumber',
			({ value }: YupMessage): string => {
				const count = value?.filter(
					(a: string) => !validateLandlineNumber(a || '')
				).length;

				const totalLength = value
					? value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
					  )
					: 0;

				if (totalLength >= 255) {
					return 'You can only input a max of 255 characters.';
				}
				if (count >= 1) {
					return `${count} ${
						count > 1 ? 'inputs are' : 'input is'
					} invalid telephone number format`;
				}
				return '';
			},
			(v: string[] | undefined): boolean => {
				if (!v) return true;
				return v.every((a) => validateLandlineNumber(a || '', v.length));
			}
		),
	mobile_no: yup
		.array()
		.nullable()
		.of(yup.string().nullable())
		.ensure()
		.label('Mobile Number')
		.min(1, ({ label }) => `Input ${label}`)
		.test(
			'isValidMobileNumber',
			({ value }: YupMessage): string => {
				const count = value?.filter(
					(a: string) => !validateMobile(a || '')
				).length;

				const totalLength = value
					? value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
					  )
					: 0;

				if (totalLength >= 255) {
					return 'You can only input a max of 255 characters.';
				}
				if (!count) return '';

				return 'Mobile number should be in +639XXXXXXXXX format.';
			},
			(v: string[] | any): boolean => {
				if (!v) return true;
				return v.every((a) => validateMobile(a || '', v.length));
			}
		),
});
