import { formatDate } from 'utils/common';
import { Column, ErrorMessage } from './types';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import styles from './styles.module.css';
import cx from 'classnames';

export const TABLE_COLUMNS: Column[] = [
	{
		Header: '',
		id: 'checked',
		width: '4%',
	},
	{
		Header: 'Date & Time Created',
		id: 'createdAt',
		width: '14%',
		sortable: true,
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Submitted by',
		id: 'submittedBy',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Channel Account Name',
		id: 'name',
		width: '14%',
		sortable: true,
	},
	{
		Header: 'Date Updated',
		id: 'updatedAt',
		width: '14%',
		sortable: true,
		accessor: ({ updatedAt }) => formatDate(updatedAt),
	},
	{
		Header: 'Updated by',
		id: 'updatedBy',
		width: '12%',
		sortable: true,
	},
	{
		Header: 'Status',
		id: 'status',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		width: '12%',
		sortable: false,
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
	},
];

export const APPROVAL_LIST_FILTER = {
	search: '',
	createdAt: '',
	submittedBy: '',
	updatedAt: '',
	channelName: '',
	productType: '',
	partner: '',
	status: '',
};

export const TABLE_FILTER = {
	limit: 25,
	page: 1,
	sort: 'desc',
	sortBy: 'createdAt',
};

export const LOADING_MESSAGE =
	"Please wait while the selected request's details is being loaded";
export const MAX_SELECTED_ROW_MESSAGE: ErrorMessage = {
	message: 'Only max of 10 request can be processed at a time.',
	header: '10',
	isCommonError: false,
};
export const SERVICE_FEE_TABLE_COLUMNS: Column[] = [
	{
		Header: '',
		id: 'checked',
		width: '4%',
	},
	{
		Header: 'Date & Time Created',
		id: 'createdAt',
		width: '10%',
		sortable: true,
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Submitted by',
		id: 'submittedBy',
		width: '8%',
		sortable: true,
	},
	{
		Header: 'Channel Account Name',
		id: 'channelAccountName',
		width: '10%',
		sortable: true,
		minWidth: '8%',
		maxWidth: '14%',
	},
	{
		Header: 'Product Type',
		id: 'productType',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Partner',
		id: 'partner',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Date Updated',
		id: 'updatedAt',
		width: '10%',
		sortable: true,
		accessor: ({ updatedAt }) => formatDate(updatedAt),
	},
	{
		Header: 'Updated by',
		id: 'updatedBy',
		width: '8%',
		sortable: true,
	},
	{
		Header: 'Status',
		id: 'status',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		width: '10%',
		sortable: false,
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
	},
];

export const STATUS_OPTIONS: SelectOption[] = [
	{ label: 'For Review', value: 'FOR_REVIEW' },
	// { label: 'Approved', value: 'APPROVED' },
	{
		label: 'Approved for Integration',
		value: 'APPROVED_FOR_INTEGRATION',
		className: cx(styles.statusField),
	},
	{ label: 'Rejected', value: 'REJECTED' },
];

export const STATUS_OPTIONS_SERVICEFEE: SelectOption[] = [
	{ label: 'For Review', value: 'FOR_REVIEW' },
	{ label: 'Approved', value: 'APPROVED' },
	{ label: 'Rejected', value: 'REJECTED' },
];

export const DEFAULT_OPTIONS: SelectOption[] = [{ label: '', value: '' }];
