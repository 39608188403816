import {
    Tabs,
    TabsPanel,
} from '@salesforce/design-system-react/module/components';
import { useEffect } from 'react';

import cx from 'classnames';
import styles from './index.module.css';

const ReportModalTab: React.FC<any> = ({
    contentClassName = '',
    onChangeTab,
    tabs,
}) => {
    useEffect(() => {
        if (onChangeTab instanceof Function) {
            onChangeTab(0);
        }
    }, []);

    return (
        <Tabs
            className={styles.tabs}
            onSelect={tab => {
                if (onChangeTab instanceof Function) {
                    onChangeTab(tab);
                }
            }}
        >
            {
                tabs?.map((tab, key) => (
                    <TabsPanel
                        label={tab.title}
                        key={`brg-key-${key + 1}`}
                    >
                        <div className={cx(styles.content, contentClassName)} >
                            {tab?.content}
                        </div>
                    </TabsPanel>
                ))
            }
        </Tabs>
    );
};

export default ReportModalTab;