import React, { useEffect } from 'react';
import industries from '../../constants/industries';
import { getBillers, selectBiller } from '../../redux/modules/billerList';
import { connect } from 'react-redux';
import PrimaryScrollableList from '../ScrollableList/PrimaryScrollableList/PrimaryScrollableList';
import PrimaryCard from '../Cards/PrimaryCard/PrimaryCard';

type Props = {
	addNewBillerButtonClick: () => void;
	billers: any;
	getBillers: any;
	selectBiller: any;
};

const BillerList: React.FC<Props> = ({
	addNewBillerButtonClick,
	billers,
	getBillers: load,
	selectBiller: onSelectBiller,
}) => {
	const scrollableListPorps = {
		primaryButtonOnClick: addNewBillerButtonClick,
		primaryButtonTitle: 'Add New Biller',
		listTitle: 'Biller List',
		dropdownOptions: industries,
		dropdownPlaceholder: 'Industry',
		dropdownOnSelect: (value: any) => {
			load({ category: value });
		},
		dropdownOnClick: () => {
			load({ category: '' });
		},
		searchFieldOnChange: (value: any) => {
			load({ search: value });
		},
		searchPlaceholder: 'Search Biller Name',
	};

	useEffect(() => {
		load();
	}, []);

	return (
		<PrimaryScrollableList {...scrollableListPorps}>
			{billers.map((biller: any, index: number) => (
				<PrimaryCard
					key={index}
					name={biller.name}
					logo={biller.logo}
					status={biller.billerStatus}
					isSelected={biller.isSelected}
					onClick={() => {
						onSelectBiller({ selected: biller.shortName });
					}}
				/>
			))}
		</PrimaryScrollableList>
	);
};

export default connect(
	(state: any) => ({
		billers: state.billers.billers,
	}),
	{ getBillers, selectBiller }
)(BillerList);
