export const product_accreditations = [
  {
    label: 'Accomplished CBCI AML Questionnaire',
    name: 'cbci_aml_question',
    required: false,
  },
  {
    label: 'Annual Income Tax Return (2 years)',
    name: 'annual_income_tax',
    required: false,
  },
  {
    label:
      'Audited Financial Statements (previous 2 years with Auditor’s Opinion & Notes to Financial Statements)',
    name: 'audited_financial_stmt',
    required: false,
  },
  {
    label: 'BIR Certificate of Registration Form No. 2303',
    name: 'bir_certificate_form',
    required: false,
  },
  {
    label:
      'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
    name: 'corp_secretary_cert',
    required: false,
  },
  {
    label: 'Business Permit/Mayor’s Permit (updated/ current year)',
    name: 'business_permit',
    required: false,
  },
  { label: 'Company Profile', name: 'company_profile', required: false },
  {
    label: 'Government Issued ID of the authorized signatory',
    name: 'govt_id',
    required: false,
  },
  {
    label: 'Latest General Information Sheet (GIS)',
    name: 'gen_info_sheet',
    required: false,
  },
  {
    label: 'Non-disclosure Agreement (NDA)',
    name: 'non_disclosure',
    required: false,
  },
  {
    label: 'SEC Registration Certificate',
    name: 'sec_reg_cert',
    required: false,
  },
  {
    label:
      'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
    name: 'sec_article_inc',
    required: false,
  },
  {
    label:
      'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
    name: 'secondary_license',
    required: false,
  },
  { label: 'First Page of Passbook', name: 'passbook', required: false },
  { label: 'Data Privacy: PIA', name: 'data_privacy_pia', required: false },
  { label: 'Data Privacy: PAT', name: 'data_privacy_pat', required: false },
  {
    label: 'Third-Party Security Checklist',
    name: 'third_party_security_check',
    required: false,
  },
];