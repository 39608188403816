import ErrorModal, {
	ErrorModalBody,
	ErrorModalActions,
} from 'components/Modal/ErrorModal';
import { TABLE_VIEW_REPORT_COLUMNS } from 'components/Reports/constants/view-report.table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState, ReactNode } from 'react';
import { useToggle } from 'utils/hooks';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import folderIcon from 'assets/icons/ic-folder.svg';
import Table from 'components/CWSTable/Table';
import classes from './index.module.css';
import styles from './index.module.css';
import cx from 'classnames';

const NoReportAvailableSection: React.FC = () => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>
					This file is too big to preview. Kindly download the file to view its
					contents.
				</div>
			</div>
		</div>
	);
};

const columnMap = (column) => {
	return {
		Header: column,
		id: column,
		width:
			column?.trim() === 'TOTAL CASH COLLECTION - TOTAL REMITTANCE CASH'
				? '37.5%'
				: '22.3%',
		Cell: ({ value }): JSX.Element => <div>{value !== '' ? value : '-'}</div>,
	};
};

const secondColumnMap = (column) => {
	return {
		Header: column,
		id: column,
		width: '35%',
		Cell: ({ value }): JSX.Element => <div>{value !== '' ? value : '-'}</div>,
	};
};

export default function ViewReport(): JSX.Element {
	const viewReport = useSelector(
		(states: RootStateOrAny) => states.reportViewer.view_report
	);
	const reportsData = useSelector((states: RootStateOrAny) => states.reports);
	const { selectedReportSize } = reportsData;
	const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');

	const hasGreaterReportSize = selectedReportSize >= 3145728;
	const secondTable = viewReport.has_second_sheet;

	const visibleData = viewReport.data.slice(0, 30);
	const summary = viewReport.summary_headers;

	const TABLE_COLUMNS =
		viewReport.columns?.length > 0
			? viewReport.columns.map(columnMap)
			: TABLE_VIEW_REPORT_COLUMNS;

	const SECOND_TABLE_COLUMNS =
		viewReport.second_columns?.length > 0
			? viewReport.second_columns.map(secondColumnMap)
			: TABLE_VIEW_REPORT_COLUMNS;

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();


	const handleSpielErrorPreviewReport = (
		header: any,
		message: string | ReactNode
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		showErrorModal();
	};

	useEffect(() => {
		if (hasGreaterReportSize) {
			handleSpielErrorPreviewReport(
				'Error!',
				<div>
					This file is too big to preview. Kindly download <br />
					the file to view its contents.
				</div>
			);
		}
	}, [selectedReportSize]);

	return (
		<>
			{hasGreaterReportSize ? (
				<NoReportAvailableSection />
			) : (
				<div id="container" className={cx(classes.container)}>
					{summary.map((d, index) => {
						if (index == 0) {
							return <strong>{d.text}</strong>;
						} else if (index == 1) {
							return (
								<strong>
									<br />
									{d.text}
								</strong>
							);
						}

						return (
							<div key={`view-${index}`}>
								{d.text}&nbsp;
								<strong>{d.value}</strong>
							</div>
						);
					})}

					<Table
						data={visibleData}
						scrollable={false}
						showPagination={false}
						isReportGeneration={true}
						modalIsOpen={false}
						columns={TABLE_COLUMNS}
					/>
					{
						secondTable && <Table
							data={viewReport.data2}
							scrollable={false}
							showPagination={false}
							isReportGeneration={true}
							modalIsOpen={false}
							columns={SECOND_TABLE_COLUMNS}
						/>
					}

				</div>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => hideErrorModal()}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</>
	);
}
