import { ReactComponent as BillsPaymentIcon } from 'assets/icons/ic-billsPay.svg';
import { ReactComponent as BuyLoadIcon } from 'assets/icons/ic-buyLoad.svg';
import { ReactComponent as RemittanceIcon } from 'assets/icons/ic-remittance.svg';
import { ReactComponent as CashOutIcon } from 'assets/icons/ic-cashOut.svg';
import { ReactComponent as TopUpIcon } from 'assets/icons/ic-topUp.svg';
import { ReactComponent as CtplIcon } from 'assets/icons/ic-cptl.svg';
import { ReactComponent as InstasuranceIcon } from 'assets/icons/ic-instasurance.svg';
import { ReactComponent as BayadOutIcon } from 'assets/icons/ic-bayadOut.svg';
import { ReactComponent as OtcIcon } from 'assets/icons/ic-otc.svg';
import { ReactComponent as BayadAtmIcon } from 'assets/icons/ic-bayAtm.svg';
import { ReactComponent as MedAssistIcon } from 'assets/icons/ic-medAssist.svg';
import { ReactComponent as TravelIcon } from 'assets/icons/ic-travel.svg';
import { ReactComponent as BillsPaymentOffIcon } from 'assets/icons/ic-billsPayOff.svg';
import { ReactComponent as BuyLoadOffIcon } from 'assets/icons/ic-buyLoadOff.svg';
import { ReactComponent as RemittanceOffIcon } from 'assets/icons/ic-remittanceOff.svg';
import { ReactComponent as CashOutOffIcon } from 'assets/icons/ic-CashOutOff.svg';
import { ReactComponent as TopUpOffIcon } from 'assets/icons/ic-topUpOff.svg';
import { ReactComponent as CtplOffIcon } from 'assets/icons/ic-cptlOff.svg';
import { ReactComponent as InstasuranceOffIcon } from 'assets/icons/ic-instasuranceOff.svg';
import { ReactComponent as BayadOutOffIcon } from 'assets/icons/ic-bayadOutOff.svg';
import { ReactComponent as OtcOffIcon } from 'assets/icons/ic-otcOff.svg';
import { ReactComponent as BayadAtmOffIcon } from 'assets/icons/ic-bayAtmOff.svg';
import { ReactComponent as MedAssistOffIcon } from 'assets/icons/ic-medAssistOff.svg';
import { ReactComponent as TravelOffIcon } from 'assets/icons/ic-travelOff.svg';

export const PRODUCT_LIST = [
	{
		name: 'Bills Payment',
		code: 'BPY',
		cardTitle: 'Bills Payment',
		iconData: {
			icon: <BillsPaymentIcon />,
			iconOff: <BillsPaymentOffIcon />,
			color: '#f26222',
		},
	},
	{
		name: 'Buy Load',
		code: 'BUY_LOAD',
		cardTitle: 'Buy Load',
		iconData: {
			icon: <BuyLoadIcon />,
			iconOff: <BuyLoadOffIcon />,
			color: '#0076bf',
		},
	},
	{
		name: 'Remittance',
		code: 'REMITTANCE',
		cardTitle: 'Remittance',
		iconData: {
			icon: <RemittanceIcon />,
			iconOff: <RemittanceOffIcon />,
			color: '#cccf19',
		},
	},
	{
		name: 'Cash-out / Pay-out',
		code: 'CASH_OUT',
		cardTitle: 'Cash-Out/Pay-Out',
		iconData: {
			icon: <CashOutIcon />,
			iconOff: <CashOutOffIcon />,
			color: '#52bd8c',
		},
	},
	{
		name: 'Cash-in / Top-up',
		code: 'CASH_IN',
		cardTitle: (
			<span>
				Cash-In/
				<br />
				Top-Up
			</span>
		),
		iconData: {
			icon: <TopUpIcon />,
			iconOff: <TopUpOffIcon />,
			color: '#ce1952',
		},
	},
	{
		name: 'e-CTPL',
		code: 'E_CTPL',
		cardTitle: 'e-CTPL',
		iconData: {
			icon: <CtplIcon />,
			iconOff: <CtplOffIcon />,
			color: '#f26222',
		},
	},
	{
		name: 'Instasurance',
		code: 'INSTASURANCE',
		cardTitle: 'Instasurance',
		iconData: {
			icon: <InstasuranceIcon />,
			iconOff: <InstasuranceOffIcon />,
			color: '#0076bf',
		},
	},
	{
		name: 'Bayad Checkout',
		code: 'BAYAD_CHECKOUT',
		cardTitle: 'Bayad Checkout',
		iconData: {
			icon: <BayadOutIcon />,
			iconOff: <BayadOutOffIcon />,
			color: '#cccf19',
		},
	},
	{
		name: 'Bayad Checkout (via OTC)',
		code: 'BAYAD_CHECKOUT_OTC',
		cardTitle: (
			<span>
				Bayad Checkout
				<br />
				(via OTC)
			</span>
		),
		iconData: {
			icon: <OtcIcon />,
			iconOff: <OtcOffIcon />,
			color: '#52bd8c',
		},
	},
	{
		name: 'Bayad ATM',
		code: 'BAYAD_ATM',
		cardTitle: 'Bayad ATM',
		iconData: {
			icon: <BayadAtmIcon />,
			iconOff: <BayadAtmOffIcon />,
			color: '#ce1952',
		},
	},
	{
		name: 'Bayad Med-assist',
		code: 'BAYAD_MED',
		cardTitle: 'Bayad Med-assist',
		iconData: {
			icon: <MedAssistIcon />,
			iconOff: <MedAssistOffIcon />,
			color: '#f26222',
		},
	},
	{
		name: 'Bayad Travel',
		code: 'BAYAD_TRAVEL',
		cardTitle: 'Bayad Travel',
		iconData: {
			icon: <TravelIcon />,
			iconOff: <TravelOffIcon />,
			color: '#0076bf',
		},
	},
];

export const PRODUCT_CODE = {
	'Bills Payment': 'BPY',
	'Buy Load': 'BUY_LOAD',
	Remittance: 'REMITTANCE',
	'Cash-Out/Pay-Out': 'CASH_OUT',
	'Cash-in / Top-up': 'CASH_IN',
	'e-CTPL': 'E_CPTL',
	Instasurance: 'INSTASURANCE',
	'Bayad Checkout': 'BAYAD_CHECKOUT',
	'Bayad Checkout (via OTC)': 'BAYAD_CHECKOUT_OTC',
	'Bayad ATM': 'BAYAD_ATM',
	'Bayad Med-assist': 'BAYAD_MED',
	'Bayad Travel': 'BAYAD_TRAVEL',
};
