import cx from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';
import styles from 'containers/ChannelManagement/Approval/styles.module.css';
import {
	DEFAULT_OPTIONS,
	STATUS_OPTIONS,
	STATUS_OPTIONS_SERVICEFEE,
} from './constants';
import {
	ChannelTableRowData,
	OptionValues,
	ServiceFeeApprovalRowData,
} from './types';

type Props = {
	control;
	reset;
	name: string;
	filter;
	optionValues: OptionValues;
};

export const getUniqueValues = (
	array: ChannelTableRowData[] | ServiceFeeApprovalRowData[],
	key: any
): SelectOption[] => {
	const arrayCreated = array
		.map((element) => element[key])
		.filter((value, index, array) => array.indexOf(value) === index)
		.filter((element) => element);
	return arrayCreated.map((element) => ({ label: element, value: element }));
};

const ApprovalTabFilter: React.FC<Props> = ({
	control,
	name,
	reset,
	filter,
	optionValues = {
		optionsChannelAccount: [],
		optionsPartner: [],
		optionsProductType: [],
		optionsSubmittedBy: [],
	},
}) => {
	const disableSubmission = () => {
		return false;
	};

	return (
		<>
			<Filter title="">
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={11}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={5} of={12}>
								<InputWithIcon
									name={`${name}.search`}
									control={control}
									placeholder="Search"
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid container gutters={'xx-small'} verticalAlign="end">
						<Grid column size={2} of={12}>
							<DatePickerField
								control={control}
								name={`${name}.createdAt`}
								label={'Date Created'}
								placeholder="MM/DD/YYYY"
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<FilterSelect
								label="Submitted By"
								control={control}
								name={`${name}.submittedBy`}
								options={optionValues.optionsSubmittedBy || DEFAULT_OPTIONS}
							/>
						</Grid>

						<Grid column size={2} of={12}>
							<DatePickerField
								control={control}
								name={`${name}.updatedAt`}
								label={'Date Updated'}
								placeholder="MM/DD/YYYY"
							/>
						</Grid>
						<Grid column size={3} of={12}>
							<FilterSelect
								label="Channel Account Name"
								control={control}
								name={`${name}.channelName`}
								options={optionValues.optionsChannelAccount || DEFAULT_OPTIONS}
							/>
						</Grid>
						{name === 'serviceFeeTabFilter' ? (
							<>
								<Grid column size={2} of={12}>
									<FilterSelect
										label="Product type"
										control={control}
										name={`${name}.productType`}
										options={optionValues.optionsProductType || DEFAULT_OPTIONS}
									/>
								</Grid>
								<Grid column size={2} of={12}>
									<FilterSelect
										label="Partner"
										control={control}
										name={`${name}.partner`}
										options={optionValues.optionsPartner || DEFAULT_OPTIONS}
									/>
								</Grid>
							</>
						) : null}
						<Grid
							column
							size={1}
							of={12}
							className={styles.selectFieldAdjusted}
						>
							<FilterSelect
								label="Status"
								control={control}
								name={`${name}.status`}
								options={
									name == 'channelTabFilter'
										? STATUS_OPTIONS
										: STATUS_OPTIONS_SERVICEFEE
								}
							/>
						</Grid>
						<Grid
							container
							size={4}
							of={12}
							className={cx('slds-p-around_xxx-small')}
						>
							<Grid column size={2} of={4}>
								<OutlineButton
									fullWidth
									onClick={() => {
										reset();
										filter();
									}}
								>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={2} of={4}>
								<PrimaryButton
									fullWidth
									disabled={disableSubmission()}
									onClick={filter}
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
		</>
	);
};

export default ApprovalTabFilter;
