import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { useCitiesQueries, useCityQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';
import { Controller } from 'react-hook-form';

type AreaHandledSelectProps = LocationSelectProps & {
	provinceIds?: string[];
};

const AreaHandledSelect: React.FC<AreaHandledSelectProps> = ({
	control,
	name,
	label = 'Area Handled',
	provinceIds,
    cities,
	disabled,
	onChange,
	defaultValue,
	setValue,
	...rest
}) => {

	const options = useMemo(() => {
		return cities;
	}, [cities]);

    if(name && control) {
        return (
            <Controller
                name={`${name}`}
                control={control}
                render={({ field }) => {
                    return <SelectField
                        label={label}
                        control={control}
                        name={`${name}`}
                        // isLoading={!options.length}
                        options={options}
                        disabled={disabled}
                        value={field.value || defaultValue}
                        defaultValue={defaultValue}
                        {...rest}
                    />
                }
               }
             />
        );
    }
    return <></>
};

export default AreaHandledSelect;
