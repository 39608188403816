import yup, {
	multipleContactNumberSchema,
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
	websiteSchema,
} from '../common';

const basicInformationSchema = yup.object().shape({
	merchantId: yup
		.string()
		.label('Merchant ID')
		.required()
		.max(5)
		.matches(/^[a-zA-Z0-9_]{1,5}$/, {
			excludeEmptyString: true,
			message: `You can only input a max of 5 characters.`,
		}),
	billerTradeName: yup.string().label('Biller Trade Name').required().max(255),
	merchantTradeName: yup
		.string()
		.label('Merchant Trade Name')
		.required()
		.max(255),
	billerShortName: yup.string().label('Biller Short Name').required().max(100),
	billerCode: yup
		.string()
		.label('Biller Code')
		.required()
		.test(
			'isValidBillerCode',
			`You can only input a max of 5 numeric characters.`,
			(value) => {
				return yup.number().max(99999).isValid(value);
			}
		),
	subBrands: yup
		.string()
		.label('Sub-Brands')
		.matches(/^[a-zA-Z0-9_ ]{1,50}$/, {
			excludeEmptyString: true,
			message: `You can only input a max of 50 alphanumeric characters.`,
		}),

	tin: yup
		.string()
		.label('TIN')
		.required()
		.matches(/^[0-9]{12}$/, {
			excludeEmptyString: true,
			message: 'Input 12 numeric character only.',
		}),
	placeOfIncorporation: yup
		.string()
		.label('Place of Incorporation')
		.required()
		.max(1000),
	website: websiteSchema.label('Website Address'),
	industry: yup.string().label('Industry').required('Select Industry.'),
	dateOfIncorporation: yup
		.mixed()
		.nullable()
		.label('Date of Incorporation')
		.transform((v) => {
			if (!v) return undefined;

			const { month, day, year } = v;
			const out = [month, day, year].every((v) => !!v)
				? `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
				: undefined;
			return out;
		})
		.required(),
	otherIndustry: yup
		.string()
		.label('Other Industry')
		.max(50)
		.when('industry', {
			is: (industry?: string) => {
				return industry && industry.toLowerCase() === 'others';
			},
			then: yup.string().required(),
		}),
	legalStructure: yup
		.string()
		.label('Legal Structure')
		.required('Select Legal Structure.'),
	otherLegalStructure: yup
		.string()
		.label('Other Legal Structure')
		.max(50)
		.when('legalStructure', {
			is: (legalStructure?: string) => {
				return legalStructure && legalStructure.toLowerCase() === 'others';
			},
			then: yup.string().required(),
		}),
	consumerBaseCount: yup
		.string()
		.label('Consumer Base Count')
		.required()
		.transform((curr) => curr.replaceAll(',', ''))
		.matches(/^[0-9]+$/, {
			excludeEmptyString: true,
			message: 'You can only input numeric characters.',
		}),
});

const billerAddressEntrySchema = yup.object().shape({
	locationBase: yup.string().oneOf(['LOCAL', 'INTERNATIONAL']),
	country: yup
		.string()
		.label('Country')
		.when('locationBase', {
			is: 'INTERNATIONAL',
			then: (s) =>
				s
					.transform((v) => v || undefined)
					.required(selectDefaultRequiredTemplate),
		}),
	province: yup
		.string()
		.label('Province')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required('Select State/Province.'),
		}),
	city: yup
		.string()
		.label('Municipality/City')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	barangay: yup
		.string()
		.label('Barangay')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	category: yup
		.string()
		.label('Address Category')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required('Select an Address Category.'),
		}),
	street: yup
		.string()
		.label('Street')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) =>
				s.required().max(255, 'You have reached maximum character input.'),
		}),
	buildingNo: yup
		.string()
		.label('Building Name/No.')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) =>
				s.required().max(255, 'You have reached maximum character input.'),
		}),
});

const billerAddressSchema = yup.array().of(billerAddressEntrySchema);

const contactDetailsMaxStringSchema = yup
	.string()
	.max(255, 'You can only input a max of 255 characters.');

const billerContactDetailsEntrySchema = yup.object().shape({
	name: contactDetailsMaxStringSchema
		.label('Name')
		.required('Input Biller Contact Name.'),
	areaHandled: yup.string().label('Area Handled').notRequired(),
	position: contactDetailsMaxStringSchema.label('Position').required(),
	department: contactDetailsMaxStringSchema.label('Department').required(),
	email: multipleEmailSchema.label('Biller Contact Email Address'),
	contactNumber: multipleContactNumberSchema.label('Biller Contact Number'),
});

const billerContactDetailsSchema = yup
	.array()
	.of(billerContactDetailsEntrySchema);

const accountOfficerSchema = yup.object().shape({
	name: yup.string().label('Account Officer Name').max(255).required(),
	email: multipleEmailSchema.label('Account Officer Email Address'),
	contactNumber: multipleContactNumberSchema.label(
		'Account Officer Contact Number'
	),
});

const primaryInformationSchema = yup.object().shape({
	basicInformation: basicInformationSchema,
	billerAddress: billerAddressSchema,
	accountOfficer: accountOfficerSchema,
	billerContactDetails: billerContactDetailsSchema,
});

export default primaryInformationSchema;
