import { KeyboardEvent } from 'react';

export function mergeSchemas(...schemas) {
	const [first, ...rest] = schemas;

	const merged: any = rest.reduce(
		(mergedSchemas, schema) => mergedSchemas.concat(schema),
		first
	);

	return merged;
}

export function convertFromBytes(size, unit: 'KB' | 'MB' | 'GB') {
	if (unit == 'KB') {
		return Math.ceil(size / 1024);
	} else if (unit == 'MB') {
		return Math.ceil(size / 1048576);
	} else if (unit == 'GB') {
		return Math.ceil(size / 1073741824);
	}
	return size;
}

export function convertToBytes(size, unit: 'KB' | 'MB' | 'GB') {
	if (unit == 'KB') {
		return size * 1024;
	} else if (unit == 'MB') {
		return size * 1048576;
	} else if (unit == 'GB') {
		return size * 1073741824;
	}
	return size;
}

export function keyify(obj, prefix = '') {
	return Object.keys(obj).reduce((res, el) => {
		if (typeof obj[el] === 'object' && obj[el] !== null) {
			return [...res, ...keyify(obj[el], prefix + el + '.')];
		}
		return [...res, prefix + el];
	}, [] as any);
}

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function compressAndEncodeImage(
	file: File,
	quality = 0.4,
	newWidth = 50,
	newHeight = 50
): Promise<string> {
	// Create an HTML Image element
	const img = new Image();
	img.src = URL.createObjectURL(file);

	// Create a Promise to handle the image loading
	const imgLoadPromise = new Promise<HTMLImageElement>((resolve) => {
		img.onload = () => resolve(img);
	});

	// Wait for the image to load
	await imgLoadPromise;

	// Create a canvas element to resize and compress the image
	const canvas = document.createElement('canvas');
	canvas.width = newWidth;
	canvas.height = newHeight;
	const ctx = canvas.getContext('2d');
	if (ctx) {
		ctx.drawImage(img, 0, 0, newWidth, newHeight);
	}

	// Convert the canvas to a compressed JPEG Blob
	const compressedBlob = await new Promise<Blob>((resolve) => {
		canvas.toBlob(
			(blob) => {
				if (blob) {
					resolve(blob);
				}
			},
			'image/jpeg',
			quality
		);
	});

	// Convert the compressed Blob to Base64
	const reader = new FileReader();
	reader.readAsDataURL(compressedBlob);
	const readerLoadPromise = new Promise<string>((resolve) => {
		reader.onloadend = () => resolve(reader.result as string);
	});

	// Wait for the reader to finish
	const compressedBase64Image = await readerLoadPromise;

	return compressedBase64Image;
}

export const hasSpecialCharacter = (v) => {
	return {
		value: new RegExp(/[/;=<>]/).test(v || ''),
		message: 'Invalid Character(s)',
	};
};

export const validateMaxLength = (v: string, x: number) => {
	return {
		value: v?.length <= x,
		message: `You can only input a max of ${x} character${x === 1 ? '' : 's'}`,
	};
};

export const handleKeyDown = (event: KeyboardEvent, actionFn: ((v?: any) => void) | undefined) => {
    if (event.key === 'Enter') {
        actionFn && actionFn();
    }
};
