import { ReactNode } from 'react';
import { connect, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { setCurrentBillerTab } from 'redux/modules/products';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import folderIcon from 'assets/icons/ic-folder.svg';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/ChannelAuditTableModal.module.css';
import Section from 'components/Section/Section';
import Table from 'components/CWSTable/Table';
import cx from 'classnames';

type Column = {
	Header: string;
	id: string;
	sortable?: boolean;
	width: string;
	accessor?: (row: any) => any; // You can adjust the type according to your needs
};

type Props = {
	data?: any;
	onClose?: () => void;
	isOpen: boolean;
	currentBillerTab?: string;
	setCurrentBillerTab: (v?: string) => void;
	columns?: () => any[];
};

type Tabs = {
	primaryInformation?: any;
	contractDetails?: any;
	serviceFeeSettings?: any;
	businessRules?: any;
	reports?: any;
};

type PAE = {
	title: string | ReactNode;
	data?: any;
	columns: () => any[];
};

const panelPlaceholderProps = {
	title: 'No Selected Channel',
	subtitle: 'Please select a channel.',
};

const ChannelBranchAuditPlaceholder: React.FC<{ title: string }> = ({
	title,
}) => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>{title}</div>
				<div className={styles.placeholderSubTitle}>
					There are no changes in this tab.
				</div>
			</div>
		</div>
	);
};

const ChannelAuditTableModalEntry: React.FC<PAE> = ({
	title,
	data,
	columns,
}) => {
	return (
		<>
			<Section title={title} className={styles.section}>
				<Table
					className={styles.paperContainer}
					columns={columns()}
					data={data}
					showPagination={false}
					preHeader={null}
					modalIsOpen={false}
				/>
			</Section>
		</>
	);
};

const AUDIT_LOG_COLUMNS = () => [
	{
		Header: 'Field Name',
		//accessor: (row: any) => row[0],
		id: 'field_name',
		sortable: false,
		width: '28%',
	},
	{
		Header: 'Old Values',
		//accessor: (row: any) => row[1],
		id: 'old_values',
		sortable: false,
		width: '36%',
	},
	{
		Header: 'New Values',
		//accessor: (row: any) => row[2],
		id: 'new_values',
		sortable: false,
		width: '36%',
	},
];

const ChannelAuditTableModal: React.FC<Props> = ({
	data,
	isOpen,
	onClose = () => {},
	columns = AUDIT_LOG_COLUMNS,
}) => {
	const activeType: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeType
	);

	const toRemoveStatusInViewLog = [
		'Edited Branch details',
		'Added Branch profile',
		'Download file',
		'Saved draft',
	].map((actions) => actions.toUpperCase());

	const action = data?.action?.toUpperCase();

	const isRemoveInViewLog = toRemoveStatusInViewLog.some(
		(keyword) => action && action === keyword
	);

	return (
		<>
			<div className={styles.statusAuditLog}>
				<ChannelAuditTableModalEntry
					columns={columns || AUDIT_LOG_COLUMNS}
					data={data || []}
					//data={data}
					title={''}
				/>
			</div>
		</>
	);
};

export default connect(
	(state: any) => ({
		currentBillerTab: state.products.currentBillerTab,
	}),
	{ setCurrentBillerTab }
)(ChannelAuditTableModal);
