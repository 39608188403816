import { useState, useEffect, useCallback } from 'react';
import { Modal } from '@salesforce/design-system-react/module/components';
import HTTP from 'helpers/ApiClient';
import styles from './Filter.module.css';
import Table from 'components/CWSTable/Table';
import { get as _get, debounce as _debounce } from 'lodash';
import LookupField from 'components/Inputs/LookupField/LookupField';
import { Control, Controller } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import cx from 'classnames';
import { isTruthy, resolveValue } from 'utils/common';
import { WalletListFilterFormData } from '../WalletManagement/WalletList/WalletListFilter';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

type GetChannelsArgs = {
	page?: number;
	limit?: number;
	name?: string;
	type?: string;
};

async function getChannels({
	page = 1,
	limit = 25,
	name = '',
	type,
}: GetChannelsArgs = {}) {
	const result = await HTTP.get('/v1/cws/util/accounts', {
		params: {
			name: resolveValue(name),
			page,
			limit,
			type,
		},
	});
	return result.data;
}

async function getPartners({
	page = 1,
	limit = 25,
	name = '',
	type,
}: GetChannelsArgs = {}) {
	const result = await HTTP.get('/v1/partners', {
		params: {
			name: resolveValue(name),
			page,
			limit,
			type,
		},
	});
	return result.data;
}

type OnSelect = ({ label, value }) => void;
const generateChannelTableColumn = (
	onSelect: OnSelect,
	displayName: string
) => [
	{
		Header: displayName,
		id: 'name',
		width: '100%',
		Cell: ({ cell, value }) => {
			const { id, name } = cell.row.values;
			return (
				<div
					{...cell.getCellProps()}
					onClick={() => {
						onSelect({
							label: name,
							value: id,
						});
					}}
					onKeyDown={()=>{return}}
					className={styles.channelRow}
				>
					{value}
				</div>
			);
		},
	},
	{ id: 'id', hidden: true, Header: 'ID' },
];

const ChannelModal = ({
	open,
	onClose,
	data,
	count,
	onPageChange,
	pageSize = 25,
	onSelect,
	displayName,
	isLoading,
	page,
	setPage,
}) => {
	const handlePageChange = (p, l) => {
		onPageChange(p, true);
		setPage(p);
	};

	const handleSelect = (value) => {
		onSelect(value);
		onClose();
	};

	return (
		<>
			<FullPageLoader
				open={isLoading}
				message="Please wait while partner list is being loaded"
			/>
			<Modal
				containerClassName={styles.partnerNameModalContainer}
				isOpen={open}
				onRequestClose={onClose}
				contentClassName={styles.partnerNameModalContent}
			>
				<Table
					columns={generateChannelTableColumn(handleSelect, displayName)}
					data={data}
					count={count}
					pageSize={pageSize}
					onPageChange={handlePageChange}
					page={page}
					showPageSizeOptions={false}
					tableBodyProps={{
						style: { maxHeight: '425px', overflowY: 'auto' },
					}}
					preHeader={null}
					modalIsOpen={false}
					isNotEvenRowColor
				/>
			</Modal>
		</>
	);
};

type ChannelNameFilterProps = {
	control?: Control<WalletListFilterFormData>;
	name: any;
	walletType?: string;
	displayName?: string;
	isSearchable?: boolean;
	value?: string | null;
};

const ChannelNameFilter: React.FC<ChannelNameFilterProps> = ({
	control,
	name,
	walletType,
	displayName = 'Channel Name',
	isSearchable = true,
	value,
}) => {
	const [page, setPage] = useState(1);
	const [fullOptions, setFullOptions] = useState([]);
	const [options, setOptions] = useState([]);
	const [count, setCount] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const seeMoreThreshold = 10;

	const fetchChannels = async (
		{ name, page = 1, limit = 25 }: GetChannelsArgs = {},
		isPageManuallyClicked = false
	) => {
		try {
			if (isPageManuallyClicked) startLoading();
			startDropdownLoading();
			const params = {
				name,
				page,
				limit,
				...(walletType && { type: walletType }),
			};

			const response =
				displayName === 'Partner Name'
					? await getPartners(params)
					: await getChannels(params);
			const data = response.result || [];
			setOptions(
				data.slice(0, seeMoreThreshold).map(({ id: value, name: label }) => ({
					label,
					value,
				}))
			);
			setFullOptions(data);

			const listCount =
				displayName === 'Channel Name'
					? data.length
					: _get(response, 'meta.total', 0);

			setCount(listCount);
		} catch (e) {
			setOptions([]);
		} finally {
			stopLoading();
			stopDropdownLoading();
		}
	};

	const {
		value: isLoading,
		valueOn: startLoading,
		valueOff: stopLoading,
	} = useToggle();

	const {
		value: isDropdownLoading,
		valueOn: startDropdownLoading,
		valueOff: stopDropdownLoading,
	} = useToggle();

	const {
		value: isChannelModalOpen,
		valueOn: openChannelModal,
		valueOff: closeChannelModal,
	} = useToggle();

	const handleSearch = useCallback(
		_debounce((value) => {
			const v = value || '';
			if (v.length >= 3 || (v === '' && inputValue !== '')) {
				fetchChannels({ name: v });
			}
		}, 500),
		[]
	);

	const handleChange = (value) => {
		const v = value || '';
		setInputValue(v);
		handleSearch(value);
	};

	const handleMenuOpen = async () => {
		if (
			options.length === 0 ||
			page !== 1 ||
			[undefined, ''].some((e) => e === value)
		) {
			setOptions([]);
			setPage(1);
			fetchChannels();
		}
	};

	const handlePageChange = (page, isPageManuallyClicked) => {
		fetchChannels({ page, name: inputValue }, isPageManuallyClicked);
	};

	useEffect(() => {
		fetchChannels();
	}, [walletType]);

	return (
		<>
			<label
				className={cx(styles.channelLabel, 'slds-form-element__label')}
				htmlFor="channelName"
			>
				{displayName}
			</label>
			<Controller
				name={name}
				control={control}
				render={({ field }) => {
					const { onChange, ...rest } = field;
					return (
						<>
							<LookupField
								{...rest}
								onChange={onChange}
								isLoading={isDropdownLoading}
								options={options}
								hasSeeMore={count > seeMoreThreshold}
								placeholder={isDropdownLoading ? '' : displayName || ''}
								onInputChange={
									isSearchable && !isChannelModalOpen ? handleChange : () => {}
								}
								onMenuOpen={handleMenuOpen}
								inputValue={inputValue}
								filterOption={() => true}
								seeMoreClick={openChannelModal}
								isSearchable={isSearchable}
							/>
							<ChannelModal
								open={isChannelModalOpen}
								data={fullOptions}
								onClose={closeChannelModal}
								count={count}
								onPageChange={handlePageChange}
								onSelect={(value) => {
									onChange(value);
									setInputValue(value.name);
								}}
								displayName={displayName}
								isLoading={isLoading}
								page={page}
								setPage={setPage}
							/>
						</>
					);
				}}
			/>
		</>
	);
};

export default ChannelNameFilter;
