import { DEFAULT_SENDER_EMAIL } from 'components/PartnerForm/Tabs/Reports';

export const NAME_LABEL = 'Name';
export const EMAIL_LABEL = 'Email Address';
export const MAX_CHAR_255 = 255;
export const MAX_CHAR_255_MSG = 'You can only input a max of 255 characters.';
export const TELEPHONE_LABEL = 'Telephone Number';
export const TOTAL_LENGTH_MSG = 'You can only input a max of 255 characters.';
export const INVALID_TEL_MSG = 'inputs are invalid telephone number format';
export const INVALID_TEL_MSG_SINGLE =
	'input is invalid telephone number format';
export const CHANNEL_CONTACT_LABEL = 'Contact Number';

export const UPDATE_AUTOSAVE = 'updateAutoSave';

export const REPORTS_FORM_DEFAULT_VALUES = {
	smtp: {
		csrConfiguration: {
			uploadFrequency: 1,
		},
		uploadConfiguration: {
			uploadFrequency: 1,
		},
		senderEmail: DEFAULT_SENDER_EMAIL,
	},
	sftp: {
		uploadConfiguration: {
			uploadFrequency: 1,
		},
		csrConfiguration: {
			uploadFrequency: 1,
		},
	},
};
