import {
	Modal,
	Button,
} from '@salesforce/design-system-react/module/components';
import { useDownloadReport } from 'components/Reports/hooks/ReportDownload';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { setClearReport } from 'redux/modules/report-viewer';
import { useState } from 'react';

import ViewReport from 'components/Reports/ReportModalTabContent/ViewReport';
import AuditTrail from 'components/Reports/ReportModalTabContent/AuditTrail';
import ReportModalTab from 'components/Reports/ReportModalTab';
import styles from './index.module.css';
import React from 'react';

type Props = {
	open?: boolean;
	disableSave?: boolean;
	handleClick?: any;
	isPrimarySuccess?: boolean;
	hasDowloadAction?: boolean;
	onClose?: () => void;
	title?: string;
};

const ModalHeader = ({ title }) => {
	return (
		<div className={styles.header}>
			<div className={styles.title}>{title}</div>
		</div>
	);
};

const ModalFooter = ({ tabIndex, handleTabIndex }) => {
	const hasDowloadAction = tabIndex === 0;
	const dispatch = useDispatch();
	const { report_file_id, report_category_id } =
		useSelector((states: RootStateOrAny) => states.reports);

	const downloadActions = useDownloadReport({
		categoryId: report_category_id,
		id: report_file_id,
	});

	const handleClose = () => {
		if (handleTabIndex instanceof Function) {
			handleTabIndex(0);
		}

		dispatch(setClearReport());
	};

	return (
		<div className={styles.footer}>
			<div className="btn-container">
				<Button onClick={handleClose}>Close</Button>
				{hasDowloadAction && (
					<Button variant="brand" {...downloadActions}>
						Download
					</Button>
				)}
				{downloadActions.errorModal}
			</div>
			<div className={styles.disclaimer}>
				Note: This is just a sample preview of the report. Kindly download the
				file to be able to view and navigate the whole report.
			</div>
		</div>
	);
};

const ReportModal: React.FC<Props> = () => {
	const [tabReport, setTabReport] = useState(0);
	const stateReportViewer = useSelector(
		(states: RootStateOrAny) => states.reportViewer
	);

	const ReportTabs = [
		{
			title: 'Preview Report',
			content: tabReport === 0 && <ViewReport />,
		},
		{
			title: 'Audit Trail',
			content: tabReport === 1 && <AuditTrail />,
		},
	];

	return (
		<Modal
			disableClose
			size="medium"
			ariaHideApp={false}
			contentClassName={styles.modal}
			isOpen={stateReportViewer.modal_open}
			headerClassName={styles.headerContainer}
			containerClassName={styles.modalContainer}
			heading={<ModalHeader title={stateReportViewer.modal_title} />}
			footer={
				<ModalFooter tabIndex={tabReport} handleTabIndex={setTabReport} />
			}
		>
			<div className={styles.body}>
				<ReportModalTab
					onChangeTab={(tab) => setTabReport(tab)}
					tabs={ReportTabs}
				/>
			</div>
		</Modal>
	);
};

export default ReportModal;
