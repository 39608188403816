import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { Datepicker } from '@salesforce/design-system-react/module/components';
import styles from './Datepicker.module.css';
import moment from 'moment';
import Label from '../Label/Label';

interface ISLDSDatepicker {
	label: string;
	value: any;
	placeholder: string;
	controlled?: boolean;
	optional?: boolean;
	onChange?: (e: string) => void;
	disabled?: boolean;
	[rest: string]: any;
}

const SLDSDatepicker = React.forwardRef(
	(
		{
			label,
			value,
			placeholder,
			controlled = false,
			onChange = () => { },
			optional = false,
			disabled = false,
			...rest
		}: ISLDSDatepicker,
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const [inputValue, setInputValue] = useState('');
		const defaultLabel = optional ? '' : label;
		const datePickerRef = useRef<any>(null);

		useEffect(() => {
			if (!value || !moment(value, 'MM/DD/YYYY', true).isValid()) {
				setInputValue('');
			} else {
				setInputValue(moment(value).format('MM/DD/YYYY'));
			}
		}, [value]);
		useEffect(() => {
			const calendarBtnNode =
				datePickerRef.current?.componentNode.children[0]?.children[1]
					?.children[1];
			const toggleMenu = (event: React.MouseEvent<HTMLElement>): void => {
				event.stopPropagation();
				setIsOpen((value) => !value);
			};

			if (calendarBtnNode) {
				calendarBtnNode.addEventListener('click', toggleMenu);
			}
			return () => {
				if (calendarBtnNode)
					calendarBtnNode.removeEventListener('click', toggleMenu);
			};
		}, [ref]);
		return (
			<>
				{optional && <Label optional>{label}</Label>}
				<Datepicker
					isOpen={isOpen}
					onRequestOpen={() => setIsOpen(true)}
					onRequestClose={() => setIsOpen(false)}
					ref={(node: any) => {
						datePickerRef.current = node;
						if (typeof ref === 'function') {
							ref(node);
						} else if (ref) {
							(ref as MutableRefObject<HTMLDivElement>).current = node;
						}
					}}
					triggerClassName={styles.datepicker}
					labels={{
						label: defaultLabel,
						placeholder,
					}}
					value={value}
					disabled={disabled}
					dateDisabled={() => disabled}
					onChange={(e: any, data: any) => {
						if (controlled) {
							onChange(data?.formattedDate || undefined); // Pass null when date is cleared
						}
						setInputValue(data ? data.formattedDate : '');
					}}
					formatter={(date: any) => {
						return date ? moment(date).format('MM/DD/YYYY') : '';
					}}
					input={<input {...rest} value={inputValue} />}
					menuPosition={'relative'}
					className={rest.className || ''}
				/>
			</>
		);
	}
);

export default SLDSDatepicker;
