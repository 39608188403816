import CsrColumnCheckbox from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/CsrColumnCheckbox';
import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { ControlledCsrColumnCheckboxProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/types';
import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { selectedChannel } from 'redux/modules/channelList';
import { FixMeLater } from 'types';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';

export function CsrColumnTemplate<T extends FieldValues>({
	control,
	name,
	onChange,
	pool,
	channelId,
	...rest
}: ControlledCsrColumnCheckboxProps<T>): FixMeLater {
	const selectedChannelId = useSelector<ReducerStateType, string>(
		(state) =>
			state.channels.selectedChannel && state.channels.selectedChannel.id
	);

	const [selectedCsrColumns, setSelectedCsrColumns] = useState<CsrColumn[]>([]);
	const {
		field: { value, onChange: hookOnChange },
	} = useController({ control, name });

	useEffect(() => {
		setSelectedCsrColumns(
			value.map((entry: number | CsrColumn) => {
				if (typeof entry === 'number') {
					return pool?.find((p) => p.id === entry);
				}
				return entry;
			}) as CsrColumn[]
		);

		return () => {
			setSelectedCsrColumns([]);
		};
	}, [value]);

	// const selectedCsrColumns = value.map((entry: number | CsrColumn) => {
	// 	if (typeof entry === 'number') {
	// 		return pool?.find((p) => p.id === entry);
	// 	}
	// 	return entry;
	// }) as CsrColumn[];

	function handleChange(entries: CsrColumn[]) {
		hookOnChange(entries);
		onChange?.(entries);
	}

	return (
		<CsrColumnCheckbox
			value={selectedCsrColumns}
			onChange={handleChange}
			control={control}
			name={name}
			pool={pool}
			channelId={channelId}
			{...rest}
		/>
	);
}
