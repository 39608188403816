import cx from 'classnames';
import styles from './BillerStatus.module.css';
import BillerTab from '../../../components/billerStatusComponent/BillerTab';

const BillerStatus: React.FC = () => {
	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			<div className={cx(styles.title)}>Biller Status</div>
			<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
				<BillerTab />
			</div>
		</div>
	);
};

export default BillerStatus;
