import React, { useEffect } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import cx from 'classnames';
import styles from './SidebarLink.module.css';
import IconResolver from 'helpers/IconResolver';
import {
	Submenu as SubmenuType,
	toggleSubMenuListItem,
	selectSubMenuItem,
} from 'redux/modules/sidebar';
import { connect, useDispatch } from 'react-redux';
import {
	clearFetchedForms,
	setProductCategoryBaseOnPath,
} from 'redux/modules/products';
import { debounce } from 'lodash';

type MenuItemProps = {
	id: string;
	collapsed?: boolean;
	onCollapsibleSubmenuClick: (id: string) => void;
	link?: string;
	hasMatches?: boolean;
	name: string;
};

type SubMenuProps = {
	id: string;
	collapsed?: boolean;
	onCollapsibleSubmenuClick: (id: string) => void;
	onCollapsibleSubmenuLinkClick?: () => any;
	hasSubmenulinks?: boolean;
	link?: string;
	hasMatches?: boolean;
	name: string;
};

const MenuLink: React.FC<MenuItemProps> = ({
	collapsed,
	hasMatches,
	onCollapsibleSubmenuClick,
	id,
	name,
	link = '/',
}) => {
	const overideMatches = hasMatches
		? hasMatches
		: !hasMatches && !collapsed
		? true
		: false;
	return (
		<Link to={link}>
			<div
				className={cx(
					'slds-grid slds-grid_align-spread',
					styles.link,
					styles.menuLink,
					{
						[styles.expanded]: !collapsed,
						[styles.isActive]: overideMatches,
					}
				)}
				onClick={() => onCollapsibleSubmenuClick(id)}
				onKeyDown={()=>{return}}
			>
				<div className="slds-col">{name}</div>
				<div className="slds-col">
					<IconResolver
						className={cx(
							'slds-icon_xx-small',
							'slds-p-left_xx-small',
							styles.chevron
						)}
						path={`utility/${collapsed ? 'chevronright' : 'chevrondown'}`}
					/>
				</div>
			</div>
		</Link>
	);
};

const SubMenuLink: React.FC<SubMenuProps> = ({
	collapsed,
	hasMatches,
	onCollapsibleSubmenuClick,
	onCollapsibleSubmenuLinkClick = () => {},
	hasSubmenulinks,
	id,
	name,
	link = '/',
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		debounce(() => !collapsed && dispatch(clearFetchedForms()), 600)();
	}, [collapsed, dispatch]);

	return (
		<Link to={link}>
			<div
				className={cx(
					'slds-grid slds-grid_align-spread',
					styles.link,
					styles.menuLink,
					{
						[styles.expanded]: !collapsed,
						[styles.isActiveSubMenuLinks]: hasMatches,
					}
				)}
				onClick={() =>
					hasSubmenulinks
						? onCollapsibleSubmenuLinkClick()
						: onCollapsibleSubmenuClick(id)
				}
				onKeyDown={()=>{return}}
			>
				<div className="slds-col">{name}</div>
				<div className="slds-col">
					<IconResolver
						className={cx(
							'slds-icon_xx-small',
							'slds-p-left_xx-small',
							styles.chevron
						)}
						path={`utility/${collapsed ? 'chevronright' : 'chevrondown'}`}
					/>
				</div>
			</div>
		</Link>
	);
};

type SideBarLinkProps = SubmenuType & {
	mainId: string;
	onCollapsibleSubmenuClick: (id: string) => void;
	toggleSubMenuListItem: any;
	selectSubMenuItem: (payload: {
		menuItemId: string;
		submenuItemId: string;
		submenuItemId2ndDepth: string;
	}) => void;
};

const SidebarLink: React.FC<SideBarLinkProps> = ({
	id,
	link,
	name,
	exact = true,
	collapsed,
	collapsible,
	extendCollapsed,
	submenus,
	onCollapsibleSubmenuClick,
	toggleSubMenuListItem,
	selectSubMenuItem,
	mainId,
}) => {
	const location = useLocation();
	const dispatch = useDispatch();

	if (extendCollapsed && collapsible && submenus) {
		const hasMatches = submenus.some(({ link: path, exact }) =>
			matchPath(location.pathname, { path, exact })
		);
		return (
			<li className={cx('slds-nav-vertical__item')}>
				<MenuLink
					collapsed={collapsed}
					hasMatches={hasMatches}
					onCollapsibleSubmenuClick={onCollapsibleSubmenuClick}
					id={id}
					name={name}
					link={link}
				/>
				{!collapsed && (
					<div className={styles.submenus}>
						{submenus &&
							submenus.map(
								({
									collapsed: submenuCollapsed,
									id: submenuItemId2ndDepth,
									link: submenuLink,
									name: submenuName,
									submenulinks = [],
								}) => {
									const hasMatches: boolean | any =
										matchPath(location.pathname, {
											path: submenuLink,
										}) || false;
									return (
										<>
											<SubMenuLink
												collapsed={submenuCollapsed}
												hasMatches={hasMatches}
												onCollapsibleSubmenuClick={onCollapsibleSubmenuClick}
												onCollapsibleSubmenuLinkClick={() => {
													dispatch(setProductCategoryBaseOnPath(submenuLink));

													toggleSubMenuListItem({
														menuItemId: mainId,
														submenuItemId: id,
														submenuItemId2ndDepth,
													});
												}}
												hasSubmenulinks={true}
												id={submenuItemId2ndDepth}
												name={submenuName}
												link={submenuLink}
											/>
											{!submenuCollapsed && (
												<div className={styles.submenus}>
													{submenulinks &&
														submenulinks.map(({ link, name }) => {
															const match = matchPath(location.pathname, {
																path: link,
															});
															return (
																<Link
																	onClick={() => {
																		dispatch(
																			setProductCategoryBaseOnPath(submenuLink)
																		);
																		selectSubMenuItem({
																			menuItemId: mainId,
																			submenuItemId: id,
																			submenuItemId2ndDepth,
																		});
																	}}
																	to={link}
																	className={cx(styles.submenu, styles.link, {
																		[styles.submenuActive]: match,
																	})}
																	key={link}
																>
																	<li
																		className={styles.submenulinks}
																		style={{
																			listStyleType: 'disc',
																			marginLeft: '15px',
																		}}
																	>
																		<p className={styles.submenulinksname}>
																			{name}
																		</p>
																	</li>
																</Link>
															);
														})}
												</div>
											)}
										</>
									);
								}
							)}
					</div>
				)}
			</li>
		);
	}

	if (collapsible && submenus) {
		const hasMatches = submenus.some(({ link: path, exact }) =>
			matchPath(location.pathname, { path, exact })
		);
		return (
			<li className={cx('slds-nav-vertical__item')}>
				<MenuLink
					collapsed={collapsed}
					hasMatches={hasMatches}
					onCollapsibleSubmenuClick={onCollapsibleSubmenuClick}
					id={id}
					name={name}
					link={link}
				/>
				{!collapsed && (
					<div className={styles.submenus}>
						{submenus &&
							submenus.map(({ link, exact = true, name }) => {
								const match = matchPath(location.pathname, {
									path: link,
									exact,
								});
								return (
									<Link
										key={link}
										to={link}
										className={cx(styles.submenu, styles.link, {
											[styles.submenuActive]: match,
										})}
									>
										{/* {name} */}
										<li
											className={styles.submenulinks}
											style={{
												listStyleType: 'disc',
												marginLeft: '15px',
											}}
										>
											<p className={styles.submenulinksname}>{name}</p>
										</li>
									</Link>
								);
							})}
					</div>
				)}
			</li>
		);
	}

	const match = matchPath(location.pathname, { path: link, exact });
	return (
		<li
			className={cx('slds-nav-vertical__item', {
				[styles.isActive]: match,
			})}
		>
			<Link to={link} className={cx(styles.submenu, styles.link)}>
				{name}
			</Link>
		</li>
	);
};

export default connect(
	(state: any) => ({ selectedBiller: state.billers.selectedBiller }),
	{ toggleSubMenuListItem, selectSubMenuItem }
)(SidebarLink);
