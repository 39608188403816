import React, { useState } from 'react';
import cx from 'classnames';

import { Button, Tooltip } from '@salesforce/design-system-react';
import Truncated from 'components/Truncated';
import Table from 'components/CWSTable/Table';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';

import { ReactComponent as ViewIcon } from 'assets/icons/ic-three-dashes-view.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/ic-block-red.svg';
import { ReactComponent as UnblockIcon } from 'assets/icons/ic-block.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/ic-resend.svg';

import { useTablePagination, useToggle } from 'utils/hooks';
import { formatDate } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import UserDetailsModal from './UserDetailsModal';
import UserResetPasswordModal from './UserResetPassword';

import { UserListTabProps, UserDetailsType } from './types';
import styles from './UserMgt.module.css';

const Cell: React.FC<{ value: string | JSX.Element }> = ({ value }) => (
	<div className={styles.rowBreak}>{value}</div>
);

const USER_COLUMNS = (fetchUser, resetPasswordBtn, blockUnblockBtn) => [
	{
		Header: 'Date Created',
		id: 'createdDate',
		sortable: true,
		width: '15%',
		accessor: ({ createdDate }) => formatDate(createdDate),
	},
	{
		Header: 'First Name',
		id: 'firstName',
		sortable: true,
		width: '11%',
		Cell,
	},
	{
		Header: 'Last Name',
		id: 'lastName',
		sortable: true,
		width: '11%',
		Cell,
	},
	{
		Header: 'Username',
		id: 'username',
		sortable: true,
		width: '9%',
		Cell,
	},
	{
		Header: 'Role',
		id: 'role',
		sortable: true,
		Cell,
	},
	{
		Header: 'Branch',
		id: 'branch',
		width: '15%',
		Cell: ({ value }) => <Truncated data={value} />,
	},
	{
		Header: 'User Type',
		id: 'userType',
		sortable: true,
		Cell,
	},
	{
		Header: 'Status',
		id: 'status',
		sortable: true,
		width: '9%',
		Cell: ({ value = '' }) => {
			return (
				<>
					{value?.toUpperCase() === 'ACTIVE' ? (
						<p style={{ color: '#14bb02' }}>Active</p>
					) : (
						''
					)}
					{value?.toUpperCase() === 'INACTIVE' ? (
						<p style={{ color: '#8b9095' }}>Inactive</p>
					) : (
						''
					)}
					{value?.toUpperCase() === 'BLOCKED' ? (
						<p style={{ color: '#d93025' }}>Blocked</p>
					) : (
						''
					)}
					{value?.toUpperCase() === 'TEMPORARY_BLOCKED' ? (
						<p style={{ color: '#f26122' }}>Temporary Blocked</p>
					) : (
						''
					)}
				</>
			);
		},
	},
	{
		Header: () => <div className={styles.actionHeader}>Actions</div>,
		id: 'actions',
		accessor: (values) => values,
		Cell: ({ value }) => {
			return (
				<>
					<div className={cx(styles.actions)}>
						<Tooltip
							content="Resend Link"
							align="bottom"
							dialogClassName={styles.tooltip}
						>
							<Button
								className={cx(styles.actionIcon)}
								onClick={() => resetPasswordBtn(value)}
								variant="icon"
							>
								<ResendIcon />
							</Button>
						</Tooltip>
						<Tooltip
							content={
								value?.status?.toUpperCase() === 'BLOCKED' ? 'Unblock' : 'Block'
							}
							align="bottom"
							dialogClassName={styles.tooltip}
						>
							<Button
								className={cx(styles.actionIcon)}
								onClick={() => blockUnblockBtn(value)}
								variant="icon"
							>
								{value?.status?.toUpperCase() === 'BLOCKED' ? (
									<UnblockIcon />
								) : (
									<BlockIcon />
								)}
							</Button>
						</Tooltip>
						<Tooltip
							content="View Details"
							align="bottom right"
							dialogClassName={styles.tooltip}
						>
							<Button
								className={cx(styles.actionIcon)}
								onClick={() => fetchUser(value)}
								variant="icon"
							>
								<ViewIcon />
							</Button>
						</Tooltip>
					</div>
				</>
			);
		},
	},
];

const UserTable: React.FC<UserListTabProps> = ({
	data,
	handleTableFetch,
	handleSubmitStatus,
}) => {
	const [selectedUserData, setSelectedUserData] = useState<UserDetailsType>();
	const [remarkValue, setRemarkValue] = useState<string>('');
	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();
	const {
		value: isViewDetailsOpen,
		valueOn: openViewDetails,
		valueOff: closeViewDetails,
	} = useToggle();
	const {
		value: isResetPassword,
		valueOn: openResetPassword,
		valueOff: closeResetPassword,
	} = useToggle();

	const fetchUser = async (value: UserDetailsType) => {
		setSelectedUserData(value);
		if (hasUserPermission('get.user')) {
			openViewDetails();
		}
	};

	const resetPasswordBtn = async (value: UserDetailsType) => {
		setSelectedUserData(value);
		if (hasUserPermission('password.reset')) {
			openResetPassword();
		}
	};

	const blockUnblockBtn = (value: UserDetailsType) => {
		const { status, ...rest } = value;
		if (
			(status &&
				status.toUpperCase() !== 'BLOCKED' &&
				hasUserPermission('user.block')) ||
			(status &&
				status.toUpperCase() === 'BLOCKED' &&
				hasUserPermission('user.unblock'))
		) {
			showConfirmModal();
		}
		setSelectedUserData({
			status: status && status.toUpperCase(),
			...rest,
		});
	};

	const isBlocked = selectedUserData?.status === 'BLOCKED';
	const actionText = `${isBlocked ? 'Unblock' : 'Block'}`;
	const statusText =
		selectedUserData?.verifiedDate !== null ? (
			<p className={styles.statusVerifiedText}>Verified User</p>
		) : (
			<p className={styles.statusUnverifiedText}>User is not yet verified</p>
		);
	const userFullName = `${
		selectedUserData?.firstName + '  ' + selectedUserData?.lastName
	}`;

	const modalHeader = `${actionText} User ${userFullName} (${selectedUserData?.username}) `;
	const bodyHeader = `This action will ${actionText.toLowerCase()} ${userFullName}'s login. This will also send a link to the user's email (${
		selectedUserData?.email
	}) to reset his/her password.`;

	return (
		<>
			<Table
				columns={USER_COLUMNS(fetchUser, resetPasswordBtn, blockUnblockBtn)}
				data={data?.data}
				preHeader={null}
				modalIsOpen={false}
				isAlignToFilter={true}
				isNotEvenRowColor
				{...tablePaginationProps}
			/>

			{selectedUserData && isViewDetailsOpen && (
				<UserDetailsModal
					isModalOpen={isViewDetailsOpen}
					onCloseModal={closeViewDetails}
					cellValue={selectedUserData}
				/>
			)}

			<UserResetPasswordModal
				isOpen={isResetPassword}
				cellValue={selectedUserData}
				onCloseModal={closeResetPassword}
			/>

			<ConfirmModalWithRemarks
				isOpen={isConfirmModalShown}
				onClose={hideConfirmModal}
				onCancel={hideConfirmModal}
				modalDisabled={false}
				confirmOnClick={() => handleSubmitStatus(selectedUserData, remarkValue)}
				header={modalHeader}
				bodyHeader={isBlocked && bodyHeader}
				remarksSubLabel="*Maximum of 1000 characters only."
				remarksMaxLength={1000}
				isReasonFormat={true}
				placeholder={`Explain the reason for ${
					isBlocked ? 'unblocking' : 'blocking'
				} this user.`}
				statusHeader={statusText}
				actionText={actionText}
				remarksOnChange={setRemarkValue}
				blockedCharsRegex="[/;<>=]"
				className={styles.confirmModalWithRemarks}
			/>
		</>
	);
};

export default UserTable;
