import yup from 'utils/formSchemas/common';

export const channelAccreditationItemSchema = yup.object({}).shape({
	label: yup.string().nullable(true).default(null).optional(),
	name: yup.string().nullable(true).default(null).optional(),
	file: yup.string().nullable(true).default(null).optional(),
	filename: yup.string().nullable(true).default(null).optional(),
	objectKey: yup.string().nullable(true).default(null).optional(),
	status: yup.string().nullable(true).default(null).optional(),
	// .oneOf(['Uploading', 'Uploaded', 'Upload Failed', 'Not Yet Uploaded', '']),
});

export const channelAccreditationSchema = yup.object({}).shape({
	birCertReg: yup.string().nullable(true).default(null).optional(),
	corpSecCert: yup.string().nullable(true).default(null).optional(),
	businessPermit: yup.string().nullable(true).default(null).optional(),
	companyProfile: yup.string().nullable(true).default(null).optional(),
	govtIds: yup.string().nullable(true).default(null).optional(),
	genInfoSheets: yup.string().nullable(true).default(null).optional(),
	nonDisclosure: yup.string().nullable(true).default(null).optional(),
	secRegCert: yup.string().nullable(true).default(null).optional(),
	secArticleIncorp: yup.string().nullable(true).default(null).optional(),
	certAuthority: yup.string().nullable(true).default(null).optional(),
	signedNda: yup.string().nullable(true).default(null).optional(),
	auditFinancialStmt: yup.string().nullable(true).default(null).optional(),
	incomeTaxReturn: yup.string().nullable(true).default(null).optional(),
	cbciAmlQuestion: yup.string().nullable(true).default(null).optional(),
	secondAuditFinStmt: yup.string().nullable(true).default(null).optional(),
	dataPrivacyPia: yup.string().nullable(true).default(null).optional(),
	dataPrivacyPat: yup.string().nullable(true).default(null).optional(),
	thirdPartySecChecklist: yup.string().nullable(true).default(null).optional(),
});
