import React from 'react';
import { Modal } from '@salesforce/design-system-react/module/components';

import ChannelDetailsHeader from '../ChannelDetailsHeader';
import ChannelDetailsBody from '../ChannelDetailsBody';

import { ChannelDetailsProps } from '../types';

import styles from './ChannelDetails.module.css';

const ChannelDetails: React.FC<ChannelDetailsProps> = ({
	isOpen,
	onClose,
	data,
	setRefetchChannelProfile,
	refetchChannelProfile,
}) => {
	return (
		<div>
			<Modal
				isOpen={isOpen}
				disableClose={false}
				onRequestClose={onClose}
				size="medium"
				containerClassName={styles.modalContainer}
				headerClassName={styles.headerContainer}
				heading={<div className={styles.header}>Channel Profile</div>}
			>
				<ChannelDetailsHeader
					data={data}
					setRefetchData={setRefetchChannelProfile}
				/>
				<ChannelDetailsBody data={data} isRefetchData={refetchChannelProfile} />
			</Modal>
		</div>
	);
};
export default ChannelDetails;
