import { Route } from 'react-router-dom';
import { defaultProductModules } from 'redux/modules/sidebar';
import Drafts from '../Draft/Draft';
import PartnerProfile from '../PartnerProfile/PartnerProfile';
// import { Approvals } from './BillsPayment/BillsPayment';
import Approval from '../Approval/Approval';

const Products: React.FC = () => {
	return (
		<>
			{defaultProductModules[0].submenus[0].submenus.map((p, key) => (
				<div key={key}>
					<Route exact path={p.link} component={PartnerProfile} />
					<Route exact path={`${p.link}/drafts`} component={Drafts} />
					<Route exact path={`${p.link}/approvals`} component={Approval} />
				</div>
			))}
		</>
	);
};

export default Products;
