import { Route } from 'react-router-dom';
import TransactionMgt from './TransactionMgt';
import ProductMgt from './ProductMgt';
import ChannelMgt from './ChannelMgt';
import UserMgt from './UserMgt';

const BfaAdminPortal: React.FC = () => {
	return (
		<div>
			<Route
				path="/bfa-admin/transaction-management/"
				component={TransactionMgt}
			/>
			<Route path="/bfa-admin/product-management/" component={ProductMgt} />
			<Route path="/bfa-admin/channel-management/" component={ChannelMgt} />
			<Route path="/bfa-admin/user-management/" component={UserMgt} />
		</div>
	);
};
export default BfaAdminPortal;
