import {
	DEFAULT_MAX_CHARS,
	FILE_FORMATS,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/constants';
import {
	checkTimeStampInterval,
	checkCoveredTime,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/utils';
import { FixMeLater } from 'types';
import yup, { multipleEmailSchema } from 'utils/formSchemas/common';
import { YupLab } from 'utils/yupLab';
import { ValidationError } from 'yup';
import { MAX_CHAR_255_MSG } from '../../const';

// export const csrColumnSchema = yup.object({
// 	id: yup.string().required(),
// 	value: yup.string().optional(),
// 	code: yup.string().optional(),
// 	sample: yup.string().optional(),
// 	is_required: yup.boolean().optional(),
// 	ordinal: yup.number().required(),
// });

const requiredStringWithMax = yup.string().max(DEFAULT_MAX_CHARS).required();

// const filenameFormat = yup
// 	.string()
// 	.label('File Name Format')
// 	.oneOf(FILE_FORMATS)
// 	.required(selectDefaultRequiredTemplate);

// const uploadSchedule = yup
// 	.string()
// 	.label('Upload Schedule')
// 	.oneOf(['SAME', 'NEXT'])
// 	.required(selectDefaultRequiredTemplate);

// const uploadFrequency = yup
// 	.number()
// 	.label('Upload Frequency')
// 	.typeError('Input numeric value from 1-25')
// 	.min(1, 'Input value greater than 0')
// 	.max(25, ({ max }) => `Input value less than or equal to ${max}`)
// 	.required()
// 	.default(1);

export const scheduleSchema = yup.object({
	coveredSchedule: yup
		.string()
		.nullable(true)
		.default(null)
		.label('Covered Schedule')
		.required('Select Covered Schedule.'),
	coveredTimeFrom: yup
		.string()
		.nullable(true)
		.default(null)
		.label('Covered Time From')
		.required('Select Covered Time From.'),
	coveredTimeTo: yup
		.string()
		.nullable(true)
		.default(null)
		.label('Covered Time To')
		.required('Select Covered Time To.')
		.test({
			name: 'timeOfUpload-custom-validation',
			test: (value, context) => {
				let testResult: boolean | ValidationError = true;
				//-------------Time Interval Validation-------------
				const coveredTimeFrom = context.parent.coveredTimeFrom;
				const coveredTimeTo = value;
				if (checkCoveredTime(coveredTimeTo, coveredTimeFrom))
					testResult = context.createError({
						message: 'Invalid Time Input.',
					});
				return testResult;
			},
		}),
	uploadId: yup.string().nullable(true),
	generateId: yup.string().nullable(true),
	pairId: yup.string().nullable(true),
	uploadTime: yup
		.string()
		.nullable(true)
		.default(null)
		.label('Time of Upload')
		.required('Select Time of Upload.')
		.test({
			name: 'uploadTime-custom-validation',
			test: (value, context) => {
				let testResult: boolean | ValidationError = true;
				//-------------Time Interval Validation-------------
				const generationTime = context.parent.generationTime;
				const timeOfUpload = value;
				if (!checkTimeStampInterval(generationTime, timeOfUpload))
					testResult = context.createError({
						message:
							'Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
					});

				//-------------Unique Validation-------------
				//converted as 'FixMeLater' because I need to use the 'from' variable
				//updating yup to latest version can access 'from' directly
				const ctx = context as FixMeLater;
				//'from' is an array holding all the parents schema and value
				if (ctx.from !== undefined) {
					//access the timestamps array values
					const timestamps = ctx.from[1].value.schedule;

					//get the index of the current iteration while validating each item in the array
					//other item in the array will be validated as well but whatever the result is ignored
					const idx = ctx.options.index as number;
					//remove the current item to avoid self comparing later
					const timestampsFiltered = timestamps?.filter(
						(item, index) => index !== idx
					);
					//transform array to contains array of codes only
					const codesArray = timestampsFiltered?.map((item) => item.uploadTime);
					//check if current value already exist
					if (codesArray?.includes(value as string)) {
						testResult = context.createError({
							message: 'Selected time already used.',
						});
					}
				}

				return testResult;
			},
		}),
	generationTime: yup
		.string()
		.nullable(true)
		.default(null)
		.label('Generation Time')
		.required('Select Generation Time.')
		.when('uploadTime', (uploadTime, s) => {
			return s.test(
				'generationTime-custom-validation',
				'Generation Time and Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
				(value) => checkTimeStampInterval(value, uploadTime)
			);
		})
		.test({
			name: 'generationTime-custom-validation',
			test: (value, context) => {
				let testResult: boolean | ValidationError = true;

				//-------------Unique Validation-------------
				//converted as 'FixMeLater' because I need to use the 'from' variable
				//updating yup to latest version can access 'from' directly
				const ctx = context as FixMeLater;
				//'from' is an array holding all the parents schema and value
				if (ctx.from !== undefined) {
					//access the timestamps array values
					const timestamps = ctx.from[1].value.schedule;
					//get the index of the current iteration while validating each item in the array
					//other item in the array will be validated as well but whatever the result is ignored
					const idx = ctx.options.index as number;
					//remove the current item to avoid self comparing later
					const timestampsFiltered = timestamps?.filter(
						(item, index) => index !== idx
					);
					//transform array to contains array of codes only
					const codesArray = timestampsFiltered?.map(
						(item) => item.generationTime
					);
					//check if current value already exist
					if (codesArray?.includes(value as string)) {
						testResult = context.createError({
							message: 'Selected time already used.',
						});
					}
				}

				return testResult;
			},
		}),
});

const wrapScheduleSchema = new YupLab(scheduleSchema);

// const defaultscheduleValue =
// 	wrapScheduleSchema.objectSchema.getDefaultFromShape();

const wrapScheduleSchemaArray = new YupLab(
	yup
		.array()
		.of(scheduleSchema)
		.test('no-duplicates', 'Duplicate objects found', function (values: any) {
			if (values) {
				const seenGenerationTime = new Map();
				const seenUploadTime = new Map();
				const keys = ['uploadTime', 'generationTime'];

				const errors = values
					.map((value, index) => {
						return keys
							.map((key) => {
								const item = values[index];
								const generationTime = item.generationTime;
								const uploadTime = item.uploadTime;

								if (key === 'generationTime') {
									if (seenGenerationTime.has(generationTime)) {
										return new ValidationError(
											`Selected time already used.`,
											value.generationTime,
											`${this.path}[${index}].generationTime`
										);
									} else {
										seenGenerationTime.set(generationTime, index);
									}
								}

								if (key === 'uploadTime') {
									if (seenUploadTime.has(uploadTime)) {
										return new ValidationError(
											`Selected time already used.`,
											value.generationTime,
											`${this.path}[${index}].uploadTime`
										);
									} else {
										seenUploadTime.set(uploadTime, index);
									}
								}
							})
							.filter(Boolean);
					})
					.filter(Boolean)
					.flat();

				if (errors.length === 0) {
					return true;
				}

				return new ValidationError(errors);
			}
			return true;
		})
);

// const uploadConfiguration = yup.object({
// 	filenameFormat,
// 	uploadSchedule,
// 	uploadFrequency,
// 	timestamps: yup
// 		.array()
// 		.of(
// 			yup.object({
// 				coveredSchedule: yup
// 					.string()
// 					.label('Covered Schedule')
// 					.required(selectDefaultRequiredTemplate),
// 			})
// 		)
// 		.min(1),
// });

// const csrConfiguration = uploadConfiguration.shape({
// 	columns: yup.array().of(csrColumnSchema),
// });

// const baseSchema = yup.object({
// 	csrConfiguration,
// 	uploadConfiguration,
// });

export const csrConfigSchema = yup.object({
	filenameFormat: yup
		.string()
		.label('File Name Format')
		// .oneOf(FILE_FORMATS)
		.required('Select File Name Format.'),
	uploadFrequency: yup
		.number()
		.label('Upload Frequency')
		.typeError('Input Upload Frequency.')
		.min(1, 'Input numeric value from 1-25.')
		.max(25, ({ max }) => `Input numeric value from 1-25.`)
		.required('Input Upload Frequency.')
		.default(1),
	schedule: wrapScheduleSchemaArray.schema,
	uploadSchedule: yup
		.string()
		.label('Upload Schedule')
		// .oneOf(['SAME', 'NEXT'])
		.required('Select Upload Schedule.'),
	csrColumns: yup.array().default([]),
});

const wrapCsrConfigSchema = new YupLab(csrConfigSchema);

// const sftpSchema = yup.object({
// 	baseInfo: transferProtocolInformation,
// 	csrConfiguration,
// 	uploadConfiguration,
// });

// const smtpSchema = baseSchema.shape({
// 	senderEmail: yup.string().label('Sender Email Address').email(),
// 	receiverEmail: multipleEmailSchema.label('Receiver Email Address'),
// });

export const sftpSchema = yup.object({
	host: yup
		.string()
		.max(DEFAULT_MAX_CHARS, MAX_CHAR_255_MSG)
		.nullable(true)
		.required('Input Host.')
		.label('Host'),
	port: yup
		.string()
		.max(DEFAULT_MAX_CHARS, MAX_CHAR_255_MSG)
		.nullable(true)
		.required('Input Port.')
		.label('Port'),
	username: yup
		.string()
		.max(DEFAULT_MAX_CHARS, MAX_CHAR_255_MSG)
		.nullable(true)
		.required('Input Username.')
		.label('Username'),
	password: yup
		.string()
		.max(DEFAULT_MAX_CHARS, MAX_CHAR_255_MSG)
		.nullable(true)
		.required('Input Password.')
		.label('Password'),
	path: yup
		.string()
		.max(DEFAULT_MAX_CHARS, MAX_CHAR_255_MSG)
		.nullable(true)
		.required('Input Path.')
		.label('Path'),
	csrConfig: wrapCsrConfigSchema.schema,
	uploadConfig: wrapCsrConfigSchema.schema,
});

export const smtpSchema = yup.object({
	csrConfig: wrapCsrConfigSchema.schema,
	sender: multipleEmailSchema
		.label('Sender Email Address')
		.default(['online@bayad.com']),
	receivers: multipleEmailSchema.label('Recipient Email Address'),
	uploadConfig: wrapCsrConfigSchema.schema,
});

export const schema = yup.object({});

export const reportsSchema = yup.object({
	sftp: sftpSchema,
	smtp: smtpSchema,
});

export const reportsDefaultValue = reportsSchema.getDefault();

export const wrapReportsSchema = new YupLab(reportsSchema);
export type ReportsSchemaType = ReturnType<
	typeof wrapReportsSchema.schema.cast
>;
