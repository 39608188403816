import { ExpandableButton } from './ExpandableButton';
import useOpenController from './useOpenController';
import ExpandedRow from './ExpandedRow';

const ExpandedSection = (props) => {
	const { isOpen, toggle } = useOpenController(false);
	return (
		<>
			<tbody>
				<tr>
					<td>
						<ExpandableButton isOpen={props.isOpen ?? isOpen} toggle={props.toggle ?? toggle} />
					</td>
				</tr>
			</tbody>
			<br />
			<div>
				{(props.isOpen ?? isOpen) && (
					<ExpandedRow
						checked={props.checked}
						label={props.label}
						disabled={props.disabled}
						subRow={props.subRow}
						onChange={props.onChange}
						{...props}
					/>
				)}
			</div>
		</>
	);
};

export default ExpandedSection;