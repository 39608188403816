import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { useBarangayQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';

type BarangaySelectProps = LocationSelectProps & {
	cityId?: string;
	isFromChannel?: boolean;
};

const BarangaySelect: React.FC<BarangaySelectProps> = ({
	control,
	name,
	label = 'Barangay',
	cityId,
	disabled,
	onChange,
	isFromChannel = false,
	defaultValue,
	...rest
}) => {
	const { data, isLoading } = useBarangayQuery(
		'barangays',
		{ cityId },
		{ enabled: !!cityId }
	);

	const options = useMemo(() => {
		return (
			data?.map(({ id: id, name: label, city_id }) => ({
				label,
				value: id,
				city: {
					id: city_id,
				},
			})) || []
		);
	}, [data]);

	return (
		<SelectField
			label={label}
			control={control}
			getOptionValue={(v) => v.value}
			getComparator={({ value }, id) => value == id}
			name={isFromChannel ? `${name}.barangayId` : `${name}.barangay`}
			required
			isLoading={isLoading}
			options={options}
			disabled={disabled}
			onChange={(v: any) => {
				onChange(v, options);
			}}
			{...rest}
			defaultValue={defaultValue}
		/>
	);
};

export default BarangaySelect;
