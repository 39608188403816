import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import styles from './ReportForm.module.css';
import CheckboxGroup from 'components/Inputs/CheckboxGroup/CheckboxGroup';
import {
	Control,
	UseFormSetValue,
	useWatch,
	useFormContext,
	FormProvider,
	UseFormSetFocus,
	UseFormGetValues,
	UseFormReset,
	UseFormClearErrors,
	UseFormResetField,
	UseFormSetError,
} from 'react-hook-form';
import { EmailTagInput } from 'components/TagInput/TagInput';
import { handleArrayWithSpaceValues } from 'utils/filter';
import ReportGenerationSchedule from './ReportGenerationSchedule';
import DropdownCheckboxWithPill from 'components/Inputs/DropdownCheckboxWithPill/DropdownCheckboxWithPill';
import Section, { SectionRow } from 'components/Section/Section';
import { debounce } from 'lodash';

interface ReportFormProps {
	value?: string;
	control: Control<any>;
	contentClassName?: string;
	isEdit: boolean;
	setValue: UseFormSetValue<any>;
	data?: any;
	selectedPills?: Array<any>;
	referenceCheckedItems?: Array<any>;
	renderTag?: (tag: any, index: number) => ReactNode;
	resetField: UseFormResetField<any>;
	clearErrors: UseFormClearErrors<any>;
	setError: UseFormSetError<any>;
	getValues: UseFormGetValues<any>;
	isValid: boolean;
	onIsValidChange: any;
	error: any;
	trigger: any;
}
type FrequencyTypeOption = 'DAILY' | 'MONTHLY' | 'YEARLY';
export type ReportsMaintenanceFormData = {
	frequencyType: FrequencyTypeOption;
};
type SelectOptions = {
	label: string;
	value: any;
}[];
type ModeOption = {
	label: string;
	value: string;
	className: string;
	isHidden?: boolean;
};
const ReportForm: React.FC<ReportFormProps> = ({
	contentClassName,
	setValue,
	control,
	isEdit,
	data,
	resetField,
	setError,
	onIsValidChange,
	error,
	getValues,
	trigger,
	clearErrors,
	value,
}) => {
	const FileType = [
		{
			label: 'CSV',
			value: 'CSV',
		},
		{
			label: 'XLSX',
			value: 'XLSX',
		},
		{
			label: 'XLS',
			value: 'XLS',
		},
	];

	const mode_of_transmission: ModeOption[] = [
		{
			label: 'In Portal',
			value: 'IN_PORTAL',
			className: cx(styles.inPortal),
		},
		{
			label: 'Email',
			value: 'EMAIL',
			className: cx(styles.email),
		},
	];
	const businessReportCategory: SelectOptions = [
		{
			label: 'Product',
			value: 'Product',
		},
		{
			label: 'Channel',
			value: 'Channel',
		},
		{
			label: 'Reconciliation',
			value: 'Reconciliation',
		},
		{
			label: 'Finance',
			value: 'Finance',
		},
		{
			label: 'Accounting',
			value: 'Accounting',
		},
		{
			label: 'RPA',
			value: 'RPA',
		},
		{
			label: 'Other Reports',
			value: 'Other Reports',
		},
	];
	const reportDetailsForm = useFormContext();
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [dateTimePart, setDateTimePart] = useState('');
	const [nameOfReport, setNameOfReport] = useState('');
	const [changed, setChanged] = useState<boolean>(false);
	const Row: React.FC<{ gutters?: boolean }> = ({
		gutters,
		children,
		...rest
	}) => (
		<Grid
			container
			gutters={gutters}
			className={styles.vspaced}
			{...rest}
			size={3}
			of={3}
		>
			{children}
		</Grid>
	);

	const frequencyType = data?.reportSchedule?.frequencyType.toUpperCase();
	const frequency = data?.reportSchedule?.frequency;

	const reportSchedule = useMemo(() => {
		const mappedReportScheduleTime =
			data?.reportSchedule?.reportScheduleTime?.map((item) => {
				const { time, dayOfMonth, monthOfYear } = item;

				return {
					time: time || null,
					dayOfMonth: dayOfMonth || null,
					monthOfYear: monthOfYear || null,
				};
			});
		return {
			frequencyType: frequencyType,
			frequency: frequency,
			reportScheduleTime: mappedReportScheduleTime,
		};
	}, [frequency, frequencyType]);

	useEffect(() => {
		setValue('reportSchedule', reportSchedule);
	}, [reportSchedule, setValue]);

	const iWatchModeOfTransmission = useWatch({
		control,
		name: `modeOfTransmission`,
	});
	const iWatchEmailRecipients = useWatch({
		control,
		name: `emailRecipients`,
	});

	const emailRecipientsErrors = error;
	const hasEmailRecipientsError =
		emailRecipientsErrors && Object.keys(emailRecipientsErrors).length === 0;
	const errorType = error?.emailRecipients?.type;
	const handleModeOfTransmitChange = () => {
		if (
			iWatchModeOfTransmission?.length === 1 &&
			iWatchModeOfTransmission?.includes('IN_PORTAL')
		) {
			resetField('emailRecipients');
			onIsValidChange(true);
			setValue('emailRecipients', [], {
				shouldValidate: false,
				shouldDirty: false,
			});
		}
		if (
			iWatchModeOfTransmission?.length > 1 &&
			iWatchModeOfTransmission?.includes('EMAIL') &&
			iWatchEmailRecipients?.length === 0
		) {
			setError('emailRecipients', {
				type: 'min',
				message: 'Input Email Recipients',
			});
			onIsValidChange(false);
		}
	};

	const handleEmailRecipientsChange = useCallback(() => {
		if (
			hasEmailRecipientsError &&
			iWatchModeOfTransmission?.length > 0 &&
			iWatchModeOfTransmission?.includes('EMAIL') &&
			errorType !== 'isValidEmail' &&
			errorType !== 'min'
		) {
			onIsValidChange(true);
		} else if (
			iWatchModeOfTransmission?.length > 1 &&
			iWatchModeOfTransmission?.includes('EMAIL') &&
			iWatchEmailRecipients?.length === 0
		) {
			onIsValidChange(false);
		}
	}, [
		errorType,
		hasEmailRecipientsError,
		iWatchEmailRecipients?.length,
		iWatchModeOfTransmission,
		onIsValidChange,
	]);
	useEffect(() => {
		handleEmailRecipientsChange();
	}, [handleEmailRecipientsChange, iWatchEmailRecipients]);

	useEffect(() => {
		const modeOfTransmission = data?.modeOfTransmission?.map((mode) =>
			mode.toUpperCase()
		);
		const uniqueModes = Array.from(new Set(modeOfTransmission));
		setValue('modeOfTransmission', uniqueModes);

		if (uniqueModes.length === 1) {
			if (uniqueModes[0] === 'IN_PORTAL') {
				setValue('emailRecipients', []);
			} else if (uniqueModes[0] === 'EMAIL') {
				setValue('modeOfTransmission', ['EMAIL', 'IN_PORTAL']);
			}
		}
	}, [data?.modeOfTransmission, setValue]);

	useEffect(() => {
		setValue('reportName', data?.reportName);
	}, [data?.reportName, setValue]);
	useEffect(() => {
		const reportName = data?.reportName;
		const fileNameFormat = data?.fileNameFormat;
		let firstPart = '';
		let secondPart = '';
		let newFileNameFormat = '';

		if (fileNameFormat) {
			const separatorIndex = fileNameFormat.indexOf(reportName);
			if (separatorIndex !== -1) {
				firstPart = fileNameFormat.substring(0, separatorIndex).trim();
				secondPart = fileNameFormat
					.substring(separatorIndex + reportName.length)
					.trim();

				const regex = /<([^>]+)>/g;

				const matchesInFirstPart = firstPart.match(regex);
				const matchesInSecondPart = secondPart.match(regex);

				if (matchesInFirstPart && changed) {
					setDateTimePart(firstPart);
					newFileNameFormat = `${dateTimePart}${nameOfReport}`;
				}

				if (matchesInSecondPart && changed) {
					setDateTimePart(secondPart);
					newFileNameFormat = `${nameOfReport}${dateTimePart}`;
				}
				if (changed) {
					setValue('fileNameFormat', newFileNameFormat);
				} else {
					setValue('fileNameFormat', data?.fileNameFormat);
				}
			} else {
				setValue('fileNameFormat', data?.fileNameFormat);
			}
		}
	}, [changed, data, dateTimePart, nameOfReport, setValue]);

	const handleReportNameChange = (e) => {
		const newReportName = e.target.value;
		setNameOfReport(newReportName);
		setChanged(true);
	};

	useEffect(() => {
		const valuesToSet: number[] = [];

		if (selectedItems?.includes('Product')) valuesToSet.push(1);
		if (selectedItems?.includes('Channel')) valuesToSet.push(2);
		if (selectedItems?.includes('Reconciliation')) valuesToSet.push(3);
		if (selectedItems?.includes('Finance')) valuesToSet.push(4);
		if (selectedItems?.includes('Accounting')) valuesToSet.push(5);
		if (selectedItems?.includes('RPA')) valuesToSet.push(6);
		if (selectedItems?.includes('Other Reports')) valuesToSet.push(7);

		setValue(
			'businessReportCategory',
			valuesToSet.length > 0 ? valuesToSet : null
		);
	}, [selectedItems, setValue]);
	useEffect(() => {
		const defaultValue = data?.businessReportCategory;
		const valuesToSet: string[] = [];

		if (defaultValue?.includes(1)) valuesToSet.push('Product');
		if (defaultValue?.includes(2)) valuesToSet.push('Channel');
		if (defaultValue?.includes(3)) valuesToSet.push('Reconciliation');
		if (defaultValue?.includes(4)) valuesToSet.push('Finance');
		if (defaultValue?.includes(5)) valuesToSet.push('Accounting');
		if (defaultValue?.includes(6)) valuesToSet.push('RPA');
		if (defaultValue?.includes(7)) valuesToSet.push('Other Reports');

		setSelectedItems(valuesToSet.length > 0 ? valuesToSet : []);
	}, [data?.businessReportCategory, setSelectedItems, setValue]);

	const handleCheckedItemsChange = (items: Array<any>) => {
		setSelectedItems(items);
	};
	const taggedOptions = useMemo(() => {
		return mode_of_transmission.map((option) => {
			if (option.label === 'In Portal') {
				return {
					...option,
					disabled: true,
				};
			}
			return option;
		});
	}, [mode_of_transmission]);

	return (
		<>
			<div className={cx(styles.content, contentClassName)}>
				<Section>
					<SectionRow verticalAlign="start">
						<Grid column size={1} of={1}>
							<TextField
								control={control}
								name="reportName"
								label="Report Name"
								disabled={!isEdit}
								required
								onChange={(e) => {
									handleReportNameChange(e);
								}}
							/>
						</Grid>
						<Grid column size={1} of={1}>
							<TextField
								name="fileNameFormat"
								control={control}
								label="File Name Format"
								disabled={true}
								multiLine
								// value={setValue}
							/>
						</Grid>
					</SectionRow>

					<SectionRow container verticalAlign="start">
						<Grid column size={1} of={1}>
							<SelectField
								label="File Type"
								name="fileType"
								control={control}
								placeholder={data?.fileType}
								options={FileType}
								disabled={true}
								defaultValue={data?.fileType}
							/>
						</Grid>
						<Row gutters>
							<Grid column size={1} of={1}>
								<DropdownCheckboxWithPill
									label="Business Report Category"
									control={control}
									name="businessReportCategory"
									placeholder={'Select Business Report Category'}
									options={businessReportCategory}
									disabled={!isEdit}
									setReferenceCheckedItems={handleCheckedItemsChange}
									referenceCheckedItems={selectedItems}
								/>
							</Grid>
						</Row>
						<Grid column size={1} of={1} className={styles.modeOftransmission}>
							<CheckboxGroup
								showLabel={true}
								parentName={'report_maintenance'}
								name="modeOfTransmission"
								label="Mode of Transmission"
								control={control}
								options={taggedOptions}
								variant="gray"
								fitHorizontally={true}
								setValue={setValue}
								onChange={handleModeOfTransmitChange}
								disabled={!isEdit}
							/>
						</Grid>
					</SectionRow>

					<SectionRow verticalAlign="end">
						<Grid column size={3} of={5}>
							{iWatchModeOfTransmission?.includes('EMAIL') && (
								<EmailTagInput
									required
									label="Email Recipients"
									control={control}
									name="emailRecipients"
									disabled={!isEdit}
									defaultValue={data?.emailRecipients}
								/>
							)}
						</Grid>
					</SectionRow>
				</Section>
				<br />
				<hr className={styles.divider} />
				<Section className={styles.sectionContent}>
					<div className={styles.sectionTitle}>Report Generation Schedule</div>
					<FormProvider {...reportDetailsForm}>
						<ReportGenerationSchedule
							setValue={setValue}
							getValues={getValues}
							data={data}
							name="reportSchedule"
							isEdit={isEdit}
							control={control}
							trigger={trigger}
							clearErrors={clearErrors}
						/>
					</FormProvider>
				</Section>
			</div>
		</>
	);
};

export default ReportForm;
