import styles from "./RightPane.module.css";
import laptop from "../../assets/images/login/img-laptop.png";
import React, {Component} from "react";

class RightPane extends Component {
    render() {
        return(
            <div style={{padding: "25px"}}>
                <div className="slds-align_absolute-center slds-m-bottom_x-large">
                    <img className={styles.imgHardLight} src={laptop} alt="Laptop"/>
                </div>
                <div style={{textAlign: "center", color: "#FFFFFF", margin: "0 15px 0 15px"}}>
                    <span className={styles.fontPoppins} style={{fontSize: "18px", display: "block", marginBottom: "15px"}}>All new Bayad</span>
                    <span className={styles.fontPoppinsLight} style={{fontSize: "12px", display: "block"}}>Were featuring complete access for all of your online transactions pay bills, send money & receive remittances anywhere & anywhere.</span>
                </div>
            </div>
        );
    }
}

export default RightPane;
