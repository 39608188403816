import {
	deleteLocalStorage,
	getLocalStorage,
	setLocalStorage,
} from './storage';

function localStorageUtilBuilder(key: string, defaultValue: any = undefined) {
	return {
		set: (value: any) => setLocalStorage(key, value),
		get: (dv?: any) =>
			getLocalStorage(key, dv !== undefined ? dv : defaultValue),
		delete: () => deleteLocalStorage(key),
	};
}

export const TokenUtil = localStorageUtilBuilder('token');
export const RefreshTokenUtil = localStorageUtilBuilder('refreshToken');
export const UserInfoUtil = localStorageUtilBuilder('userInfo', {});
