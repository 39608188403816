import CsrConfiguration from './CsrConfiguration';
import TransferProtocolInformation from './TransferProtocol';
import UploadConfiguration from './UploadConfiguration';

type Props = {
	disabled?: boolean;
};

const NAME = 'sftp' as const;

const SFTP: React.FC<Props> = ({ disabled }) => {
	return (
		<>
			<TransferProtocolInformation
				name={NAME}
				title="SFTP/FTP"
				disabled={disabled}
			/>
			<CsrConfiguration name={NAME} disabled={disabled} />
			<UploadConfiguration
				name={`${NAME}.uploadConfiguration`}
				title="Upload Configuration"
				disabled={disabled}
			/>
		</>
	);
};

export default SFTP;
