import cx from 'classnames';
import { connect } from 'react-redux';
import styles from './BillerProfile.module.css';
import { useToggle } from '../../../utils/hooks';
import verifyPermission from '../../../utils/verifyPermission';
import BillerModal from '../../../components/BillerModal/BillerModal';
import { BillerFormProps } from 'components/BillerForm/BillerForm';
import BillerList from '../../../components/BillerList/BillerList';
import BillerTab from '../../../components/BillerList/BillerTab';

import { showAccessDeniedModal } from 'redux/modules/access';

const CREATE_NEW_BILLER_PERMISSION = {
	scope: 'bip.create.biller.info',
	resource: '/v1/biller',
};

interface Props {
	scopes: any;
	showAccessDeniedModal: () => void;
}

const BillerProfile: React.FC<Props> = ({ scopes, showAccessDeniedModal }) => {
	const {
		value: isAddBillerProfileModalShowing,
		valueOn: showAddBillerProfileModal,
		valueOff: hideAddBillerProfileModal,
	} = useToggle();
	const addBillerInitialValues: BillerFormProps['initialValues'] = {
		primaryInformation: {
			basicInformation: {},
			billerAddress: [
				{
					locationBase: 'LOCAL',
				},
			],
			billerContactDetails: [{ name: '', areaHandled: '' }],
			accountOfficer: {},
		},
	};

	const handleAddNewBillerOnClick = async () => {
		if (verifyPermission(scopes, CREATE_NEW_BILLER_PERMISSION)) {
			showAddBillerProfileModal();
		} else {
			showAccessDeniedModal();
		}
	};

	return (
		<>
			<div className={cx('slds-card', styles.paperContainer)}>
				<div className={cx(styles.title)}>Biller Profile</div>
				<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
					<BillerList addNewBillerButtonClick={handleAddNewBillerOnClick} />
					<BillerTab />
				</div>
			</div>
			<BillerModal
				open={isAddBillerProfileModalShowing}
				onClose={hideAddBillerProfileModal}
				initialValues={addBillerInitialValues}
			/>
		</>
	);
};

export default connect(
	(state: any) => ({
		scopes: state.userInfo.scopes,
	}),
	{ showAccessDeniedModal }
)(BillerProfile);
