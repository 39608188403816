import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { debounce as _debounce } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import LookupFieldWithExpanded from 'components/Inputs/LookupFieldWithExpanded';

import channelFilterSchema from 'utils/formSchemas/bfaAdminPortal/channelFilter';
import { Api3Status, BfaStatus, SettlementSetup } from 'utils/lookup';

import { ChannelFilterForm, ChannelHeaderProps } from './types';
import styles from './ChannelMgt.module.css';

const ChannelHeader: React.FC<ChannelHeaderProps> = ({
	handleFilterSubmit,
}) => {
	const initialValues = {
		api3Status: undefined,
		bfaStatus: undefined,
		channelCode: undefined,
		channelName: undefined,
		dateFrom: undefined,
		dateTo: undefined,
		settlementSetup: undefined,
	};
	const {
		resetField,
		getValues,
		handleSubmit,
		control,
		formState: { isValid, isDirty },
		reset,
		trigger,
		watch,
	} = useForm<ChannelFilterForm>({
		mode: 'all',
		resolver: yupResolver(channelFilterSchema),
		defaultValues: initialValues,
	});

	const handleClear = () => {
		reset();
		handleFilterSubmit({});
	};

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const allValues = getValues() || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...allValues, search: value });
			}
		}, 500),
		[isDirty, isValid]
	);

	// Retriggers the validation
	useEffect(() => {
		trigger('dateTo');
	}, [watch('dateFrom')]);

	useEffect(() => {
		trigger('dateFrom');
	}, [watch('dateTo')]);

	return (
		<>
			<Filter
				title="Channel List"
				padding={'1.2vmax 0.7vmax 1.2vmax 0.7vmax'}
				className={styles.filters}
			>
				<FilterOuter>
					<Grid container align="end">
						<Grid column size={5} of={12}>
							<InputWithIcon
								onChange={handleSearch}
								placeholder="Search"
								className={styles.searchBar}
								icons={[
									{
										path: 'utility/search',
										isLeft: true,
										className: styles.inputIcon,
									},
								]}
							/>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid
						container
						align="spread"
						verticalAlign="start"
						gutters="xx-small"
					>
						<Grid column size={1} of={8} className={styles.field}>
							<DatePickerField
								control={control}
								name="dateFrom"
								label="Date From"
								placeholder="Date From"
								className={styles.dateField}
							/>
						</Grid>
						<Grid column size={1} of={8} className={styles.field}>
							<DatePickerField
								control={control}
								name="dateTo"
								label="Date To"
								placeholder="Date To"
								className={styles.dateField}
							/>
						</Grid>
						<Grid column size={1} of={6} className={styles.field}>
							<LookupFieldWithExpanded
								name="channelName"
								control={control}
								displayName="Channel Account Name"
								apiRoute="/v2/bfa-admin-portal/utils/channel/name-list"
								className={styles.lookupTextField}
								labelClassName={styles.lookupTextLabel}
							/>
						</Grid>
						<Grid column size={1} of={7} className={styles.field}>
							<SelectField
								name="settlementSetup"
								label="Settlement Setup"
								control={control}
								options={SettlementSetup}
								triggerClassName={styles.selectField}
								labelClassName={styles.label}
								parseDefaultValue={false}
							/>
						</Grid>
						<Grid column size={1} of={7} className={styles.field}>
							<TextField
								name="channelCode"
								label="Channel Account Code"
								control={control}
								className={styles.textField}
								labelClassName={styles.label}
								onBlur={(e?: any) => {
									if (e?.target?.value === '') {
										resetField('channelCode');
										return;
									}
								}}
							/>
						</Grid>
						<Grid column size={1} of={8} className={styles.field}>
							<SelectField
								name="api3Status"
								label="API 3.0 Status"
								placeholder="API 3.0 Status"
								control={control}
								options={Api3Status}
								triggerClassName={styles.selectField}
								labelClassName={styles.label}
								parseDefaultValue={false}
							/>
						</Grid>
						<Grid column size={1} of={8} className={styles.field}>
							<SelectField
								name="bfaStatus"
								label="BFA Status"
								placeholder="BFA Status"
								control={control}
								options={BfaStatus}
								triggerClassName={styles.selectField}
								labelClassName={styles.label}
								parseDefaultValue={false}
							/>
						</Grid>
						<Grid column size={1} of={12} className={styles.button}>
							<OutlineButton
								fullWidth
								onClick={handleClear}
								disabled={!isDirty}
								className={styles.buttons}
							>
								Clear All Filters
							</OutlineButton>
						</Grid>
						<Grid column size={1} of={12} className={styles.button}>
							<PrimaryButton
								disabled={!isValid || !isDirty}
								onClick={handleSubmit((filter = {}) =>
									handleFilterSubmit(filter)
								)}
								fullWidth
								className={styles.buttons}
							>
								Filter
							</PrimaryButton>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
		</>
	);
};

export default ChannelHeader;
