import cx from 'classnames';
import styles from './style.module.css';

const Headers: React.FC = () => {
	return (
		<div className={cx(styles.headerContainer, styles.bold)}>
			<div>CIS Bayad Center, Inc.</div>
			<div>Channel Collection Summary Report</div>
		</div>
	);
};

export default Headers;
