import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo, useState } from 'react';
import { useZipCodeQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';
import cx from 'classnames';
import { useController } from 'react-hook-form';
import { isEmpty } from 'lodash';

type ZipCodeSelectProps = LocationSelectProps & {
	barangayId: string;
	required?: boolean;
};

const ZipCodeSelect: React.FC<ZipCodeSelectProps> = ({
	control,
	name,
	label = 'Zip Code',
	barangayId,
	disabled,
	onChange,
	defaultValue,
	...rest
}) => {
	const [defaultLabel, setDefaultLabel] = useState<string | null>();
	const { data, isLoading } = useZipCodeQuery(
		'zipcodes',
		{ barangayId },
		{ enabled: !!barangayId }
	);

	const { field } = useController({
		name,
		control,
	});

	const zip_codes = useMemo(() => {
		const options =
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [];

		if (!isEmpty(options)) {
			setDefaultLabel(options[0] ? options[0].label : null);
			// don't set value if disabled (to prevent reloading of data)
			if (!disabled) {
				field && field.onChange(options[0]?.value);
				onChange && onChange(options[0]?.value, options);
			}
		}
		return options;
	}, [data]);

	return (
		<>
			<SelectField
				className={cx(
					!disabled && !isLoading && 'zipCodeSelect',
					!isLoading && 'noIcon'
				)}
				label={label}
				placeholder={"Zip Code"}
				name={name}
				control={control}
				disabled={true}
				defaultValue={defaultValue || zip_codes[0]?.value}
				isLoading={isLoading}
				options={zip_codes}
				{...rest}
			/>
		</>
	);
};

export default ZipCodeSelect;
