import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { startCase } from 'lodash';

import { Modal } from '@salesforce/design-system-react';
import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';

import {
	AuditTrailResp,
	TransactionAuditTrailProp,
	Details,
	AuditTrailData,
} from '../types';
import styles from './TransactionAuditTrail.module.css';

const TransactionAuditTrail: React.FC<TransactionAuditTrailProp> = ({
	title,
	columns,
	isOpen,
	onClose,
}) => {
	const [data, setData] = useState<AuditTrailData>();

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const DisplayNames: { [key in keyof Details]?: string } = {
		partnerName: 'Partner Name',
		productType: 'Product Type',
		channelPartner: 'Channel Partner',
		branchName: 'Branch Name',
		tpaid: 'TPAID',
		status: 'API 3.0 Logs',
		bfaStatus: 'BFA Status',
	};

	const formatOldValues = (oldValues: Details): string => {
		const { dateFrom = '', dateTo = '' } = oldValues;

		const formattedLines = Object.entries(oldValues)
			.filter(([key, value]) => value && DisplayNames[key as keyof Details])
			.map(
				([key, value]) =>
					`${DisplayNames[key as keyof Details]}: ${
						key === 'tpaid' ? value : startCase(value?.toLowerCase())
					}`
			);

		const dateRange =
			dateFrom || dateTo
				? `${moment(dateFrom).format('MM/DD/YYYY') || ''} - ${
						moment(dateTo).format('MM/DD/YYYY') || ''
				  }`
				: '';

		return dateFrom || dateTo
			? `${dateRange}\n${formattedLines.join('\n')}`
			: formattedLines.join('\n');
	};

	const fetchAuditTrail = async (params?: AuditTrailResp) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const result: AuditTrailResp = await client.get(
				'/v2/bfa-admin-portal/transactions/audit_trail',
				{ params }
			);

			const formattedData = result?.data?.data?.map((item) => {
				let formattedDetails = '';

				if (typeof item?.details === 'string' && item?.details !== null) {
					formattedDetails = formatOldValues(JSON.parse(item?.details));
				}

				return {
					...item,
					details: formattedDetails,
				};
			});

			setData({
				data: formattedData,
				meta: result?.data?.meta,
			});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchAuditTrail(params)
			);
		} finally {
			hideLoading();
		}
	};

	const tablePaginationProps = useTablePagination(() => {
		fetchAuditTrail().then(() => {
			// Handle successful resolution
		}).catch(() => {
			// Handle errors
		});}, data?.meta);

	useEffect(() => {
		fetchAuditTrail();
	}, []);

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<Modal
				isOpen={isOpen}
				ariaHideApp={false}
				size="medium"
				onRequestClose={onClose}
			>
				{title && <div className={cx(styles.title)}>{title}</div>}
				<Table
					columns={columns}
					data={data?.data}
					isNotEvenRowColor={false}
					preHeader={null}
					modalIsOpen={false}
					scrollable={true}
					hasDetails={true}
					{...tablePaginationProps}
				/>
			</Modal>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</>
	);
};

export default TransactionAuditTrail;
