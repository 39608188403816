import SelectField from 'components/Inputs/SelectField/SelectField';
import cx from 'classnames';
import styles from './BillerStatusSelect.module.css';
import { useMemo } from 'react';
import { TBillerAddress } from '../Tabs/PrimaryInformation/sections/BillerAddress/BillerAddress';

type Props = {
	labelPrefix?: string;
	value?: TBillerStatus;
	disabled?: boolean;
};

export type TBillerStatus = 'Engaged' | 'Active' | 'Suspended' | 'Deactivated';

type StatusOption = {
	label: TBillerStatus;
	value: TBillerStatus;
	className: string;
};

const options: StatusOption[] = [
	{
		name: 'Engaged',
		className: styles.engaged,
	},
	{
		name: 'Active',
		className: styles.active,
	},
	{
		name: 'Suspended',
		className: styles.suspended,
	},
	{
		name: 'Deactivated',
		className: styles.deactivated,
	},
].map(({ name, className }) => ({
	label: name as TBillerStatus,
	value: name as TBillerStatus,
	className: cx(styles.option, className),
}));

const BillerStatusSelect: React.FC<Props> = ({
	value,
	labelPrefix = '',
	disabled,
}) => {
	const match = useMemo<undefined | any>(() => {
		return value && options.find(({ value: v }) => value === v);
	}, [value]);
	return (
		<SelectField
			className={cx(styles.select, match ? match.className : '')}
			labelPrefix={`${labelPrefix} - `}
			value={value}
			defaultValue={value as any}
			options={options}
			size="x-small"
			disabled={disabled}
		/>
	);
};

export default BillerStatusSelect;
