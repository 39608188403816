import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import SummaryCards from 'components/SummaryCards';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import WarningModal from 'components/Modal/WarningModal';
import FileTypeSelectModal from 'components/Modal/FileTypeSelectModal';

import { ReactComponent as TotalTransIcon } from 'assets/icons/ic-card-white.svg';
import { ReactComponent as TotalCollectionIcon } from 'assets/icons/ic-transaction-white.svg';

import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { formatCash, formatParams } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import client from 'helpers/ApiClient';

import TransactionTable from './TransactionTable';
import TransactionHeader from './TransactionHeader';

import {
	TransactionListData,
	TransactionListResp,
	TransactionSummaryResp,
	TransactionFilterOptions,
	TransactionSummary,
	TransactionDownloadResp,
} from './types';
import styles from './TransactionMgt.module.css';

const SUMMARY_DATA = ({
	totalTransactionVolume,
	totalCollection,
}: TransactionSummary) => [
	{
		label: 'Total Transaction Volume *',
		value: totalTransactionVolume.toLocaleString(),
		icon: (
			<div className={styles.totalTransIcon}>
				<TotalTransIcon />
			</div>
		),
		color: '#BC224C',
	},
	{
		label: 'Total Collection *',
		value: formatCash(totalCollection),
		icon: (
			<div className={styles.totalCollectionIcon}>
				<TotalCollectionIcon />
			</div>
		),
		color: '#4CB483',
	},
];

const TransactionMgt: React.FC = () => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<TransactionListData>();
	const [summaryData, setSummaryData] = useState<TransactionSummary>();
	const [filter, setFilter] = useState<TransactionFilterOptions>({});
	const [errorMsg, setErrorMsg] = useState("");
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isWarningModalOpen,
		valueOn: showWarningModal,
		valueOff: hideWarningModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const fetchTransactionList = async (params?: TransactionFilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const response: TransactionListResp = await client.get(
				'/v2/bfa-admin-portal/transactions',
				{ params }
			);

			setData(response?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchTransactionList(params)
			);
		} finally {
			hideLoading();
		}
	};

	const handleDownload = async () => {
		
		const { page, limit, ...downloadParams } = filter;

		if (isEmpty(downloadParams)) {
			setErrorMsg('Kindly select filter to download the transaction list.');
			showWarningModal();
			return;
		}
		if (data?.meta?.total && data?.meta?.total > 1048000){
			setErrorMsg("Data size selected exceeded the max size limit of 1,048,000 rows. Kindly select or filter a smaller range of data to be able to download the Transaction list.")
			showWarningModal();
			return;
		} 
		showLoadingMessage();
		hideErrorModal();

		try {
			const response: TransactionDownloadResp = await client.get(
				'/v2/bfa-admin-portal/transactions/download',
				{ params: downloadParams }
			);
			if (response.status === 200) {
				showConfirmationModal();
			}
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => handleDownload()
			);
		} finally {
			hideLoading();
		}
	};

	const handleFilterSubmit = async (params: TransactionFilterOptions) => {
		const { limit, sort, sortBy } = filter;
		const newFilter = formatParams({ limit, sort, sortBy, ...params });
		setFilter(newFilter);
		fetchTransactionList(newFilter);
	};

	const handleTableFetch = (params: TransactionFilterOptions) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchTransactionList(newFilter);
	};

	const fetchTransactionOnLoad = async () => {
		hideErrorModal();
		showLoadingMessage();
		try {
			const [summaryResp, listResp]: [
				TransactionSummaryResp,
				TransactionListResp
			] = await Promise.all([
				client.get('/v2/bfa-admin-portal/transactions/summary'),
				client.get('/v2/bfa-admin-portal/transactions'),
			]);
			setData(listResp?.data);
			setSummaryData(summaryResp?.data?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchTransactionOnLoad()
			);
		} finally {
			hideLoading();
		}
	};

	useEffect(() => {
		if (hasUserPermission('list.transaction')) {
			fetchTransactionOnLoad();
		}
	}, []);

	return (
		<>
			{summaryData && (
				<SummaryCards
					data={SUMMARY_DATA(summaryData as TransactionSummary)}
					footer={`* YTD as of ${String(moment().format('MMMM D, YYYY'))}`}
				/>
			)}
			<TransactionHeader
				handleFilterSubmit={handleFilterSubmit}
				onDownload={handleDownload}
			/>
			<TransactionTable data={data} handleTableFetch={handleTableFetch} />
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<WarningModal
				isOpen={isWarningModalOpen}
				onClose={hideWarningModal}
				message={errorMsg}
			/>
			<FileTypeSelectModal
				open={isConfirmationModalOpen}
				onClose={hideConfirmationModal}
				confirmationMsg="Transaction List will now be generated and sent to your email. Kindly refresh your inbox or wait for a few minutes in case no email received."
			/>
		</>
	);
};

export default TransactionMgt;
