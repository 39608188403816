import { Modal } from '@salesforce/design-system-react/module/components';
import BillerStatusSelect from 'components/BillerForm/BillerStatusSelect/BillerStatusSelect';
import Grid from 'components/Grid/Grid';
import React, { useEffect, useState } from 'react';
import BillerForm, {
	BillerFormProps,
	TabStatus,
} from '../BillerForm/BillerForm';
import PrimaryButton from '../Buttons/PrimaryButton';
import OutlineButton from '../Buttons/OutlineButton';
import styles from './BillerModal.module.css';
import HTTP from 'helpers/ApiClient';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { PrimaryInformationFormData } from 'components/BillerForm/Tabs/PrimaryInformation/PrimaryInformation';
import { FixMeLater } from 'types';
import { useToggle } from 'utils/hooks';
import { submit as submitAC, submitBU } from 'redux/modules/form';
import { setCurrentBillerTab } from 'redux/modules/billerList';

import { BarangayOptionValue } from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/BillerAddress';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { Country } from 'utils/queries/location';
import { connect } from 'react-redux';
import { updateBillerUpload } from '../../redux/modules/form';
import { tabIndexNames } from '../../components/BillerForm/BillerForm';

type Props = {
	open?: boolean;
	onClose?: () => void;
	billerUpload: any;
	currentBillerTab: string;
	initialValues: BillerFormProps['initialValues'];
};
export type FormState = {
	isValid: boolean;
	isDirty: boolean;
};

type Action = { type: 'update'; payload: FormState };
export type Dispatch = (action: Action) => void;

const Footer: React.FC<{
	disableSave?: boolean;
	currentBillerTab: Props['currentBillerTab'];
	tabIndexNames;
	tabStatus;
}> = ({ disableSave, currentBillerTab, tabStatus }) => {
	const status: FixMeLater = useSelector<ReducerStateType>(
		(state) => state.form.status
	);

	const dispatch = useDispatch();

	const onClickBack = () => {
		const idx = tabIndexNames.findIndex((tab) => tab.name == currentBillerTab);
		const prevIdx = idx - 1;
		let tab: any;
		prevIdx < 0 ? (tab = tabIndexNames.length - 1) : (tab = prevIdx);

		dispatch(setCurrentBillerTab(tabIndexNames[tab].name));
	};

	const handleClick = () => {
		if (currentBillerTab === 'primaryInformation') {
			dispatch(submitAC());
			console.log('pi here');
			return;
		}
		if (currentBillerTab === 'serviceFeeSettings') {
			// dispatch(submitServiceFee());
			console.log('sf here');
			return;
		}

		if (currentBillerTab === 'billerUploadSettings') {
			dispatch(submitBU());
			console.log('bu here');
			return;
		}
	};

	const handleSubmit = () => {
		console.log(tabStatus);
	};

	const checkSubmit = () => {
		if (tabStatus) {
			if (
				tabStatus.primaryInformation.finished == true &&
				// tabStatus.serviceFeeSettings.finished == true &&
				tabStatus.billerUploadSettings.finished == true
			)
				return false;
		}
		return true;
	};
	return (
		<div className={styles.footer}>
			{currentBillerTab !== 'primaryInformation' && (
				<OutlineButton
					onClick={onClickBack}
					className={styles.backBtn}
					// disabled={currentBillerTab === 'primaryInformation'}
				>
					Back
				</OutlineButton>
			)}

			<PrimaryButton
				onClick={handleClick}
				disabled={disableSave}
				className={styles.btn}
			>
				Save
			</PrimaryButton>

			{currentBillerTab === 'billerUploadSettings' ? (
				<PrimaryButton
					onClick={handleSubmit}
					className={styles.btn}
					disabled={checkSubmit()}
				>
					Submit
				</PrimaryButton>
			) : (
				<PrimaryButton
					onClick={() => {}}
					className={styles.btn}
					disabled={!(status.isValid && !status.isDirty)}
				>
					Next
				</PrimaryButton>
			)}
		</div>
	);
};

const initialState = {
	isValid: false,
	isDirty: false,
};

export const BillerModalContext = React.createContext<{
	state: FormState;
	dispatch: Dispatch;
}>({ state: initialState, dispatch: () => {} });

export type SuccessResponse = {
	status: 'OK';
};
export type TimeoutResponse = {
	status: 'TIMEOUT';
};
export type FailedResponse = {
	status: 'FAILED';
	errors: string[];
};

export type PrimaryInfoSaveResponse =
	| SuccessResponse
	| FailedResponse
	| TimeoutResponse;

async function savePrimaryInformation(
	values: PrimaryInformationFormData,
	billerUpload
): Promise<PrimaryInfoSaveResponse> {
	const { username: userName } = JSON.parse(
		localStorage.getItem('userInfo') || ''
	);
	const {
		basicInformation: { logo, subBrands, website, ...restBasicInfo } = {},
		billerAddress,
		...rest
	} = values as PrimaryInformationFormData & { philippines: Country };

	const { objectKey = '', url = '', filename = '' } = logo || {};
	const body = {
		logo: { objectKey, url, filename },
		current_user: {
			userName,
		},
		basicInformation: {
			...restBasicInfo,
			subBrands: subBrands || '',
			website: website || '',
		},
		billerAddress: billerAddress?.map((address) => {
			if (address.locationBase === 'LOCAL') {
				const {
					barangayObj: { value: { zipCode: { id: zipCode } = {} } = {} } = {},
				} = address as unknown as {
					barangayObj: { value: BarangayOptionValue };
				};
				return {
					...address,
					zipCode: `${zipCode}`,
				};
			}

			return { ...address, zipCode: '' };
		}),
		...rest,
		billerUploadSettings: billerUpload,
	};

	try {
		await HTTP.post('/v1/biller', body);
		return {
			status: 'OK',
		};
	} catch (error: any) {
		if (!error.status && !error.response) {
			return {
				status: 'TIMEOUT',
			};
		}

		const response = error.response;

		if (response.status == 400) {
			return {
				status: 'FAILED',
				errors: response.data.errors || [],
			};
		}

		return {
			status: 'FAILED',
			errors: [],
		};
	}
}

const BillerModal: React.FC<Props> = ({
	open,
	onClose = () => {},
	initialValues,
	billerUpload,
	currentBillerTab,
}) => {
	const dispatch = useDispatch();
	const status: FixMeLater = useSelector<ReducerStateType>(
		(state) => state.form.status
	);
	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isTimeoutModalShowing,
		valueOn: showTimeoutModal,
		valueOff: hideTimeoutModal,
	} = useToggle();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const initTabStatus = {
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: true, finished: false },
		serviceFeeSettings: { disabled: false, finished: false },
		businessRules: { disabled: true, finished: false },
		billerUploadSettings: { disabled: false, finished: false },
	};

	const [tabStatus, setTabStatus] = useState<TabStatus | undefined>(
		initTabStatus
	);

	useEffect(() => {
		setTabStatus(initTabStatus);
	}, [open]);

	const handleSubmit = async (
		values: PrimaryInformationFormData
	): Promise<PrimaryInfoSaveResponse> => {
		try {
			showLoader();

			const response = await savePrimaryInformation(values, billerUpload);

			if (response.status === 'OK') {
				setTabStatus((prev) => ({
					...prev,
					primaryInformation: {
						...prev?.primaryInformation,
						finished: true,
					},
				}));
				showSuccessModal();
			} else if (response.status === 'TIMEOUT') {
				showTimeoutModal();
			}
			return response;
		} catch (e) {
			console.log(e);
			return {
				status: 'FAILED',
				errors: [],
			};
		} finally {
			hideLoader();
		}
	};

	const handleSaveTab = async (
		values: any
	): Promise<PrimaryInfoSaveResponse> => {
		try {
			showLoader();
			console.log('saveTab ' + currentBillerTab);
			console.log(values);

			const response: any = [];
			if (currentBillerTab === 'primaryInformation') {
				// response = await savePrimaryInformation(values, billerUpload);
				response.status = 'OK';
			}
			if (currentBillerTab === 'billerUploadSettings') {
				// response = await savePrimaryInformation(values, billerUpload);
				response.status = 'OK';
			}

			if (response.status === 'OK') {
				successTrigger();
			} else if (response.status === 'TIMEOUT') {
				showTimeoutModal();
			}
			return response;
		} catch (e) {
			console.log(e);
			return {
				status: 'FAILED',
				errors: [],
			};
		} finally {
			hideLoader();
		}
	};

	const handleConfirmClose = () => {
		hideConfirmModal();
		onClose();
	};

	const handleOnClose = () => {
		if (status.isDirty) {
			showConfirmModal();
			return;
		}

		onClose();
	};

	const handleRetry = () => {
		hideTimeoutModal();
		// dispatch(submitAC());
		if (currentBillerTab === 'primaryInformation') {
			dispatch(submitAC());
			console.log('pi here');
			return;
		}
		if (currentBillerTab === 'serviceFeeSettings') {
			// dispatch(submitServiceFee());
			console.log('sf here');
			return;
		}

		if (currentBillerTab === 'billerUploadSettings') {
			dispatch(submitBU());
			console.log('bu here');
			return;
		}
	};

	const successTrigger = () => {
		showSuccessModal();
		if (currentBillerTab === 'billerUploadSettings') {
			setTabStatus((prev) => ({
				...prev,
				billerUploadSettings: {
					...prev?.billerUploadSettings,
					finished: true,
				},
			}));
		}
	};

	const failTrigger = () => {
		if (currentBillerTab === 'billerUploadSettings') {
			setTabStatus((prev) => ({
				...prev,
				billerUploadSettings: {
					...prev?.billerUploadSettings,
					finished: false,
				},
			}));
		}
	};

	const idx = tabIndexNames.find((tab) => tab.name == currentBillerTab);

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={handleOnClose}
				headerClassName={styles.headerContainer}
				containerClassName={styles.modalContainer}
				contentClassName={styles.modal}
				size="medium"
				// dismissOnClickOutside={false}
				heading={
					<div className={styles.header}>
						<div className={styles.title}>Add New Biller</div>
						<Grid container gutters="xx-small">
							<Grid column>
								<BillerStatusSelect
									labelPrefix="In STG"
									value="Engaged"
									disabled
								/>
							</Grid>
							<Grid column>
								<BillerStatusSelect
									labelPrefix="In Prod"
									value="Engaged"
									disabled
								/>
							</Grid>
						</Grid>
					</div>
				}
				footer={
					<Footer
						disableSave={isLoading}
						currentBillerTab={currentBillerTab}
						tabIndexNames={tabIndexNames}
						tabStatus={tabStatus}
					/>
				}
			>
				<div className={styles.body}>
					<BillerForm
						initialValues={initialValues}
						contentClassName={styles.bodyContent}
						onSubmit={handleSubmit}
						onSave={handleSaveTab}
						tabStatus={tabStatus}
						showSucess={successTrigger}
						showFail={failTrigger}
					/>
				</div>
			</Modal>
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div>Updates on {idx?.label} are successfully saved!</div>
						{currentBillerTab === 'billerUploadSettings' ? (
							<div className={styles.subtext}>
								Once all details are provided, <br />
								you may click the <b> Submit </b>
								button
							</div>
						) : (
							<div className={styles.subtext}>
								You may click the <b>Next</b> button
							</div>
						)}
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={hideSuccessModal}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isTimeoutModalShowing} onClose={hideTimeoutModal}>
				<ErrorModalBody>
					<ErrorText>Timeout Error</ErrorText>
					<ErrorSubText>
						A problem occured while submitting the data please try again.
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton className={styles.retryBtn} onClick={handleRetry}>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			<ConfirmModal
				open={isConfirmModalShowing}
				disableClose={false}
				onClose={hideConfirmModal}
				headerText="Close Add New Biller"
				bodyText={[
					() => (
						<>
							<div>
								Are you sure you want to close <b>Add New Biller</b>?
							</div>
							<div>
								Your progress will not be saved if you wish to continue.
							</div>
						</>
					),
				]}
				confirmButton={{
					name: 'Confirm',
					event: handleConfirmClose,
				}}
				cancelButton={{
					name: 'Back',
					event: hideConfirmModal,
				}}
			/>
		</>
	);
};

export default connect(
	(state: any) => ({
		billerUpload: state.form.billerUploadValue,
		currentBillerTab: state.billers.currentBillerTab,
	}),
	{}
)(BillerModal);
