import logo from '../../assets/images/login/logo-bayadIP@2x.png';
import styles from './LeftPane1.module.css';
import React, { Component } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';

class LeftPane1 extends Component {
	render() {
		return (
			<div className="slds-p-around_xx-large">
				<div className="slds-align_absolute-center slds-m-bottom_xx-large">
					<img style={{ width: '70%' }} src={logo} alt="Bayad IP Logo" />
				</div>
				<div>
					<div style={{ textAlign: 'center', margin: '60px 0 35px 0' }}>
						<span
							className={styles.fontWavehaus}
							style={{ fontSize: '22px', color: '#0076C0' }}
						>
							Welcome KaBayad!
						</span>
					</div>
					<div>
						<PrimaryButton
							onClick={this.props.onLoginClick}
							className={'slds-button_stretch slds-m-bottom_small'}
						>
							Login with UAM
						</PrimaryButton>
						<span
							className={styles.fontPoppins}
							style={{ fontSize: '9px', display: 'block' }}
						>
							This site is protected by reCAPTCHA and the
							<a href="https://policies.google.com/privacy" target="_blank">
								<span style={{ color: '#0077C0' }}> Google Privacy Policy</span>
							</a>{' '}
							and
							<a href="https://policies.google.com/terms" target="_blank">
								<span style={{ color: '#0077C0' }}> Terms of Service</span>
							</a>{' '}
							apply.
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default LeftPane1;
