import { addThousandsSeparator } from 'components/Format/NumberFormatter';
import moment from 'moment';
import { FixMeLater, YupMaxMessage, YupMessage, YupTypeMessage } from 'types';
import { DATE_FORMAT_ISO_8601, formatDate } from 'utils/common';
import {
	validateContactNumber,
	validateEmail,
	validateLandlineNumber,
} from 'utils/validation';
import * as yup from 'yup';

yup.setLocale({
	mixed: {
		notType: ({ label = 'Field', type }: YupTypeMessage) =>
			`${label} must be a ${type}`,
		required: ({ label = 'value' }: YupMessage) => `Input ${label}`,
	},
	string: {
		max: ({ max }: YupMaxMessage) =>
			`You can only input a max of ${max} character${max === 1 ? '' : 's'}`,
	},
});

export const defaultMax255CharSpiel =
	'You can only input a max of 255 characters.';
export const defaultMax255AlphaSpiel =
	'You can only input a max of 255 alphanumeric characters.';
export const defaultOnlyAlphaChar =
	'This field only allows alphanumeric characters.';

export const invalidContactNumberErrorMessage = ({
	value,
}: YupMessage): string => {
	const count = value?.filter(
		(a: string) => !validateContactNumber(a || '')
	).length;
	if (!count) return '';
	if (count === 1) {
		return `${count} input is invalid contact number format`;
	}
	return `${count} inputs are invalid contact number format`;
};

export const multipleContactNumberSchema = yup
	.array()
	.of(yup.string())
	.test(
		'isValidNumber',
		invalidContactNumberErrorMessage,
		(v) => !!v?.every((a) => validateContactNumber(a || ''))
	)
	.ensure()
	.min(1, ({ label }) => `Input ${label}`)
	.max(15);

export const emailErrorMessage = ({ value }: YupMessage): string => {
	const count = value?.filter((a: string) => !validateEmail(a || '')).length;
	if (!count) return '';
	if (count === 1) {
		return `${count} input is invalid email format.`;
	}
	return `${count} inputs are invalid email format.`;
};

export const multipleEmailSchema = yup
	.array()
	.of(yup.string().email())
	.ensure()
	.min(1, ({ label }) => `Input ${label}.`)
	.test(
		'isValidNumber',
		emailErrorMessage,
		(v) => !!v?.every((a) => validateEmail(a || ''))
	);

export const multipleEmailSchemaCustom = yup
	.array()
	.of(yup.string().email())
	.ensure()
	.min(1, ({ label }) => `Input ${label}.`)
	.test(
		'isValidNumber',
		'Input valid email format',
		(v) => !!v?.every((a) => validateEmail(a || ''))
	);

export const recepientEmailSchemaCustom = yup
	.array()
	.of(yup.string().email())
	.ensure()
	.min(1, `Input Recipient Email Address.`)
	.test(
		'isValidNumber',
		emailErrorMessage,
		(v) => !!v?.every((a) => validateEmail(a || ''))
	);

export const whiteSpaceRegex = /^(?!\s+$).*/;
export const InputDefaultRequiredTemplate = ({ label }: YupMessage): string =>
	`Input ${label}.`;
export const selectDefaultRequiredTemplate = ({ label }: YupMessage): string =>
	`Select ${label}.`;
export const defaultInvalidCharacter = ({ label }: YupMessage): string =>
	`Invalid Character(s) in ${label}.`;
export const defaultCheckboxRequired = ({ label }: YupMessage): string =>
	`Please select ${label}.`;

export const websiteSchema = yup
	.string()
	.nullable()
	.max(255)
	.matches(
		/^((https?|ftp|smtp):\/\/)?(www.)?([a-z0-9-]+\.)+[a-z]{2,}(\/[a-zA-Z0-9#-]+\/?)*$/i,
		{
			excludeEmptyString: true,
			message: ({ label }) => `Input valid ${label}`,
		}
	);

export const hasSpecialCharacter = (v: string): boolean => {
	const noSpecialChar = new RegExp(/[/;=<>]/).test(v || '');
	return !noSpecialChar;
};

export const numbersOnly = (v: string): boolean => {
	const noSpecialChar = new RegExp(/^[0-9]+$/).test(v || '');
	return noSpecialChar;
};

const restrictSpecialChars = (inputValue) => {
	const trimmedValue = inputValue.trim();
	const regex = /[\/;<>=]/g;
	return trimmedValue.replace(regex, '');
};

export const specialCharsValidator = (v) => {
	const sanitizedValue = restrictSpecialChars(v);

	if (v.trim() === '' && sanitizedValue === ' ') {
		return false;
	}

	if (sanitizedValue !== v) {
		return false;
	}

	if (/^\w+\s$/.test(v)) {
		return false;
	}

	return true;
};

export const alphaNumericWithSpace = (v) => {
	//return false if whitespace only
	if (v.trim().length === 0) return false;
	//return false string starts with whitespace
	else if (/^\s/.test(v)) return false;

	return /^[a-zA-Z0-9 ]*$/gm.test(v);
};
export const restricSpecificSpecialChars = (v) => {
	if (/^[a-zA-Z0-9.,_\-()& ]+$/.test(v)) {
		return true;
	}

	return false;
};
export const transformDate = (
	v: string | null | undefined = ''
): string | null | undefined => {
	if (
		v === null ||
		!v ||
		!moment(v, ['MM/DD/YYYY', 'YYYY-MM-DD'], true).isValid()
	) {
		return null;
	}

	const parsedDate = moment(v, ['MM/DD/YYYY', 'YYYY-MM-DD'], true);
	if (parsedDate.isValid()) {
		return parsedDate.format(DATE_FORMAT_ISO_8601);
	} else {
		return null;
	}
};
export const transformDateFormat = (
	v: string | null | undefined = ''
): string | null | undefined => {
	if (v === null || !v) {
		return null;
	}
	const parsedDate = moment(v, ['MM/DD/YYYY', 'YYYY-MM-DD'], true);
	if (parsedDate.isValid()) {
		return parsedDate.format('MM/DD/YYYY');
	} else {
		return 'Invalid date format.';
	}
};

export const transformCash = (_, curr: number | string): number | null => {
	if (!curr || curr === '.00') return null;
	if (typeof curr === 'number') return curr;
	return Number(curr.replace(/,/g, ''));
};

export const validateDateSchema = (v: string | Date): boolean => {
	if (!v || v === '') {
		return true;
	}

	return (
		moment(v).format('MM/DD/YYYY') === v || moment(v).format('YYYY-MM-DD') === v
	);

	// return /^[A-Za-z0-9/]*$/.test(v);
};

export const yupValidateEmails = (
	v: FixMeLater = [],
	ctx: FixMeLater
): yup.ValidationError | boolean => {
	const { createError } = ctx;
	const allValues = v.length ? v.join() : '';
	const countInvalid = v?.filter(
		(a?: string) => !validateEmail(a || '')
	).length;
	if (allValues.length > 255)
		return createError({
			message: defaultMax255CharSpiel,
		});
	if (countInvalid >= 1) {
		return createError({
			message: `${countInvalid} ${
				countInvalid > 1 ? 'inputs are' : 'input is'
			} invalid email format`,
		});
	}
	return true;
};
export const ValidateEmailsRecipients = (
	v: FixMeLater = [],
	ctx: FixMeLater
): yup.ValidationError | boolean => {
	const { createError } = ctx;
	const countInvalid = v?.filter(
		(a?: string) => !validateEmail(a || '')
	).length;

	if (countInvalid >= 1) {
		return createError({
			message: `${countInvalid} ${
				countInvalid > 1 ? 'inputs are' : 'input is'
			} invalid email format`,
		});
	}
	return true;
};

export const yupValidateContactNums = (
	v: FixMeLater = [],
	ctx: FixMeLater
): yup.ValidationError | boolean => {
	const { createError } = ctx;
	const allValues = v.length ? v.join() : '';
	const countInvalid = v?.filter(
		(a?: string) => !validateContactNumber(a || '')
	).length;

	if (allValues.length > 255)
		return createError({
			message: defaultMax255CharSpiel,
		});
	if (countInvalid >= 1) {
		return createError({
			message: 'Mobile number should be in +639XXXXXXXXX format',
		});
	}
	return true;
};

export const yupValidateTelNums = (
	v: FixMeLater = [],
	ctx: FixMeLater
): yup.ValidationError | boolean => {
	const { createError } = ctx;
	const allValues = v.length ? v.join() : '';
	const countInvalid = v?.filter(
		(a?: string) => !validateLandlineNumber(a || '')
	).length;

	if (allValues.length > 255)
		return createError({
			message: defaultMax255CharSpiel,
		});
	if (countInvalid >= 1) {
		return createError({
			message: `${countInvalid} ${
				countInvalid > 1 ? 'inputs are' : 'input is'
			} invalid telephone number format`,
		});
	}
	return true;
};

export default yup;
