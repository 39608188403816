import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import styles from './ServiceFeeSettings.module.css';
import Grid from 'components/Grid/Grid';
import CheckGroup from './ServiceFeesCheckbox';
import { useForm } from 'react-hook-form';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { DaysOptions, MonthValueOptions } from 'utils/lookup';
import { SetStateAction, useEffect, useState } from 'react';
import TextField from 'components/Inputs/TextField/TextField';
import { toDecimalPlaces } from 'components/Format/NumberFormatter';
import { BreakLine } from './CustomLabel';
import ServiceFeeSchemaTable from '../ServiceFeeSettings/ServiceFeeSchemaTable/ServiceFeeSchemaTable';
import { yupResolver } from '@hookform/resolvers/yup';
import serviceFeeSchema from 'utils/formSchemas/partnerForm/serviceFeeSetting';
import client from 'helpers/ApiClient';
import { connect, useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, omit } from 'lodash';
import {
	TProductState,
	setAutoSaveValues,
	setServiceFeeSettings,
	setSubmittingAll,
	setDrafting,
	setEditing,
	updateValidForm,
} from 'redux/modules/products';
import { ReducerStateType } from 'redux/modules/reducers';
import { ExcludeAutoSave } from 'components/PartnerDetails/PartnerDetails';
import { usePreviousDistinct } from 'react-use';
import { LoaderState } from 'components/PartnerModal/PartnerModal';
import { FixMeLater } from 'types';

type Props = {
	data?: any;
	showOtherIndustry?: boolean;
	showOtherLegalStructure?: boolean;
	disabled?: boolean;
	initialValues?: any;
	onSubmit?: (values: any, saveType: string | LoaderState | null) => void;
	option?: any;
	products: Array<any>;
	productId?: string | number;
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['autoSaveValues'];
	currentPartnerTab?: string;
	action?: string;
	setServiceFeeSettings?: any;
	setSubmittingAll?: any;
	setDrafting?: any;
	setEditing?: any;
	setLastAutoSaved?: SetStateAction<any>;
	modalIsOpen?: boolean;
	fromAutoSave: boolean;
};
export type ServiceFeeSchemaType =
	| 'REGULAR'
	| 'TIER'
	| 'PERCENTAGE'
	| 'SPECIAL';
const SchemeTypes: Array<{
	label: string;
	value: ServiceFeeSchemaType;
}> = [
	{
		label: 'Regular',
		value: 'REGULAR',
	},
	{
		label: 'Tier',
		value: 'TIER',
	},
	{
		label: 'Percentage',
		value: 'PERCENTAGE',
	},
	{
		label: 'Special',
		value: 'SPECIAL',
	},
];
type RateType = 'FIXED_RATE' | 'PERCENTAGE' | '';
type BillingType = 'PASS_ON' | 'SUBSIDIZED';
type BasedType = 'AMOUNT_BASED' | 'COUNT_BASED' | '';
const arrRateType: Array<{
	label: string;
	value: RateType;
}> = [
	{
		label: 'Fixed Rate',
		value: 'FIXED_RATE',
	},
	{
		label: 'Percentage',
		value: 'PERCENTAGE',
	},
];
const arrBasedType: Array<{
	label: string;
	value: BasedType;
}> = [
	{
		label: 'Amount-based',
		value: 'AMOUNT_BASED',
	},
	{
		label: 'Count-based',
		value: 'COUNT_BASED',
	},
];
type FrequencyTypeOption = 'DAILY' | 'MONTHLY' | 'YEARLY' | '';
const frequencyTypes: Array<{
	label: string;
	value: FrequencyTypeOption;
}> = [
	{
		label: 'Daily',
		value: 'DAILY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
	{
		label: 'Yearly',
		value: 'YEARLY',
	},
];
export type ServiceFeeSettingsFormData = {
	with_sf_settle_deduction: '1' | '0';
	sf_nonShareableAmount: '1' | '0';
	non_shareable_rate_type?: RateType;
	vat: 'INCLUSIVE' | 'EXCLUSIVE';
	frequency_type?: FrequencyTypeOption;
	frequency_month?: any;
	frequency_day?: any;
	scheme_type: ServiceFeeSchemaType;
	tier_count?: number;
	tiers?: Array<any>;
	regular?: {
		subsidized_fee?: string;
		subsidized_rate?: string;
		pass_on_fee?: string;
		pass_on_rate?: string;
	};
	billing_type: Record<BillingType, boolean>;
	with_non_shareable_amount: '1' | '0';
	tier_base_type?: BasedType;
	tier_rate_type?: RateType;
	percentage?: {
		subsidized_rate?: string;
		pass_on_rate?: string;
		non_share_percentage?: string;
	};
	special?: Array<any>;
	special_rate_type?: RateType;
	id: number;
};

export const mapServiceFeeSettings = (
	data: FixMeLater
): Record<string, unknown> => {
	const billingType = { ...data?.billing_type };
	const { regular = {}, tiers = [], percentage = {}, special = [] } = data;
	const emptyBillingRate = {
		fixed: {
			pass_on_fee: null,
			subsidized_fee: null,
			non_share_fixed_rate: null,
		},
		percentage: {
			pass_on_rate: null,
			subsidized_rate: null,
			non_share_percentage: null,
		},
	};
	const id = data?.id;

	const feeParser = (fee: string | number) => {
		if (typeof fee === 'number') return fee;

		return fee?.replaceAll(',', '');
	};

	const getTotal = (passOnFee: string, subsidizedFee: string) => {
		const sanitizedPassOnFee = Number(`${passOnFee}`?.replaceAll(',', ''));
		const sanitizedSubsidizedFee = Number(
			`${subsidizedFee}`?.replaceAll(',', '')
		);
		return toDecimalPlaces(sanitizedPassOnFee + sanitizedSubsidizedFee);
	};

	const dataTier = (() => {
		const regularPercentageId = parseInt(id);
		// remove total_fixed_rate and total_percentage_rate as it is commputed in BE
		const ommitObj = (o) =>
			omit(o, ['total_fixed_rate', 'total_percentage_rate']);
		if (!isEmpty(regular) && data.scheme_type === 'REGULAR') {
			const _regular = ommitObj(regular);
			if (data.non_shareable_rate_type === 'PERCENTAGE')
				return [
					{
						..._regular,
						...emptyBillingRate.fixed,
						channel_type_id: null,
						id: regularPercentageId,
					},
				];

			return [
				{
					..._regular,
					...emptyBillingRate.percentage,
					channel_type_id: null,
					pass_on_fee: feeParser(regular.pass_on_fee),
					subsidized_fee: feeParser(regular.subsidized_fee),
					non_share_fixed_rate:
						data?.with_non_shareable_amount == '0'
							? null
							: feeParser(regular.non_share_fixed_rate),
					id: regularPercentageId,
				},
			];
		}
		if (!isEmpty(tiers) && data.scheme_type === 'TIER')
			return tiers?.map((t, i) => {
				const lowerUpperLimit = {
					lower_limit: `${t.lower_limit}`.replaceAll(',', ''),
					upper_limit: `${t.upper_limit}`.replaceAll(',', ''),
				};
				const _tier = ommitObj(t);
				if (data.tier_rate_type === 'PERCENTAGE')
					return {
						..._tier,
						...emptyBillingRate.fixed,
						...lowerUpperLimit,
						channel_type_id: null,
						tier_number: i + 1,
						id: id?.[i],
					};

				return {
					..._tier,
					...emptyBillingRate.percentage,
					...lowerUpperLimit,
					channel_type_id: null,
					tier_number: i + 1,
					non_share_fixed_rate:
						data?.with_non_shareable_amount == '0'
							? null
							: feeParser(t.non_share_fixed_rate),
					pass_on_fee: feeParser(t.pass_on_fee),
					subsidized_fee: feeParser(t.subsidized_fee),
					id: id?.[i],
				};
			});
		if (!isEmpty(percentage) && data.scheme_type === 'PERCENTAGE') {
			const _percentage = ommitObj(percentage);
			if (data.non_shareable_rate_type === 'FIXED_RATE')
				return [
					{
						..._percentage,
						...emptyBillingRate.percentage,
						channel_type_id: null,
						non_share_fixed_rate:
							data?.with_non_shareable_amount == '0'
								? null
								: feeParser(percentage.non_share_fixed_rate),
						pass_on_fee: feeParser(percentage.pass_on_fee),
						subsidized_fee: feeParser(percentage.subsidized_fee),
						id: regularPercentageId,
					},
				];

			return [
				{
					..._percentage,
					...emptyBillingRate.fixed,
					channel_type_id: null,
					id: regularPercentageId,
				},
			];
		}
		if (!isEmpty(special) && data.scheme_type === 'SPECIAL')
			return special?.map((s, i) => {
				const _s = ommitObj(s);
				if (data.special_rate_type === 'PERCENTAGE')
					return {
						...s,
						...emptyBillingRate.fixed,
						channel_type_id: i + 1,
						tier_number: null,
						id: id?.[i],
					};

				return {
					..._s,
					...emptyBillingRate.percentage,
					channel_type_id: i + 1,
					tier_number: null,
					non_share_fixed_rate:
						data?.with_non_shareable_amount == '0'
							? null
							: feeParser(s.non_share_fixed_rate),
					pass_on_fee: feeParser(s.pass_on_fee),
					subsidized_fee: feeParser(s.subsidized_fee),
					id: id?.[i],
				};
			});
		return [];
	})();

	return {
		billingType:
			billingType?.PASS_ON === true && billingType?.SUBSIDIZED === true
				? 'BOTH'
				: (billingType?.PASS_ON && 'PASS_ON') ||
				  (billingType?.SUBSIDIZED && 'SUBSIDIZED') ||
				  null,
		vat: data.vat,
		withNonShareable: data?.with_non_shareable_amount,
		nonShareableRateType: data?.non_shareable_rate_type,
		schemeType: data?.scheme_type,
		tierBaseType: data?.tier_base_type || null,
		tierRateType: data?.tier_rate_type || null,
		specialRateType: data?.special_rate_type || null,
		withSfSettleDeduction: data?.with_sf_settle_deduction,
		frequencyType: data?.frequency_type || null,
		frequencyMonth: data?.frequency_month,
		frequencyDay: data?.frequency_day,
		tiers: dataTier,
		isDirty: data?.isDirty,
		isValid: data?.isValid,
		id,
	};
};

const ServiceFeeSettings: React.FC<Props> = ({
	action,
	disabled,
	products,
	productId,
	autoSaveValues,
	setAutoSaveValues,
	currentPartnerTab,
	data,
	setServiceFeeSettings,
	setSubmittingAll,
	setDrafting,
	setEditing,
	setLastAutoSaved,
	onSubmit,
	modalIsOpen,
	fromAutoSave,
}) => {
	const dispatch = useDispatch();
	const [isAutoSaveLoading, setAutoSaveLoading] = useState(false);

	const statusSubmitting = useSelector<ReducerStateType>(
		(state) => state.products.isSubmittingAll
	);
	const drafting = useSelector<ReducerStateType>(
		(state) => state.products.drafting
	);
	const editing = useSelector<ReducerStateType>(
		(state) => state.products.editing
	);
	const previousTab = usePreviousDistinct(currentPartnerTab);

	const defaultValues = data?.service_fee_settings;
	const {
		control,
		watch,
		handleSubmit,
		setValue,
		getValues,
		clearErrors,
		setError,
		reset,
		trigger,
		formState: { isDirty, isValid },
	} = useForm<ServiceFeeSettingsFormData>({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(serviceFeeSchema),
	});

	useEffect(() => {
		if (drafting) {
			const data = structuredClone({ ...getValues(), isDirty, isValid });
			setServiceFeeSettings(data);
			setDrafting(false);
		}
	}, [drafting]);

	useEffect(() => {
		if (editing) {
			handleSubmit((v) => {
				const validData = structuredClone({ ...v, isDirty, isValid });
				setServiceFeeSettings(validData);
			})();
			if (!isValid) {
				const data = structuredClone({
					...getValues(),
					isDirty,
					isValid,
				});
				setServiceFeeSettings(data);
			}
			setEditing(false);
		}
	}, [editing]);

	useEffect(() => {
		if (disabled) return;
		clearErrors();
		if (statusSubmitting || previousTab === 'service-fee') {
			handleSubmit((v) => {
				const validData = structuredClone({ ...v, isDirty, isValid });
				setServiceFeeSettings(validData);
			})();

			if (!isValid) {
				const data = structuredClone({ ...getValues(), isDirty, isValid });
				setServiceFeeSettings(data);
			}
			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab]);

	const iwatchFrequencType = watch('frequency_type');
	const sf_settlementDeduction = watch('with_sf_settle_deduction');
	const sf_nonShareableAmount = watch('with_non_shareable_amount');
	const nonShareableRateType = watch('non_shareable_rate_type');
	const schemeType = watch('scheme_type');
	const tierCount = disabled
		? data?.service_fee_settings?.tier_count
		: watch('tier_count');
	const sfBillingType = watch('billing_type');
	const tierRateType = watch('tier_rate_type');
	const specialRateType = watch('special_rate_type');
	const isPercentage =
		nonShareableRateType === 'PERCENTAGE' ||
		schemeType === 'PERCENTAGE' ||
		tierRateType === 'PERCENTAGE' ||
		specialRateType === 'PERCENTAGE';

	const serviceFeeSchemaTableProps = {
		disabled,
		schemeType,
		tierCount,
		control,
		withNonShareableAmount: sf_nonShareableAmount,
		serviceFeeBillingType: sfBillingType,
		getValues,
		setValue,
		action,
	};

	const resetTables = () => {
		const defaultTable: any = {
			regular: {},
			tier: [{}],
			percentage: {},
			special: [],
		};
		reset({ ...getValues(), ...structuredClone(defaultTable) });
	};

	const resetSFnonShareableAmount = (e) => {
		if (disabled || isAutoSaveLoading) return;

		if (
			sf_nonShareableAmount == '0' &&
			schemeType &&
			schemeType !== 'PERCENTAGE'
		) {
			setValue('tier_rate_type', tierRateType, { shouldDirty: true });
			setValue('special_rate_type', specialRateType, { shouldDirty: true });
			setValue('non_shareable_rate_type', '', {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
	};

	const resetFrequencyType = () => {
		if (disabled || isAutoSaveLoading) return;

		if (sf_settlementDeduction == '0') {
			setValue('frequency_type', '');
			setValue('frequency_month', '');
			setValue('frequency_day', '');
		}
	};

	// Reset when Scheme Type changes
	const resetScheme = (e) => {
		if (disabled || isAutoSaveLoading) return;

		resetTables();
		resetBillingFees(e, sfBillingType);
		setValue('tier_count', 0, { shouldDirty: true });
		setValue('tier_rate_type', '', { shouldDirty: true });
		setValue('tier_base_type', '', { shouldDirty: true });
	};

	const resetBillingFees = (
		selectedScheme,
		billingType,
		isTierCountChanged = false
	) => {
		if (disabled || !billingType || !selectedScheme || isAutoSaveLoading)
			return;

		const defaultTable = {
			billing_type: billingType,
		};
		let updatedTable = {};

		const regularList = getValues().regular ?? [];
		const tierList = getValues().tiers ?? [];
		const percentageList = getValues().percentage ?? [];
		const specialList = getValues().special ?? [];

		const feeParser = (fee: string | number) => {
			if (typeof fee === 'number') return fee;

			return parseFloat(fee?.replaceAll(',', ''));
		};

		const getUpdatedFees = {
			totalFee: (passOnFee, subsidizedFee) => {
				if (isPercentage) return null;

				const parsedPassOn = passOnFee ? feeParser(passOnFee) : 0;
				const parsedSubsidized = subsidizedFee ? feeParser(subsidizedFee) : 0;

				if (billingType.PASS_ON && billingType.SUBSIDIZED) {
					return `${toDecimalPlaces(parsedPassOn + parsedSubsidized, 2)}`;
				} else if (billingType.PASS_ON) {
					return `${toDecimalPlaces(parsedPassOn)}`;
				} else if (billingType.SUBSIDIZED) {
					return `${toDecimalPlaces(parsedSubsidized)}`;
				}

				return 0;
			},
			totalRate: (passOnRate, subsidizedRate) => {
				if (!isPercentage) return null;

				const parsedPassOn = passOnRate ? feeParser(passOnRate) : 0;
				const parsedSubsidized = subsidizedRate ? feeParser(subsidizedRate) : 0;

				if (billingType.PASS_ON && billingType.SUBSIDIZED) {
					return `${toDecimalPlaces(parsedPassOn + parsedSubsidized, 2)}`;
				} else if (billingType.PASS_ON) {
					return `${toDecimalPlaces(parsedPassOn)}`;
				} else if (billingType.SUBSIDIZED) {
					return `${toDecimalPlaces(parsedSubsidized)}`;
				}

				return 0;
			},
			subsidizedFee: (subsidizedFee) => {
				if (isPercentage) return null;

				return billingType.SUBSIDIZED && subsidizedFee ? subsidizedFee : '0';
			},
			subsidizedRate: (subsidizedRate) => {
				if (!isPercentage) return null;

				return billingType.SUBSIDIZED && subsidizedRate ? subsidizedRate : '0';
			},
			passOnFee: (passOnFee) => {
				if (isPercentage) return null;

				return billingType.PASS_ON && passOnFee ? passOnFee : '0';
			},
			passOnRate: (passOnRate) => {
				if (!isPercentage) return null;

				return billingType.PASS_ON && passOnRate ? passOnRate : '0';
			},
			nonShareRate: (nonShareRate) => {
				if (sf_nonShareableAmount == '0') return null;

				return isTierCountChanged ? nonShareRate : '0';
			},
		};

		if (selectedScheme === 'REGULAR') {
			updatedTable = {
				regular: {
					...regularList,
					subsidized_fee: getUpdatedFees.subsidizedFee(
						regularList['subsidized_fee']
					),
					subsidized_rate: getUpdatedFees.subsidizedRate(
						regularList['subsidized_rate']
					),
					pass_on_fee: getUpdatedFees.passOnFee(regularList[`pass_on_fee`]),
					pass_on_rate: getUpdatedFees.passOnRate(regularList[`pass_on_rate`]),
					total_fixed_rate: getUpdatedFees.totalFee(
						regularList[`pass_on_fee`],
						regularList['subsidized_fee']
					),
					total_percentage_rate: getUpdatedFees.totalRate(
						regularList[`pass_on_rate`],
						regularList['subsidized_rate']
					),
					non_share_fixed_rate: getUpdatedFees.nonShareRate(
						regularList['non_share_fixed_rate']
					),
					non_share_percentage: getUpdatedFees.nonShareRate(
						regularList['non_share_percentage']
					),
				},
			};
		} else if (selectedScheme === 'TIER') {
			updatedTable = {
				tiers: Array.isArray(tierList) ? tierList.map((t, i) => {
					return {
						...tierList[i],
						subsidized_fee: getUpdatedFees.subsidizedFee(
							tierList[i]['subsidized_fee']
						),
						subsidized_rate: getUpdatedFees.subsidizedRate(
							tierList[i]['subsidized_rate']
						),
						pass_on_fee: getUpdatedFees.passOnFee(tierList[i][`pass_on_fee`]),
						pass_on_rate: getUpdatedFees.passOnRate(
							tierList[i][`pass_on_rate`]
						),
						total_fixed_rate: getUpdatedFees.totalFee(
							tierList[i][`pass_on_fee`],
							tierList[i]['subsidized_fee']
						),
						total_percentage_rate: getUpdatedFees.totalRate(
							tierList[i][`pass_on_rate`],
							tierList[i]['subsidized_rate']
						),
						non_share_fixed_rate: getUpdatedFees.nonShareRate(
							tierList[i]['non_share_fixed_rate']
						),
						non_share_percentage: getUpdatedFees.nonShareRate(
							tierList[i]['non_share_percentage']
						),
					};
				}) : [],
			};
		} else if (selectedScheme === 'PERCENTAGE') {
			updatedTable = {
				percentage: {
					subsidized_rate: getUpdatedFees.subsidizedRate(
						percentageList['subsidized_rate']
					),
					pass_on_rate: getUpdatedFees.passOnRate(
						percentageList[`pass_on_rate`]
					),
					total_percentage_rate: getUpdatedFees.totalRate(
						percentageList[`pass_on_rate`],
						percentageList['subsidized_rate']
					),
					non_share_percentage: getUpdatedFees.nonShareRate(
						percentageList['non_share_percentage']
					),
				},
			};
		} else if (selectedScheme === 'SPECIAL') {
			updatedTable = {
				special: specialList.map((t, i) => {
					return {
						...specialList[i],
						subsidized_fee: getUpdatedFees.subsidizedFee(
							specialList[i]['subsidized_fee']
						),
						subsidized_rate: getUpdatedFees.subsidizedRate(
							specialList[i]['subsidized_rate']
						),
						pass_on_fee: getUpdatedFees.passOnFee(
							specialList[i][`pass_on_fee`]
						),
						pass_on_rate: getUpdatedFees.passOnRate(
							specialList[i][`pass_on_rate`]
						),
						total_fixed_rate: getUpdatedFees.totalFee(
							specialList[i][`pass_on_fee`],
							specialList[i]['subsidized_fee']
						),
						total_percentage_rate: getUpdatedFees.totalRate(
							specialList[i][`pass_on_rate`],
							specialList[i]['subsidized_rate']
						),
						non_share_fixed_rate: getUpdatedFees.nonShareRate(
							specialList[i]['non_share_fixed_rate']
						),
						non_share_percentage: getUpdatedFees.nonShareRate(
							specialList[i]['non_share_percentage']
						),
					};
				}),
			};
		}

		reset(
			{
				...getValues(),
				...structuredClone({ ...defaultTable, ...updatedTable }),
			},
			{ keepDirty: true }
		);
	};

	const handleChangeBilling = (e) => {
		resetBillingFees(schemeType, e);
		trigger('billing_type');
	};

	const handleChangeNonShareable = (e) => {
		if (e === 'FIXED_RATE' && schemeType === 'PERCENTAGE') {
			setError('scheme_type', {
				type: 'custom',
				message: 'Non-Shareable Rate Type should be Percentage for this scheme',
			});
		}
	};

	// syncing start
	useEffect(() => {
		if (disabled || isAutoSaveLoading) return;

		if (!nonShareableRateType && schemeType === 'REGULAR') {
			setValue('tier_rate_type', undefined, { shouldDirty: true });
			setValue('special_rate_type', undefined, { shouldDirty: true });
		}
	}, [schemeType]);

	useEffect(() => {
		if (disabled || isAutoSaveLoading) return;

		if (tierRateType && nonShareableRateType) {
			setValue('non_shareable_rate_type', tierRateType, { shouldDirty: true });
		}

		if (tierRateType) {
			resetBillingFees(schemeType, sfBillingType);
		}
	}, [tierRateType]);

	useEffect(() => {
		if (disabled || isAutoSaveLoading) return;

		if (specialRateType && nonShareableRateType) {
			setValue('non_shareable_rate_type', specialRateType, {
				shouldDirty: true,
			});
		}

		if (specialRateType) resetBillingFees(schemeType, sfBillingType);
	}, [specialRateType]);

	useEffect(() => {
		if (disabled || isAutoSaveLoading) return;

		if (nonShareableRateType && schemeType === 'TIER') {
			setValue('tier_rate_type', nonShareableRateType, { shouldDirty: true });
		} else if (nonShareableRateType && schemeType === 'SPECIAL') {
			setValue('special_rate_type', nonShareableRateType, {
				shouldDirty: true,
			});
		}

		resetBillingFees(schemeType, sfBillingType);
	}, [nonShareableRateType]);
	// syncing end

	//slice tier
	useEffect(() => {
		if (disabled || isAutoSaveLoading) return;

		const tiers: any = getValues().tiers ?? [];
		Array.isArray(tiers) && setValue('tiers', tiers.slice(0, tierCount) as []);
		resetBillingFees(schemeType, sfBillingType, true);
	}, [tierCount]);

	useEffect(() => {
		if (disabled || (action && ExcludeAutoSave.includes(action))) return;
		if (isDirty && previousTab === 'service-fee') {
			scoffoledData();
		}
	}, [previousTab, isDirty]);

	useEffect(() => {
		if (
			disabled ||
			(action && ExcludeAutoSave.includes(action)) ||
			!fromAutoSave
		)
			return;

		// get latest auto save values if has autosave in BE
		if (!isEmpty(autoSaveValues)) {
			setAutoSaveLoading(true);
			client
				.get('v2/autosave/products')
				.then(({ data }) => {
					const values = data.data.service_fee_settings.originalValues;
					reset(values);
					setAutoSaveValues({});
				})
				.then(() => {
					setAutoSaveLoading(false);
				});
		}
	}, []);

	const scoffoledData = async () => {
		onSubmit && (await onSubmit(null, LoaderState.ShowLoader));
		const data = getValues() as any;
		const productType = products.find((p) => p.id == productId)?.code;
		const payload = mapServiceFeeSettings(data);

		client
			.post(`/v2/autosave/products`, {
				serviceFeeSettings: {
					...payload,
					originalValues: getValues(),
				},
				productType,
			})
			.then(({ data }) => {
				setLastAutoSaved({
					date: data?.data?.created_at,
					username: data?.data?.username,
				});
				onSubmit && onSubmit(null, LoaderState.HideLoader);
			});
	};

	useEffect(() => {
		debounce(() => {
			dispatch(
				updateValidForm({
					formTab: 'serviceFeeSettings',
					isValid,
					isDirty,
					isLoaded: true,
				})
			);
		}, 900)();
	}, [isValid, isDirty, dispatch]);

	// For reseting the form when refetched
	const isTabRefetched = useSelector<ReducerStateType>(
		(state) => state.products.serviceFeeSettings?.isRefetched
	);

	useEffect(() => {
		// reset form if refetched
		if (isTabRefetched) {
			reset(defaultValues, { keepDirty: false });
			updateValidForm({
				formTab: 'serviceFeeSettings',
				isDirty,
				isRefetched: false,
			});
		}
	}, [isTabRefetched, reset]);
	//

	return (
		<>
			<div className={styles.gbl_fee}>
				<CheckGroup
					label="Service Fee Billing Type"
					name="billing_type"
					control={control}
					required
					onChange={handleChangeBilling}
					options={[
						{
							label: 'Pass-On',
							value: 'PASS_ON',
						},
						{
							label: 'Subsidized',
							value: 'SUBSIDIZED',
						},
					]}
					disabled={disabled}
				/>
			</div>
			<hr className={styles.divider} />
			<div className={styles.gbl_fee}>
				<RadioGroup
					label="Value Added Tax (VAT)"
					disabled={disabled}
					options={[
						{
							label: 'VAT Inclusive',
							value: 'INCLUSIVE',
						},
						{
							label: 'VAT Exclusive',
							value: 'EXCLUSIVE',
						},
					]}
					name="vat"
					required
					isVertical={true}
					control={control}
				/>
			</div>
			<hr className={styles.divider} />
			<Grid container gutters className={styles.gbl_fee}>
				<Grid column size={1} of={3}>
					<RadioGroup
						label="With Non-Shareable Amount?"
						disabled={disabled}
						options={[
							{
								value: '1',
								label: 'Yes',
							},
							{
								label: 'No',
								value: '0',
							},
						]}
						control={control}
						defaultValue={'0'}
						onChange={resetSFnonShareableAmount}
						name="with_non_shareable_amount"
						subText="*This refers to the total amount from the service fee which will not be subject for revenue sharing with channel partners."
						required
					/>
				</Grid>
				<Grid column size={1} of={3}>
					{sf_nonShareableAmount == '1' && (
						<SelectField
							label="Non-Shareable Rate Type"
							disabled={disabled}
							control={control}
							required
							placeholder="Select Non-Shareable Rate Type"
							name="non_shareable_rate_type"
							shouldUnregister
							onChange={handleChangeNonShareable}
							options={[
								{
									label: 'Percentage',
									value: 'PERCENTAGE',
								},
								{
									label: 'Fixed Rate',
									value: 'FIXED_RATE',
								},
							]}
						/>
					)}
				</Grid>
			</Grid>

			<hr className={styles.divider} />

			<Grid gutters container className={styles.gbl_fee}>
				<Grid column size={1} of={3}>
					<SelectField
						label="Service Fee Scheme Type"
						disabled={disabled}
						control={control}
						required
						placeholder="Select Service Fee Scheme Type"
						name="scheme_type"
						options={SchemeTypes}
						shouldUnregister
						onChange={resetScheme}
					/>
				</Grid>
				{schemeType === 'SPECIAL' && (
					<Grid column size={1} of={3}>
						<SelectField
							label="Special Rate Type"
							disabled={disabled}
							control={control}
							required
							name="special_rate_type"
							placeholder="Select Special Rate Type"
							options={arrRateType}
							shouldUnregister
						/>
					</Grid>
				)}
				{schemeType === 'TIER' && (
					<>
						<Grid column size={1} of={3}>
							<div style={{ marginLeft: '5px', marginRight: '5px' }}>
								<TextField
									label="No. of Tiers"
									control={control}
									name="tier_count"
									type="number"
									maxValue={50}
									formatValue={(v) => (v ? parseInt(v) : v)}
									disabled={disabled}
									shouldUnregister
								/>
							</div>
						</Grid>

						<>
							<Grid column size={1} of={3}>
								<SelectField
									label="Tier Base Type"
									disabled={disabled}
									control={control}
									required
									name="tier_base_type"
									placeholder="Select Tier Base Type"
									options={arrBasedType}
									shouldUnregister
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<SelectField
									label="Tier Rate Type"
									disabled={disabled}
									control={control}
									required
									name="tier_rate_type"
									placeholder="Select Tier Rate Type"
									options={arrRateType}
									shouldUnregister
								/>
							</Grid>
						</>
					</>
				)}
			</Grid>
			<hr className={styles.divider} />
			<Grid container gutters className={styles.gbl_fee}>
				<Grid column size={1} of={3}>
					<RadioGroup
						label={BreakLine(
							'With SF Settlement Deduction on Collections > (Net Settlement)?'
						)}
						disabled={disabled}
						options={[
							{
								label: 'Yes',
								value: '1',
							},
							{
								label: 'No',
								value: '0',
							},
						]}
						name="with_sf_settle_deduction"
						required
						isVertical={true}
						control={control}
						onChange={() => resetFrequencyType()}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					{sf_settlementDeduction == '1' && (
						<SelectField
							label="Frequency Type"
							disabled={disabled}
							control={control}
							required
							placeholder="Select Frequency Type"
							name="frequency_type"
							options={frequencyTypes}
							onChange={() => {
								if (disabled || isAutoSaveLoading) return;
								setValue('frequency_month', '', { shouldDirty: true });
								setValue('frequency_day', '', { shouldDirty: true });
							}}
							shouldUnregister
						/>
					)}
				</Grid>
				<Grid container column size={1} of={3} gutters>
					{sf_settlementDeduction == '1' && (
						<>
							{iwatchFrequencType === 'MONTHLY' && (
								<SelectField
									label="Day"
									disabled={disabled}
									control={control}
									required
									placeholder="Day"
									name="frequency_day"
									options={DaysOptions}
									shouldUnregister
								/>
							)}

							{iwatchFrequencType === 'YEARLY' && (
								<>
									<Grid column size={1} of={2}>
										<SelectField
											label="Month"
											disabled={disabled}
											control={control}
											required
											placeholder="Month"
											name="frequency_month"
											options={MonthValueOptions}
											shouldUnregister
										/>
									</Grid>

									<Grid column size={1} of={2}>
										<SelectField
											label="Day"
											disabled={disabled}
											control={control}
											required
											placeholder="Day"
											name="frequency_day"
											options={DaysOptions}
											shouldUnregister
										/>
									</Grid>
								</>
							)}
						</>
					)}
				</Grid>
			</Grid>
			<hr className={styles.divider} />
			<div className={styles.gbl_fee}>
				<ServiceFeeSchemaTable
					{...serviceFeeSchemaTableProps}
					modalIsOpen={modalIsOpen}
				/>
			</div>
		</>
	);
};

export default connect(
	(state: any) => ({
		lastActionButton: state.products.lastActionButton,
		currentPartnerTab: state.products.currentBillerTab,
		autoSaveValues: state.products.autoSaveValues,
		selectedBiller: state.products.selectedBiller,
		productId: state.sidebar.itemId,
		products: state.sidebar.products,
	}),
	{
		setAutoSaveValues,
		setSubmittingAll,
		setDrafting,
		setEditing,
		setServiceFeeSettings,
	}
)(ServiceFeeSettings);
