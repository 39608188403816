import { useState } from 'react';
import { startCase } from 'lodash';
import { Button, Tooltip } from '@salesforce/design-system-react';

import { ReactComponent as PartnerIcon } from 'assets/icons/ic-card-white.svg';
import { ReactComponent as InactiveUserIcon } from 'assets/icons/ic-inactive-users.svg';
import { ReactComponent as ModuleIcon } from 'assets/icons/ic-modules.svg';
import { ReactComponent as BrandIcon } from 'assets/icons/ic-partnerbrands.svg';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/ic-active-users.svg';

import { useToggle } from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import { SelectedPartnerProps, Summary } from './types';
import styles from './ProductMgt.module.css';

export const getSummaryData = (summaryData: Summary) => {
	return [
		{
			label: 'Total Number of Partners *',
			value: summaryData.totalPartners.toLocaleString(),
			icon: (
				<div className={styles.partnerIcon}>
					<PartnerIcon />
				</div>
			),
			color: '#f26122',
		},
		{
			label: 'Total Number of Partner Brands *',
			value: summaryData.totalPartnerBrands.toLocaleString(),
			icon: (
				<div className={styles.brandIcon}>
					<BrandIcon />
				</div>
			),
			color: '#c9cb2A',
		},
		{
			label: 'Total Number of Modules *',
			value: summaryData.totalModules.toLocaleString(),
			icon: (
				<div className={styles.moduleIcon}>
					<ModuleIcon />
				</div>
			),
			color: '#0176c0',
		},
		{
			label: 'Total Number of Activated Partners *',
			value: summaryData.totalActivatedPartners.toLocaleString(),
			icon: <ActiveUserIcon />,
			color: '#bc224c',
		},
		{
			label: 'Total Number of Partners with No transactions *',
			value: summaryData.totalPartnersNoTxn.toLocaleString(),
			icon: <InactiveUserIcon />,
			color: '#bc224c',
		},
	];
};

export const PartnerTableColumn = () => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [selectedPartner, setSelectedPartner] = useState<SelectedPartnerProps>(
		{}
	);

	const {
		value: isPartnerProfileShown,
		valueOn: showPartnerProfile,
		valueOff: hidePartnerProfile,
	} = useToggle();

	const onClickActionBtn = (data) => {
		if (hasUserPermission('get.partner')) {
			setSelectedPartner(data?.original);
			showPartnerProfile();
		}
	};

	const PARTNER_COLUMNS = [
		{
			Header: () => <div style={{ paddingLeft: '10px' }}>Partner Name</div>,
			id: 'name',
			width: '14%',
			Cell: ({ value = '' }) => <div className={styles.label}>{value}</div>,
		},
		{
			Header: 'Partner Code',
			id: 'partnerCode',
			width: '10%',
		},
		{
			Header: 'Service Code',
			id: 'serviceCode',
			width: '10%',
		},
		{
			Header: 'Category',
			id: 'category',
		},
		{
			Header: 'SF Scheme Type',
			id: 'sfSchemeType',
			width: '12%',
		},
		{
			Header: 'Service Fee (Pass-On)',
			id: 'serviceFeePassOn',
			width: '14%',
			Cell: ({ value, row }) => (
				<>
					{value}
					{row.original.sfSchemeType == 'PERCENTAGE' && '%'}
				</>
			),
		},
		{
			Header: 'API 3.0 Status',
			id: 'api3status',
			Cell: ({ value = '' }) => (
				<div
					className={
						value === 'ACTIVE' ? styles.labelActive : styles.labelInactive
					}
				>
					{startCase(value.toLowerCase())}
				</div>
			),
		},
		{
			Header: 'BFA Status',
			id: 'bfaStatus',
			Cell: ({ value = '' }) => (
				<div
					className={
						value === 'ENABLED' ? styles.labelActive : styles.labelInactive
					}
				>
					{startCase(value.toLowerCase())}
				</div>
			),
		},
		{
			Header: 'Action',
			id: 'actions',
			Cell: ({ row = '' }) => (
				<div className={styles.viewIcon}>
					<Tooltip
						content="View"
						align="bottom"
						dialogClassName={styles.tooltip}
					>
						<Button
							assistiveText={{ icon: 'Icon Bare Small' }}
							className={styles.viewIcon}
							iconCategory="utility"
							iconName="picklist_type"
							iconSize="small"
							iconVariant="bare"
							iconClassName={styles.iconBtn}
							onClick={() => {
								onClickActionBtn(row);
							}}
							variant="icon"
						/>
					</Tooltip>
				</div>
			),
		},
	];
	return {
		PARTNER_COLUMNS,
		selectedPartner,
		isPartnerProfileShown,
		hidePartnerProfile,
	};
};
