import PlainCheckbox, { ICheckbox } from 'components/Checkbox/Checkbox';
import { PropsWithChildren, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Checkbox.module.css';

type CheckboxProps<T extends any | undefined> = PropsWithChildren<{
	onClick?: (value?: T) => void;
	checked?: boolean;
	value?: T;
	icon?: ReactNode;
	label: string;
	disabled?: boolean;
}>;

export const Checkbox = <T extends any | undefined>({
	checked = false,
	onClick,
	value,
	icon,
	label,
	disabled = false,
	children,
}: CheckboxProps<T>): JSX.Element => {
	const handleClick = () => {
		if (disabled) return;
		onClick?.(value);
	};
	return (
		<div
			className={cx(styles.checkboxContainer, { [styles.disabled]: disabled })}
		>
			<PlainCheckbox
				checked={checked}
				label=""
				className={styles.checkbox}
				onChange={handleClick}
			/>
			<div className={styles.checkboxBody} onClick={handleClick} onKeyDown={()=>{return}}>
				{icon}
				{label}
			</div>
			{children && <CheckboxFooter>{children}</CheckboxFooter>}
		</div>
	);
};

export const CheckboxFooter: React.FC = ({ children }) => {
	return <div className={styles.checkboxFooterContainer}>{children}</div>;
};

export const CheckboxGroup: React.FC = ({ children }) => {
	return <div className={styles.checkboxGroupContainer}>{children}</div>;
};

export type SubCheckboxProps = Omit<ICheckbox, 'onChange'> & {
	onClick?: ICheckbox['onChange'];
};

export const SubCheckbox: React.FC<SubCheckboxProps> = ({
	label,
	onClick,
	...props
}) => {
	const handleClick = () => {
		if (props.disabled) return;
		onClick?.();
	};
	return (
		<PlainCheckbox
			label=""
			onChange={handleClick}
			labels={{ label }}
			{...props}
		/>
	);
};

Checkbox.Group = CheckboxGroup;
Checkbox.Footer = CheckboxFooter;
Checkbox.SubCheckbox = SubCheckbox;
