import { common } from './common';
import {
	product_primary_info,
	product_primary_info_schema,
} from './product_primary_info';
import { product_address, product_address_schema } from './product_address';
import {
	product_contact_detail,
	product_contact_detail_schema,
} from './product_contact_detail';
import {
	product_affiliate,
	product_affiliate_schema,
} from './product_affiliate';
import { product_officer, product_officer_schema } from './product_officer';
import { product_contract, product_contract_schema } from './product_contract';
import {
	product_cash_collection,
	product_cash_collection_schema,
} from './product_cash_collection';
import {
	product_check_collection,
	product_check_collection_schema,
} from './product_check_collection';
import {
	product_terms_duration,
	product_terms_duration_schema,
} from './product_terms_duration';
import {
	product_dpo_detail,
	product_dpo_detail_schema,
} from './product_dpo_detail';
import { product_dsa_bond, product_dsa_bond_schema } from './product_dsa_bond';
import {
	product_accreditation,
	product_accreditation_schema,
} from './product_accreditation';
import {
	product_service_fee,
	product_service_fee_schema,
} from './product_service_fee';
import {
	product_service_fee_tiers,
	product_service_fee_tiers_schema,
} from './product_service_fee_tiers';
import {
	product_business_rule,
	product_business_rule_schema,
} from './product_business_rule';
import * as yup from 'yup';
import { InferType } from 'yup';
import { isEmpty } from 'lodash';

export type product = {
	name?: string;
	description?: string;
	code?: string; // merchant id
	category?: string;
	type?: string;
	logo?: { objectKey: string };
	logo_url?: string;
	status?: string;
	is_async?: number;
	is_cde?: number;
	is_routed?: number;
	product_code_type?: string;
	product_code?: string; // partner code
	product_primary_info?: product_primary_info;
	product_affiliates?: product_affiliate[];
	product_addresses?: product_address[];
	product_contact_details?: product_contact_detail[];
	product_officer?: product_officer;
	product_contract?: product_contract;
	product_cash_collection?: product_cash_collection;
	product_check_collection?: product_check_collection;
	product_terms_duration?: product_terms_duration;
	product_dpo_detail?: product_dpo_detail;
	product_dsa_bond?: product_dsa_bond;
	product_accreditation?: product_accreditation;
	product_service_fee?: product_service_fee;
	product_service_fee_tiers?: product_service_fee_tiers;
	product_business_rule?: product_business_rule;
} & common;

export const product_schema = (values: any) =>
	yup.object().shape({
		code: yup
			.string()
			.label('Merchant ID')
			.required()
			.max(5, 'You can only input a max of 5 characters.')
			.matches(/^[a-z|A-Z|0-9_]{1,5}$/, {
				excludeEmptyString: true,
				message: `This field only allows alphanumeric characters.`,
			}),
		product_code_type: yup
			.string()
			.nullable()
			.label('Partner Code Type')
			.oneOf(['AUTO', 'EXISTING']),
		product_code: yup
			.string()
			.nullable()
			.label('Partner Code')
			.when('product_code_type', {
				is: (v: string) => v != 'AUTO',
				then: () => yup.string().required('Input existing partner code.'),
				otherwise: yup.string().nullable(),
			}),
		product_primary_info: product_primary_info_schema,
		product_affiliates: yup.array().nullable().of(product_affiliate_schema),
		product_addresses: yup.array().nullable().of(product_address_schema),
		product_contact_details: yup
			.array()
			.nullable()
			.of(product_contact_detail_schema),
		product_officer: product_officer_schema,
		product_contract: product_contract_schema,
		product_cash_collection: product_cash_collection_schema,
		product_check_collection: product_check_collection_schema,
		product_terms_duration: product_terms_duration_schema,
		product_dpo_detail: product_dpo_detail_schema,
		product_dsa_bond: product_dsa_bond_schema,
		product_accreditation: product_accreditation_schema,
		product_service_fee: product_service_fee_schema,
		product_service_fee_tiers: product_service_fee_tiers_schema,
		product_business_rule: product_business_rule_schema,
	});

export type PrimaryInfoSchema = InferType<typeof primary_info_schema>;

export const primary_info_schema = yup.object().shape({
	code: yup
		.string()
		.nullable()
		.label('Merchant ID')
		.required()
		.max(5, 'You can only input a max of 5 characters.')
		.matches(/^[a-z|A-Z|0-9]{1,5}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		}),
	product_code_type: yup
		.string()
		.nullable()
		.label('Partner Code Type')
		.oneOf(['AUTO', 'EXISTING']),
	product_code: yup
		.mixed()
		.nullable()
		.label('Partner Code')
		.when('product_code_type', {
			is: 'EXISTING',
			then: yup
				.mixed()
				.required('Input existing partner code.')
				.test(
					'required',
					'Input existing partner code.',
					(v: { Label: string; value: string }) => {
						return !isEmpty(v);
					}
				),
		}),
	product_primary_info: product_primary_info_schema,
	product_affiliates: yup.array().nullable().of(product_affiliate_schema),
	product_addresses: yup.array().nullable().of(product_address_schema),
	product_contact_details: yup
		.array()
		.nullable()
		.of(product_contact_detail_schema),
	product_officer: product_officer_schema,
});

export const contract_details_schema = yup.object().shape({
	product_contract: product_contract_schema,
	product_cash_collection: product_cash_collection_schema,
	product_check_collection: product_check_collection_schema,
	product_terms_duration: product_terms_duration_schema,
	product_dpo_detail: product_dpo_detail_schema,
	product_dsa_bond: product_dsa_bond_schema,
	product_accreditation: product_accreditation_schema,
});

export const business_rules_schema = yup.object().shape({
	product_business_rule: product_business_rule_schema,
});
