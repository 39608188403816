import { useForm } from 'react-hook-form';

import { InputIcon } from '@salesforce/design-system-react';

import Section, { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';
import Grid from 'components/Grid/Grid';
import Table from 'components/CWSTable/Table';
import Label from 'components/Inputs/Label/Label';

import { ReactComponent as PercentIcon } from 'assets/icons/ic-percent.svg';

import styles from './PartnerDetailsHeader.module.css';

const TIER_TABLE_WIDTH = 100 / 4;

const TIER_SF_PASS_ON_COLUMNS = [
	{
		Header: 'Tier',
		id: 'tier',
		width: `${TIER_TABLE_WIDTH}%`,
	},
	{
		Header: 'Lower Limit',
		id: 'lowerLimit',
		width: `${TIER_TABLE_WIDTH}%`,
	},
	{
		Header: 'Upper Limit',
		id: 'upperLimit',
		width: `${TIER_TABLE_WIDTH}%`,
	},
	{
		Header: 'Pass-On',
		id: 'passOnFee',
		width: `${TIER_TABLE_WIDTH}%`,
		Cell: ({ row: { original }, value }) => {
			return <div>{`${value || 0}${original?.isPercentage ? '%' : ''}`}</div>;
		},
	},
];

const SPECIAL_TABLE_WIDTH = 100 / 2;

const SPECIAL_SF_PASS_ON_COLUMNS = [
	{
		Header: 'Channel Type',
		id: 'channelType',
		width: `${SPECIAL_TABLE_WIDTH}%`,
	},
	{
		Header: 'Pass-On',
		id: 'passOnFee',
		width: `${SPECIAL_TABLE_WIDTH}%`,
		Cell: ({ row: { original }, value }) => {
			return <div>{`${value || 0}${original?.isPercentage ? '%' : ''}`}</div>;
		},
	},
];

const DetailsSection = ({ ...props }) => {
	const defaultValues = {
		partnerCode: props?.data?.partnerCode,
		serviceCode: props?.data?.serviceCode,
		category: props?.data?.category,
		sfSchemeType: props?.data?.sfSchemeType,
		sfPassOn: props?.data?.sfPassOn,
		dateActivated: props?.data?.dateActivated,
		activatedBy: props?.data?.activatedBy,
		sfPassOnTiers: props?.data?.sfPassOnTiers,
		sfPassOnSpecial: props?.data?.sfPassOnSpecial,
	};

	const { control, watch, getValues } = useForm({
		mode: 'all',
		defaultValues: defaultValues,
	});

	const sfSchemeType = getValues('sfSchemeType');

	return (
		<Section className={styles.sectionContainer}>
			<SectionRow verticalAlign="start">
				<Grid column verticalAlign="start" size={4} of={12}>
					<TextField
						label="Partner Code"
						control={control}
						disabled={true}
						name="partnerCode"
					/>
				</Grid>
				<Grid column verticalAlign="start" size={4} of={12}>
					<TextField
						label="Service Code"
						control={control}
						disabled={true}
						name="serviceCode"
					/>
				</Grid>
				<Grid column verticalAlign="start" size={4} of={12}>
					<TextField
						label="Category"
						disabled={true}
						control={control}
						name="category"
					/>
				</Grid>
			</SectionRow>
			{(sfSchemeType === 'PERCENTAGE' || sfSchemeType === 'REGULAR') && (
				<>
					<SectionRow verticalAlign="start">
						<Grid column verticalAlign="start" size={4} of={12}>
							<TextField
								label="SF Scheme Type"
								disabled={true}
								control={control}
								placeholder="Select SF Scheme Type"
								name="sfSchemeType"
							/>
						</Grid>
						{sfSchemeType === 'PERCENTAGE' && (
							<Grid column verticalAlign="start" size={4} of={12}>
								<TextField
									label="Service Fee (Pass-On)"
									disabled={true}
									control={control}
									name="sfPassOn"
									iconRight={
										sfSchemeType === 'PERCENTAGE' && (
											<InputIcon
												assistiveText={{
													icon: 'none',
												}}
												name="percent"
												category="utility"
											>
												<PercentIcon />
											</InputIcon>
										)
									}
								/>
							</Grid>
						)}
						{sfSchemeType === 'REGULAR' && (
							<Grid column verticalAlign="start" size={4} of={12}>
								<TextField
									label="Service Fee (Pass-On)"
									disabled={true}
									control={control}
									name="sfPassOn"
								/>
							</Grid>
						)}
						<Grid column verticalAlign="start" size={4} of={12}>
							<TextField
								label="Date Activated"
								control={control}
								name="dateActivated"
								disabled={true}
							/>
						</Grid>
					</SectionRow>
					<SectionRow verticalAlign="start">
						<Grid column size={4} of={12} verticalAlign="start">
							<TextField
								label="Activated By"
								control={control}
								name="activatedBy"
								disabled={true}
							/>
						</Grid>
					</SectionRow>
				</>
			)}
			{(sfSchemeType === 'TIER' || sfSchemeType === 'SPECIAL') && (
				<>
					<SectionRow verticalAlign="start">
						<Grid column verticalAlign="start" size={4} of={12}>
							<TextField
								label="SF Scheme Type"
								disabled={true}
								control={control}
								placeholder="Select SF Scheme Type"
								name="sfSchemeType"
							/>
						</Grid>
					</SectionRow>
					<Label>Service Fee (Pass-On)</Label>
					{sfSchemeType === 'TIER' && (
						<SectionRow verticalAlign="start">
							<Grid column verticalAlign="start" size={12} of={12}>
								<Table
									columns={TIER_SF_PASS_ON_COLUMNS}
									data={props?.data?.sfPassOnTiers}
									className={styles.paperContainer}
									preHeader={null}
									modalIsOpen={false}
									isNotEvenRowColor={undefined}
									overFlow="hidden"
									showPagination={false}
								/>
							</Grid>
						</SectionRow>
					)}
					{sfSchemeType === 'SPECIAL' && (
						<SectionRow verticalAlign="start">
							<Grid column verticalAlign="start" size={12} of={12}>
								<Table
									columns={SPECIAL_SF_PASS_ON_COLUMNS}
									data={props?.data?.sfPassOnSpecial}
									className={styles.paperContainer}
									preHeader={null}
									modalIsOpen={false}
									isNotEvenRowColor={undefined}
									overFlow="hidden"
									showPagination={false}
								/>
							</Grid>
						</SectionRow>
					)}
					<SectionRow verticalAlign="start">
						<Grid column size={4} of={12} verticalAlign="start">
							<TextField
								label="Activated By"
								control={control}
								name="activatedBy"
								disabled={true}
							/>
						</Grid>
						<Grid column verticalAlign="start" size={4} of={12}>
							<TextField
								label="Date Activated"
								control={control}
								name="dateActivated"
								disabled={true}
							/>
						</Grid>
					</SectionRow>
				</>
			)}
		</Section>
	);
};

export default DetailsSection;
