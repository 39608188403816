import yup from 'utils/formSchemas/common';
export const termsAndDurationSchema = yup.object({}).shape({
	executionDate: yup
		.mixed()
		.nullable(true)
		.default(null)
		.label('Execution Date')
		.test(
			'is-valid-format',
			'Input date in this format: MM/DD/YYYY.',
			(value) => {
				if (!value || value === 'Invalid date') return true;
				return /^(0[1-9]|1[0-2])[-/]([0-2][0-9]|3[0-1])[-/]([0-9]{4})$/.test(
					value
				);
			}
		),
	autoRenew: yup.string().nullable(true).label('Is Auto Renew?'),
	terminationDate: yup
		.mixed()
		.nullable(true)
		.default(null)
		.label('Termination Date')
		.when('autoRenew', {
			is: '0',
			then: (s) =>
				s.test(
					'is-valid-format',
					'Input date in this format: MM/DD/YYYY.',
					(value) => {
						if (!value || value === 'Invalid date') return true;
						return /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/.test(
							value
						);
					}
				),
		}),
	renewalFrequency: yup
		.mixed()
		.nullable(true)
		.default(null)
		.label('Renewal Frequency')
		.transform((o, c) => (o ? c : null))
		.when('autoRenew', {
			is: '1',
			then: (s) => s.required('Select Renewal Frequency.'),
		}),

	frequencyInterval: yup
		.string()
		.nullable(true) // Allow null values
		.default(null)
		.transform((o, c) => (o ? c : null))
		.when('renewalFrequency', {
			is: 'EVERY_N_YEARS',
			then: (s) =>
				s
					.required('Input Frequency Interval.')
					.matches(/^[0-9]*$/, 'Value should be 1 or any positive number.') // Additional regex validation for numeric values
					.test(
						'moreThanZero',
						'Value should be 1 or any positive number.',
						(value) => {
							if (!value) {
								return true; // Falsy value is already handled by .transform()
							}
							return parseFloat(value) > 0;
						}
					)
					.test('notExceed100', 'The maximum value is 100.', (value) => {
						if (!value) {
							return true; // Falsy value is already handled by .transform()
						}
						return parseFloat(value) <= 100;
					}),
		}),
});
