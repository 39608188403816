import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@salesforce/design-system-react';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationModal from 'components/ChannelConfirmationModal/ConfirmationModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import BranchForm from 'containers/ChannelManagement/Branch/BranchForm/BranchForm';
import { branchSchema } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchSchema';
import SuccessModal from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/SuccessModal';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import {
	postForBranch,
	putForBranchDraft,
	postForBranchSubmit,
	transformBranchFormToPayload,
} from 'containers/ChannelManagement/Branch/BranchForm/utils';
import { transformAutosaveResponseToBranchType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/utils';
import { BRANCH_DEFAULT_VALUES } from 'containers/ChannelManagement/Branch/constants';
import {
	BranchModalProps,
	Status,
} from 'containers/ChannelManagement/Branch/types';
import ErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FixMeLater } from 'types';
import { useToggle } from 'utils/hooks';
import styles from './BranchModal.module.css';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';

const BranchModal: React.FC<BranchModalProps> = ({
	autoSaveValue,
	isAutoSave,
	open,
	onClose,
	channelPrimaryInfo,
	channelContractDetails,
	selectedChannelId,
	draftsData,
	branchId,
	disabledForm = false,
	mode,
	refetch,
	notAutosavedUpdatedAt,
	notAutosavedUpdatedBy,
}) => {
	const [isSaveAsDraft, setIsSaveAsDraft] = useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	const status = useRef<Status | ''>('');
	const confirmationHeader = useRef<string>('');
	const confirmationMessage = useRef<string>('');
	const confirmationCancelBtnLabel = useRef<string>('');
	const confirmationConfirmBtnLabel = useRef<string>('');
	const errorHeader = useRef<string>('');
	const errorMessage = useRef<JSX.Element>(<></>);
	const errorButton = useRef<string>('');
	const successMessage = useRef<string | ReactNode>('');
	const successOkayLabel = useRef<string>('');

	const [stateDisabledForm, setStateDisabledForm] =
		useState<boolean>(disabledForm);

	let defaultValues: BranchType;

	if (isAutoSave) {
		defaultValues = transformAutosaveResponseToBranchType(autoSaveValue);
		defaultValues.branchBasicInformation.channelAccountName =
			channelPrimaryInfo?.name || '';
		defaultValues.branchBasicInformation.channelAccountCode =
			channelPrimaryInfo?.tpaAccount || '';
		defaultValues.branchBasicInformation.settlementSetup =
			channelPrimaryInfo?.settlementSetup || '';
	} else if (draftsData) {
		defaultValues = draftsData;
	} else {
		defaultValues = structuredClone(BRANCH_DEFAULT_VALUES);
		defaultValues.branchBasicInformation.channelAccountName =
			channelPrimaryInfo?.name || '';
		defaultValues.branchBasicInformation.channelAccountCode =
			channelPrimaryInfo?.tpaAccount || '';
		defaultValues.branchBasicInformation.settlementSetup =
			channelPrimaryInfo?.settlementSetup || '';
		defaultValues.branchBasicInformation.channelTransactionType =
			channelPrimaryInfo?.channelTransactionType || '';
		defaultValues.branchBasicInformation.channelTypeId = String(
			channelPrimaryInfo?.channelType || ''
		);
		defaultValues.branchBasicInformation.posType =
			channelPrimaryInfo?.posType || '';
		defaultValues.branchAddress.area =
			channelPrimaryInfo?.channelTransactionType === 'DIGITAL'
				? 'NATIONWIDE'
				: '';
		defaultValues.branchOperatingSchedule.depositConsolidation =
			channelContractDetails?.depositConsolidation || '';
	}

	const branchForm = useForm<BranchType>({
		mode: 'all',
		resolver: yupResolver(branchSchema),
		defaultValues,
		context: { mode },
	});

	const {
		formState: { isValid, isDirty },
		reset,
		handleSubmit,
		getValues,
	} = branchForm;

	let updatedAt = '';
	let updatedBy = '';

	if (autoSaveValue) {
		updatedAt =
			moment
				.unix(autoSaveValue.data.updatedAt)
				.format('MM/DD/YYYY hh:mm:ss a') || '';
		updatedBy = autoSaveValue.data.username || '';
	} else {
		updatedAt = notAutosavedUpdatedAt || '';
		updatedBy = notAutosavedUpdatedBy || '';
	}

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const {
		value: isShowErrorModal,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const { value: isSuccessModalOpen, valueOn: showSuccessModal } = useToggle();

	useEffect(() => {
		if (isSaveAsDraft) {
			const saveAsDraft = async (data: BranchType) => {
				const branchName = getValues('branchBasicInformation.branchName');

				if (!branchName) {
					errorHeader.current = 'Incomplete Branch Details';
					errorMessage.current = (
						<>
							<p>Branch Name is required to save this as draft.</p>
							<p>
								Please input the Branch Name under the Branch Basic Information
								section.
							</p>
						</>
					);
					errorButton.current = 'Okay';
					showErrorModal();
					return;
				}

				showLoading();
				try {
					const payload = transformBranchFormToPayload(
						data,
						'DRAFT',
						selectedChannelId
					);

					if (mode === 'ADD') {
						await postForBranch(payload, 'drafts');
					} else {
						await putForBranchDraft(payload, branchId as number);
					}

					successMessage.current = (
						<>
							<p>
								Your work was saved as draft. You may continue <br></br>your
								progress anytime by going to <b>Drafts</b> <br></br> and
								selecting the branch under the draft list.
							</p>
						</>
					);
					successOkayLabel.current = 'Done';
					showSuccessModal();
				} catch (error: FixMeLater) {
					if (error?.response?.status !== 403) {
						errorHeader.current = 'Timeout Error!';
						errorButton.current = 'Retry';
						let cignalCodeError = '';
						let pldtCodeError = '';

						if (
							error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
								'already exist'
							) ||
							error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
								'already exist'
							)
						) {
							errorHeader.current = 'Error!';
							errorButton.current = 'Okay';
							status.current = '';
							if (
								error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
									'already exist'
								)
							) {
								cignalCodeError = 'Cignal code already exist.';
							}

							if (
								error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
									'already exist'
								)
							) {
								pldtCodeError = 'Pldt code already exist. ';
							}
						}

						errorMessage.current = (
							<>
								<p>A problem occurred while saving as draft.</p>
								{cignalCodeError && <p>{cignalCodeError}</p>}
								{pldtCodeError && <p>{pldtCodeError}</p>}
								<p>Please try again.</p>
							</>
						);

						showErrorModal();
					}
				}
				hideLoading();
			};

			saveAsDraft(getValues());
			setIsSaveAsDraft(false);
		}
	}, [
		branchId,
		getValues,
		hideLoading,
		isSaveAsDraft,
		mode,
		selectedChannelId,
		showErrorModal,
		showLoading,
		showSuccessModal,
	]);

	useEffect(() => {
		if (isSubmit) {
			const submit = async (data: BranchType) => {
				showLoading();
				try {
					const payload = transformBranchFormToPayload(
						data,
						'ACTIVE',
						selectedChannelId
					);

					if (mode === 'ADD') {
						await postForBranch(payload, 'submit');
					} else {
						await postForBranchSubmit(payload, branchId as number);
					}

					successMessage.current = (
						<>
							<p className={styles.successMessage}>
								You have created a new branch.
							</p>
						</>
					);
					successOkayLabel.current = 'Okay';
					showSuccessModal();
				} catch (e: FixMeLater) {
					if (e?.response?.status !== 403) {
						const error = e as FixMeLater;
						errorHeader.current = 'Timeout Error!';
						errorButton.current = 'Retry';
						let cignalCodeError = '';
						let pldtCodeError = '';

						if (
							error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
								'already exist'
							) ||
							error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
								'already exist'
							)
						) {
							errorHeader.current = 'Error!';
							errorButton.current = 'Okay';
							status.current = '';
							if (
								error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
									'already exist'
								)
							) {
								cignalCodeError = 'Cignal code already exist.';
							}

							if (
								error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
									'already exist'
								)
							) {
								pldtCodeError = 'Pldt code already exist. ';
							}
						}

						errorMessage.current = (
							<>
								<p>A problem occurred while adding the branch.</p>
								{cignalCodeError && <p>{cignalCodeError}</p>}
								{pldtCodeError && <p>{pldtCodeError}</p>}
								<p>Please try again.</p>
							</>
						);

						showErrorModal();
					}
				}
				hideLoading();
			};

			handleSubmit(
				(data) => {
					submit(data);
				},
				() => {
					errorHeader.current = 'Submit Error!';
					errorMessage.current = <p>Please check inputs with red box.</p>;
					errorButton.current = 'Retry';
					showErrorModal();
				}
			)();

			setIsSubmit(false);
		}
	}, [
		branchId,
		handleSubmit,
		hideLoading,
		isSubmit,
		mode,
		selectedChannelId,
		showErrorModal,
		showLoading,
		showSuccessModal,
	]);

	const updateCurrentRefs = (
		currentStatus: Status,
		currentConfirmationHeader: string,
		currentConfirmationMessage: string,
		currentConfirmationCancelBtnLabel: string,
		currentConfirmationConfirmBtnLabel: string
	) => {
		status.current = currentStatus;
		confirmationHeader.current = currentConfirmationHeader;
		confirmationMessage.current = currentConfirmationMessage;
		confirmationCancelBtnLabel.current = currentConfirmationCancelBtnLabel;
		confirmationConfirmBtnLabel.current = currentConfirmationConfirmBtnLabel;
	};

	const { hasEditDraftPermission } = useTerminal();

	const confirmMessageElement = (
		<>
			<div className={styles.bodyHeader}>
				<p>{confirmationMessage.current}</p>
			</div>
		</>
	);

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={() => {
					if (mode === 'ADD') {
						updateCurrentRefs(
							'EXITING',
							'Are you done?',
							`You can continue your progress by saving this as draft,
							otherwise you may need to create a new registration.
							Would you like to save as draft?`,
							'No',
							'Yes, save as draft'
						);
					} else {
						updateCurrentRefs(
							'EXITING',
							'Save as Draft',
							`Would you like to save as draft?`,
							'No',
							'Yes, save as draft'
						);
					}

					if (stateDisabledForm) {
						onClose?.();
					} else {
						if (isDirty) {
							showConfirmationModal();
						} else {
							onClose?.();
						}
					}
				}}
				headerClassName={styles.headerContainer}
				contentClassName={styles.modal}
				size="medium"
				heading={
					<div className={styles.header}>
						<div className={styles.titleLeftPanel}>
							<div className={styles.titleTextContainer}>
								{'Add New Branch'}
							</div>
						</div>
						<div className={styles.titleRightPanel}>
							<div className={styles.titleActionBtnContainer}>
								<OutlineButton
									onClick={() => {
										if (stateDisabledForm && !hasEditDraftPermission) {
											return showPermissionErrorModal();
										}
										if (stateDisabledForm) {
											setStateDisabledForm(false);
											return;
										}
										updateCurrentRefs(
											'DRAFTING',
											'Save as Draft',
											'Would you like to save this as draft?',
											'Back',
											'Yes'
										);
										showConfirmationModal();
									}}
									className={styles.btn}
								>
									<SaveDraftIcon className={styles.btnIcon} />
									{stateDisabledForm ? 'Edit Branch Details' : 'Save as Draft'}
								</OutlineButton>
							</div>
							<div className={styles.titleActionTextContainer}>
								{updatedAt && updatedBy && (
									<em className={styles.titleActionText}>
										{'Last updated at ' + updatedAt}
										{' by ' + updatedBy}
									</em>
								)}
							</div>
						</div>
					</div>
				}
				footer={
					<div className={styles.footer}>
						<div className={styles.footerRightPanel}>
							<PrimaryButton
								onClick={() => {
									updateCurrentRefs(
										'SUBMITTING',
										'Add Branch',
										'Are you sure you want to add branch?',
										'Cancel',
										'Confirm'
									);
									showConfirmationModal();
								}}
								className={styles.btn}
								disabled={stateDisabledForm || !isValid}
							>
								Submit
							</PrimaryButton>
						</div>
					</div>
				}
			>
				<FormProvider {...branchForm}>
					<BranchForm
						isModal={true}
						branchBasicInformationProp={{
							channelPrimaryInfo: channelPrimaryInfo,
							selectedChannelId: selectedChannelId,
							isDisabledAllEditableFields: stateDisabledForm,
						}}
						branchAddress={{ isDisabledAllEditableFields: stateDisabledForm }}
						branchContactDetails={{ disabled: stateDisabledForm }}
						operatingScheduleProps={{ isDisabled: stateDisabledForm }}
						mode={mode}
						refetch={refetch}
					/>
				</FormProvider>
			</Modal>
			{isLoading && (
				<FullPageLoader
					open={isLoading}
					message={'Please wait while new branch is being saved'}
				/>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading={confirmationHeader.current}
					message={confirmMessageElement}
					onClose={() => {
						hideConfirmationModal();
					}}
					onCancelBtnClick={() => {
						hideConfirmationModal();
						if (status.current === 'EXITING') {
							onClose?.();
							reset(BRANCH_DEFAULT_VALUES);
						}
					}}
					onConfirmBtnClick={() => {
						hideConfirmationModal();

						if (status.current === 'DRAFTING' || status.current === 'EXITING') {
							setIsSaveAsDraft(true);
						}
						if (status.current === 'SUBMITTING') {
							setIsSubmit(true);
						}
					}}
					cancelBtnLabel={confirmationCancelBtnLabel.current}
					confirmBtnLabel={confirmationConfirmBtnLabel.current}
					containerClassName={styles.containerWidth}
				/>
			)}
			{isShowErrorModal && (
				<ErrorModal
					open={isShowErrorModal}
					onClose={hideErrorModal}
					errorHeader={errorHeader.current}
					errorMessage={errorMessage.current}
					onRetry={() => {
						hideErrorModal();

						if (status.current === 'DRAFTING') {
							setIsSaveAsDraft(true);
						}
						if (status.current === 'SUBMITTING') {
							setIsSubmit(true);
						}
					}}
					errorButton={errorButton.current}
				/>
			)}
			{isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={() => onClose?.()}
					successMessage={successMessage.current}
					onOkay={() => onClose?.()}
					okayLabel={successOkayLabel.current}
				></SuccessModal>
			)}
			{hasPermission && (
				<AccessDeniedModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				/>
			)}
		</>
	);
};

export default BranchModal;
