import cx from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from './PartnerProfile.module.css';
import { useToggle } from '../../../utils/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';
import PartnerList from 'components/PartnerList/PartnerList';
import PartnerTab from 'components/PartnerList/PartnerTab';
import PartnerModal from 'components/PartnerModal/PartnerModal';
import { ReactComponent as WarningIcon } from 'assets/icons/ic-warning.svg';
import { useEffect, useMemo, useState } from 'react';
import client from 'helpers/ApiClient';
import {
	TProductState,
	setAutoSaveValues,
	setTrackPrimaryInfo,
	setPrimaryInfo,
	clearFormStates,
} from 'redux/modules/products';
import { isEmpty } from 'lodash';
import Alert from 'components/Alert/Alert';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { mockPartner } from 'constants/samplePayloads';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

interface Props {
	showAccessDeniedModal: () => void;
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['setAutoSaveValues'];
	products: Array<any>;
	currentProductId?: string;
	isSetSampleValues: boolean;
	isFormEdited?: boolean;
}

//to fix models later
export const default_value: any = {
	primary_info: {
		code: '', // merchant id
		product_code_type: 'AUTO',
		product_code: '',
		logo_url: '',
		product_primary_info: {},
		product_affiliates: [],
		product_addresses: [],
		product_contact_details: [],
		product_officer: {},
	},
	product_contract: {
		product_contract: {},
		product_cash_collection: {},
		product_check_collection: {},
		product_terms_duration: {},
		product_dpo_detail: {},
		product_dsa_bond: {},
		product_accreditation: {},
	},
	product_service_fee: {},
	product_service_fee_tiers: {},
	product_business_rule: {},
};

const PartnerProfile: React.FC<Props> = ({
	showAccessDeniedModal,
	autoSaveValues,
	setAutoSaveValues,
	products,
	currentProductId,
	isSetSampleValues,
	isFormEdited,
}) => {
	const dispatch = useDispatch();
	const {
		value: isAddPartnerProfileModalShowing,
		valueOn: showAddPartnerProfileModal,
		valueOff: hideAddPartnerProfileModal,
	} = useToggle();

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission('products');

	const hasAddPartnerPermission = useMemo(
		() => hasUserPermission(productCategory, 'add.partner'),
		[hasUserPermission, productCategory]
	);

	const openAddNewPartnerModal = () => {
		setAutoSaveValues({});
		dispatch(setTrackPrimaryInfo({}));
		dispatch(setPrimaryInfo({}));
		setFromAutoSave(false);
		showAddPartnerProfileModal();
	};

	const handleAddNewPartnerOnClick = async () => {
		if (hasAddPartnerPermission) {
			if (isFormEdited) {
				setConfirmationAction({ go: openAddNewPartnerModal });
				showConfirmationModal();
				return;
			}
			openAddNewPartnerModal();
		} else {
			showAccessDeniedModal();
		}
	};

	useEffect(() => {
		setAutoSaveValues({});
		client.get('v2/autosave/products').then(({ data }) => {
			const values =
				data.data.primary_info?.originalValues ?? data.data.primary_info;
			const productType = data.data.product_type;
			default_value.autoSavedData = {
				date: data?.data?.created_at,
				username: data?.data?.username,
			};
			setAutoSaveValues({ ...values, product_type: productType });
		});
	}, []);

	const cancelAutoSave = () => {
		client.delete(`v2/autosave/products`).then(() => {
			setAutoSaveValues({});
		});
	};

	const checkCurrentProduct = () => {
		if (!isEmpty(autoSaveValues)) {
			return (
				autoSaveValues.product_type ===
				products.find((p) => p.id == currentProductId)?.code
			);
		}
		return false;
	};

	const _defaultValues = isSetSampleValues ? mockPartner : default_value;

	const [isfromAutoSave, setFromAutoSave] = useState(false);

	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();
	const [confirmationAction, setConfirmationAction] = useState<{
		go: () => void;
	}>();

	return (
		<>
			<div className={cx('slds-card', styles.paperContainer)}>
				{!isEmpty(autoSaveValues) && checkCurrentProduct() && (
					<Alert
						bodyText="You have unfinished work
				for adding a new partner. Would you like to continue?"
						cancelFn={cancelAutoSave}
						confirmFn={() => {
							setFromAutoSave(true);
							showAddPartnerProfileModal();
						}}
						iconLeft={() => <WarningIcon />}
					/>
				)}

				<div className={cx(styles.title)}>Partner Profile</div>
				<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
					<PartnerList addNewPartnerButtonClick={handleAddNewPartnerOnClick} />
					<PartnerTab
						selectedIndex={selectedIndex}
						setSelectedIndex={setSelectedIndex}
						showConfirmationModal={showConfirmationModal}
						setConfirmationAction={setConfirmationAction}
					/>
				</div>
			</div>
			{isAddPartnerProfileModalShowing && (
				<PartnerModal
					open={isAddPartnerProfileModalShowing}
					onClose={hideAddPartnerProfileModal}
					data={_defaultValues}
					action="ADD"
					fromAutoSave={isfromAutoSave}
				/>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading="Exit Edit Mode?"
					message={
						<>
							<div>Are you sure you want to exit Edit Mode?</div>
						</>
					}
					onClose={hideConfirmationModal}
					onCancelBtnClick={hideConfirmationModal}
					onConfirmBtnClick={() => {
						hideConfirmationModal();
						confirmationAction?.go();
					}}
				/>
			)}
		</>
	);
};

export default connect(
	(state: any) => ({
		scopes: state.userInfo.scopes,
		autoSaveValues: state.products.autoSaveValues,
		products: state.sidebar.products,
		currentProductId: state.sidebar.itemId,
		isSetSampleValues: state.products.isSetSampleValues,
		isFormEdited:
			state.products.primaryInfo?.isDirty ||
			state.products.contractDetails?.isDirty ||
			state.products.reports?.isDirty ||
			state.products.serviceFeeSettings?.isDirty ||
			state.products.businessRules?.isDirty,
	}),
	{ showAccessDeniedModal, setAutoSaveValues }
)(PartnerProfile);