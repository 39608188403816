import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import {
	EmailTagInput,
	ContactNumberTagInput,
	TelephoneNumberTagInput,
	MobileNumberTagInput,
} from 'components/TagInput/TagInput';
import { Control } from 'react-hook-form';
import styles from './AccountOfficer.module.css';
import { product_officer } from 'utils/models/product_officer';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	data?: product_officer;
};

const AccountOfficer: React.FC<Props> = ({ control, disabled, data }) => {
	const name = 'product_officer';
	return (
		<Section title="Account Officer">
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						required
						label="Name"
						name={`${name}.name`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<EmailTagInput
						required
						control={control}
						name={`${name}.email`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TelephoneNumberTagInput
						control={control}
						name={`${name}.tel_no`}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<MobileNumberTagInput
						required
						secondarySubtext="* Mobile Number should start with +639"
						control={control}
						name={`${name}.mobile_no`}
						disabled={disabled}
						validateType="639"
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default AccountOfficer;
