import React from 'react';
import { startCase } from 'lodash';

import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';

import { formatCash, formatDate, strToNumber } from 'utils/common';

import { DetailsSectionProps } from '../types';
import style from '../TransactionMgt.module.css';

const DetailsSection: React.FC<DetailsSectionProps> = ({ transaction }) => {
	const {
		createdAt,
		trn,
		crn,
		arn,
		channelName,
		branchName,
		tpaid,
		productType = '',
		billerName,
		amount,
		fee,
		totalAmount,
		api3Status,
		bfaStatus = '',
		errorDetails,
	} = transaction || {};
	return (
		<Section className={style.fields}>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						label="Date and Time Created"
						value={formatDate(createdAt)}
						disabled
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<TextField label="Transaction Reference No." value={trn} disabled />
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField label="Client Reference No." value={crn} disabled />
				</Grid>
				<Grid column size={1} of={2}>
					<TextField label="Account No." value={arn} disabled />
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField label="Channel Partner" value={channelName} disabled />
				</Grid>
				<Grid column size={1} of={2}>
					<TextField label="Branch Name" value={branchName} disabled />
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField label="TPAID" value={tpaid} disabled />
				</Grid>
				<Grid column size={1} of={2}>
					<TextField
						label="Product Type"
						value={startCase(productType?.toLowerCase())}
						disabled
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField label="Product Partner Name" value={billerName} disabled />
				</Grid>
				<Grid column size={1} of={2}>
					<TextField
						label="Amount"
						value={formatCash(strToNumber(amount))}
						disabled
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						label="Service Fee"
						value={formatCash(strToNumber(fee))}
						disabled
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<TextField
						label="Total Amount"
						value={formatCash(strToNumber(totalAmount))}
						disabled
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						label="API 3.0 Status"
						value={startCase(api3Status?.toLowerCase())}
						disabled
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<TextField
						label="BFA Status"
						value={startCase(bfaStatus?.toLowerCase())}
						disabled
					/>
				</Grid>
			</SectionRow>
			{(api3Status === 'FAILED' || errorDetails) && (
				<SectionRow>
					<Grid column>
						<TextField
							label="Error Details"
							value={errorDetails}
							multiLine
							disabled
							textAreaHeight={'131px'}
							className={style.multiLine}
						/>
					</Grid>
				</SectionRow>
			)}
		</Section>
	);
};

export default DetailsSection;
