import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';

import styles from './BillerStatus.module.css';
import PanelPlaceholder from './BillerTabStatusPanelPlaceholder'

const BillerTab: React.FC = () => {

   return(
    <div className="slds-col slds-large-size_9-of-12">
            <Tabs
				variant="scoped"
				id="tabs-example-scoped"
				className={styles.tabs}
				defaultSelectedIndex={1}
			>
				<TabsPanel label="Biller Performance">
					<PanelPlaceholder />
				</TabsPanel>
				<TabsPanel label="Biller Details">
					{/* <PanelPlaceholder /> */}
					{/* <BillerDetails /> */}
				</TabsPanel>
				<TabsPanel label="Audit Trail">
					<PanelPlaceholder />
				</TabsPanel>
			</Tabs>
    </div>
   )
}

export default BillerTab;