import client from 'helpers/ApiClient';
import { UseQueryResult, useQueries, useQuery } from 'react-query';
import {
	GET_CHANNEL_DETAILS_PRIMARY_INFORMATION,
	GET_CHANNEL_DETAILS_CONTRACT_INFO,
	GET_CHANNEL_DETAILS_REPORT_INFO,
	GET_BRANCH_DETAILS,
	GET_TERMINAL_DETAILS,
	GET_CHANNEL_AUTO_SAVE,
	GET_BRANCH_AUTO_SAVE,
	GET_TERMINAL_AUTO_SAVE,
} from './const';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import { PermissionObject } from '../ChannelDetails/ChannelDetails';
import { useHasUserPermission } from 'utils/permissions';

export const useGetChannelDetails = (channelId: number): any => {
	const hasUserPermission = useHasUserPermission('channels');
	const hasView: PermissionObject = {
		primaryInfo: hasUserPermission('view.primary-info'),
		contractDetails: hasUserPermission('view.contract-details'),
		reports: hasUserPermission('view.reports'),
	};
	return useQueries([
		{
			queryKey: [GET_CHANNEL_DETAILS_PRIMARY_INFORMATION, channelId],
			queryFn: async () => {
				const response = await client.get(
					`v2/channels/${channelId}/primary-info`
				);
				return response.data;
			},
			enabled: !!channelId,
			retry: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		},
		{
			queryKey: [GET_CHANNEL_DETAILS_CONTRACT_INFO, channelId],
			queryFn: async () => {
				const response = await client.get(
					`v2/channels/${channelId}/contract-details`
				);
				return response.data;
			},
			enabled: !!channelId && hasView?.contractDetails,
			staleTime: 0,
			retry: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		},
		{
			queryKey: [GET_CHANNEL_DETAILS_REPORT_INFO, channelId],
			queryFn: async () => {
				const response = await client.get(`v2/channels/${channelId}/reports`);
				return response.data;
			},
			enabled: !!channelId && hasView?.reports,
			staleTime: 0,
			retry: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		},
	]);
};

export const UseGetBranchDetails = (
	branchId: number
): UseQueryResult<BranchResponseType, unknown> => {
	return useQuery(
		[GET_BRANCH_DETAILS, branchId],
		async () => {
			const response = await client.get<BranchResponseType>(
				`v2/branches/${branchId}`
			);
			return response.data;
		},
		{
			enabled: !!branchId,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const UseGetTerminalDetails = (terminalId: number) => {
	return useQuery(
		[GET_TERMINAL_DETAILS, terminalId],
		async () => {
			const response = await client.get(`v2/terminals/${terminalId}`);
			return response.data;
		},
		{
			enabled: !!terminalId,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useGetChannelAutoSave = () => {
	return useQuery([GET_CHANNEL_AUTO_SAVE], async () => {
		const response = await client.get(`/v2/autosave/channels`);
		return response.data;
	});
};

export const useGetBranchAutoSave = (activeType: string) => {
	return useQuery([GET_BRANCH_AUTO_SAVE], async () => {
		const response = await client.get(`/v2/autosave/branches`);
		return response.data;
	});
};

export const useGetTerminalAutoSave = (activeType: string) => {
	return useQuery(
		[GET_TERMINAL_AUTO_SAVE],
		async () => {
			const response = await client.get(`/v2/autosave/terminals`);
			return response.data;
		},
		{ enabled: activeType === 'branch' }
	);
};
