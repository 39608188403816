import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { FixMeLater } from 'types';

export function isTruthy(value: any): boolean {
	if (!value) return false;
	if (typeof value === 'string') return !!value.trim();
	return !!value;
}

export function strToNumber(amount: any): number {
	if (!isTruthy(amount)) return 0;
	if (typeof amount === 'number') return amount;
	if (typeof amount !== 'string') return 0;
	return +amount.trim().replace(/[\s,]/g, '');
}

export function restrictSpecialChars(specialChars) {
	const textarea = specialChars.target;
	const inputValue = textarea.value;
	const regex = /[/;<>=]/g;
	const sanitizedValue = inputValue.replace(regex, '');
	textarea.value = sanitizedValue;
}

export function resolveValue<T>(
	value: T,
	defaultValue: any = undefined
): T | undefined {
	return isTruthy(value) ? value : defaultValue;
}

export function resolveRecord<T>(
	value: T,
	defaultValue: any = undefined
): T | undefined {
	return _.isEmpty(value) ? defaultValue : value;
}

export function capitalize(s: string, defaultString = ''): string {
	return (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || defaultString;
}

export function formatCash(s: FixMeLater, decimalPlace = 2): string {
	const val: string = Intl.NumberFormat('en-US', {
		minimumFractionDigits: decimalPlace,
		maximumFractionDigits: decimalPlace,
	}).format(s);

	// if NaN, fallback to passed value
	return val != 'NaN' ? val : s;
}

export function formatSeparator(num: string | number): string {
	const str = num.toString().split('.');
	str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return str.join('.');
}

export const DATE_FORMAT_ISO_8601 = 'YYYY-MM-DD';
export const formatDate = (
	date: Date | string,
	format = 'MM/DD/YYYY hh:mm:ss A'
): string | null => {
	const hasDate = !!date || date !== undefined;
	const isValidDate = moment(date).isValid();

	return hasDate && isValidDate ? moment(date).format(format) : null;
};

export const pagination = (current: number, total: number): any => {
	const list: any = [];
	const pageLimit = 5;
	let upperLimit, lowerLimit;
	let cursor;
	const currentPage = (lowerLimit = upperLimit = Math.min(current, total));

	for (let b = 1; b < pageLimit && b < total; ) {
		if (lowerLimit > 1) {
			lowerLimit--;
			b++;
		}
		if (b < pageLimit && upperLimit < total) {
			upperLimit++;
			b++;
		}
	}

	for (let i = lowerLimit; i <= upperLimit; i++) {
		if (i == currentPage) {
			cursor = i;
		}
		list.push(i);
	}

	return { list, cursor };
};

export const filterObjectEmpty = (obj): any => {
	for (const propName in obj) {
		if (isEmpty(obj[propName])) {
			delete obj[propName];
		}
	}
	return obj;
};

export const isNumeric = (str: any): boolean => {
	if (str.includes(',')) str = str.replaceAll(',', '');
	return !isNaN(str) && !isNaN(parseFloat(str));
};

type FormatValues = 'CAMEL' | 'SNAKE';

const formattingKey = (key: string, format?: FormatValues): string => {
	if (format === 'SNAKE') return _.snakeCase(key);
	else if (format === 'CAMEL') return _.camelCase(key);
	return key;
};

export const formatObjectKeys = (
	obj: Record<string, unknown>,
	format: FormatValues
): any =>
	_.transform(
		obj,
		(result: Record<string, unknown>, value: unknown, key: string, target) => {
			const targetKey = _.isArray(target) ? key : formattingKey(key, format);
			result[targetKey] = _.isObject(value)
				? formatObjectKeys(value as Record<string, unknown>, format)
				: value;
		}
	);

export const replaceDeepKeys = (
	obj: Record<string, unknown>,
	regex: RegExp,
	replaceText = '',
	format?: FormatValues,
	reformatFields?: string[]
): any =>
	_.transform(
		obj,
		(result: Record<string, unknown>, value: unknown, key: string, target) => {
			const tragetKey = _.isArray(target)
				? key
				: formattingKey(key.replace(regex, replaceText), format);
			result[tragetKey] = _.isObject(value)
				? replaceDeepKeys(
						value as Record<string, unknown>,
						regex,
						replaceText,
						format,
						reformatFields
				  )
				: reformatFields?.includes(tragetKey) &&
				  _.isString(tragetKey) &&
				  _.isString(value)
				? value?.replaceAll(',', '')
				: value;
		}
	);

export const replaceNoValuesObj = (
	obj: Record<string, unknown>
): Record<string, unknown> =>
	_.isEmpty(obj)
		? {}
		: _.transform(
				obj,
				(result: Record<string, unknown>, value: unknown, key: string) => {
					const targetKey = key;

					if (_.isArray(value)) {
						const arrayCopy: Record<string, unknown>[] = [];
						for (let i = 0; i < value.length; i++) {
							const v = _.isObject(value[i])
								? replaceNoValuesObj(value[i] as Record<string, unknown>)
								: typeof value === 'string' && _.isEmpty(value)
								? null
								: value[i];

							if (v) arrayCopy.push(v);
						}
						result[targetKey] = _.isEmpty(arrayCopy) ? null : arrayCopy;
					} else {
						result[targetKey] = _.isObject(value)
							? replaceNoValuesObj(value as Record<string, unknown>)
							: typeof value === 'string' && _.isEmpty(value)
							? null
							: value;
					}
				}
		  );

export const sleep = (ms: number): Promise<FixMeLater> =>
	new Promise((resolve) => setTimeout(resolve, ms));

export const downloadFile = (download_url: string, fileName: string): void => {
	fetch(download_url)
		.then((resp) => resp.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		});
};

export const getMonthName = (value: number | string): string | undefined => {
	const mValue = moment(value, 'MM');
	if (value && mValue.isValid()) {
		return mValue.format('MMMM');
	}
	return undefined;
};

export const noWhiteSpace = (): FixMeLater => {
	const test = {
		name: 'noWhiteSpace',
		test: (value) => {
			let result = true;
			if (value?.length !== 0) {
				result = value?.trim().length !== 0;
			}
			return result;
		},
		message: ({ label }) => `Input ${label}`,
	};
	return test;
};

export const formatParams = (params) => {
	const { dateFrom, dateTo, page, limit, ...rest } = params;
	const cleanParams = Object.fromEntries(
		Object.entries(rest).filter(([_, value]) => value && value !== 'NaN')
	);

	if (dateFrom && moment(dateFrom).isValid()) {
		cleanParams.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
	}

	if (dateTo && moment(dateTo).isValid()) {
		cleanParams.dateTo = moment(dateTo).format('YYYY-MM-DD');
	}

	if (page && page !== 1) {
		cleanParams.page = page;
	}

	if (limit && limit !== 25) {
		cleanParams.limit = limit;
	}

	return cleanParams;
};

export const formatPhoneNumber = (phoneNumber?: string): string => {
	if (!phoneNumber) return '';

	if (phoneNumber.length >= 10) {
		return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
			2,
			5
		)} ${phoneNumber.slice(5)}`;
	} else {
		return phoneNumber;
	}
};

export const timeFormat12Hr = (time: any) => {
	const [timePart, period] = time.split(' ');
	const [hours, minutes] = timePart.split(':');
	let formattedHours = parseInt(hours, 10);

	if (period === 'PM' && formattedHours < 12) {
		formattedHours += 12;
	} else if (period === 'AM' && formattedHours === 12) {
		formattedHours = 0;
	}

	const finalTime =
		(formattedHours < 10 ? '0' + formattedHours : formattedHours) +
		':' +
		minutes;

	return finalTime;
};
