import { Modal } from '@salesforce/design-system-react/module/components';
import { ReactComponent as Icon } from 'assets/icons/ic-download3.svg';
import styles from './FileTypeSelectModal.module.css';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';

type FileTypeSelectModalProps = {
	open?: boolean;
	onClose?: () => void;
	handleDownload?: (fileType: string) => void;
	confirmationMsg?: string;
};

const FileTypeSelectModal: React.FC<FileTypeSelectModalProps> = ({
	open,
	onClose,
	handleDownload,
	confirmationMsg,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={styles.header}
			containerClassName={styles.modalContainer}
			contentClassName={cx(styles.modal)}
		>
			<Grid vertical verticalAlign="center" className={styles.container}>
				{Icon && (
					<Grid column className={styles.iconContainer}>
						<Icon />
					</Grid>
				)}
				<div className={styles.modalBody}>
					<Grid column className={styles.downloadText}>
						Download
					</Grid>
					{confirmationMsg ? (
						<>
							<Grid className={styles.padBottom} column>
								{confirmationMsg}
							</Grid>
							<Grid className={styles.button}>
								<PrimaryButton onClick={() => onClose && onClose()}>
									Done
								</PrimaryButton>
							</Grid>
						</>
					) : (
						<>
							<Grid className={styles.padBottom} column>
								Choose file format you want to download
							</Grid>
							<Grid vertical={false}>
								<Grid className={styles.button}>
									<PrimaryButton
										onClick={() => handleDownload && handleDownload('XLSX')}
									>
										Download as XLSX
									</PrimaryButton>
								</Grid>
								<Grid className={styles.button}>
									<PrimaryButton
										onClick={() => handleDownload && handleDownload('PDF')}
									>
										Download as PDF
									</PrimaryButton>
								</Grid>
							</Grid>
						</>
					)}
				</div>
			</Grid>
		</Modal>
	);
};

export default FileTypeSelectModal;
