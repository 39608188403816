import styles from './Smtp.module.css';
import Section, { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import Grid from 'components/Grid/Grid';
import { useEffect, useState } from 'react';
import { useToggle } from 'utils/hooks';
import { FileFormat, UploadSchedule } from 'utils/lookup';
import TimeInput from 'components/Inputs/TimeField/TimeField';
import { EmailTagInput } from 'components/TagInput/TagInput';

type billerUploadSettingCommon = {
	fileFormat: string;
	generationTime: string;
	uploadSched?: string;
	uploadFreq: string;
	uploadTime?: string;
};

export type billerUploadSFTP = {
	username?: string;
	password?: string;
	host?: string;
	port?: string;
	path?: string;
	csrConfig?: billerUploadSettingCommon;
	uploadConfig?: billerUploadSettingCommon;
};

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	showOtherIndustry?: boolean;
	showOtherLegalStructure?: boolean;
	disabled?: boolean;
};

function useToggleOthersField(
	value: any,
	predicate: (v: any) => boolean,
	callback?: () => void
) {
	const {
		value: isOthersShown,
		valueOn: showOtherIndustry,
		valueOff: hideOtherIndustry,
	} = useToggle();

	useEffect(() => {
		if (predicate(value)) {
			showOtherIndustry();
		} else {
			hideOtherIndustry();
			callback && callback();
		}
	}, [value]);

	return isOthersShown;
}

const Smtp: React.FC<Props> = ({ control, setValue, disabled }) => {
	return (
		<>
			<Section title="SMTP">
				{/* <SectionRow className={styles.emptyRow}></SectionRow> */}
			</Section>

			<Section title="Collection Summary Report (CSR) Configuration">
				<SectionRow verticalAlign="end">
					<Grid column size={1} of={3} className={styles.grid}>
						<SelectFieldWithOthers
							label="Filename Format"
							disabled={disabled}
							required
							control={control}
							name="smtpInformation.csrConfigFileFormat"
							options={FileFormat.map((i) => ({
								label: i,
								value: i,
							}))}
						/>
					</Grid>
					<Grid
						container
						column
						verticalAlign="start"
						size={1}
						of={3}
						className={styles.grid}
					>
						<SelectFieldWithOthers
							label="Upload Schedule"
							disabled={disabled}
							required
							control={control}
							name="smtpInformation.csrConfigUploadSched"
							options={UploadSchedule}
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label="Generation Time"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.csrConfigGenerationTime"
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TextField
							label="Upload Frequency"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.csrConfigUploadFreq"
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label="Time of Upload"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.csrConfigUploadTime"
						/>
					</Grid>
				</SectionRow>
			</Section>

			<Section title="Select Columns for the CSR Report Template">
				<SectionRow verticalAlign="end"></SectionRow>
			</Section>

			<Section title="Upload Configuration">
				<SectionRow verticalAlign="end">
					<Grid column size={1} of={3} className={styles.grid}>
						<SelectFieldWithOthers
							label="Filename Format"
							disabled={disabled}
							required
							control={control}
							name="smtpInformation.uploadConfigFileFormat"
							options={FileFormat.map((i) => ({
								label: i,
								value: i,
							}))}
						/>
					</Grid>
					<Grid
						container
						column
						verticalAlign="start"
						size={1}
						of={3}
						className={styles.grid}
					>
						<SelectFieldWithOthers
							label="Upload Schedule"
							disabled={disabled}
							required
							control={control}
							name="smtpInformation.uploadConfigUploadSched"
							options={UploadSchedule}
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label="Generation Time"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.uploadConfigGenerationTime"
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TextField
							label="Upload Frequency"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.uploadConfigUploadFreq"
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label="Time of Upload"
							required
							disabled={disabled}
							control={control}
							name="smtpInformation.uploadConfigUploadTime"
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={2}>
						<EmailTagInput
							required
							control={control}
							name="smtpInformation.senderEmails"
							label="Sender Email Address"
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={2}>
						<EmailTagInput
							required
							control={control}
							name="smtpInformation.receiverEmails"
							label="Receiver Email Address"
							disabled={disabled}
						/>
					</Grid>
				</SectionRow>
			</Section>
		</>
	);
};

export default Smtp;
