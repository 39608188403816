import ErrorModalOriginal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import styles from './ErrorModal.module.css';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { ErrorModalProps } from 'containers/ChannelManagement/Drafts/ErrorModal/types';

const ErrorModal: React.FC<ErrorModalProps> = ({
	open,
	onClose,
	errorHeader,
	errorMessage,
	errorInstruction,
	onRetry,
	errorButton,
}) => {
	return (
		<>
			<ErrorModalOriginal open={open} onClose={onClose}>
				<ErrorModalBody>
					<div className={styles.errorHeader}>{errorHeader}</div>
					<div className={styles.errorBody}>{errorMessage}</div>
					<div className={styles.errorFooter}>{errorInstruction}</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={() => (errorButton === 'Okay' ? onClose?.() : onRetry?.())}
						className={styles.errorModalBtn}
					>
						{errorButton ? errorButton : 'Retry'}
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModalOriginal>
		</>
	);
};

export default ErrorModal;
