import React, { useEffect, useState } from 'react';
import CashFlowFilter from './CashFlowFilter';
import CashFlowTable from './CashFlowTable';
import HTTP from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { filterObjectEmpty, formatDate, formatCash } from 'utils/common';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import FileTypeSelectModal from 'components/Modal/FileTypeSelectModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './CashFlow.module.css';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useDispatch } from 'react-redux';
import { CashFlowServiceTypes, CashFlowThresholdStatuses } from 'utils/lookup';
import csvIcon from 'assets/icons/ic-csv.svg';
import ResponseModal from 'components/Modal/ResponseModal';
import { calibrateDate } from 'utils/date';

const resolveValue = (value, defaultValue = undefined) => {
	return value || defaultValue;
};

const INITIAL_VALUES = {
	dateFrom: undefined,
	dateTo: undefined,
	requestNumber: '',
	channelName: '',
	transactionType: '',
	thresholdStatus: '',
};

type TransactionType = 'total_amount_credit' | 'total_amount_debit';

const Cell: React.FC<{ value: any }> = ({ value }) => (
	<div className={styles.rowBreak}>{value}</div>
);
type GetCashFlowsArgs = {
	page?: number;
	pageSize?: number;
	limit?: number;
	sortBy?: string;
	sort?: string;
	dateCreatedFrom?: string | null;
	dateCreatedTo?: string | null;
	partnerName?: string;
	transactionType?: string;
	thresholdStatus?: string;
	requestNumber?: string;
};

type FetchCashFlowsArgs = Omit<
	GetCashFlowsArgs,
	'dateCreatedFrom' | 'dateCreatedTo'
> & {
	dateFrom?: GetCashFlowsArgs['dateCreatedFrom'];
	dateTo?: GetCashFlowsArgs['dateCreatedTo'];
};

async function getCashFlows(values: GetCashFlowsArgs = {}) {
	const {
		page = 1,
		limit = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName,
		transactionType,
		thresholdStatus,
		requestNumber,
	} = values;

	const params = {
		sortBy,
		sort,
		page,
		limit,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName: resolveValue(partnerName),
		transactionType: resolveValue(transactionType),
		thresholdStatus: resolveValue(thresholdStatus),
		requestNumber: resolveValue(requestNumber),
	};
	const result = await HTTP.get('/v2/cash-flow', { params });
	return result.data;
}

const renderTransactionAmount =
	(transactionType: TransactionType) =>
	({ row: { original }, value }) =>
		(
			<Cell
				value={
					[null, undefined].some((e) => original[transactionType] === e)
						? '-'
						: value
				}
			/>
		);

const CASH_FLOW_COLUMNS: Array<any> = [
	{
		Header: 'Transaction Date and Time',
		id: 'date_created',
		sortable: true,
		width: '14%',
		accessor: ({ date_created }) => formatDate(date_created),
	},
	{
		Header: 'Wallet ID',
		id: 'wallet_id',
		sortable: true,
		width: '15%',
		Cell,
	},
	{
		Header: 'Partner Name',
		id: 'partner_name',
		sortable: true,
		Cell,
	},
	{
		Header: 'Partner Reference Number',
		id: 'partner_reference_no',
		sortable: true,
		Cell,
	},
	{
		Header: 'Request No.',
		id: 'request_no',
		sortable: true,
		Cell,
	},
	{
		Header: 'Transaction Ref. No.',
		id: 'transaction_reference_no',
		sortable: true,
		Cell,
	},
	{
		Header: 'Biller Code',
		id: 'biller_code',
		sortable: true,
		Cell,
	},
	{
		Header: 'Account No.',
		id: 'account_number',
		sortable: true,
		Cell,
	},
	{
		Header: 'Transaction Type',
		id: 'transaction_type',
		sortable: true,
		width: '14%',
		accessor: ({ transaction_type }) =>
			CashFlowServiceTypes.find(({ value }) => value === transaction_type)
				?.label || '',

		Cell: ({ row }) => (
			<div style={{ whiteSpace: 'unset' }}>{row.values.transaction_type}</div>
		),
	},
	{
		Header: 'Product Type',
		id: 'product_type',
		sortable: true,
		Cell,
	},
	{
		Header: 'Amount Due',
		id: 'amount_due',
		accessor: ({ amount_due }) => formatCash(amount_due),
		width: '9%',
		sortable: true,
		Cell,
	},
	{
		Header: 'Service Fee',
		id: 'service_fee',
		accessor: ({ service_fee }) => formatCash(service_fee),
		width: '8%',
		sortable: true,
		Cell,
	},
	{
		Header: 'Total Debit Amount',
		id: 'total_amount_debit',
		sortable: true,
		accessor: ({ total_amount_debit }) =>
			`(${formatCash(total_amount_debit, 2)})`,
		Cell: renderTransactionAmount('total_amount_debit'),
	},
	{
		Header: 'Total Credit Amount',
		id: 'total_amount_credit',
		sortable: true,
		accessor: ({ total_amount_credit }) => formatCash(total_amount_credit, 2),
		Cell: renderTransactionAmount('total_amount_credit'),
	},
	{
		Header: 'Running Balance',
		id: 'running_balance',
		sortable: true,
		accessor: ({ running_balance }) => formatCash(running_balance),
		Cell,
	},
	{
		Header: 'Threshold Amount',
		id: 'threshold_amount',
		sortable: true,
		accessor: ({ threshold_amount }) => formatCash(threshold_amount, 2),
		Cell,
	},
	{
		Header: 'Threshold Status',
		id: 'threshold_status',
		sortable: true,
		accessor: ({ threshold_status }) =>
			CashFlowThresholdStatuses.find(({ value }) => value === threshold_status)
				?.label || '',
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		width: '8%',
		accessor: ({ remarks }) => (remarks ? 'Service Fee of Check MOP' : '-'),
	},
];

const CashFlow: React.FC = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [filterParams, setFilterParams] = useState<GetCashFlowsArgs>({});
	const [isSearch, setIsSearch] = useState(false);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState('desc');
	const [lastParams, setLastParams] = useState<any>({});

	const [response, setResponse] = useState({
		isOpen: false,
		icon: csvIcon,
		bodyText: '',
		bodyHeader: 'File is being downloaded',
		respondButton: {
			name: 'Done',
			event: () => {
				setResponse({
					...response,
					isOpen: false,
				});
			},
		},
		isRespondButton2Shown: false,
		small: true,
	});

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isDownloadErrorModalShown,
		valueOn: showDownloadErrorModal,
		valueOff: hideDownloadErrorModal,
	} = useToggle();

	const dispatch = useDispatch();

	const fetchCashFlows = async (
		values: FetchCashFlowsArgs = {},
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				withLoader && showLoading();
				setLastParams(p);
				const result = await getCashFlows(p);
				const { meta } = result.data;
				setData(result.data.data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e) {
				if (withLoader) {
					showErrorModal();
					return;
				}
				throw e;
			} finally {
				setIsSearch(false);
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		} = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};
		await doRequest(params);
	};

	const initialFetch = async () => {
		try {
			showLoading();
			await fetchCashFlows({}, false, false);
		} catch (e: any) {
			if (e.response.status === 403) {
				dispatch(showAccessDeniedModal());
			} else {
				showErrorModal();
			}
		} finally {
			hideLoading();
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	const handlePageChange = async (_page, _pageSize) => {
		const { page: _, pageSize: __, ...rest } = filterParams;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await fetchCashFlows({ page: _page, limit: _pageSize, ...rest });
		}
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		setPage(1);
		await fetchCashFlows({ sortBy, sort, page: 1, ...filterParams });
	};

	const handleRetry = () => {
		hideErrorModal();
		if (lastParams) {
			fetchCashFlows(lastParams, true);
		}
	};

	const handleFilterSubmit = async (values, _isSearch) => {
		const {
			channelName = {},
			transactionType,
			dateFrom,
			dateTo,
			status,
			requestNumber,
			thresholdStatus,
		} = values;

		const params = {
			partnerName: channelName.label,
			transactionType,
			dateFrom,
			dateTo,
			status,
			requestNumber,
			page: 1,
			pageSize,
			thresholdStatus,
		};

		setPage(1);
		setFilterParams(params);
		await fetchCashFlows(params);
		setIsSearch(_isSearch);
	};

	const {
		value: isDownloadModalShown,
		valueOn: showDownloadModal,
		valueOff: hideDownloadModal,
	} = useToggle();

	const handleDownload = () => {
		const dates = calibrateDate(lastParams.dateFrom, lastParams.dateTo);

		const userDetails = JSON.parse(localStorage.getItem('userInfo') || '');
		const body = {
			user: userDetails.username,
			params: {
				...filterObjectEmpty(lastParams),
				...dates,
				page,
				pageSize,
				dateFrom: undefined,
				dateTo: undefined,
			},
		};

		showLoading();
		HTTP.post('/v1/cash-flow/download-history', body)
			.then((value) => {
				if (value.status === 200) {
					showDownloadModal();
				} else {
					if(value.status !== 403) {
						showDownloadErrorModal();
					}
				}
			})
			.catch((error) => {
				if(error.response.status !== 403) {
					showDownloadErrorModal();
				}
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<>
			<CashFlowFilter
				initialValues={INITIAL_VALUES}
				onSubmit={handleFilterSubmit}
				onDownload={handleDownload}
			/>
			<CashFlowTable
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				isSearch={isSearch}
				columns={CASH_FLOW_COLUMNS}
			/>
			<FullPageLoader
				open={isLoading}
				message="Please wait while cash flow list is being loaded"
			/>
			<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
				<ErrorModalBody>
					<div className={styles.errorBody}>
						<div>A problem occurred while</div>
						<div>loading the cash flow list.</div>
					</div>
					<div className={styles.errorFooter}>Please try again</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={handleRetry}
						className={styles.retryBtn}
					>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			<ErrorModal
				open={isDownloadErrorModalShown}
				onClose={hideDownloadErrorModal}
			>
				<ErrorModalBody>
					<div className={styles.errorBody}>
						<div>A problem occurred while</div>
						<div>downloading the cash flow list.</div>
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton fullWidth onClick={hideDownloadErrorModal}>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			<ResponseModal {...response} />
			<FileTypeSelectModal
				open={isDownloadModalShown}
				onClose={hideDownloadModal}
				confirmationMsg="Cash Flow History will now be generated and sent
				to your email. Kindly refresh your inbox or wait for a few minutes
				in case no email received."
			/>
		</>
	);
};

export default CashFlow;
