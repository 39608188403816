import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHasUserPermission } from 'utils/permissions';
import { ReducerStateType } from 'redux/modules/reducers';
import { FixMeLater } from 'types';

export const useTerminal = () => {
	const channelTypes = useSelector(
		(state: ReducerStateType) => state.channels.channelTypes
	);
	const channelDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedChannel ?? {}
	);
	const branchDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedBranch ?? {}
	);
	const terminalDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedTerminal ?? {}
	);
	const channelFormValues = useSelector(
		(state: ReducerStateType) => state.channels.channelFormValues
	);
	const branchFormValues = useSelector(
		(state: ReducerStateType) => state.channels.branchFormValues
	);
	const terminalFormValues = useSelector(
		(state: ReducerStateType) => state.channels.terminalFormValues
	);
	const _userInfo = useSelector<ReducerStateType, FixMeLater>(
		(state) => state.userInfo
	);
	const currenType = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);

	const hasUserPermission = useHasUserPermission('terminals');

	const {
		hasAddTerminalPermission,
		hasEditTerminalPermission,
		hasViewTerminalPermission,
		hasEditStatusTerminalPermission,
		hasSaveDraftTerminalPermission,
	} = useMemo(() => {
		return {
			hasAddTerminalPermission: hasUserPermission('add'),
			hasEditTerminalPermission: hasUserPermission('edit'),
			hasViewTerminalPermission: hasUserPermission('view'),
			hasEditStatusTerminalPermission: hasUserPermission('edit.status'),
			hasSaveDraftTerminalPermission: hasUserPermission('save.draft'),
		};
	}, [hasUserPermission]);

	const hasChannelUserPermission = useHasUserPermission('channels');

	const { hasEditDraftPermission, hasSaveDraftPermission } = useMemo(() => {
		return {
			hasEditDraftPermission: hasChannelUserPermission('edit.draft'),
			hasSaveDraftPermission: hasChannelUserPermission('save.draft'),
		};
	}, [hasChannelUserPermission]);

	return {
		channelTypes,
		channelDetails,
		branchDetails,
		terminalDetails,
		channelFormValues,
		branchFormValues,
		terminalFormValues,
		hasAddTerminalPermission,
		hasEditTerminalPermission,
		hasViewTerminalPermission,
		hasEditStatusTerminalPermission,
		hasSaveDraftTerminalPermission,
		_userInfo,
		hasEditDraftPermission,
		hasSaveDraftPermission,
		currenType,
	};
};
