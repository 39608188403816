import {
	Accordion,
	AccordionPanel,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from '../Inputs/Label/Label';
import styles from '../GroupControl/GroupControl.module.css';
import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import { ReactComponent as UpIcon } from 'assets/icons/ic-up.svg';
import { ReactComponent as DownIcon } from 'assets/icons/ic-down.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ic-info.svg';

type Props = {
	id?: string;
	refs?: any;
	content?: any;
	title?: any;
	summary?: any;
	tooltip?: any;
	contentTitle?: any;
};

const GroupControl: React.FC<Props> = ({
	id,
	refs,
	content,
	title,
	summary,
	tooltip,
	contentTitle,
}) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

	const handleTogglePanel = (evt: any) => {
		return setIsExpanded(!isExpanded);
	};

	const getToggleIcon = () => {
		return isExpanded ? (
			<UpIcon className={styles.summaryActionIcon} />
		) : (
			<DownIcon className={styles.summaryActionIcon} />
		);
	};

	return (
		<Accordion id={id} refs={refs} className={styles.groupControl}>
			<AccordionPanel
				id={id}
				className={styles.container}
				expanded={isExpanded}
				onTogglePanel={(evt: any) => handleTogglePanel(evt)}
				title={title}
				summary={
					<div className={styles.summary}>
						{summary} {getToggleIcon()}
					</div>
				}
			>
				<div className={styles.content}>
					{contentTitle ? (
						<div className={styles.contentTitle}>
							{contentTitle}
							{tooltip && (
								<>
									<Tooltip
										align="bottom left"
										content={tooltip}
										className={styles.summaryTooltipIcon}
									/>
								</>
							)}
						</div>
					) : null}
					{content}
				</div>
			</AccordionPanel>
		</Accordion>
	);
};

export default GroupControl;
