import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectFieldChannel';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { useCityQuery } from 'utils/queries/location';

export type CitySelectProps = {
	provinceId?: string;
	selectFieldProps?: SelectFieldProps;
	onChange?: (
		selected: SelectOption,
		options: {
			label: string;
			value: string;
		}[]
	) => void;
};

const CitySelect: React.FC<CitySelectProps> = ({
	provinceId,
	selectFieldProps,
	onChange,
}) => {
	const { data, isLoading } = useCityQuery(
		'cities',
		{ provinceId },
		{ enabled: !!provinceId, cacheTime: 0 }
	);

	const options = useMemo(() => {
		const cities =
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [];

		return cities;
	}, [data]);

	return (
		<SelectField
			label="Municipality/City"
			options={options}
			isLoading={isLoading}
			{...selectFieldProps}
			onChange={(selected) => {
				onChange?.(selected, options);
				selectFieldProps?.onChange?.(selected);
			}}
		/>
	);
};

export default CitySelect;
