import { React, Component } from 'react';
import {
	Modal,
	IconSettings,
	Button,
} from '@salesforce/design-system-react/module/components';
import styles from './SessionExpiredModal.module.css';
import close from '../../assets/icons/ic-failed.svg';

class SessionExpiredModal extends Component {
	redirect() {
		window.location.replace(process.env.REACT_APP_URL);
	}

	render() {
		const content = (
			<section className='slds-p-around_small'>
				<div className={styles.headerText}>
					Oops! Your session has expired!
				</div>

				<div className={styles.bodyText}>Please log in again.</div>
			</section>
		);

		return (
			<IconSettings iconPath='/lib/lightningdesignsystem/assets/icons'>
				<Modal disableClose isOpen={this.props.isExpired}>
					<div className={styles.closeIcon}>
						<img src={close} alt='close' />
						{content}
					</div>

					<div className={styles.buttonDiv}>
						<Button
							label='Close'
							className={styles.sessionButton}
							onClick={this.redirect}
						/>
					</div>
				</Modal>
			</IconSettings>
		);
	}
}

export default SessionExpiredModal;
