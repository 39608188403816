import React from 'react';

import { Modal } from '@salesforce/design-system-react';

import DetailsSection from './DetailsSection';
import DetailsTabs from './DetailsTabs';
import { TransactionDetailsProps } from '../types';
import styles from '../TransactionMgt.module.css';

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
	transaction,
	isOpen,
	onClose,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			size="small"
			contentClassName={styles.modal}
			onRequestClose={onClose}
		>
			<div className={styles.header}>View Transaction Details</div>
			<DetailsSection transaction={transaction} />
			<DetailsTabs trn={transaction?.trn} />
		</Modal>
	);
};

export default TransactionDetails;
