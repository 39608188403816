import React, { ReactNode } from 'react';

import ErrorModal, { ErrorModalActions, ErrorModalBody } from '../ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './ErrorModalContainer.module.css';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	errorHeader?: ReactNode | string;
	errorMessage?: ReactNode | string;
	errorInstruction?: ReactNode | string;
	retryBtnOnClick?: {
		action?: () => void;
	};
};

const ErrorModalContainer: React.FC<Props> = ({
	isOpen,
	onClose,
	errorHeader,
	errorMessage,
	errorInstruction,
	retryBtnOnClick,
}) => {
	return (
		<ErrorModal open={isOpen} onClose={onClose}>
			<ErrorModalBody>
				<div className={styles.errorBodyContent}>
					<div className={styles.errorHeader}>{errorHeader}</div>
					<div className={styles.errorBody}>{errorMessage}</div>
					<div className={styles.errorFooter}>{errorInstruction}</div>
				</div>
			</ErrorModalBody>
			<ErrorModalActions>
				<PrimaryButton
					fullWidth
					onClick={() =>
						retryBtnOnClick &&
						retryBtnOnClick.action &&
						retryBtnOnClick.action()
					}
					className={styles.errorModalBtn}
				>
					Retry
				</PrimaryButton>
			</ErrorModalActions>
		</ErrorModal>
	);
};

export default ErrorModalContainer;
