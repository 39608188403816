import { Component } from "react";
import styles from "./Table.module.css";
import cx from "classnames";
import { Icon } from "@salesforce/design-system-react/module/components";

class Thead extends Component {
  handleDesc(toggleSortBy, handleSort, id) {
    toggleSortBy(true, false);
    if (id === "created_at") {
      handleSort(id, false);
    } else {
      handleSort(id, true);
    }
  }

  handleAsc(toggleSortBy, handleSort, id) {
    toggleSortBy(false, false);
    if (id === "created_at") {
      handleSort(id, true);
    } else {
      handleSort(id, false);
    }
  }

  render() {
    const {
      className,
      column: {
        canSort,
        getHeaderProps,
        isSorted,
        isSortedDesc,
        toggleSortBy,
        id,
      },
      children,
      style,
      textStyle,
      handleSort,
    } = this.props;
    return (
      <div
        className={cx(styles.th, "slds-col", className)}
        {...getHeaderProps()}
        style={style}
      >
        <div className="slds-grid slds-grid_align-space">
          <div className="slds-col" style={textStyle}>
            {children}
          </div>
          {canSort && (
            <div className="slds-col">
              {!isSorted && (
                <>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className={styles.iconUp}
                    onClick={() => {
                      this.handleAsc(toggleSortBy, handleSort, id);
                    }}
                    onKeyDown={()=>{return}}
                  >
                    <Icon
                      category="utility"
                      name="up"
                      size="xx-small"
                      colorVariant="base"
                      className={styles.icons}
                    />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.handleDesc(toggleSortBy, handleSort, id);
                    }}
                    onKeyDown={()=>{return}}
                  >
                    <Icon
                      category="utility"
                      name="down"
                      size="xx-small"
                      colorVariant="base"
                      className={cx(styles.icons, styles.iconDown)}
                    />
                  </div>
                </>
              )}
              {isSorted && isSortedDesc && (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className={styles.iconUp}
                  onClick={() => {
                    this.handleAsc(toggleSortBy, handleSort, id);
                  }}
                  onKeyDown={()=>{return}}
                >
                  <Icon
                    category="utility"
                    name="up"
                    size="xx-small"
                    colorVariant="base"
                    className={styles.icons}
                  />
                </div>
              )}

              {isSorted && !isSortedDesc && (
                <div
                  style={{
                    cursor: "pointer",
                    lineHeight: 3.4,
                  }}
                  onClick={() => {
                    this.handleDesc(toggleSortBy, handleSort, id);
                  }}
                  onKeyDown={()=>{return}}
                >
                  <Icon
                    category="utility"
                    name="down"
                    size="xx-small"
                    colorVariant="base"
                    className={cx(styles.icons, styles.iconDown)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Thead;
