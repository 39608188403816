import axios from 'axios';
import { formatObjectKeys } from 'utils/common';
import { logOut } from '../utils/logout';

export type ApiResponse<T> = {
	data: T,
};

export const client = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	headers: {
		'Content-Type': 'application/json',
		'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
	},
});

export function setupInterceptors(store, history) {
	client.interceptors.request.use(function (config) {
		const toCamelCase = config.headers['X-CAMEL-CASE'] === 'true';
		if (toCamelCase) {
			const { params = {} } = config;
			config.params = { ...params, camelCase: '1' };
		}
		delete config.headers['X-CAMEL-CASE'];
		const token = localStorage.getItem('token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	});

	client.interceptors.response.use(
		(response) => {
			const toCamelCase = response.config?.params?.camelCase === '1';
			if (toCamelCase && response.data) {
				response.data = formatObjectKeys(response.data, 'CAMEL');
			}
			return response;
		},
		async (error) => {
			const e = { ...error };
			console.log('ERROR API-CLIENT: ', e.response);
			if (!error.response && !error.status) {
				return Promise.reject(error);
			}

			e.response.status === 401 && (await logOut());

			if (e.response && error.response.status === 403) {
				window['showAccessDeniedModal']();
			}

			return Promise.reject(error);
		}
	);
}

export default client;
