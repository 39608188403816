import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import { Control, UseFormSetValue } from 'react-hook-form';
import SelectField from 'components/Inputs/SelectField/SelectField';
import BankSelect from 'components/Inputs/SelectField/Types/BankSelect/BankSelect/BankSelect';
import { account_type } from 'constants/account_type';
import { ContractDetailsFormData } from '../../ContractDetails';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
};

const CheckCollectionDepositoryAccount = <T extends ContractDetailsFormData>({
	control,
	disabled,
}: Props<T>): JSX.Element => {
	const name = 'product_check_collection';
	return (
		<Section title="Check Collection Depository Account">
			<SectionRow>
				<Grid column size={1} of={3}>
					<BankSelect
						label="Bank Name"
						placeholder="Select Bank"
						name={`${name}.bank_id`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Bank Branch"
						placeholder="Bank Branch"
						name={`${name}.bank_branch`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Account Name"
						placeholder="Account Name"
						name={`${name}.account_name`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Account Number"
						placeholder="Account Number"
						name={`${name}.account_no`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Account Type"
						placeholder="Select Account Type"
						name={`${name}.account_type`}
						control={control}
						disabled={disabled}
						options={account_type}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default CheckCollectionDepositoryAccount;
