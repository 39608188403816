import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import TagInput, { EmailTagInput } from 'components/TagInput/TagInput';
import {
	Control,
	UseFormClearErrors,
	UseFormResetField,
	UseFormSetValue,
	UseFormTrigger,
	useForm,
	useWatch,
} from 'react-hook-form';
import styles from './DataSharingAgreement.module.css';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import BarangaySelect from 'components/Inputs/SelectField/Types/LocationSelect/BarangaySelect';
import CitySelect from 'components/Inputs/SelectField/Types/LocationSelect/CitySelect';
import CountrySelect from 'components/Inputs/SelectField/Types/LocationSelect/CountrySelect';
import ProvinceSelect from 'components/Inputs/SelectField/Types/LocationSelect/ProvinceSelect';
import ZipCodeSelect from 'components/Inputs/SelectField/Types/LocationSelect/ZipCodeSelect';
import { Country } from 'utils/queries/location';
import { address_type } from 'constants/address_type';
import { AddressDefaultValue } from 'utils/lookup';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from 'yup/lib/schema';
import { product_dpo_detail_schema } from 'utils/models/product_dpo_detail';
import { validateNumberLengthBetween } from 'utils/validation';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
	isDirty?: boolean;
	clearErrors: UseFormClearErrors<any>;
	resetField: UseFormResetField<any>;
	getValue: UseFormResetField<any>;
	trigger: UseFormTrigger<any>
};

const DataSharingAgreement: React.FC<Props> = ({
	control,
	disabled,
	setValue,
	clearErrors,
	isDirty,
	resetField,
	getValue,
	trigger,
}): JSX.Element => {
	const name = 'product_dpo_detail';
	const handleLocationBaseOnChange = (val) => {
		if (val == 'LOCAL') {
			setValue(`${name}.country_id`, 175);
			clearErrors(`${name}.country_id`)
		} else {
			setValue(`${name}.country_id`, null);
			trigger(`${name}.country_id`)
		}
		clearFromLocationBase();
	};
	const handleCountryOnChange = (val, options) => {
		const option = options
			? options.find(({ value }: SelectOption) => value == value)
			: {};

		setValue(`${name}.country_obj`, option);
	};
	const handleProvinceOnChange = (val, options) => {
		const option = options
			? options.find(({ value }: SelectOption) => value == value)
			: {};
		setValue(`${name}.province_obj`, option);
		clearFromProvince();
	};
	const handleCityOnChange = (val, options) => {
		const option = options
			? options.find(({ value }: SelectOption) => value == value)
			: {};
		setValue(`${name}.city_obj`, option);
		clearFromCity();
	};
	const handleBarangayOnChange = (val, options) => {
		const option = options
			? options.find(({ value }: SelectOption) => value == value)
			: {};
		setValue(`${name}.barangay_obj`, option);
		clearFromBarangay();
	};
	const handleZipCodeOnChange = (val, options) => {
		const option = options
			? options.find(({ value }: SelectOption) => value == value)
			: {};
		setValue(`${name}.zip_code_obj`, option?.label);
	};
	
	const clearFromProvince = () => {
		// Prevent clearing fields upon viewing
		if (!isDirty || disabled) return;
		setValue(`${name}.city_id`, undefined);
		setValue(`${name}.barangay_id`, undefined);
		setValue(`${name}.zip_code_id,`, undefined);
		setValue(`${name}.city_obj`, undefined);
		setValue(`${name}.barangay_obj`, undefined);
		setValue(`${name}.zip_code_obj`, undefined);
		trigger(`${name}.city_id`)
	};

	const clearFromCity = () => {
		if (!isDirty || disabled) return;
		setValue(`${name}.barangay_id`, undefined);
		setValue(`${name}.zip_code_id`, undefined);
		setValue(`${name}.barangay_obj`, undefined);
		setValue(`${name}.zip_code_obj`, undefined);
		trigger(`${name}.barangay_id`)
	};

	const clearFromBarangay = () => {
		if (!isDirty || disabled) return;
		setValue(`${name}.zip_code_id`, undefined);
		setValue(`${name}.zip_code_obj`, undefined);
	};
	const clearFromLocationBase = () => {
		if (!isDirty || disabled) return;
		setValue(`${name}.city_id`, undefined);
		setValue(`${name}.province_id`, undefined);
		setValue(`${name}.barangay_id`, undefined);
		setValue(`${name}.zip_code_id`, undefined);
		setValue(`${name}.region_id`, undefined);
		setValue(`${name}.province_obj`, undefined);
		setValue(`${name}.region_obj`, undefined);
		setValue(`${name}.street`, undefined);
		setValue(`${name}.building_no`, undefined);
	};

	const addressFields = useWatch({ name, control });

	return (
		<Section title="Data Sharing Agreement (DSA)">
			<Section
				title="Data Privacy Officer (DPO) Details"
				titleClassName={styles.sectionSubTitle}
			>
				<SectionRow>
					<Grid column size={1} of={3} className={styles.nameContainer}>
						<TextField
							required
							label="Name"
							name={`${name}.name`}
							control={control}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<EmailTagInput
							required
							control={control}
							name={`${name}.email`}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TagInput
							required
							label="Contact Number"
							subtext="*Can input multiple contact numbers separated by commas."
							control={control}
							hasError={(tag) => !validateNumberLengthBetween(tag, 10, 15)}
							name={`${name}.contact_no`}
							disabled={disabled}
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3}>
						<RadioGroup
							label="Location Base"
							isHorizontalLabel
							required
							disabled={disabled}
							options={address_type}
							name={`${name}.location_base`}
							control={control}
							onChange={(val) => {
								handleLocationBaseOnChange(val);
							}}
						/>
					</Grid>
					{addressFields?.location_base == 'LOCAL' ? (
						<Grid column size={1} of={3}>
							<CountrySelect
								label="Country"
								disabled
								required
								control={control}
								name={`${name}.country_id`}
								onChange={(val, options) => {
									handleCountryOnChange(val, options);
								}}
							/>
						</Grid>
					) : (
						<Grid column size={1} of={3}>
							<CountrySelect
								control={control}
								disabled={disabled}
								name={`${name}.country_id`}
								countryFilter={(country: Country) =>
									country.country_code !== 'PH'
								}
								onChange={(val, options) => {
									handleCountryOnChange(val, options);
								}}
							/>
						</Grid>
					)}
				</SectionRow>
				{addressFields?.location_base === 'LOCAL' && (
					<>
						<SectionRow>
							<Grid column size={1} of={3}>
								<ProvinceSelect
									disabled={disabled}
									control={control}
									name={`${name}`}
									onChange={(val, options) => {
										handleProvinceOnChange(val, options);
									}}
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<CitySelect
									control={control}
									name={`${name}`}
									provinceId={addressFields?.province_id}
									disabled={!addressFields?.province_id || disabled}
									onChange={(val, options) => {
										handleCityOnChange(val, options);
									}}
									defaultValue={''}
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<BarangaySelect
									control={control}
									name={`${name}`}
									cityId={addressFields?.city_id}
									disabled={!addressFields?.city_id || disabled}
									onChange={(val, options) => {
										handleBarangayOnChange(val, options);
									}}
									defaultValue={''}
								/>
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={3}>
								<TextField
									label="Street"
									disabled={disabled}
									control={control}
									name={`${name}.street`}
									optional
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<TextField
									label="Building Name/No."
									disabled={disabled}
									control={control}
									name={`${name}.building_no`}
									required
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<ZipCodeSelect
									required
									control={control}
									name={`${name}.zip_code_id`}
									barangayId={addressFields?.barangay_id}
									disabled={!addressFields?.barangay_id || disabled}
									onChange={(val, options) => {
										handleZipCodeOnChange(val, options);
									}}
								/>
							</Grid>
						</SectionRow>
					</>
				)}
				<SectionRow>
					<Grid column size={1} of={3}>
						<DatePickerField
							required
							label="Execution Date"
							name={`${name}.execution_date`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<DatePickerField
							required
							label="Termination Date"
							name={`${name}.termination_date`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
						/>
					</Grid>
				</SectionRow>
			</Section>
		</Section>
	);
};

export default DataSharingAgreement;
