import { createSlice } from '@reduxjs/toolkit';

export type AccessSliceState = {
	showDeniedModal: boolean;
};

const initialState: AccessSliceState = { showDeniedModal: false };

const accessCheck = createSlice({
	name: 'accessCheck',
	initialState,
	reducers: {
		accessDeniedModal: (state) => {
			state.showDeniedModal = !state.showDeniedModal;
		},
		showAccessDeniedModal: (state) => {
			state.showDeniedModal = true;
		},
		closeAccessDeniedModal: (state) => {
			state.showDeniedModal = false;
		},
	},
});

export const { accessDeniedModal,showAccessDeniedModal,closeAccessDeniedModal } = accessCheck.actions;
export default accessCheck.reducer;
