import { AxiosResponse } from 'axios';
import client from 'helpers/ApiClient';
import { FixMeLater } from 'types';

export const getForChannelPrimaryInfo = async (
	channelId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.get(`v2/channels/${channelId}/primary-info`);
	return fetchResult;
};

export const getForChannelContractDetails = async (
	channelId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.get(
		`v2/channels/${channelId}/contract-details`
	);
	return fetchResult;
};

export const getForChannelReports = async (
	channelId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.get(`v2/channels/${channelId}/reports`);
	return fetchResult;
};

export const getForDraftDetails = async (channelId?: number) => {
	const response = await client.get(`/v2/channels/${channelId}/draft-details`);
	return response.data;
};
