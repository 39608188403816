import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import PartnerDetails from 'components/PartnerDetails/PartnerDetails';
import PartnerAuditTrailList from 'components/PartnerAuditTrailList/PartnerAuditTrailList';
import { connect, useDispatch } from 'react-redux';
import { clearFormStates } from 'redux/modules/products';

const PartnerTab: React.FC<{
	isFormEdited?: boolean;
	selectedIndex: number;
	setSelectedIndex: (v: number) => void;
	showConfirmationModal: () => void;
	setConfirmationAction: any;
}> = ({
	isFormEdited,
	selectedIndex = 0,
	setSelectedIndex,
	showConfirmationModal,
	setConfirmationAction,
}) => {
	const dispatch = useDispatch();
	const primaryTabProps = {
		// lazy load if not yet selected
		tabs: [
			{
				title: 'Partner Details',
				component: selectedIndex === 0 ? <PartnerDetails /> : <></>,
			},
			{
				title: 'Audit Trail',
				scope: 'audit-trail',
				component: selectedIndex === 1 ? <PartnerAuditTrailList /> : <></>,
			},
		],
		defaultIndex: 0,
		isOverrideIndex: true,
		overrideIndexValue: selectedIndex,
	};

	const triggerAuditTab = () => {
		setSelectedIndex(1);
		dispatch(clearFormStates());
	};

	return (
		<>
			<PrimaryTab
				{...primaryTabProps}
				onSelect={(v: number) => {
					if (selectedIndex === v) return;

					if (isFormEdited && v === 1) {
						setConfirmationAction({ go: triggerAuditTab });
						showConfirmationModal();
						return;
					}
					setSelectedIndex(v);
				}}
			/>
		</>
	);
};

export default connect((state: any) => ({
	isFormEdited:
		state.products.primaryInfo?.isDirty ||
		state.products.contractDetails?.isDirty ||
		state.products.reports?.isDirty ||
		state.products.serviceFeeSettings?.isDirty ||
		state.products.businessRules?.isDirty,
}))(PartnerTab);
