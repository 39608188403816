import { Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import UploadStyles from './UploadSuccessModal.module.css';
import { ReactNode } from 'react';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';

type UploadSuccessModalSectionProps = {
	children: ReactNode;
};

export const UploadSuccessModalBody: React.FC<
	UploadSuccessModalSectionProps
> = ({ children, ...props }) => {
	return <div {...props}>{children}</div>;
};
export const UploadSuccessModalActions: React.FC<
	UploadSuccessModalSectionProps
> = ({ children, ...props }) => {
	return <div {...props}>{children}</div>;
};

type UploadSuccessModalProps = {
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
	larger?: boolean;
};

export const UploadSuccessText: React.FC = ({ children }) => (
	<div className={UploadStyles.uploadSuccessText}>{children}</div>
);
export const UploadSuccessSubText: React.FC = ({ children }) => (
	<div className={UploadStyles.uploadSuccessSubText}>{children}</div>
);

const UploadSuccessModal: React.FC<UploadSuccessModalProps> = ({
	open,
	onClose,
	children,
	larger = false,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={UploadStyles.header}
			size="small"
			containerClassName={UploadStyles.modalContainer}
			contentClassName={cx(
				UploadStyles.modal,
				larger ? UploadStyles.larger : ''
			)}
		>
			<Grid vertical verticalAlign="center" className={UploadStyles.container}>
				<Grid column className={UploadStyles.modalBody}>
					<RenderByType type={UploadSuccessModalBody}>{children}</RenderByType>
				</Grid>
				<Grid column className={UploadStyles.modalActions}>
					<RenderByType type={UploadSuccessModalActions}>
						{children}
					</RenderByType>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default UploadSuccessModal;
