import {
	BranchAddressType,
	BranchBasicInformationType,
	BranchContactDetailsType,
	BranchOperatingScheduleType,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';
import { Schedule } from 'containers/ChannelManagement/Branch/types';

export const BRANCH_BASIC_INFORMATION_DEFAULT_VALUES: BranchBasicInformationType =
	{
		channelAccountName: '',
		channelAccountCode: '',
		branchName: '',
		branchCode: '',
		channelTransactionType: '',
		channelTypeId: '',
		posType: '',
		settlementSetup: '',
		storeType: '',
		storeFormat: '',
		machineLocation: '',
		pldtTellerCode: '',
		cignalTellerCode: '',
		meralcoPaymentCenterCode: '',
		remarksChanges: '',
	};

export const BRANCH_ADDRESS_DEFAULT_VALUES: BranchAddressType = {
	locationBase: 'LOCAL',
	country: '175',
	area: '',
	region: '',
	stateOrProvince: '',
	municipalityOrCity: '',
	barangay: '',
	buildingNameOrNumber: '',
	street: '',
	zipCode: '',
};

export const BRANCH_CONTACT_DETAILS_DEFAULT = {
	name: '',
	department: '',
	emailAddress: [],
	mobileNumber: [],
	position: '',
	telephoneNumber: [],
};

export const BRANCH_CONTACT_DETAILS_DEFAULT_VALUES: BranchContactDetailsType = [
	BRANCH_CONTACT_DETAILS_DEFAULT,
];

export const OPERATING_SCHEDULE: Schedule[] = [
	{ day: 'Monday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Tuesday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Wednesday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Thursday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Friday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Saturday', startTime: ' ', endTime: ' ', isSelected: false },
	{ day: 'Sunday', startTime: ' ', endTime: ' ', isSelected: false },
];

export const BRANCH_OPERATING_SCHEDULE: BranchOperatingScheduleType = {
	schedule: OPERATING_SCHEDULE,
	depositConsolidation: '',
	taxPayerType: '',
	activationDate: '',
	remarks: '',
};

export const BRANCH_DEFAULT_VALUES: BranchType = {
	branchBasicInformation: BRANCH_BASIC_INFORMATION_DEFAULT_VALUES,
	branchAddress: BRANCH_ADDRESS_DEFAULT_VALUES,
	branchContactDetails: BRANCH_CONTACT_DETAILS_DEFAULT_VALUES,
	branchOperatingSchedule: BRANCH_OPERATING_SCHEDULE,
};
