import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import styles from './CashFlow.module.css';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce as _debounce } from 'lodash';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from '../../Filter/Datepicker';
import ChannelNameFilter from '../../Filter/ChannelNameFilter';
import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';
import Grid from 'components/Grid/Grid';
import { CashFlowServiceTypes, CashFlowThresholdStatuses } from 'utils/lookup';
import SelectField from 'components/Inputs/SelectField/SelectField';
import cx from 'classnames';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useDispatch, useSelector } from 'react-redux';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { showAccessDeniedModal } from 'redux/modules/access';

const DOWNLOAD_CASH_FLOW_PERMISSION = {
	scope: 'cws.download.cash.flow.history',
	resource: '/v1/cash-flow/download-history',
};

const shouldDisableSubmission = (values) => {
	const {
		dateFrom,
		dateTo,
		channelName,
		transactionType,
		status,
		thresholdStatus,
	} = values;
	return ![
		channelName,
		transactionType,
		status,
		dateFrom,
		dateTo,
		thresholdStatus,
	].some((v) => v);
};

type CashFlowFilterFormData = {
	filter: {
		dateFrom?: string;
		dateTo?: string;
		channelName?: string;
		transactionType?: string;
		thresholdStatus?: string;
		requestNumber?: string;
	};
};

type Props = {
	onSubmit: (values: any, isSearch: boolean) => void;
	initialValues?: Partial<CashFlowFilterFormData['filter']>;
	onDownload: () => void;
};

const downloadTypes = [
	{
		label: 'Cash Flow History',
		value: 'CASH_FLOW_HISTORY',
	},
	{
		label: 'Wallet Balance History',
		value: 'WALLET_BALANCE_HISTORY',
	},
];

const CashFlowFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = {},
	onDownload,
}) => {
	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const {
		register,
		getValues,
		setValue,
		clearErrors,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<CashFlowFilterFormData>({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isDownloadSelectOpen,
		valueOn: openDownloadSelect,
		valueOff: closeDownloadSelect,
	} = useToggle();

	const handleFilterSubmit = (values, isSearch = false) => {
		onSubmit(values || {}, isSearch);
	};

	const requestNumber = watch('filter.requestNumber');
	const allValues = watch('filter');

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, requestNumber: value }, true);
			}
		}, 500),
		[isDirty, isValid, requestNumber]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			handleFilterSubmit(initialValues);
			setIsReset(true);
		}
	};

	const dispatch = useDispatch();

	const downloadFile = (type) => {
		if (
			type ==
			downloadTypes.filter((e) => {
				return e.label == 'Cash Flow History';
			})[0]?.value
		) {
			if (!verifyPermission(scopes, DOWNLOAD_CASH_FLOW_PERMISSION)) {
				dispatch(showAccessDeniedModal());
				return;
			}

			onDownload();
		}
	};

	useEffect(() => {
		setInitialized(true);
	}, []);
	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(requestNumber);
		}
	}, [requestNumber]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	return (
		<>
			<FullPageLoader
				open={isLoading}
				message="Please wait while the file is being generated"
			/>
			<Filter title="Cash Flow">
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={11}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={5} of={12}>
								<InputWithIcon
									placeholder="Search Request No."
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
									name="filter.requestNumber"
									control={control}
								/>
							</Grid>
							<Grid
								column
								size={2}
								of={12}
								className={styles.downloadContainer}
							>
								<DownloadButton onClick={openDownloadSelect} />
								<SelectField
									isOpen={isDownloadSelectOpen}
									triggerClassName={styles.downloadDropdown}
									placeholder="Download"
									value={'DOWNLOAD'}
									onOpen={openDownloadSelect}
									onClose={closeDownloadSelect}
									onChange={(type) => {
										downloadFile(type.value);
									}}
									options={downloadTypes}
									menuPosition="overflowBoundaryElement"
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<div
						className={cx(
							'slds-grid',
							'slds-grid_align-spread',
							'slds-grid_vertical-align-start',
							styles.filters
						)}
					>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.dateFrom"
								label="Date From"
								errors={errors}
								validate={(v) =>
									validateDateFrom(v, getValues('filter.dateTo'), () =>
										clearErrors('filter.dateTo')
									)
								}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.dateTo"
								label="Date To"
								errors={errors}
								validate={(v) =>
									validateDateTo(getValues('filter.dateFrom'), v, () =>
										clearErrors('filter.dateFrom')
									)
								}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<ChannelNameFilter
								displayName="Partner Name"
								name="filter.channelName"
								control={control}
								value={allValues.channelName}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<FilterSelect
								name="filter.transactionType"
								label="Transaction Type"
								control={control}
								options={CashFlowServiceTypes}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<FilterSelect
								name="filter.thresholdStatus"
								label="Threshold Status"
								control={control}
								options={CashFlowThresholdStatuses}
							/>
						</div>
						<Grid
							container
							column
							size={4}
							of={12}
							verticalAlign="end"
							align="spread"
							className={styles.innerButtons}
						>
							<Grid
								column
								size={4}
								of={7}
								className={styles.clearFilterBtnContainer}
							>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={3} of={7}>
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter, true)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</div>
				</FilterInner>
			</Filter>
		</>
	);
};

export default CashFlowFilter;
