import React from 'react';

const RenderByType = ({ type, children }) => (
	<>
		{React.Children.map(children, (child) => {
			if (child && child.type === type) {
				return child;
			}
		})}
	</>
);

export default RenderByType;
