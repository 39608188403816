import moment from 'moment';
import { IconSettings, Button } from '@salesforce/design-system-react';
import cx from 'classnames';

import styles from './WalletList.module.css';
import Table from '../../../../components/CWSTable/Table';
import { formatDate } from 'utils/common';

export const channelLevelMap = {
	TPA_ACCOUNT: 'TPA Account',
	BRANCH: 'Branch',
	TPA_ID: 'TPA ID',
};
export const walletTypeMap = {
	CHANNEL: 'Channel Wallet',
	CONSUMER: 'Consumer Wallet',
};

const WALLET_LIST_COLUMNS = [
	{
		Header: 'Date Created',
		accessor: ({ date_created }) => formatDate(date_created),
		id: 'date_created',
		width: '15%',
		sortable: true,
	},
	{
		Header: 'Created By',
		id: 'created_by',
		sortable: true,
	},
	{
		Header: 'Wallet ID',
		id: 'wallet_identifier',
		width: '13%',
		Cell: ({ value }) => <div className={styles.walletId}>{value}</div>,
	},
	{
		Header: 'Channel Name',
		id: 'channel_name',
		width: '12%',
	},
	{
		Header: 'Channel Level',
		id: 'channel_level',
		accessor: ({ channel_level }) =>
			channelLevelMap[channel_level] || channel_level,
		width: '12%',
		sortable: true,
	},
	{
		Header: 'Wallet Balance',
		id: 'wallet_balance',
		width: '12%',
		sortable: true,
		numeric: true,
	},
	{
		Header: 'Threshold Amount',
		id: 'threshold_amount',
		width: '12%',
		numeric: true,
	},
	{
		Header: 'Wallet Type',
		id: 'type',
		accessor: ({ type }) => walletTypeMap[type] || type,
		width: '15%',
		sortable: true,
	},
	{
		Header: 'Status',
		Cell: ({ cell, value }) => {
			const match = STATUS.find(
				({ code }) => code.toLowerCase() === value.toLowerCase()
			) as StatusType;
			const className = match ? match.className : '';

			return (
				<div {...cell.getCellProps()} className={className}>
					{match.name}
				</div>
			);
		},
		id: 'status',
	},
	{
		Header: (props) => <div className={styles.actionHeader}>Actions</div>,
		id: 'actions',
		accessor: (values) => values,
		Cell: ({ value }) => {
			return (
				<>
					<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
						<div className={cx(styles.actionCell, 'slds-grid')}>
							<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
								<Button
									assistiveText={{ icon: 'Icon Bare Small' }}
									className={styles.crossIcon}
									iconCategory="utility"
									iconName="picklist_type"
									iconSize="small"
									iconVariant="bare"
									iconClassName={cx(styles.iconBtn)}
									onClick={() => {
										console.log('View Clicked', value);
									}}
									variant="icon"
								/>
							</div>
						</div>
					</IconSettings>
				</>
			);
		},
	},
];
export type StatusType = {
	name: string;
	code: string;
	className: string;
};

export const STATUS: StatusType[] = [
	{
		name: 'Active',
		code: 'ACTIVE',
		className: styles.statusActive,
	},
	{
		name: 'Deactivated',
		code: 'DEACTIVATED',
		className: styles.statusDeactivated,
	},
	{
		name: 'Inactive',
		code: 'INACTIVE',
		className: styles.statusInactive,
	},
];

function WalletListTable({
	data,
	pageSize,
	count,
	onPageChange,
	onSort,
	sortBy,
	sort,
	page,
	columns = [],
	isSearch,
}: any): any {
	return (
		<Table
			columns={columns.length ? columns : WALLET_LIST_COLUMNS}
			data={data}
			count={count}
			pageSize={pageSize}
			onPageChange={onPageChange}
			onSort={onSort}
			sort={sort}
			sortBy={sortBy}
			page={page}
			isSearch={isSearch}
			preHeader={null}
			modalIsOpen={false}
		/>
	);
}

export default WalletListTable;
