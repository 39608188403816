import Grid from 'components/Grid/Grid';
import Section, { SectionRow, SectionTitle } from 'components/Section/Section';
import Configurations from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/Configurations';
import { useGetCsrColumnsQuery } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/hooks';
import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { CsrColumnTemplate } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnTemplate';
import CsrReportPreview from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/CsrReportPreview';
import { SIDE_HEADERS } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/constants';
import { FtpContainerProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/types';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReportsSchemaType } from '../ReportsTabSchema';

const FtpContainer: React.FC<FtpContainerProps> = ({
	name,
	disabled,
	channelId,
}) => {
	const { control, watch } = useFormContext<ReportsSchemaType>();
	const { data } = useGetCsrColumnsQuery();
	const namePrefix = `${name}.csrConfig` as const;

	const columns = watch(`${name}.csrConfig.csrColumns`);

	const selectedColumns = useMemo<CsrColumn[]>(() => {
		if (!data) return [];
		if (!columns) return [];

		if (columns.length === 0 && data.length > 0) {
			return data
				.filter((column) =>
					typeof column === 'number' ? true : column.isRequired === 1
				)
				.filter(Boolean) as CsrColumn[];
		}
		return columns
			.map((column) => data.find((d) => d.id === column.id || d.id === column))
			.filter(Boolean) as CsrColumn[];
	}, [data, columns]);

	return (
		<>
			<Configurations
				name={namePrefix}
				title="Channel Collection Summary Report (CCSR) Configuration"
				disabled={disabled}
			/>
			<Section>
				<SectionRow vertical>
					<Grid column>
						<SectionTitle>Select columns for the CCSR template:</SectionTitle>
					</Grid>
					<Grid column>
						{data && (
							<CsrColumnTemplate
								control={control}
								pool={data}
								name={`${namePrefix}.csrColumns`}
								disabled={disabled}
								channelId={channelId}
							/>
						)}
					</Grid>
				</SectionRow>
			</Section>
			<div>
				{selectedColumns && (
					<Section>
						<CsrReportPreview
							sideHeaders={SIDE_HEADERS}
							columns={selectedColumns}
						/>
					</Section>
				)}
			</div>
		</>
	);
};
export default FtpContainer;
