import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import styles from './PrimaryTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { useCallback, useEffect, useState } from 'react';
import { showAccessDeniedModal } from 'redux/modules/access';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import { useToggle } from 'utils/hooks';

type TabsInfoType = {
	title: string;
	scope?: { permissions: string[] };
	component: any;
};

type Props = {
	tabs: TabsInfoType[];
	defaultIndex?: number;
	onSelect?: (index: number) => void;
	module?: string;
};

const PrimaryTab: React.FC<Props> = ({
	tabs,
	defaultIndex = 0,
	onSelect,
	module = 'products',
}) => {
	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission(module);
	const hasViewPermission = useCallback(
		(scope: string) => {
			switch (scope) {
				case 'audit-trail':
					return hasUserPermission(productCategory, `list.${scope}`);
				default:
					return false;
			}
		},
		[hasUserPermission, productCategory]
	);

	const hasViewChannelPermission = useCallback(
		(scope) => {
			const isAllPermitted = scope.permissions.every((v) =>
				hasUserPermission(v)
			);
			if (isAllPermitted) {
				return true;
			}
			return false;
		},
		[hasUserPermission]
	);

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	return (
		<>
			<div className={'slds-col'}>
				<Tabs
					variant="scoped"
					id="tabs-example-scoped"
					className={styles.tabs}
					defaultSelectedIndex={selectedIndex}
					selectedIndex={selectedIndex}
					onSelect={(index: number) => {
						const scope = tabs[index].scope;
						if (
							module === 'channels' ||
							module === 'branches' ||
							module === 'terminals'
						) {
							if (scope && !hasViewChannelPermission(scope)) {
								return showPermissionErrorModal();
							}
						} else {
							if (scope && !hasViewPermission(scope)) {
								return dispatch(showAccessDeniedModal());
							}
						}

						if (hasViewChannelPermission(scope)) {
							onSelect && onSelect(index);
							setSelectedIndex(index);
						}
					}}
				>
					{tabs.map((tab: TabsInfoType, index: number) => (
						<TabsPanel key={index} label={tab.title}>
							{tab.component}
						</TabsPanel>
					))}
				</Tabs>
			</div>
			{hasPermission && (
				<AccessDeniedModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				/>
			)}
		</>
	);
};

export default PrimaryTab;
