const iteration = 'C1';
export const sampleProductRequestPayload = {
	productType: 'BPY',
	primaryInfo: {
		merchantId: `MEG${iteration}`,
		productTradeName: `Legit Trade Corporation LTD ${iteration}`,
		merchantTradeName: `Legit Merchant Corporation LTD ${iteration}`,
		productShortName: `Legit Trade ${iteration}`,
		productCode: 'AUTO',
		logo: {
			objectKey: 'Letter-L-PNG-Royalty-Free.png',
			url: 'https://www.pngplay.com/wp-content/uploads/1/Letter-L-PNG-Royalty-Free.png',
			filename: 'Letter-L-PNG-Royalty-Free.png',
		},
		categoryId: 1,
		otherCategory: '',
		placeOfIncorporation: 'Blue Jewel Star System 46A2C',
		dateOfIncorporation: '2060-03-25',
		subBrand: 'Legitimate Blue',
		websiteAddress: 'www.legit-trade.com',
		legalStructure: 'OTHERS',
		otherLegalStruture: 'Legality of Legit Royalties',
		tin: '987654321012',
		consumerBaseCount: 100000,
		partnerCode: 'AUTO',
		affiliates: [
			{
				code: `LLG${iteration}`,
				name: 'Legit Legends',
			},
			{
				code: `BUT${iteration}`,
				name: 'Legit Authentic',
			},
			{
				code: `QEA${iteration}`,
				name: 'Legit Pearl',
			},
		],
		accountOfficer: {
			name: 'Major Qin Ye',
			email: 'qinye@legit.com',
			telNo: '09876543212',
			mobileNo: '09123478912',
		},
		addresses: [
			{
				id: 15,
				locationBase: 'LOCAL',
				addressCategory: 'HEAD',
				buildingNo: 'AS 234 45',
				street: 'Lapiz Lazuli',
				countryId: 175,
				provinceId: 140100000,
				cityId: 140101000,
				barangayId: 140101001,
				zipCodeId: 502800000,
			},
			{
				id: 16,
				locationBase: 'LOCAL',
				addressCategory: 'BUSINESS',
				buildingNo: 'AS 278 21',
				street: 'Quartz',
				countryId: 175,
				provinceId: 140100000,
				cityId: 140101000,
				barangayId: 140101001,
				zipCodeId: 502800000,
			},
			{
				id: 17,
				locationBase: 'LOCAL',
				addressCategory: 'BUSINESS',
				buildingNo: 'AS 194 05',
				street: 'Emerald',
				countryId: 175,
				provinceId: 140100000,
				cityId: 140101000,
				barangayId: 140101001,
				zipCodeId: 502800000,
			},
		],
		contactDetails: [
			{
				id: 14,
				name: 'Li Shiye',
				position: 'Squad Captain',
				department: 'AS 234 45',
				email: ['lishiye@legit.com', 'lishiyeofficial@legit.com'],
				telNo: '09123456789',
				mobileNo: '09987654321',
				cityId: 140101000,
			},
			{
				id: 15,
				name: 'Xue Zhenbin',
				position: '2nd Lieutenant',
				department: 'AS 278 21',
				email: 'xuezhenbin@legit.com',
				telNo: '09123456789',
				mobileNo: '09987654321',
				cityId: 140101000,
			},
		],
	},
	contractDetails: {
		uploadSchedule: 'SAME',
		uploadTime: '22:22',
		depositSchedule: 'FIRST',
		oneTimeFee: 1,
		oneTimeFeeAmount: 930000000,
		collectionDate: '2060-02-14',
		uploadPenaltyType: 'PERCENTAGE',
		uploadPenaltyRate: 57,
		depositPenaltyType: 'AMOUNT',
		depositPenaltyRate: 9271,
		otherPenalties: [
			{
				penaltyName: 'DEATH',
				penaltyType: 'AMOUNT',
				penaltyRate: 666666,
			},
		],
		cashCollection: {
			bankId: 20,
			accountName: 'EVELYN MENTOR',
			accountNo: '987654321',
			accountType: 'CURRENT',
			bankBranch: 'LEGITIMATE BANK BLUE STAR BRANCH',
		},
		checkCollection: {
			bankId: 45,
			accountName: 'SIMOUNE ANDERSON',
			accountNo: '123456789',
			accountType: 'SAVINGS',
			bankBranch: 'LEGITIMATE BANK BLUE STAR BRANCH',
		},
		termsDuration: {
			executionDate: '2060-03-25',
			autoRenew: 0,
			renewalFrequency: null,
			terminationDate: '2075-11-05',
		},
		dpoDetails: {
			name: 'LEGIT SHARING',
			email: ['sharing@legit.com', 'agreement@legit.com'],
			contactNo: '09123456789',
			locationBase: 'LOCAL',
			buildingName: 'DIAMOND SKYSCRAPER',
			street: 'RIVER FALL',
			countryId: 175,
			provinceId: 140100000,
			cityId: 140101000,
			barangayId: 140101001,
			zipCodeId: 502800000,
			executionDate: '2060-03-25',
			terminationDate: '2075-11-05',
		},
		dsaBonds: {
			bondType: 'OTHERS',
			otherBondType: 'MIGHTY BOND',
			bondAmount: 10000,
			bondPremiumType: 'PERCENTAGE',
			bondPremiumRate: 80,
			bondRenewal: 'ANNUALLY',
			prefundedAmount: 120000,
			prefundedThresholdAmt: 80000,
			executionDate: '2060-03-25',
			terminationDate: '2075-11-05',
		},
		accreditations: {
			cbciAmlQuestion: null,
			annualIncomeTax: null,
			auditedFinancialStmt: null,
			birCertificateForm: null,
			corpSecretaryCert: null,
			businessPermit: null,
			companyProfile: null,
			govtId: null,
			genInfoSheet: null,
			nonDisclosure: null,
			secRegCert: null,
			secArticleInc: null,
			secondaryLicense: null,
			passbook: null,
			dataPrivacyPia: null,
			dataPrivacyPat: null,
			thirdPartySecurityCheck: null,
		},
	},
	serviceFeeSettings: {
		billingType: 'BOTH',
		vat: 'EXCLUSIVE',
		withNonShareable: 1,
		nonShareableRateType: 'PERCENTAGE',
		schemeType: 'TIER',
		tierBaseType: 'COUNT_BASED',
		tierRateType: 'PERCENTAGE',
		specialRateType: null,
		withSfSettleDeduction: 1,
		frequencyType: 'YEARLY',
		frequencyMonth: 11,
		frequencyDay: 11,
		tiers: [
			{
				tierNumber: 1,
				lowerLimit: 0,
				upperLimit: 10000,
				channelTypeId: null,
				subsidizedFee: null,
				subsidizedRate: 20,
				passOnFee: null,
				passOnRate: 25,
				nonShareFixedRate: 20,
				nonSharePercentage: 30,
			},
			{
				tierNumber: 2,
				lowerLimit: 10001,
				upperLimit: 100000,
				channelTypeId: null,
				subsidizedFee: null,
				subsidizedRate: 40,
				passOnFee: null,
				passOnRate: 45,
				nonShareFixedRate: 40,
				nonSharePercentage: 50,
			},
			{
				tierNumber: 3,
				lowerLimit: 100001,
				upperLimit: 500000,
				channelTypeId: null,
				subsidizedFee: null,
				subsidizedRate: 60,
				passOnFee: null,
				passOnRate: 65,
				nonShareFixedRate: 60,
				nonSharePercentage: 70,
			},
		],
	},
	businessRules: {
		paymentMode: 'CASH,CHECK',
		cashMultipleBills: 1,
		checkMultipleBills: 1,
		cashCheckMultipleBills: null,
		creditMultipleBills: null,
		posType: null,
		acceptedCheckTypes: null,
		otherCheckTypes: null,
		requiredDocuments: null,
		otherRequiredDocuments: null,
		areaRestriction: 0,
		memo: null,
		minAmountCash: 10,
		maxAmountCash: 10000000,
		minAmountCheck: 5000,
		maxAmountCheck: 100000000,
		paymentAccepted: 'EXACT_PAYMENT',
		paymentAcceptancePolicy: 'ON_OR_BEFORE_DUE',
		gracePeriod: 33,
	},
	reports: {
		sftp: {
			username: 'legit_username',
			password: 'legit_password',
			host: 'legit.com',
			port: 6666,
			path: '/legit',
			csr_config: {
				filename_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 2,
				csr_columns: [10, 20, 21],
				schedule: [
					{
						generation_time: '09:00',
						upload_time: '10:00',
						covered_schedule: 'T+1',
						covered_time_from: '04:00',
						covered_time_to: '11:00',
					},
					{
						generation_time: '20:00',
						upload_time: '21:00',
						covered_schedule: 'T+2',
						covered_time_from: '06:00',
						covered_time_to: '18:00',
					},
				],
			},
			upload_config: {
				filename_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 2,
				schedule: [
					{
						generation_time: '09:00',
						upload_time: '10:00',
						covered_schedule: 'T+1',
						covered_time_from: '04:00',
						covered_time_to: '11:00',
					},
					{
						generation_time: '20:00',
						upload_time: '21:00',
						covered_schedule: 'T+2',
						covered_time_from: '06:00',
						covered_time_to: '18:00',
					},
				],
			},
		},
		smtp: {
			sender: 'online@bayad.com',
			receivers: ['sampleemail@legit.com', 'testemail@legit.com'],
			csr_config: {
				filename_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 2,
				csr_columns: [10, 20, 21],
				schedule: [
					{
						generation_time: '09:00',
						upload_time: '10:00',
						covered_schedule: 'T+1',
						covered_time_from: '04:00',
						covered_time_to: '11:00',
					},
					{
						generation_time: '20:00',
						upload_time: '21:00',
						covered_schedule: 'T+2',
						covered_time_from: '06:00',
						covered_time_to: '18:00',
					},
				],
			},
			upload_config: {
				filename_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 2,
				schedule: [
					{
						generation_time: '09:00',
						upload_time: '10:00',
						covered_schedule: 'T+1',
						covered_time_from: '04:00',
						covered_time_to: '11:00',
					},
					{
						generation_time: '20:00',
						upload_time: '21:00',
						covered_schedule: 'T+2',
						covered_time_from: '06:00',
						covered_time_to: '18:00',
					},
				],
			},
		},
	},
};

export const FEMockProductPayload = {
	productType: 'BPY',
	code: 'TES15',
	primaryInfo: {
		logo: {
			objectKey: null,
			url: 'https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/MECOR.png',
			filename: null,
		},
		merchantId: 'TES15',
		productCode: 'AUTO',
		affiliates: [
			{
				name: 'TSET`',
				code: 'TSET1',
			},
		],
		consumerBaseCount: '12',
		tin: '123123123123',
		legalStructure: 'SOLE',
		otherLegalStruture: '',
		websiteAddress: 'test.com',
		dateOfIncorporation: '2023-01-01',
		placeOfIncorporation: 'TES15',
		categoryId: '1',
		otherCategory: '',
		productShortName: 'TES15',
		merchantTradeName: 'TES15',
		productTradeName: 'TES15',
		addresses: [
			{
				locationBase: 'LOCAL',
				buildingNo: 'TES15',
				street: 'TES15',
				addressCategory: 'HEAD',
				barangayId: '140101001',
				countryId: '175',
				cityId: '140101000',
				provinceId: '140100000',
				zipCodeId: '502800000',
			},
		],
		contactDetails: [
			{
				name: 'TES15',
				area_handled: '',
				position: 'TES15',
				department: 'TES15',
				email: ['da@da.cn'],
				mobile_no: ['09123123123'],
				cityId: '',
			},
		],
		accountOfficer: {
			name: 'TEST1',
			email: ['da@da.cn'],
			mobile_no: ['09123123123'],
		},
		product_code: 'AUTO',
		code: 'TES15',
		logo_url: '',
		isDirty: false,
		isValid: true,
	},
	contractDetails: {
		uploadSchedule: 'SAME',
		uploadTime: '00:00',
		depositSchedule: 'FIRST',
		oneTimeFee: '1',
		uploadPenaltyType: 'AMOUNT',
		depositPenaltyType: 'AMOUNT',
		otherPenalties: 'Test',
		otherPenaltiesType: 'AMOUNT',
		oneTimeFeeAmount: '10',
		collectionDate: {
			month: '1',
			day: 1,
			year: '2023',
		},
		uploadPenaltyRate: '10',
		depositPenaltyRate: '10',
		otherPenaltiesRate: '10',
		dsaBonds: {
			bondType: 'SURETY',
			bondAmount: '10',
			bondPremiumType: 'AMOUNT',
			bondRenewal: 'MONTHLY',
			prefundedAmount: '10',
			prefundedThresholdAmt: '10',
			executionDate: {
				month: '1',
				day: 1,
				year: '2023',
			},
			terminationDate: {
				month: '1',
				day: 1,
				year: '2023',
			},
			bondPremiumRate: '10',
		},
		dpoDetails: {
			buildingName: 'Test',
			name: 'Test',
			email: ['da@da.cn'],
			contactNo: ['09123123123'],
			locationBase: 'LOCAL',
			provinceId: 140100000,
			cityId: 140101000,
			barangayId: 140101001,
			street: 'Test',
			zipCodeId: 502800000,
			executionDate: {
				month: '1',
				day: 1,
				year: '2023',
			},
			terminationDate: {
				month: '1',
				day: 1,
				year: '2023',
			},
			province: {},
			city: {
				label: 'BANGUED',
				value: 140101000,
			},
			barangay: {
				label: 'AGTANGAO',
				value: {
					id: 140101001,
					cityId: 140101000,
				},
			},
			zipCode: {
				label: '2800',
				value: 502800000,
			},
		},
		accreditations: {
			annualIncomeTax: '',
			auditedFinancialStmt: '',
			birCertificateForm: '',
			corpSecretaryCert: '',
			businessPermit: '',
			companyProfile: '',
			govtId: '',
			genInfoSheet: '',
			nonDisclosure: '',
			secRegCert: '',
			secArticleInc: '',
			secondaryLicense: '',
			passbook: '',
			dataPrivacyPia: '',
			dataPrivacyPat: '',
			thirdPartySecurityCheck: '',
		},
		termsDuration: {
			renewalFrequency: 10,
			executionDate: {
				month: '1',
				day: 1,
				year: '2023',
			},
			autoRenew: '1',
		},
		cashCollection: {
			bankId: 44,
			bankBranch: 'Test',
			accountName: 'Test',
			accountNo: '09123123123',
			accountType: 'SAVINGS',
		},
		checkCollection: {
			bankId: 4,
			bankBranch: 'Test',
			accountName: 'Test',
			accountNo: '09123123123',
			accountType: 'SAVINGS',
		},
		isDirty: true,
		isValid: true,
	},
	serviceFeeSettings: {
		billingType: 'BOTH',
		vat: 'INCLUSIVE',
		withNonShareable: '1',
		nonShareableRateType: 'FIXED_RATE',
		schemeType: 'SPECIAL',
		tierBaseType: null,
		tierRateType: null,
		specialRateType: 'FIXED_RATE',
		withSfSettleDeduction: '1',
		frequencyType: 'DAILY',
		tiers: [
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 1,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 2,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 3,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 4,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 5,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 6,
				tierNumber: null,
			},
			{
				passOnFee: '10.00',
				passOnRate: null,
				subsidizedFee: '10.00',
				subsidizedRate: null,
				nonSharePercentage: null,
				nonShareFixedRate: '10.00',
				totalFixedRate: 20,
				channelTypeId: 7,
				tierNumber: null,
			},
		],
	},
	businessRules: {
		paymentMode: ['CASH', 'CHECK', 'CASH_CHECK', 'CREDIT_CARD'],
		acceptedCheckTypes: [
			'LOCAL_CLEARING_CHECK',
			'REGIONAL_CHECK',
			'OUT_OF_TOWN_CHECK',
			'LOCAL_CASHIER_MANAGER_CHECK',
			'DIVIDED_CHECK',
			'OTHER_CHECK_TYPES',
		],
		requiredDocuments: [
			'SOA',
			'SOA_SMS_BAYAD_FORM',
			'ONLINE_DIGITAL',
			'BAYAD_FORM',
			'SMS',
			'OTHER_FORMS',
		],
		areaRestriction: '1',
		minAmountCash: '10',
		maxAmountCash: '20',
		minAmountCheck: '10',
		maxAmountCheck: '20',
		paymentAccepted: ['EXACT_PAYMENT', 'OVER_PAYMENT'],
		paymentAcceptancePolicy: 'BEYOND_DUE',
		gracePeriod: '10',
		isDirty: true,
		isValid: false,
	},
	reports: {
		smtp: {
			receiver_email: ['da@da.cn'],
			upload_configuration: {
				file_name_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 1,
				timestamps: [
					{
						generation_time: '00:00',
						time_of_upload: '01:00',
						covered_schedule: 'T+1',
						covered_time: {
							from: '01:00',
							to: '13:00',
						},
					},
				],
			},
			csr_configuration: {
				columns: [
					{
						ordinal: 4,
						is_required: true,
						sample: 'Branch 1',
						code: 'BRANCH_NAME',
						value: 'Branch Name',
						id: '4',
						format: null,
					},
					{
						ordinal: 7,
						is_required: true,
						sample: '100,000.00',
						code: 'CASH_AMOUNT',
						value: 'Cash Amount',
						id: '7',
						format: ':,.2f',
					},
					{
						ordinal: 8,
						is_required: true,
						sample: '0.00',
						code: 'CHECK_AMOUNT',
						value: 'Check Amount',
						id: '8',
						format: ':,.2f',
					},
					{
						ordinal: 6,
						is_required: true,
						sample: '1234567890',
						code: 'ARN',
						value: 'Customer Account No.',
						id: '6',
						format: null,
					},
					{
						ordinal: 9,
						is_required: true,
						sample: '100,000.00',
						code: 'TOTAL_AMOUNT',
						value: 'Total Amount',
						id: '9',
						format: ':,.2f',
					},
					{
						ordinal: 5,
						is_required: true,
						sample: '0001',
						code: 'TPAID',
						value: 'TPAID',
						id: '5',
						format: null,
					},
					{
						ordinal: 1,
						is_required: true,
						sample: '08/08/2021',
						code: 'TXN_DATE',
						value: 'Transaction Date',
						id: '1',
						format: '%m/%d/%Y',
					},
					{
						ordinal: 3,
						is_required: true,
						sample: '2123101B1200000001',
						code: 'TRN',
						value: 'Transaction Reference No.',
						id: '3',
						format: null,
					},
					{
						ordinal: 2,
						is_required: true,
						sample: '01:00:00 PM',
						code: 'TXN_TIME',
						value: 'Transaction Time',
						id: '2',
						format: '%I:%M:%S %p',
					},
				],
				timestamps: [
					{
						generation_time: '00:00',
						time_of_upload: '01:00',
						covered_schedule: 'T+1',
						covered_time: {
							from: '00:00',
							to: '02:00',
						},
					},
				],
				upload_frequency: 1,
				upload_schedule: 'SAME',
				file_name_format: 'CSV',
			},
			base_info: {
				host: 'test.com',
				port: '80',
				username: 'test',
				password: 'test',
				path: 'test',
			},
		},
		sftp: {
			upload_configuration: {
				file_name_format: 'CSV',
				upload_schedule: 'SAME',
				upload_frequency: 1,
				timestamps: [
					{
						generation_time: '01:00',
						time_of_upload: '14:00',
						covered_schedule: 'T+1',
						covered_time: {
							from: '00:00',
							to: '02:00',
						},
					},
				],
			},
			csr_configuration: {
				columns: [
					{
						ordinal: 4,
						is_required: true,
						sample: 'Branch 1',
						code: 'BRANCH_NAME',
						value: 'Branch Name',
						id: '4',
						format: null,
					},
					{
						ordinal: 7,
						is_required: true,
						sample: '100,000.00',
						code: 'CASH_AMOUNT',
						value: 'Cash Amount',
						id: '7',
						format: ':,.2f',
					},
					{
						ordinal: 8,
						is_required: true,
						sample: '0.00',
						code: 'CHECK_AMOUNT',
						value: 'Check Amount',
						id: '8',
						format: ':,.2f',
					},
					{
						ordinal: 6,
						is_required: true,
						sample: '1234567890',
						code: 'ARN',
						value: 'Customer Account No.',
						id: '6',
						format: null,
					},
					{
						ordinal: 9,
						is_required: true,
						sample: '100,000.00',
						code: 'TOTAL_AMOUNT',
						value: 'Total Amount',
						id: '9',
						format: ':,.2f',
					},
					{
						ordinal: 5,
						is_required: true,
						sample: '0001',
						code: 'TPAID',
						value: 'TPAID',
						id: '5',
						format: null,
					},
					{
						ordinal: 1,
						is_required: true,
						sample: '08/08/2021',
						code: 'TXN_DATE',
						value: 'Transaction Date',
						id: '1',
						format: '%m/%d/%Y',
					},
					{
						ordinal: 3,
						is_required: true,
						sample: '2123101B1200000001',
						code: 'TRN',
						value: 'Transaction Reference No.',
						id: '3',
						format: null,
					},
					{
						ordinal: 2,
						is_required: true,
						sample: '01:00:00 PM',
						code: 'TXN_TIME',
						value: 'Transaction Time',
						id: '2',
						format: '%I:%M:%S %p',
					},
				],
				timestamps: [
					{
						generation_time: '00:00',
						time_of_upload: '01:00',
						covered_schedule: 'T+1',
						covered_time: {
							from: '00:00',
							to: '14:00',
						},
					},
				],
				upload_frequency: 1,
				upload_schedule: 'SAME',
				file_name_format: 'CSV',
			},
			base_info: {
				host: 'test.com',
				port: '80',
				username: 'test',
				password: 'test',
				path: 'test',
			},
		},
		is_dirty: true,
		is_valid: true,
	},
};

export const mockPartner = {
	primary_info: {
		code: 'ATA01',
		product_primary_info: {
			place_of_incorporation: 'ATA01',
			category_id: 1,
			date_of_incorporation: '5/8/2023',
			other_legal_struture: '',
			other_category: '',
			product_short_name: 'ATA01',
			tin: '091231231231',
			merchant_trade_name: 'ATA01',
			consumer_base_count: '1',
			product_code: '',
			product_trade_name: 'ATA01',
			legal_structure: 'SOLE',
		},
		product_affiliates: [],
		product_addresses: [
			{
				cities: [
					{
						label: 'BANGUED',
						value: 140101000,
					},
					{
						label: 'BOLINEY',
						value: 140102000,
					},
					{
						label: 'BUCAY',
						value: 140103000,
					},
					{
						label: 'BUCLOC',
						value: 140104000,
					},
					{
						label: 'DAGUIOMAN',
						value: 140105000,
					},
					{
						label: 'DANGLAS',
						value: 140106000,
					},
					{
						label: 'DOLORES',
						value: 140107000,
					},
					{
						label: 'LA PAZ',
						value: 140108000,
					},
					{
						label: 'LACUB',
						value: 140109000,
					},
					{
						label: 'LAGANGILANG',
						value: 140110000,
					},
					{
						label: 'LAGAYAN',
						value: 140111000,
					},
					{
						label: 'LANGIDEN',
						value: 140112000,
					},
					{
						label: 'LICUAN-BAAY',
						value: 140113000,
					},
					{
						label: 'LUBA',
						value: 140114000,
					},
					{
						label: 'MALIBCONG',
						value: 140115000,
					},
					{
						label: 'MANABO',
						value: 140116000,
					},
					{
						label: 'PEÑARRUBIA',
						value: 140117000,
					},
					{
						label: 'PIDIGAN',
						value: 140118000,
					},
					{
						label: 'PILAR',
						value: 140119000,
					},
					{
						label: 'SALLAPADAN',
						value: 140120000,
					},
					{
						label: 'SAN ISIDRO',
						value: 140121000,
					},
					{
						label: 'SAN JUAN',
						value: 140122000,
					},
					{
						label: 'SAN QUINTIN',
						value: 140123000,
					},
					{
						label: 'TAYUM',
						value: 140124000,
					},
					{
						label: 'TINEG',
						value: 140125000,
					},
					{
						label: 'TUBO',
						value: 140126000,
					},
					{
						label: 'VILLAVICIOSA',
						value: 140127000,
					},
				],
				zip_code_obj: 502800000,
				address_category: 'HEAD',
				city_obj: {
					label: 'BANGUED',
					value: 140101000,
				},
				building_no: 'ATA01',
				country_obj: {
					name: 'Philippines',
					country_code: 'PH',
					id: '175',
				},
				zip_code_id: 502800000,
				barangay_id: 140101001,
				province_id: 140100000,
				street: '',
				location_base: 'LOCAL',
				barangay_obj: {
					id: 140101001,
					city_id: 140101000,
				},
				country_id: 175,
				city_id: 140101000,
			},
		],
		product_contact_details: [
			{
				area_handled: '140101000',
				tel_no: ['02123123123'],
				name: 'ATA01',
				mobile_no: ['+639123123123'],
				position: 'ATA01',
				department: 'ATA01',
				email: ['da@da.cn'],
				city_id: '',
			},
		],
		product_officer: {
			name: 'Daaa',
			mobile_no: ['+639123123123'],
			email: ['da@da.cn'],
			tel_no: ['02123123123'],
		},
		product_code_type: 'AUTO',
	},
	product_contract: {
		product_contract: {
			upload_schedule: 'SAME',
			upload_time: '00:12',
			deposit_schedule: 'FIRST',
			one_time_fee: '1',
			upload_penalty_type: 'AMOUNT',
			deposit_penalty_type: 'AMOUNT',
			other_penalties: [
				{
					penaltyType: 'AMOUNT',
					penaltyRate: '10',
				},
			],
			one_time_fee_amount: '10',
			collection_date: '5/10/2023',
			upload_penalty_rate: '10',
			deposit_penalty_rate: '10',
		},
		product_accreditations: {
			cbci_aml_question:
				'95cc9e9b-5e3a-4660-922b-eb4d8e90d745-Blank diagram.png',
		},
		product_terms_duration: {
			renewal_frequency_type: 'ANNUALLY',
			renewalFrequency: 10,
			execution_date: '5/9/2023',
			auto_renew: '1',
		},
		product_cash_collection: {
			bank_id: 44,
			bank_branch: 'ATA01',
			account_name: 'ATA01',
			account_no: '123123123',
			account_type: 'SAVINGS',
		},
		product_check_collection: {
			bank_id: 44,
			bank_branch: 'ATA01',
			account_name: 'ATA01',
			account_no: '123123123',
			account_type: 'SAVINGS',
		},
		isDirty: false,
		isValid: false,
		product_dpo_detail: {
			location_base: 'LOCAL',
			country_id: 175,
			name: 'Da',
			email: ['da@da.cn'],
			contact_no: ['+639123123123'],
			province_id: 140100000,
			city_id: 140101000,
			barangay_id: 140101001,
			building_no: 'test',
			zip_code_id: 502800000,
			execution_date: '5/10/2023',
			termination_date: '5/16/2023',
			province_obj: {},
			city_obj: {
				label: 'BANGUED',
				value: 140101000,
			},
			barangay_obj: {
				label: 'AGTANGAO',
				value: {
					id: 140101001,
					city_id: 140101000,
				},
			},
			zip_code_obj: {
				label: '2800',
				value: 502800000,
			},
		},
		product_dsa_bond: {
			bond_type: 'SURETY',
			bond_amount: '10',
			bond_premium_type: 'AMOUNT',
			bond_renewal: 'MONTHLY',
			prefunded_amount: '10',
			prefunded_threshold_amt: '10',
			execution_date: '5/10/2023',
			termination_date: '5/10/2023',
			bond_premium_rate: '10',
		},
		product_accreditation: {
			cbci_aml_question:
				'cc420e17-7409-4223-a3ef-813ffdde33fb-Blank diagram.png',
		},
	},
	service_fee_settings: {
		billing_type: {
			PASS_ON: true,
			SUBSIDIZED: true,
		},
		vat: 'INCLUSIVE',
		with_non_shareable_amount: '1',
		scheme_type: 'REGULAR',
		with_sf_settle_deduction: '1',
		non_shareable_rate_type: 'FIXED_RATE',
		regular: {
			subsidized_fee: '10.00',
			pass_on_fee: '10.00',
			total_fixed_rate: 20,
			non_share_fixed_rate: '20.00',
		},
		tier: [{}],
		percentage: {},
		special: [],
		tier_count: 0,
		tier_rate_type: '',
		tier_base_type: '',
		tiers: [],
		frequency_type: 'YEARLY',
		frequency_month: 'January',
		frequency_day: '1',
	},
	business_rules: {
		product_business_rule: {
			payment_mode: ['CASH', 'CHECK', 'CASH_CHECK', 'CREDIT'],
			accepted_check_types: [
				'LOCAL_CLEAR_CHECK',
				'REGION_CHECK',
				'OUT_OF_TOWN_CHECK',
				'LOCAL_CASHIER_CHECK',
				'DIVIDEND_CHECK',
				'OTHER_CHECK',
			],
			required_documents: [
				'SOA',
				'BAYAD_FORM',
				'SMS',
				'SOA_SMS_BAYAD_FORM',
				'ONLINE',
				'OTHER',
			],
			area_restriction: '1',
			memo: 'bbcd3e15-0b3a-480e-b77d-af499a774ac3-Blank diagram (1).png',
			memo_fileName: 'Blank diagram (1).png',
			min_amount_cash: '10',
			max_amount_cash: '100',
			min_amount_check: '10',
			max_amount_check: '100',
			payment_accepted: ['EXACT_PAYMENT', 'OVER_PAYMENT', 'UNDER_PAYMENT'],
			payment_acceptance_policy: 'BEYOND_DUE',
			other_check_types: 'TEST other',
			cash_multiple_bills: ['1'],
			check_multiple_bills: ['1'],
			cash_check_multiple_bills: ['1'],
			pos_type: ['BAYAD_POS', 'PARTNER_POS'],
			grace_period: '10',
			other_required_documents: 'Test Form',
		},
	},
	reports: {
		smtp: {
			csrConfiguration: {
				uploadFrequency: 1,
				timestamps: [
					{
						generationTime: '00:12',
						timeOfUpload: '12:12',
						coveredSchedule: 'T+0',
						coveredTime: {
							from: '00:12',
							to: '12:12',
						},
					},
				],
				columns: [
					{
						id: 4,
						code: 'BRANCH_NAME',
						value: 'Branch Name',
						sample: 'Branch 1',
						format: null,
						is_required: 1,
						ordinal: 4,
					},
					{
						id: 7,
						code: 'CASH_AMOUNT',
						value: 'Cash Amount',
						sample: '100,000.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 7,
					},
					{
						id: 8,
						code: 'CHECK_AMOUNT',
						value: 'Check Amount',
						sample: '0.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 8,
					},
					{
						id: 6,
						code: 'ARN',
						value: 'Customer Account No.',
						sample: '1234567890',
						format: null,
						is_required: 1,
						ordinal: 6,
					},
					{
						id: 9,
						code: 'TOTAL_AMOUNT',
						value: 'Total Amount',
						sample: '100,000.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 9,
					},
					{
						id: 5,
						code: 'TPAID',
						value: 'TPAID',
						sample: '0001',
						format: null,
						is_required: 1,
						ordinal: 5,
					},
					{
						id: 1,
						code: 'TXN_DATE',
						value: 'Transaction Date',
						sample: '08/08/2021',
						format: '%m/%d/%Y',
						is_required: 1,
						ordinal: 1,
					},
					{
						id: 3,
						code: 'TRN',
						value: 'Transaction Reference No.',
						sample: '2123101B1200000001',
						format: null,
						is_required: 1,
						ordinal: 3,
					},
					{
						id: 2,
						code: 'TXN_TIME',
						value: 'Transaction Time',
						sample: '01:00:00 PM',
						format: '%I:%M:%S %p',
						is_required: 1,
						ordinal: 2,
					},
				],
				filenameFormat: 'XLS',
				uploadSchedule: 'NEXT',
			},
			uploadConfiguration: {
				uploadFrequency: 1,
				timestamps: [
					{
						generationTime: '00:12',
						timeOfUpload: '12:12',
						coveredSchedule: 'T+0',
						coveredTime: {
							from: '00:12',
							to: '12:12',
						},
					},
				],
				filenameFormat: 'CSV',
				uploadSchedule: 'SAME',
			},
			senderEmail: 'online@bayad.com',
			receiverEmail: ['email@email.com'],
		},
		sftp: {
			uploadConfiguration: {
				uploadFrequency: 1,
				timestamps: [
					{
						generationTime: '00:12',
						timeOfUpload: '12:12',
						coveredSchedule: 'T+0',
						coveredTime: {
							from: '00:12',
							to: '12:12',
						},
					},
				],
				filenameFormat: 'CSV',
				uploadSchedule: 'SAME',
			},
			csrConfiguration: {
				uploadFrequency: 1,
				filenameFormat: 'CSV',
				uploadSchedule: 'SAME',
				columns: [
					{
						id: 4,
						code: 'BRANCH_NAME',
						value: 'Branch Name',
						sample: 'Branch 1',
						format: null,
						is_required: 1,
						ordinal: 4,
					},
					{
						id: 7,
						code: 'CASH_AMOUNT',
						value: 'Cash Amount',
						sample: '100,000.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 7,
					},
					{
						id: 8,
						code: 'CHECK_AMOUNT',
						value: 'Check Amount',
						sample: '0.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 8,
					},
					{
						id: 6,
						code: 'ARN',
						value: 'Customer Account No.',
						sample: '1234567890',
						format: null,
						is_required: 1,
						ordinal: 6,
					},
					{
						id: 9,
						code: 'TOTAL_AMOUNT',
						value: 'Total Amount',
						sample: '100,000.00',
						format: ':,.2f',
						is_required: 1,
						ordinal: 9,
					},
					{
						id: 5,
						code: 'TPAID',
						value: 'TPAID',
						sample: '0001',
						format: null,
						is_required: 1,
						ordinal: 5,
					},
					{
						id: 1,
						code: 'TXN_DATE',
						value: 'Transaction Date',
						sample: '08/08/2021',
						format: '%m/%d/%Y',
						is_required: 1,
						ordinal: 1,
					},
					{
						id: 3,
						code: 'TRN',
						value: 'Transaction Reference No.',
						sample: '2123101B1200000001',
						format: null,
						is_required: 1,
						ordinal: 3,
					},
					{
						id: 2,
						code: 'TXN_TIME',
						value: 'Transaction Time',
						sample: '01:00:00 PM',
						format: '%I:%M:%S %p',
						is_required: 1,
						ordinal: 2,
					},
				],
				timestamps: [
					{
						generationTime: '00:12',
						timeOfUpload: '12:12',
						coveredSchedule: 'T+0',
						coveredTime: {
							from: '00:12',
							to: '12:12',
						},
					},
				],
			},
			baseInfo: {
				host: 'test.com',
				port: '80',
				username: 'test',
				password: 'test',
				path: '/test',
			},
		},
	},
};

const draftPayload = {
	productType: 'ATA01',
	code: 'ATA01',
	primaryInfo: {
		logo: {
			objectKey: null,
			url: 'https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/MECOR.png',
			filename: null,
		},
		merchantId: 'ATA01',
		placeOfIncorporation: 'ATA01',
		categoryId: 1,
		dateOfIncorporation: '5/8/2023',
		productShortName: 'ATA01',
		tin: '091231231231',
		merchantTradeName: 'ATA01',
		consumerBaseCount: '1',
		productCode: 'AUTO',
		productTradeName: 'ATA01',
		legalStructure: 'SOLE',
		addresses: [
			{
				locationBase: 'LOCAL',
				buildingNo: 'ATA01',
				addressCategory: 'HEAD',
				barangayId: 140101001,
				countryId: 175,
				cityId: 140101000,
				provinceId: 140100000,
				zipCodeId: 502800000,
			},
		],
		contactDetails: [
			{
				area_handled: '140101000',
				tel_no: ['02123123123'],
				name: 'ATA01',
				mobile_no: ['+639123123123'],
				position: 'ATA01',
				department: 'ATA01',
				email: ['da@da.cn'],
				cityId: '140101000',
			},
		],
		accountOfficer: {
			name: 'Daaa',
			mobile_no: ['+639123123123'],
			email: ['da@da.cn'],
			tel_no: ['02123123123'],
		},
		code: 'ATA01',
		isDirty: false,
	},
	contractDetails: {
		contract: {
			uploadSchedule: 'SAME',
			uploadTime: '00:12',
			depositSchedule: 'FIRST',
			oneTimeFee: '1',
			uploadPenaltyType: 'AMOUNT',
			depositPenaltyType: 'AMOUNT',
			otherPenaltiesType: 'AMOUNT',
			collectionDate: '5/8/2023',
			oneTimeFeeAmount: '10',
			uploadPenaltyRate: '10',
			depositPenaltyRate: '10',
		},
		cashCollection: {
			bankId: 44,
			bankBranch: 'ATA01',
			accountName: 'ATA01',
			accountNo: '123123123',
			accountType: 'SAVINGS',
		},
		checkCollection: {
			bankId: 44,
			bankBranch: 'ATA01',
			accountName: 'ATA01',
			accountNo: '123123123',
			accountType: 'SAVINGS',
		},
		termsDuration: {
			executionDate: '5/9/2023',
			autoRenew: '1',
		},
		dpoDetail: {
			locationBase: 'LOCAL',
			countryId: 175,
			name: 'ATA01',
			email: ['da@da.cn'],
			contactNo: ['+639123123123'],
			provinceId: 140100000,
			cityId: 140101000,
			barangayId: 140101001,
			buildingNo: 'Daaa',
			zipCodeId: 502800000,
			executionDate: '5/17/2023',
			terminationDate: '5/24/2023',
			province: {},
			city: {
				label: 'BANGUED',
				value: 140101000,
			},
			barangay: {
				label: 'AGTANGAO',
				value: {
					id: 140101001,
					cityId: 140101000,
				},
			},
			zipCode: {
				label: '2800',
				value: 502800000,
			},
		},
		dsaBond: {
			bondType: 'SURETY',
			bondAmount: '10',
			bondPremiumType: 'AMOUNT',
			bondRenewal: 'MONTHLY',
			prefundedAmount: '10',
			prefundedThresholdAmt: '10',
			executionDate: '5/2/2023',
			terminationDate: '5/17/2023',
		},
		accreditation: {
			cbciAmlQuestion: '95cc9e9b-5e3a-4660-922b-eb4d8e90d745-Blank diagram.png',
		},
		isDirty: false,
	},
	serviceFeeSettings: {
		billingType: 'BOTH',
		vat: 'INCLUSIVE',
		withNonShareable: '1',
		nonShareableRateType: 'FIXED_RATE',
		schemeType: 'REGULAR',
		tierBaseType: null,
		tierRateType: null,
		specialRateType: null,
		withSfSettleDeduction: '1',
		frequencyType: 'YEARLY',
		frequencyMonth: 'January',
		frequencyDay: '1',
		tiers: [
			{
				passOnRate: null,
				subsidizedRate: null,
				nonSharePercentage: null,
				totalFixedRate: 0,
				channelTypeId: null,
			},
		],
		isDirty: true,
		isValid: false,
	},
	businessRules: {
		productBusinessRule: {
			paymentMode: ['CASH', 'CHECK', 'CASH_CHECK', 'CREDIT'],
			acceptedCheckTypes: [
				'LOCAL_CLEAR_CHECK',
				'REGION_CHECK',
				'OUT_OF_TOWN_CHECK',
				'LOCAL_CASHIER_CHECK',
				'DIVIDEND_CHECK',
				'OTHER_CHECK',
			],
			requiredDocuments: [
				'SOA',
				'BAYAD_FORM',
				'SMS',
				'SOA_SMS_BAYAD_FORM',
				'ONLINE',
				'OTHER',
			],
			areaRestriction: '1',
			memo: 'bbcd3e15-0b3a-480e-b77d-af499a774ac3-Blank diagram (1).png',
			memoFileName: 'Blank diagram (1).png',
			minAmountCash: '10',
			maxAmountCash: '100',
			minAmountCheck: '10',
			maxAmountCheck: '100',
			paymentAccepted: ['EXACT_PAYMENT', 'OVER_PAYMENT', 'UNDER_PAYMENT'],
			paymentAcceptancePolicy: 'BEYOND_DUE',
			otherCheckTypes: 'TEST other',
			cashMultipleBills: ['1'],
			checkMultipleBills: ['1'],
			cashCheckMultipleBills: ['1'],
			posType: ['BAYAD_POS', 'PARTNER_POS'],
			gracePeriod: '10',
		},
		isDirty: false,
	},
	reports: {
		sftp: {
			host: 'test.com',
			port: '80',
			username: 'test',
			password: 'test',
			path: '/test',
			csr_config: {
				csr_columns: [4, 7, 8, 6, 9, 5, 1, 3, 2],
				filename_format: 'CSV',
				upload_frequency: 1,
				upload_schedule: 'SAME',
			},
			upload_config: {
				filename_format: 'CSV',
				upload_frequency: 1,
				upload_schedule: 'SAME',
			},
		},
		smtp: {
			csr_config: {
				csr_columns: [4, 7, 8, 6, 9, 5, 1, 3, 2],
				filename_format: 'XLS',
				upload_frequency: 1,
				upload_schedule: 'NEXT',
			},
			upload_config: {
				filename_format: 'CSV',
				upload_frequency: 1,
				upload_schedule: 'SAME',
			},
			receivers: ['email@email.com'],
			sender: 'online@bayad.com',
		},
		is_dirty: false,
		is_valid: false,
	},
};
