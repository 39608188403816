import cx from 'classnames';

import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import TagInput from 'components/TagInput/TagInput';

import { formatDate, formatPhoneNumber } from 'utils/common';

import { UserDetailsTabProps } from '../types';
import styles from './UserDetails.module.css';

const UserDetails: React.FC<UserDetailsTabProps> = ({ cellValue }) => {
	const branchNames: string[] | undefined = cellValue?.branch.split(', ');
	return (
		<Section>
			<SectionRow>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Username"
						disabled
						value={cellValue?.username}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Date Created"
						disabled
						value={formatDate(
							cellValue?.createdDate ?? new Date(),
							'MM/DD/YYYY'
						)}
						icons={[
							{
								path: 'utility/date_input',
							},
						]}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="User Type"
						disabled
						value={cellValue?.userType}
						icons={[
							{
								path: 'utility/down',
							},
						]}
						className={cx(styles.section)}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="User Role"
						disabled
						value={cellValue?.userRole}
						icons={[
							{
								path: 'utility/down',
							},
						]}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={2} of={3}>
					{branchNames && branchNames.length > 1 ? (
						<TagInput
							label="Branch Name"
							tags={branchNames}
							tagClassName={styles.pill}
							disabled
							viewTagsOnly
						/>
					) : (
						<InputWithIcon
							label="Branch Name"
							disabled
							value={cellValue?.branch}
							className={cx(styles.section)}
						/>
					)}
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="First Name"
						disabled
						value={cellValue?.firstName}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Middle Name"
						disabled
						value={cellValue?.middleName}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Last Name"
						disabled
						value={cellValue?.lastName}
						className={cx(styles.section)}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="User Status"
						disabled
						value={cellValue?.userStatus}
						icons={[
							{
								path: 'utility/down',
							},
						]}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Contact No."
						disabled
						value={formatPhoneNumber(cellValue?.contactNo)}
						className={cx(styles.section)}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<InputWithIcon
						label="Email Address"
						disabled
						value={cellValue?.email}
						className={cx(styles.section)}
					/>
				</Grid>
			</SectionRow>
			{cellValue?.userType === 'INTERNAL' && (
				<>
					<SectionRow>
						<Grid column size={1} of={3}>
							<InputWithIcon
								label="Job Title"
								disabled
								value={cellValue?.jobTitle}
								className={cx(styles.section)}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<InputWithIcon
								label="Division"
								disabled
								value={cellValue?.division}
								className={cx(styles.section)}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<InputWithIcon
								label="Department"
								disabled
								value={cellValue?.department}
								className={cx(styles.section)}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<InputWithIcon
								label="Employment Start Date"
								disabled
								value={formatDate(
									cellValue?.employmentStartDate ?? new Date(),
									'MM/DD/YYYY'
								)}
								icons={[
									{
										path: 'utility/date_input',
									},
								]}
								className={cx(styles.section)}
							/>
						</Grid>
					</SectionRow>
				</>
			)}
		</Section>
	);
};

export default UserDetails;
