import { DEFAULT_SENDER_EMAIL } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/constants';
import moment from 'moment';

export const mapReports = (v: any): any => {
	const formatSchedule = (arr: Array<any>) =>
		arr.map((v = {}) => ({
			generateId: v.generateId || null,
			uploadId: v.uploadId || null,
			covered_schedule: v?.coveredSchedule,
			covered_time_from: v?.coveredTime?.from,
			covered_time_to: v?.coveredTime?.to,
			generation_time: v?.generationTime,
			upload_time: v?.timeOfUpload,
		}));
	const {
		sftp: {
			baseInfo: sftpBaseInfo,
			csrConfiguration: {
				columns: sftpColumns = [],
				fileNameFormat: sftpCsrFileFormat,
				timestamps: sftpCsrTimestamps = [],
				...sftpCsrRest
			},
			uploadConfiguration: {
				fileNameFormat: sftpUploadFileFormat,
				timestamps: sftpUploadTimestamps = [],
				...sftpUploadRest
			},
			...sftpRest
		},
		smtp: {
			baseInfo: smtpBaseInfo,
			csrConfiguration: {
				columns: smtpColumns = [],
				fileNameFormat: smtpCsrFileFormat,
				timestamps: smtpCsrTimestamps = [],
				...smtpCsrRest
			},
			uploadConfiguration: {
				fileNameFormat: smtpUploadFileFormat,
				timestamps: smtpUploadTimestamps = [],
				...smtpUploadRest
			},
			receiverEmail,
			senderEmail,
		},

		...rest
	} = v;

	return {
		sftp: {
			...sftpBaseInfo,
			csrConfig: {
				csrColumns: sftpColumns.map(({ id }) => id),
				filenameFormat: sftpCsrFileFormat,
				schedule: formatSchedule(sftpCsrTimestamps),
				...sftpCsrRest,
			},
			uploadConfig: {
				filenameFormat: sftpUploadFileFormat,
				schedule: formatSchedule(sftpUploadTimestamps),
				...sftpUploadRest,
			},
			...sftpRest,
		},
		smtp: {
			csrConfig: {
				csrColumns: smtpColumns.map(({ id }) => id),
				filenameFormat: smtpCsrFileFormat,
				schedule: formatSchedule(smtpCsrTimestamps),
				...smtpCsrRest,
			},
			uploadConfig: {
				filenameFormat: smtpUploadFileFormat,
				schedule: formatSchedule(smtpUploadTimestamps),
				...smtpUploadRest,
			},
			receivers: receiverEmail,
			sender: senderEmail || DEFAULT_SENDER_EMAIL,
		},
		...rest,
	};
};

export const checkTimeStampInterval = (generationTimeVal, timeOfUploadVal) => {
	if (generationTimeVal && timeOfUploadVal) {
		timeOfUploadVal = moment(timeOfUploadVal, 'HH:mm').format('hh:mm A');
		generationTimeVal = moment(generationTimeVal, 'HH:mm').format('hh:mm A');

		let timeDiff: number;
		const timeOfUploadDiff = moment(timeOfUploadVal, 'hh:mm A');
		const generationTimeDiff = moment(generationTimeVal, 'hh:mm A');
		if (timeOfUploadVal.includes('AM') && generationTimeVal.includes('PM')) {
			timeDiff = timeOfUploadDiff
				.add('1', 'day')
				.diff(generationTimeDiff, 'minutes');
		} else timeDiff = timeOfUploadDiff.diff(generationTimeDiff, 'minutes');
		return timeDiff < 30 ? false : true;
	}
	return true;
};

export const checkTimeStampIntervalField = (
	generationTimeVal,
	timeOfUploadVal
) => {
	if (generationTimeVal && timeOfUploadVal) {
		timeOfUploadVal = moment(timeOfUploadVal, 'HH:mm').format('hh:mm A');
		generationTimeVal = moment(generationTimeVal, 'HH:mm').format('hh:mm A');

		let timeDiff: number;
		const timeOfUploadDiff = moment(timeOfUploadVal, 'hh:mm A');
		const generationTimeDiff = moment(generationTimeVal, 'hh:mm A');
		if (timeOfUploadVal.includes('AM') && generationTimeVal.includes('PM')) {
			timeDiff = timeOfUploadDiff
				.add('1', 'day')
				.diff(generationTimeDiff, 'minutes');
		} else timeDiff = timeOfUploadDiff.diff(generationTimeDiff, 'minutes');
		return timeDiff < 30 ? false : true;
	}
	return false;
};

export const checkCoveredTime = (coveredTimeFrom, coveredTimeTo) => {
	if (coveredTimeFrom && coveredTimeTo) {
		coveredTimeTo = moment(coveredTimeTo, 'HH:mm').format('hh:mm A');
		coveredTimeFrom = moment(coveredTimeFrom, 'HH:mm').format('hh:mm A');

		const coveredTimeToDiff = moment(coveredTimeTo, 'hh:mm A');
		const coveredTimeFromDiff = moment(coveredTimeFrom, 'hh:mm A');

		// Compare if coveredTimeFrom is greater than coveredTimeTo
		return coveredTimeFromDiff.isSameOrBefore(coveredTimeToDiff);
	}
	return true;
};
export const checkCoveredTimeField = (coveredTimeFrom, coveredTimeTo) => {
	if (coveredTimeFrom && coveredTimeTo) {
		coveredTimeTo = moment(coveredTimeTo, 'HH:mm').format('hh:mm A');
		coveredTimeFrom = moment(coveredTimeFrom, 'HH:mm').format('hh:mm A');

		const coveredTimeToDiff = moment(coveredTimeTo, 'hh:mm A');
		const coveredTimeFromDiff = moment(coveredTimeFrom, 'hh:mm A');

		// Compare if coveredTimeFrom is greater than coveredTimeTo
		return coveredTimeFromDiff.isSameOrBefore(coveredTimeToDiff);
	}
	return true;
};
