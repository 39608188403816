import Grid from 'components/Grid/Grid';
import React, { ReactNode, useState } from 'react';
import styles from './BillerDetails.module.css';
import BillerForm from '../BillerForm/BillerForm';
import BillerStatusSelect from '../BillerForm/BillerStatusSelect/BillerStatusSelect';

import client from '../../helpers/ApiClient';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PanelPlaceholder from '../BillerList/BillerTabPanelPlaceholder';
import { SelectOption } from '../Inputs/SelectField/SelectField';
import { useToggle } from '../../utils/hooks';

type Props = {
	selectedBiller: any;
};

type BasicInfoFields = {
	merchant_id?: string;
	biller_trade_name?: string;
	merchant_trade_name?: string;
	biller_short_name?: string;
	biller_code?: string;
	industry?: SelectOption;
	other_industry?: string;
	sub_brands?: string;
	place_of_incorporation?: string;
	date_of_incorporation?: string;
	website_address?: string;
	legal_structure?: SelectOption;
	other_legal_structure?: string;
	tin?: string;
	consumer_base_count?: string;
};

type LocalBillerAddressFields = {
	locationBase: 'LOCAL';
	country?: SelectOption;
	address?: Array<any>;
	address_category: SelectOption;
	province?: SelectOption;
	city?: SelectOption;
	barangay?: SelectOption;
	category?: SelectOption;
	street?: string;
	building_no?: string;
	zip_code?: string;
};

type BillerContactFields = {
	contact_details: Array<any>;
};

type AccountOfficerFields = {
	officer_name: string;
	officer_email_address: any;
	officer_contact_number: any;
};

const defaultValue: any = {
	primaryInformation: {
		basicInformation: {},
		billerAddress: [
			{
				locationBase: 'LOCAL',
				country: {
					label: 'Philippines',
					value: '1',
				},
			},
		],
		billerContactDetails: [{ name: '', email: [], contactNumber: [] }],
	},
};

const capitalize = (s: any) =>
	(s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()) || 'Engaged';

const PartnerDetails: React.FC<Props> = ({ selectedBiller }) => {
	const [billerInfo, setBillerInfo] = useState<
		BasicInfoFields &
			LocalBillerAddressFields &
			BillerContactFields &
			AccountOfficerFields
	>();

	const [billerStatusSTG, setBillerStatusSTG] = useState<any>();
	const [billerStatusPROD, setBillerStatusPROD] = useState<any>();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const formattedBillerInfo: any = billerInfo
		? {
				primaryInformation: {
					basicInformation: {
						merchantId: billerInfo.merchant_id,
						billerTradeName: billerInfo.biller_trade_name,
						merchantTradeName: billerInfo.merchant_trade_name,
						billerShortName: billerInfo.biller_short_name,
						billerCode: billerInfo.biller_code,
						industry: billerInfo.industry,
						otherIndustry: billerInfo.other_industry,
						subBrands: billerInfo.sub_brands,
						placeOfIncorporation: billerInfo.place_of_incorporation,
						dateOfIncorporation: billerInfo.date_of_incorporation,
						website: billerInfo.website_address,
						legalStructure: billerInfo.legal_structure,
						otherLegalStructure: billerInfo.other_legal_structure,
						tin: billerInfo.tin,
						consumerBaseCount: billerInfo.consumer_base_count,
					},
					billerAddress: billerInfo.address?.length
						? billerInfo.address?.map((address: any) => ({
								locationBase: address.location_base.toUpperCase(),
								country: { label: address.country },
								category: address.address_category,
								province: address.province,
								city: address.city,
								barangay: address.barangay,
								street: address.street,
								buildingNo: address.building_name,
								zipCode: address.name,
						  }))
						: defaultValue.primaryInformation.billerAddress,
					billerContactDetails: billerInfo.contact_details?.length
						? billerInfo.contact_details?.map((contact: any) => ({
								name: contact.contact_person,
								areaHandled: {
									value: contact.area_handled,
									label: contact.area_handled,
								},
								position: contact.position,
								department: contact.department,
								email: contact.email_address.split('|'),
								contactNumber: contact.contact_number.split('|'),
						  }))
						: defaultValue.primaryInformation.billerContactDetails,
					accountOfficer: {
						name: billerInfo.officer_name,
						email: billerInfo.officer_email_address.split('|'),
						contactNumber: billerInfo.officer_contact_number.split('|'),
					},
				},
		  }
		: defaultValue;

	useEffect(() => {
		if (selectedBiller) {
			showLoading();

			const fetchBillerDetails = async () => {
				try {
					await client
						.get(`/v1/biller/${selectedBiller.id}`)
						.then(({ data }) => {
							console.log(data, `BILLER DETAILS /v1/biller/${data.biller_id}`);
							setBillerInfo(data);
						})
						.catch((e) => {
							setBillerInfo(undefined);
						});
					await client
						.get(`/v1/passthru/biller/${selectedBiller.id}/status`)
						.then(({ data }) => {
							console.log(
								data,
								`BILLER STAT STG /v1/passthru/biller/${selectedBiller.id}/status`
							);
							setBillerStatusSTG(data);
						})
						.catch((e) => {
							setBillerStatusSTG('Engage');
							hideLoading();
						});

					await client
						.get(`/v1/biller/${selectedBiller.id}/status`)
						.then(({ data }) => {
							console.log(
								data,
								`BILLER STAT PROD /v1/biller/${selectedBiller.id}/status`
							);
							setBillerStatusPROD(data);
						})
						.catch((e) => {
							console.log(e, 'ERROR BILLER STAT PROD');
							setBillerStatusPROD('Engage');
							hideLoading();
						});
				} catch (e) {
					hideLoading();
				}

				hideLoading();
			};

			fetchBillerDetails();
		}
	}, [selectedBiller]);

	return selectedBiller ? (
		<div className={styles.container}>
			<FullPageLoader
				open={isLoading}
				message="Please wait while biller details is being loaded"
			/>
			{selectedBiller && !isLoading && (
				<>
					<div className={styles.title}>
						{selectedBiller ? selectedBiller.name : 'Biller Name'}
					</div>
					<div className={styles.header}>
						<Grid container gutters="xx-small">
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In STG"
									value={capitalize(billerStatusSTG?.staging?.biller_status)}
									disabled
								/>
							</Grid>
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In Prod"
									value={capitalize(billerStatusPROD?.dev?.biller_status)}
									disabled
								/>
							</Grid>
						</Grid>
					</div>

					<BillerForm disabled={true} initialValues={formattedBillerInfo} />
				</>
			)}
		</div>
	) : (
		<>
			<PanelPlaceholder />
		</>
	);
};

export default connect(
	(state: any) => ({ selectedBiller: state.billers.selectedBiller }),
	{}
)(PartnerDetails);
