import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ServiceFeeSchemaType } from '../../ServiceFee/ServiceFeeSettings';
import { ServiceFeeSettingsFormData } from '../../ServiceFee/ServiceFeeSettings';
import PercentageSchema from './PercentageSchema';
import RegularSchema from './RegularSchema';
import SpecialSchema from './SpecialSchema';
import TierSchema from './TierSchema';

type ServiceFeeSchemaTableProps = {
	schemeType: ServiceFeeSchemaType;
	tierCount: ServiceFeeSettingsFormData['tier_count'];
	control: Control<ServiceFeeSettingsFormData>;
	withNonShareableAmount: ServiceFeeSettingsFormData['with_non_shareable_amount'];
	serviceFeeBillingType: ServiceFeeSettingsFormData['billing_type'];
	action?: string;
	getValues: UseFormGetValues<ServiceFeeSettingsFormData>;
	setValue: UseFormSetValue<any>;
	modalIsOpen?: boolean;
};

const ServiceFeeSchemaTable: React.FC<ServiceFeeSchemaTableProps> = ({
	schemeType,
	tierCount,
	control,
	withNonShareableAmount,
	serviceFeeBillingType,
	modalIsOpen,
	...props
}) => {
	const isTierNonShareable =
		serviceFeeBillingType?.PASS_ON === true &&
		serviceFeeBillingType?.SUBSIDIZED === false &&
		schemeType == 'TIER';

	switch (schemeType) {
		case 'REGULAR':
			return (
				<RegularSchema
					control={control}
					serviceFeeBillingType={serviceFeeBillingType}
					withNonShareableAmount={withNonShareableAmount}
					modalIsOpen={modalIsOpen}
					viewType="regularSchema"
					{...props}
				/>
			);
		case 'TIER':
			return (
				<TierSchema
					control={control}
					serviceFeeBillingType={serviceFeeBillingType}
					withNonShareableAmount={
						isTierNonShareable ? '0' : withNonShareableAmount
					}
					tierCount={tierCount}
					modalIsOpen={modalIsOpen}
					viewType="tierSchema"
					isShowNonShareableColumn={isTierNonShareable}
					{...props}
				/>
			);
		case 'PERCENTAGE':
			return (
				<PercentageSchema
					control={control}
					serviceFeeBillingType={serviceFeeBillingType}
					withNonShareableAmount={withNonShareableAmount}
					modalIsOpen={modalIsOpen}
					viewType="percentageSchema"
					{...props}
				/>
			);
		case 'SPECIAL':
			return (
				<SpecialSchema
					control={control}
					serviceFeeBillingType={serviceFeeBillingType}
					withNonShareableAmount={withNonShareableAmount}
					modalIsOpen={modalIsOpen}
					viewType="specialSchema"
					{...props}
				/>
			);
		default:
			return <></>;
	}
};
//
export default ServiceFeeSchemaTable;
