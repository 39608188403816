import { common } from './common';
import * as yup from 'yup';

export type product_service_fee = {
	billing_type?: string;
	vat?: string;
	with_non_shareable?: number;
	non_shareable_rate_type?: string;
	scheme_type?: string;
	tier_base_type?: string;
	tier_rate_type?: string;
	special_rate_type?: string;
	with_sf_settle_deduction?: number;
	frequency_type?: string;
	frequency_month?: number;
	frequency_day?: number;
} & common;

export const product_service_fee_schema = yup.object().shape({});
