import transaction from './transaction';
import timeout from './timeout';
import userInfo from './userInfo';
import changePassword from './changePassword';
import sidebar from './sidebar';
import viewtransaction from './viewtransaction';
import billerList from './billerList';
import channelList from './channelList';
import access from './access';
import form from './form';
import reports from './reports';
import reportViewer from './report-viewer';
import reportMaintenance from './report-maintenance';

import { connectRouter, RouterState } from 'connected-react-router';
import branchWallet from './branchWallet';
import voidTransaction from './voidTransaction';
import walletReplenishment from './walletReplenishment';
import viewWalletDetails from './viewWalletDetails';
import products from './products';
import approval from './approval';
import common from './common';

import { History, LocationState } from 'history';
import { Reducer } from '@reduxjs/toolkit';
import { IScope } from 'utils/verifyPermission';

export type ReducerStateType = {
	transaction: any;
	timeout: any;
	userInfo: {
		scopes?: IScope[];
		[k: string]: any;
		permissions?: Record<string, any>;
	};
	changePassword: any;
	sidebar: any;
	viewtransaction: any;
	viewWalletDetails: any;
	router: Reducer<RouterState<LocationState>>;
	branchWallet: any;
	billers: any;
	form: any;
	channels: any;
	voidTransaction: any;
	access: any;
	walletReplenishment: any;
	products: any;
	approval: any;
	common: any;
	reports: any;
	reportViewer: any;
	reportMaintenance: any;
};

const reducers = (history: History<LocationState>): ReducerStateType => ({
	transaction,
	timeout,
	userInfo,
	changePassword,
	sidebar,
	viewtransaction,
	viewWalletDetails,
	router: connectRouter(history),
	branchWallet,
	billers: billerList,
	form,
	channels: channelList,
	voidTransaction,
	access,
	walletReplenishment,
	products,
	approval,
	common,
	reports,
	reportViewer,
	reportMaintenance,
});

export default reducers;
