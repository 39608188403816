import PanelPlaceholder from './BillerTabPanelPlaceholder';

import BillerDetails from 'components/BillerDetails/BillerDetails';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';

const BillerTab: React.FC = () => {
	const primaryTabProps = {
		tabs: [
			{
				title: 'Biller Performance',
				component: <PanelPlaceholder />,
			},
			{
				title: 'Biller Details',
				component: <BillerDetails />,
			},
			{
				title: 'Audit Trail',
				component: <PanelPlaceholder />,
			},
		],
		defaultIndex: 1,
	};

	return <PrimaryTab {...primaryTabProps} />;
};

export default BillerTab;
