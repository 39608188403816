import React, { useState } from 'react';

import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';

import ChannelBranchTpa from './ChannelBranchTpa';
import ChannelBranchAccess from './ChannelBranchAccess';
import ChannelUserList from './ChannelUserList';
import ChannelAuditTrail from './ChannelAuditTrail';

import { ChannelDetailsBodyProps } from '../types';
import styles from '../ChannelDetails/ChannelDetails.module.css';

const PRIMARY_TABS = (activeTab, channelId, isRefetchData) => {
	return [
		{
			title: 'Branch/TPAID',
			component: activeTab === 0 && (
				<ChannelBranchTpa channelId={channelId} isRefetchData={isRefetchData} />
			),
		},
		{
			title: 'Branch Access Control',
			component: activeTab === 1 && (
				<ChannelBranchAccess channelId={channelId} />
			),
		},
		{
			title: 'User List',
			component: activeTab === 2 && <ChannelUserList channelId={channelId} />,
		},
		{
			title: 'Audit Trail',
			component: activeTab === 3 && (
				<ChannelAuditTrail
					channelId={channelId}
					isRefetchData={isRefetchData}
				/>
			),
		},
	];
};

const ChannelDetailsBody: React.FC<ChannelDetailsBodyProps> = ({
	data,
	isRefetchData,
}) => {
	// used to ensure shown tab is the only tab that renders
	const [activeTab, setActiveTab] = useState(0);

	return (
		<div className={styles.tabContainer}>
			<PrimaryTab
				tabs={PRIMARY_TABS(activeTab, data?.id, isRefetchData)}
				onSelect={(index) => setActiveTab(index)}
				className={styles.tabPanel}
			/>
		</div>
	);
};
export default ChannelDetailsBody;
