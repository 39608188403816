import {
	setCategoryId,
	fetchReportNames,
	fetchCategoryTable,
	setClearStates,
} from 'redux/modules/reports';
import { fetchAuditTrail, fetchViewReport, setErrorFalse } from 'redux/modules/report-viewer';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TAB_ITEMS } from 'components/Reports/constants/report-tabs';
import { categories } from 'utils/queries/reports';
import { useCallback, useEffect } from 'react';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ReportErrorDialog from 'components/Reports/ReportErrorDialog';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import ReportModal from 'components/Reports/ReportModal';
import styles from './index.module.css';
import cx from 'classnames';

export default function Reports(): JSX.Element {
	const dispatch = useDispatch();
	const stateReportViewer = useSelector(
		(states: RootStateOrAny) => states.reportViewer
	);
	const viewReport = stateReportViewer.view_report;
	const auditTrial = stateReportViewer.audit_trail;
	const stateReport = useSelector((states: RootStateOrAny) => states.reports);
	const { report_file_id } = stateReport;
	const hasLoading =
		stateReport.loading || viewReport.loading || auditTrial.loading;

	const categoryId = stateReport.report_category_id;
	const reports = stateReport[categories[categoryId]];
	const pagination = reports.pagination;
	const filters = reports.filter;
	const hasError = stateReport.error || viewReport.error || auditTrial.error;

	//--- fetch reports name --- //
	useEffect(() => {
		dispatch(
			fetchReportNames({
				report_category_id: categoryId,
			})
		);
	}, [categoryId]);

	useEffect(() => {
		return () => {
			dispatch(setErrorFalse());
			dispatch(setClearStates());
		};
	}, []);

	const handleCategory = (index) => {
		dispatch(setCategoryId(index + 1));
	};

	const handleRetryFetching = useCallback(() => {
		if (reports.has_report_name_error) {
			dispatch(
				fetchReportNames({
					report_category_id: categoryId,
				})
			);
		} else if (reports.has_report_files_error) {
			dispatch(
				fetchCategoryTable({
					categoryId,
					...pagination,
					...filters,
				})
			);
		} else if (viewReport.error) {
			dispatch(
				fetchViewReport({
					report_file_id,
					categoryId,
				})
			);
		} else if (auditTrial.error) {
			dispatch(
				fetchAuditTrail({
					report_file_id,
					categoryId,
					...pagination,
				})
			);
		}
	}, [
		auditTrial.error,
		categoryId,
		dispatch,
		filters,
		pagination,
		report_file_id,
		reports.has_report_files_error,
		reports.has_report_name_error,
		viewReport.error,
	]);
	return (
		<>
			<FullPageLoader
				open={hasLoading}
				message="Please wait while data are being loaded"
			/>
			{viewReport.error && auditTrial.error && (
				<ReportErrorDialog
					handleRetry={() => {
						handleRetryFetching();
					}}
				/>
			)}
			{hasError && (
				<ReportErrorDialog
					handleRetry={() => {
						handleRetryFetching();
					}}
				/>
			)}
			<ReportModal />
			<div className={cx('slds-card', styles.paperContainer)}>
				<div className="slds-grid sls-grid_align-spread">
					<div className={cx('slds-col', styles.title)}>Reports</div>
				</div>
				<div className="slds-grid sls-grid_align-spread">
					<PrimaryTab
						{...TAB_ITEMS}
						onSelect={(index) => {
							handleCategory(index);
						}}
					/>
				</div>
			</div>
		</>
	);
}
