import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import { FaCheckCircle } from 'react-icons/fa';
import cx from 'classnames';
import styles from './BillerForm.module.css';
import PrimaryInformation, {
	PrimaryInformationFormData,
} from './Tabs/PrimaryInformation/PrimaryInformation';
import { FixMeLater } from 'types';
import { TBillerStatus } from './BillerStatusSelect/BillerStatusSelect';
import { PrimaryInfoSaveResponse } from 'components/BillerModal/BillerModal';
import ServiceFeeSettings from './Tabs/ServiceFeeSettings/ServiceFeeSettings';
import BillerUploadSettings from './Tabs/BusinessUploadSettings/BillerUploadSettings';
type TabLabelProps = {
	label: string;
	done?: boolean;
	disabled?: boolean;
};
import { BillerUploadSettingsFormData } from './Tabs/BusinessUploadSettings/BillerUploadSettings';
import { connect } from 'react-redux';
import { setCurrentBillerTab } from 'redux/modules/billerList';

export const TabLabel: React.FC<TabLabelProps> = ({
	label,
	done,
	disabled,
}) => {
	return (
		<div className={cx({ [styles.tabDisabled]: disabled })}>
			{label}
			{done && <FaCheckCircle className={cx(styles.tabCheck, {})} />}
		</div>
	);
};

export type BillerFormProps = {
	tabStatus?: TabStatus;
	disabled?: boolean;
	contentClassName?: string;
	currentBillerTab?: string;
	setCurrentBillerTab: (tabName: string) => void;
	showSucess?: any;
	showFail?: any;
	onSave?: (values: any) => Promise<PrimaryInfoSaveResponse>;
	onSubmit?: (
		values: PrimaryInformationFormData
	) => Promise<PrimaryInfoSaveResponse>;
	initialValues: {
		status?: {
			uat?: TBillerStatus;
			staging?: TBillerStatus;
			production?: TBillerStatus;
		};
		primaryInformation?: PrimaryInformationFormData;
		contractDetails?: FixMeLater;
		serviceFeeSettings?: FixMeLater;
		businessRules?: FixMeLater;
		billerUploadSettings?: BillerUploadSettingsFormData;
	};
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

type TabIndexNameType = Array<{
	index: number;
	name: string;
	label: string;
}>;

export type TabStatus = PartialRecord<
	keyof BillerFormProps['initialValues'],
	TabStatusValue
>;

function getPanels({ tabStatus }: { tabStatus: TabStatus }) {
	const panels: {
		key: keyof BillerFormProps['initialValues'];
		label: string;
		Content?: React.FC<any>;
	}[] = [
		{
			key: 'primaryInformation',
			label: 'Primary Information',
			Content: PrimaryInformation,
		},
		{
			key: 'contractDetails',
			label: 'Contract Details',
			// Content: PrimaryInformation,
		},
		{
			key: 'serviceFeeSettings',
			label: 'Service Fee Settings',
			// Content: PrimaryInformation,
		},
		{
			key: 'businessRules',
			label: 'Business Rules',
			// Content: PrimaryInformation,
		},
		{
			key: 'billerUploadSettings',
			label: 'Biller Upload Settings',
			// Content: PrimaryInformation,
		},
	];

	return panels.map(({ key, label, Content = () => <></> }) => ({
		label: (
			<TabLabel
				label={label}
				disabled={tabStatus[key]?.disabled}
				done={tabStatus[key]?.finished}
			/>
		),
		Content: (props: any) => <Content {...props} />,
		key,
		status: tabStatus[key],
	}));
}

const initTabStatus: TabStatus = {
	primaryInformation: { disabled: false },
	contractDetails: { disabled: true },
	serviceFeeSettings: { disabled: true },
	businessRules: { disabled: true },
	billerUploadSettings: { disabled: false },
};

export const tabIndexNames: TabIndexNameType = [
	{ index: 0, name: 'primaryInformation', label: 'Primary Information' },
	// { index: 1, name: 'contractDetails',label: 'Contract Details', },
	{ index: 2, name: 'serviceFeeSettings', label: 'Service Fee Settings' },
	// { index: 3, name: 'businessRules' ,label: 'Business Rules',},
	{ index: 4, name: 'billerUploadSettings', label: 'Biller Upload Settings' },
];
const BillerForm: React.FC<BillerFormProps> = ({
	tabStatus = initTabStatus,
	contentClassName = '',
	initialValues,
	setCurrentBillerTab,
	currentBillerTab,
	showSucess,
	showFail,
	...props
}) => {
	function findIndex() {
		const idx = tabIndexNames.find((tab) => tab.name == currentBillerTab);
		return idx?.index;
	}
	function findIndexName(selected: any): string {
		const idx = tabIndexNames.findIndex((tab) => tab.index == selected);
		return tabIndexNames[idx].name;
	}
	return (
		<Tabs
			className={styles.tabs}
			onSelect={(i) => {
				setCurrentBillerTab(findIndexName(i));
			}}
			selectedIndex={findIndex()}
		>
			<TabsPanel
				label={
					<TabLabel
						label="Primary Information"
						done={tabStatus.primaryInformation?.finished}
					/>
				}
				disabled={tabStatus.primaryInformation?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}>
					<PrimaryInformation
						{...props}
						initialValues={
							initialValues.primaryInformation as PrimaryInformationFormData
						}
					/>
				</div>
			</TabsPanel>
			<TabsPanel
				label="Contract Details"
				disabled={tabStatus.contractDetails?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}></div>
			</TabsPanel>
			<TabsPanel label="Service Fee Settings" disabled={true}></TabsPanel>
			<TabsPanel
				label="Business Rules"
				disabled={tabStatus.businessRules?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}></div>
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Biller Upload Settings"
						done={tabStatus.billerUploadSettings?.finished}
					/>
				}
				disabled={tabStatus.billerUploadSettings?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}>
					<BillerUploadSettings
						{...props}
						initialValues={
							(initialValues.billerUploadSettings as BillerUploadSettingsFormData) ||
							{}
						}
						showSuccess={showSucess}
						showFail={showFail}
					/>
				</div>
			</TabsPanel>
		</Tabs>
	);
};

export default connect(
	(state: any) => ({
		currentBillerTab: state.billers.currentBillerTab,
	}),
	{ setCurrentBillerTab }
)(BillerForm);
