import {
	Input,
	Textarea,
} from '@salesforce/design-system-react/module/components';
import TextField from '../TextField/TextField';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { isANumber } from 'components/Format/NumberFormatter';
import Grid from 'components/Grid/Grid';
import _ from 'lodash';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import {
	Control,
	Controller,
	UseFormGetValues,
	useWatch,
} from 'react-hook-form';
import Label from '../Label/Label';
import uploadFieldStyles from './UploadField.module.css';
import successModalStyles from 'components/Modal/SuccessModal.module.css';
import errorModalStyles from 'components/Modal/ErrorModal.module.css';
import { useToggle } from 'utils/hooks';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { convertFromBytes } from 'utils/utils';
import HTTP from '../../../helpers/ApiClient';
import axios from 'axios';
import { sleep } from 'utils/common';
const styles = {
	...uploadFieldStyles,
	...successModalStyles,
	...errorModalStyles,
};

const UploadField: React.FC<{
	name: string;
	placeholder?: string;
	helperText?: string;
	control: Control<any>;
	onChange?: (n) => void;
	setValue: (n, v) => void;
	clearErrors: (n) => void;
	buttonText?: string;
	required?: boolean;
	label?: string;
	fileFormats?: string[];
	disabled?: boolean;
	fileSizeLimitInBytes?: number;
	inquireType?: string;
	id?: number;
	getValues: UseFormGetValues<any>;
	statusUrl: string;
	documentUrl: string;
}> = ({
	name,
	placeholder,
	helperText,
	control,
	onChange,
	setValue,
	clearErrors,
	buttonText,
	label,
	required,
	fileFormats = [],
	disabled,
	fileSizeLimitInBytes = 26214400,
	inquireType,
	id,
	getValues,
	statusUrl,
	documentUrl,
}) => {
	const retryFileValues = {
		files: {},
		field: {},
	};
	const inputFileRef: any = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isRetrying, setIsRetrying] = useState(false);
	const [retryFiles, setRetryFiles] = useState(retryFileValues);
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const formats = fileFormats.map((format) => '.' + format).join();
	const fieldTypeFile = () => {
		clearErrors(name);
		setValue(name, undefined);
		setValue('fileName', undefined);
		inputFileRef.current.value = '';
		inputFileRef.current.click();
	};
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const showErrorMessage = (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const handleInquireUpload = (documentData, file, field, index = 1) => {
		const url = statusUrl;
		HTTP.get(url, {
			params: {
				key: documentData.file_name,
				type: inquireType,
				id,
			},
		})
			.then(async ({ data }) => {
				const status = data.status;
				if (status === 'SCAN_CLEAN') {
					setValue(name, documentData.file_name);
					setValue(name + '_fileName', file.name);
					field.onChange(documentData?.file_name);
					onChange && onChange(documentData?.file_name);

					setIsLoading(false);
					showSuccessMessage('File successfully uploaded');
				} else {
					await sleep(10000);
					if (index < 5) {
						index += 1;
						await handleInquireUpload(documentData, file, field, index);
					} else {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while trying to upload the file',
							null,
							() => {
								setIsLoading(true);
								handleInquireUpload(documentData, file, field);
							}
						);
						setIsLoading(false);
					}
				}
			})
			.catch((error) => {
				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while trying to upload the file'
				);
				setValue(name + '_fileName', file.name);
				setIsRetrying(true);
				setIsLoading(false);
			});
	};

	const handleInquireUploadValidation = (files, field) => {
		setRetryFiles({ files, field });
		const file = (files ? files[0] : null) as File;
		if (!file) {
			return;
		}
		if (file.size > fileSizeLimitInBytes) {
			showErrorMessage(
				'File Size Error!',
				'The maximum file size accepted is ' +
					convertFromBytes(fileSizeLimitInBytes, 'MB') +
					'MB.',
				'Please select a different file.'
			);
			return;
		}
		if (
			fileFormats.length > 0 &&
			!fileFormats.includes(file.type.replace(/(.*)\//g, ''))
		) {
			showErrorMessage(
				'File Type Error!',
				'Invalid file type selected.',
				'Please select a different file.'
			);
			return;
		}
		setIsLoading(true);
		const url = documentUrl;
		HTTP.get(url, {
			params: {
				fileName: file?.name,
				contentType: file?.type,
			},
		})
			.then((response) => {
				const documentData = response.data;
				const url = documentData.presigned_keys.url;
				const formData = new FormData();
				Object.entries(documentData.presigned_keys.fields).forEach(
					([key, value]: any) => {
						formData.append(key, value);
					}
				);
				formData.append('file', file);
				const UNINTERCEPTED_HTTP = axios.create({
					baseURL: process.env.REACT_APP_API_ENDPOINT,
					headers: {
						'Content-Type': 'application/json',
						'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
					},
				});
				UNINTERCEPTED_HTTP.post(url, formData)
					.then((response) => {
						handleInquireUpload(documentData, file, field);
					})
					.catch((error) => {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while trying to upload the file'
						);
						setValue(name + '_fileName', file.name);
						setIsRetrying(true);
						setIsLoading(false);
					});
			})
			.catch((error) => {
				if (error.response.status === 400) {
					showErrorMessage('Invalid file type', error.response.data.message);
				} else {
					showErrorMessage(
						'Timeout Error!',
						'A problem occurred while trying to upload the file'
					);
					setValue(name + '_fileName', file.name);
					setIsRetrying(true);
					setIsLoading(false);
				}
			});
	};
	const handleClickRetry = () => {
		handleInquireUploadValidation(retryFiles.files, retryFiles.field);
		setIsRetrying(false);
		setIsLoading(true);
	};
	return (
		<Controller
			name={name}
			control={control}
			render={(props: any) => {
				const {
					field,
					fieldState: { error },
					link,
				} = props;
				return (
					<>
						<input type="hidden" name={name} />
						<Grid column size={1} of={1}>
							<TextField
								link={link}
								label={label || ''}
								name={name}
								className={styles.uploadField}
								control={control}
								placeholder={placeholder ?? 'No file selected'}
								readOnly
								required={required}
								value={getValues(name + '_fileName')}
								disabled={disabled}
								hasSpinner={isLoading}
								isOverrideValueProps={true}
								isRetry={isRetrying}
								onHandleRetry={handleClickRetry}
							/>
							<span
								style={{
									fontStyle: 'italic',
									opacity: 0.6,
								}}
							>
								{helperText}
							</span>
						</Grid>
						<Grid column className={styles.alignCenter} size={1} of={4}>
							<input
								disabled={disabled}
								type="file"
								ref={inputFileRef}
								hidden
								onChange={({ target: { files = [] } }) => {
									handleInquireUploadValidation(files, field);
								}}
								accept={formats || undefined}
							/>
							<OutlineButton
								className={styles.uploadButton}
								fullWidth
								onClick={() => fieldTypeFile()}
								disabled={disabled}
							>
								{buttonText ? buttonText : 'Browse'}
							</OutlineButton>
						</Grid>
						{isSuccessModalOpen && (
							<SuccessModal
								open={isSuccessModalOpen}
								onClose={hideSuccessModal}
							>
								<SuccessModalBody>
									<SuccessText>
										<div className={styles.successHeader}>Success!</div>
										<div className={styles.successBody}>{successMessage}</div>
									</SuccessText>
								</SuccessModalBody>
								<SuccessModalActions>
									<PrimaryButton
										className={styles.successModalBtn}
										onClick={() => {
											doneBtnOnClick.action();
										}}
									>
										Done
									</PrimaryButton>
								</SuccessModalActions>
							</SuccessModal>
						)}
						{isErrorModalOpen && (
							<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
								<ErrorModalBody>
									<div className={styles.errorHeader}>{errorHeader}</div>
									<div className={styles.errorBody}>{errorMessage}</div>
									<div className={styles.errorFooter}>{errorInstruction}</div>
								</ErrorModalBody>
								<ErrorModalActions>
									<PrimaryButton
										fullWidth
										onClick={() => {
											retryBtnOnClick.action();
										}}
										className={styles.errorModalBtn}
									>
										Retry
									</PrimaryButton>
								</ErrorModalActions>
							</ErrorModal>
						)}
					</>
				);
			}}
		/>
	);
};

export default UploadField;
