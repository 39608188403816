import { FC, useState, useEffect } from 'react';
import container from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeContainer.module.css';
import serviceFee from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeTable/ServiceFeeTable.module.css';
import { ServiceFeeContainerProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import ServiceFeeTable from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeTable/ServiceFeeTable';
import {
	FEE_COLUMNS,
	RATE_COLUMNS,
	TIER_COLUMNS,
	TIER_RATE_COLUMNS,
	CHANNEL_TYPE_COLUMNS,
	TIER_COLUMNS_FIELD,
	CHANNEL_TYPE_COLUMS_FIELD,
	CHANNEL_TYPE_RATE_COLUMNS,
	REGULAR_PERCENTAGE_COLUMNS,
} from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import ChannelTable from 'containers/ChannelManagement/Channel/ChannelTable/ChannelTable';
import { isEmpty } from 'lodash';

const ServiceFeeContainer: FC<ServiceFeeContainerProps> = ({
	itemKey,
	data,
	selectedChannel,
	permission,
	serviceFees,
	customStyles,
	hasEditServiceFeePermission,
	channelTypes,
	isLoadingSFS = [],
}) => {
	const [nonShareableColumn, setNonShareableColumn] = useState({
		isPercentage: false,
	});

	const styles = customStyles
		? customStyles
		: {
				...container,
				...serviceFee,
		  };

	const [serviceFeeData, setServiceFeeData] = useState([]);

	useEffect(() => {
		if (serviceFees) {
			setServiceFeeData(serviceFees);
		}
	}, [serviceFees]);

	useEffect(() => {
		const config = (
			(serviceFeeData &&
				serviceFeeData.length > 0 &&
				serviceFeeData.find(
					(v: { config: any; shares: []; values: [] }) =>
						v.config.productId === data.productId
				)) ||
			{}
		).config;
		setNonShareableColumn({
			isPercentage: config && config.nonShareableRateType === 'PERCENTAGE',
		});
	}, [serviceFeeData, data.productId]);

	const calculateTotalAmountForSfShare = (sfShares) => {
		const configuration = sfShares && sfShares.config;
		const schemeType = configuration?.schemeType || '';
		const rateType = configuration?.tierRateType;
		const baseType = configuration?.tierBaseType;
		const nonShareableRateType = configuration?.nonShareableRateType;
		const specialRateType = configuration?.specialRateType;
		return (
			sfShares &&
			sfShares.values &&
			sfShares.values.map((item) => {
				let totalAmountForSfShare = '0';
				let totalAmountForSfShareRate = '0';

				if (schemeType === 'TIER') {
					if (rateType === 'FIXED_RATE') {
						totalAmountForSfShare = item.calcSfShare;
					} else if (rateType === 'PERCENTAGE') {
						totalAmountForSfShareRate = item.calcSfShare;
					}
				} else if (schemeType === 'SPECIAL') {
					if (specialRateType === 'FIXED_RATE') {
						totalAmountForSfShare = item.calcSfShare;
					} else if (specialRateType === 'PERCENTAGE') {
						totalAmountForSfShareRate = item.calcSfShare;
					}
				} else if (schemeType === 'REGULAR') {
					if (nonShareableRateType === 'PERCENTAGE') {
						totalAmountForSfShareRate = item.calcSfShare;
					} else {
						totalAmountForSfShare = item.calcSfShare;
					}
				} else if (schemeType === 'PERCENTAGE') {
					totalAmountForSfShareRate = item.calcSfShare;
				}

				if (
					(nonShareableColumn && nonShareableColumn.isPercentage) ||
					schemeType === 'PERCENTAGE'
				) {
					return { ...item, totalAmountForSfShareRate };
				} else {
					return { ...item, totalAmountForSfShare };
				}
			})
		);
	};

	const [activeColumn, setActiveColumn] = useState<Array<any>>([]);
	const [activeFieldColumn, setActiveFieldColumn] = useState<Array<any>>([]);
	const [
		activeFieldColumnRegularPercentage,
		setActiveFieldColumnRegularPercentage,
	] = useState<Array<any>>([]);
	useEffect(() => {
		const config =
			serviceFeeData &&
			(serviceFeeData.find((v) => v.config.productId === data.productId) || {})
				.config;
		if (config && config.schemeType) {
			switch (config.schemeType) {
				case 'TIER':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(TIER_RATE_COLUMNS);
					} else {
						setActiveColumn(TIER_COLUMNS);
					}
					setActiveFieldColumn(TIER_COLUMNS_FIELD);
					break;
				case 'SPECIAL':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(CHANNEL_TYPE_RATE_COLUMNS);
					} else {
						setActiveColumn(CHANNEL_TYPE_COLUMNS);
					}
					setActiveFieldColumn(CHANNEL_TYPE_COLUMS_FIELD);
					break;
				case 'REGULAR':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(RATE_COLUMNS);
					} else {
						setActiveColumn(FEE_COLUMNS);
					}
					setActiveFieldColumnRegularPercentage(REGULAR_PERCENTAGE_COLUMNS);
					break;
				case 'PERCENTAGE':
					setActiveColumn(RATE_COLUMNS);
					setActiveFieldColumnRegularPercentage(REGULAR_PERCENTAGE_COLUMNS);
					break;
				default:
					setActiveColumn([]);
					setActiveFieldColumn([]);
					break;
			}
		}
	}, [serviceFeeData, nonShareableColumn, itemKey, data.productId]);

	return (
		<div
			id={`accordion-item-${itemKey}-${data.productId}`}
			className={styles.accordionContent}
		>
			<div className={styles.serviceFeeContainer}>
				{serviceFeeData &&
				serviceFeeData.length > 0 &&
				activeColumn &&
				activeColumn.length > 0 ? (
					<>
						<ChannelTable
							itemKey={`table-item-${itemKey}`}
							data={calculateTotalAmountForSfShare(
								serviceFeeData.find(
									(v) => v.config.productId === data.productId
								)
							)}
							columnsParam={activeColumn}
							key={`${itemKey}-${data.productId}-${Math.random() * 1000000}`}
							tableClassName={styles.table}
							bodyClassName={styles.tableBody}
							headerClassName={styles.tableHeader}
							containerClassName={styles.tableContainerStyle}
							customStyles={customStyles}
							channelTypes={channelTypes}
						/>
						<hr className={styles.lineDevider} />
					</>
				) : (
					<div style={{ marginLeft: '1.5vh' }}>
						{isLoadingSFS &&
						isLoadingSFS.find((v) => v.productId === data.productId)
							?.isLoading ? (
							<>Please wait while we load the Service Fee</>
						) : (
							serviceFeeData.filter(
								(v: { config: any; values: any }) =>
									v.config.productId === data.productId && !isEmpty(v.values)
							).length === 0 && <>No Service Fee available</>
						)}
					</div>
				)}

				{serviceFeeData &&
				serviceFeeData.length > 0 &&
				activeFieldColumn &&
				activeFieldColumn.length > 0 ? (
					<ServiceFeeTable
						itemKey={itemKey}
						data={calculateTotalAmountForSfShare(
							serviceFeeData.find((v) => v.config.productId === data.productId)
						)}
						title="Service Fee Share"
						columns={activeFieldColumn}
						nonShareableColumn={nonShareableColumn}
						sfConfig={serviceFeeData}
						permission={permission}
						selectedChannel={selectedChannel}
						product={data}
						key={`${itemKey}-${data.productId}`}
						customStyles={customStyles ? customStyles : undefined}
						hasEditServiceFeePermission={hasEditServiceFeePermission}
						channelTypes={channelTypes}
					/>
				) : serviceFeeData &&
				  serviceFeeData.length > 0 &&
				  activeFieldColumnRegularPercentage &&
				  activeFieldColumnRegularPercentage.length > 0 ? (
					<ServiceFeeTable
						itemKey={itemKey}
						data={calculateTotalAmountForSfShare(
							serviceFeeData.find((v) => v.config.productId === data.productId)
						)}
						title="Service Fee Share"
						columns={activeFieldColumnRegularPercentage}
						nonShareableColumn={nonShareableColumn}
						sfConfig={serviceFeeData}
						permission={permission}
						selectedChannel={selectedChannel}
						product={data}
						key={`${itemKey}-${data.productId}`}
						customStyles={customStyles}
						hasEditServiceFeePermission={hasEditServiceFeePermission}
						channelTypes={channelTypes}
					/>
				) : (
					<></>
				)}

				{/* <hr className={styles.lineDeviderEnd} /> */}
			</div>
		</div>
	);
};

export default ServiceFeeContainer;
