import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useTable, usePagination } from 'react-table';
import Table from '../Table/Table';
import Pagination from '../Table/Pagination';
import styles from './BillersTable.module.css';
import { billersPaginationActionCreator } from '../../redux/modules/transaction';

function BillersTable({
	data,
	columns,
	controlledPageCount,
	count,
	billersPaginationActionCreator,
	page,
}) {
	const memoColumn = useMemo(() => columns, [columns]);
	const memoData = useMemo(() => data, [data]);
	const tableProps = useTable(
		{
			columns: memoColumn,
			data: memoData,
			initialState: {
				pageIndex: page,
			},
			manualPagination: true,
			pageCount: controlledPageCount,
		},
		usePagination
	);

	const {
		state: { pageIndex },
	} = tableProps;

	React.useEffect(() => {
		billersPaginationActionCreator(pageIndex);
	}, [pageIndex]);

	return (
		<div style={{ padding: '35px 25px 0px 25px' }}>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={styles.tableContainer}>
					<Table
						{...tableProps}
						preHeader={null}
						borderStyle="1px solid #0076C0"
					/>
				</div>
			</div>
			<Pagination
				{...tableProps}
				pageIndexProps={page}
				pageCount={controlledPageCount}
				count={count}
			/>
		</div>
	);
}

export default connect(
	(state, ownProps) => ({
		count: state.transaction.billers.pagination.count,
		page: state.transaction.billers.pagination.page,
		controlledPageCount: state.transaction.billers.pagination.pageCount,
		data: state.transaction.billers.searchResult,
		columns: ownProps.columns,
	}),
	{ billersPaginationActionCreator }
)(BillersTable);
