import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import {
	Control,
	UseFormSetValue,
	UseFormSetError,
	useController,
	Path,
	FieldValues,
	UseFormTrigger,
} from 'react-hook-form';
import { ReactComponent as CashIcon } from 'assets/icons/ic-cash.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic-check.svg';
import { ReactComponent as CashCheckIcon } from 'assets/icons/ic-cashcheck.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/ic-creditcard.svg';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	Checkbox as TCheckbox,
	SubCheckboxProps,
} from './PaymentModeCheckbox/Checkbox';
import useMultipleSelect from './PaymentModeCheckbox/useMultiSelect';
import { get as _get } from 'lodash';
import styles from './PaymentMode.module.css';
import { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { BusinessRulesFormData } from '../BusinessRules';

type Props<T extends FieldValues> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
	setError: UseFormSetError<T>;
	trigger: UseFormTrigger<any>;
};

const PAYMENT_MODES = ['CASH', 'CHECK', 'CASH_CHECK', 'CREDIT'];
const POS_TYPES = ['PARTNER_POS', 'BAYAD_POS'];

type ControlledSubCheckboxProps<T extends FieldValues> = SubCheckboxProps &
	Pick<Props<T>, 'control'> & { name: string };

const ControlledSubCheckbox = <T extends BusinessRulesFormData>({
	control,
	name,
	...rest
}: ControlledSubCheckboxProps<T>): JSX.Element => {
	const {
		field: { onChange, value = 0 as number | string },
	} = useController({ name: name as Path<T>, control });

	return (
		<TCheckbox.SubCheckbox
			onClick={() => onChange(value == 0 ? 1 : 0)}
			{...rest}
			checked={value == 1}
		/>
	);
};

const PosCheckbox = <T extends BusinessRulesFormData>({
	control,
	name,
	disabled,
	isCreditSelected,
	setError,
	trigger
}: Props<T> & {
	name: string;
	isCreditSelected: boolean;
}): JSX.Element => {
	const {
		field: { onChange, value = [] as string | Array<string> },
		formState: { defaultValues },
		fieldState: { error },
	} = useController({ name: `${name}.pos_type` as Path<T>, control });
	const _initValues = _get(defaultValues ?? {}, `${name}.pos_type`, []);
	const { toggle } = useMultipleSelect(POS_TYPES, (e) => e, {
		onChange: (v: string | string[]) => {
			const arr = Array.isArray(v) ? v : v?.split(',');
			onChange(arr.filter(Boolean).join(','));
		},
		initialValue: _initValues
			? Array.isArray(_initValues)
				? _initValues
				: _initValues?.split(',')
			: [],
	});
	const isSelected = (v: string) => {
		if (!value) return false;
		const arr = Array.isArray(value) ? value : value?.split(',');
		return arr.includes(v);
	};

	useEffect(() => {
		if (!isCreditSelected) {
			if (isSelected('BAYAD_POS')) {
				toggle('BAYAD_POS');
			}
			if (isSelected('PARTNER_POS')) {
				toggle('PARTNER_POS');
			}
		}
		trigger(`${name}.pos_type`);
	}, [isCreditSelected]);
	

	useEffect(() => {
		const isPosEmpty = isCreditSelected && value?.length <= 0;
		if (isPosEmpty) {
			setError(`${name}.pos_type` as Path<T>, {
				type: 'manual',
				message: 'Please select POS type',
			});
		}
	}, [value?.length]);

	return (
		<>
			<TCheckbox.SubCheckbox
				checked={isSelected('BAYAD_POS')}
				onClick={() => toggle('BAYAD_POS')}
				label="Bayad POS"
				disabled={disabled}
			/>
			<TCheckbox.SubCheckbox
				checked={isSelected('PARTNER_POS')}
				onClick={() => toggle('PARTNER_POS')}
				label="Partner POS"
				disabled={disabled}
			/>
			{error?.message && (
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						<em>{`*${error?.message}`}</em>
					</div>
				</div>
			)}
		</>
	);
};

const PaymentModeCheckboxGroup = <T extends BusinessRulesFormData>({
	control,
	name,
	disabled,
	setValue,
	setError,
	trigger
}: Props<T> & { name: string }): JSX.Element => {
	const paymentModeName = `${name}.payment_mode`;
	const {
		field: { onChange, value: paymentMode = '' as string },
		formState: { defaultValues },
	} = useController({ control, name: `${name}.payment_mode` as Path<T> });
	const _initValues = _get(defaultValues ?? {}, paymentModeName, []);
	const {
		isSelected: isSelectFromUseMultiple,
		selectedEntries,
		toggle,
		toggleAll,
	} = useMultipleSelect(PAYMENT_MODES, (e) => e, {
		onChange,
		initialValue: _initValues
			? Array.isArray(_initValues)
				? _initValues
				: _initValues?.split(',')
			: [],
	});

	const productTypeId = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);

	const disabledMultipleBills = productTypeId !== 1;

	const makeClickHandler = (value: string) => () => {
		toggle(value);
	};

	const handleSelectAllClick = () => {
		toggleAll(!allSelected);
	};

	const isSelected = (v: string) => {
		if (!paymentMode) return false;
		const valueSet = new Set(
			Array.isArray(paymentMode) ? paymentMode : paymentMode.split(',')
		);
		return valueSet.has(v);
	};

	// reset all subcheckboxes when main checkbox is unchecked
	useEffect(() => {
		if (!isSelectFromUseMultiple('CASH')) {
			setValue(`${name}.cash_multiple_bills` as Path<T>, 0 as never, {
				shouldDirty: true,
			});
		}
		if (!isSelectFromUseMultiple('CHECK')) {
			setValue(`${name}.check_multiple_bills` as Path<T>, 0 as never, {
				shouldDirty: true,
			});
		}
		if (!isSelectFromUseMultiple('CASH_CHECK')) {
			setValue(`${name}.cash_check_multiple_bills` as Path<T>, 0 as never, {
				shouldDirty: true,
			});
		}
		if (!isSelectFromUseMultiple('CREDIT')) {
			setValue(`${name}.credit_multiple_bills` as Path<T>, 0 as never, {
				shouldDirty: true,
			});
		}
	}, [selectedEntries]);

	const allSelected = useMemo(() => {
		return PAYMENT_MODES.every((p) => paymentMode?.includes(p));
	}, [toggleAll, handleSelectAllClick, isSelected, paymentMode]);
	return (
		<div>
			{!disabled && (
				<TCheckbox.SubCheckbox
					checked={allSelected}
					onClick={handleSelectAllClick}
					label="Select All Payment Mode"
					className={styles.selectAll}
				/>
			)}
			<TCheckbox.Group>
				<TCheckbox
					onClick={makeClickHandler('CASH')}
					icon={<CashIcon width="35" height="35" />}
					label="Cash"
					checked={isSelected('CASH')}
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.cash_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CASH')}
					/>
				</TCheckbox>
				<TCheckbox
					onClick={makeClickHandler('CHECK')}
					checked={isSelected('CHECK')}
					icon={<CheckIcon width="35" height="35" />}
					label="Check"
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.check_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CHECK')}
					/>
				</TCheckbox>
				<TCheckbox
					icon={<CashCheckIcon width="35" height="35" />}
					label="Cash + Check"
					onClick={makeClickHandler('CASH_CHECK')}
					checked={isSelected('CASH_CHECK')}
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.cash_check_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CASH_CHECK')}
					/>
				</TCheckbox>
				<TCheckbox
					icon={<CreditCardIcon width="35" height="35" />}
					label="Credit Card"
					onClick={makeClickHandler('CREDIT')}
					checked={isSelected('CREDIT')}
					disabled={disabled}
				>
					<PosCheckbox
						control={control}
						name={name}
						disabled={!isSelected('CREDIT')}
						isCreditSelected={isSelected('CREDIT')}
						setValue={setValue}
						setError={setError}
						trigger={trigger}
					/>

					<ControlledSubCheckbox
						control={control}
						name={`${name}.credit_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CREDIT')}
					/>
				</TCheckbox>
			</TCheckbox.Group>
		</div>
	);
};

const PaymentMode = <T extends BusinessRulesFormData>({
	control,
	disabled,
	setValue,
	setError,
	trigger
}: Props<T>): JSX.Element => {
	const name = 'product_business_rule' as const;
	const {
		fieldState: { error },
	} = useController({ control, name: `${name}.payment_mode` as Path<T> });
	return (
		<Section title="Payment Mode">
			<SectionRow>
				<Grid column size={1} of={1}>
					<PaymentModeCheckboxGroup
						disabled={disabled}
						control={control}
						name={name}
						setValue={setValue}
						setError={setError}
						trigger={trigger}
					/>
				</Grid>
			</SectionRow>
			{error && (
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error?.message}
					</div>
				</div>
			)}
		</Section>
	);
};

export default PaymentMode;
