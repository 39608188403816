import { IconSettings, Button } from '@salesforce/design-system-react';
import cx from 'classnames';
import { IoMdEye, IoMdDownload, IoMdTrash } from 'react-icons/io';
import styles from './AttachmentCard.module.css';
import { FC, useState } from 'react';
import { MdDescription } from 'react-icons/md';
import FileThumbnail from 'components/FilePreview/FileThumbnail';
import { includes } from 'lodash';

type AttachmentCardProps = {
	url: string;
	fileName: string;
	type: string;
	onView?: () => void;
	onRemove?: () => void;
	// TODO: add parameter
	onDownload?: () => void;
	isDisplayOnly?: boolean;
};

const AttachmentCard: FC<AttachmentCardProps> = ({
	url,
	fileName,
	type,
	onView,
	onDownload,
}) => {
	const isFileTypeSupported = (type) =>
		includes(['png', 'jpg', 'jpeg', 'pdf'], type);

	return (
		<div className={cx(styles.card)}>
				<div
					className={cx(styles.overlay)}
				>
					<div className={cx(styles.overlayControls)}>
						<Button
							className={cx(styles.overlayButton)}
							variant="contained"
							size="medium"
							disableFocusRipple
							disableTouchRipple
							onClick={onView}
						>
							<IoMdEye />
						</Button>
						<Button
							className={cx(styles.overlayButton)}
							variant="contained"
							size="medium"
							color="error"
							disableFocusRipple
							disableTouchRipple
							onClick={onDownload}
						>
							<IoMdDownload />
						</Button>
					</div>
				</div>
			<div
				className={cx(
					isFileTypeSupported(type.replace('.', ''))
						? styles.attachmentImage
						: styles.attachmentNoPreview
				)}
			>
				{isFileTypeSupported(type.replace('.', '')) ? (
					<FileThumbnail filePath={url} fileType={type.replace('.', '')} />
				) : (
					<MdDescription style={{ fontSize: '50px', color: 'var(--gray)' }} />
				)}
			</div>
			<div className={cx(styles.attachmentName)}>{fileName}</div>
		</div>
	);
};

export default AttachmentCard;
