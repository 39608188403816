import Grid from 'components/Grid/Grid';
import cx from 'classnames';
import styles from './style.module.css';
import CsrReportTable from './CsrReportTable';
import { CsrColumn } from '../CsrColumnCheckbox';

export type SideHeaderProps = {
	merchantId: string;
	businessName: string;
	merchantName: string;
	generationDate: string;
};

export type CsrReportPreviewProps = {
	sideHeaders: SideHeaderProps;
	columns: CsrColumn[];
};

type Pair = { label: string; value: string };

const SideHeaders: React.FC<{ values: Pair[] }> = ({ values }) => {
	return (
		<Grid container>
			<Grid container vertical column size={1} of={3}>
				{values.map(({ label, value }) => (
					<Grid
						key={label}
						container
						align="start"
						className={styles.headerRow}
					>
						<Grid column size={1} of={2}>
							<div className={styles.bold}>{label}</div>
						</Grid>
						<Grid column size={1} of={2}>
							{value}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};

function useSideHeaders({
	merchantId,
	businessName,
	merchantName,
	generationDate,
}: SideHeaderProps) {
	return [
		{
			label: 'Merchant ID',
			value: merchantId,
		},
		{
			label: 'Business Name',
			value: businessName,
		},
		{
			label: 'Merchant Name',
			value: merchantName,
		},
		{
			label: 'Generation Date',
			value: generationDate,
		},
	];
}

const Headers: React.FC = () => {
	return (
		<div className={cx(styles.headerContainer, styles.bold)}>
			<div>CIS Bayad Center, Inc.</div>
			<div>Collection Summary Report</div>
		</div>
	);
};

export default function CsrReportPreview({
	sideHeaders,
	columns,
}: CsrReportPreviewProps) {
	const sideHeaderValues = useSideHeaders(sideHeaders);

	return (
		<div className={styles.container}>
			<Headers />
			<SideHeaders values={sideHeaderValues} />
			<CsrReportTable entries={columns} />
		</div>
	);
}
