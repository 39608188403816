import React from 'react';
import styles from './Breadcrumbs.module.css';
import { BreadcrumbsProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ tab, activePage }) => {
	const mockNav = {
		// to set correct name of selected product just push here from state
		parent: 'Products',
		children: [
			{
				name: tab.name,
			},
		],
	};
	return (
		<>
			<ul className={styles.Breadcrumbs}>
				<li>
					<a
						href="#"
						onClick={() => {
							activePage && activePage(true, {});
						}}
					>
						{mockNav.parent}
					</a>
				</li>
				{mockNav.children.map((child) => {
					return (
						<>
							<li className={styles.child}>
								<a href="#">{child.name}</a>
							</li>
						</>
					);
				})}
			</ul>
		</>
	);
};

export default Breadcrumbs;
