import React, { Component } from "react";
import styles from "./Header.module.css";
import Dropdown from "../Dropdown/Dropdown";
import avatar from "../../assets/images/logout/ic-avatar.png";
import { connect } from "react-redux";

class Header extends Component {
  render() {
    return (
      <>
        <div className={styles.header}>
          <img src={avatar} className={styles.avatar} alt="Logout Icon" />
          <ul className={styles.nametag}>
            {`${this.props.userInfo.firstname} ${this.props.userInfo.lastname}`}{" "}
            ({this.props.userInfo.username})
          </ul>
          <ul className={styles.ul}>
            <Dropdown />
          </ul>
        </div>
      </>
    );
  }
}

export default connect((state) => ({ userInfo: state.userInfo }))(Header);
