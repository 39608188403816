import { useRef } from 'react';

import styles from './Path.module.css';

type PathProp = {
	partner: string;
	module: string;
	hidePath: () => void | undefined;
};

const Path: React.FC<PathProp> = ({ partner, module, hidePath }) => {
	const partnerRef = useRef<HTMLDivElement>(null);

	const MAX_PARTNER_LENGTH = 13;
	const ADDITIONAL_WIDTH = 15;

	const BASE_WIDTH = 140;
	const BASE_LEFT = 108;
	const MIN_WIDTH = 80;
	const MAX_WIDTH = 234;

	const calculateWidthAndLeft = (length: number) => {
		let dynamicWidth = BASE_WIDTH;
		let dynamicLeft = 0;

		if (length === 16) {
			dynamicWidth += ADDITIONAL_WIDTH * (length - MAX_PARTNER_LENGTH);
			dynamicLeft = BASE_LEFT + (length - 12) * 9;
		} else if (length === 18) {
			dynamicWidth += ADDITIONAL_WIDTH * (length - 2 - MAX_PARTNER_LENGTH);
			dynamicLeft = BASE_LEFT + (length - 12) * 6;
		} else if (length > MAX_PARTNER_LENGTH) {
			dynamicWidth += ADDITIONAL_WIDTH * (length - MAX_PARTNER_LENGTH);
			dynamicLeft = BASE_LEFT + (length - 12) * 6;
		}

		const finalWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, dynamicWidth));
		const finalLeft = Math.max(BASE_LEFT, Math.min(MAX_WIDTH, dynamicLeft));

		return { width: finalWidth + 'px', left: finalLeft + 'px' };
	};

	const { width, left } = calculateWidthAndLeft(partner.length);

	if (partnerRef.current) {
		partnerRef.current.style.width = width;
		partnerRef.current.style.left = left;
	}

	return (
		<div className={styles.path}>
			<div className={styles.row}>
				<div className={styles.module} style={{ width }} onClick={hidePath} onKeyDown={()=>{return}}>
					{module}
				</div>
				<div ref={partnerRef} className={styles.partner}>
					{partner}
				</div>
			</div>
		</div>
	);
};

export default Path;
