import { common } from './common';
import * as yup from 'yup';
import { DateValue } from 'components/Inputs/Datepicker/SelectDatepicker';
import {
	transformCash,
	transformDateFormat,
	validateDateSchema,
} from 'utils/formSchemas/common';

export type product_contract = {
	upload_schedule?: string;
	upload_time?: string;
	deposit_schedule?: string;
	one_time_fee?: string;
	one_time_fee_amount?: number;
	collection_date?: string | DateValue;
	upload_penalty_type?: string;
	upload_penalty_rate?: number;
	deposit_penalty_type?: string;
	deposit_penalty_rate?: number;
	other_penalties?: {
		penalty_name?: string;
		penalty_type?: string;
		penalty_rate?: number;
	}[];
} & common;

export const product_contract_schema = yup.object().shape({
	upload_schedule: yup
		.string()
		.nullable()
		.label('Upload Schedule')
		.required('Select Upload Schedule.')
		.oneOf(['SAME', 'NEXT']),
	upload_time: yup
		.string()
		.nullable()
		.label('Time of Upload')
		.required('Input value for Time of Upload')
		.max(255),
	deposit_schedule: yup
		.string()
		.nullable()
		.label('Deposit Schedule')
		.required('Select Deposit Schedule.')
		.oneOf(['FIRST', 'SECOND']),
	one_time_fee: yup
		.string()
		.nullable()
		.label('One Time Fee')
		.required('Select an option for One Time Fee.')
		.oneOf(['1', '0']),
	one_time_fee_amount: yup
		.number()
		.nullable()
		.label('One Time Fee Amount')
		.typeError('Input One Time Fee Amount.')
		.min(1, 'Input Positive Values.')
		.when('one_time_fee', {
			is: '1',
			then: (s) => s.required('Input One Time Fee Amount.'),
		})
		.transform(transformCash),
	collection_date: yup
		.mixed()
		.nullable()
		.label('Date of Collection')
		.when('one_time_fee', {
			is: '1',
			then: (s) =>
				s
					.transform(transformDateFormat)
					.test('valid-date', 'Invalid date format.', validateDateSchema)
					.required(),
		}),
	upload_penalty_type: yup
		.string()
		.nullable()
		.label('Upload Penalty Type')
		.required('Select Upload Penalty Type.')
		.oneOf(['AMOUNT', 'PERCENTAGE']),
	upload_penalty_rate: yup
		.number()
		.nullable()
		.label('Upload Penalty Rate')
		.typeError('Input Upload Penalty Rate.')
		.when('upload_penalty_type', {
			is: 'AMOUNT',
			then: (s) =>
				s
					.required('Input Upload Penalty Rate.')
					.min(0.01, 'Value should be greater than 0.'),
		})
		.when('upload_penalty_type', {
			is: 'PERCENTAGE',
			then: (s) =>
				s
					.required('Input Upload Penalty Rate.')
					.min(0, 'Value should be from 0-100.')
					.max(100, 'Value should be from 0-100.'),
		})
		.transform(transformCash),
	deposit_penalty_type: yup
		.string()
		.nullable()
		.label('Deposit Penalty Type')
		.required('Select Deposit Penalty Type.')
		.oneOf(['AMOUNT', 'PERCENTAGE']),
	deposit_penalty_rate: yup
		.number()
		.nullable()
		.label('Deposit Penalty Rate')
		.typeError('Input Deposit Penalty Rate.')
		.when('deposit_penalty_type', {
			is: 'AMOUNT',
			then: (s) =>
				s
					.required('Input Deposit Penalty Rate.')
					.min(0.01, 'Value should be greater than 0.'),
		})
		.when('deposit_penalty_type', {
			is: 'PERCENTAGE',
			then: (s) =>
				s
					.required('Input Deposit Penalty Rate.')
					.min(0, 'Value should be from 0-100.')
					.max(100, 'Value should be from 0-100.'),
		})
		.transform(transformCash),
	other_penalties: yup
		.array()
		.nullable()
		.optional()
		.of(
			yup.object().shape({
				penalty_name: yup
					.string()
					.optional()
					.nullable()
					.label('Other Penalties')
					.max(255, 'You can only input a max of 255 characters.'),
				penalty_type: yup
					.string()
					.nullable()
					.label('Other Penalties Type')
					.optional()
					.when('penalty_name', {
						is: (s) => s != undefined && s != '',
						then: (s) =>
							s
								.oneOf(['AMOUNT', 'PERCENTAGE'])
								.required('Select Other Penalties Type.'),
					}),
				penalty_rate: yup
					.number()
					.optional()
					.nullable()
					.label('Other Penalties Rate')
					.typeError('Input Other Penalty Rate.')
					.when('penalty_type', {
						is: 'AMOUNT',
						then: (s) =>
							s
								.required('Input Other Penalty Rate.')
								.min(0.01, 'Value should be greater than 0.'),
					})
					.when('penalty_type', {
						is: 'PERCENTAGE',
						then: (s) =>
							s
								.required('Input Other Penalty Rate.')
								.min(0, 'Value should be from 0-100.')
								.max(100, 'Value should be from 0-100.'),
					})
					.transform(transformCash),
			})
		),
});
