import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@salesforce/design-system-react';
import Grid from 'components/Grid/Grid';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import LookupFieldWithExpanded from 'components/Inputs/LookupFieldWithExpanded';

import { ReactComponent as ClearIcon } from 'assets/icons/ic-clear.svg';
import { ReactComponent as ClearIconDisabled } from 'assets/icons/ic-clear-gray.svg';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import { formatParams } from 'utils/common';

import ChannelDetailsTable from './ChannelDetailsTable';
import { User, UserListResp, ChannelBranch, UserListFilters } from '../types';
import styles from '../ChannelDetails/ChannelDetails.module.css';

const ChannelUserList: React.FC<ChannelBranch> = ({ channelId }) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<User>();
	const [filter, setFilter] = useState<UserListFilters>({});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		control,
		reset,
		watch,
		formState: { isDirty },
	} = useForm<UserListFilters>({ mode: 'all' });

	const fetchUserList = async (params) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const result: UserListResp = await client.get(
				`/v2/bfa-admin-portal/channels/${channelId}/users`,
				{ params }
			);

			setData(result?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchUserList(params)
			);
		} finally {
			hideLoading();
		}
	};

	const handleFilterSubmit = async (params = {}) => {
		const { limit, sort, sortBy, search } = filter;
		const newFilter = formatParams({
			limit,
			sort,
			sortBy,
			search,
			...watch(),
			...params,
		});
		setFilter(newFilter);
		fetchUserList(newFilter);
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchUserList(newFilter);
	};

	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const onClear = () => {
		reset();
		const newFilter = { ...(filter.search && { search: filter.search }) };
		setFilter(newFilter);
		fetchUserList(newFilter);
	};

	const tableProps = {
		columns: [
			{
				Header: 'Username',
				id: 'username',
				sortable: true,
				width: '20%',
			},
			{
				Header: 'Last Name',
				id: 'lastName',
				sortable: true,
				width: '20%',
			},
			{
				Header: 'First Name',
				id: 'firstName',
				sortable: true,
				width: '20%',
			},
			{
				Header: 'User Role',
				id: 'role',
				sortable: true,
				width: '20%',
			},
			{
				Header: 'Branch',
				id: 'branch',
				sortable: true,
				width: '20%',
			},
		],
		scrollable: true,
		data: data?.data,
		className: styles.tableContainerTabContent,
		isNotEvenRowColor: true,
		...tablePaginationProps,
	};

	const ClearBtnIcon = isDirty ? ClearIcon : ClearIconDisabled;
	const buttonClass = isDirty ? styles.buttonEnabled : styles.buttonDisabled;
	const filterProps = {
		filterTitle: '',
		isFilterAvailable: true,
		filterAction: (
			<Button className={buttonClass} onClick={onClear} disabled={!isDirty}>
				<span style={{ paddingRight: '5px' }}>
					<ClearBtnIcon style={{ display: 'center' }} />
				</span>
				Clear All Filters
			</Button>
		),
		filterFields: (
			<Grid container align="spread" verticalAlign="start" gutters>
				<Grid column size={6} of={12}>
					<LookupFieldWithExpanded
						control={control}
						name="role"
						displayName="Role"
						apiRoute="/v2/bfa-admin-portal/utils/roles"
						isSearchable
						className={styles.lookupTextField}
						labelClassName={styles.lookupTextLabel}
						onChange={() => handleFilterSubmit()}
					/>
				</Grid>
				<Grid column size={6} of={12}>
					<LookupFieldWithExpanded
						control={control}
						name="branch"
						displayName="Branch"
						apiRoute="/v2/bfa-admin-portal/utils/branches"
						isSearchable
						className={styles.lookupTextField}
						labelClassName={styles.lookupTextLabel}
						onChange={() => handleFilterSubmit()}
					/>
				</Grid>
			</Grid>
		),
		padding: '0 0 1vw 0',
		onSearch: handleFilterSubmit,
	};

	useEffect(() => {
		if (hasUserPermission('list.channel.user')) {
			fetchUserList({});
		}
	}, []);

	return (
		<div>
			<ChannelDetailsTable tableProps={tableProps} filterProps={filterProps} />
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};
export default ChannelUserList;
