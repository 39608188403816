import cx from 'classnames';
import Checkbox from 'components/Checkbox/Checkbox';
import MultiCheckboxContainer from 'components/Checkbox/MultiCheckboxContainer';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './style.module.css';
import {
	CsrColumn,
	CsrColumnCheckboxProps,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { useCsrColumns } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/hooks';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { useFormContext } from 'react-hook-form';
import { ReportsSchemaType } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/ReportsTabSchema';

const CsrColumnCheckbox: React.FC<CsrColumnCheckboxProps> = ({
	onChange,
	value = [],
	pool = [],
	name,
	channelId = 0,
	disabled = false,
}) => {
	const selectedChannelId = useSelector<ReducerStateType, string>(
		(state) =>
			state.channels.selectedChannel && state.channels.selectedChannel.id
	);

	const { setValue } = useFormContext<ReportsSchemaType>();

	const columns = value;
	const {
		toggleAll,
		entries,
		toggle,
		toggleFiltered,
		selectedEntries,
		searchKeyword,
		setSearchKeyword,
		setSelectedEntries,
		resetEntries,
	} = useCsrColumns(pool, value);

	const handleSearchChange = (v: string) => {
		setSearchKeyword(v);
	};

	useEffect(() => {
		resetEntries();
		setSelectedEntries([]);
	}, [selectedChannelId]);

	const columnsRef = useRef(columns); // Create a mutable ref for columns

	columnsRef.current = columns;

	const previousValueRef = useRef<any>([]);

	useEffect(() => {
		/** DO NOT REMOVE: FOR DEBUGGING PURPOSES
		    console.log(
			'debug ',
			columnsRef.current,
			value.length,
			selectedEntries.length,
			selectedChannelId
		); 
		*/
		if (selectedChannelId !== null || channelId) {
			onToggleAction(previousValueRef, selectedEntries, value);
		}
		// for add channel, for view approval
		else if (
			selectedChannelId === null &&
			columnsRef.current.filter((column) => column.isRequired === 0).length > 0 // currently has delay due to update of forms. ToDo: Use columnsRef only once.
		) {
			if (selectedEntries.length !== value.length) {
				if (previousValueRef.current.length === 0) {
					onChange?.(columnsRef.current);
					return;
				} else {
					previousValueRef.current = selectedEntries;
					onChange?.(selectedEntries);
					return;
				}
			} else {
				previousValueRef.current = columnsRef.current;
				onChange?.(columnsRef.current);
				return;
			}
		} else {
			onChange?.(selectedEntries);
			return;
		}
	}, [columnsRef.current.length, selectedEntries, selectedChannelId]);

	const onToggleAction = (prev, entry, fromValue) => {
		if (value && value.length > 0) {
			// with selected column and selectedChannel
			if (
				entry.length === 13 &&
				entry.length < fromValue.length &&
				prev.current.length !== 0
			) {
				if (prev.current.length === fromValue.length) {
					prev.current = entry;
					onChange?.(entry);
					return;
				} else if (
					fromValue.length < prev.current.length &&
					prev.current.length === entry.length
				) {
					prev.current = fromValue;
					onChange?.(fromValue);
				} else {
					prev.current = fromValue;
					setSelectedEntries(fromValue);
					onChange?.(fromValue);
					return;
				}
			} else if (entry.length > prev.current.length) {
				prev.current = entry;
				setSelectedEntries(entry);
				onChange?.(entry);
			} else if (prev.current.length === fromValue.length) {
				prev.current = entry;
				onChange?.(entry);
			} else if (fromValue.length === entry.length && prev.current.length > 0) {
				prev.current = entry;
				setSelectedEntries(entry);
				onChange?.(fromValue);
			}
		} else {
			// with NO selected column and selectedChannel
			if (entry.length > 13) {
				prev.current = entry;
				onChange?.(entry);
			}
		}
	};

	const isSelected = useCallback(
		(v: CsrColumn) => {
			if (!columns) return v.isRequired;
			return columns.some((entry) => entry.id === v.id) || v.isRequired;
		},
		[columns]
	);

	const allSelected = useMemo(() => {
		return entries.length > 0 && entries.every(isSelected);
	}, [entries, isSelected]);

	return (
		<MultiCheckboxContainer
			selectAll={allSelected}
			onSelectAll={() => {
				toggleFiltered();
				setValue(name, entries, { shouldTouch: true });
			}}
			disabled={disabled}
			className={styles.mainContainer}
		>
			<div className={styles.searchContainer}>
				<InputWithIcon
					placeholder="Search Column Name"
					value={searchKeyword}
					onChange={handleSearchChange}
					disabled={disabled}
					icons={[
						{
							path: 'utility/search',
							isLeft: true,
						},
					]}
				/>
			</div>
			<div className={styles.container}>
				<div className={styles.column}>
					{entries
						.filter((entry) => entry.id <= 13)
						.sort((a, b) => a.id - b.id)
						.map((entry) => (
							<div
								key={entry.id}
								className={cx(styles.entry, {
									[styles.readonly]: entry.isRequired || disabled,
								})}
							>
								<Checkbox
									label={entry.value}
									checked={Boolean(entry.isRequired) || isSelected(entry)}
									onChange={() => toggle(entry)}
									disabled={Boolean(entry.isRequired) || disabled}
								/>
							</div>
						))}
				</div>
				<div className={styles.column}>
					{entries
						.filter((entry) => entry.id > 13)
						.sort((a, b) => a.id - b.id)
						.map((entry) => (
							<div
								key={entry.id}
								className={cx(styles.entry, {
									[styles.readonly]: entry.isRequired || disabled,
								})}
							>
								<Checkbox
									label={entry.value}
									checked={Boolean(entry.isRequired) || isSelected(entry)}
									onChange={() => {
										toggle(entry);
										setValue(name, entries, { shouldTouch: true });
									}}
									disabled={Boolean(entry.isRequired) || disabled}
								/>
							</div>
						))}
				</div>
			</div>
		</MultiCheckboxContainer>
	);
};

export default CsrColumnCheckbox;
