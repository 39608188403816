import { useMemo } from 'react';
import { useHasUserPermission } from 'utils/permissions';

export const useChannelPermission = () => {
	const hasChannelUserPermission = useHasUserPermission('channels');

	const {
		hasAddChannelPermission,
		hasSecondaryAddChannelPermission,
		hasListChannelPermission,
		hasViewPrimaryInfoChannelPermission,
		hasViewContractDetailsChannelPermission,
		hasViewAccreditationsChannelPermission,
		hasViewReportsChannelPermission,
		hasDownloadAccreditationsChannelPermission,
		hasEditStatusChannelPermission,
		hasEditPrimaryInfoChannelPermission,
		hasEditContractDetailsChannelPermission,
		hasEditReportsChannelPermission,
		hasEditDraftChannelPermission,
		hasSaveDraftChannelPermission,
		hasListDraftChannelPermission,
		hasViewDraftChannelPermission,
		hasListApprovalsChannelPermission,
		hasFeesApprovalListChannelPermission,
		hasFeesApprovalDetailsChannelPermission,
		hasApprovalApproveChannelPermission,
		hasFeesApprovalApproveChannelPermission,
		hasApprovalRejectChannelPermission,
		hasFeesApprovalRejectChannelPermission,
		hasResubmitChannelPermission,
		hasViewProductChannelPermission,
		hasViewProductConfigChannelPermission,
		hasViewProductEnableChannelPermission,
		hasEnableProductChannelPermission,
		hasDisableProductChannelPermission,
		hasEnableChannelPermission,
		hasDisableChannelPermission,
		hasViewServiceFeeChannelPermission,
		hasCreateServiceFeeChannelPermission,
		hasListAuditTrailChannelPermission,
		hasViewAuditTrailChannelPermission,
		hasViewIntegrationChannelPermission,
		hasListClientCredentialsChannelPermission,
		hasGenerateClientCredentialsChannelPermission,
		hasSendClientCredentialsChannelPermission,
		hasListPublicCertificateChannelPermission,
		hasDownloadLinkCertificateChannelPermission,
		hasUploadDocumentChannelPermission,
		hasUploadLogoChannelPermission,
		hasListProductTypesChannelPermission,
		hasUtilsListChannelsChannelPermission,
		hasUtilsListBranchesChannelPermission,
		hasUtilsListTerminalsChannelPermission,
		hasUtilsListMeralcoCentersChannelPermission,
		hasViewProductTypeAccessChannelPermission,
		hasGeneratePublicCertificatChannelPermission,
	} = useMemo(() => {
		return {
			hasAddChannelPermission: hasChannelUserPermission('add'),
			hasSecondaryAddChannelPermission:
				hasChannelUserPermission('secondary.add'),
			hasListChannelPermission: hasChannelUserPermission('list'),
			hasViewPrimaryInfoChannelPermission:
				hasChannelUserPermission('view.primary-info'),
			hasViewContractDetailsChannelPermission: hasChannelUserPermission(
				'view.contract-details'
			),
			hasViewAccreditationsChannelPermission: hasChannelUserPermission(
				'view.accreditations'
			),
			hasViewReportsChannelPermission: hasChannelUserPermission('view.reports'),
			hasDownloadAccreditationsChannelPermission: hasChannelUserPermission(
				'download.accreditations'
			),
			hasEditStatusChannelPermission: hasChannelUserPermission('edit.status'),
			hasEditPrimaryInfoChannelPermission:
				hasChannelUserPermission('edit.primary-info'),
			hasEditContractDetailsChannelPermission: hasChannelUserPermission(
				'edit.contract-details'
			),
			hasEditReportsChannelPermission: hasChannelUserPermission('edit.reports'),
			hasEditDraftChannelPermission: hasChannelUserPermission('edit.draft'),
			hasSaveDraftChannelPermission: hasChannelUserPermission('save.draft'),
			hasListDraftChannelPermission: hasChannelUserPermission('list.draft'),
			hasViewDraftChannelPermission: hasChannelUserPermission('view.draft'),
			hasListApprovalsChannelPermission:
				hasChannelUserPermission('list.approvals'),
			hasFeesApprovalListChannelPermission:
				hasChannelUserPermission('fees.approval.list'),
			hasFeesApprovalDetailsChannelPermission: hasChannelUserPermission(
				'fees.approval.details'
			),
			hasApprovalApproveChannelPermission:
				hasChannelUserPermission('approval.approve'),
			hasFeesApprovalApproveChannelPermission: hasChannelUserPermission(
				'fees.approval.approve'
			),
			hasApprovalRejectChannelPermission:
				hasChannelUserPermission('approval.reject'),
			hasFeesApprovalRejectChannelPermission: hasChannelUserPermission(
				'fees.approval.reject'
			),
			hasResubmitChannelPermission: hasChannelUserPermission('resubmit'),
			hasViewProductChannelPermission: hasChannelUserPermission('view.product'),
			hasViewProductConfigChannelPermission: hasChannelUserPermission(
				'view.product.config'
			),
			hasViewProductEnableChannelPermission: hasChannelUserPermission(
				'view.product-enable'
			),
			hasEnableProductChannelPermission:
				hasChannelUserPermission('enable.product'),
			hasDisableProductChannelPermission:
				hasChannelUserPermission('disable.product'),
			hasEnableChannelPermission: hasChannelUserPermission('enable'),
			hasDisableChannelPermission: hasChannelUserPermission('disable'),
			hasViewServiceFeeChannelPermission:
				hasChannelUserPermission('view.service-fee'),
			hasCreateServiceFeeChannelPermission:
				hasChannelUserPermission('create.service-fee'),
			hasListAuditTrailChannelPermission:
				hasChannelUserPermission('list.audit-trail'),
			hasViewAuditTrailChannelPermission:
				hasChannelUserPermission('view.audit-trail'),
			hasViewIntegrationChannelPermission:
				hasChannelUserPermission('view.integration'),
			hasListClientCredentialsChannelPermission: hasChannelUserPermission(
				'list.client-credentials'
			),
			hasGenerateClientCredentialsChannelPermission: hasChannelUserPermission(
				'generate.client-credentials'
			),
			hasSendClientCredentialsChannelPermission: hasChannelUserPermission(
				'send.client-credentials'
			),
			hasListPublicCertificateChannelPermission: hasChannelUserPermission(
				'list.public.certificate'
			),
			hasDownloadLinkCertificateChannelPermission: hasChannelUserPermission(
				'download.link.certificate'
			),
			hasUploadDocumentChannelPermission:
				hasChannelUserPermission('upload.document'),
			hasUploadLogoChannelPermission: hasChannelUserPermission('upload.logo'),
			hasListProductTypesChannelPermission:
				hasChannelUserPermission('list.product-types'),
			hasUtilsListChannelsChannelPermission: hasChannelUserPermission(
				'utils.list-channels'
			),
			hasUtilsListBranchesChannelPermission: hasChannelUserPermission(
				'utils.list-branches'
			),
			hasUtilsListTerminalsChannelPermission: hasChannelUserPermission(
				'utils.list-terminals'
			),
			hasUtilsListMeralcoCentersChannelPermission: hasChannelUserPermission(
				'utils.list-meralco-centers'
			),
			hasViewProductTypeAccessChannelPermission: hasChannelUserPermission(
				'view.product-type-access'
			),
			hasGeneratePublicCertificatChannelPermission: hasChannelUserPermission(
				'generate.public.certificate'
			),
		};
	}, [hasChannelUserPermission]);

	return {
		hasAddChannelPermission,
		hasSecondaryAddChannelPermission,
		hasListChannelPermission,
		hasViewPrimaryInfoChannelPermission,
		hasViewContractDetailsChannelPermission,
		hasViewAccreditationsChannelPermission,
		hasViewReportsChannelPermission,
		hasDownloadAccreditationsChannelPermission,
		hasEditStatusChannelPermission,
		hasEditPrimaryInfoChannelPermission,
		hasEditContractDetailsChannelPermission,
		hasEditReportsChannelPermission,
		hasEditDraftChannelPermission,
		hasSaveDraftChannelPermission,
		hasListDraftChannelPermission,
		hasViewDraftChannelPermission,
		hasListApprovalsChannelPermission,
		hasFeesApprovalListChannelPermission,
		hasFeesApprovalDetailsChannelPermission,
		hasApprovalApproveChannelPermission,
		hasFeesApprovalApproveChannelPermission,
		hasApprovalRejectChannelPermission,
		hasFeesApprovalRejectChannelPermission,
		hasResubmitChannelPermission,
		hasViewProductChannelPermission,
		hasViewProductConfigChannelPermission,
		hasViewProductEnableChannelPermission,
		hasEnableProductChannelPermission,
		hasDisableProductChannelPermission,
		hasEnableChannelPermission,
		hasDisableChannelPermission,
		hasViewServiceFeeChannelPermission,
		hasCreateServiceFeeChannelPermission,
		hasListAuditTrailChannelPermission,
		hasViewAuditTrailChannelPermission,
		hasViewIntegrationChannelPermission,
		hasListClientCredentialsChannelPermission,
		hasGenerateClientCredentialsChannelPermission,
		hasSendClientCredentialsChannelPermission,
		hasListPublicCertificateChannelPermission,
		hasDownloadLinkCertificateChannelPermission,
		hasUploadDocumentChannelPermission,
		hasUploadLogoChannelPermission,
		hasListProductTypesChannelPermission,
		hasUtilsListChannelsChannelPermission,
		hasUtilsListBranchesChannelPermission,
		hasUtilsListTerminalsChannelPermission,
		hasUtilsListMeralcoCentersChannelPermission,
		hasViewProductTypeAccessChannelPermission,
		hasGeneratePublicCertificatChannelPermission,
	};
};

export const useBranchPermission = () => {
	const hasBranchUserPermission = useHasUserPermission('branches');

	const {
		hasEnableBranchPermission,
		hasDisableBranchPermission,
		hasAddBranchPermission,
		hasSecondaryAddBranchPermission,
		hasEditBranchPermission,
		hasEditStatusBranchPermission,
		hasSaveDraftBranchPermission,
		hasEditDraftBranchPermission,
		hasViewBranchPermission,
		hasListBranchPermission,
		hasListAuditTrailBranchPermission,
		hasViewAuditTrailBranchPermission,
	} = useMemo(() => {
		return {
			hasEnableBranchPermission: hasBranchUserPermission('enable'),
			hasDisableBranchPermission: hasBranchUserPermission('disable'),
			hasAddBranchPermission: hasBranchUserPermission('add'),
			hasSecondaryAddBranchPermission: hasBranchUserPermission('secondary.add'),
			hasEditBranchPermission: hasBranchUserPermission('edit'),
			hasEditStatusBranchPermission: hasBranchUserPermission('edit.status'),
			hasSaveDraftBranchPermission: hasBranchUserPermission('save.draft'),
			hasEditDraftBranchPermission: hasBranchUserPermission('edit.draft'),
			hasViewBranchPermission: hasBranchUserPermission('view'),
			hasListBranchPermission: hasBranchUserPermission('list'),
			hasListAuditTrailBranchPermission:
				hasBranchUserPermission('list.audit-trail'),
			hasViewAuditTrailBranchPermission:
				hasBranchUserPermission('view.audit-trail'),
		};
	}, [hasBranchUserPermission]);

	return {
		hasEnableBranchPermission,
		hasDisableBranchPermission,
		hasAddBranchPermission,
		hasSecondaryAddBranchPermission,
		hasEditBranchPermission,
		hasEditStatusBranchPermission,
		hasSaveDraftBranchPermission,
		hasEditDraftBranchPermission,
		hasViewBranchPermission,
		hasListBranchPermission,
		hasListAuditTrailBranchPermission,
		hasViewAuditTrailBranchPermission,
	};
};

export const useTerminalPermission = () => {
	const hasTerminalUserPermission = useHasUserPermission('terminals');

	const {
		hasEnableTerminalPermission,
		hasDisableTerminalPermission,
		hasAddTerminalPermission,
		hasSecondaryAddTerminalPermission,
		hasEditTerminalPermission,
		hasEditStatusTerminalPermission,
		hasSaveDraftTerminalPermission,
		hasEditDraftTerminalPermission,
		hasViewTerminalPermission,
		hasListTerminalPermission,
		hasListAuditTrailTerminalPermission,
		hasViewAuditTrailTerminalPermission,
	} = useMemo(() => {
		return {
			hasEnableTerminalPermission: hasTerminalUserPermission('enable'),
			hasDisableTerminalPermission: hasTerminalUserPermission('disable'),
			hasAddTerminalPermission: hasTerminalUserPermission('add'),
			hasSecondaryAddTerminalPermission:
				hasTerminalUserPermission('secondary.add'),
			hasEditTerminalPermission: hasTerminalUserPermission('edit'),
			hasEditStatusTerminalPermission: hasTerminalUserPermission('edit.status'),
			hasSaveDraftTerminalPermission: hasTerminalUserPermission('save.draft'),
			hasEditDraftTerminalPermission: hasTerminalUserPermission('edit.draft'),
			hasViewTerminalPermission: hasTerminalUserPermission('view'),
			hasListTerminalPermission: hasTerminalUserPermission('list'),
			hasListAuditTrailTerminalPermission:
				hasTerminalUserPermission('list.audit-trail'),
			hasViewAuditTrailTerminalPermission:
				hasTerminalUserPermission('view.audit-trail'),
		};
	}, [hasTerminalUserPermission]);

	return {
		hasEnableTerminalPermission,
		hasDisableTerminalPermission,
		hasAddTerminalPermission,
		hasSecondaryAddTerminalPermission,
		hasEditTerminalPermission,
		hasEditStatusTerminalPermission,
		hasSaveDraftTerminalPermission,
		hasEditDraftTerminalPermission,
		hasViewTerminalPermission,
		hasListTerminalPermission,
		hasListAuditTrailTerminalPermission,
		hasViewAuditTrailTerminalPermission,
	};
};
