import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import styles from './PrimaryCard.module.css';
import cx from 'classnames';
import { useMemo } from 'react';
import { startCase } from 'lodash';

type Props = {
	name: string;
	logo: string;
	status: string;
	isSelected?: boolean;
	onClick: any;
};

const PrimaryCardPartner: React.FC<Props> = ({
	name,
	logo,
	status,
	isSelected = false,
	onClick,
}) => {
	const iconClassNames = useMemo(() => {
		const s = status.toLocaleUpperCase();
		return cx({
			[styles.statusIndactorActive]: s === 'ACTIVE',
			[styles.statusIndactorEngaged]: [
				'ENGAGED',
				'APPROVED',
				'UNDER_DEVELOPMENT',
				'FOR_ACTIVATION',
			].includes(status.toLocaleUpperCase()),
			[styles.statusIndactorSuspended]: ['SUSPENDED', 'DEACTIVATED'].includes(
				status.toLocaleUpperCase()
			),
			[styles.statusIndactorDeactivated]: ['TERMINATED'].includes(
				status.toLocaleUpperCase()
			),
		});
	}, [status]);
	return (
		<div
			className={cx(
				'slds-grid slds-wrap slds-grid_vertical-align-center',
				styles.cardContainer,
				{
					[styles.isSelected]: isSelected,
					[styles.isStatusActive]: status.toLocaleUpperCase() === 'ACTIVE',
					[styles.isStatusEngaged]: [
						'ENGAGED',
						'APPROVED',
						'UNDER_DEVELOPMENT',
					].includes(status.toLocaleUpperCase()),
					[styles.isStatusSuspended]: ['SUSPENDED', 'DEACTIVATED'].includes(
						status.toLocaleUpperCase()
					),
					[styles.isStatusDeactivated]: ['TERMINATED'].includes(
						status.toLocaleUpperCase()
					),
				}
			)}
			onClick={onClick}
			onKeyDown={()=>{return}}
		>
			<img className="slds-col slds-large-size_2-of-12" src={logo} />
			<div className={cx('slds-col slds-large-size_9-of-12')}>
				<div className="slds-grid slds-grid_align-spread">
					<span>{name}</span>
					<div>
						<Tooltip
							id="tooltip"
							align="top right"
							hoverCloseDelay={100}
							content={startCase(status.toLocaleLowerCase())}
							triggerClassName={styles.toolTip}
						>
							<span>
								<Icon
									category="utility"
									name="record"
									size="xx-small"
									className={iconClassNames}
								/>
							</span>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrimaryCardPartner;
