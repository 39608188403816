import cx from 'classnames';
import styles from './styles.module.css';
import { StatusOption } from './types';

export const statusOption: StatusOption[] = [
    {
        label: 'Active',
        value: 'ACTIVE',
        className: cx(styles.active),
    },
    {
        label: 'Terminated',
        value: 'TERMINATED',
        className: cx(styles.terminated),
    },
    {
        label: 'Suspended',
        value: 'SUSPENDED',
        className: cx(styles.suspended),
    },
    {
        label: 'Deactivated',
        value: 'DEACTIVATED',
        className: cx(styles.deactivated),
    },
    {
        label: 'On-going Integration',
        value: 'ONGOING_INTEGRATION',
        className: cx(styles.draft),
    },
    {
        label: 'Ready for Activation',
        value: 'READY_FOR_ACTIVATION',
        className: cx(styles.forActivation),
    },
    {
        label: 'Approved for Integration',
        value: 'APPROVED_FOR_INTEGRATION',
        className: cx(styles.approved),
        isHidden: true
    },
    {
        label: 'For Review',
        value: 'FOR_REVIEW',
        className: cx(styles.draft),
        isHidden: true
    },
    {
        label: 'Rejected',
        value: 'REJECTED',
        className: cx(styles.rejected),
        isHidden: true
    }

];

export const statusOptionTwo: StatusOption[] = [
    {
        label: 'Active',
        value: 'ACTIVE',
        className: cx(styles.active),
    },
    {
        label: 'Terminated',
        value: 'TERMINATED',
        className: cx(styles.terminated),
    },
    {
        label: 'Suspended',
        value: 'SUSPENDED',
        className: cx(styles.suspended),
    },
    {
        label: 'Deactivated',
        value: 'DEACTIVATED',
        className: cx(styles.deactivated),
    },
    {
        label: 'On-going Integration',
        value: 'ONGOING_INTEGRATION',
        className: cx(styles.draft),
        isHidden: true
    },
    {
        label: 'Ready for Activation',
        value: 'READY_FOR_ACTIVATION',
        className: cx(styles.forActivation),
        isHidden: true
    },
    {
        label: 'Approved for Integration',
        value: 'APPROVED_FOR_INTEGRATION',
        className: cx(styles.approved),
        isHidden: true
    },
    {
        label: 'For Review',
        value: 'FOR_REVIEW',
        className: cx(styles.draft),
        isHidden: true
    },
    {
        label: 'Rejected',
        value: 'REJECTED',
        className: cx(styles.rejected),
        isHidden: true
    }

];

export const LOADING_MESSAGE = "Please wait while the selected request's details is being loaded"