import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { debounce as _debounce } from 'lodash';

import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SelectField from 'components/Inputs/SelectField/SelectField';
import LookupFieldWithExpanded from 'components/Inputs/LookupFieldWithExpanded';

import { Api3Status, BfaAdminSchemeType, BfaStatus } from 'utils/lookup';

import { ProductFilterFormData, PartnerHeaderProps } from '../types';
import styles from './PartnerHeader.module.css';

const PartnerHeader: React.FC<PartnerHeaderProps> = ({
	onSubmit,
	clickedCardTitle,
	hidePartnerList,
	productId,
}) => {
	const {
		getValues,
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<ProductFilterFormData>({ mode: 'all' });

	const handleFilterSubmit = (values, isSearch = false) => {
		onSubmit(values || {}, isSearch);
	};

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues() || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, find: value });
			}
		}, 500),
		[isDirty, isValid]
	);

	const handleReset = () => {
		reset();
		handleFilterSubmit({});
	};

	return (
		<>
			<Filter
				title="Partner List"
				padding={'1.2vmax 0.7vmax 1.2vmax 0.7vmax'}
				showPath={clickedCardTitle}
				hidePath={hidePartnerList}
				className={styles.filters}
			>
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={12}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={5} of={12}>
								<InputWithIcon
									onChange={handleSearch}
									errors={errors}
									placeholder="Search"
									name="find"
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
										},
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid
						container
						align="spread"
						verticalAlign="start"
						gutters="xx-small"
					>
						<Grid column size={2} of={12}>
							<LookupFieldWithExpanded
								name="partner"
								control={control}
								displayName="Partner Name"
								apiRoute="/v2/bfa-admin-portal/utils/partner/name-list"
								isSearchable
								className={styles.lookupTextField}
								labelClassName={styles.lookupTextLabel}
								additionalParams={{
									product_type_id: productId,
								}}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<LookupFieldWithExpanded
								name="category"
								control={control}
								displayName="Category"
								apiRoute="/v2/bfa-admin-portal/utils/partner/category-list"
								isSearchable
								className={styles.lookupTextField}
								labelClassName={styles.lookupTextLabel}
								additionalParams={{
									product_type_id: productId,
								}}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<SelectField
								name="schemeType"
								label="SF Scheme Type"
								control={control}
								options={BfaAdminSchemeType}
								labelClassName={styles.selectLabel}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<SelectField
								name="api3status"
								label="API 3.0 Status"
								control={control}
								options={Api3Status}
								labelClassName={styles.selectLabel}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<SelectField
								name="bfaStatus"
								label="BFA Status"
								control={control}
								options={BfaStatus}
								labelClassName={styles.selectLabel}
							/>
						</Grid>
						<Grid column size={2} of={12} className={styles.button}>
							<OutlineButton
								fullWidth
								onClick={handleReset}
								disabled={!isDirty}
								className={styles.buttons}
							>
								Clear All Filters
							</OutlineButton>
						</Grid>
						<Grid column size={1} of={12} className={styles.button}>
							<PrimaryButton
								disabled={!(isValid && isDirty)}
								onClick={handleSubmit((filter = {}) =>
									handleFilterSubmit(filter, true)
								)}
								fullWidth
								className={styles.buttons}
							>
								Filter
							</PrimaryButton>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
		</>
	);
};

export default PartnerHeader;
