import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFormErrorStatus } from 'redux/modules/channelList';
import { FormType } from './types';

export const useFormErrorHandling = (form: FormType, formId: string): void => {
	const dispatch = useDispatch();
	const {
		formState: { errors },
	} = form;

	useEffect(() => {
		const hasErrors = Object.keys(errors).length > 0;
		dispatch(setFormErrorStatus({ formId, hasError: hasErrors }));
	}, [errors, dispatch, formId]);
};
