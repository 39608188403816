import yup from '../common';

const channelFilterSchema = yup.object().shape({
	dateFrom: yup
		.date()
		.nullable()
		.max(yup.ref('dateTo'), `You’ve selected invalid date`)
		.max(new Date(), `You’ve selected invalid date`),
	dateTo: yup
		.date()
		.nullable()
		.min(yup.ref('dateFrom'), `You’ve selected invalid date`)
		.when('dateFrom', (dateFrom, schema) =>
			dateFrom ? schema.required() : schema.nullable()
		),
	channelName: yup.string().nullable(),
	channelCode: yup.string().nullable(),
	settlementSetup: yup.string().nullable(),
	api3Status: yup.string().nullable(),
	bfaStatus: yup.string().nullable(),
	search: yup.string().nullable().min(3, 'Minimum of 3 characters'),
});

export default channelFilterSchema;
