import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
	data: {
		details: null,
		logs: {
			api: [],
			audit: [],
		},
	},
	selectedId: 0,
	showModal: false,
	isLoading: false,
};

const viewtransaction = createSlice({
	name: 'viewtransaction',
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.selectedId = payload.id;
			state.isLoading = true;
		},
		load: (state, payload) => {
			const otherInfo_defaultValue = {
				CheckDetails: {
					BankCode: undefined,
					CheckNo: undefined,
				},
			};

			const other_info =
				Object.keys(payload.result.other_info).length !== 0
					? payload.result.other_info
					: otherInfo_defaultValue;

			state.data.details = {
				...payload.result.data,
				other_info,
				created_at: moment(new Date(payload.result.data.created_at)).format(
					'MM/DD/YYYY h:mm:ss a'
				),
			};
			state.data.logs = {
				api: payload.result.logs.api.map((_lA) => ({
					..._lA,
					created_at: moment(new Date(_lA.created_at)).format(
						'MM/DD/YYYY h:mm:ss a'
					),
					updated_at: moment(new Date(_lA.updated_at)).format(
						'MM/DD/YYYY h:mm:ss a'
					),
				})),
				audit: payload.result.logs.audit.map((_lA) => ({
					..._lA,
					datetime: moment(new Date(_lA.datetime)).format(
						'MM/DD/YYYY h:mm:ss a'
					),
				})),
			};
			state.showModal = true;
			state.isLoading = false;
		},
		error: (state) => {
			console.log('Error');
			state.isLoading = false;
		},
		closeModal: (state) => {
			state.showModal = false;
			state.isLoading = false;
		},
	},
});

const { prep, load, error, closeModal } = viewtransaction.actions;

const getViewTransaction = (client, state) => {
	return client.get(`/v1/transactions/${state.viewtransaction.selectedId}`);
};

function viewActionCreator(id) {
	return {
		types: [prep.type, load.type, error.type],
		promise: getViewTransaction,
		id,
	};
}

export default viewtransaction.reducer;

export { viewActionCreator, closeModal };
