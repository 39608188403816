import { File, Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import { ReactComponent as Icon } from 'assets/icons/ic-document.svg';
import styles from 'components/Modal/SuccessFileDownloadModal.module.css';
import { ReactNode } from 'react';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';

type SuccessFileDownloadModalSectionProps = {
	children: ReactNode;
};

export const SuccessFileDownloadModalBody: React.FC<SuccessFileDownloadModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};
export const SuccessFileDownloadModalActions: React.FC<SuccessFileDownloadModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};

const SuccessIcon: React.FC = () => <Icon className={styles.successIcon} />;
const FileIcon: React.FC = () => <Icon className={styles.fileIcon} />;

type SuccessFileDownloadModalProps = {
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
	larger?: boolean;
};

export const SuccessFileDownloadText: React.FC = ({ children }) => (
	<div className={styles.successModalText}>{children}</div>
);
export const SuccessSubText: React.FC = ({ children }) => (
	<div className={styles.successModalSubtext}>{children}</div>
);

const SuccessFileDownloadModal: React.FC<SuccessFileDownloadModalProps> = ({
	open,
	onClose,
	Icon = FileIcon,
	children,
	larger = false,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={styles.successModalHeader}
			size="small"
			containerClassName={styles.successModalContainer}
			contentClassName={cx(styles.successModal, larger ? styles.larger : '')}
		>
			<Grid vertical verticalAlign="center" className={styles.successModalContent}>
				{Icon && (
					<Grid column className={styles.successModalIconContainer}>
						<Icon/>
					</Grid>
				)}
				<Grid column className={styles.successModalBody}>
					<RenderByType type={SuccessFileDownloadModalBody}>{children}</RenderByType>
				</Grid>
				<Grid column className={styles.successModalActions}>
					<RenderByType type={SuccessFileDownloadModalActions}>{children}</RenderByType>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default SuccessFileDownloadModal;
