import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
	category_id: 0,
};

const reportMaintenance = createSlice({
	name: 'report-maintenance',
	initialState,
	reducers: {
		setCategory: (state, { payload }) => {
			state.category_id = payload;
		},
	},
});

export default reportMaintenance.reducer;

export const { setCategory } = reportMaintenance.actions;
