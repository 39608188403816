import {
	BRANCH_NAME,
	CHANNEL_ACCOUNT_NAME,
	DATE_CREATED,
	DATE_UPDATED,
	SEARCH,
	TPAID,
} from 'containers/ChannelManagement/Drafts/constants';
import _ from 'lodash';
import * as yup from 'yup';

export const draftsFilterSchema = yup.object({
	[DATE_CREATED]: yup.string().label(DATE_CREATED),
	[DATE_UPDATED]: yup.string().label(_.startCase(DATE_UPDATED)),
	[CHANNEL_ACCOUNT_NAME]: yup.string().label(_.startCase(CHANNEL_ACCOUNT_NAME)),
	[BRANCH_NAME]: yup.string().label(_.startCase(BRANCH_NAME)),
	[TPAID]: yup.string().label(TPAID.toUpperCase()),
	[SEARCH]: yup.string().label(_.startCase(SEARCH)),
});
