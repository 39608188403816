/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from 'helpers/ApiClient';
import qs from 'qs';

export const categories = {
	1: 'product',
	2: 'channel',
	3: 'reconciliation',
	4: 'finance',
	5: 'accounting',
	6: 'rpa',
	7: 'other',
};

export const reportFiles = async (params: any, { rejectWithValue }) => {
	try {
		const { categoryId, ...queryParams } = params;
		const response = await client.get(
			`/v2/reports/${categories[categoryId]}/report-files`,
			{
				params: queryParams,
				paramsSerializer: function (params) {
					return qs.stringify(params, { indices: false });
				},
			}
		);

		return response;
	} catch (error) {
		return rejectWithValue(error.response);
	}
};

export const reportName = async (params: any, { rejectWithValue }) => {
	try {
		const { report_category_id } = params;
		const response = await client.get(
			`/v2/report/${categories[report_category_id]}/report-details`,
			{
				params: {
					report_category_id,
				},
			}
		);
		return response;
	} catch (error) {
		return rejectWithValue(error.response);
	}
};

export const reportViewReport = async (params, { rejectWithValue }) => {
	try {
		const { report_file_id, categoryId } = params;
		const endpoint = `/v2/reports/${categories[categoryId]}/report-files/${report_file_id}/preview`;
		const response = await client.get(endpoint);

		return response;
	} catch (error) {
		return rejectWithValue(error.response);
	}
};

export const reportAuditTrail = async (params, { rejectWithValue }) => {
	try {
		const { report_file_id, categoryId, ...pagination } = params;
		const endpoint = `/v2/reports/${categories[categoryId]}/report-files/${report_file_id}/audit-trails`;
		const response = await client.get(endpoint, {
			params: pagination,
		});

		return response;
	} catch (error) {
		return rejectWithValue(error.response);
	}
};

export const reportDownloadLink = async (params) => {
	const { id, categoryId } = params;
	const response = await client.get(
		`/v2/reports/${categories[categoryId]}/report-files/${id}/download`
	);

	return response.data.data;
};
