import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Switch } from 'components/Switch';

import { ReactComponent as EditIcon } from 'assets/icons/ic-edit-mui.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete-mui.svg';

import { ScheduleItemProps } from '../types';
import styles from './ScheduleModal.module.css';

const ScheduleItem: React.FC<ScheduleItemProps> = ({ key, schedule }) => {
	const scheduleStyle = {
		recurring: {
			backgroundColor: '#F0EFC3',
			border: '1px solid #CCCF19',
		},
		specific: {
			backgroundColor: '#D5EADA',
			border: '1px solid #4BB482',
		},
	};
	const days = {
		mon: 'Monday',
		tue: 'Tuesday',
		wed: 'Wednesday',
		thu: 'Thursday',
		fri: 'Friday',
		sat: 'Saturday',
		sun: 'Sunday',
	};
	const dateSchedule =
		schedule.isRecurring && schedule.recurringDays
			? `Every ${schedule.recurringDays
					.split('|')
					.map((e) => days[e.toLowerCase()])
					.join(', ')}`
			: `${moment(schedule.startDate).format('MMMM D, YYYY')} - ${moment(
					schedule.endDate
			  ).format('MMMM D, YYYY')}`;

	const timeSchedule =
		moment(schedule.startTime, 'hh:mm').format('LT') +
		' - ' +
		moment(schedule.endTime, 'hh:mm').format('LT');

	return (
		<div
			className={styles.scheduleItem}
			style={
				schedule.isRecurring ? scheduleStyle.recurring : scheduleStyle.specific
			}
			key={key}
		>
			<div className={styles.scheduleLabel}>
				<div className={styles.scheduleLabelTitle}>{dateSchedule}</div>
				<div className={styles.scheduleLabelSubTitle}>
					Operating Hours: <b className={styles.bold}>{timeSchedule}</b>
				</div>
			</div>
			<div className={cx(styles.scheduleActions, styles.disabled)}>
				<div className={styles.toggle}>
					<Switch checked name="status" onChange={() => {}} />
				</div>
				<EditIcon />
				<DeleteIcon />
			</div>
		</div>
	);
};
export default ScheduleItem;
