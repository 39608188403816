import ReportTabContent from '../ReportTabContent';

export const TAB_ITEMS = {
    tabs: [
        {
            id: 1,
            title: 'Product',
            component: <ReportTabContent
                categoryId={1}
            />,
        },
        {
            id: 2,
            title: 'Channel',
            component: <ReportTabContent
                categoryId={2}
            />,
        },
        {
            id: 3,
            title: 'Reconciliation',
            component: <ReportTabContent
                categoryId={3}
            />,
        },
        {
            id: 4,
            title: 'Finance',
            component: <ReportTabContent
                categoryId={4}
            />,
        },
        {
            id: 5,
            title: 'Accounting',
            component: <ReportTabContent
                categoryId={5}
            />,
        },
        {
            id: 6,
            title: 'RPA',
            component: <ReportTabContent
                categoryId={6}
            />,
        },
        {
            id: 7,
            title: 'Other Reports',
            component: <ReportTabContent
                categoryId={7}
            />,
        },
    ],
    defaultIndex: 0,
};
