export const headerCss = (
	modalIsOpen: boolean,
	rows,
	cellLength,
	column,
	headerColumnIndex,
	headerGroup,
	viewType: string
) => {
	const width = {
		minWidth: column.width,
		width: column.width,
		maxWidth: column.width,
	};
	if (modalIsOpen) {
		switch (viewType) {
			case 'regularSchema':
				return {
					...width,
					backgroundColor: '#0176c0',
					borderTopLeftRadius: headerColumnIndex === 0 && '10px',
					borderTopRightRadius:
						headerGroup.headers.length - 1 === headerColumnIndex && '10px',
					display: 'flex',
					justifyContent: column.id.includes('total')
						? 'center'
						: column.id.includes('subsidized')
						? 'center'
						: column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					marginTop: '1%',
				};
			case 'percentageSchema':
				return {
					...width,
					backgroundColor: '#0176c0',
					borderTopLeftRadius: headerColumnIndex === 0 && '10px',
					borderTopRightRadius:
						headerGroup.headers.length - 1 === headerColumnIndex && '10px',
					display: 'flex',
					justifyContent: column.id.includes('total')
						? 'center'
						: column.id.includes('subsidized')
						? 'center'
						: column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					marginTop: '1%',
				};
			case 'specialSchema':
				return {
					minWidth: '20%',
					width: '20%',
					maxWidth: '20%',
					backgroundColor: '#0176c0',
					borderTopLeftRadius: headerColumnIndex === 0 && '10px',
					borderTopRightRadius:
						headerGroup.headers.length - 1 === headerColumnIndex && '10px',
					display: 'flex',
					justifyContent: column.id.includes('total')
						? 'center'
						: column.id.includes('subsidized')
						? 'center'
						: column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					marginTop: '1%',
				};
			case 'tierSchema':
				return {
					minWidth: headerColumnIndex === 0 ? '7.3%' : `16%`,
					width: headerColumnIndex === 0 ? '7.3%' : `15%`,
					maxWidth: headerColumnIndex === 0 ? '7.3%' : `15%`,
					backgroundColor: '#0176c0',
					borderTopLeftRadius: headerColumnIndex === 0 && '10px',
					borderTopRightRadius:
						headerGroup.headers.length - 1 === headerColumnIndex && '10px',
					display: 'flex',
					justifyContent: column.id.includes('total')
						? 'center'
						: column.id.includes('subsidized')
						? 'center'
						: column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					marginTop: '1%',
				};
		}
	}
	return {
		...width,
	};
};

export const bodyCss = (
	modalIsOpen: boolean,
	cellLength,
	cell,
	cellIndex,
	viewType: string,
	hasDetails: boolean
) => {
	const width = {
		minWidth: cell.column.width,
		width: cell.column.width,
		maxWidth: cell.column.width,
	};
	if (modalIsOpen) {
		const isColumnTotalOrNonShare =
			cell.column.id.includes('total') || cell.column.id.includes('non_share');
		switch (viewType) {
			case 'regularSchema':
				return {
					...width,
					display: 'flex',
					justifyContent: cell.column.id.includes('total')
						? 'center'
						: cell.column.id.includes('subsidized')
						? 'center'
						: cell.column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					paddingBottom: cell.column.id.includes('total') ? '15px' : '',
				};
			case 'percentageSchema':
				return {
					...width,
					display: 'flex',
					justifyContent: cell.column.id.includes('total')
						? 'center'
						: cell.column.id.includes('subsidized')
						? 'center'
						: cell.column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					paddingBottom: cell.column.id.includes('total') ? '15px' : '',
				};
			case 'specialSchema':
				return {
					minWidth: '20%',
					width: '20%',
					maxWidth: '20%',
					display: 'flex',
					justifyContent: cell.column.id.includes('total')
						? 'center'
						: cell.column.id.includes('subsidized')
						? 'center'
						: cell.column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					paddingBottom: cell.column.id.includes('total') ? '15px' : '',
				};
			case 'tierSchema':
				return {
					minWidth: cellIndex === 0 ? '7.3%' : `16%`,
					width: cellIndex === 0 ? '7.3%' : `15%`,
					maxWidth: cellIndex === 0 ? '7.3%' : `15%`,
					display: 'flex',
					justifyContent: isColumnTotalOrNonShare
						? 'center'
						: cell.column.id.includes('subsidized')
						? 'center'
						: cell.column.id.includes('pass')
						? 'center'
						: 'flex-start',
					alignItems: 'center',
					textAlign: 'center',
					tableLayout: 'fixed',
					paddingBottom: isColumnTotalOrNonShare ? '15px' : '',
				};
		}
	}
	if (hasDetails) {
		return {
			...width,
			whiteSpace: 'pre-line',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			...(cell.column.id === 'remarks' && { fontSize: 'min(0.8vw, 12px)' }),
		};
	}

	return {
		...width,
	};
};
