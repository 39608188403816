import { SectionRow, SectionTitle } from 'components/Section/Section';
import { useFormContext } from 'react-hook-form';
import { PartnerReportsFormData, TransferProtocolKey } from '..';
import {
	ControlledCsrColumnCheckbox,
	CsrColumn,
	useGetCsrColumnsQuery,
} from './CsrColumnCheckbox';
import Grid from 'components/Grid/Grid';
import CsrReportPreview from './CsrReportPreview';
import { SideHeaderProps } from './CsrReportPreview';
import { useMemo } from 'react';
import UploadConfiguration from '../UploadConfiguration';

const sideHeaders: SideHeaderProps = {
	merchantId: 'RT101',
	businessName: 'Radius Telecoms Inc',
	merchantName: 'Radius Telecoms Inc',
	generationDate: '08/27/2021',
};

export const FILE_FORMATS = ['CSV', 'XLS'];

type Props = {
	name: TransferProtocolKey;
	disabled?: boolean;
};

const CsrConfiguration: React.FC<Props> = ({ name, disabled }) => {
	const { control, watch } = useFormContext<PartnerReportsFormData>();
	const { data } = useGetCsrColumnsQuery();
	const namePrefix = `${name}.csrConfiguration` as const;

	const columns = watch(`${namePrefix}.columns`);
	const selectedColumns = useMemo<CsrColumn[]>(() => {
		if (!data) return [];
		if (!columns) return [];

		return columns
			.map(({ id }) => data.find((d) => d.id === id))
			.filter(Boolean) as CsrColumn[];
	}, [data, columns]);

	return (
		<UploadConfiguration
			name={namePrefix}
			title="Collection Summary Report (CSR) Configuration"
			disabled={disabled}
		>
			<SectionRow vertical gutters={false}>
				<Grid column>
					<SectionTitle>Select columns for the CSR template:</SectionTitle>
				</Grid>
				<Grid column>
					{data && (
						<ControlledCsrColumnCheckbox
							control={control}
							pool={data}
							name={`${namePrefix}.columns`}
							disabled={disabled}
						/>
					)}
				</Grid>
			</SectionRow>
			<CsrReportPreview sideHeaders={sideHeaders} columns={selectedColumns} />
		</UploadConfiguration>
	);
};
export default CsrConfiguration;
