import { AxiosResponse } from 'axios';
import {
	BranchAddressPayloadType,
	BranchAddressType,
	BranchContactDetailsPayloadType,
	BranchContactDetailsType,
	BranchOperatingScheduleType,
	BranchPayloadType,
	BranchScheduleType,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import client from 'helpers/ApiClient';
import moment from 'moment';
import { FixMeLater } from 'types';

export const transformBranchAddressToPayload = (
	branchFormAddress: BranchAddressType
): BranchAddressPayloadType[] => {
	const branchAddressPayload: BranchAddressPayloadType = {
		locationBase: branchFormAddress.locationBase || null,
		countryId: Number(branchFormAddress.country) || null,
		area: branchFormAddress.area || null,
		regionId: Number(branchFormAddress.region) || null,
		provinceId: Number(branchFormAddress.stateOrProvince) || null,
		cityId: Number(branchFormAddress.municipalityOrCity) || null,
		barangayId: Number(branchFormAddress.barangay) || null,
		buildingName: branchFormAddress.buildingNameOrNumber || null,
		street: branchFormAddress.street || null,
		zipCodeId: Number(branchFormAddress.zipCode) || null,
	};
	return [branchAddressPayload];
};

export const transformBranchContactDetailsToPayload = (
	branchContactDetails: BranchContactDetailsType
): BranchContactDetailsPayloadType[] => {
	const branchContactDetailsPayload: BranchContactDetailsPayloadType[] = [];

	branchContactDetails?.map((item) => {
		const branchContactDetailsItem: BranchContactDetailsPayloadType = {
			name: item.name || null,
			position: item.position || null,
			department: item.department || null,
			emailAddress: item.emailAddress.map((email) => email || '') || null,
			telNo: item.telephoneNumber.map((tel) => tel || '') || null,
			mobileNo: item.mobileNumber.map((mobile) => mobile || '') || null,
		};
		branchContactDetailsPayload.push(branchContactDetailsItem);
	});

	return branchContactDetailsPayload;
};

export const transformOperatingScheduleToPayload = (
	branchOperatingSchedule: BranchOperatingScheduleType
): BranchScheduleType[] => {
	const branchSchedulePayload: BranchScheduleType[] = [];

	branchOperatingSchedule.schedule
		?.filter((item) => item.isSelected)
		.map((item) => {
			const branchScheduleItem: BranchScheduleType = {
				days: item.day?.toLocaleUpperCase() || null,
				startTime: item.startTime || null,
				endTime: item.endTime || null,
			};
			branchSchedulePayload.push(branchScheduleItem);
		});
	return branchSchedulePayload;
};

export const transformBranchFormToPayload = (
	branchFormValues: BranchType,
	status: string,
	channelAccountId: number
): BranchPayloadType => {
	const result: BranchPayloadType = {
		primaryInfo: {
			branchName: branchFormValues.branchBasicInformation.branchName || null,
			accountId: channelAccountId || null,
			status: status || null,
			channelTransactionType:
				branchFormValues.branchBasicInformation.channelTransactionType,
			channelTypeId:
				Number(branchFormValues.branchBasicInformation.channelTypeId) || null,
			channelType: '',
			pldtCode: branchFormValues.branchBasicInformation.pldtTellerCode || null,
			cignalCode:
				branchFormValues.branchBasicInformation.cignalTellerCode || null,
			storeFormat: branchFormValues.branchBasicInformation.storeFormat || null,
			storeType: branchFormValues.branchBasicInformation.storeType || null,
			posType: branchFormValues.branchBasicInformation.posType || null,
			machineLocation:
				branchFormValues.branchBasicInformation.machineLocation || null,
			depositConsolidation:
				branchFormValues.branchOperatingSchedule.depositConsolidation || null,
			taxPayerType:
				branchFormValues.branchOperatingSchedule.taxPayerType || null,
			meralcoPaymentCenterId:
				Number(
					branchFormValues.branchBasicInformation.meralcoPaymentCenterCode
				) || null,
			remarks: branchFormValues.branchOperatingSchedule.remarks || null,
			activationDate:
				moment(branchFormValues.branchOperatingSchedule.activationDate).format(
					'YYYY-MM-DD'
				) || null,
			branchAddresses: transformBranchAddressToPayload(
				branchFormValues.branchAddress
			),
			branchContactDetails: transformBranchContactDetailsToPayload(
				branchFormValues.branchContactDetails
			),
			branchOperatingSchedule: transformOperatingScheduleToPayload(
				branchFormValues.branchOperatingSchedule
			),
		},
		remarks: branchFormValues.branchBasicInformation.remarksChanges || null,
	};
	return result;
};

export const postForBranch = async (
	primaryInfo: BranchPayloadType,
	draftsOrSubmit: 'drafts' | 'submit'
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.post(
		`/v2/branches/${draftsOrSubmit}`,
		primaryInfo
	);
	return fetchResult;
};

export const putForBranchDraft = async (
	primaryInfo: BranchPayloadType,
	branchId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.put(
		`/v2/branches/${branchId}/draft`,
		primaryInfo
	);
	return fetchResult;
};

export const putForBranchSave = async (
	primaryInfo: BranchPayloadType,
	branchId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.put(`/v2/branches/${branchId}`, primaryInfo);
	return fetchResult;
};

export const postForBranchSubmit = async (
	primaryInfo: BranchPayloadType,
	branchId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.put(
		`/v2/branches/${branchId}/submit`,
		primaryInfo
	);
	return fetchResult;
};

export const getForBranch = async (
	branchId: number
): Promise<AxiosResponse<BranchResponseType>> => {
	const fetchResult = await client.get<BranchResponseType>(
		`/v2/branches/${branchId}`
	);
	return fetchResult;
};

export const getForTerminal = async (
	terminalId: number
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.get(`/v2/terminals/${terminalId}`);
	return fetchResult;
};
