import React, { useState, useMemo, useEffect } from 'react';
import styles from './SearchFilter.module.css';
import { FilterOuter } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import DropdownCheckbox from 'components/Inputs/DropdownChecbox/DropdownCheckboxChannel';
import { productStatuses } from 'constants/channelType';
import { SearchFilterProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const SearchFilter: React.FC<SearchFilterProps> = ({
	data,
	control,
	filterControl,
	hasPartnerListPermission,
	hasStatusFilter,
	size,
	textBoxName,
	textBoxPlaceholder,
	dropDownName,
	dropDownPlaceholder,
	onFilterChange,
}) => {
	const [statusItemChecked, setStatusItemChecked] = useState<Array<any>>([]);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const filterValues = useMemo(
		() => ({
			searchFilter,
			statusItemChecked,
		}),
		[searchFilter, statusItemChecked]
	);

	useEffect(() => {
		onFilterChange(filterValues);
	}, [filterValues]);
	return (
		<>
			<FilterOuter>
				<Grid container align="end">
					<Grid
						container
						column
						size={12}
						of={12}
						align="end"
						gutters="x-small"
					>
						{hasStatusFilter && (
							<Grid column size={2} of={10}>
								<DropdownCheckbox
									placeholder={dropDownPlaceholder}
									name={dropDownName}
									options={productStatuses}
									control={control}
									onChange={(val) => {
										setStatusItemChecked(val);
									}}
									referenceCheckedItems={statusItemChecked}
									setReferenceCheckedItems={setStatusItemChecked}
									hasBadgeCount
									isReturnAllOnChange
									disabled={hasPartnerListPermission}
								/>
							</Grid>
						)}
						<Grid column size={size} of={12}>
							<div className={styles.searchBox}>
								<InputWithIcon
									name={textBoxName}
									control={control}
									value={searchFilter}
									onChange={(v) => {
										setSearchFilter(v);
									}}
									placeholder={textBoxPlaceholder}
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</FilterOuter>
		</>
	);
};
export default SearchFilter;
