import { common } from './common';
import * as yup from 'yup';
import { PrimaryInfoSchema } from './product';
import { noWhiteSpace } from 'utils/common';

export type product_affiliate = {
	code?: string;
	name?: string;
} & common;

export const product_affiliate_schema = yup.object().shape({
	name: yup
		.string()
		.nullable()
		.label('Affiliate/Brand Name')
		.required()
		.max(255, 'You can only input a max of 255 characters.')
		.test(noWhiteSpace()),
	code: yup
		.string()
		.nullable()
		.label('Merchant ID')
		.required()
		.max(5, 'You can only input a max of 5 characters.')
		.matches(/^[a-z|A-Z|0-9_]{1,5}$/, {
			excludeEmptyString: true,
			message: `This field only allows alphanumeric characters.`,
		})
		.test({
			name: 'Unique In Merchant ID Array',
			test: (value, context) => {
				let unique = true;

				//converted as 'any' because I need to use the 'from' variable
				//updating yup to latest version can access 'from' directly
				const ctx = context as any;

				//'from' is an array holding all the parents schema and value
				if (ctx.from !== undefined) {
					//access the root level parent.
					//last item in the array is the root parent
					const rootParent = ctx.from[ctx.from.length - 1]
						.value as PrimaryInfoSchema;

					//get the index of the current iteration while validating each item in the array
					//some item in the array will be validated but whatever the result is ignored
					const idx = ctx.options.index as number;

					//access the product_affiliates values
					//remove the current item to avoid self comparing later
					const filteredProductAffiliates =
						rootParent.product_affiliates?.filter(
							(item, index) => index !== idx
						);

					//transform array to contains array of codes only
					const codesArray = filteredProductAffiliates?.map(
						(item) => item.code
					);

					//Add the Merchant Id
					codesArray?.push(rootParent.code);

					//check if current value already exist
					if (codesArray?.includes(value as string)) unique = false;
				}

				return unique;
			},
			message: 'Merchant ID is already existing',
		}),
});
