export const CHANNEL_ACCOUNT_NAME = 'Channel Account Name';
export const CHANNEL_ACCOUNT_CODE = 'Channel Account Code';
export const BUSINESS_GROUP = 'Business Group';
export const PARTNER_SHIP_TYPE = 'Partnership Type';
export const CHANNEL_TRANSACTION_TYPE = 'Channel Transaction Type';
export const CHANNEL_TYPE = 'Channel Type';
export const POS_TYPE = 'POS Type';
export const SETTLEMENT_SETUP = 'Settlement Setup';
export const BUSINESS_TYPE = 'Business Type';
export const OTHER_BUSINESS_TYPE = 'Other Business Type';
export const TIN = 'TIN';
export const TIN_REGEX = /^[0-9]{12}$/;
export const TIN_MESSAGE = 'Input 12 numeric character only.';
export const PARTNER_LOWER_CASE = 'partner';
export const OTHERS_LOWER_CASE = 'others';
export const CUSTOMER_FRONTLINE_SOLUTIONS =
	'Is CFS?';
