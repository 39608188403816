import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import { DraftsFilterFormDataType } from 'containers/ChannelManagement/Drafts/DraftsFilter/types';
import {
	BackendDraftsData,
	DraftsData,
	TableStates,
} from 'containers/ChannelManagement/Drafts/DraftsTable/types';
import {
	BACKEND_SORTBY,
	BRANCH_NAME,
	CHANNEL_ACCOUNT_NAME,
	DATE_CREATED,
	DATE_UPDATED,
	REMARKS,
	STATUS,
	SUBMITTED_BY,
	TPAID,
	UPDATED_BY,
} from 'containers/ChannelManagement/Drafts/constants';

import HTTP from 'helpers/ApiClient';
import moment from 'moment';
import { UseFormHandleSubmit } from 'react-hook-form';
import { Updater } from 'use-immer';

export const fetchAndUpdateTableStates = (
	draftsFilterFormHandleSubmit: UseFormHandleSubmit<DraftsFilterFormDataType>,
	tableStates: TableStates,
	setTableStates: Updater<TableStates>,
	showLoading: () => void,
	hideLoading: () => void,
	enableSearch: () => void,
	disableSearch: () => void,
	setErrorMessage: Updater<string>,
	showError: () => void
): void => {
	draftsFilterFormHandleSubmit(async (draftsFilterFormData) => {
		showLoading();
		disableSearch();
		const params = {
			search:
				draftsFilterFormData.search && draftsFilterFormData.search.length > 2
					? draftsFilterFormData.search
					: '',
			dateCreated:
				draftsFilterFormData.dateCreated &&
				moment(draftsFilterFormData.dateCreated).format('YYYY/MM/DD'),
			dateUpdated:
				draftsFilterFormData.dateUpdated &&
				moment(draftsFilterFormData.dateUpdated).format('YYYY/MM/DD'),
			channel: draftsFilterFormData.channelAccountName,
			branch: draftsFilterFormData.branchName,
			terminal: draftsFilterFormData.tpaid,
			limit: tableStates.pageSize,
			sort: tableStates.sortOrder.toLowerCase(),
			sortBy: BACKEND_SORTBY[tableStates.sortBy],
			page: tableStates.page,
		};
		try {
			const result = await HTTP.get(`/v2/channels/drafts`, {
				params,
			});
			const { data, page, pageSize, total } = result.data;
			setTableStates((tableStates) => {
				tableStates.count = total;
				tableStates.page = page;
				tableStates.pageSize = pageSize;
				tableStates.data = parseData(data);
			});
		} catch (e) {
			setErrorMessage('Failed to fetch data...');
			showError();
		}
		hideLoading();
		enableSearch();
	})();
};

const parseData = (backendDraftsData: BackendDraftsData[]) => {
	const parsedData: DraftsData[] = [];

	backendDraftsData.forEach((item) => {
		const newDrafsData: DraftsData = {
			[DATE_CREATED]: item.createdAt,
			[SUBMITTED_BY]: item.submittedBy,
			[CHANNEL_ACCOUNT_NAME]: item.channelName,
			[BRANCH_NAME]: item.branchName,
			[TPAID]: item.tpaid,
			[DATE_UPDATED]: item.updatedAt,
			[UPDATED_BY]: item.updatedBy,
			[STATUS]: item.status,
			[REMARKS]: item.remarks,
			channelId: item.channelId,
			branchId: item.branchId,
			terminalId: item.terminalId,
			level: item.level,
		};
		parsedData.push(newDrafsData);
	});

	return parsedData;
};

export const updateDraftsDropdownOptions = async (
	setDropDownOptions: Updater<SelectOption[]>,
	setDropDownIsLoading: Updater<boolean>,
	setErrorMessage: Updater<string>,
	showError: () => void,
	url: string
): Promise<void> => {
	setDropDownIsLoading(true);
	try {
		const fetchedData = (await HTTP.get(url)).data.data;
		setDropDownOptions(parseToSelectOption(fetchedData));
	} catch (e) {
		setErrorMessage('Failed to fetch data...');
		showError();
	}
	setDropDownIsLoading(false);
};

const parseToSelectOption = (
	fetchedData: Array<{ id: number; name: string }>
) => {
	const parsedData: SelectOption[] = [];

	fetchedData.forEach((item) => {
		const newSelectOption: SelectOption = {
			label: item.name,
			value: item.name,
		};
		parsedData.push(newSelectOption);
	});

	return parsedData;
};
