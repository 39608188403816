import { ReactComponent as Icon } from 'assets/icons/ic-alert-triangle.svg';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Pagination from 'components/CWSTable/Pagination';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessChannelModal';
import {
	enableDisableBranch,
	enableDisablePartner,
	enableDisableTerminal,
	getProductServiceFee,
} from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductQuery';
import ChannelProductModal from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal';
import customModalStyles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import Accordion, {
	SnackBar,
} from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion';
import accordion from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion.module.css';
import AccordionItem from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/AccordionItem';
import accordionContainer from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/AccordionContainer/AccordionContainer.module.css';
import Navigation from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Navigation/Navigation';
import SearchFilter from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/SearchFilter/SearchFilter';
import ServiceFeeContainer from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeContainer';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import {
	AccordionContainerProps,
	EnableDisableState,
	MessageSpiel,
	PartnerListDataType,
} from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import { useList } from 'containers/ChannelManagement/List/hooks';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import client from 'helpers/ApiClient';
import { enqueueSnackbar } from 'notistack';
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { FixMeLater } from 'types';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';

const AlertIcon: React.FC = () => <Icon />;

const AccordionContainer: React.FC<AccordionContainerProps> = ({
	data,
	product,
	selectedChannel,
	isEnabledFilter,
}) => {
	const [expandedIndices, setExpandedIndices] = useState<string[]>([]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState<MessageSpiel>({
		header: '',
		caption: '',
	});
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [modalMessage, setModalMessage] = useState<React.ReactNode>(<></>);
	const [modalHeading, setModalHeading] = useState('');
	const hasUserPermission = useHasUserPermission('channels');
	const hasBranchPermission = useHasUserPermission('branches');
	const hasTerminalPermission = useHasUserPermission('terminals');
	const { enableBranch, disableBranch, enableTerminal, disableTerminal } =
		useMemo(() => {
			return {
				enableBranch: hasBranchPermission('enable'),
				disableBranch: hasBranchPermission('disable'),
				enableTerminal: hasTerminalPermission('enable'),
				disableTerminal: hasTerminalPermission('disable'),
			};
		}, [hasBranchPermission, hasTerminalPermission]);
	const { enableProductParnter, disableProductPartner } = useMemo(() => {
		return {
			enableProductParnter: hasUserPermission('enable'),
			disableProductPartner: hasUserPermission('disable'),
		};
	}, [hasUserPermission]);
	const hasEditPermission = hasUserPermission('edit');
	const hasEditServiceFeePermission = hasUserPermission('create.service-fee');
	const hasViewServiceFeePermission = hasUserPermission('view.service-fee');
	const hasViewEnableDisablePermission = hasUserPermission(
		'view.product-enable'
	);
	const [remarks, setRemarks] = useState('');
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [isEnabled, setIsEnabled] = useState(false);
	const [accordionData, setAccordionData] = useState<Array<any>>([]);
	const [filterValue, setFilterValue] = useState('');
	const [pageNumber, setPageNumber] = useState(1);
	const [pageLimit, setPageLimit] = useState(25);
	const [isInitialLoad, setIsInitialLoad] = useState(false);
	const [serviceFeeShareData, setServiceFeeShareData] = useState();
	const [resStatus, setResStatus] = useState();
	const [refetchList, setRefetchList] = useState<Array<any>>([]);
	// const [enableOptions, setEnableOptions] = useState<string>('');
	// const [optionsError, setOptionsError] = useState<ReactFragment>();
	// const [radioLabel, setRadioLabel] = useState({
	// 	retainLabel: '',
	// 	cascadeLabel: '',
	// });
	const [errorMessage, setErrorMessage] = useState({
		title: '',
		message: <></>,
		isAlert: false,
	});
	const [partnerList, setPartnerList] = useState<PartnerListDataType>({
		data: [],
		page: 0,
		pageSize: 0,
		total: 0,
	});
	const [forEnableDisable, setForEnableDisable] = useState<EnableDisableState>(
		{}
	);
	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});

	const { channelTypeFilter } = useList();

	const styles = {
		...accordionContainer,
		...accordion,
		...customModalStyles,
	};

	useEffect(() => {
		if (selectedChannel) {
			setExpandedIndices([]);
		}
	}, [selectedChannel]);

	// useEffect(() => {
	// 	setEnableFilter(isEnabledFilter);
	// }, [isEnabledFilter]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	const {
		value: isAccessDenied,
		valueOn: showAccessDenied,
		valueOff: hideAccessDenied,
	} = useToggle();

	const notifRef = useRef();

	useEffect(() => {
		const getPartnerList = async () => {
			showLoadingMessage('Please wait while we load data');
			let isEnableProp = '';
			const searchFilter =
				(isEnabledFilter && isEnabledFilter.searchFilter) || '';
			const filter = isEnabledFilter && isEnabledFilter.statusItemChecked;
			if (filter && filter.length === 1) {
				isEnableProp = filter[0] === 'Enabled' ? 'true' : 'false';
			}

			try {
				const response = await client.get(
					BASE_PATH +
						`${selectedChannel.id}/products/types/${product.id}?page=${pageNumber}&limit=${pageLimit}&search=${searchFilter}&isEnabled=${isEnableProp}`
				);
				setResStatus(response?.request?.status);
				setPartnerList(response.data);
				if (
					(searchFilter !== '' || (filter && filter.length > 0)) &&
					response.data.data.length === 0 &&
					pageNumber !== 1
				) {
					setPageNumber(1);
				}
			} catch (error) {
				console.error('Error fetching partner list: ', error);
			}
			hideLoading();
		};
		if (selectedChannel.id && isInitialLoad) {
			getPartnerList();
		} else {
			setIsInitialLoad(true);
		}

		return () => {
			setPartnerList({
				data: [],
				page: 1,
				pageSize: 25,
				total: partnerList.total,
			});
		};
	}, [
		selectedChannel.id,
		pageNumber,
		pageLimit,
		isEnabledFilter,
		product,
		refetchList,
	]);

	useEffect(() => {
		// resets and populate accordionData when selectedChannel is change
		if (partnerList.data && selectedChannel) {
			const updatedData = partnerList.data.map((data: FixMeLater) => {
				return { ...data, tabName: 'enableDisable' };
			});
			setAccordionData(updatedData);
		}

		return () => {
			// clean accordionData when selectedChannel or partnerList is changed
			setAccordionData([]);
		};
	}, [partnerList, selectedChannel]);

	const handleChangeOfRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setIsRemarksEmpty(
			/^\s+$/.test(event.target.value) || event.target.value === ''
		);
		setRemarks(event.target.value);
	};

	const deepCopy = (obj: any) => {
		if (typeof obj !== 'object' || obj === null) {
			return obj;
		}

		if (Array.isArray(obj)) {
			return obj.map((item) => deepCopy(item));
		}

		const newObj: any = {};
		for (const key in obj) {
			newObj[key] = deepCopy(obj[key]);
		}

		return newObj;
	};

	const showConfigShareError = () => {
		setErrorMessage({
			title: 'Please set the Service Fee Share',
			message: <></>,
			isAlert: true,
		});
		showErrorRetry();
	};

	const getSFShare = async (productId) => {
		try {
			const res = await getProductServiceFee(selectedChannel.id, productId);
			if (res) {
				return res.data.data;
			}
		} catch (err) {
			console.error((err as FixMeLater).response);
			// timeout error
		}
		return null;
	};

	const setSnackBar = (
		isProductEnabled,
		isBranchEnabled,
		data,
		type,
		name,
		branchName
	) => {
		let message = {};
		if (data && type) {
			if (!isProductEnabled && type === 'partner') {
				message = {
					branch: `${name} is Disabled. Enable the partner to edit the branch status.`,
				};
			} else if (isBranchEnabled !== null && !isBranchEnabled) {
				message = {
					terminal: `${branchName} is Disabled. Enable the branch to edit the TPAID status.`,
				};
			} else {
				message = {
					branch: `${name} is Disabled. Enable the partner to edit the branch status.`,
				};
			}

			notifRef.current = enqueueSnackbar(
				<SnackBar
					message={message[type] || 'This item is disabled.'}
					key={message[type]}
				/>,
				{
					key: message[type],
					preventDuplicate: true,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center',
					},
				}
			) as never;
			return;
		}
	};

	const handleToggleOnClick = async (
		index: number,
		subIndex?: number,
		subSubIndex?: number | typeof NaN,
		_data?: Record<string, unknown>,
		type?: 'branch' | 'terminal'
	) => {
		if (!product.enabled && _data && type) {
			const message = {
				branch: `${product.name} is Disabled. Enable the product to edit the branch status.`,
				terminal: `${product.name} is Disabled. Enable the product to edit the TPAID status.`,
			};
			notifRef.current = enqueueSnackbar(
				<SnackBar
					message={message[type] || 'This item is disabled.'}
					key={message[type]}
				/>,
				{
					key: message[type],
					preventDuplicate: true,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center',
					},
				}
			) as never;
			return;
		}

		const updatedData = deepCopy(accordionData);

		if (
			(subIndex !== undefined && typeof subSubIndex !== 'number') ||
			Number.isNaN(subSubIndex)
		) {
			// setRadioLabel({
			// 	retainLabel: 'TPA IDs',
			// 	cascadeLabel: 'TPAIDs',
			// });
			updatedData[index].data.branches[subIndex].enabled =
				!updatedData[index].data.branches[subIndex].enabled;
			// to add temporary enable disable of all terminal
			if (
				(updatedData[index].data.branches[subIndex].enabled &&
					!enableProductParnter) ||
				!enableBranch ||
				(!updatedData[index].data.branches[subIndex].enabled &&
					!disableProductPartner) ||
				!disableBranch
			) {
				showAccessDenied();
				return;
			} else {
				if (updatedData[index].isEnabled !== 'Disabled') {
					updateItem(
						updatedData[index].data.branches[subIndex],
						'branches',
						updatedData[index].productId,
						null,
						updatedData
					);
				} else {
					setSnackBar(
						updatedData[index].isEnabled !== 'Disabled',
						null,
						_data,
						type,
						updatedData[index].productName,
						null
					);
				}
			}
		} else if (
			(subIndex !== undefined && typeof subSubIndex === 'number') ||
			(!Number.isNaN(subSubIndex) && subSubIndex !== undefined)
		) {
			updatedData[index].data.branches[subIndex].terminals[
				subSubIndex
			].enabled =
				!updatedData[index].data.branches[subIndex].terminals[subSubIndex]
					.enabled;
			if (
				(updatedData[index].data.branches[subIndex].terminals[subSubIndex]
					.enabled &&
					!enableProductParnter) ||
				!enableBranch ||
				!enableTerminal ||
				(!updatedData[index].data.branches[subIndex].terminals[subSubIndex]
					.enabled &&
					!disableProductPartner) ||
				!disableBranch ||
				!disableTerminal
			) {
				showAccessDenied();
				return;
			} else {
				if (
					updatedData[index].isEnabled !== 'Disabled' &&
					updatedData[index].data.branches[subIndex].enabled === true
				) {
					updateItem(
						updatedData[index].data.branches[subIndex].terminals[subSubIndex],
						'terminals',
						updatedData[index].productId,
						updatedData[index].data.branches[subIndex].id,
						updatedData
					);
				} else {
					setSnackBar(
						updatedData[index].isEnabled !== 'Disabled',
						updatedData[index].data.branches[subIndex].enabled,
						_data,
						type,
						updatedData[index].productName,
						updatedData[index].data.branches[subIndex].name
					);
				}
			}
		} else {
			// setRadioLabel({
			// 	retainLabel: 'Branches and TPA IDs',
			// 	cascadeLabel: 'Branches and TPAIDs',
			// });

			updatedData[index].isEnabled =
				updatedData[index].isEnabled === 'Enabled' ? 'Disabled' : 'Enabled';

			if (
				(updatedData[index].isEnabled === 'Enabled' && !enableProductParnter) ||
				(updatedData[index].isEnabled === 'Disabled' && !disableProductPartner)
			) {
				showAccessDenied();
				return;
			}
			// check sfShare when enabling/disabling partner
			const checkSfShare = await getSFShare(updatedData[index].productId);
			if (
				checkSfShare === null ||
				(checkSfShare && checkSfShare.shares.length === 0)
			) {
				if (product.enabled) showConfigShareError();
			} else {
				if (product.enabled) {
					updateItem(updatedData[index], 'partner', null, null, updatedData);
				}
			}
		}
	};

	const updateItem = (
		item: any,
		level: any,
		partnerId?: any,
		branchId?: any,
		data?: any
	) => {
		switch (level) {
			case 'partner':
				setModalSettings(item, 'Partner');
				setForEnableDisable({ partnerId: item.productId, update: data });
				break;
			case 'branches':
				setModalSettings(item, 'Branch');
				setForEnableDisable({
					partnerId: partnerId,
					branchId: item.id,
					update: data,
				});
				break;
			case 'terminals':
				setModalSettings(item, 'TPAID');
				setForEnableDisable({
					partnerId: partnerId,
					branchId: branchId,
					terminal: item.id,
					update: data,
				});
				break;
		}
	};

	const setModalSettings = (item: any, level: string) => {
		let setEnabled;
		level === 'TPAID' || level === 'Branch'
			? (setEnabled = item.enabled)
			: (setEnabled = item.isEnabled === 'Enabled');
		setModalMessage(
			<>
				Are you sure you want to
				{setEnabled ? ' enable ' : ' disable '}
				<strong>
					{level === 'Branch'
						? item.name
						: level === 'TPAID'
						? item.tpa_id
						: item.productName}
				</strong>
				?
			</>
		);
		setModalHeading(`${setEnabled ? 'Enable ' : 'Disable '} ${level}`);
		setShowConfirmationModal(true);
		setIsEnabled(setEnabled);
	};

	const handleToggleOnChange = (
		index: number,
		e: ChangeEvent<HTMLInputElement>
	) => {
		return;
	};
	const dispatch = useDispatch();

	const onClickTab = (tab: any, index) => {
		const changeActiveTabOnIndex = [...accordionData];
		changeActiveTabOnIndex[index].tabName = tab.tabName;
		setAccordionData(changeActiveTabOnIndex);
		if (tab.tabName === 'serviceFee') {
			if (!hasViewServiceFeePermission) {
				showAccessDenied();
				return;
			}
			getProductServiceFees(
				selectedChannel.id,
				changeActiveTabOnIndex[index].productId
			);
		} else {
			if (!hasViewEnableDisablePermission) {
				showAccessDenied();
				return;
			}
		}
	};

	const handleSubAccordionClick = useCallback(
		(index: number, subIndex?: number) => {
			const getItemKey = (i: number, subI?: number) =>
				subI !== undefined ? `${i}-${subI}` : i.toString();

			const itemKey = getItemKey(index, subIndex);
			const isExpanded = expandedIndices.includes(itemKey);
			setExpandedIndices((expandedIndices) =>
				isExpanded
					? expandedIndices.filter((key) => key !== itemKey)
					: [...expandedIndices, itemKey]
			);
		},
		[expandedIndices]
	);
	const handleConfirmBtnClick = () => {
		if (
			forEnableDisable.branchId !== undefined &&
			forEnableDisable.terminal === undefined
		) {
			// if (enableOptions !== '') {
			setShowConfirmationModal(false);
			enableDisableBranchQuery();
			// } else {
			// 	setOptionsError(
			// 		<span className={styles.radioOptionsLabel}>Please select option</span>
			// 	);
			// }
		} else if (
			forEnableDisable.branchId !== undefined &&
			forEnableDisable !== undefined
		) {
			setShowConfirmationModal(false);
			enableDisableTerminalQuery();
		} else {
			// if (enableOptions !== '') {
			setShowConfirmationModal(false);
			enableDisablePartnerQuery();
			// } else {
			// 	setOptionsError(
			// 		<span className={styles.radioOptionsLabel}>Please select option</span>
			// 	);
			// }
		}
	};
	const handleSuccessResponse = (message) => {
		setSuccessMessage(message);
		showSuccessModal();
		clearRemarks();
	};

	const handleErrorResponse = () => {
		setErrorMessage({
			title: 'Timeout Error!',
			message: (
				<>
					<div>A problem occured while saving the data.</div>{' '}
					<div>Please try again.</div>
				</>
			),
			isAlert: false,
		});
		showErrorRetry();
		clearRemarks();
	};

	const setLoaderMessage = (level) => {
		const action = isEnabled ? 'enable' : 'disable';
		showLoadingMessage(`Please wait while we mark the ${level} as ${action}`);
	};
	const enableDisablePartnerQuery = async () => {
		setLoaderMessage('partner');
		enableDisablePartner(
			selectedChannel.id,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks } // { remarks: remarks, option: enableOptions }
		)
			.then(() => {
				handleSuccessResponse({
					header: `Successfully ${isEnabled ? 'Enabled' : 'Disabled'}`,
					caption: (
						<>
							<b>
								{' '}
								{
									forEnableDisable.update.find(
										(val) => val.productId === forEnableDisable.partnerId
									).productName
								}
							</b>{' '}
							is now {isEnabled ? 'Enabled' : 'Disabled'}
						</>
					),
				});
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const enableDisableBranchQuery = async () => {
		setLoaderMessage('branch');
		enableDisableBranch(
			forEnableDisable.branchId,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks } // { remarks: remarks, option: enableOptions }
		)
			.then(() => {
				handleSuccessResponse({
					header: `Successfully ${isEnabled ? 'Enabled' : 'Disabled'}`,
					caption: (
						<>
							<b>
								{
									forEnableDisable.update
										.find(
											(value) => value.productId === forEnableDisable.partnerId
										)
										.data.branches.find(
											(val) => val.id === forEnableDisable.branchId
										).name
								}
							</b>{' '}
							is now {isEnabled ? 'Enabled' : 'Disabled'}
						</>
					),
				});
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const enableDisableTerminalQuery = async () => {
		setLoaderMessage('terminal');
		enableDisableTerminal(
			forEnableDisable.terminal,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks }
		)
			.then(() => {
				handleSuccessResponse({
					header: `Successfully ${isEnabled ? 'Enabled' : 'Disabled'}`,
					caption: (
						<>
							<b>
								{
									forEnableDisable.update
										.find(
											(value) => value.productId === forEnableDisable.partnerId
										)
										.data.branches.find(
											(val) => val.id === forEnableDisable.branchId
										)
										.terminals.find(
											(val2) => val2.id === forEnableDisable.terminal
										).tpa_id
								}
							</b>{' '}
							is now {isEnabled ? 'Enabled' : 'Disabled'}
						</>
					),
				});
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleClickRetry = () => {
		// retry option here
	};

	const handleCancelBtnClick = () => {
		setShowConfirmationModal(false);
		// setEnableOptions('');
		// setOptionsError(undefined);
		clearRemarks();
	};

	const clearRemarks = () => {
		setRemarks('');
		setIsRemarksEmpty(true);
	};

	const filterBranch = (filter: any, sections: any) => {
		return (
			sections.branches &&
			sections.branches.filter((section) =>
				section.name.toLowerCase().includes(filter.toLowerCase())
			)
		);
	};

	const setSectionData = useCallback((section, productId) => {
		setAccordionData((prevAccordionData) => {
			const updatedData = prevAccordionData.map((val) => {
				if (val.productId === productId && val.data === undefined) {
					return section;
				}
				return val;
			});
			return updatedData;
		});
	}, []);

	/**
	 * @function: When partner is clicked from enable to disable,
	 * all branches and terminals should be disabled
	 * @param section : product with branches and terminals
	 * */
	const cascadeDisabled = (section) => {};

	const getPageNumberOptions = (
		pageLimit: number,
		totalItems: number
	): number[] => {
		const totalPages = Math.ceil(totalItems / pageLimit);
		const pageNumberOptions: number[] = [];

		for (let i = 1; i <= totalPages; i++) {
			pageNumberOptions.push(i);
		}
		return pageNumberOptions;
	};

	const goToLastPage = (pageLimit: number, totalItems: number) => {
		const last = Math.ceil(totalItems / pageLimit);
		setPageNumber(last);
	};

	const handleOnExpandAction = (index, isExpanded) => {
		if (!isExpanded && !hasViewEnableDisablePermission) {
			showAccessDenied();
		}
		const resetActiveTabOnIndex = [...accordionData];
		resetActiveTabOnIndex[index].tabName = 'enableDisable';
		setAccordionData(resetActiveTabOnIndex);
	};
	const [isLoadingSFS, setIsLoadingSFS] = useState<any[]>([]);
	const getProductServiceFees = async (channelId, productId) => {
		setIsLoadingSFS((prev) => {
			const exists = prev && prev.find((item) => item.productId === productId);

			if (exists) {
				return prev.map((item) =>
					item.productId === productId ? { ...item, isLoading: true } : item
				);
			} else {
				return [
					...prev,
					{
						productId: productId,
						isLoading: true,
					},
				];
			}
		});
		try {
			const result = await getProductServiceFee(channelId, productId);
			if (result) {
				setServiceFeeShareData((prev) => {
					const exists =
						prev &&
						prev.find(
							(v) => v.config.productId === result.data.data.config.productId
						);
					const prevData = prev || [];
					return exists ? prev : [...prevData, result.data.data];
				});
			}
			setIsLoadingSFS((prev) =>
				prev.map((item) =>
					item.productId === productId ? { ...item, isLoading: false } : item
				)
			);
		} catch (err) {
			console.error('Error: ', err?.response);
			setIsLoadingSFS((prev) =>
				prev.map((item) =>
					item.productId === productId ? { ...item, isLoading: false } : item
				)
			);
		}
	};

	// const handleEnableOption = (value) => {
	// 	setEnableOptions(value);
	// 	setOptionsError(undefined);
	// };

	return (
		<>
			<div className={styles.header}>
				<div>
					{data.title}
					<span className={styles.action}>{data.action}</span>
				</div>
			</div>
			<div className={styles.relativeContainer}>
				<div className={styles.body}>
					{accordionData && accordionData.length > 0 ? (
						accordionData.map((section: any, index: number) => {
							const filteredBranches =
								section.data &&
								section.data.branches &&
								filterBranch(filterValue, section.data);
							const branchesToRender =
								filteredBranches || (section.data && section.data.branches);
							return (
								section && (
									<Accordion
										title={section.productName}
										product={product}
										isPartnerEnabled={product.enabled}
										enabled={section.isEnabled === 'Enabled' ? true : false}
										accordionKey={index}
										onExpandAction={handleOnExpandAction}
										onClickBtn={handleToggleOnClick}
										onChangeBtn={handleToggleOnChange}
										key={index}
										channel={selectedChannel}
										partner={section}
										sectionData={setSectionData}
										hasViewEnableDisablePermission={
											hasViewEnableDisablePermission
										}
										content={
											<>
												<ul className={styles.navSection}>
													<Navigation
														tabs={[
															{
																title: 'Enable/Disable',
																name: 'enableDisable',
															},
															{ title: 'Service Fee', name: 'serviceFee' },
														]}
														itemKey={index}
														onClickTab={onClickTab}
														content={
															<>
																{section &&
																section.data &&
																section.data.branches.length > 0 &&
																section.tabName === 'enableDisable' ? (
																	hasViewEnableDisablePermission ? (
																		<>
																			<div className={styles.searchBox}>
																				<SearchFilter
																					onFilterChange={(e) => {
																						setFilterValue(e.searchFilter);
																					}}
																					key={selectedChannel}
																					size={12}
																					textBoxName="filterBranch.branchName"
																					textBoxPlaceholder="Search Branch Name"
																					onChange={(e) => {}}
																				/>
																			</div>
																			<div
																				key={index}
																				className={styles.expandedContent}
																			>
																				{branchesToRender &&
																					branchesToRender.map(
																						(
																							subSection: any,
																							subIndex: number
																						) => {
																							const itemKey = `${index}-${subIndex}`;
																							const isSubExpanded =
																								expandedIndices.includes(
																									itemKey
																								);
																							return (
																								<AccordionItem
																									key={subIndex}
																									index={index}
																									subIndex={subIndex}
																									handleSubAccordionClick={
																										handleSubAccordionClick
																									}
																									listItemClass={
																										styles.listItem
																									}
																									accordionBtnClass={
																										styles.accordionButton
																									}
																									expandedClass={
																										styles.expanded
																									}
																									isSubExpanded={isSubExpanded}
																									subSectionItemClass={
																										styles.subSectionItem
																									}
																									subSection={subSection}
																									section={section}
																									toggleButtonClass={
																										styles.toggleButton
																									}
																									toggleBtnDivClass={
																										styles.toggleBtnDiv
																									}
																									handleToggleOnChange={
																										handleToggleOnChange
																									}
																									handleToggleOnClick={
																										handleToggleOnClick
																									}
																									lineDeviderClass={
																										styles.lineDevider
																									}
																									hasEditPermission={
																										hasEditPermission
																									}
																									accordionContent2Class={
																										styles.accordionContent2
																									}
																								/>
																							);
																						}
																					)}
																			</div>
																		</>
																	) : (
																		<div style={{ height: '40px' }}></div>
																	)
																) : (
																	<>
																		{section.tabName === 'enableDisable' ? (
																			<div
																				key={index}
																				className={cx(
																					styles.navSection,
																					styles.noItem
																				)}
																			>
																				{section &&
																				section.data &&
																				section.data.branches.length === 0 ? (
																					<>No branch and terminal available</>
																				) : (
																					<>
																						Please wait while we load the branch
																						list
																					</>
																				)}
																			</div>
																		) : (
																			<></>
																		)}
																	</>
																)}
																{section.tabName === 'serviceFee' ? (
																	hasViewServiceFeePermission ? (
																		<ServiceFeeContainer
																			key={`service-fee-${section.productId}`}
																			itemKey={index}
																			data={section}
																			selectedChannel={selectedChannel}
																			permission={!hasEditPermission}
																			serviceFees={serviceFeeShareData}
																			hasEditServiceFeePermission={
																				!hasEditServiceFeePermission
																			}
																			channelTypes={channelTypeFilter}
																			isLoadingSFS={isLoadingSFS}
																		/>
																	) : (
																		<div style={{ height: '40px' }}></div>
																	)
																) : (
																	''
																)}
															</>
														}
													/>
												</ul>
											</>
										}
									/>
								)
							);
						})
					) : (
						<div className={styles.accordionMessage}>
							{resStatus === 200 && accordionData && accordionData.length === 0
								? 'No Partner available'
								: 'Please wait while we load partner list'}
						</div>
					)}
				</div>
			</div>
			<div className={styles.footer}>
				<Pagination
					page={partnerList.page}
					canPreviousPage={true}
					canNextPage={true}
					pageOptions={getPageNumberOptions(pageLimit, partnerList.total)}
					previousPage={() => setPageNumber(pageNumber - 1)}
					gotoPage={(v) => {
						setPageNumber(v === 0 ? 0 : v + 1);
					}}
					nextPage={(v) => {
						setPageNumber(pageNumber + 1);
					}}
					handlePageCount={(v) => setPageLimit(v)}
					pageIndexProps={
						partnerList.page > 0 ? partnerList.page - 1 : partnerList.page
					}
					count={partnerList.total}
					goToFirst={() => setPageNumber(0)}
					goToLast={() => goToLastPage(pageLimit, partnerList.total)}
					pageCount={partnerList.total}
					pageSize={partnerList.pageSize}
					key={1}
					showFrom={true}
				/>
			</div>

			{isAccessDenied && (
				<AccessDeniedModal open={isAccessDenied} onClose={hideAccessDenied} />
			)}

			{showConfirmationModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>{modalHeading}</h2>}
					message={<h2 className={styles.bodyHeader}>{modalMessage}</h2>}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					// getOption={handleEnableOption}
					// retainLabel={radioLabel.retainLabel}
					// cascadeLabel={radioLabel.cascadeLabel}
					// errorOptions={optionsError}
					// hasRadioGroup
				/>
			)}
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>{successMessage.header}</SuccessText>
					<div className={styles.successMessage}>
						<p className={styles.subTextNext}>{successMessage.caption}</p>
					</div>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.doneButton}
						onClick={() => {
							setRefetchList(accordionData);
							hideSuccessModal();
						}}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal
				open={isErrorRetryShowing}
				onClose={hideErrorRetry}
				Icon={errorMessage.isAlert ? <AlertIcon /> : undefined}
			>
				<ErrorModalBody
					className={cx(
						styles.errorBody,
						errorMessage.isAlert ? styles.alertBody : ''
					)}
				>
					<div className={styles.errorHeader}>
						<b>{errorMessage.title}</b>
					</div>
					<br />
					<div className={styles.errorText}>
						<div>{errorMessage.message}</div>
					</div>
					<br />
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={() => {
							hideErrorRetry();
							handleClickRetry();
						}}
					>
						Okay
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default AccordionContainer;
