import React from 'react';
import { debounce as _debounce } from 'lodash';

import SelectField from 'components/Inputs/SelectField/SelectField';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import TextField from 'components/Inputs/TextField/TextField';
import LookupFieldWithExpanded from 'components/Inputs/LookupFieldWithExpanded';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid/Grid';

import {
	Api3TrnsactionStatus,
	BfaTransactionStatus,
	ProductType,
} from 'utils/lookup';

import { TransactionFilterProps } from './types';
import styles from './TransactionMgt.module.css';

const TransactionFilter: React.FC<TransactionFilterProps> = ({
	handleFilterSubmit,
	reset,
	resetField,
	control,
	formState: { isValid, isDirty },
	handleSubmit,
}) => {
	const handleReset = () => {
		reset();
		handleFilterSubmit({});
	};

	const productTypeOptions = ProductType.map(({ label }) => ({
		label,
		value: label,
	}));

	return (
		<Grid container align="spread" verticalAlign="start" gutters="xxx-small">
			<Grid column size={3} of={12}>
				<DatePickerField
					control={control}
					name="dateFrom"
					label="Date From"
					className={styles.dateField}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<DatePickerField
					control={control}
					name="dateTo"
					label="Date To"
					className={styles.dateField}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<LookupFieldWithExpanded
					name="partnerName"
					displayName="Partner Name"
					control={control}
					apiRoute="/v2/bfa-admin-portal/utils/transaction/partners"
					isSearchable
					className={styles.lookupTextField}
					labelClassName={styles.lookupTextLabel}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<SelectField
					name="productType"
					label="Product Type"
					control={control}
					options={productTypeOptions}
					className={styles.selectField}
					labelClassName={styles.lookupTextLabel}
					parseDefaultValue={false}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<LookupFieldWithExpanded
					name="channelPartner"
					control={control}
					displayName="Channel Name"
					apiRoute="/v2/bfa-admin-portal/utils/transaction/channels"
					isSearchable
					className={styles.lookupTextField}
					labelClassName={styles.lookupTextLabel}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<LookupFieldWithExpanded
					name="branchName"
					control={control}
					displayName="Branch Name"
					apiRoute="/v2/bfa-admin-portal/utils/transaction/branches"
					isSearchable
					className={styles.lookupTextField}
					labelClassName={styles.lookupTextLabel}
				/>
			</Grid>
			<Grid column size={2} of={12}>
				<TextField
					name="tpaid"
					label="TPAID"
					placeholder="TPAID"
					control={control}
					className={styles.textField}
					labelClassName={styles.lookupTextLabel}
					onBlur={(e?: any) => {
						if (e?.target?.value === '') {
							resetField('tpaid');
							return;
						}
					}}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<SelectField
					name="status"
					label="API 3.0 Status"
					placeholder="API 3.0 Status"
					control={control}
					options={Api3TrnsactionStatus}
					className={styles.selectField}
					labelClassName={styles.lookupTextLabel}
					parseDefaultValue={false}
				/>
			</Grid>
			<Grid column size={3} of={12}>
				<SelectField
					name="bfaStatus"
					label="BFA Status"
					placeholder="BFA Status"
					control={control}
					options={BfaTransactionStatus}
					className={styles.selectField}
					labelClassName={styles.lookupTextLabel}
					parseDefaultValue={false}
				/>
			</Grid>
			<Grid column size={1} of={8}>
				<OutlineButton
					fullWidth
					onClick={handleReset}
					disabled={!isDirty}
					className={styles.buttons}
				>
					Clear All Filters
				</OutlineButton>
			</Grid>
			<Grid column size={1} of={8}>
				<PrimaryButton
					disabled={!isValid || !isDirty}
					onClick={handleSubmit((filter) => handleFilterSubmit(filter))}
					fullWidth
					className={styles.buttons}
				>
					Filter
				</PrimaryButton>
			</Grid>
		</Grid>
	);
};

export default TransactionFilter;
