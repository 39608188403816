import Grid from 'components/Grid/Grid';
import { EmailTagInput } from 'components/TagInput/TagInput';
import { useFormContext } from 'react-hook-form';
import { PartnerReportsFormData } from '.';
import TransferProtocolInformation from './TransferProtocol';
import UploadConfiguration from './UploadConfiguration';
import styles from './style.module.css';
import CsrConfiguration from './CsrConfiguration';
import Section from 'components/Section/Section';

type Props = {
	disabled?: boolean;
};

const NAME = 'smtp' as const;

const EmailAddressFields: React.FC<Props> = ({ disabled }) => {
	const { control, trigger } = useFormContext<PartnerReportsFormData>();
	return (
		<Grid container gutters className={styles.emailAddressContainer}>
			<Grid column size={1} of={2}>
				<EmailTagInput
					required
					control={control}
					name="smtp.senderEmail"
					label="Sender Email Address"
					disabled
				/>
			</Grid>
			<Grid column size={1} of={2}>
				<EmailTagInput
					required
					control={control}
					name="smtp.receiverEmail"
					label="Receiver Email Address"
					disabled={disabled}
					onInputChange={() => trigger("smtp.receiverEmail")}
				/>
			</Grid>
		</Grid>
	);
};

const SMTP: React.FC<Props> = ({ disabled }) => {
	return (
		<>
			<Section title="SMTP"></Section>
			<EmailAddressFields disabled={disabled} />
			<CsrConfiguration name={NAME} disabled={disabled} />
			<UploadConfiguration
				name={`${NAME}.uploadConfiguration`}
				title="Upload Configuration"
				disabled={disabled}
			/>
		</>
	);
};

export default SMTP;
