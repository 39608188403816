import {
	handleSearch,
	filterActionCreator,
	filterActionCreatorCount,
} from '../../redux/modules/transaction';
import { showAccessDeniedModal } from 'redux/modules/access';
import { connect } from 'react-redux';
import { Component } from 'react';

import TransactionSubFilter from '../TransactionSubFilter/TransactionSubFilter';
import DownloadButton from '../Buttons/DownloadButton/DownloadButton';
import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import styles from './TransactionFilterForm.module.css';
import verifyPermission from 'utils/verifyPermission';
import cx from 'classnames';
import { debounce } from 'lodash';

const DOWNLOAD_TRANSACTION_LIST = {
	scope: 'bip.transaction.download',
	resource: '/v1/transactions/download',
};
class TransactionFilterForm extends Component {
	constructor() {
		super();
		this.handleDownload = this.handleDownload.bind(this);
		this.handleFilterSearch = debounce(this.handleFilterSearch.bind(this), 2500);
	}

	handleDownload() {
		if (
			!verifyPermission(this.props.userInfo.scopes, DOWNLOAD_TRANSACTION_LIST)
		) {
			this.props.showAccessDeniedModal();
		}
	}

	handleFilterSearch(v) {
		if ((v && v.length > 2) || v === '') {
			handleSearch(
				{ search: v },
				this.props.filterActionCreator,
				this.props.filterActionCreatorCount
			);
		}
	}

	render() {
		return (
			<div className={cx('slds-card', styles.divCardContainer)}>
				<div className="slds-card__headers slds-grid">
					<article className="slds-col slds-size_12-of-12">
						<header className="slds-media slds-media_center slds-has-flexi-truncate">
							<div className="slds-media__body">
								<div className="slds-grid slds-grid_align-spread">
									<p className={cx(styles.cardTitle, 'slds-col')}>
										Transaction List
									</p>
									<div className="slds-col slds-size_5-of-12">
										<div className="slds-grid slds-grid_align-spread">
											<div style={{ width: '340px' }}>
												<InputWithIcon
													placeholder="Search"
													icons={[
														{
															path: 'utility/search',
															isLeft: true,
															className: styles.inputIcon,
														},
													]}
													onChange={this.handleFilterSearch}
												/>
											</div>
											<DownloadButton
												name="Download"
												onClick={this.handleDownload}
											/>
										</div>
									</div>
								</div>
							</div>
						</header>
					</article>
				</div>
				<div
					className="slds-card__body slds-card__body_inner"
					style={{ paddingLeft: 0, paddingRight: 0 }}
				>
					<TransactionSubFilter />
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		userInfo: state.userInfo,
	}),
	{ filterActionCreator, filterActionCreatorCount, showAccessDeniedModal }
)(TransactionFilterForm);
