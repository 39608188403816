import PrimaryButton from '../Buttons/PrimaryButton';
import OutlineButton from '../Buttons/OutlineButton';
import React from 'react';
import styles from './ResponseModal.module.css';
import cx from 'classnames';

function ResponseModal(props) {
	const {
		isOpen,
		icon,
		bodyHeader,
		bodyText,
		respondButton,
		respondButton2,
		isRespondButton2Shown = false,
		small = false,
	} = props;
	const toggleModalCss = isOpen ? 'slds-fade-in-open' : '';
	const toggleModalBackdropCss = isOpen ? 'slds-backdrop_open' : '';
	const displayRespondButton2 = !isRespondButton2Shown
		? { display: 'none' }
		: {};
	const respondBtn2 = !isRespondButton2Shown ? {} : respondButton2;
	return (
		<>
			<section
				role="dialog"
				tabIndex="-1"
				className={cx('slds-modal', toggleModalCss, 'slds-modal_small')}
				aria-labelledby="modal-heading-01"
				aria-modal="true"
				aria-describedby="modal-content-id-1"
			>
				<div
					className={cx(
						small ? styles.smallModal : styles.modal,
						'slds-modal__container'
					)}
				>
					<div
						className={cx(
							'slds-modal__content slds-text-align--center',
							small ? 'slds-p-around_x-large' : 'slds-p-around_large'
						)}
						id="modal-content-id-1"
						style={{ borderRadius: '4px 4px 4px 4px' }}
					>
						<img src={icon} alt="Success" />
						<span
							className={cx(styles.bodyHeader, 'slds-m-top_large')}
							style={{ display: 'block' }}
						>
							{bodyHeader}
						</span>
						<span
							className={cx(styles.bodyText, 'slds-m-top_medium')}
							style={{ display: 'block' }}
						>
							{bodyText}
						</span>

						<div className="slds-align_absolute-center slds-m-top_large">
							<OutlineButton
								style={displayRespondButton2}
								onClick={respondBtn2.event}
								btnClassStyle="outline2"
								className={
									'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
								}
							>
								{respondBtn2.name}
							</OutlineButton>
							<PrimaryButton
								onClick={respondButton.event}
								className={
									'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
								}
							>
								{respondButton.name}
							</PrimaryButton>
						</div>
					</div>
				</div>
			</section>
			<div className={cx(toggleModalBackdropCss, 'slds-backdrop ')}></div>
		</>
	);
}

export default ResponseModal;
