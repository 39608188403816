import { yupResolver } from '@hookform/resolvers/yup';
import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import ChannelCredentials from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentials';
import ChannelCertificate from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Certificate/ChannelCertificate';
import { schema } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/ReportsTabSchema';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { REPORTS_FORM_DEFAULT_VALUES } from 'containers/ChannelManagement/Channel/ChannelForm/const';
import styles from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelIntegration.module.css';
import ChannelStatusSelect from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelStatusSelect';
import {
	SetStateAction,
	useCallback,
	useEffect,
	useState,
	useMemo,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import { channelIntegrationTabs } from 'containers/ChannelManagement/Channel/ChannelIntegration/channel_integration_tabs';
import StatusUpdate from 'containers/ChannelManagement/Channel/ChannelStatusSelect/StatusUpdate';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import { useToggle } from 'utils/hooks';

type TabLabelProps = {
	label: string;
	done?: boolean;
	disabled?: boolean;
	action?: string;
};

export const TabLabel: React.FC<TabLabelProps> = ({
	label,
	done,
	disabled,
	action,
}) => {
	return (
		<div
			className={cx(
				action !== 'VIEW' &&
					styles.tabLabel + ' ' + { [styles.tabDisabled]: disabled }
			)}
		>
			{label}
		</div>
	);
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type Tabs = {
	channelCredentials?: any;
	channelCertificate?: any;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

export type TabStatus = PartialRecord<keyof Tabs, TabStatusValue>;

const initTabStatus: TabStatus = {
	channelCredentials: { disabled: false },
	channelCertificate: { disabled: false },
};

type Props = {
	action?: string;
	tabStatus?: TabStatus;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

const ChannelIntegration: React.FC<Props> = ({
	tabStatus = initTabStatus,
	onSubmit,
	...props
}) => {
	const reportsForm = useForm<PartnerReportsFormData>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: REPORTS_FORM_DEFAULT_VALUES,
	});

	const selectorName = () => {
		let nameSelect: string | undefined = undefined;
		if (selectedChannel) {
			nameSelect = selectedChannel?.name;
		}
		return nameSelect;
	};

	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};

	interface ListSelection {
		id: number;
		name: string;
		logo: string;
		status: string;
		tpaAccount: string;
	}

	const selectedChannel = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedChannel
	);
	const channelFormValues = useSelector(
		(state: ReducerStateType) => state.channels.channelFormValues
	);

	function findIndexName(selected: number): string {
		const idx = channelIntegrationTabs.findIndex(
			(tab) => tab.index == selected
		);
		return channelIntegrationTabs[idx].name;
	}

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission('products');
	const dispatch = useDispatch();
	const [showView, setShowView] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [selectedIndex, setSelectedIndex] = useState(0);

	// permission handling for editing partner details
	const hasEditPermission = useCallback(
		(scope: string) => {
			return hasUserPermission(productCategory, `edit.${scope}`);
		},
		[hasUserPermission, productCategory]
	);

	// permission handling for viewing partner details
	const hasViewPermission = useCallback(
		(scope: string) => {
			return hasUserPermission(productCategory, `view.${scope}`);
		},
		[hasUserPermission, productCategory]
	);

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const hasChannelUserPermission = useHasUserPermission('channels');

	const { hasViewChannelPublicCertificate, hasViewChannelClientCredentials } =
		useMemo(() => {
			return {
				hasViewChannelPublicCertificate: hasChannelUserPermission(
					'list.public.certificate'
				),
				hasViewChannelClientCredentials: hasChannelUserPermission(
					'list.client-credentials'
				),
			};
		}, [hasChannelUserPermission]);

	useEffect(() => {
		if (selectedTabIndex === 0 && !hasViewChannelClientCredentials) {
			return showPermissionErrorModal();
		} else if (selectedTabIndex === 1 && !hasViewChannelPublicCertificate) {
			setSelectedTabIndex(0);
			showPermissionErrorModal();
			return;
		}
	}, [selectedTabIndex]);

	return selectedChannel && selectedChannel.id !== undefined ? (
		<>
			<div className={styles.title}>{selectorName()}</div>
			<Grid container gutters="xx-small">
				<Grid column>
					<div className={styles.leftPanel}>
						<div className={styles.status}>
							<div className={styles.statusLabel}>Channel Status:</div>
							<div className={styles.statusField}>
								<StatusUpdate
									value={channelFormValues?.contractDetails?.status || ''}
									id={selectedChannel.id}
									idType="channels"
									disabled
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
			<Tabs
				className={styles.tabs}
				onSelect={(i: number) => {
					setSelectedTabIndex(i);
					const intergrationTab = findIndexName(i);
				}}
				selectedIndex={selectedTabIndex}
			>
				<TabsPanel
					label={
						<TabLabel
							label="Channel Client Credentials"
							done={tabStatus.channelCredentials?.finished || true}
							action={props.action}
						/>
					}
					disabled={tabStatus.channelCertificate?.disabled}
				>
					{hasViewChannelClientCredentials && (
						<div className={cx(styles.content)}>
							<ChannelCredentials
								initialValues={{
									basicInformation: undefined,
								}}
								{...props}
							/>
						</div>
					)}
				</TabsPanel>

				<TabsPanel
					label={
						<TabLabel
							label="Channel Public Certificate"
							done={tabStatus.channelCertificate?.finished || true}
							action={props.action}
						/>
					}
					disabled={tabStatus.channelCertificate?.disabled}
				>
					{hasViewChannelPublicCertificate && (
						<div className={cx(styles.content)}>
							<ChannelCertificate
								initialValues={{
									basicInformation: undefined,
								}}
								{...props}
							/>
						</div>
					)}
				</TabsPanel>
			</Tabs>
			{hasPermission && (
				<AccessDeniedModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				/>
			)}
		</>
	) : (
		<PanelPlaceholder {...panelPlaceholderProps} />
	);
};

export default ChannelIntegration;
