import React, { useState } from 'react';
import { startCase } from 'lodash';

import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';

import { formatDate } from 'utils/common';

import TableTab from './TableTab';
import { DetailsTabsProps } from '../types';
import styles from '../TransactionMgt.module.css';

const AUDIT_TRAIL_COLUMN = (formatJson: (data: string) => string) => [
	{
		Header: 'Date and Time',
		id: 'createdAt',
		sortable: false,
		width: '22%',
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Logged By',
		id: 'loggedBy',
		sortable: false,
		width: '11%',
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
		width: '11%',
	},
	{
		Header: 'Old Values',
		id: 'oldValues',
		sortable: false,
		width: '15%',
		accessor: ({ oldValues }) => formatJson(oldValues),
	},
	{
		Header: 'New Values',
		id: 'newValues',
		sortable: false,
		width: '15%',
		accessor: ({ newValues }) => formatJson(newValues),
	},
	{
		Header: 'Source IP',
		id: 'ip',
		sortable: false,
		width: '13%',
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		sortable: false,
		width: '13%',
	},
];

const API_LOGS_COLUMN = (formatJson: (data: string) => string) => [
	{
		Header: 'Transaction No.',
		id: 'trn',
		sortable: false,
		width: '20%',
	},
	{
		Header: 'Biller No.',
		id: 'billerNumber',
		sortable: false,
		width: '10%',
	},
	{
		Header: 'Data',
		id: 'data',
		sortable: false,
		width: '28%',
		accessor: ({ data }) => formatJson(data),
	},
	{
		Header: 'Created At',
		id: 'createdAt',
		sortable: false,
		width: '21%',
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Updated At',
		id: 'updatedAt',
		sortable: false,
		width: '21%',
		accessor: ({ updatedAt }) => formatDate(updatedAt),
	},
];

const TABS = (
	formatJson: (data: string) => string,
	selectedIndex: number,
	trn: string
) => [
	{
		title: 'API 3.0 Logs',
		component: selectedIndex === 0 && (
			<TableTab
				columns={API_LOGS_COLUMN(formatJson)}
				apiRoute={`/v2/bfa-admin-portal/transactions/${trn}/api3logs`}
			/>
		),
	},
	{
		title: 'Audit Logs',
		component: selectedIndex === 1 && (
			<TableTab
				columns={AUDIT_TRAIL_COLUMN(formatJson)}
				apiRoute={`/v2/bfa-admin-portal/transactions/${trn}/audit-trail`}
			/>
		),
	},
];

const DetailsTabs: React.FC<DetailsTabsProps> = ({ trn }) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(0);

	const formatJson = (data: string): string => {
		if (typeof data !== 'string') return '';
		const dateProperties = [
			'payment_at',
			'presentment_at',
			'updated_at',
			'created_at',
			'posted_at',
		];
		const action = Object.entries(JSON.parse(data))
			.map(([key, value]) => {
				if (value === null) {
					return `${startCase(key)}: null`;
				} else if (typeof value === 'object') {
					return `${startCase(key)}: { ${formatJson(JSON.stringify(value))} }`;
				} else if (dateProperties.includes(key)) {
					const date = String(value)?.split('+')[0] as string;
					return `${startCase(key)}: ${formatDate(date)}`;
				} else {
					return `${startCase(key)}: ${value}`;
				}
			})
			.join(`,\n`);
		return action;
	};

	return (
		<PrimaryTab
			tabs={TABS(formatJson, selectedIndex, trn)}
			onSelect={setSelectedIndex}
			className={styles.tabPanel}
		/>
	);
};

export default DetailsTabs;
