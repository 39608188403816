import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
	setHasError
} from 'redux/modules/reports';

import { useToggle } from 'utils/hooks';
import { useEffect } from 'react';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './index.module.css';

type Props = {
	handleRetry: () => void;
};

const ErrorMessageModal = ({ handleRetry }: Props): JSX.Element => {
	const dispatch = useDispatch();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const reports = useSelector((states: RootStateOrAny) => states.reports);
	const reportViewer = useSelector(
		(states: RootStateOrAny) => states.reportViewer
	);
	const viewReport = reportViewer.view_report;
	const auditTrial = reportViewer.audit_trail;
	const hasError = reports.error || viewReport.error || auditTrial.error;

	useEffect(() => {
		if (hasError) {
			showErrorModal()
		}
	}, [hasError]);

	const handleRetryFetching = () => {
		if (handleRetry instanceof Function) {
			handleRetry();
			hideErrorModal();
		}
	};

	return (
		<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
			<ErrorModalBody>
				<div></div>
				<div></div>
				<div className={styles.errorHeader}>Timeout Error!</div>
				<div className={styles.errorBody}>
					<div>A problem occured while loading the data.</div>
					<div>Please try again.</div>
				</div>
			</ErrorModalBody>
			<ErrorModalActions>
				<PrimaryButton
					onClick={() => handleRetryFetching()}
					className={styles.errorModalBtn}
				>
					Retry
				</PrimaryButton>
			</ErrorModalActions>
		</ErrorModal>
	);
};

export default ErrorMessageModal;
