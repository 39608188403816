import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import styles from './Approval.module.css';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { debounce as _debounce } from 'lodash';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from '../../CentralWalletSystem/Filter/Datepicker';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';
import { ProductApprovalListStatuses } from 'utils/lookup';
import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { formatDate } from 'utils/common';

const shouldDisableSubmission = (values) => {
	const { createdAt, updatedAt, status } = values;
	return ![createdAt, updatedAt, status].some((v) => v);
};

type ApprovalFilterFormData = {
	filter: {
		createdAt?: string;
		updatedAt?: string;
		status?: string;
		partnerName?: string;
	};
};

type Props = {
	onSubmit: (values: any, isSearch: boolean) => void;
	initialValues?: Partial<ApprovalFilterFormData['filter']>;
};

const ApprovalListFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = {},
}) => {
	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const {
		register,
		getValues,
		setValue,
		clearErrors,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<ApprovalFilterFormData>({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);

	const handleFilterSubmit = (values, isSearch = false) => {
		const createdAt = values['createdAt'],
			updatedAt = values['updatedAt'];
		values['createdAt'] = createdAt && formatDate(createdAt, 'yyyy-MM-DD');
		values['updatedAt'] = updatedAt && formatDate(updatedAt, 'yyyy-MM-DD');
		onSubmit(values || {}, isSearch);
	};

	const partnerName = useWatch({ control, name: 'filter.partnerName' });
	const allValues = useWatch({ name: 'filter', control });

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, partnerName: value }, true);
			}
		}, 500),
		[isDirty, isValid, partnerName]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			handleFilterSubmit(initialValues);
			setIsReset(true);
		}
	};

	useEffect(() => {
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(partnerName);
		}
	}, [partnerName]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	return (
		<>
			<Filter title="Approvals">
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={11}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={5} of={12}>
								<InputWithIcon
									name="filter.partnerName"
									control={control}
									placeholder="Search Partner Name"
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<div
						className={cx(
							'slds-grid',
							'slds-grid_align-spread',
							'slds-grid_vertical-align-start',
							styles.filters
						)}
					>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_4-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.createdAt"
								label="Date Created"
								placeholder="MM/DD/YYYY"
								errors={errors}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_4-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.updatedAt"
								label="Date Updated"
								placeholder="MM/DD/YYYY"
								errors={errors}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_4-of-12'
							)}
						>
							<FilterSelect
								name="filter.status"
								label="Status"
								control={control}
								options={ProductApprovalListStatuses}
							/>
						</div>
						<Grid
							container
							column
							size={4}
							of={12}
							verticalAlign="end"
							align="spread"
							className={styles.innerButtons}
						>
							<Grid
								column
								size={4}
								of={7}
								className={styles.clearFilterBtnContainer}
							>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={3} of={7}>
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter, true)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</div>
				</FilterInner>
			</Filter>
		</>
	);
};

export default ApprovalListFilter;
