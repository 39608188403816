import { filterArrayValues, handleArrayWithSpaceValues } from 'utils/filter';
import { isEmpty } from 'lodash';
import { decrypt } from 'utils/codeGenerator';
import {
	POST_TYPE_OPTIONS,
	MACHINE_LOCATION_OPTION,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';

export const remappedTerminalDataHandler = (
	data: any,
	type: string | undefined,
	result: string | undefined,
	reportsField: string | undefined
) => {
	if (type == 'terminal') {
		if (!data?.auditDetail) {
			return;
		}
		const auditDetail = data?.auditDetail;
		if (!auditDetail) {
			return;
		}

		if (result === 'status') {
			if (!data?.auditDetail) {
				return;
			}
			const auditDetail = data.auditDetail;
			if (!auditDetail) {
				return;
			}

			const status = auditDetail.status;
			if (!status) {
				return;
			}

			const statusArray: any[] = [];
			statusArray.push({
				field_name: 'Status',
				old_values: status.oldValue,
				new_values: status.newValue,
			});

			if (statusArray.length > 0) {
				return statusArray;
			} else {
				return;
			}
		}

		if (result === 'terminals') {
			if (!data?.auditDetail) {
				return;
			}
			const auditDetail = data.auditDetail;
			if (!auditDetail) {
				return;
			}

			const terminals = auditDetail.terminals;
			if (!terminals) {
				return;
			}

			const terminalsArray: any[] = [];

			const fieldsMapping = {
				channelAccountName: 'Channel Account Name',
				channelTpaAccount: 'Channel Account Code',
				channelTransactionType: 'Channel Transaction Type',
				channelType: 'Channel Type',
				branchName: 'Branch Name',
				branchCode: 'Branch Code',
				tpaId: 'TPA ID',
				machineLocation: 'Machine Location',
				settlementSetup: 'Settlement Setup',
				spmNumber: 'SPM Number',
				activationDate: 'Activation Date',
				posType: 'POS Type',
				meralcoPaymentCenterCode: 'Meralco Payment Center Code',
				code: 'code',
				name: 'name',
				description: 'description',
				remarks: 'Remarks',
			};

			for (const [key, value] of Object.entries(fieldsMapping)) {
				const fieldValue = terminals[key];
				if (
					fieldValue &&
					key !== 'code' &&
					key !== 'name' &&
					key !== 'description'
				) {
					terminalsArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				} else if (key === 'code' && terminals.code) {
					if (terminals.code.oldValue != '-') {
						terminalsArray.push({
							field_name: fieldsMapping.meralcoPaymentCenterCode,
							old_values:
								terminals.code.oldValue +
								': ' +
								terminals.name.oldValue +
								' - ' +
								terminals.description.oldValue,
							new_values:
								terminals.code.newValue +
								': ' +
								terminals.name.newValue +
								' - ' +
								terminals.description.newValue,
						});
					} else {
						terminalsArray.push({
							field_name: fieldsMapping.meralcoPaymentCenterCode,
							old_values: terminals.code.oldValue,
							new_values:
								terminals.code.newValue +
								': ' +
								terminals.name.newValue +
								' - ' +
								terminals.description.newValue,
						});
					}
				}
			}

			// if (terminalsArray.length > 0) {
			// 	return terminalsArray;
			// } else {
			// 	return;
			// }

			if (terminalsArray.length > 0) {
				terminalsArray.forEach((entry) => {
					if (entry.field_name === 'POS Type') {
						if (entry.new_values !== '-')
							entry.new_values = POST_TYPE_OPTIONS.find(
								(item) => item.value === entry.new_values
							)?.label;
						if (entry.old_values !== '-')
							entry.old_values = POST_TYPE_OPTIONS.find(
								(item) => item.value === entry.old_values
							)?.label;
					}

					if (entry.field_name === 'Machine Location') {
						if (entry.new_values !== '-')
							entry.new_values = MACHINE_LOCATION_OPTION.find(
								(item) => item.value === entry.new_values
							)?.label;
						if (entry.old_values !== '-')
							entry.old_values = MACHINE_LOCATION_OPTION.find(
								(item) => item.value === entry.old_values
							)?.label;
					}
				});
				return terminalsArray;
			} else {
				return;
			}
		}

		if (result === 'integration') {
			if (!data?.auditDetail) {
				return;
			}
			const auditDetail = data.auditDetail;
			if (!auditDetail) {
				return;
			}

			const tokenId = auditDetail.tokenId;
			if (!tokenId) {
				return;
			}

			const tokenIdArray: any[] = [];
			tokenIdArray.push({
				field_name: 'Token ID Registration',
				old_values: tokenId.oldValue,
				new_values: tokenId.newValue,
			});

			if (tokenIdArray.length > 0) {
				return tokenIdArray;
			} else {
				return;
			}
		}
	} else {
		return;
	}

	return;
};
