import WalletListFilter from './WalletListFilter';
import WalletListTable, {
	channelLevelMap,
	STATUS,
	StatusType,
} from './WalletListTable';
import HTTP from '../../../../helpers/ApiClient';
import { useEffect, useMemo, useState } from 'react';
import FullPageLoader from '../../../../components/Loader/FullPageLoader/FullPageLoader';
import { useToggle } from '../../../../utils/hooks';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from '../../../../components/Modal/ErrorModal';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import styles from './WalletList.module.css';
import { formatDate, resolveValue, formatCash } from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { showAccessDeniedModal } from 'redux/modules/access';
import { ReducerStateType } from 'redux/modules/reducers';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { Button, IconSettings } from '@salesforce/design-system-react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { viewWalletDataActionCreator } from '../../../../redux/modules/viewWalletDetails';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ConfirmModal from 'components/Modal/ConfirmModal';
import WalletDetails from './WalletDetails';
import OutlineButton from 'components/Buttons/OutlineButton';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { calibrateDate } from 'utils/date';
const CREATE_CHANNEL_WALLET_SCOPE_NAME = 'bip.wallet.approval.adjustment';

const DEACTIVATE_WALLET_PERMISSION = {
	scope: 'bip.wallet.deactivate',
	resource: '/wallets/{id}/deactivate',
};

const ACTIVATE_WALLET_PERMISSION = {
	scope: 'bip.wallet.activate',
	resource: '/v1/wallets/{id}/activate',
};

type GetWalletListArgs = {
	page?: number;
	pageSize?: number;
	limit?: number;
	sortBy?: string;
	sort?: string;
	dateCreatedFrom?: string | null;
	dateCreatedTo?: string | null;
	walletType?: string | null;
	partnerName?: string | null;
	channelLevel?: string | null;
	status?: string;
	walletId?: string;
	serviceType?: string;
	transactionNumber?: string;
};

async function getWalletList(values: GetWalletListArgs = {}) {
	const {
		page = 1,
		limit = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		walletType,
		partnerName,
		status,
		channelLevel,
		walletId,
	} = values;

	const params = {
		sortBy,
		sort,
		page,
		limit,
		dateCreatedFrom,
		dateCreatedTo,

		partnerName: resolveValue(partnerName),
		channelLevel,
		status: resolveValue(status),
		walletId: resolveValue(walletId),
		type: resolveValue(walletType),
	};
	const result = await HTTP.get('/v1/wallets', {
		params,
	});

	return result.data;
}

const initialValues = {
	dateFrom: undefined,
	dateTo: undefined,
	channelName: '',
	channelLevel: undefined,
	type: undefined,
	status: '',
	transactionNumber: '',
	walletId: '',
};

type FetchWalletListArgs = Omit<
	GetWalletListArgs,
	'dateCreatedFrom' | 'dateCreatedTo'
> & {
	dateFrom?: GetWalletListArgs['dateCreatedFrom'];
	dateTo?: GetWalletListArgs['dateCreatedTo'];

	type?: walletType;
	channelLevel?: any;
};

type walletType = {
	prefunded: string;
	bonded: string;
	cws: string;
};

const walletTypeMap: walletType = {
	prefunded: 'Channel Wallet - Prefunded',
	bonded: 'Channel Wallet - Bonded',
	cws: 'Product Wallet',
};

function walletListColumnConfig({
	handleView,
	handleShowNoFundModal,
	handleShowReactivateModal,
	handleShowDeactivateModal,
	deactivateWalletScope,
	dispatch,
}) {
	const formatDate = (date) => moment(date).format('MM/DD/YYYY hh:mm:ss A');
	const channelLevelMap = {
		TPA_ACCOUNT: 'TPA Account',
		BRANCH: 'Branch',
		TPA_ID: 'TPA ID',
	};
	const walletTypeMap: walletType = {
		prefunded: 'Channel Wallet - Prefunded',
		bonded: 'Channel Wallet - Bonded',
		cws: 'Product Wallet',
	};

	type StatusType = {
		name: string;
		code: string;
		className: string;
	};
	const STATUS: StatusType[] = [
		{
			name: 'Active',
			code: 'ACTIVE',
			className: styles.statusActive,
		},
		{
			name: 'Deactivated',
			code: 'DEACTIVATED',
			className: styles.statusDeactivated,
		},
		{
			name: 'Inactive',
			code: 'INACTIVE',
			className: styles.statusInactive,
		},
	];

	const onHandleActivate = (row: any) => {
		if (row.wallet_balance <= row.threshold_amount) {
			handleShowNoFundModal(row);
		} else {
			handleShowReactivateModal(row);
		}
	};

	const onHandleDeactivate = (row: any) => {
		if (!deactivateWalletScope) {
			dispatch(showAccessDeniedModal());
		} else {
			handleShowDeactivateModal(row);
		}
	};

	const handleChildClick = (e) => {
		e.stopPropagation();

		//needed to prevent double onClick
	};

	return [
		{
			Header: 'Date Created',
			accessor: ({ date_created }) => formatDate(date_created),
			id: 'date_created',
			width: '15%',
			sortable: true,
		},
		{
			Header: 'Created By',
			id: 'created_by',
			sortable: true,
		},
		{
			Header: 'Wallet ID',
			id: 'wallet_identifier',
			width: '13%',
			Cell: ({ value }) => <div className={styles.walletId}>{value}</div>,
			sortable: true,
		},
		{
			Header: 'Wallet Type',
			id: 'type',
			accessor: ({ type }) => walletTypeMap[type] || type,
			width: '15%',
			sortable: true,
			Cell: ({ row: { original } }) => {
				switch (original.type) {
					case 'bonded':
						return <div>Channel Wallet - Bonded</div>;
					case 'prefunded':
						return <div>Channel Wallet - Prefunded</div>;
					default:
						return <div>Product Wallet</div>;
				}
			},
		},
		{
			Header: 'Partner Name',
			id: 'partner_name',
		},
		{
			Header: 'Channel Level',
			id: 'channel_level',
			accessor: ({ channel_level }) =>
				channelLevelMap[channel_level] || channel_level,
			width: '12%',
			sortable: true,
			Cell: ({ row: { original } }) => {
				switch (original.type) {
					case 'cws':
						return <div>-</div>;
					default:
						return <div>{original.channel_level}</div>;
				}
			},
		},
		{
			Header: 'Wallet Balance',
			accessor: ({ wallet_balance }) => formatCash(wallet_balance, 2),
			id: 'wallet_balance',
			width: '12%',
			sortable: true,
			numeric: false,
		},
		{
			Header: 'Threshold Amount',
			accessor: ({ threshold_amount }) => formatCash(threshold_amount, 2),
			id: 'threshold_amount',
			width: '12%',
			numeric: false,
			sortable: true,
		},

		{
			Header: 'Status',
			Cell: ({ cell, value }) => {
				const match = STATUS.find(
					({ code }) => code.toLowerCase() === value.toLowerCase()
				) as StatusType;
				const className = match ? match.className : '';
				return (
					<div {...cell.getCellProps()} className={className}>
						{match.name}
					</div>
				);
			},
			id: 'status',
			sortable: true,
		},
		{
			Header: 'Actions',
			id: 'actions',
			accessor: (values) => values,
			Cell: ({ value, row }) => {
				return (
					<>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx(styles.actionCell, 'slds-grid')}>
								<div className="slds-col slds-grid slds-grid_align-left slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.crossIcon}
										iconCategory="utility"
										iconName="picklist_type"
										iconSize="small"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn)}
										onClick={() => {
											handleView(value.id);
										}}
										variant="icon"
									/>
									<div className={styles.toggleButtonContainer}>
										<label
											className="slds-checkbox_toggle slds-grid"
											onClick={() =>
												row.original.status == 'ACTIVE'
													? onHandleDeactivate(row.original)
													: onHandleActivate(row.original)
											}
											onKeyDown={()=>{return}}
										>
											<input
												disabled={
													row.original.wallet_balance <=
													row.original.threshold_amount
												}
												checked={row.original.status == 'ACTIVE'}
												onClick={handleChildClick}
												type="checkbox"
												name="checkbox-toggle-16"
												value="checkbox-toggle-16"
												aria-describedby="checkbox-toggle-16"
											/>
											<span
												id="checkbox-toggle-16"
												className="slds-checkbox_faux_container"
												aria-live="assertive"
											>
												<span
													className={cx(
														'slds-checkbox_faux',
														styles.toggleButton
													)}
												></span>
												<span className="slds-checkbox_on"></span>
												<span className="slds-checkbox_off"></span>
											</span>
										</label>
									</div>
								</div>
							</div>
						</IconSettings>
					</>
				);
			},
		},
	];
}

const WALLET_LIST_COLUMNS: Array<any> = [
	{
		Header: 'Date Created',
		accessor: ({ date_created }) => formatDate(date_created),
		id: 'date_created',
		width: '15%',
		sortable: true,
	},
	{
		Header: 'Created By',
		id: 'created_by',
		sortable: true,
	},
	{
		Header: 'Wallet ID',
		id: 'wallet_identifier',
		width: '13%',
		Cell: ({ value }) => <div className={styles.walletId}>{value}</div>,
	},
	{
		Header: 'Wallet Type',
		id: 'type',
		accessor: ({ type }) => walletTypeMap[type] || type,
		width: '15%',
		sortable: true,
		Cell: ({ row: { original } }) => {
			switch (original.type) {
				case 'bonded':
					return <div>Channel Wallet - Bonded</div>;
				case 'prefunded':
					return <div>Channel Wallet - Prefunded</div>;
				default:
					return <div>Product Wallet</div>;
			}
		},
	},
	{
		Header: 'Partner Name',
		id: 'partner_name',
	},
	{
		Header: 'Channel Level',
		id: 'channel_level',
		accessor: ({ channel_level }) =>
			channelLevelMap[channel_level] || channel_level,
		width: '12%',
		sortable: true,
		Cell: ({ row: { original } }) => {
			switch (original.type) {
				case 'cws':
					return <div>-</div>;
				default:
					return <div>{original.channel_level}</div>;
			}
		},
	},
	{
		Header: 'Wallet Balance',
		id: 'wallet_balance',
		width: '12%',
		sortable: true,
		numeric: true,
	},
	{
		Header: 'Threshold Amount',
		id: 'threshold_amount',
		width: '12%',
		numeric: true,
	},

	{
		Header: 'Status',
		Cell: ({ cell, value }) => {
			const match = STATUS.find(
				({ code }) => code.toLowerCase() === value.toLowerCase()
			) as StatusType;
			const className = match ? match.className : '';

			return (
				<div {...cell.getCellProps()} className={className}>
					{match.name}
				</div>
			);
		},
		id: 'status',
	},
	{
		Header: () => <div className={styles.actionHeader}>Actions</div>,
		id: 'actions',
		accessor: (values) => values,
		Cell: () => {
			return (
				<>
					<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
						<div className={cx(styles.actionCell, 'slds-grid')}>
							<div className="slds-col slds-grid slds-grid_align-left slds-grid_vertical-align-center">
								<Button
									assistiveText={{ icon: 'Icon Bare Small' }}
									className={styles.crossIcon}
									iconCategory="utility"
									iconName="picklist_type"
									iconSize="small"
									iconVariant="bare"
									iconClassName={cx(styles.iconBtn)}
									variant="icon"
								/>
							</div>
						</div>
					</IconSettings>
				</>
			);
		},
	},
];

const WalletList: React.FC<{
	showModal?: any;
	viewWalletDataActionCreator?: any;
}> = ({ showModal, viewWalletDataActionCreator }) => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [filterParams, setFilterParams] = useState<GetWalletListArgs>({});
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState('desc');
	const [isSearch, setIsSearch] = useState(false);
	const [selectedWallet, setSelectedWallet] = useState<any>(null);

	const history = useHistory();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isModalShown,
		valueOn: showModalOn,
		valueOff: hideModal,
	} = useToggle();

	const {
		value: isNoFundModalShown,
		valueOn: showNoFundModal,
		valueOff: hideNoFundModal,
	} = useToggle();

	const {
		value: isReactivateModalShown,
		valueOn: showReactivateModal,
		valueOff: hideReactivateModal,
	} = useToggle();

	const {
		value: isDeactivateModalShown,
		valueOn: showDeactivateModal,
		valueOff: hideDeactivateModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isSuccessDeactivationModalShown,
		valueOn: showSuccessDeactivationModal,
		valueOff: hideSuccessDeactivationModal,
	} = useToggle();

	const dispatch = useDispatch();

	const [lastParams, setLastParams] = useState({});

	const [errorDetails, setErrorDetails] = useState({
		isOpen: false,
		body: <></>,
		actions: <></>,
		onClose: () => {},
	});

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const createChannelWalletScope = useMemo(
		() =>
			scopes.find(({ scope }) => scope === CREATE_CHANNEL_WALLET_SCOPE_NAME),
		[scopes]
	);

	const deactivateWalletScope = useMemo(
		() =>
			scopes.find(({ scope }) => scope === DEACTIVATE_WALLET_PERMISSION.scope),
		[scopes]
	);

	const fetchWallets = async (
		values: FetchWalletListArgs = {},
		retry = false
	) => {
		const doRequest = async (p) => {
			try {
				showLoading();
				setLastParams(p);
				const result = await getWalletList(p);
				const { meta } = result;
				setData(result.result);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e: any) {
				if (e.response?.status === 403) {
					dispatch(showAccessDeniedModal());
				} else {
					setErrorDetails({
						isOpen: true,
						body: (
							<>
								<div className={styles.errorBody}>
									<div>A problem occurred while</div>
									<div>loading the wallet list.</div>
								</div>
								<div className={styles.errorFooter}>Please try again</div>
							</>
						),
						actions: (
							<>
								<ErrorModalActions>
									<PrimaryButton
										fullWidth
										onClick={handleRetry}
										className={
											'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
										}
									>
										Retry
									</PrimaryButton>
								</ErrorModalActions>
							</>
						),
						onClose: () => {
							setErrorDetails({
								...errorDetails,
								isOpen: false,
							});
						},
					});
				}
			} finally {
				hideLoading();
				setIsSearch(false);
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		} = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};
		await doRequest(params);
	};

	useEffect(() => {
		if (showModal) showModalOn();
		else hideModal();
	}, [showModal]);

	useEffect(() => {
		fetchWallets();
	}, []);

	const handleFilterSubmit = async (values, _isSearch) => {
		const {
			channelName = {},
			walletType,
			dateFrom,
			dateTo,
			channelLevel = null,
			status,
			walletId,
		} = values;

		const params = {
			partnerName: channelName.label,
			walletType,
			dateFrom,
			dateTo,
			channelLevel,
			status,
			walletId,
			page: 1,
			pageSize,
		};

		setPage(1);
		setFilterParams(params);
		await fetchWallets(params);
		setIsSearch(_isSearch);
	};

	const handlePageChange = async (_page, _pageSize) => {
		const { page: _, pageSize: __, ...rest } = filterParams;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await fetchWallets({ page: _page, limit: _pageSize, ...rest });
		}
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		await fetchWallets({ sortBy, sort, ...filterParams });
	};

	const handleRetry = () => {
		setErrorDetails({
			...errorDetails,
			isOpen: false,
		});

		if (lastParams) {
			fetchWallets(lastParams, true);
		}
	};

	const onShowNoFundModal = (e) => {
		if (!verifyPermission(scopes, ACTIVATE_WALLET_PERMISSION)) {
			dispatch(showAccessDeniedModal());
			return;
		}

		setSelectedWallet(e);
		showNoFundModal();
	};
	const onShowReactivateModal = (e) => {
		if (!verifyPermission(scopes, ACTIVATE_WALLET_PERMISSION)) {
			dispatch(showAccessDeniedModal());
			return;
		}
		setSelectedWallet(e);
		showReactivateModal();
	};

	const onShowDeactivateModal = (e) => {
		if (!verifyPermission(scopes, DEACTIVATE_WALLET_PERMISSION)) {
			dispatch(showAccessDeniedModal());
			return;
		}
		setSelectedWallet(e);
		showDeactivateModal();
	};

	const handleDone = () => {
		hideSuccessModal();
		handleRetry();
	};
	const handleDonedeactivate = () => {
		hideSuccessDeactivationModal();
		handleRetry();
	};

	const handleReactivate = () => {
		setErrorDetails({
			...errorDetails,
			isOpen: false,
		});

		HTTP.put(`/v1/wallets/${selectedWallet?.id}/activate`, {})
			.then((res) => {
				hideReactivateModal();
				showSuccessModal();
			})
			.catch((err) => {
				hideReactivateModal();

				setErrorDetails({
					isOpen: true,
					body: (
						<>
							<div className={cx(styles.bodyHeader, 'slds-m-top_large')}>
								<div>
									Failed to reactivate Wallet{' '}
									{selectedWallet?.wallet_identifier}
								</div>
							</div>
							<div className={cx(styles.bodyText, 'slds-m-top_medium')}>
								Something went wrong with your request.
								<br />
								Would you like to retry?
							</div>
						</>
					),
					actions: (
						<>
							<ErrorModalActions>
								<div className="slds-align_absolute-center">
									<OutlineButton
										onClick={() =>
											setErrorDetails({
												...errorDetails,
												isOpen: false,
											})
										}
										btnClassStyle="outline2"
										className={
											'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
										}
									>
										Cancel
									</OutlineButton>
									<PrimaryButton
										onClick={handleReactivate}
										className={
											'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
										}
									>
										Retry
									</PrimaryButton>
								</div>
							</ErrorModalActions>
						</>
					),
					onClose: () => {
						setErrorDetails({
							...errorDetails,
							isOpen: false,
						});
					},
				});
				return err;
			});
	};

	const handleDeactivate = () => {
		setErrorDetails({
			...errorDetails,
			isOpen: false,
		});

		HTTP.put(`/v2/wallets/${selectedWallet?.id}/deactivate`, {})
			.then((res) => {
				hideDeactivateModal();
				showSuccessDeactivationModal();
			})
			.catch((err) => {
				hideDeactivateModal();

				setErrorDetails({
					isOpen: true,
					body: (
						<>
							<div className={cx(styles.bodyHeader, 'slds-m-top_medium')}>
								<div>
									Failed to deactivate Wallet ID{' '}
									{selectedWallet?.wallet_identifier}
								</div>
							</div>
							<div className={cx(styles.bodyText, 'slds-m-top_medium')}>
								Something went wrong with your request.
								<br />
								{err.response && <div>Error: {err.response.data.message}</div>}
								Would you like to retry?
							</div>
						</>
					),
					actions: (
						<>
							<ErrorModalActions>
								<div className="slds-align_absolute-center">
									<OutlineButton
										onClick={() =>
											setErrorDetails({
												...errorDetails,
												isOpen: false,
											})
										}
										btnClassStyle="outline2"
										className={
											'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
										}
									>
										Cancel
									</OutlineButton>
									<PrimaryButton
										onClick={handleDeactivate}
										className={
											'slds-m-top_small slds-p-left_x-large slds-p-right_x-large'
										}
									>
										Retry
									</PrimaryButton>
								</div>
							</ErrorModalActions>
						</>
					),
					onClose: () => {
						setErrorDetails({
							...errorDetails,
							isOpen: false,
						});
					},
				});
				return err;
			});
	};

	return (
		<>
			<WalletListFilter
				initialValues={initialValues}
				page={page}
				pageSize={pageSize}
				sortBy={sortBy}
				sort={sort}
				onSubmit={handleFilterSubmit}
				canCreateChannelWallet={!!createChannelWalletScope}
			/>
			<WalletListTable
				page={page}
				data={data}
				pageSize={pageSize}
				count={count}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sortBy={sortBy}
				sort={sort}
				filterParams={filterParams}
				isSearch={isSearch}
				columns={walletListColumnConfig({
					handleView: viewWalletDataActionCreator,
					handleShowNoFundModal: onShowNoFundModal,
					handleShowReactivateModal: onShowReactivateModal,
					handleShowDeactivateModal: onShowDeactivateModal,
					deactivateWalletScope: deactivateWalletScope,
					dispatch: dispatch,
				})}
			/>
			{isModalShown && (
				<WalletDetails open={isModalShown} onClose={hideModal} />
			)}
			<FullPageLoader
				open={isLoading}
				message="Please wait while wallet list is being loaded"
			/>

			<ErrorModal open={errorDetails.isOpen} onClose={errorDetails.onClose}>
				<ErrorModalBody>{errorDetails.body}</ErrorModalBody>
				<ErrorModalActions>{errorDetails.actions}</ErrorModalActions>
			</ErrorModal>

			<ErrorModal open={isNoFundModalShown} onClose={hideNoFundModal}>
				<ErrorModalBody>
					<div className={styles.errorBody}>
						<div>
							Failed to reactivate Wallet {selectedWallet?.wallet_identifier}
						</div>
					</div>
					<div className={styles.errorFooter}>
						You have insufficient wallet balance.
						<br />
						Please click <b>"Add Funds"</b> to replenish wallet balance.
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={() => {
							history.push('/wallet-system/management/replenishment#Add');
						}}
						className={styles.retryBtn}
					>
						Add Funds
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>

			<ConfirmModal
				open={isReactivateModalShown}
				disableClose={false}
				onClose={hideReactivateModal}
				headerText="Reactivate Wallet"
				bodyText={[
					() => (
						<>
							<div>
								Are you sure you want to reactivate{' '}
								<b>Wallet {selectedWallet?.wallet_identifier}</b>?
							</div>
						</>
					),
				]}
				cancelButton={{
					name: 'Cancel',
					event: hideReactivateModal,
				}}
				confirmButton={{
					name: 'Reactivate',
					event: handleReactivate,
				}}
				disableOnClick={true}
			/>

			<ConfirmModal
				open={isDeactivateModalShown}
				disableClose={false}
				onClose={hideDeactivateModal}
				headerText="Deactivate Wallet"
				bodyText={[
					() => (
						<>
							<div>
								Are you sure you want to deactivate wallet id{' '}
								<b> {selectedWallet?.wallet_identifier}</b>?
								<br />
								No transaction will be processed once it is deactivate.
							</div>
						</>
					),
				]}
				cancelButton={{
					name: 'Cancel',
					event: hideDeactivateModal,
				}}
				confirmButton={{
					name: 'Deactivate',
					event: handleDeactivate,
				}}
				disableOnClick={true}
			/>

			<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div>Success!</div>
						<div className={styles.subtext}>
							You've reactivated Wallet {selectedWallet?.wallet_identifier}
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={handleDone}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<SuccessModal
				open={isSuccessDeactivationModalShown}
				onClose={hideSuccessDeactivationModal}
			>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.success}>Success!</div>
						<div className={styles.subtext}>
							You've deactivated Wallet ID {selectedWallet?.wallet_identifier}
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={handleDonedeactivate}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
		</>
	);
};

export default connect(
	(state: any) => ({
		showModal: state.viewWalletDetails.showModal,
	}),
	{ viewWalletDataActionCreator }
)(WalletList);
