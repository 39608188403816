import Section, { SectionRow } from 'components/Section/Section';
import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextFieldChannel';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelContactDetails/ChannelContactDetails.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import Button from 'components/Buttons/Button';
import {
	ContactNumberTagInput,
	EmailTagInput,
	TelephoneNumberTagInput,
} from 'components/TagInput/TagInputChannel';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import Grid from 'components/Grid/Grid';
import { orderBy as _orderBy, uniqBy as _uniqBy } from 'lodash';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessChannelModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import { useState } from 'react';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { useMemo, ReactNode } from 'react';
import {
	ChannelContactDetailsFields,
	TChannelContactDetails,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelContactDetails/types';
import { useEffect } from 'react';
import {
	LocalChannelAddress,
	TChannelAddress,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelAddress/types';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const ChannelContactDetails: React.FC<{
	maxEntries?: number;
	disabled?: boolean;
}> = ({ maxEntries, disabled }) => {
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const name = 'primaryInfo.channelContactDetails';
	const { control, setValue } = useFormContext();

	const watchFields = useWatch({
		control,
		name,
	}) as ChannelContactDetailsFields;

	const watchAddresses = useWatch({
		control,
		name: 'channelContacDetails',
		defaultValue: [],
	});

	const [activeEntry, setActiveEntry] = useState<
		{ index: number; value: TChannelContactDetails } | undefined
	>(undefined);
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});
	const controlledFields = fields.map((field, i) => ({
		...field,
		...watchFields[i],
	}));

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isContactDetailsErrorModalOpen,
		valueOn: openContactDetailsErrorModal,
		valueOff: closeContactDetailsErrorModal,
	} = useToggle();

	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			openContactDetailsErrorModal();
			return;
		}

		append({});
	};

	const handleConfirmDeleteEntry = (
		index: number,
		value: TChannelContactDetails
	) => {
		setActiveEntry({ index, value });
		openConfirmDeleteModal();
	};

	const handleDeleteAddress = (index: any | undefined) => {
		activeEntry && remove(activeEntry.index);
		closeConfirmDeleteModal();

		showSuccessMessage(
			<div className={styles.successModalText}>
				Channel Contact Details {index} <br />
				successfully deleted.
			</div>
		);
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const areaHandledCities = useMemo<SelectOption[]>(() => {
		const cities = watchAddresses
			.filter((address: TChannelAddress) => {
				return address.locationBase === 'LOCAL';
			})
			.map(
				(address: LocalChannelAddress & { cityObj: SelectOption }) =>
					address?.cityObj
			)
			.filter((c: any) => !!c);
		return _orderBy(
			_uniqBy<SelectOption>(cities, 'value'),
			[(city) => city.label.toLowerCase()],
			['asc']
		);
	}, [watchAddresses]);

	// useEffect(() => {
	// controlledFields.forEach((f, i) => {
	// 	if (f.areaHandled) {
	// 		const selectedEntryIsRemoved = areaHandledCities.find(
	// 			(a) => a.value === f.areaHandled
	// 		);
	// 		if (selectedEntryIsRemoved) {
	// 			setValue(`${name}.${i}.areaHandled`, '');
	// 		}
	// 	}
	// });
	// }, [areaHandledCities]);

	return (
		<>
			{controlledFields.map((field, index) => {
				const n = `${name}.${index}`;
				return (
					<Section
						key={field.id}
						title={() => (
							<Grid container gutters align="spread">
								<Grid column size={1} of={3}>
									Channel Contact Details
									{index > 0 && ` ${index + 1}`}
								</Grid>
								<Grid column>
									{index === 0 ? (
										<Button
											fullWidth
											onClick={handleAddEntry}
											className={styles.addButton}
											disabled={disabled}
										>
											<AddIcon /> Add Contact Details
										</Button>
									) : (
										<Button
											onClick={() => handleConfirmDeleteEntry(index, field)}
											disabled={disabled}
										>
											<DeleteIcon />
										</Button>
									)}
								</Grid>
							</Grid>
						)}
					>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									label="Name"
									required
									name={`${n}.name`}
									control={control}
									defaultValue={field.name}
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									label="Department"
									name={`${n}.department`}
									control={control}
									defaultValue={field.department}
									disabled={disabled}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<TextField
									label="Position"
									name={`${n}.position`}
									control={control}
									defaultValue={field.position}
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={2}>
								<EmailTagInput
									required
									control={control}
									name={`${n}.emailAddress`}
									defaultValue={field.emailAddress}
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TelephoneNumberTagInput
									control={control}
									name={`${n}.telNo`}
									disabled={disabled}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<ContactNumberTagInput
									control={control}
									required
									name={`${n}.mobileNo`}
									secondarySubtext="* Mobile Number should start with +639"
									disabled={disabled}
								/>
							</Grid>
						</SectionRow>
					</Section>
				);
			})}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			<ErrorModal
				open={isContactDetailsErrorModalOpen}
				onClose={closeContactDetailsErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>Failed to Add Channel Contact Details</ErrorText>
					<ErrorSubText>
						You have reached max number <br></br> of accepted Channel Contact
						Details
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={closeContactDetailsErrorModal}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			{activeEntry && (
				<ConfirmationModal
					isOpen={isConfirmDeleteModalOpen}
					heading={
						<div className={styles.headerClass}>
							{activeEntry &&
								`Delete Channel Contact Details ${activeEntry.index + 1}`}
						</div>
					}
					message={
						<>
							Are you sure you want to delete{' '}
							<span className={styles.confirmTextName}>
								Channel Contact Details {activeEntry.index + 1}?
							</span>
						</>
					}
					onClose={() => {
						setActiveEntry(undefined);
						closeConfirmDeleteModal();
					}}
					onCancelBtnClick={closeConfirmDeleteModal}
					onConfirmBtnClick={() => handleDeleteAddress(activeEntry.index + 1)}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Delete"
				/>
			)}
		</>
	);
};

export default ChannelContactDetails;
