import { Controller } from 'react-hook-form';
import cx from 'classnames';
import styles from './Filter.module.css';
import Select from '../../../components/Inputs/Select/Select';

const FilterSelect = ({
	name,
	label,
	control,
	onChange = () => {},
	options,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<>
						<label
							className={cx(
								styles.selectLabel,
								'slds-form-element__label'
							)}
							htmlFor={name}
						>
							{label}
						</label>
						<Select
							placeholder={"Select" + " " +label}
							onChange={onChange}
							options={options}
							controlled
							{...field}
						/>
					</>
				)}
			/>
		</>
	);
};

export default FilterSelect;
