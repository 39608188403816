import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import {
	Control,
	Path,
	PathValue,
	UseFormSetValue,
	useWatch,
} from 'react-hook-form';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { rate_type } from 'constants/rate_type';
import { ContractDetailsFormData } from '../../ContractDetails';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import AmountField from 'components/Inputs/TextField/AmountField';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
};

const DepositPenalty = <T extends ContractDetailsFormData>({
	control,
	disabled,
	setValue,
}: Props<T>): JSX.Element => {
	const name = 'product_contract' as const;
	const depositPenaltyType = useWatch({
		control,
		name: `${name}.deposit_penalty_type` as Path<T>,
	});
	return (
		<Section title="Deposit Penalty">
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Deposit Penalty Type"
						placeholder="Select Deposit Penalty Type"
						name={`${name}.deposit_penalty_type`}
						control={control}
						disabled={disabled}
						options={rate_type}
						onChange={(selected) =>
							selected != depositPenaltyType &&
							setValue(
								`${name}.deposit_penalty_rate` as Path<T>,
								'' as PathValue<T, Path<T>>
							)
						}
					/>
				</Grid>
				{depositPenaltyType && (
					<Grid column size={1} of={3}>
						<AmountField
							required
							label="Deposit Penalty Rate"
							placeholder={`${'0.00'}${
								depositPenaltyType == 'PERCENTAGE' ? '%' : ''
							}`}
							name={`${name}.deposit_penalty_rate`}
							control={control}
							disabled={disabled}
							formatValue={TextFieldFormatter.amountFormat}
						/>
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default DepositPenalty;
