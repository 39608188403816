import folderIcon from 'assets/icons/ic-folder.svg';
import cx from 'classnames';
import styles from './index.module.css';
type ReportPlaceholderProps = { display?: number };

export default function ReportPlaceholder(
	{ display = 1 }: ReportPlaceholderProps
): JSX.Element {
	return (
		<div
			id="report-placeholder"
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				{display === 1 && <>
					<div className={styles.placeholderTitle}>
						No Selected Report Name
					</div>
					<div className={styles.placeholderSubTitle}>
						Please select a report name.
					</div>
				</>}
				{display === 2 && <>
					<div className={styles.placeholderTitle}>
						No Reports Available
					</div>
				</>}
			</div>
		</div>
	);
}