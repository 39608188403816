import {
	Input,
	Textarea,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import _ from 'lodash';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './TextField.module.css';
import { isEmpty } from 'lodash';

type Props = {
	label: string;
	placeholder?: string;
	error?: string;
	className?: string;
	labelClassName?: string;
	formatValue?: (value: any) => any;
	required?: boolean;
	fullWidth?: boolean;
	optional?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	isNoDashAllowed?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
	defaultValue?: any;
	name?: string;
	onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
	multiLine?: boolean;
	inputRef?: any;
	id?: any;
	resizable?: boolean;
	onBlur?: (e) => void;
	onKeyPress?: (e) => void;
	onFocus?: () => any;
	rows?: number;
	autoFocus?: boolean;
	iconLeft?: any;
	iconRight?: any;
	fixedTextLeft?: string;
	fixedTextRight?: string;
	isFieldTable?: boolean;
};

const PlainTextField: React.FC<Props> = ({
	label,
	placeholder,
	error,
	className = '',
	labelClassName = '',
	required,
	fullWidth = true,
	optional = false,
	readOnly = false,
	disabled = false,
	value,
	name,
	onChange,
	multiLine,
	formatValue,
	defaultValue,
	inputRef = null,
	id,
	onBlur,
	onKeyPress,
	onFocus,
	resizable = false,
	rows = 1,
	isNoDashAllowed,
	isFieldTable,
	...rest
}) => {
	const amountFormat = (v: any): string => {
		try {
			const convertValue = v.toString();
			const sanitized = convertValue.replaceAll(',', '')?.split('.');
			const _biggo = BigInt(sanitized[0]);

			// Reset to empty string once text field value is  deleted
			if (isEmpty(sanitized[0]) && (!sanitized[1] || sanitized[1] == 0))
				return '';
			return (
				_biggo.toLocaleString('en-US') +
				'.' +
				(sanitized[1] || '00').toString().slice(0, 2).padEnd(2, '0')
			);
		} catch (e) {
			return v;
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const validateOriginalValue =
			event.target.value === undefined || !event.target.value
				? ''
				: String(event.target.value);

		const [integerPart, decimalPart] = validateOriginalValue.split('.');
		let FormatValueWithDecimal = validateOriginalValue;
		if (integerPart.length > 0) {
			FormatValueWithDecimal = validateOriginalValue;
		} else {
			FormatValueWithDecimal = '';
		}

		const removeComma = FormatValueWithDecimal.replace(/[^0-9.]/g, '');
		const modifiedEvent: React.ChangeEvent<HTMLInputElement> = {
			...event,
			target: {
				...event.target,
				value: removeComma,
			},
		};

		onChange && onChange(modifiedEvent);
	};

	const tooLongPlaceholder = (placeholder: string | undefined): any => {
		if (placeholder && placeholder.length > 15) {
			return styles.longPlaceholder;
		}
		return styles.placeholder;
	};
	const inputProps = {
		name,
		inputRef,
		placeholder: placeholder !== undefined ? placeholder : label,
		errorText: error,
		className: cx(
			styles.input,
			tooLongPlaceholder(placeholder !== undefined ? placeholder : label),
			{
				[styles.error]: !!error,
				[styles.multiLine]: multiLine,
				[styles.tableInput]: isFieldTable,
			},
			className
		),
		value,
		readOnly,
		onChange: (event: any, target?: { value?: any }) => {
			const v = multiLine ? event.target.value : target?.value;
			onChange && onChange(v || '');
		},
		id,
		onBlur,
		isNoDashAllowed,
		...rest,
	};

	const [currentEvent, setCurrentEvent] = useState();

	// Function to fix the caret for formatted values
	// Fixes the issue where the caret jumps to the end after every update
	const keepCaret = (event, _val) => {
		const _oldVal = event?.target.value;
		const _newVal = _val;
		const target = event?.target as HTMLInputElement;

		if (_newVal && _oldVal) {
			// Compute the shift based on the diffence of commas between the original value and formatted value
			let shift =
				_newVal?.toString()?.split(',').length -
				_oldVal?.toString()?.split(',').length;
			shift = isNaN(shift) ? 0 : shift;

			if (target.selectionStart) {
				// Set the shifted caret
				moveCaret(target, shift);
			}
		}
	};

	const handleCaretPeriod = (event) => {
		const target = event?.target as HTMLInputElement;
		if (target.value?.split('.').length >= 1) event.preventDefault();

		if (
			target.selectionStart &&
			target.value.indexOf('.') == target.selectionStart
		) {
			// Set the shifted caret
			event.preventDefault();
			moveCaret(target, 1);
		}
	};

	const moveCaret = (target, shift) => {
		const caret = target.selectionStart + shift;
		const element = target;

		window.requestAnimationFrame(() => {
			element.selectionStart = caret;
			element.selectionEnd = caret;
		});
	};

	const formattedValue = useMemo(() => {
		if (amountFormat) {
			let _val;
			try {
				_val = amountFormat(value);
				return _val;
			} catch (error) {
				return value;
			} finally {
				keepCaret(currentEvent, _val);
			}
		}

		return value;
	}, [value]);

	const _allowedKeyCodes = isNoDashAllowed
		? [190, 8, 37, 39, 9]
		: [189, 190, 8, 37, 39, 9];
	const _allowedShiftKeyCodes = [37, 39];
	const _allowedControlKeyCodes = [86, 88, 67, 9];

	return (
		<div className={cx({ [styles.fullWidth]: fullWidth })}>
			{label && (
				<Label
					required={required}
					optional={optional}
					className={labelClassName}
				>
					{label}
				</Label>
			)}

			<Input
				{...inputProps}
				defaultValue={defaultValue}
				value={formattedValue}
				disabled={disabled}
				id={id}
				onBlur={(e) => onBlur && onBlur(e)}
				onKeyPress={() => onKeyPress && onKeyPress(event)}
				onFocus={(e) => onFocus && onFocus()}
				onChange={(e) => {
					//store event for caret handling
					setCurrentEvent(e);
					handleInputChange(e);
				}}
				onKeyDown={(e) => {
					if (
						!(
							(e.shiftKey &&
								_allowedShiftKeyCodes.find((v) => v == e.keyCode) !=
									undefined) ||
							(e.ctrlKey &&
								_allowedControlKeyCodes.find((v) => v == e.keyCode) !=
									undefined)
						)
					) {
						// e.keyCode = 48 is button '0'
						// e.keyCode = 57 is button '9'
						if (
							(!(e.keyCode >= 48 && e.keyCode <= 57) &&
								_allowedKeyCodes.find((v) => v == e.keyCode) == undefined) ||
							e.shiftKey ||
							e.ctrlKey
						) {
							e.preventDefault();
						}
					}

					switch (e.keyCode) {
						case 190:
							//handling when typing period
							handleCaretPeriod(e);
							break;
						case 8:
							{
								const currSelected = e.target.selectionStart;

								//handling for backspace on comma, and decimal zeros
								if (
									(e.target.value.length - currSelected <= 2 &&
										e.target.value[currSelected - 1] == 0) ||
									e.target.value[currSelected - 1] == ','
								) {
									moveCaret(e.target, -1);
									e.preventDefault();
								}

								//handling for backspace for period
								if (
									e.target.value[currSelected - 1] == '.' &&
									e.target.value?.split('.').length - 1 == 1
								) {
									moveCaret(e.target, -1);
									e.preventDefault();
								}
							}
							break;
					}
				}}
				fixedTextLeft={rest.fixedTextLeft}
				fixedTextRight={rest.fixedTextRight}
			/>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AmountCurrencyField: React.FC<Props & { control?: Control<any> }> = ({
	control,
	name,
	defaultValue,
	disabled,
	...rest
}) => {
	if (control && name) {
		const { onChange } = rest;
		const { onBlur } = rest;
		const { onKeyPress } = rest;
		const { onFocus } = rest;
		return (
			<>
				<Controller
					name={name}
					render={(props: any) => {
						const {
							field,
							fieldState: { error },
						} = props;
						const defaultValueCheck = defaultValue || field.value;
						return (
							<PlainTextField
								{...rest}
								{...field}
								error={error?.message}
								onChange={(v) => {
									onChange && onChange(v);
									field.onChange(v);
								}}
								value={disabled ? defaultValueCheck : field.value}
								disabled={disabled}
								inputRef={field.ref}
								className={styles.retryInput}
								onBlur={(e) => {
									onBlur && onBlur(e);
									field.onBlur();
								}}
								onKeyPress={() => {
									onKeyPress && onKeyPress(event);
								}}
								onFocus={() => {
									onFocus && onFocus();
								}}
							/>
						);
					}}
					control={control}
					defaultValue={defaultValue}
				/>
			</>
		);
	}

	return <PlainTextField name={name} disabled={disabled} {...rest} />;
};

export default AmountCurrencyField;
