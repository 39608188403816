import { common } from './common';
import * as yup from 'yup';

export type product_check_collection = {
	bank_id?: number;
	account_name?: string;
	account_no?: string;
	account_type?: string;
	bank_branch?: string;
} & common;

export const product_check_collection_schema = yup.object().shape({
	bank_id: yup
		.string()
		.nullable()
		.label('Bank Name')
		.required('Select Bank Name.'),
	account_name: yup
		.string()
		.nullable()
		.label('Account Name')
		.matches(/^(?!\s+$).*/, {
			message: `Input Account Name`,
		})
		.matches(/^(?!\s+$)/, {
			message: `Input Account Name`,
		})
		.max(100, 'You can only input a max of 100 characters.')
		.required('Input Account Name.'),
	account_no: yup
		.string()
		.nullable()
		.label('Account Number')
		.matches(/^(?!\s+$).*/, {
			message: `Input Account Number`,
		})
		.matches(/^(?!\s+$)/, {
			message: `Input Account Number`,
		})
		.required('Input Account Number')
		.min(
			9,
			'This field requires to input 9-16 characters. Kindly input the number of characters needed.'
		)
		.max(
			16,
			'This field requires to input 9-16 characters. Kindly input the number of characters needed.'
		),
	account_type: yup
		.string()
		.nullable()
		.label('Account Type')
		.required('Select Account Type.')
		.oneOf(['SAVINGS', 'CURRENT']),
	bank_branch: yup
		.string()
		.nullable()
		.label('Bank Branch')
		.matches(/^(?!\s+$).*/, {
			message: `Input Bank Branch`,
		})
		.matches(/^(?!\s+$)/, {
			message: `Input Bank Branch`,
		})
		.max(255, 'You can only input a max of 255 characters.')
		.required('Input Bank Branch.'),
});
