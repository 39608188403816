import { gql } from '@apollo/client';

export const ON_CREATE_BATCH_WAL_REP = gql`
	subscription MySubscription($ack_id: String!) {
		onCreateBatchRequest(ack_id: $ack_id) {
			ack_id
			batch_type
			progress
			total_records
			success_records_count
			error_records_count
			error_details
		}
	}
`;
