import styles from './audit-trail.module.css';
import moment from 'moment';

const formatDate = (date: string) =>
	moment(date).format('MM/DD/YYYY hh:mm:ss A');

interface ICell {
	value: any;
	cell?: any;
}

export const TABLE_AUDIT_TRAIL_COLUMNS = [
	{
		Header: 'Date & Time',
		accessor: ({ created_at }: { created_at: string }): string =>
			formatDate(created_at),
		id: 'created_at',
		width: '20%',
	},
	{
		Header: 'Logged By',
		id: 'logged_by',
		width: '20%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.column}>{value}</div>
		),
	},
	{
		Header: 'Action',
		id: 'action',
		width: '24%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.column}>{value}</div>
		),
	},
	{
		Header: 'Source IP',
		id: 'ip_address',
		width: '20%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.column}>{value}</div>
		),
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		width: '20%',
		Cell: ({ value }: ICell): JSX.Element => (
			<div className={styles.column}>{value}</div>
		),
	},
];
