import { RadioGroup as LDRadioGroup } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { Control, Controller } from 'react-hook-form';
import styles from './ServiceFeesCheckbox.module.css';
import Grid, { SizeOptions } from 'components/Grid/Grid';
import Checkbox from 'components/Checkbox/Checkbox';
import { ReactNode, useEffect, useState } from 'react';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';

export type RadioOption = {
	label: string;
	value: string;
};
type SelectionEntry = Record<string, boolean>;
interface Props {
	label: string;
	onChange?: (value?: SelectionEntry) => void;
	disabled?: boolean;
	name?: string;
	value?: any;
	defaultValue?: string;
	error?: string;
	required?: boolean;
	isVertical?: boolean;
	className?: any;
	options: SelectOption[];
}

const PlainCheckGroup: React.FC<Props> = ({
	name = '',
	value,
	className = '',
	label,
	onChange = () => {},
	required,
	error,
	options,
	disabled,
}) => {
	const [selections, setSelection] = useState<SelectionEntry>(
		options
			.map(({ value }) => value)
			.reduce((prev, next) => {
				return { ...prev, [next]: false };
			}, {})
	);

	// on load default values
	useEffect(() => {
		if (value) {
			setSelection(value);
		}
	}, [value]);

	const handleChange = (value: string) => {
		const updatedSelections = Object.keys(selections).reduce((prev, next) => {
			if (next === value) {
				return {
					...prev,
					[next]: !selections[next],
				};
			}
			return { ...prev, [next]: selections[next] };
		}, {});
		setSelection(updatedSelections);
		onChange && onChange(updatedSelections);
	};

	return (
		<div className={cx(styles.container)}>
			<div className={cx(error && styles.selectError)}>
				<LDRadioGroup
					labels={!required && { label }}
					label={label}
					name={name}
					disabled={disabled}
				>
					{required ? <Label required={true}>{label}</Label> : <></>}
					<div
						className={cx(
							required ? styles.radioOptions : styles.radioOptionsBelow
						)}
					>
						<Grid container gutters>
							{options.map((item, i) => (
								<Grid
									key={i}
									column
									size={1}
									of={
										10
										// Math.max(Math.floor(options.length / 3), 3) as SizeOptions
									}
								>
									<Checkbox
										className={cx(styles.checkbox, className)}
										label={item.label}
										value={item.value}
										onChange={() => {
											handleChange(item.value);
										}}
										checked={selections[item.value]}
										disabled={disabled}
									/>
								</Grid>
							))}
						</Grid>
					</div>
				</LDRadioGroup>
			</div>

			{error && (
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
		</div>
	);
};

type LabelProps = {
	children: ReactNode;
	required?: boolean;
	optional?: boolean;
	className?: string;
};

const Label: React.FC<LabelProps> = ({
	children,
	required,
	optional,
	className = '',
}) => {
	return (
		<div className={cx(styles.label, className)}>
			<div className={cx({ [styles.required]: required })}>{children}</div>
			{optional && (
				<div className={styles.optional}>
					<em>(Optional)</em>
				</div>
			)}
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckGroup: React.FC<Props & { control?: Control<any> }> = ({
	control,
	name,
	defaultValue,
	...rest
}) => {
	if (control && name) {
		const { onChange } = rest;
		return (
			<Controller
				name={name}
				render={({ field, fieldState: { error } }) => (
					<PlainCheckGroup
						{...field}
						{...rest}
						error={error?.message}
						onChange={(v) => {
							onChange && onChange(v);
							field.onChange(v);
						}}
					/>
				)}
				control={control}
				defaultValue={defaultValue}
			/>
		);
	}

	return <PlainCheckGroup {...rest} />;
};

export default CheckGroup;
