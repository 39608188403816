export const COVERED_SCHEDULE = [
	{
		label: 'T+0 (Same Day)',
		value: 'T+0',
	},
	{
		label: 'T-1',
		value: 'T-1',
	},
	{
		label: 'T-2',
		value: 'T-2',
	},
	{
		label: 'T-3',
		value: 'T-3',
	},
];
