import client from 'helpers/ApiClient';
import HTTP from 'helpers/ApiClient';
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { UPDATE_AUTOSAVE } from './const';
import { FixMeLater } from 'types';

const BASE_PATH = '/v2/channels';

export const useCreateChannel = (params: any) =>
	useQuery(['createChannel'], async () => {
		const response = await client.get('/v2/channels/', { params });
		return response.data;
	});

export const useUpdateChannel = (params: any) =>
	useQuery(['updateChannel'], async () => {
		const response = await client.get('/v2/channels/', { params });
		return response.data;
	});

export const useViewChannel = (paramns: any) =>
	useQuery(['fetchChannel'], async () => {
		const response = await client.get('/v2/channels/');
		return response.data;
	});

export const useSaveChannelAutoSave = () => {
	return useMutation({
		mutationFn: async (params: any) => {
			await client.post('/v2/autosave/channels', params);
		},
	});
};

export const useDeleteChannelAutoSave = () => {
	return useMutation({
		mutationFn: async () => {
			await client.delete('/v2/autosave/channels');
		},
	});
};
export const useDeleteBranchAutoSave = () => {
	return useMutation({
		mutationFn: async () => {
			await client.delete('/v2/autosave/branches');
		},
	});
};
export const useDeleteTerminalAutoSave = () => {
	return useMutation({
		mutationFn: async () => {
			await client.delete('/v2/autosave/terminals');
		},
	});
};

export const useCreateChannelModal = () => {
	return useMutation({
		mutationFn: async (params: any) => {
			const response = await client.post('/v2/channels/submit', params);
		},
	});
};

export const useUpdateChannelModal = (
	channelId: number
): UseMutationResult<void, unknown, FixMeLater, unknown> => {
	return useMutation({
		mutationFn: async (params: FixMeLater) => {
			await client.post(`/v2/channels/${channelId}/submit`, params);
		},
	});
};

export const useResubmitRejectedChannel = (
	channelId: number
): UseMutationResult<void, unknown, FixMeLater, unknown> => {
	return useMutation({
		mutationFn: async (params: FixMeLater) => {
			await client.post(`v2/channels/${channelId}/resubmit`, params)
		}
	})
}

export const useSaveDraft = () => {
	return useMutation({
		mutationFn: async (params: any) => {
			const response = await client.post('/v2/channels/drafts', params);
		},
	});
};

export const useUpdateDraft = (
	channelId: number
): UseMutationResult<void, unknown, FixMeLater, unknown> => {
	return useMutation({
		mutationFn: async (params: FixMeLater) => {
			await client.put(`/v2/channels/${channelId}/draft`, params);
		},
	});
};

export const UseUpdateChannelDetails = async (
	channelId: number,
	params: FixMeLater,
	scope: string
) => {
	return await HTTP.put(`${BASE_PATH}/${channelId}/${scope}`, params);
};
