import { useEffect, useState, useMemo } from 'react';
import client from 'helpers/ApiClient';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { SLDSDropdownOptionType } from 'types';
import {
	UseGetBranchList,
	UseGetTerminalList,
} from 'containers/ChannelManagement/List/query';
import { useMeralcoPaymentCenterQuery } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/utils';
import { useHasUserPermission } from 'utils/permissions';
import { LoaderState } from 'redux/modules/channelList';

export const useList = () => {
	const [channelTypeFilter, setChannelTypeFilter] = useState<
		SLDSDropdownOptionType[]
	>([{ value: '', label: '' }]);

	const channelDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedChannel ?? {}
	);
	const branchDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedBranch ?? {}
	);
	const channelFilter = useSelector(
		(state: ReducerStateType) => state.channels.channelFilter
	);
	const branchFilter = useSelector(
		(state: ReducerStateType) => state.channels.branchFilter
	);
	const terminalFilter = useSelector(
		(state: ReducerStateType) => state.channels.terminalFilter
	);
	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.channels.loader
	);

	useEffect(() => {
		const getChannelTypeFilter = async () => {
			const response = await client.get('/v2/channel-types');
			const transformData = response.data?.data.map(({ id, name }) => ({
				value: id,
				label: name,
			}));
			setChannelTypeFilter(transformData);
		};
		getChannelTypeFilter();
	}, []);

	const { data, isLoading } = useMeralcoPaymentCenterQuery();
	const MeralcoCodeOptions = useMemo(
		() =>
			data?.map(({ id, code, name, description }) => ({
				label: `${code}: ${name} - ${description}`,
				value: id,
			})) || [],
		[data]
	);

	const hasChannelUserPermission = useHasUserPermission('channels');
	const hasBranchUserPermission = useHasUserPermission('branches');
	const hasTerminalUserPermission = useHasUserPermission('terminals');

	const { hasViewChannelListPermission } = useMemo(() => {
		return {
			hasViewChannelListPermission: hasChannelUserPermission('list'),
		};
	}, [hasChannelUserPermission]);

	const { hasViewBranchListPermission } = useMemo(() => {
		return {
			hasViewBranchListPermission: hasBranchUserPermission('list'),
		};
	}, [hasBranchUserPermission]);

	const { hasViewTerminalListPermission } = useMemo(() => {
		return {
			hasViewTerminalListPermission: hasTerminalUserPermission('list'),
		};
	}, [hasTerminalUserPermission]);

	let branchListQuery;
	if (hasViewBranchListPermission) {
		if (branchFilter.name.length >= 3) {
			branchListQuery = UseGetBranchList(branchFilter, channelDetails.id);
		} else {
			const { name, ...rest } = branchFilter;
			branchListQuery = UseGetBranchList(rest, channelDetails.id);
		}
	}

	let terminalListQuery;
	if (hasViewTerminalListPermission) {
		if (terminalFilter.tpaId.length >= 3) {
			terminalListQuery = UseGetTerminalList(terminalFilter, branchDetails.id);
		} else {
			const tpaId = '';
			terminalListQuery = UseGetTerminalList({ tpaId }, branchDetails.id);
		}
	}

	return {
		channelTypeFilter,
		channelDetails,
		branchDetails,
		channelFilter,
		branchFilter,
		terminalFilter,
		branchListQuery,
		terminalListQuery,
		MeralcoCodeOptions,
		hasViewChannelListPermission,
		hasViewBranchListPermission,
		hasViewTerminalListPermission,
		loader,
	};
};
