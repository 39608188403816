import yup from 'utils/formSchemas/common';

export const collectionDepositoryAccountSchema = yup.object({}).shape({
	bankId: yup
		.string()
		// .required()
		.label('Select Bank Name')
		.nullable(true)
		.default(null),
	acctName: yup
		.string()
		// .required()
		.nullable(true)
		.default(null)
		.label('Account Name')
		.transform((o, c) => (o === '' ? null : c))
		.matches(
			/^[a-zA-Z0-9 ]+$/,
			'This field only allows alphanumeric characters.'
		)
		.max(255, 'You can only input a max of 255 characters.'),
	acctNo: yup
		.string()
		.label('Account Number')
		// .required()
		.nullable(true)
		.default(null)
		.transform((o, c) => (o === '' ? null : c))
		.matches(/^[0-9]+$/, 'This field only allows numeric characters.')
		.min(
			9,
			'This field requires to input 9-15 characters. Kindly input the number of characters needed.'
		)
		.max(
			15,
			'This field requires to input 9-15 characters. Kindly input the number of characters needed.'
		),
	acctType: yup
		.string()
		.label('Account Type')
		// .required()
		.nullable(true)
		.default(null),
	bankBranch: yup
		.string()
		.label('Bank Branch')
		.nullable(true)
		.default(null)
		// .required()
		.transform((o, c) => (o === '' ? null : c))
		.matches(
			/^[a-zA-Z0-9 ]+$/,
			'This field only allows alphanumeric characters.'
		)
		.max(255, 'You can only input a max of 255 characters.'),
});
