import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';

export const useGetChannelCredentials = (
	id: number | undefined,
	params: any,
	tab: string | undefined
) => {
	return useQuery(['audit', id, params, tab], async () => {
		if (id != undefined && tab === 'ChanneIntegration') {
			try {
				const response = await client.get(
					`/v2/channels/${id}/client-credentials`,
					{ params }
					//`/v2/channels/829/client-credentials`,{ params }
				);
				return response.data;
			} catch (error) {
				throw new Error('Error generating client credentials: ' + error);
			}
		}
	});
};

export const useGenerateClientCredentials = (
	id: number | undefined,
	query: string | undefined
) => {
	return useQuery(
		['generate', id, query],
		async () => {
			if (id != undefined && id != null && id >= -1 && query === 'generate') {
				try {
					const response = await client.post(
						'/v2/channels/client-credentials/generate',
						{
							channelId: id, //829
							//channelId: 829, //829
						}
					);
					return response.data;
				} catch (error) {
					throw new Error('Error generating client credentials: ' + error);
				}
			}
		},
		{ staleTime: 12000 }
	);
};

export const useSendClientCredentials = (
	id: number | undefined,
	credId: number | undefined,
	query: string | undefined
) => {
	return useQuery(
		['send', id, credId, query],
		async () => {
			if (
				credId != undefined &&
				credId != null &&
				credId >= -1 &&
				query === 'send'
			) {
				try {
					const response = await client.post(
						'/v2/channels/client-credentials/send',
						{
							channelId: id, //829
							clientCredentialId: credId, //139
							//channelId: 829,
							//clientCredentialId: 139,
						}
					);
					return response.data;
				} catch (error) {
					throw new Error('Error sending client credentials: ' + error);
				}
			}
		},
		{ staleTime: 12000 }
	);
};

export const useSendOtpRequest = (
	sendType: string | undefined,
	mode: string | undefined,
	tab: string | undefined
) => {
	return useQuery(
		['otp', sendType, mode],
		async () => {
			if (
				sendType != undefined &&
				sendType != '' &&
				sendType != null &&
				sendType != '' &&
				tab === 'ChanneIntegration'
			) {
				try {
					const response = await client.post('/v2/otp', {
						sendType: sendType,
						mode: mode,
					});
					return response.data;
				} catch (error) {
					return error?.response;
					//throw new Error('Error sending otp request: ' + error);
				}
			}
		},
		{ staleTime: 120000 }
	);
};

export const useVerifyOtpRequest = (
	otpNum: string | undefined,
	tab: string | undefined
) => {
	return useQuery(
		['otpVerify', otpNum],
		async () => {
			if (
				otpNum != undefined &&
				otpNum != '' &&
				otpNum != null &&
				otpNum != '' &&
				tab === 'ChanneIntegration'
			) {
				try {
					const response = await client.post('/v2/otp/verify', {
						otp: otpNum,
					});
					return response.data;
				} catch (error) {
					return error?.response;
					//throw new Error('Error sending otp request: ' + error);
				}
			}
		},
		{ staleTime: 120000 }
	);
};
