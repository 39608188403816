import React, { ReactNode, useState } from 'react';
import styles from './ConfirmationModal.module.css';
import { Modal } from '@salesforce/design-system-react/module/components';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';

type Props = {
	heading: any;
	message: any;
	isOpen: boolean;
	isDisabled?: boolean;
	isPrimaryBtnDisabled?: boolean;
	onClose?: () => void;
	onCancelBtnClick?: () => void;
	onConfirmBtnClick?: () => void;
	cancelBtnLabel?: string;
	confirmBtnLabel?: string;
	headerClassName?: any;
	containerClassName?: any;
	remarksInfo?: any;
};

const ConfirmationModal: React.FC<Props> = ({
	heading,
	message,
	isOpen,
	isDisabled,
	isPrimaryBtnDisabled,
	onClose,
	onCancelBtnClick,
	onConfirmBtnClick,
	cancelBtnLabel,
	confirmBtnLabel,
	headerClassName,
	containerClassName,
	remarksInfo = { text: '*Maximum of 1000 characters only.', limit: 1000 },
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => {
				onClose && onClose();
			}}
			disable={isDisabled}
			headerClassName={headerClassName}
			containerClassName={containerClassName}
			contentClassName={styles.confirmationModal}
			heading={heading}
			footer={
				<div className={styles.confirmationModalFooter}>
					<OutlineButton
						onClick={() => {
							onCancelBtnClick && onCancelBtnClick();
						}}
						className={styles.confirmationModalBtn}
					>
						{cancelBtnLabel || 'Cancel'}
					</OutlineButton>
					<PrimaryButton
						disabled={isPrimaryBtnDisabled}
						onClick={() => {
							onConfirmBtnClick && onConfirmBtnClick();
						}}
						className={styles.confirmationModalBtn}
					>
						{confirmBtnLabel || 'Confirm'}
					</PrimaryButton>
				</div>
			}
		>
			<div>{message}</div>
		</Modal>
	);
};

export default ConfirmationModal;
