import React, { useEffect, useState } from 'react';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import { Modal } from '@salesforce/design-system-react/module/components';
import TerminalForm from 'containers/ChannelManagement/Terminal/Form/TerminalForm';
import { FormProvider, useForm } from 'react-hook-form';
import { TerminalDetails } from 'containers/ChannelManagement/Terminal/types';
import { terminalDetails as terminalSchema } from 'containers/ChannelManagement/Terminal/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import { FixMeLater } from 'types';
import {
	transformAutosaveResponseToTerminalType,
	transformTerminalToPayload,
} from 'containers/ChannelManagement/Terminal/utils';
import { useToggle } from 'utils/hooks';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import SuccessModal, {
	SuccessModalBody,
} from 'components/Modal/SuccessChannelModal';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import {
	UseSubmitTerminal,
	UseDraftTerminal,
	UseUpdateTerminal,
	UseUpdateTerminalDraft,
	useSaveTerminalAutoSave,
	UseSubmitTerminalDraft,
} from 'containers/ChannelManagement/Terminal/query';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import { useList } from 'containers/ChannelManagement/List/hooks';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import { FormModeType } from 'containers/ChannelManagement/Channel/ChannelForm/types';
import moment from 'moment';
import {
	useTerminalPermission,
	useChannelPermission,
} from 'containers/ChannelManagement/Permission/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useDispatch } from 'react-redux';

type Props = {
	isModal?: boolean;
	open?: boolean;
	onClose?: () => void;
	isAutoSave?: boolean;
	draftsData?: FixMeLater;
	autoSaveValue?: FixMeLater;
	mode?: string;
	terminalId?: number;
	notAutosavedUpdatedAt?: string;
	notAutosavedUpdatedBy?: string;
	disabledForm?: FixMeLater;
};

const TerminalModal: React.FC<Props> = ({
	isModal = false,
	autoSaveValue,
	isAutoSave,
	open,
	onClose = () => {
		return;
	},
	draftsData,
	mode,
	terminalId,
	notAutosavedUpdatedAt,
	notAutosavedUpdatedBy,
	disabledForm,
}) => {
	const [currentSection, setCurrentSection] = useState<string>('');
	const [prevSection, setPrevSection] = useState<string>('');
	const [stateDisabledForm, setStateDisabledForm] =
		useState<boolean>(disabledForm);
	const [updatedAt, setUpdatedAt] = useState('');
	const [updatedBy, setUpdatedBy] = useState('');

	const dispatch = useDispatch();

	const { hasEditDraftChannelPermission } = useChannelPermission();
	const { hasSaveDraftTerminalPermission } = useTerminalPermission();

	let defaultValues: FixMeLater;

	if (isAutoSave) {
		defaultValues = transformAutosaveResponseToTerminalType(autoSaveValue);
	} else if (draftsData) {
		defaultValues = draftsData;
	}

	const TerminalDetailsUseFormMethods = useForm<TerminalDetails>({
		mode: 'all',
		resolver: yupResolver(terminalSchema),
		defaultValues: defaultValues,
	});

	const formValues = TerminalDetailsUseFormMethods.getValues();

	const { channelDetails, branchDetails, _userInfo } = useTerminal();

	const terminalPayload = transformTerminalToPayload(
		formValues,
		channelDetails.id,
		branchDetails.id
	);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		value: isConfirmDraftShowing,
		valueOn: showConfirmDraft,
		valueOff: hideConfirmDraft,
	} = useToggle();

	const {
		value: isConfirmSubmitShowing,
		valueOn: showConfirmSubmit,
		valueOff: hideConfirmSubmit,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isErrorSubmitModalOpen,
		valueOn: showErrorSubmitModal,
		valueOff: hideErrorSubmitModal,
	} = useToggle();

	const handleConfirmClose = () => {
		hideConfirmModal();
		onClose();
	};

	useEffect(() => {
		if (autoSaveValue && autoSaveValue.data.updatedAt && isAutoSave) {
			setUpdatedAt(
				moment
					.unix(autoSaveValue.data.updatedAt)
					.format('MM/DD/YYYY hh:mm:ss a') || ''
			);
			setUpdatedBy(autoSaveValue.data.username || '');
		} else if (mode === 'EDIT' || mode === 'APPROVAL') {
			setUpdatedBy(autoSaveValue.data.username);
			setUpdatedAt(
				moment(autoSaveValue.data.updatedAt).format('MM/DD/YYYY hh:mm:ss a')
			);
		} else if (mode === 'DRAFT') {
			setUpdatedBy(defaultValues.data.data.updatedBy);
			setUpdatedAt(
				moment
					.utc(defaultValues.data.data.updatedAt)
					.format('MM/DD/YYYY hh:mm:ss a')
			);
		}
		if (TerminalDetailsUseFormMethods.formState.isDirty && mode !== 'DRAFT') {
			setUpdatedBy(_userInfo.username);
			setUpdatedAt(moment().format('MM/DD/YYYY hh:mm:ss a'));
		}
	}, [formValues]);

	const heading = (
		<div className={styles.header}>
			<div className={styles.titleLeftPanel}>
				<div className={styles.titleTextContainer}>{'Add New Terminal'}</div>
			</div>
			<div className={styles.titleRightPanel}>
				<div className={styles.titleActionBtnContainer}>
					<OutlineButton
						className={styles.btn}
						onClick={() => {
							if (
								!hasSaveDraftTerminalPermission ||
								(stateDisabledForm && !hasEditDraftChannelPermission)
							) {
								return dispatch(showAccessDeniedModal());
							}
							if (stateDisabledForm) {
								setStateDisabledForm(false);
								return;
							}
							showConfirmDraft();
						}}
					>
						<SaveDraftIcon className={styles.btnIcon} />
						{stateDisabledForm ? 'Edit Terminal Details' : 'Save as Draft'}
					</OutlineButton>
				</div>
				<div className={styles.titleActionTextContainer}>
					{updatedAt && updatedBy && (
						<em className={styles.titleActionText}>
							{'Last updated at ' + updatedAt}
							{' by ' + updatedBy}
						</em>
					)}
				</div>
			</div>
		</div>
	);

	const footer = (
		<div className={styles.footer}>
			<div className={styles.footerRightPanel}>
				<PrimaryButton
					className={styles.btn}
					onClick={() => {
						showConfirmSubmit();
					}}
					disabled={
						stateDisabledForm ||
						!TerminalDetailsUseFormMethods.formState.isValid
					}
				>
					Submit
				</PrimaryButton>
			</div>
		</div>
	);

	const confirmModalBodyText = (
		<>
			<br />
			You can continue your progress by saving this as draft,
			<br />
			otherwise you may need to create a new registration.
			<br />
			Would you like to save as draft?
		</>
	);

	const confirmDraftBodyText = (
		<>
			<div className={styles.bodyHeader}>
				Would you like to save this as draft?
			</div>
		</>
	);

	const confirmSubmitBodyText = (
		<>
			<br />
			<br />
			Are you sure you want to add terminal?
		</>
	);

	const handleSuccessButton = () => {
		hideSuccessModal();
		hideConfirmModal();
		hideConfirmDraft();
		onClose();
	};

	const successSubmitBodyText = (
		<>
			<div className={styles.subtext}>
				Your work was saved as draft. You may continue
				<br />
				your progress anytime by going to <b>Drafts</b>
				<br />
				and selecting the terminal under the draft list.
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={handleSuccessButton}
			>
				Done
			</PrimaryButton>
		</>
	);

	const successSaveBodyText = (
		<>
			<div className={styles.successMessage}>
				<div className={styles.subtext}>
					You have created a new terminal.
					{/* <br />
				<br />
				<br />
				<br />
				<br /> */}
				</div>
			</div>
			<div className={styles.successBtnAlign}>
				<PrimaryButton
					className={styles.successBtn}
					onClick={handleSuccessButton}
				>
					Okay
				</PrimaryButton>
			</div>
		</>
	);

	const successModalBodyText = (
		<>
			<div className={styles.confirmationHeader}>Success!</div>
			{isConfirmModalShowing || isConfirmDraftShowing
				? successSubmitBodyText
				: isSuccessModalOpen
				? successSaveBodyText
				: ''}
		</>
	);

	const { terminalListQuery } = useList();

	const { refetch: refetchTerminalList } = terminalListQuery || {};

	const { terminalInfo } = terminalPayload;

	const handleSubmit = async () => {
		hideConfirmSubmit();
		showLoading();
		if (mode === 'ADD') {
			await UseSubmitTerminal(terminalInfo)
				.then(() => {
					hideLoading();
					refetchTerminalList && refetchTerminalList();
					showSuccessModal();
				})
				.catch(() => {
					hideLoading();
					showErrorSubmitModal();
				});
		} else {
			await UseSubmitTerminalDraft(
				{
					...terminalInfo,
					status: 'ACTIVE',
				},
				terminalId
			)
				.then(() => {
					hideLoading();
					refetchTerminalList && refetchTerminalList();
					showSuccessModal();
				})
				.catch(() => {
					hideLoading();
					showErrorSubmitModal();
				});
		}
	};

	const handleDraft = async () => {
		showLoading();
		if (mode === 'ADD') {
			await UseDraftTerminal(terminalInfo)
				.then(() => {
					hideLoading();
					showSuccessModal();
				})
				.catch(() => {
					hideLoading();
					showErrorModal();
				});
		} else {
			await UseUpdateTerminalDraft(
				{
					...terminalInfo,
					status: 'DRAFT',
				},
				terminalId
			)
				.then(() => {
					hideLoading();
					showSuccessModal();
				})
				.catch(() => {
					hideLoading();
					showErrorModal();
				});
		}
	};

	const errorModalBodyText = (
		<>
			<div className={styles.confirmationHeader}>Timeout Error!</div>
			<div className={styles.subtext}>
				A problem occurred while saving as draft.
				<br />
				Please try again.
				<br />
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton className={styles.successBtn} onClick={handleDraft}>
				Retry
			</PrimaryButton>
		</>
	);

	const errorSubmitModalBodyText = (
		<>
			<div className={styles.confirmationHeader}>Timeout Error!</div>
			<div className={styles.subtext}>
				A problem occurred while submitting your request.
				<br />
				Please try again.
				<br />
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton className={styles.successBtn} onClick={handleSubmit}>
				Retry
			</PrimaryButton>
		</>
	);

	const updateAutoSave = useSaveTerminalAutoSave();

	const handleAutoSave = async () => {
		if (currentSection !== prevSection && isModal) {
			await updateAutoSave.mutate(terminalPayload);
		}
	};

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={() => {
					TerminalDetailsUseFormMethods.formState.isDirty
						? showConfirmModal()
						: onClose();
				}}
				headerClassName={styles.headerContainer}
				size="medium"
				contentClassName={styles.modal}
				heading={heading}
				footer={footer}
			>
				<FormProvider {...TerminalDetailsUseFormMethods}>
					<TerminalForm
						isModal={true}
						mode={mode}
						isAutoSave={isAutoSave}
						autoSaveValue={autoSaveValue}
						setCurrentSection={setCurrentSection}
						setPrevSection={setPrevSection}
						updateAutoSave={handleAutoSave}
						disabled={stateDisabledForm}
					/>
				</FormProvider>
			</Modal>
			{isConfirmModalShowing && (
				<ConfirmationModal
					open={isConfirmModalShowing}
					onClose={hideConfirmModal}
					heading="Are you done?"
					body={confirmModalBodyText}
					cancelButton="No"
					submitButton="Yes, save as draft"
					handleSubmit={() => {
						hasSaveDraftTerminalPermission
							? handleDraft()
							: dispatch(showAccessDeniedModal());
					}}
					onCloseAll={handleConfirmClose}
				/>
			)}
			{isConfirmDraftShowing && (
				<ConfirmationModal
					open={isConfirmDraftShowing}
					onClose={hideConfirmDraft}
					heading="Save as Draft"
					body={confirmDraftBodyText}
					cancelButton="Back"
					submitButton="Yes"
					handleSubmit={handleDraft}
				/>
			)}
			{isConfirmSubmitShowing && (
				<ConfirmationModal
					open={isConfirmSubmitShowing}
					onClose={hideConfirmSubmit}
					heading="Add Terminal"
					body={confirmSubmitBodyText}
					cancelButton="Cancel"
					submitButton="Confirm"
					handleSubmit={handleSubmit}
				/>
			)}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>{successModalBodyText}</SuccessModalBody>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>{errorModalBodyText}</ErrorModalBody>
				</ErrorModal>
			)}
			{isErrorSubmitModalOpen && (
				<ErrorModal
					open={isErrorSubmitModalOpen}
					onClose={hideErrorSubmitModal}
				>
					<ErrorModalBody>{errorSubmitModalBodyText}</ErrorModalBody>
				</ErrorModal>
			)}
			{isLoading && (
				<FullPageLoader
					open={isLoading}
					message={'Please wait while new terminal are being saved.'}
				/>
			)}
		</>
	);
};

export default TerminalModal;
