import HTTP from '../helpers/ApiClient';
import qs from 'qs';
import { get as _get } from 'lodash';

export async function callback(code) {
	try {
		const req = qs.stringify({
			client_id: process.env.REACT_APP_APP_KEY,
			redirect_uri: '',
			code_verifier: localStorage.getItem('codeVerifier'),
			grant_type: 'authorization_code',
			code,
		});

		// get token
		const tokenResponse = await HTTP.post(
			process.env.REACT_APP_ACCESS_TOKEN_URL,
			req,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);

		const { access_token: token, refresh_token: refreshToken } = _get(
			tokenResponse,
			['data'],
			{}
		);

		const headers = {
			Authorization: `Bearer ${token}`,
		};

		// get user
		const authResponse = await HTTP.get(
			process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/user',
			{
				headers,
			}
		);

		const {
			uuid,
			username,
			emailAddress,
			lastname,
			firstname,
			middlename,
			mobileNumber,
			emailVerifiedAt,
			mobileNumberVerifiedAt,
			roles = [],
		} = _get(authResponse, ['data', 'data'], {});

		const uamRoleData = roles.find(
			(role) => role.platform.id === process.env.REACT_APP_APP_KEY
		);
		const uamRole = uamRoleData || null;

		localStorage.setItem('token', token);
		localStorage.setItem('refreshToken', refreshToken);

		// get scopes
		const scopeResponse = await HTTP.get(
			process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/scopes',
			{
				headers,
			}
		);

		const scopes = _get(scopeResponse, ['data', 'data'], []);

		const userInfo = {
			uuid,
			username,
			emailAddress,
			lastname,
			firstname,
			middlename,
			mobileNumber,
			emailVerifiedAt,
			mobileNumberVerifiedAt,
			roles,
			uamRole,
			scopes,
		};
		localStorage.setItem('userInfo', JSON.stringify(userInfo));

		// create session
		await HTTP.post(
			'/v1/user/session',
			{ user_hash: uuid },
			{
				headers,
			}
		);

		window.location.replace(process.env.REACT_APP_URL);
	} catch (error) {
		console.error(error);
	}
}
