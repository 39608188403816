import cx from 'classnames';
import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import styles from './PrimaryTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { useCallback, useState } from 'react';
import { showAccessDeniedModal } from 'redux/modules/access';

type TabsInfoType = {
	title: string;
	scope?: string;
	component: React.ReactNode;
};

type Props = {
	tabs: TabsInfoType[];
	defaultIndex?: number;
	onSelect?: (index: number) => void;
	isOverrideIndex?: boolean;
	overrideIndexValue?: number | null;
	module?: string;
	className?: any;
};

const PrimaryTab: React.FC<Props> = ({
	tabs,
	isOverrideIndex,
	defaultIndex = 0,
	onSelect,
	module,
	className = '',
	...props
}) => {
	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);
	const _selectedIndex = isOverrideIndex
		? props.overrideIndexValue
		: selectedIndex;

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');
	const hasViewPermission = useCallback(
		(scope: string) => {
			switch (scope) {
				case 'audit-trail':
					return hasUserPermission(productCategory, `list.${scope}`);
				default:
					return false;
			}
		},
		[hasUserPermission, productCategory]
	);

	return (
		<div className={'slds-col'}>
			<Tabs
				variant="scoped"
				id="tabs-example-scoped"
				defaultSelectedIndex={defaultIndex}
				selectedIndex={_selectedIndex}
				className={cx(
					selectedIndex === 1 ? styles.tabs : styles.tab,
					className
				)}
				onSelect={(index: number) => {
					const scope = tabs[index].scope;
					if (!module && scope && !hasViewPermission(scope)) {
						dispatch(showAccessDeniedModal());
						return;
					}
					onSelect && onSelect(index);

					if (isOverrideIndex) {
						return;
					}

					setSelectedIndex(index);
				}}
			>
				{tabs.map((tab: TabsInfoType, index: number) => (
					<TabsPanel key={index} label={tab.title}>
						{tab.component}
					</TabsPanel>
				))}
			</Tabs>
		</div>
	);
};

export default PrimaryTab;
