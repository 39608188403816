import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { useCityQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';

type CitySelectProps = LocationSelectProps & {
	provinceId?: string;
};

const CitySelect: React.FC<CitySelectProps> = ({
	control,
	name,
	label = 'Municipality/City',
	provinceId,
	disabled,
	onChange,
	defaultValue,
	setValue,
	...rest
}) => {
	const { data, isLoading } = useCityQuery(
		'cities',
		{ provinceId },
		{ enabled: !!provinceId, cacheTime: 0 }
	);

	const options = useMemo(() => {
		const cities =
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [];
		const shouldDirty = disabled ? false : true;
		setValue && setValue(`${name}.cities`, cities, {shouldDirty}); // add cities to state (for area handled)
		return cities;
	}, [data, name, setValue]);

	return (
		<SelectField
			label={label}
			control={control}
			name={`${name}.city_id`}
			required
			isLoading={isLoading}
			options={options}
			disabled={disabled}
			onChange={(v) => onChange(v, options)}
			defaultValue={defaultValue}
			{...rest}
		/>
	);
};

export default CitySelect;
