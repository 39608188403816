import {
	SideHeaderProps,
	SideHeaderValues,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/types';

export function useSideHeaders({
	merchantId,
	businessName,
	merchantName,
	generationDate,
}: SideHeaderProps): SideHeaderValues {
	return [
		{
			label: 'Channel Account Name',
			value: 'Test Name',
		},
		{
			label: 'Channel Account Code',
			value: '0000',
		},
		{
			label: 'Generation Date and Time',
			value: 'MM/DD/YYY - HH:MM:SS',
		},
	];
}
