import Grid from 'components/Grid/Grid';
import CheckboxGroup from 'components/Inputs/CheckboxGroup/CheckboxGroupChannel';
import Section from 'components/Section/Section';
import { useFormContext, useWatch } from 'react-hook-form';
import { BUSINESS_LINES_OPTIONS } from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';
import { useEffect } from 'react';
type Props = {
	title: string;
	name: string;
	disabled: boolean;
};
const OtherBusinessLines: React.FC<Props> = ({ disabled, name, title }) => {
	const { control, setValue, getValues } =
		useFormContext<ContractDetailsType>();

	const otherBusinessListener = useWatch({
		control,
	});

	return (
		<Section title={title} required>
			<Grid column size={1} of={1}>
				<CheckboxGroup
					showLabel={false}
					parentName={`contractDetails.otherBusinessLines`}
					name={`contractDetails.otherBusinessLines`}
					label="Other Business Lines"
					control={control}
					options={BUSINESS_LINES_OPTIONS}
					displayAll={true}
					fitHorizontally={true}
					disabled={disabled}
					setValue={setValue}
				/>
			</Grid>
		</Section>
	);
};

export default OtherBusinessLines;
