import React, { useCallback } from 'react';
import { debounce as _debounce } from 'lodash';

import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import Grid from 'components/Grid/Grid';

import { ChannelDetailsToolbarProps } from '../types';

const ChannelDetailsToolbar: React.FC<ChannelDetailsToolbarProps> = ({
	filterTitle = '',
	isFilterAvailable = false,
	filterFields,
	filterAction,
	padding = '',
	onSearch,
}) => {
	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			if (value.length >= 3 || value === '') {
				onSearch && onSearch({ search: value });
			}
		}, 500),
		[]
	);

	return (
		<div>
			<Filter title={filterTitle} padding={padding}>
				<FilterOuter>
					<Grid container align="end">
						<Grid
							container
							column
							size={11}
							of={12}
							align="end"
							gutters="x-small"
						>
							<Grid column size={5} of={12}>
								<InputWithIcon
									onChange={handleSearch}
									placeholder="Search"
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
										},
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				{isFilterAvailable && (
					<FilterInner filterAction={filterAction}>{filterFields}</FilterInner>
				)}
			</Filter>
		</div>
	);
};

export default ChannelDetailsToolbar;
