import moment from 'moment';

const convertToUnix = (date: Date): number => date.getTime() / 1000;

export const calibrateDateFrom = (date: Date): number => {
	const d = new Date(date);
	d.setHours(0, 0, 0, 0);
	return convertToUnix(d);
};
export const calibrateDateTo = (date: Date): number => {
	const d = new Date(date);
	d.setHours(23, 59, 59);
	return +convertToUnix(d);
};

export const calibrateDate = (
	dateFrom: string | null | undefined,
	dateTo: string | null | undefined
) => {
	const parsedDateFrom = dateFrom ? new Date(dateFrom) : null;
	const parsedDateTo = dateTo ? new Date(dateTo) : null;

	const incompleteRange = !parsedDateFrom || !parsedDateTo;

	return incompleteRange
		? { dateCreatedFrom: undefined, dateCreatedTo: undefined }
		: {
				dateCreatedFrom: calibrateDateFrom(parsedDateFrom!),
				dateCreatedTo: calibrateDateTo(parsedDateTo!),
		  };
};

// from ISO 8601 format or Epoch
export const convertDate = (date, type: 'EPOCH' | 'ISO') => {
	if (!date) return;

	let formatDate;
	if (type === 'EPOCH' && typeof date === 'number') {
		formatDate = date * 1000; // convert to millis
	} else if (type === 'ISO' && typeof date === 'string') {
		formatDate = date.split('+')[0]; // remove timezone
	} else {
		return;
	}

	formatDate = new Date(formatDate).toLocaleString();
	return moment(formatDate).format('MM/DD/YYYY hh:mm:ss A');
};
