import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Filter.module.css';

type Props = {
	filterAction?: ReactNode;
};

const FilterInner: React.FC<Props> = ({ children, filterAction, ...props }) => (
	<div className={cx('slds-grid_vertical', styles.innerContainer)} {...props}>
		{filterAction ? (
			<div className={cx('slds-grid')}>
				<div className={cx('slds-col', styles.innerTitle)}>Filter:</div>
				<div>{filterAction}</div>
			</div>
		) : (
			<div className={cx('slds-col', styles.innerTitle)}>Filter:</div>
		)}
		<div
			className={cx(styles.filterLabel, 'slds-col', 'slds-p-vertical_x-small')}
		>
			{children}
		</div>
	</div>
);

export default FilterInner;
