import Grid from 'components/Grid/Grid';
import CheckboxGroup from 'components/Inputs/CheckboxGroup/CheckboxGroup';
import Label from 'components/Inputs/Label/Label';
import Section, { SectionRow } from 'components/Section/Section';
import { Control, UseFormSetValue, UseFormSetError, UseFormTrigger } from 'react-hook-form';
import styles from './PaymentAccepted.module.css';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
	setError: UseFormSetError<any>;
	trigger: UseFormTrigger<any>;
};

const paymentAcceptedOptions = [
	{
		label: 'Exact Payment',
		value: 'EXACT_PAYMENT',
	},
	{
		label: 'Overpayment',
		value: 'OVER_PAYMENT',
	},
	{
		label: 'Underpayment',
		value: 'UNDER_PAYMENT',
	},
];

const PaymentAccepted: React.FC<Props> = ({
	control,
	disabled,
	setValue,
	setError,
	trigger
}) => {
	const name = 'product_business_rule';
	return (
		<Section
			title={
				<Label required>
					<span className={styles.sectionTitle}>Payment Accepted</span>
				</Label>
			}
		>
			<SectionRow>
				<Grid column size={1} of={1}>
					<CheckboxGroup
						showLabel={false}
						parentName={name}
						name={`${name}.payment_accepted`}
						label="Payment Accepted"
						control={control}
						options={paymentAcceptedOptions}
						displayAll={true}
						selectAllLabel="Select All Payment"
						fitHorizontally={true}
						disabled={disabled}
						setValue={setValue}
						onChange={(v) => { trigger(`${name}.payment_accepted`) }}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default PaymentAccepted;
