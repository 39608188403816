import Grid from 'components/Grid/Grid';
import Label from 'components/Inputs/Label/Label';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import styles from './PaymentAcceptancePolicy.module.css';

type Props = {
	control: Control<any>;
	disabled?: boolean;
};

const payment_acceptance_policy_options = [
	{
		label: 'Before Due Date Only',
		value: 'BEFORE_DUE',
	},
	{
		label: 'On or Before Due Date',
		value: 'ON_OR_BEFORE_DUE',
	},
	{
		label: 'Beyond Due Date',
		value: 'BEYOND_DUE',
	},
];

const PaymentAcceptancePolicy: React.FC<Props> = ({ control, disabled }) => {
	const name = 'product_business_rule';

	const paymentPolicy = useWatch({
		name: `${name}.payment_acceptance_policy`,
		control,
	});
	return (
		<Section
			title={
				<Label required>
					<span className={styles.sectionTitle}>Payment Acceptance Policy</span>
				</Label>
			}
		>
			<SectionRow>
				<Grid className={styles.paymentAcceptancePolicy} column size={1} of={1}>
					<RadioGroup
						options={payment_acceptance_policy_options}
						control={control}
						disabled={disabled}
						name={`${name}.payment_acceptance_policy`}
						centerHorizontally={true}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3} />
				<Grid column size={1} of={3} />
				<Grid column size={1} of={3}>
					{paymentPolicy === 'BEYOND_DUE' && (
						<TextField
							label="Grace Period"
							name={`${name}.grace_period`}
							control={control}
							placeholder="0"
							fixedTextRight="days"
							disabled={disabled}
							formatValue={(value) => {
								// Remove any non-numeric or non-decimal characters
								const modifiedValue = value.replace(/[^0-9.]/g, '');

								// Ensure only one decimal point is present
								const decimalCount = modifiedValue.split('.').length - 1;
								if (decimalCount > 1) {
									// More than one decimal point found, remove extra decimals
									const parts = modifiedValue.split('.');
									const integerPart = parts[0];
									const decimalPart = parts.slice(1).join('');
									return `${integerPart}.${decimalPart}`;
								}

								return modifiedValue;
							}}
							onKeyPress={(event) => {
								const keyCode = event.which || event.keyCode;
								const isNumberKey = keyCode >= 48 && keyCode <= 57; // 0-9
								const isDecimalPoint = keyCode === 46; // Decimal point key

								if (!isNumberKey && !isDecimalPoint) {
									event.preventDefault(); // Prevent entering the invalid key
								}
							}}
						/>
					)}
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default PaymentAcceptancePolicy;
