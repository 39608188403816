import React from 'react';
import cx from 'classnames';
import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';

import styles from './Snackbar.module.css';
interface SnackbarProps extends CustomContentProps {
	showLoader?: boolean;
}

const Snackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
	(props, ref) => {
		const { id, message, showLoader = false, ...other } = props;

		return (
			<SnackbarContent ref={ref} {...other}>
				<div className={styles.container}>
					<div className={cx(showLoader && styles.customLoader)} />
					<div>{message}</div>
					<CloseIcon
						className={styles.alertCloseIcon}
						onClick={() => closeSnackbar(id)}
						height={12}
						width={12}
					/>
				</div>
			</SnackbarContent>
		);
	}
);

export default Snackbar;
