import yup from '../common';

const transactionFilterSchema = yup.object().shape({
	dateFrom: yup
		.date()
		.nullable()
		.max(yup.ref('dateTo'), `You’ve selected invalid date`)
		.max(new Date(), `You’ve selected invalid date`),
	dateTo: yup
		.date()
		.nullable()
		.min(yup.ref('dateFrom'), `You’ve selected invalid date`)
		.when('dateFrom', (dateFrom, schema) =>
			dateFrom ? schema.required() : schema.nullable()
		),
	partnerName: yup.string().nullable(),
	productType: yup.string().nullable(),
	channelPartner: yup.string().nullable(),
	branchName: yup.string().nullable(),
	tpaid: yup
		.string()
		.nullable()
		.max(4, 'Invalid value')
		.matches(/^[0-9a-zA-Z]*$/, 'Invalid value'),
	api3Status: yup.string().nullable(),
	bfaStatus: yup.string().nullable(),
	search: yup.string().nullable().min(3, 'Minimum of 3 characters'),
});

export default transactionFilterSchema;
