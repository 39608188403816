import { common } from './common';
import * as yup from 'yup';

export type accreditation = {
	product_accreditation: product_accreditation;
};

export type product_accreditation_item = {
	label?: string;
	name?: string;
	file?: string;
	filename?: string;
	objectKey?: string;
	status?: 'Uploading' | 'Uploaded' | 'Upload Failed' | 'Not Yet Uploaded' | '';
};

export type product_accreditation = {
	cbci_aml_question?: product_accreditation_item;
	annual_income_tax?: product_accreditation_item;
	audited_financial_stmt?: product_accreditation_item;
	bir_certificate_form?: product_accreditation_item;
	corp_secretary_cert?: product_accreditation_item;
	business_permit?: product_accreditation_item;
	company_profile?: product_accreditation_item;
	govt_id?: product_accreditation_item;
	gen_info_sheet?: product_accreditation_item;
	non_disclosure?: product_accreditation_item;
	sec_reg_cert?: product_accreditation_item;
	sec_article_inc?: product_accreditation_item;
	secondary_license?: product_accreditation_item;
	passbook?: product_accreditation_item;
	data_privacy_pia?: product_accreditation_item;
	data_privacy_pat?: product_accreditation_item;
	third_party_security_check?: product_accreditation_item;
} & common;

export const product_accreditation_schema = yup.object().shape({
	cbci_aml_question: yup
		.string()
		.nullable()
		.label('Accomplished CBCI AML Questionnaire')
		.optional(),
	annual_income_tax: yup
		.string()
		.nullable()
		.label('Annual Income Tax Return')
		.optional(),
	audited_financial_stmt: yup
		.string()
		.nullable()
		.label('Audited Financial Statements')
		.optional(),
	bir_certificate_form: yup
		.string()
		.nullable()
		.label('BIR Certificate of Registration')
		.optional(),
	corp_secretary_cert: yup
		.string()
		.nullable()
		.label('Corporate Secretary’s Certificate')
		.optional(),
	business_permit: yup
		.string()
		.nullable()
		.label('Business Permit/Mayor’s Permit')
		.optional(),
	company_profile: yup.string().nullable().label('Company Profile').optional(),
	govt_id: yup.string().nullable().label('Government Issued ID').optional(),
	gen_info_sheet: yup
		.string()
		.nullable()
		.label('General Information Sheet (GIS)')
		.optional(),
	non_disclosure: yup
		.string()
		.nullable()
		.label('Non-disclosure Agreement (NDA)')
		.optional(),
	sec_reg_cert: yup
		.string()
		.nullable()
		.label('SEC Registration Certificate')
		.optional(),
	sec_article_inc: yup
		.string()
		.nullable()
		.label('SEC Articles of Incorporation & By-Laws')
		.optional(),
	secondary_license: yup
		.string()
		.nullable()
		.label('Secondary license')
		.optional(),
	passbook: yup.string().nullable().label('First Page of Passbook').optional(),
	data_privacy_pia: yup.string().nullable().label('').optional(),
	data_privacy_pat: yup.string().nullable().label('').optional(),
	third_party_security_check: yup.string().nullable().label('').optional(),
});
