import * as yup from 'yup';

/**
 * YupLab - Yup Wrapper with Extended Functionality
 * Author: Jaspher
 *
 * This library provides a wrapper around Yup, a JavaScript schema validation library,
 * with extended functionality to simplify working with Yup schemas and accessing field labels.
 */
export class YupLab {
  public schema: yup.AnySchema;

  constructor(schema: yup.AnySchema) {
    this.schema = schema;
  }

  /**
   * Get the object schema from the Yup schema.
   * Throws an error if the schema is not an object schema.
   */
   public get objectSchema(): yup.ObjectSchema<any> {
    if (this.schema.describe().type === 'object') {
      return this.schema as yup.ObjectSchema<any>;
    } else {
      throw new Error('Schema is not an object schema');
    }
  }

  /**
   * Get the array schema from the Yup schema.
   * Throws an error if the schema is not an array schema.
   *
   * @returns {yup.ArraySchema<any>} - The array schema extracted from the Yup schema.
   * @throws {Error} - Throws an error if the schema is not an array schema.
   */
  public get arraySchema(): yup.ArraySchema<any> {
    if (this.schema.describe().type === 'array') {
      return this.schema as yup.ArraySchema<any>;
    } else {
      throw new Error('Schema is not an array schema');
    }
  }

  /**
   * Get the label of a field in the Yup schema.
   *
   * @param {string} fieldName - The name of the field in dot notation.
   * @returns {string | undefined} - The label of the field, or undefined if not found.
   */
  getLabel(fieldName: string): string | undefined {
    const fieldPath = this.convertDotToPath(fieldName);
    const field = this.getFieldFromSchema(fieldPath);
    return field?.describe().label;
  }

  /**
   * Get the default value of a field in the Yup schema.
   *
   * @param {string} fieldName - The name of the field in dot notation.
   * @returns {any | undefined} - The default value of the field, or undefined if not found.
   */
  getDefaultValue(fieldName: string): any | undefined {
    const fieldPath = this.convertDotToPath(fieldName);
    const field = this.getFieldFromSchema(fieldPath);
    return field?.getDefault();
  }

  /**
   * Get the required status of a field in the Yup schema.
   *
   * @param {string} fieldName - The name of the field in dot notation.
   * @returns {boolean} - The required status of the field.
   */
  isRequired(fieldName: string): boolean {
    const fieldPath = this.convertDotToPath(fieldName);
    const field = this.getFieldFromSchema(fieldPath);
    return field?.tests.some((test: any) => test.OPTIONS?.name === 'required') ?? false;
  }

  /**
   * Convert dot notation to array notation.
   *
   * @param {string} fieldName - The field name in dot notation.
   * @returns {string[]} - The field name in array notation.
   */
  private convertDotToPath(fieldName: string): string[] {
    return fieldName.split('.');
  }

  /**
   * Get the field from the Yup schema based on the field path.
   *
   * @param {string[]} fieldPath - The field path in array notation.
   * @returns {yup.AnySchema | undefined} - The Yup schema field, or undefined if not found.
   */
  private getFieldFromSchema(fieldPath: string[]): yup.AnySchema | undefined {
    let field: yup.AnySchema | undefined = this.schema;
    for (const path of fieldPath) {
      if (field instanceof yup.ObjectSchema) {
        field = field.fields[path];
      } else {
        field = undefined;
        break;
      }
    }
    return field;
  }
}
