import { Input } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { isANumber } from 'components/Format/NumberFormatter';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './TimeField.module.css';

type Props = {
	label?: string;
	type?: any;
	placeholder?: string;
	error?: string;
	className?: string;
	labelClassName?: string;
	formatValue?: (value: any) => any;
	required?: boolean;
	fullWidth?: boolean;
	optional?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	shouldUnregister?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
	defaultValue?: any;
	name?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: string) => void;
	multiLine?: boolean;
	inputRef?: any;
	id?: any;
	onBlur?: (e) => void;
	onKeyPress?: (e) => void;
	onFocus?: () => void;
};

const PlainTextField: React.FC<Props> = ({
	label,
	placeholder,
	error,
	className = '',
	labelClassName = '',
	required,
	fullWidth = true,
	optional = false,
	readOnly = false,
	disabled = false,
	value,
	name,
	onChange,
	multiLine,
	formatValue,
	defaultValue,
	inputRef = null,
	id,
	onBlur,
	onKeyPress,
	onFocus,
	type,
	...rest
}) => {
	const inputProps = {
		name,
		inputRef,
		placeholder: placeholder !== undefined ? placeholder : label,
		errorText: error,
		className: cx(
			styles.input,
			{ [styles.multiLine]: multiLine, [styles.error]: !!error },
			className
		),
		value,
		readOnly,
		onChange: (event: any, target?: { value?: any }) => {
			const v = multiLine ? event.target.value : target?.value;
			onChange?.(v || '');
		},
		id,
		onBlur,
		...rest,
	};

	const formattedValue = useMemo(() => {
		if (formatValue) {
			try {
				return formatValue(value);
			} catch (error) {
				return value;
			}
		}

		return value;
	}, [value]);

	return (
		<div className={cx({ [styles.fullWidth]: fullWidth })}>
			{label && (
				<Label
					required={required}
					optional={optional}
					className={labelClassName}
				>
					{label}
				</Label>
			)}
			{multiLine ? (
				<>
					<textarea
						{...inputProps}
						className={cx(inputProps.className, 'slds-input')}
						defaultValue={defaultValue}
						disabled={disabled}
						value={formattedValue}
						rows={formattedValue?.split('\n').length || 1}
					/>

					{error && (
						<div
							className={cx({
								'slds-has-error': !!inputProps.errorText,
							})}
						>
							<div className={cx(styles.helper, 'slds-form-element__help')}>
								{error}
							</div>
						</div>
					)}
				</>
			) : (
				<Input
					{...inputProps}
					// className={styles.inputTime}
					type={type ? type : 'time'}
					required
					value={value}
					disabled={disabled}
					id={id}
					onBlur={(e) => onBlur?.(e)}
					onKeyPress={(e) => {
						onKeyPress?.(e);
						onChange?.(e.target.value);
					}}
					onFocus={() => onFocus?.()}
				/>
			)}
		</div>
	);
};

export const TextFieldFormatter = {
	commaSeparated: (v: any) => {
		try {
			const sanitized = v.replaceAll(',', '');
			if (!isANumber(sanitized)) return v;
			else if (-sanitized)
				return sanitized
					.split('')
					.reverse()
					.join('')
					.replace(/(\d{3}\B)/g, '$1,')
					.split('')
					.reverse()
					.join('');
		} catch (error) {
			return v;
		}
	},
	decimalPlaces:
		(decimalPlaces = 2) =>
		(v: any) => {
			const allNum = v.replaceAll(',', '').replace(/[^\d.-]/g, '');

			const res = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(allNum);
			return res;
		},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TimeField: React.FC<
	Props & { control?: Control<any>; disabled?: boolean }
> = ({
	control,
	name,
	defaultValue,
	disabled,
	shouldUnregister,
	type,
	...rest
}) => {
	if (control && name) {
		const { onChange, onBlur } = rest;
		return (
			<>
				<Controller
					name={name}
					shouldUnregister={shouldUnregister}
					render={(props: any) => {
						const {
							field,
							fieldState: { error },
						} = props;
						return (
							<PlainTextField
								{...rest}
								{...field}
								type={type}
								error={error?.message}
								onChange={(v) => {
									onChange?.(v);
									field.onChange(v);
								}}
								value={field.value}
								disabled={disabled}
								inputRef={field.ref}
								onBlur={(e) => {
									onBlur?.(e);
									field.onBlur();
								}}
							/>
						);
					}}
					control={control}
					defaultValue={defaultValue}
				/>
			</>
		);
	}

	return <PlainTextField name={name} disabled={disabled} {...rest} />;
};

export default TimeField;
