import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

type TInitialState = {
	data: {
		details: any;
	};
	selectedId: number;
	showModal: boolean;
	isLoading: boolean;
};
const initialState: TInitialState = {
	data: {
		details: null,
	},
	selectedId: 0,
	showModal: false,
	isLoading: false,
};

const walletReplenishment = createSlice({
	name: 'walletReplenishment',
	initialState,
	reducers: {
		prep: (state, payload: any) => {
			state.selectedId = payload.id;
		},
		load: (state) => {
			
			state.showModal = true;
		},
		error: (state) => {
			state.isLoading = false;
		},
		closeModal: (state) => {
			state.showModal = false;
		},
		openLoader: (state) => {
			state.isLoading = true;
		},
		closeLoader: (state) => {
			state.isLoading = false;
		},
	},
});

const { prep, load, error, closeModal, openLoader, closeLoader } = walletReplenishment.actions;


export default walletReplenishment.reducer;

export { load, closeModal, openLoader, closeLoader };
