import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import styles from './PrimaryCard.module.css';
import cx from 'classnames';
import { useMemo } from 'react';
import { startCase } from 'lodash';
type Props = {
	name: string;
	logo: string;
	status: string;
	isSelected?: boolean;
	onClick: any;
	listLength?: any;
};
const PrimaryCardChannel: React.FC<Props> = ({
	name,
	logo,
	status,
	isSelected = false,
	listLength = undefined,
	onClick,
}) => {
	const iconClassNames = useMemo(() => {
		const s = status.toLocaleUpperCase();
		return cx({
			[styles.statusIndactorActive]: ['ACTIVE', 'OK'].includes(
				status.toLocaleUpperCase()
			),
			[styles.statusIndactorEngaged]: [
				'ENGAGED',
				'APPROVED',
				'UNDER_DEVELOPMENT',
				'ONGOING_INTEGRATION',
				'READY_FOR_ACTIVATION',
				'APPROVED_FOR_INTEGRATION',
			].includes(status.toLocaleUpperCase()),
			[styles.statusIndactorSuspended]: ['SUSPENDED', 'DEACTIVATED'].includes(
				status.toLocaleUpperCase()
			),
			[styles.statusIndactorDeactivated]: ['TERMINATED'].includes(
				status.toLocaleUpperCase()
			),
		});
	}, [status]);
	const alignToolTip = listLength == 1 ? 'left' : 'top right';
	let toolTipContent = startCase(status.toLocaleLowerCase());
	if (status.toLocaleUpperCase() == 'ONGOING_INTEGRATION') {
		toolTipContent = 'On-going Integration';
	}
	return (
		<div
			className={cx(
				'slds-grid slds-wrap slds-grid_vertical-align-center',
				styles.cardContainer,
				{
					[styles.isSelected]: isSelected,
					[styles.isStatusActive]: ['ACTIVE', 'OK'].includes(
						status.toLocaleUpperCase()
					),
					[styles.isStatusEngaged]: [
						'ENGAGED',
						'APPROVED',
						'UNDER_DEVELOPMENT',
					].includes(status.toLocaleUpperCase()),
					[styles.isStatusSuspended]: ['SUSPENDED', 'DEACTIVATED'].includes(
						status.toLocaleUpperCase()
					),
					[styles.isStatusDeactivated]: ['TERMINATED', 'LOCKED'].includes(
						status.toLocaleUpperCase()
					),
				}
			)}
			onClick={onClick}
			onKeyDown={()=>{return}}
		>
			<img className="slds-col slds-large-size_2-of-12" src={logo} />
			<div className={cx('slds-col slds-large-size_7-of-12')}>
				<div className="slds-grid slds-grid_align-spread">
					<span className={styles.name}>{name}</span>
				</div>
			</div>
			<div className={cx('slds-col slds-large-size_2-of-12')}>
				<Tooltip
					id="tooltip"
					align={alignToolTip}
					hoverCloseDelay={100}
					content={toolTipContent}
					triggerClassName={styles.toolTip}
				>
					<span>
						<Icon
							category="utility"
							name="record"
							size="xx-small"
							className={iconClassNames}
						/>
					</span>
				</Tooltip>
			</div>
		</div>
	);
};
export default PrimaryCardChannel;
