import { useEffect, useState } from 'react';
import cx from 'classnames';

import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import AuditTrailList from 'components/AuditTrailList/AuditTrailList';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { formatDate } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import UserDetails from '../UserDetails';
import {
	AuditTrailResp,
	UserDetailsTabProps,
	UsersFilterOptions,
} from '../types';
import styles from './UserDetailsTab.module.css';

const COLUMN_WIDTH = 100 / 3;

const VIEW_LOGS_COLUMNS = () => [
	{
		Header: 'Field Name',
		id: 'action',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: 'Old Values',
		id: 'old_values',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: 'New Values',
		id: 'new_values',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
];

const AUDIT_TRAIL_COLUMNS = (handleOnClickViewLogs) => [
	{
		Header: 'Date and Time',
		id: 'createdDate',
		sortable: false,
		width: '20%',
		accessor: ({ createdDate }) => formatDate(createdDate),
	},
	{
		Header: 'Logged By',
		id: 'loggedBy',
		sortable: false,
		width: '16%',
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
		width: '16%',
	},
	{
		Header: 'Source IP',
		id: 'sourceIP',
		sortable: false,
		width: '16%',
	},
	{
		Header: 'Remarks',
		id: 'reason',
		sortable: false,
		width: '16%',
	},
	{
		Header: '',
		id: 'logs',
		sortable: false,
		width: '16%',
		Cell: ({ row }) => (
			<div className={styles.actionContainer}>
				<div
					className={cx(styles.action)}
					onClick={() => handleOnClickViewLogs(row.id)}
					onKeyDown={()=>{return}}
				>
					View Logs
				</div>
			</div>
		),
	},
];

const UserDetailsTab: React.FC<UserDetailsTabProps> = ({ cellValue }) => {
	const {
		value: isAuditTrailOpen,
		valueOn: openAuditTrail,
		valueOff: closeAuditTrail,
	} = useToggle();

	const {
		value: isViewLogsOpen,
		valueOn: openViewLogs,
		valueOff: closeViewLogs,
	} = useToggle();

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [data, setData] = useState<AuditTrailResp | undefined>({});
	const [logsData, setLogsData] = useState<AuditTrailResp | undefined>({});

	const handleOnClickViewLogs = (row: number) => {
		showLoadingMessage();
		const logsValue = data?.data?.[row];

		const combinedData: AuditTrailResp = {
			data: logsValue
				? [
						{
							...logsValue,
							new_values: logsValue.details
								? logsValue?.details?.new_values?.slice(1, -1)
								: '',
							old_values: logsValue.details
								? logsValue?.details?.old_values?.slice(1, -1)
								: '',
							action: 'Action',
						},
				  ]
				: [],
			meta: data?.meta,
		};
		setLogsData(combinedData);
		setTimeout(() => {
			hideLoading();
			openViewLogs();
		}, 300);
	};

	const fetchUserAuditTrail = async (params?: UsersFilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const apiUrl = `v2/bfa-admin-portal/users/audit-trail/block-unblock/${cellValue?.id}`;

			const param = {
				page: params?.page,
				limit: params?.limit,
			};

			await client.get(apiUrl, { params: param }).then((response) => {
				if (response?.data?.data.length === 0) {
					return;
				}

				const formattedResult = response?.data?.data.map((item) => {
					return {
						...item,
						createdDate: `${formatDate(item?.createdDate)}`,
					};
				});

				const formattedData: AuditTrailResp | undefined = {
					data: formattedResult,
					meta: response?.data?.meta,
				};

				if (response?.data?.error) {
					throw new Error(response?.data?.error?.message);
				}

				setData(formattedData);
			});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchUserAuditTrail(params)
			);
		} finally {
			hideLoading();
			openAuditTrail();
		}
	};

	const primaryTabProps = {
		tabs: [
			{
				title: 'User Details',
				component: <UserDetails cellValue={cellValue} />,
			},
			{
				title: 'Audit Trail',
				scope: 'audit-trail',
				component: selectedIndex === 1 && (
					<AuditTrailList
						handleViewLogs={isViewLogsOpen}
						columns={AUDIT_TRAIL_COLUMNS(handleOnClickViewLogs)}
						onClose={closeAuditTrail}
						isViewLogsOpen={isViewLogsOpen}
						viewLogsColumn={VIEW_LOGS_COLUMNS()}
						auditTrailData={data}
						onCloseViewLogs={closeViewLogs}
						viewLogsData={logsData}
						handleTableFetch={fetchUserAuditTrail}
					/>
				),
			},
		],
		defaultIndex: 0,
	};

	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	useEffect(() => {
		if (selectedIndex === 1) {
			if (hasUserPermission('get.user.audit-trail')) {
				fetchUserAuditTrail({ page: 1, limit: 25 });
			}
		} else {
			setData({});
		}
	}, [selectedIndex]);

	return (
		<>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}

			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => retryBtnOnClick.action()}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			<PrimaryTab
				{...primaryTabProps}
				module={'User Details'}
				onSelect={setSelectedIndex}
				className={styles.tabPanel}
			/>
		</>
	);
};

export default UserDetailsTab;
