import SelectField from 'components/Inputs/SelectField/SelectFieldChannel';
import { useMemo } from 'react';
import { useRegionQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';

const RegionSelect: React.FC<LocationSelectProps> = ({
	control,
	name,
	label = 'Region',
	disabled,
	defaultValue,
	isFromChannel = false,
	...rest
}) => {
	const { data, isLoading } = useRegionQuery();
	const options = useMemo(
		() =>
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [],

		[data]
	);

	return (
		<SelectField
			label={label}
			control={control}
			name={isFromChannel ? name : `${name}.region`}
			required
			isLoading={isLoading}
			defaultValue={defaultValue}
			options={options}
			{...rest}
			disabled={disabled}
		/>
	);
};

export default RegionSelect;
