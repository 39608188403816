import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import { Control, Path, UseFormSetValue, useWatch } from 'react-hook-form';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import { ReactNode, useState } from 'react';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { boolean } from 'constants/boolean';
import { renewal_frequency } from 'constants/renewal_frequency';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { ContractDetailsFormData } from '../../ContractDetails';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
};

const TermsAndDuration = <T extends ContractDetailsFormData>({
	control,
	disabled,
}: Props<T>): JSX.Element => {
	const name = 'product_terms_duration';
	const autoRenew = useWatch({
		control,
		name: `${name}.auto_renew` as Path<T>,
	});

	const renewalFrequencyType = useWatch({
		control,
		name: `${name}.renewal_frequency_type` as Path<T>,
	});

	return (
		<Section title="Terms And Duration">
			<SectionRow>
				<Grid column size={1} of={3}>
					<DatePickerField
						required
						label="Execution Date"
						name={`${name}.execution_date`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<RadioGroup
						required
						label="Is Auto Renew"
						control={control}
						name={`${name}.auto_renew`}
						disabled={disabled}
						options={boolean}
					/>
				</Grid>
				{autoRenew == '0' && (
					<Grid column size={1} of={3}>
						<DatePickerField
							required
							label="Termination Date"
							name={`${name}.termination_date`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
							shouldUnregister
						/>
					</Grid>
				)}
				{autoRenew == '1' && (
					<Grid container column size={1} of={3} gutters="xx-small">
						<Grid
							column
							size={1}
							of={renewalFrequencyType === 'EVERY_N_YEARS' ? 2 : 1}
						>
							<SelectField
								required
								label="Renewal Frequency"
								placeholder="Select Renewal Frequency"
								control={control}
								name={`${name}.renewal_frequency_type`}
								options={renewal_frequency}
								disabled={disabled}
								shouldUnregister
							/>
						</Grid>
						{renewalFrequencyType === 'EVERY_N_YEARS' && (
							<Grid column size={1} of={2}>
								<TextField
									required
									label="Frequency Interval"
									placeholder={'0'}
									name={`${name}.renewal_frequency`}
									control={control}
									disabled={disabled}
									fixedTextRight="Years"
									shouldUnregister
								/>
							</Grid>
						)}
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default TermsAndDuration;
