import { ReactComponent as PreviewIcon } from 'assets/icons/ic-download4.svg';
import { useDownloadReport } from 'components/Reports/hooks/ReportDownload';
import { Tooltip } from '@salesforce/design-system-react/module/components';
import { IconSettings } from '@salesforce/design-system-react';
import { useSelector, RootStateOrAny } from 'react-redux';

import styles from './index.module.css';
import cx from 'classnames';

const DownloadBtn = ({ data: { id } }: any): JSX.Element => {
	const { report_category_id } = useSelector(
		(states: RootStateOrAny) => states.reports
	);

	const downloadActions = useDownloadReport({
		categoryId: report_category_id,
		id,
	});

	return (
		<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
			<div className={cx(styles.actionCell, 'slds-grid')}>
				<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
					<Tooltip
						role="tooltip"
						content="Download"
						variant="list-item"
						align="bottom right"
						triggerClassName={styles.toolTip}
					>
						<a {...downloadActions}>
							<PreviewIcon />
						</a>
					</Tooltip>
					{downloadActions.errorModal}
				</div>
			</div>
		</IconSettings>
	);
};

export default DownloadBtn;
