const voidTransationModalVoidReason = {
	ALTERED_CHECK: 'Altered Check',
	WRONG_PAYMENT_TYPE: 'Wrong Payment Type',
	CANCELLED_PAYMENT: 'Cancelled Payment',
	ALREADY_PAID_BY_THE_CUSTOMER: 'Already paid by the Customer',
	CASH_ENCODED_AS_CHECK: 'Cash encoded as Check',
	VOIDED_CANCELLED_BY_BILLER: 'Voided/Cancelled by Biller',
	CHECK_ENCODED_AS_CASH: 'Check encoded as Cash',
	FOR_REFUND_REVERSAL: 'For Refund/Reversal',
	POST_DATED_CHECK: 'Post Dated Check',
	CUSTOMER_ERROR: 'Customer Error',
	STALED_CHECK: 'Staled Check',
	CUSTOMER_INITIATED_REQUEST: 'Customer Initiated Request',
	UNSIGNED_CHECK: 'Unsigned Check',
	TELLER_ERROR: 'Teller Error',
	WRONG_BANK_CODE: 'Wrong Bank Code',
	NO_PAYMENT_RECEIVED: 'No Payment Received',
	WRONG_CHECK_NUMBER: 'Wrong Check Number',
	ERRONEOUS_DEPOSIT: 'Erroneous Deposit',
	'WRONG_AMOUNT_IN_WORDS_&_FIGURES': 'Wrong Amount in Words & Figures',
	OVER_DEPOSIT: 'Over Deposit',
	WRONG_AMOUNT: 'Wrong Amount',
	UNDER_DEPOSIT: 'Under Deposit',
	WRONG_BILLER: 'Wrong Biller',
	DOUBLE_POSTING: 'Double Posting',
	WRONG_BILL_PRESENTED: 'Wrong Bill Presented',
	SYSTEM_ERROR: 'System Error',
	WRONG_SYSTEM_DATE: 'Wrong System Date',
	NO_TELLER_CODE: 'No Teller Code',
	WRONG_CUSTOMER_ACCOUNT_OR_REFERENCE_NO:
		'Wrong Customer Account / Reference No.',
	UNIQUE_CONSTRAINT: 'Unique Constraint',
	INVALID_ACCOUNT_NO: 'Invalid Account No.',
	OTHERS: 'Others',
};

export const mapVoidTransactionReason = (reason: string) => {
	if (!reason) return '';

	return reason
		?.split(',')
		?.map((value) => voidTransationModalVoidReason[value])
		.join(',');
};
