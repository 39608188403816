import { SelectOption } from 'components/Inputs/SelectField/SelectField';

export const DEPOSIT_CONSOLIDATION_OPTIONS: SelectOption[] = [
	{
		label: 'Direct to CBCI',
		value: 'DIRECT_CBCI',
	},
	{
		label: 'Direct to Biller',
		value: 'DIRECT_BILLER',
	},
];

export const TAX_PAYER_TYPE_OPTIONS: SelectOption[] = [
	{
		label: 'Large',
		value: 'LARGE',
	},
	{
		label: 'Non-Large',
		value: 'NON-LARGE',
	},
];
