import styles from './products.module.css';
import moment from 'moment';

const formatDate = (date: string) => moment(date).format('MM/DD/YYYY hh:mm:ss A');

interface ICell {
    value: any;
    cell?: any;
}

export const TABLE_VIEW_REPORT_COLUMNS = [
    {
        Header: '',
        accessor: ({ createdAt }: { createdAt: string }): string => formatDate(createdAt),
        id: 'null-1',
        width: '20%',
    },
    {
        Header: '',
        id: 'null-2',
        width: '20%',
        Cell: ({ value }: ICell): JSX.Element => <div className={styles.column}>{value}</div>,
    },
    {
        Header: '',
        id: 'null-3',
        width: '20%',
        Cell: ({ value }: ICell): JSX.Element => <div className={styles.column}>{value}</div>,
    },
    {
        Header: '',
        id: 'null-4',
        width: '20%',
        Cell: ({ value }: ICell): JSX.Element => <div className={styles.column}>{value}</div>,
    },
    {
        Header: '',
        id: 'null-5',
        width: '20%',
        Cell: ({ value }: ICell): JSX.Element => <div className={styles.column}>{value}</div>,
    }
];