import { yupResolver } from '@hookform/resolvers/yup';
import { SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { business_rules_schema } from 'utils/models/product';
import AcceptedCheckTypes from './AcceptedCheckTypes/AcceptedCheckTypes';
import AmountValidation from './AmountValidation/AmountValidation';
import AreaRestriction from './AreaRestriction/AreaRestriction';
import styles from './BusinessRules.module.css';
import PaymentAcceptancePolicy from './PaymentAcceptancePolicy/PaymentAcceptancePolicy';
import PaymentMode from './PaymentMode/PaymentMode';
import RequiredDocuments from './RequiredDocuments/RequiredDocuments';
import {
	TProductState,
	setBusinessRules,
	setSubmittingAll,
	setAutoSaveValues,
	setDrafting,
	setEditing,
	updateValidForm,
} from 'redux/modules/products';
import { usePreviousDistinct } from 'react-use';
import { FixMeLater } from 'types';
import yup from 'utils/formSchemas/common';
import client from 'helpers/ApiClient';
import { debounce, isEmpty } from 'lodash';
import PaymentAccepted from './PaymentAccepted/PaymentAccepted';
import { LoaderState } from 'components/PartnerModal/PartnerModal';
import { ExcludeAutoSave } from 'components/PartnerDetails/PartnerDetails';

export type BusinessRulesFormData = yup.InferType<typeof business_rules_schema>;

type Props = {
	action?: string;
	data?: any;
	disabled?: boolean;
	onSubmit?: (values: any, saveType: string | LoaderState | null) => void;
	currentProductId?: string | number;
	autoSaveValues?: any;
	setAutoSaveValues: TProductState['autoSaveValues'];
	currentPartnerTab?: string;
	setBusinessRules: any;
	setSubmittingAll: any;
	setDrafting?: any;
	setEditing?: any;
	products: Array<any>;
	setLastAutoSaved?: SetStateAction<any>;
	fromAutoSave: boolean;
	currentPartnerStatus?: string;
};

const BusinessRules: React.FC<Props> = ({
	action,
	disabled,
	currentProductId,
	autoSaveValues,
	setAutoSaveValues,
	currentPartnerTab,
	data,
	setBusinessRules,
	setSubmittingAll,
	setDrafting,
	setEditing,
	products,
	setLastAutoSaved,
	onSubmit,
	currentPartnerStatus,
}) => {
	const defaultValues = { payment_mode: [], ...data?.business_rules };
	const {
		control,
		setValue,
		getValues,
		clearErrors,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		setError,
		trigger,
	} = useForm<BusinessRulesFormData>({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(business_rules_schema),
		context: { currentProductId: currentProductId },
	});

	const type =
		!['DRAFT', 'REJECTED', 'FOR_REVIEW'].includes(data.status) &&
		data.business_rules?.product_business_rule?.product_id
			? 'product_document_active'
			: 'product_document_draft';

	const dispatch = useDispatch();
	const statusSubmitting = useSelector<ReducerStateType>(
		(state) => state.products.isSubmittingAll
	);
	const drafting = useSelector<ReducerStateType>(
		(state) => state.products.drafting
	);
	const editing = useSelector<ReducerStateType>(
		(state) => state.products.editing
	);
	const previousTab = usePreviousDistinct(currentPartnerTab);

	const formatFormBusiness = (v: FixMeLater) => {
		return {
			...v.product_business_rule,
			memo: v?.product_business_rule?.memo ?? ' ',
			posType: v?.product_business_rule?.pos_type ?? null,
			isDirty: v.isDirty,
			isValid: v.isValid,
		};
	};

	useEffect(() => {
		if (drafting) {
			const data = structuredClone({ ...getValues(), isDirty, isValid });
			setBusinessRules(formatFormBusiness(data));
			setDrafting(false);
		}
	}, [drafting]);

	useEffect(() => {
		if (editing) {
			handleSubmit(
				(v) => {
					const validData = structuredClone({
						...v,
						isDirty,
						isValid,
					});
					setBusinessRules(formatFormBusiness(validData));
				},
				(e) => console.log('business rules invalid', e)
			)();

			if (!isValid) {
				const data = structuredClone({
					...getValues(),
					isDirty,
					isValid,
				});
				setBusinessRules(formatFormBusiness(data));
			}
			setEditing(false);
		}
	}, [editing]);

	useEffect(() => {
		if (disabled) return;
		clearErrors();
		if (statusSubmitting || previousTab === 'business-rules') {
			handleSubmit((v) => {
				const validData = structuredClone({
					...v,
					isDirty,
					isValid,
				});
				setBusinessRules(formatFormBusiness(validData));
			})();

			if (!isValid) {
				const data = structuredClone({ ...getValues(), isDirty, isValid });
				setBusinessRules(formatFormBusiness(data));
			}
			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab]);

	useEffect(() => {
		if (disabled || !action || ExcludeAutoSave.includes(action)) return;

		const handleAutoSave = (checkIfDirty = false) => {
			if (checkIfDirty && isDirty) {
				scoffoledData();
				return;
			}
			scoffoledData();
		};

		window['businessRulesAuto'] = handleAutoSave;

		window['verifyBusinessRules'] = () => handleSubmit(() => {})();

		if (!isEmpty(autoSaveValues)) {
			client.get('v2/autosave/products').then(({ data }) => {
				const values = data.data.business_rules.originalValues;
				reset(values);
				setAutoSaveValues({});
			});
		}
	}, []);

	const scoffoledData = async () => {
		onSubmit && onSubmit(null, LoaderState.ShowLoader);
		const data = getValues() as BusinessRulesFormData;
		const productType = products?.find((p) => p.id === currentProductId)?.code;

		client
			.post(`/v2/autosave/products`, {
				businessRules: {
					...data,
					originalValues: getValues(),
				},
				productType,
			})
			.then(({ data }) => {
				setLastAutoSaved({
					date: data?.data?.created_at,
					username: data?.data?.username,
				});
				onSubmit && onSubmit(null, LoaderState.HideLoader);
			});
	};

	useEffect(() => {
		if (!action || ExcludeAutoSave.includes(action)) return;
		if (isDirty && previousTab == 'business-rules') {
			scoffoledData();
		}
	}, [currentPartnerTab, isDirty]);

	useEffect(() => {
		if (disabled) return;
		clearErrors();
		if (statusSubmitting || previousTab === 'business-rules') {
			handleSubmit((v) => {
				const validData = structuredClone({ ...v, isDirty, isValid });

				setBusinessRules(formatFormBusiness(validData));
			})();

			const data = structuredClone({ ...getValues(), isDirty, isValid });

			!isValid && setBusinessRules(formatFormBusiness(data));
			setSubmittingAll(false);
		}
	}, [statusSubmitting, previousTab]);

	useEffect(() => {
		debounce(() => {
			dispatch(
				updateValidForm({
					formTab: 'businessRules',
					isValid,
					isDirty,
					isLoaded: true,
				})
			);
		}, 900)();
	}, [isValid, isDirty, dispatch]);

	// For reseting the form when refetched
	const isTabRefetched = useSelector<ReducerStateType>(
		(state) => state.products.businessRules?.isRefetched
	);

	useEffect(() => {
		// reset form if refetched
		if (isTabRefetched) {
			reset(defaultValues);
			updateValidForm({
				formTab: 'businessRules',
				isDirty,
				isRefetched: false,
			});
		}
	}, [isTabRefetched, reset]);
	//

	return (
		<div className={styles.container}>
			<PaymentMode
				control={control}
				setValue={setValue}
				disabled={disabled}
				setError={setError}
				trigger={trigger}
			/>
			<hr className={styles.divider} />
			<AcceptedCheckTypes
				control={control}
				setValue={setValue}
				getValue={getValues}
				disabled={disabled}
				getValues={getValues}
				setError={setError}
			/>
			<hr className={styles.divider} />
			<RequiredDocuments
				control={control}
				setValue={setValue}
				disabled={disabled}
				setError={setError}
				getValues={getValues}
			/>
			<hr className={styles.divider} />
			<AreaRestriction
				control={control}
				setValue={setValue}
				disabled={disabled}
				clearErrors={clearErrors}
				action={action}
				getValues={getValues}
				inquireType={type}
				isDraft={(() => {
					if (action === 'DRAFT') return true;
					if (action === 'APPROVAL') {
						return currentPartnerStatus?.includes('APPROVED') ? false : true;
					}
					return false;
				})()}
			/>
			<hr className={styles.divider} />
			<AmountValidation
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<PaymentAccepted
				control={control}
				setValue={setValue}
				disabled={disabled}
				setError={setError}
				trigger={trigger}
			/>
			<hr className={styles.divider} />
			<PaymentAcceptancePolicy control={control} disabled={disabled} />
		</div>
	);
};

export default connect(
	(state: any) => ({
		lastActionButton: state.products.lastActionButton,
		currentPartnerTab: state.products.currentBillerTab,
		autoSaveValues: state.products.autoSaveValues,
		productId: state.sidebar.itemId,
		products: state.sidebar.products,
		currentProductId: state.sidebar.itemId,
	}),
	{
		setAutoSaveValues,
		setSubmittingAll,
		setDrafting,
		setEditing,
		setBusinessRules,
	}
)(BusinessRules);
