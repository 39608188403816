import crypto from 'crypto-js';
import _, { isEmpty } from 'lodash';

export function createRandomString(num) {
	return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
}

function base64Url(string) {
	return string
		.toString(crypto.enc.Base64)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=/g, '');
}

export function getChallenge(verifier) {
	return base64Url(crypto.SHA256(verifier));
}

export function decrypt(
	ciphertext,
	key,
	replaceKey = false,
	convertToObject = false
) {
	if (isEmpty(ciphertext) || isEmpty(key)) return null;

	try {
		ciphertext = crypto.enc.Base64.parse(ciphertext);
		key = crypto.enc.Utf8.parse(replaceKey ? key.replaceAll('-', '') : key);
		// split IV and ciphertext
		let iv = ciphertext.clone();
		iv.sigBytes = 16;
		iv.clamp();
		ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
		ciphertext.sigBytes -= 16;

		// decryption
		const decrypted = crypto.AES.decrypt({ ciphertext }, key, {
			iv: iv,
		}).toString(crypto.enc.Utf8);

		return convertToObject ? JSON.parse(decrypted) : decrypted;
	} catch (e) {
		console.error('Error Decrypting: ', e);
		return null;
	}
}
