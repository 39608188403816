import { FaUserCircle } from 'react-icons/fa';
import { BsPlus } from 'react-icons/bs';
import cx from 'classnames';
import HTTP from 'helpers/ApiClient';
import styles from './BasicInformation.module.css';
import Button from 'components/Buttons/Button';
import Section, { SectionRow } from 'components/Section/Section';
import GroupControl from 'components/GroupControl/GroupControl';
import TextField from 'components/Inputs/TextField/TextField';
import LookupField from 'components/Inputs/LookupField/LookupField';
import { PartnerCodeType } from 'utils/lookup';
import {
	Control,
	useFieldArray,
	UseFormResetField,
	UseFormSetError,
	UseFormSetValue,
	useWatch,
} from 'react-hook-form';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import Grid from 'components/Grid/Grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'utils/hooks';
import {
	addThousandsSeparator,
	isANumber,
} from 'components/Format/NumberFormatter';
import UploadLogoModal from '../../../../UploadLogoModal/UploadLogoModal';
import { UploadLogoResponse } from 'components/UploadLogoModal/UploadLogoResponse';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteTransparentIcon } from 'assets/icons/ic-delete-transparent.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic-checksymbol.svg';
import { resolveValue } from 'utils/common';
import { connect } from 'react-redux';
import {
	Modal,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import { legal_structure } from 'constants/legal_structure';
import { useIndustryQuery } from 'constants/industries';
import { isRmtProduct } from 'constants/rmtProducts';
import { TPartnerModalConfirmProps } from 'types';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import Table from 'components/CWSTable/Table';

type LogoFieldProps = {
	onClick: () => void;
	onSuccess: (data: UploadLogoResponse) => void;
	image?: string;
	disabled?: boolean;
	action?: any;
	selectedProductId?: any;
};

const LogoField: React.FC<LogoFieldProps> = ({
	onSuccess,
	onClick,
	image,
	disabled,
	action,
	selectedProductId,
}) => {
	const {
		value: isUploadLogoModalOpen,
		valueOn: showUploadLogoModal,
		valueOff: hideUploadLogoModal,
	} = useToggle();

	return (
		<div className={styles.logoFieldContainer}>
			<div className={cx(styles.avatar, { [styles.hasImage]: !!image })}>
				{image ? (
					<img src={image} className={styles.image} alt="partner-logo" />
				) : (
					<FaUserCircle />
				)}
				{disabled ? (
					<Button className={styles.uploadBtn}>
						<BsPlus />
					</Button>
				) : (
					<Button className={styles.uploadBtn} onClick={showUploadLogoModal}>
						<BsPlus />
					</Button>
				)}
			</div>
			<div className={styles.logoLabelContainer}>
				<div className={styles.logoLabel}>Upload Logo</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum file size accepted:</em>
					<span className={styles.logoSubtextValue}>10MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*File types accepted:</em>
					<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
				</div>
			</div>
			<UploadLogoModal
				open={isUploadLogoModalOpen}
				onClose={hideUploadLogoModal}
				onSuccess={onSuccess}
				url="/v2/products/logo"
				action={action}
				selectedProductId={selectedProductId}
			/>
		</div>
	);
};

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	setError: UseFormSetError<any>;
	resetField: UseFormResetField<any>;
	showOtherIndustry?: boolean;
	showOtherLegalStructure?: boolean;
	disabled?: boolean;
	productId?: number | string;
	categories: SelectOption[];
	productList: { code: string; name: string }[];
	data?: any;
	action?: string;
	selectedProductId?: any;
	products: any;
	showConfirmationMessage?: TPartnerModalConfirmProps;
};

type OnSelect = ({ label, value, hasPending, amount }) => void;

const generateChannelTableColumn = (
	onSelect: OnSelect,
	displayName: string
) => [
	{
		Header: displayName,
		id: 'name',
		width: '100%',
		Cell: ({ cell, value }) => {
			const { id, name, hasPending, amount } = cell.row.original;
			return (
				<div
					{...cell.getCellProps()}
					onClick={() => {
						onSelect({
							label: name,
							value: id,
							hasPending,
							amount,
						});
					}}
					onKeyDown={()=>{return}}
					className={styles.channelRow}
				>
					{value}
				</div>
			);
		},
	},
	{ id: 'id', hidden: true, Header: 'ID' },
];

const PartnerCodeModal = ({
	open,
	onClose,
	data,
	count,
	onPageChange,
	pageSize = 25,
	onSelect,
	displayName,
	isLoading,
	page,
	setPage,
}) => {
	const handlePageChange = (p, l) => {
		onPageChange(p, true);
		setPage(p);
	};

	const handleSelect = (value) => {
		onSelect(value);
		onClose();
	};

	return (
		<>
			<Modal
				containerClassName={styles.partnerCodeModalContainer}
				isOpen={open}
				onRequestClose={onClose}
				contentClassName={styles.partnerNameModalContent}
				isLoading={isLoading}
			>
				<div style={{ overflow: 'scroll' }}>
					<Table
						columns={generateChannelTableColumn(handleSelect, displayName)}
						data={data}
						count={count}
						pageSize={pageSize}
						onPageChange={handlePageChange}
						page={page}
						showPageSizeOptions={false}
						tableBodyProps={{
							style: { maxHeight: '425px', overflowY: 'auto' },
						}}
						preHeader={null}
						modalIsOpen={false}
					/>
				</div>
			</Modal>
		</>
	);
};

const BasicInformation: React.FC<Props> = ({
	control,
	setValue,
	setError,
	disabled,
	productId,
	selectedProductId,
	categories,
	productList,
	data,
	action,
	resetField,
	products,
	showConfirmationMessage,
}) => {
	const name = 'product_primary_info';
	const [partnerCodeType, setPartnerCodeType] = useState(
		data?.primary_info?.product_code_type || ''
	);
	const [partnerCode, setPartnerCode] = useState(
		data?.primary_info?.product_code || undefined
	);

	useEffect(() => {
		setValue(
			'product_primary_info.product_code',
			partnerCode && partnerCode.length > 0 ? partnerCode[0].value : ''
		);
	}, [partnerCode, setValue]);

	const [isPartnerCodeLoading, setIsPartnerCodeLoading] = useState(false);

	const [isPartnerCodeOptionsShown, setIsPartnerCodeOptionsShown] =
		useState(false);

	const [partnerCodeOptions, setPartnerCodeOptions] = useState([]);
	const [_tempPartnerCode, _setTempPartnerCode] = useState<any[]>([]);

	const [isFetchingExistingPartnerCode, setIsFetchingExistingPartnerCode] =
		useState(false);

	const [existingPartnerCodes, setExistingPartnerCodes] = useState<
		{ partner_code: string; name: string; code?: string }[] | undefined
	>(undefined);

	const existingMerchantList = useMemo(() => {
		if (
			!productList ||
			productList.length === 0 ||
			!existingPartnerCodes ||
			existingPartnerCodes.length === 0
		)
			return [];

		return existingPartnerCodes.map((x) => {
			return { ...x, merchant_id: x?.code };
		});
	}, [existingPartnerCodes, productList]);

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const industry = useWatch({
		control,
		name: `product_primary_info.category_id`,
	});

	const legalStructure = useWatch({
		control,
		name: `product_primary_info.legal_structure`,
	});

	const prdtCodeType: string = useWatch({
		control,
		name: `product_code_type`,
		defaultValue: 'AUTO',
	});

	const prdtCode: any = useWatch({
		control,
		name: `product_code`,
	});

	const {
		fields: affiliateFields,
		append: appendAffiliate,
		remove: removeAffiliate,
	} = useFieldArray({
		control,
		name: 'product_affiliates',
	});

	const isOtherIndustryShown = useMemo(() => {
		if (industry && (industry == 'Others' || industry == 23)) return true;

		setValue('product_primary_info.other_category', '', {
			shouldValidate: true,
		});
		return false;
	}, [industry]);

	const isOtherLegalStructureShown = useMemo(() => {
		if (legalStructure && legalStructure.toLowerCase() === 'others')
			return true;

		setValue('product_primary_info.other_legal_struture', '', {
			shouldValidate: true,
		});
		return false;
	}, [legalStructure]);

	const [logo, setLogo] = useState(data?.primary_info?.logo_url || '');

	const logoUrl: string | undefined = useWatch({
		control,
		name: `product_primary_info.logo_url`,
	});

	useEffect(() => {
		if (!logoUrl) return;
		setLogo(logoUrl);
		const lastSlashIndex = logoUrl?.lastIndexOf('/');
		setValue(
			'logo',
			{
				objectKey: logoUrl?.substring(lastSlashIndex + 1),
			},
			{ shouldDirty: true, shouldTouch: true }
		);
	}, [logoUrl]);

	const handleUploadSuccess = (data: UploadLogoResponse) => {
		const { objectKey } = data;
		setValue(
			'logo',
			{
				objectKey,
			},
			{ shouldDirty: true, shouldTouch: true }
		);

		setLogo(URL.createObjectURL(data.file));
	};

	const handlePartnerCodeTypeOnChange = (val) => {
		setPartnerCodeType(val);

		if (val == 'AUTO') {
			setPartnerCode(undefined);
			setValue('product_primary_info.product_code', '');
			setValue('product_code', '');
		}
		if (val == 'EXISTING') {
			setPartnerCode('');
		}
	};

	const handlePartnerCodeInput = (partnerCode) => {
		return fetchPartnerCodeOptions(partnerCode);
	};

	const handlePartnerCodeInputChange = (event) => {
		if (!disabled) {
			if (event.length >= 3) {
				setIsPartnerCodeOptionsShown(true);
			} else {
				setIsPartnerCodeOptionsShown(false);
			}
		}
	};

	const handlePartnerCodeSelect = useCallback(
		(selectedPartnerCode) => {
			const { value: partnerCode } = selectedPartnerCode as { value: string };

			setIsFetchingExistingPartnerCode(true);

			HTTP.get(`/v2/utils/products`, {
				params: {
					partner_code: partnerCode,
					product_type_id: productId,
				},
			})
				.then((response) => {
					setIsFetchingExistingPartnerCode(false);

					const { data: existingPartnerCodes } = response.data as {
						data: { partner_code: string; name: string }[];
					};

					setExistingPartnerCodes(existingPartnerCodes);

					showConfirmationModal();
					_setTempPartnerCode([selectedPartnerCode]);
				})
				.catch(() => setIsFetchingExistingPartnerCode(false));
		},
		[productId, showConfirmationModal]
	);

	const fetchPartnerCodeOptions = async (partnerCode) => {
		try {
			setIsPartnerCodeLoading(true);
			const response = await HTTP.get(
				'/v2/utils/products/distinct-partner-codes',
				{
					params: {
						partner_code: resolveValue(partnerCode),
						product_type_id: resolveValue(productId),
					},
				}
			);
			const result = response.data || [];
			const options = result.data.slice().map((item) => {
				return {
					label: item.partner_code,
					value: item.partner_code,
				};
			});
			setPartnerCodeOptions(Array.from(options));
			setIsPartnerCodeOptionsShown(true);
			return Array.from(options);
		} catch (e) {
			setPartnerCodeOptions([]);
		} finally {
			setIsPartnerCodeLoading(false);
			setIsPartnerCodeOptionsShown(false);
		}
	};

	const {
		value: isPartnerCodeModalOpen,
		valueOn: openPartnerCodeModal,
		valueOff: closePartnerCodeModal,
	} = useToggle();

	const [partnerCodeModalInputValue, setPartnerCodeModalInputValue] =
		useState('');

	const handlePageChange = (page) => {
		fetchPartnerCodeItems(partnerCodeModalInputValue);
	};

	const [isPartnerCodeModalLoading, setIsPartnerCodeModalLoading] =
		useState(false);

	const [partnerCodeModalItems, setPartnerCodeModalItems] = useState([]);

	const [page, setPage] = useState(1);

	const [count, setCount] = useState(0);

	const fetchPartnerCodeItems = async (partnerCode) => {
		try {
			setIsPartnerCodeModalLoading(true);
			const response = await HTTP.get('/v2/utils/products', {
				params: {
					partner_code: resolveValue(partnerCode),
					product_type_id: resolveValue(productId),
				},
			});
			const result = response.data || [];
			const items = result.data.slice().map((item) => {
				return {
					label: item.partner_code,
					value: item.partner_code,
				};
			});
			setPartnerCodeModalItems(Array.from(items));
			return Array.from(items);
		} catch (e) {
			setPartnerCodeModalItems([]);
		} finally {
			setIsPartnerCodeModalLoading(false);
		}
	};

	const { data: industryData, isLoading: isIndustryLoading } = useIndustryQuery(
		'categories',
		{},
		{}
	);

	const industryOptions = useMemo(() => {
		const options =
			industryData?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [];
		return options;
	}, [industryData]);

	const duplicateCheck = async (e) => {
		const { name, value = '' } = e?.target || {};
		const _value = value?.trim();

		if (_value === '') return;

		if (name === 'code') {
			const response = await HTTP.get('/v2/utils/products/duplicate', {
				params: {
					merchant_id: resolveValue(_value),
				},
			});

			if (response?.data?.exists) {
				//true
				if (data?.code == _value) {
					return;
				}
				return setError('code', {
					type: 'custom',
					message: 'Merchant ID is already existing',
				});
			}
			return;
		}
		if (name === 'product_primary_info.product_short_name') {
			const response = await HTTP.get('/v2/utils/products/duplicate', {
				params: {
					product_short_name: resolveValue(_value),
					product_type_id: resolveValue(productId),
				},
			});
			if (response?.data?.exists) {
				if (
					data.primary_info?.product_primary_info?.product_short_name !==
						_value &&
					action === 'EDIT'
				) {
					return setError('product_primary_info.product_short_name', {
						type: 'custom',
						message: 'Partner short name is already existing.',
					});
				}
				if (action === 'ADD') {
					return setError('product_primary_info.product_short_name', {
						type: 'custom',
						message: 'Partner short name is already existing.',
					});
				}
				return;
			}
			return;
		}
		//check if product_affiliates[index].code
		const regex = /product_affiliates\[\d\]\.code/i;
		if ((name as string).match(regex)) {
			let primaryInfo = [];
			primaryInfo = data?.primary_info?.product_affiliates;

			if (primaryInfo.length != 0) {
				for (const { code } of primaryInfo) {
					if (code == _value) return;
				}
			}
			const response = await HTTP.get('/v2/utils/products/duplicate', {
				params: {
					merchant_id: resolveValue(_value),
				},
			});
			if (response?.data?.exists) {
				return setError(name, {
					type: 'custom',
					message: 'Merchant ID is already existing',
				});
			}
			return;
		}
	};

	return (
		<>
			<FullPageLoader
				key={'basic-information-fetch-existing-partner-codes'}
				open={isFetchingExistingPartnerCode}
				message={'Please wait...'}
			/>
			<Section title="Partner Basic Information">
				<SectionRow verticalAlign="start">
					<Grid column container size={1} of={3} verticalAlign="start">
						<LogoField
							onSuccess={handleUploadSuccess}
							image={logo}
							onClick={() => console.log('Upload Clicked')}
							disabled={disabled}
							action={action}
							selectedProductId={selectedProductId}
						/>
					</Grid>
					<Grid column verticalAlign="start" size={2} of={3}>
						<SectionRow className={styles.noMarginBottom}>
							<Grid column size={3} of={4}>
								<TextField
									label="Merchant ID"
									required
									disabled={disabled || action === 'EDIT'}
									control={control}
									name="code"
									onBlur={duplicateCheck}
								/>
							</Grid>
							<Grid column size={1} of={4}>
								{!disabled && (
									<Button
										fullWidth
										className={styles.addButton}
										onClick={(evt) => {
											appendAffiliate({});
										}}
									>
										<AddIcon /> Add Affiliate
									</Button>
								)}
							</Grid>
						</SectionRow>
						<SectionRow>
							<Grid column size={4} of={4} verticalAlign="start">
								<GroupControl
									content={affiliateFields.map(
										(
											field: { id: string; name?: string; code?: string },
											index
										) => {
											return (
												<Grid
													key={field.id}
													container
													gutters
													className={styles.affiliateContainer}
												>
													<Grid column size={1} of={2}>
														<TextField
															name={`${'product_affiliates'}[${index}].name`}
															control={control}
															label={index == 0 ? 'Affiliate/Brand Name' : ''}
															required
															placeholder={`Affiliate/Brand Name`}
															disabled={disabled}
														/>
													</Grid>
													<Grid column size={1} of={2}>
														<TextField
															name={`${'product_affiliates'}[${index}].code`}
															control={control}
															label={
																index == 0 ? 'Affiliate/Brand Merchant ID' : ''
															}
															required
															placeholder={`Affiliate/Brand Merchant ID`}
															disabled={disabled}
															onBlur={duplicateCheck}
														/>
													</Grid>
													<Grid column verticalAlign="end">
														{!disabled && (
															<Button
																disabled={disabled}
																className={cx(
																	styles.deleteAffiliateButton +
																		' ' +
																		(index == 0 &&
																			styles.firstDeleteAffiliateButton)
																)}
																onClick={() => {
																	showConfirmationMessage &&
																		showConfirmationMessage(
																			'Delete Confirmation',
																			'Are you sure you want to delete?',
																			() => removeAffiliate(index)
																		);
																}}
															>
																<DeleteTransparentIcon />
															</Button>
														)}
													</Grid>
												</Grid>
											);
										}
									)}
									summary={
										<em style={{ fontSize: '0.83em' }}>
											{affiliateFields.length} Affiliate Merchant ID
										</em>
									}
									tooltip={'List of your affiliates and each merchant ID.'}
									contentTitle={
										<>
											{disabled ? 'List of Affiliate(s):' : 'Add Affiliate'}{' '}
											{affiliateFields?.length > 0 && (
												<CheckIcon className={styles.checkIcon} />
											)}
										</>
									}
								/>
							</Grid>
						</SectionRow>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3}>
						<RadioGroup
							label={
								<>
									Partner Code
									<Tooltip
										align="bottom left"
										content={
											<>
												Note: Partner Code should be unique per Partner.
												However, same Partner Code can be assigned to a single
												Partner with multiple/different Merchant IDs.
											</>
										}
									/>
								</>
							}
							name="product_code_type"
							control={control}
							options={PartnerCodeType}
							isVertical={true}
							onChange={(val) => {
								handlePartnerCodeTypeOnChange(val);
							}}
							disabled={disabled || action === 'EDIT'}
							defaultValue={partnerCodeType || undefined}
							indented={true}
						/>
					</Grid>

					<Grid column size={1} of={3}>
						<LookupField
							label="Partner Code"
							key={`partner-${prdtCode}`}
							subLabel={
								prdtCodeType == 'AUTO'
									? '(Auto-generated)'
									: '(Existing Partner Code)'
							}
							required={prdtCodeType == 'AUTO' ? false : true}
							disabled={
								(disabled ? disabled : prdtCodeType == 'AUTO' ? true : false) ||
								action === 'EDIT'
							}
							control={control}
							name="product_code"
							openMenuOnFocus={
								!disabled ? (prdtCodeType == 'AUTO' ? false : true) : false
							}
							openMenuOnClick={
								!disabled ? (prdtCodeType == 'AUTO' ? false : true) : false
							}
							isLoading={isPartnerCodeLoading}
							options={partnerCodeOptions}
							onInputChange={handlePartnerCodeInputChange}
							isAsync={true}
							loadOptions={
								isPartnerCodeOptionsShown
									? handlePartnerCodeInput
									: () => {
											return Promise.resolve();
									  }
							}
							placeholder={
								prdtCodeType == 'AUTO' ? '- - - - -' : 'Partner Code'
							}
							isSearchable={prdtCodeType == 'AUTO' ? false : true}
							noOptionsMessage={() => {
								return 'No results found.';
							}}
							onSelect={handlePartnerCodeSelect}
							value={prdtCode || null}
							defaultOptions={prdtCode || []}
							classNames={styles.customFieldContainer}
							isValueDisplay
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TextField
							label="Merchant Trade Name"
							required
							disabled={disabled}
							control={control}
							name="product_primary_info.merchant_trade_name"
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3}>
						<TextField
							label="Partner Trade Name"
							required
							disabled={disabled}
							control={control}
							name="product_primary_info.product_trade_name"
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TextField
							label="Partner Short Name"
							required
							disabled={disabled}
							control={control}
							name="product_primary_info.product_short_name"
							onBlur={duplicateCheck}
						/>
					</Grid>
					<Grid
						container
						column
						size={1}
						of={3}
						className={styles.selectWithOthersContainer}
					>
						<Grid column>
							<SelectFieldWithOthers
								required
								label="Industry"
								control={control}
								disabled={disabled}
								sizeOfTextField={1}
								sizeOfSelectField={2}
								options={industryOptions}
								isLoading={isIndustryLoading}
								name="product_primary_info.category_id"
								showOthersInputField={isOtherIndustryShown}
								othersInputName="product_primary_info.other_category"
							/>
						</Grid>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={1} of={3}>
						<TextField
							label="Sub-Brands"
							disabled={disabled}
							optional
							control={control}
							name="product_primary_info.sub_brand"
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TextField
							label="Place of Incorporation"
							disabled={disabled}
							required
							control={control}
							name="product_primary_info.place_of_incorporation"
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<DatePickerField
							label="Date of Incorporation"
							name={`product_primary_info.date_of_incorporation`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
							required
						/>
					</Grid>
				</SectionRow>

				<SectionRow>
					<Grid column size={1} of={3}>
						<TextField
							label="Website Address"
							disabled={disabled}
							optional
							control={control}
							name="product_primary_info.website_address"
						/>
					</Grid>
					<Grid
						container
						column
						size={1}
						of={3}
						className={styles.selectWithOthersContainer}
					>
						<Grid column>
							<SelectFieldWithOthers
								required
								control={control}
								disabled={disabled}
								label="Legal Structure"
								options={legal_structure}
								showOthersInputField={isOtherLegalStructureShown}
								name="product_primary_info.legal_structure"
								othersInputName="product_primary_info.other_legal_struture"
							/>
						</Grid>
					</Grid>
					<Grid column size={1} of={3}>
						<TextField
							label="TIN"
							disabled={disabled}
							required
							control={control}
							name="product_primary_info.tin"
						/>
					</Grid>
				</SectionRow>
				<Grid container gutters>
					<Grid column size={1} of={3}>
						<TextField
							label="Consumer Base Count"
							disabled={disabled}
							required={!isRmtProduct(productId as number, products)}
							control={control}
							name="product_primary_info.consumer_base_count"
							formatValue={(v) => {
								try {
									const sanitized = v.toString().replaceAll(',', '');
									if (!isANumber(sanitized)) return v;
									return addThousandsSeparator(sanitized, 0, ',', true);
								} catch (error) {
									return v;
								}
							}}
						/>
					</Grid>
				</Grid>
			</Section>
			<PartnerCodeModal
				open={isPartnerCodeModalOpen}
				data={partnerCodeModalItems}
				onClose={closePartnerCodeModal}
				count={count}
				onPageChange={handlePageChange}
				onSelect={(value) => {
					handlePartnerCodeInput(value);
				}}
				displayName={'Partner Codes'}
				isLoading={isPartnerCodeModalLoading}
				page={page}
				setPage={setPage}
			/>
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading="Partner Code"
					message={`The partner code you entered is also assigned to existing registered Partner/Merchants:

						${existingMerchantList.map((x) => `${x.name} - ${x.merchant_id}`).join('\n')}

						Would you like to proceed in using this Partner Code for this Partner/Merchant?`}
					onClose={() => {
						hideConfirmationModal();
						_setTempPartnerCode([]);
						setExistingPartnerCodes(undefined);
					}}
					onCancelBtnClick={() => {
						hideConfirmationModal();
						_setTempPartnerCode([]);
						setValue('product_code', '');
						setExistingPartnerCodes(undefined);
					}}
					onConfirmBtnClick={() => {
						hideConfirmationModal();
						setPartnerCode(_tempPartnerCode);
						_setTempPartnerCode([]);
						setExistingPartnerCodes(undefined);
					}}
					cancelBtnLabel="No"
					confirmBtnLabel="Yes"
				/>
			)}
		</>
	);
};

export default connect((state: any) => ({
	productId: state.sidebar.itemId,
	categories: state.products.categories,
	productList: state.products.productList,
	products: state.sidebar.products,
}))(BasicInformation);
