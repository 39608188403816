import { Component } from 'react';
import styles from './Table.module.css';
import cx from 'classnames';
import { Fragment } from 'react';

class Table extends Component {
	render() {
		const {
			tableProps,
			headerGroups,
			tableBodyProps,
			page,
			prepareRow,
			borderStyle,
		} = this.props;
		return (
			<div {...tableProps}>
				<div className={styles.thead}>
					{headerGroups.map((headerGroup, i) => (
						<div
							className="slds-grid"
							{...headerGroup.getHeaderGroupProps()}
							key={i}
						>
							{headerGroup.headers.map((column, i) => (
								<Fragment key={'table-header-' + i}>
									{column.render('Header')}
								</Fragment>
							))}
						</div>
					))}
				</div>
				<div
					{...tableBodyProps}
					style={{
						border: borderStyle,
						borderRadius: '0px 0px 10px 10px',
					}}
				>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<div
								className={cx(styles.tr, 'slds-grid')}
								key={i}
								{...row.getRowProps()}
							>
								{row.cells.map((cell, i) => {
									return (
										<Fragment key={'cell-' + i}>{cell.render('Cell')}</Fragment>
									);
								})}
							</div>
						);
					})}
					{!page.length &&
						[...Array(10).keys()].map((index) => (
							<div
								className={styles.tr}
								style={{
									width: '100%',
									height: '47px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								key={index}
							>
								{index === 4 && 'No Record Found'}
							</div>
						))}
					{page.length !== 0 && this.props.children}
				</div>
			</div>
		);
	}
}

export default Table;
