import cx from 'classnames';
import styles from './Label.module.css';
import { LabelProps } from './types';

/**
 * Label Component
 *
 * This component is used to display a label. It supports additional properties 
 * to mark the label as required or optional, and an additional class can be supplied
 * for custom styling.
 *
 * @param {LabelProps} props - Properties passed to the component.
 * @param {ReactNode} props.children - The content to be displayed in the label.
 * @param {boolean} [props.required] - Whether the label should be marked as required.
 * @param {boolean} [props.optional] - Whether the label should be marked as optional.
 * @param {string} [props.className] - Additional CSS classes to apply to the label.
 * @param {string} [props.subLabel] - Additional text to display below the main label.
 * @returns {JSX.Element} The Label component.
 */
const Label: React.FC<LabelProps> = ({
    children,
    required = false,
    optional = false,
    className = '',
    subLabel,
}) => {
    return (
        <div className={cx(styles.label, className)}>
            <div className={cx({ [styles.required]: required })}>{children}</div>
            {subLabel && <div className={styles.optional}><em>{subLabel}</em></div>}
            {optional && <div className={styles.optional}><em>(Optional)</em></div>}
        </div>
    );
};

export default Label;
