import Address from 'containers/ChannelManagement/Branch/BranchForm/Address/Address';
import {
	INTERNATIONAL,
	LOCAL,
	NAME_OF_AREA,
	NAME_OF_BARANGAY,
	NAME_OF_LOCATION_BASE,
	NAME_OF_MUNICIPALITY_OR_CITY,
	NAME_OF_REGION,
	NAME_OF_STATE_OR_PROVINCE,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/constants';
import { BranchAddressProps } from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/types';
import {
	AREA,
	BARANGAY,
	BRANCH_ADDRESS,
	BUILDING_NAME_OR_NUMBER,
	COUNTRY,
	LOCATION_BASE,
	MUNICIPALITY_OR_CITY_LABEL,
	REGION,
	STATE_OR_PROVINCE_LABEL,
	STREET,
	ZIP_CODE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { Country } from 'utils/queries/location';

const BranchAddress: React.FC<BranchAddressProps> = ({
	setCurrentSection,
	setPrevSection,
	updateAutoSave,
	address,
	isDisabledAllEditableFields,
}) => {
	const { control, setValue, clearErrors } = useFormContext();

	const currentLocationBaseValue = useWatch({
		name: NAME_OF_LOCATION_BASE,
	});

	const selectedRegion = useWatch({
		name: NAME_OF_REGION,
	});

	const selectedProvince = useWatch({
		name: NAME_OF_STATE_OR_PROVINCE,
	});

	const selectedCity = useWatch({
		name: NAME_OF_MUNICIPALITY_OR_CITY,
	});

	const selecteBarangay = useWatch({
		name: NAME_OF_BARANGAY,
	});

	return (
		<>
			<Address
				control={control}
				onClick={() => {
					setCurrentSection((prevState) => {
						setPrevSection(prevState);
						setCurrentSection('BranchAddress');
					});

					updateAutoSave();
				}}
				isAreaToZipCodeVisible={currentLocationBaseValue === LOCAL}
				sectionTitle="Branch Address"
				{...address}
				locationBase={{
					name: `${BRANCH_ADDRESS}.${LOCATION_BASE}`,
					disabled: isDisabledAllEditableFields,
					onChange: (value: string) => {
						if (value === LOCAL) {
							setValue(`${BRANCH_ADDRESS}.${COUNTRY}`, '175', {
								shouldDirty: true,
							});
							clearErrors(`${BRANCH_ADDRESS}.${COUNTRY}`);
						}
						if (value === INTERNATIONAL) {
							setValue(`${BRANCH_ADDRESS}.${COUNTRY}`, '', {
								shouldDirty: true,
							});
							clearErrors(`${BRANCH_ADDRESS}.${COUNTRY}`);
						}
						setValue(NAME_OF_AREA, '', { shouldDirty: true });
						clearErrors(NAME_OF_AREA);
						setValue(NAME_OF_REGION, '', {
							shouldDirty: true,
						});
						clearErrors(NAME_OF_REGION);
						setValue(NAME_OF_STATE_OR_PROVINCE, '', { shouldDirty: true });
						clearErrors(NAME_OF_STATE_OR_PROVINCE);
						setValue(NAME_OF_MUNICIPALITY_OR_CITY, '', { shouldDirty: true });
						clearErrors(NAME_OF_MUNICIPALITY_OR_CITY);
						setValue(NAME_OF_BARANGAY, '', { shouldDirty: true });
						clearErrors(NAME_OF_BARANGAY);
						setValue(`${BRANCH_ADDRESS}.${BUILDING_NAME_OR_NUMBER}`, '', {
							shouldDirty: true,
						});
						clearErrors(`${BRANCH_ADDRESS}.${BUILDING_NAME_OR_NUMBER}`);
						setValue(`${BRANCH_ADDRESS}.${STREET}`, '', {
							shouldDirty: true,
						});
						clearErrors(`${BRANCH_ADDRESS}.${STREET}`);
						setValue(`${BRANCH_ADDRESS}.${ZIP_CODE}`, '', {
							shouldDirty: true,
						});
						clearErrors(`${BRANCH_ADDRESS}.${ZIP_CODE}`);
					},
					...address?.locationBase,
				}}
				country={{
					countryFilter:
						currentLocationBaseValue === INTERNATIONAL
							? (country: Country) => country.country_code !== 'PH'
							: () => true,
					...address?.country,
					selectFieldProps: {
						name: `${BRANCH_ADDRESS}.${COUNTRY}`,
						disabled:
							isDisabledAllEditableFields || currentLocationBaseValue === LOCAL,
						...address?.country?.selectFieldProps,
					},
				}}
				area={{
					name: NAME_OF_AREA,
					placeholder: `Select ${_.startCase(AREA)}`,
					disabled: isDisabledAllEditableFields,
					...address?.area,
				}}
				region={{
					...address?.region,
					selectFieldProps: {
						placeholder: `Select ${_.startCase(REGION)}`,
						name: NAME_OF_REGION,
						disabled: isDisabledAllEditableFields,
						...address?.region?.selectFieldProps,
					},
				}}
				stateOrProvince={{
					regionId: selectedRegion,
					...address?.stateOrProvince,
					selectFieldProps: {
						name: NAME_OF_STATE_OR_PROVINCE,
						placeholder: `Select ${STATE_OR_PROVINCE_LABEL}`,
						disabled: isDisabledAllEditableFields || !selectedRegion,
						...address?.stateOrProvince?.selectFieldProps,
					},
				}}
				municipalityOrCity={{
					provinceId: selectedProvince,
					...address?.municipalityOrCity,
					selectFieldProps: {
						disabled: isDisabledAllEditableFields || !selectedProvince,
						name: NAME_OF_MUNICIPALITY_OR_CITY,
						placeholder: `Select ${MUNICIPALITY_OR_CITY_LABEL}`,

						...address?.municipalityOrCity?.selectFieldProps,
					},
				}}
				barangay={{
					cityId: selectedCity,
					...address?.barangay,
					selectFieldProps: {
						disabled: isDisabledAllEditableFields || !selectedCity,
						name: NAME_OF_BARANGAY,
						placeholder: `Select ${_.startCase(BARANGAY)}`,
						...address?.barangay?.selectFieldProps,
					},
				}}
				street={{
					name: `${BRANCH_ADDRESS}.${STREET}`,
					disabled: isDisabledAllEditableFields,
					...address?.street,
				}}
				buildingNameOrNumber={{
					name: `${BRANCH_ADDRESS}.${BUILDING_NAME_OR_NUMBER}`,
					disabled: isDisabledAllEditableFields,
					...address?.buildingNameOrNumber,
				}}
				zipCode={{
					barangayId: selecteBarangay,
					...address?.zipCode,
					selectFieldProps: {
						disabled: true,
						name: `${BRANCH_ADDRESS}.${ZIP_CODE}`,
						...address?.zipCode?.selectFieldProps,
					},
				}}
			></Address>
		</>
	);
};

export default BranchAddress;
