import styles from './TagInput.module.css';
import { Pill } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import React, {
	ClipboardEventHandler,
	ReactNode,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from 'components/Inputs/Label/Label';
import {
	validateContactNumber,
	validateEmail,
	validateLandlineNumber,
} from 'utils/validation';

type Tag = {
	value: any;
	index: number;
};

type Props = {
	tags?: string[];
	onChange?: any;
	validateType?: string;
	defaultValue?: any;
	name?: string;
	label?: string;
	subtext?: string;
	secondarySubtext?: string;
	error?: string;
	onAdd?: (tag: string) => void;
	onBlur?: any;
	onDelete?: (index: number) => void;
	onTagClick?: (tag: string, index: number) => void;
	hasError?: (tag: string, index: number) => boolean;
	className?: string;
	placeholder?: string;
	fullWidth?: boolean;
	required?: boolean;
	optional?: boolean;
	allowDuplicates?: boolean;
	tagClassName?: string;
	errorClassName?: string;
	getTagValue?: (tag: string) => string;
	delimiters?: string[];
	inputValue?: string;
	onInputChange?: (value: string) => void;
	onPaste?: (values: any[]) => void;
	renderTag?: (tag: string, index: number) => ReactNode;
	disabled?: boolean;
	inputRef?: any;
};

type WithControl = { control?: Control<any>; disabled?: boolean };

function getContactNumberValue(value: string): string {
	// if (value && validateMobile(value)) {
	// 	return `+63${value.slice(-10)}`;
	// }
	return value;
}

const PlainTagInput: React.FC<Props> = ({
	tags,
	onAdd,
	onDelete,
	onTagClick = () => {},
	placeholder = '',
	allowDuplicates = false,
	renderTag,
	tagClassName = '',
	className = '',
	fullWidth = true,
	hasError = () => false,
	errorClassName = '',
	getTagValue = (tag) => tag,
	delimiters = [','],
	inputValue: controlledInputValue,
	onInputChange,
	onPaste,
	label,
	required,
	optional,
	subtext,
	onBlur,
	error,
	disabled,
	inputRef,
	validateType,
	secondarySubtext,
}) => {
	const inputValueIsControlled = controlledInputValue !== undefined;
	const [inputValue, setInputValue] = useState<string | undefined>('');
	const [activeTag, setActiveTag] = useState<Tag | undefined>();
	const pillRef = useRef<HTMLDivElement>(null);

	const pillHeight = useMemo(() => {
		return pillRef?.current?.clientHeight || 0;
	}, [pillRef.current?.clientHeight]);

	const clearInput = useCallback(() => {
		setInputValue('');
		onInputChange && onInputChange('');
	}, [inputValue]);

	useEffect(() => {
		if (inputValueIsControlled) {
			setInputValue(controlledInputValue);
		}
	}, [controlledInputValue]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		onInputChange && onInputChange(value);
		if (!inputValueIsControlled) {
			setInputValue(
				validateType === '639' ? getContactNumberValue(value) : value
			);
		}
	};

	const handleInputBlur = () => {
		onBlur && onBlur();
		addTag();
	};

	const handlePaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
		const pastedValue = (e.clipboardData || window.clipboardData).getData(
			'text'
		);

		if (onPaste) {
			const matchingDelimiter = delimiters.find((d) => pastedValue.includes(d));
			if (matchingDelimiter) {
				e.preventDefault();
				const values = pastedValue
					.split(matchingDelimiter)
					.map((v: string) => v.trim())
					.filter((v: string) => v)
					.filter((v: string) => !tags?.includes(v));
				onPaste(Array.from(new Set(values)));
				clearInput();
			}
		}
	};

	const addTag = useCallback(
		(value?: string) => {
			const v = value || inputValue;
			if (!v || !v.trim()) {
				return;
			}

			const match = tags?.find((t) => getTagValue(t) === getTagValue(v));

			if (!match || allowDuplicates) {
				onAdd && onAdd(v.trim());
			}
			clearInput();
		},
		[inputValue]
	);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		addTag();
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!inputValue && tags && tags.length > 0 && e.key === 'Backspace') {
			handleTagDelete(tags.length - 1);
		}
		if (delimiters.includes(e.key)) {
			addTag();
		}
	};

	const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (delimiters.includes(e.key)) {
			clearInput();
			return;
		}
	};

	const handleContainerKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const copyPressed = (e.ctrlKey || e.metaKey) && e.key === 'c';

		if (activeTag && copyPressed) {
			navigator.clipboard.writeText(activeTag.value);
		}
	};

	const handleTagBlur = (value: any, index: number) => {
		if (activeTag && activeTag.index === index) {
			setActiveTag(undefined);
		}
	};

	const handleTagDelete = (index: number) => {
		onDelete && onDelete(index);
		if (activeTag && activeTag.index === index) {
			setActiveTag(undefined);
		}
	};
	return (
		<div
			onKeyDown={handleContainerKeyDown}
			className={cx({ [styles.fullWidth]: fullWidth }, className)}
		>
			{label && (
				<div className={styles.labelContainer}>
					<Label
						required={required}
						optional={optional}
						className={styles.label}
					>
						{label}
					</Label>
					{subtext && (
						<div className={styles.subtext}>
							<em>{subtext}</em>
						</div>
					)}
					{secondarySubtext && (
						<div className={styles.subtext}>
							<em>{secondarySubtext}</em>
						</div>
					)}
				</div>
			)}
			<div
				className={cx(styles.tagInput, {
					[styles.empty]: !tags || tags.length === 0,
					[styles.hasError]: !!error,
				})}
				style={{ maxHeight: pillHeight ? pillHeight * 3.5 : undefined }}
			>
				{tags &&
					tags?.map(
						(tag, index) =>
							tag && (
								<div
									className={styles.tagContainer}
									key={index}
									tabIndex={0}
									ref={index === 0 ? pillRef : null}
								>
									{renderTag ? (
										renderTag(tag, index)
									) : (
										<Pill
											key={index}
											labels={{ label: tag }}
											className={cx(styles.tag, tagClassName, {
												[styles.error]: hasError(tag, index),
												[errorClassName]: hasError(tag, index),
												[styles.activeTag]:
													activeTag && activeTag.index === index,
												[styles.disabled]: disabled,
											})}
											onClick={() => {
												onTagClick(tag, index);
												setActiveTag({ value: tag, index });
											}}
											onRemove={() => !disabled && handleTagDelete(index)}
											onBlur={() => handleTagBlur(tag, index)}
										/>
									)}
								</div>
							)
					)}
				<form className={styles.inputForm} onSubmit={handleSubmit}>
					<input
						type="text"
						value={inputValue}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						onKeyUp={handleKeyUp}
						placeholder={tags && tags.length == 0 ? placeholder || label : ''}
						onPaste={handlePaste}
						autoComplete="off"
						ref={inputRef}
						onBlur={handleInputBlur}
						disabled={disabled}
					/>
					<button type="submit" hidden />
				</form>
			</div>
			{error && (
				<div
					className={cx({
						'slds-has-error': !!error,
					})}
				>
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
		</div>
	);
};

const TagInput: React.FC<Props & WithControl> = ({
	control,
	name,
	placeholder,
	...rest
}) => {
	if (control && name) {
		return (
			<Controller
				name={name}
				render={({ field, fieldState: { error } }) => {
					const value = field.value as string[];
					const tags =
						value && Array.isArray(value) ? value : (value && [value]) ?? [];
					return (
						<PlainTagInput
							{...field}
							{...rest}
							tags={tags}
							error={error?.message}
							inputRef={field.ref}
							placeholder={placeholder}
							onBlur={() => {
								field.onBlur();
								rest.onBlur && rest.onBlur();
							}}
							onAdd={(v) => {
								field.onChange(
									tags.concat(v).map((tag) => {
										if (rest.getTagValue) return rest.getTagValue(tag);
										return tag;
									})
								);
								rest.onAdd && rest.onAdd(v);
							}}
							onDelete={(index) => {
								const filtered = tags.filter((_, i) => i !== index);
								field.onChange(filtered);
								rest.onDelete && rest.onDelete(index);
							}}
							onPaste={(values) => {
								field.onChange(
									tags.concat(values).map((tag) => {
										if (rest.getTagValue) return rest.getTagValue(tag);
										return tag;
									})
								);
								rest.onPaste && rest.onPaste(values);
							}}
						/>
					);
				}}
				defaultValue={rest.defaultValue}
				control={control}
			/>
		);
	}

	return <PlainTagInput name={name} {...rest} />;
};

export const EmailTagInput: React.FC<Props & WithControl> = ({
	control,
	disabled,
	label = 'Email Address',
	onChange,
	onBlur,
	...rest
}) => {
	return (
		<TagInput
			label={label}
			control={control}
			hasError={(v) => !validateEmail(v)}
			subtext="*Can input multiple emails separated by commas."
			disabled={disabled}
			onBlur={(e) => onChange && onChange(e)}
			{...rest}
		/>
	);
};

export const MobileNumberTagInput: React.FC<Props & WithControl> = ({
	control,
	disabled,
	...rest
}) => {
	return (
		<TagInput
			label="Mobile Number"
			placeholder="+63XXXXXXXXXX"
			control={control}
			hasError={(tag, index) => !validateMobile(tag, index)}
			subtext="*Can input multiple mobile numbers separated by commas."
			disabled={disabled}
			{...rest}
		/>
	);
};

export const TelephoneNumberTagInput: React.FC<Props & WithControl> = ({
	control,
	disabled,
	...rest
}) => {
	return (
		<TagInput
			label="Telephone Number"
			placeholder="02XXXXXXXX"
			control={control}
			hasError={(tag, index) => !validateLandlineNumber(tag, index)}
			subtext="*Can input multiple telephone numbers separated by commas."
			secondarySubtext="* Telephone Number should start with area code"
			disabled={disabled}
			{...rest}
		/>
	);
};

export const ContactNumberTagInput: React.FC<Props & WithControl> = ({
	control,
	disabled,
	validateType = '639',
	...rest
}) => {
	return (
		<TagInput
			label="Mobile Number"
			control={control}
			hasError={(tag, index) => !validateMobile(tag, index)}
			subtext="*Can input multiple contact numbers separated by commas."
			disabled={disabled}
			validateType={validateType}
			{...rest}
		/>
	);
};

function validateMobile(num: string, index?: number): boolean {
	if (index && index >= 19) {
		return false;
	}

	return new RegExp(/^(\+?639)\d{9}$/).test(num);
}

export default TagInput;
