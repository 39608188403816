import cx from 'classnames';
import folderIcon from '../../assets/icons/ic-folder.svg';
import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import styles from './Checkbox.module.css';
import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import Checkbox from './Checkbox';
import MultiCheckboxContainer from './MultiCheckboxContainer';
import { useEffect, useMemo, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Control, Controller } from 'react-hook-form';

interface Props {
	name?: string;
	control?: Control<any>;
	isShowTooltip?: boolean;
	entries: any;
	onEntryClick: (x, y) => void;
	getEntryValue?: (entry) => any;
	disabled?: boolean;
}

const ToolTipContainer = ({ children, disabled, isShowTooltip }) =>
	disabled && isShowTooltip ? (
		<Tooltip
			id="tooltip"
			align="bottom left"
			content={
				<span>
					This branch is already covered
					<br />
					by another wallet.
				</span>
			}
			triggerClassName={styles.toolTip}
		>
			{children}
		</Tooltip>
	) : (
		children
	);

function BranchPortion({
	isShowTooltip,
	entries = [],
	onEntryClick,
	disabled = false,
}) {
	return (
		<>
			{entries.map((entry) => {
				const { id, name, checked, has_wallet } = entry;
				const _disabled = disabled || has_wallet;
				return (
					<ToolTipContainer
						key={id}
						disabled={has_wallet}
						isShowTooltip={isShowTooltip}
					>
						<div
							className={cx('slds-col', styles.entry, {
								[styles.disabledChkBox]: _disabled,
							})}
						>
							<Checkbox
								disabled={_disabled}
								label={name}
								checked={checked}
								onChange={() => onEntryClick(entry, !checked)}
							/>
						</div>
					</ToolTipContainer>
				);
			})}
		</>
	);
}

const BranchCheckbox: React.FC<Props> = ({
	name = '',
	control,
	isShowTooltip = false,
	entries,
	onEntryClick = (x, y) => {},
	getEntryValue = (entry) => entry,
	disabled,
}) => {
	const halfIndex = Math.ceil(entries.length / 2) - 1;
	const [selected, setSelected] = useState(false);
	const [filteredEntries, setFilteredEntries] = useState(entries);
	const [search, setSearch] = useState('');
	const handleSearchChange = (e) => setSearch(e.target.value);

	const disabledIfAllHasWallet =
		entries.filter((e) => e.has_wallet).length === entries.length;

	const handleSearch = debounce(() => {
		if (search === '') {
			setFilteredEntries(entries);
			return;
		}
		setFilteredEntries(
			entries.filter((branch) =>
				branch.name.toLowerCase().includes(search.trim().toLowerCase())
			)
		);
	}, 300);
	useEffect(() => {
		handleSearch();
	}, [search]);

	useEffect(() => {
		isEmpty(search) && setFilteredEntries(() => entries);
		if (
			entries.filter(({ checked }) => checked === false).length ===
			entries.length
		)
			setSelected(false);
	}, [entries]);

	const handleSelectAll = () => {
		setSelected(!selected);
		entries.forEach((e) => onEntryClick(e, !selected));
	};

	const MainCheckbox = ({ field = { onChange: (v) => {} }, error }) => (
		<MultiCheckboxContainer
			className={cx(error && styles.errorCheckbox)}
			selectAll={selected}
			onSelectAll={() => {
				field &&
					field.onChange(
						entries
							.map((e) => {
								e.checked = !selected;
								return e;
							})
							.filter((e) => e.checked)
					);
				handleSelectAll();
			}}
			selectAllLabel="Select All Branches"
			disabled={disabled || disabledIfAllHasWallet}
		>
			<InputWithIcon
				placeholder="Search Branch"
				value={search}
				onChange={handleSearchChange}
				icons={[
					{
						path: 'utility/search',
						isLeft: true,
					},
				]}
				disabled={disabled}
			/>
			<div className={cx('slds-grid', styles.listBody)}>
				{entries && entries.length > 0 ? (
					<>
						<div className="slds-grid_vertical slds-col slds-size_1-of-2">
							<BranchPortion
								isShowTooltip={isShowTooltip}
								entries={filteredEntries.filter((_, i) => i <= halfIndex)}
								onEntryClick={(e, selected) => {
									const result = onEntryClick(e, selected);
									field && field.onChange(result);
								}}
								disabled={disabled}
							/>
						</div>
						<div className="slds-grid_vertical slds-col slds-col slds-size_1-of-2">
							<BranchPortion
								isShowTooltip={isShowTooltip}
								entries={filteredEntries.filter((_, i) => i > halfIndex)}
								onEntryClick={(e, selected) => {
									const result = onEntryClick(e, selected);
									field && field.onChange(result);
								}}
								disabled={disabled}
							/>
						</div>
					</>
				) : (
					<div className={styles.noResult}>
						<img src={folderIcon} alt="Folder Icon" />
						<span>No Branch Found.</span>
					</div>
				)}
			</div>
		</MultiCheckboxContainer>
	);

	return name && control ? (
		<Controller
			name={name}
			control={control}
			render={(props) => {
				const {
					field,
					fieldState: { error },
				} = props;
				return (
					<>
						{MainCheckbox({ field, error })}
						{error && (
							<div className={cx('slds-has-error')}>
								<div className={cx(styles.helper, 'slds-form-element__help')}>
									{error.message}
								</div>
							</div>
						)}
					</>
				);
			}}
		/>
	) : (
		MainCheckbox({ error: '' })
	);
};

export default BranchCheckbox;
