import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import {
	BranchAutosaveResponseType,
	BranchResponseType,
} from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import { OPERATING_SCHEDULE } from 'containers/ChannelManagement/Branch/constants';
import moment from 'moment';
import { FixMeLater } from 'types';

export const transformBranchResponseToBranchType = (
	response: BranchResponseType,
	channelPrimaryInfo: FixMeLater
): BranchType => {
	const transformed: BranchType = {
		branchBasicInformation: {
			channelAccountName: channelPrimaryInfo?.name || '',
			channelAccountCode: channelPrimaryInfo?.tpaAccount || '',
			branchName: response?.data?.name || '',
			branchCode: response?.data?.branchCode || '',
			channelTransactionType:
				response?.data?.branchPrimaryInfos?.channelTransactionType || '',
			channelTypeId: String(
				response?.data?.branchPrimaryInfos?.channelTypeId || ''
			),
			posType: response?.data?.branchPrimaryInfos?.posType || '',
			settlementSetup: channelPrimaryInfo?.settlementSetup || '',
			storeType: response?.data?.branchPrimaryInfos?.storeType || '',
			storeFormat: response?.data?.branchPrimaryInfos?.storeFormat || '',
			machineLocation:
				response?.data?.branchPrimaryInfos?.machineLocation || '',
			pldtTellerCode: response?.data?.branchPrimaryInfos?.pldtCode || '',
			cignalTellerCode: response?.data?.branchPrimaryInfos?.cignalCode || '',
			meralcoPaymentCenterCode: String(
				response?.data?.branchPrimaryInfos?.meralcoPaymentCenterId || ''
			),
			remarksChanges: '',
		},
		branchAddress: {
			locationBase: response?.data?.branchAddresses[0]?.locationBase || '',
			country: String(response?.data?.branchAddresses[0]?.countryId || ''),
			area: response?.data?.branchAddresses[0]?.area || '',
			region: String(response?.data?.branchAddresses[0]?.regionId || ''),
			stateOrProvince: String(
				response?.data?.branchAddresses[0]?.provinceId || ''
			),
			municipalityOrCity: String(
				response?.data?.branchAddresses[0]?.cityId || ''
			),
			barangay: String(response?.data?.branchAddresses[0]?.barangayId || ''),
			buildingNameOrNumber:
				response?.data?.branchAddresses[0]?.buildingName || '',
			street: response?.data?.branchAddresses[0]?.street || '',
			zipCode: String(response?.data?.branchAddresses[0]?.zipCodeId || ''),
		},
		branchContactDetails:
			response?.data?.branchContactDetails.map((item) => {
				return {
					name: item?.name || '',
					department: item?.department || '',
					emailAddress: item?.emailAddress
						? item?.emailAddress?.split(',')
						: [],
					mobileNumber: item?.mobileNo ? item?.mobileNo?.split(',') : [],
					position: item?.position,
					telephoneNumber: item?.telNo ? item.telNo.split(',') : [],
				};
			}) || [],
		branchOperatingSchedule: {
			schedule: structuredClone(OPERATING_SCHEDULE).map((schedule) => {
				const filteredSchedule =
					response?.data?.branchOperatingSchedule?.filter(
						(savedSchedule) => schedule.day === savedSchedule.days
					)[0];
				if (filteredSchedule) {
					schedule.day = filteredSchedule.days || '';
					schedule.startTime = filteredSchedule.startTime
						? moment(filteredSchedule.startTime, ['HH:mm:ss']).format('HH:mm')
						: null;
					schedule.endTime = filteredSchedule.endTime
						? moment(filteredSchedule.endTime, ['HH:mm:ss']).format('HH:mm')
						: null;
					schedule.isSelected = true;
				}
				return schedule;
			}),
			depositConsolidation:
				response?.data?.branchPrimaryInfos?.depositConsolidation || '',
			taxPayerType: response?.data?.branchPrimaryInfos?.taxPayerType || '',
			activationDate:
				moment(response?.data?.branchPrimaryInfos?.activationDate).format(
					'MM/DD/YYYY'
				) || '',
			remarks: response?.data?.branchPrimaryInfos?.remarks || '',
		},
	};
	return transformed;
};

export const transformAutosaveResponseToBranchType = (
	response: BranchAutosaveResponseType
): BranchType => {
	const transformed: BranchType = {
		branchBasicInformation: {
			channelAccountName: '',
			channelAccountCode: '',
			branchName: response?.data?.primaryInfo.branchName || '',
			branchCode: '',
			channelTransactionType:
				response?.data?.primaryInfo?.channelTransactionType,
			channelTypeId: String(response?.data?.primaryInfo?.channelTypeId || ''),
			posType: response?.data?.primaryInfo?.posType || '',
			settlementSetup: response?.data?.primaryInfo?.settlementSetup || '',
			storeType: response?.data?.primaryInfo?.storeType || '',
			storeFormat: response?.data?.primaryInfo?.storeFormat || '',
			machineLocation: response?.data?.primaryInfo?.machineLocation || '',
			pldtTellerCode: response?.data?.primaryInfo?.pldtCode || '',
			cignalTellerCode: response?.data?.primaryInfo?.cignalCode || '',
			meralcoPaymentCenterCode: String(
				response?.data?.primaryInfo?.meralcoPaymentCenterId || ''
			),
			remarksChanges: '',
		},
		branchAddress: {
			locationBase:
				response?.data?.primaryInfo.branchAddresses[0]?.locationBase || '',
			country: String(
				response?.data?.primaryInfo.branchAddresses[0]?.countryId || ''
			),
			area: response?.data?.primaryInfo.branchAddresses[0]?.area || '',
			region: String(
				response?.data?.primaryInfo.branchAddresses[0]?.regionId || ''
			),
			stateOrProvince: String(
				response?.data?.primaryInfo.branchAddresses[0]?.provinceId || ''
			),
			municipalityOrCity: String(
				response?.data?.primaryInfo.branchAddresses[0]?.cityId || ''
			),
			barangay: String(
				response?.data?.primaryInfo.branchAddresses[0]?.barangayId || ''
			),
			buildingNameOrNumber:
				response?.data?.primaryInfo.branchAddresses[0]?.buildingName || '',
			street: response?.data?.primaryInfo.branchAddresses[0]?.street || '',
			zipCode: String(
				response?.data?.primaryInfo.branchAddresses[0]?.zipCodeId || ''
			),
		},
		branchContactDetails:
			response?.data?.primaryInfo.branchContactDetails.map((item) => {
				return {
					name: item?.name || '',
					department: item?.department || '',
					emailAddress: item?.emailAddress || [],
					mobileNumber: item?.mobileNo || [],
					position: item?.position,
					telephoneNumber: item?.telNo || [],
				};
			}) || [],
		branchOperatingSchedule: {
			schedule: structuredClone(OPERATING_SCHEDULE).map((schedule) => {
				const filteredSchedule =
					response?.data?.primaryInfo.branchOperatingSchedule?.filter(
						(savedSchedule) =>
							schedule.day.toUpperCase() === savedSchedule.days.toUpperCase()
					)[0];
				if (filteredSchedule) {
					schedule.day = filteredSchedule.days || '';
					schedule.startTime = filteredSchedule.startTime || null;
					schedule.endTime = filteredSchedule.endTime || null;
					schedule.isSelected = true;
				}
				return schedule;
			}),
			depositConsolidation:
				response?.data?.primaryInfo?.depositConsolidation || '',
			taxPayerType: response?.data?.primaryInfo?.taxPayerType || '',
			activationDate:
				moment(response?.data?.primaryInfo?.activationDate).format(
					'MM/DD/YYYY'
				) || '',
			remarks: response?.data?.primaryInfo?.remarks || '',
		},
	};
	return transformed;
};
