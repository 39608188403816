import { UsePartnerTabQueryKey } from 'utils/queries/partner';

type TPartnerModalTabs = {
	index: number;
	name: string;
	formTab: UsePartnerTabQueryKey;
	label?: string;
};
export const channelLogsModalTabs: Array<TPartnerModalTabs> = [
	{
		index: 0,
		name: 'primary-info',
		formTab: 'primaryInfo',
		label: 'Primary Information',
	},
	{
		index: 1,
		name: 'contract-details',
		formTab: 'contractDetails',
		label: 'Contract Details',
	},
	{ 
		index: 2, 
		name: 'reports', 
		formTab: 'reports', 
		label: 'Reports' 
	},
];

export const findAuditTab = (name: string): TPartnerModalTabs | undefined => {
	return channelLogsModalTabs.find((v) => v.name === name);
};

export const findTabLabel = (label: string): TPartnerModalTabs | undefined => {
	return channelLogsModalTabs.find((v) => v.label === label);
};
