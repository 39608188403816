import React from 'react';
import styles from './PrimaryButton.module.css';
import cx from 'classnames';
import Button from './Button';
import { ButtonProps } from 'types';

type Props = ButtonProps & {
	onClick: () => void;
	className?: string;

	[rest: string]: any;
};

const PrimaryButton: React.FC<Props> = ({
	onClick,
	className = '',
	children,
	...rest
}) => {
	return (
		<Button
			className={cx(styles.primary, 'slds-button_brand', className)}
			onClick={onClick}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default PrimaryButton;
