import React from 'react';
import styles from './OutlineButton.module.css';
import cx from 'classnames';
import Button from './Button';
import { ButtonProps } from 'types';

type Props = ButtonProps & {
	className?: string;
	btnClassStyle?: string;
	onMouseEnter?;
	onMouseLeave?;
	onMouseOver?;
	onMouseOut?;
};

const OutlineButton: React.FC<Props> = ({
	children,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onMouseOver,
	onMouseOut,
	className = '',
	btnClassStyle = '',
	...rest
}) => {
	const btnStyle = btnClassStyle == '' ? styles.outline : styles.outline2;

	return (
		<Button
			className={cx(btnStyle, 'slds-button_brand', className)}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default OutlineButton;
