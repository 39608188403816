import * as yup from 'yup';
import { objectSchema as primaryInfo } from './ChannelBasicInformation/ChannelBasicInformationSchema';
import { YupLab } from 'utils/yupLab';
import { InferType } from 'yup';

export const objectSchema = yup.object({
	primaryInfo
});

export const PrimaryInformationSchema = new YupLab(objectSchema);
export const primartyInfoInitlValues = PrimaryInformationSchema.schema.cast({});
export type PrimaryInfoType = InferType<typeof objectSchema>;
