import { ExpandableButton } from 'containers/CentralWalletSystem/WalletManagement/WalletAdjustment/ExpandableButton';
import useOpenController from 'containers/CentralWalletSystem/WalletManagement/WalletAdjustment/useOpenController';
import styles from './WalletAdjustment.module.css';
import ExpandedRow from './ExpandedRow';
import { useState } from 'react';

export const ExpandedSection = (props) => {
	const { isOpen, toggle } = useOpenController(false);
	const { onApprove, onDecline, isAllowedApproval, status } = props;
	return (
		<>
			<tbody>
				<tr>
					<td>
						<ExpandableButton isOpen={isOpen} toggle={toggle} />
					</td>
				</tr>
			</tbody>
			<br />
			<div>
				{isOpen && (
					<ExpandedRow
						data={props.data}
						onApprove={onApprove}
						onDecline={onDecline}
						isAllowedApproval={isAllowedApproval}
						status={status}
					/>
				)}
			</div>
		</>
	);
};
