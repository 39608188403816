import React, { useEffect, useRef, useState } from 'react';

import ExpandedSection from 'components/CWSTable/ExpandableRow/ExpandedSection';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import client from 'helpers/ApiClient';
import { formatParams } from 'utils/common';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';

import PartnerDetailsTable from './PartnerDetailsTable';
import ActionCell from './ActionCell';
import HeadExpandableRow from './HeadExpandableRow';

import {
	PartnerBranchTpaProps,
	PartnerDetailsResp,
	TableProps,
	TableRef,
} from './types';
import styles from './PartnerDetailsBody.module.css';

const TABLE_WIDTH = 97 / 3;

const BRANCH_TPA_COLUMNS = (setRefetchData, width) => {
	return [
		{
			Header: '',
			id: 'expandButton',
			width: '3%',
			Cell: ({ row }) => {
				return (
					<ExpandedSection
						disabled={!row?.original.branches}
						styles={styles}
						className={styles.expandedButtons}
					>
						<HeadExpandableRow
							data={row?.original.branches}
							toggleActiveValue="ACTIVE"
							partnerId={row?.original?.partnerId}
							channelId={row?.original?.channelId}
							setRefetchData={setRefetchData}
							width={width}
						/>
					</ExpandedSection>
				);
			},
		},
		{
			Header: 'TPA Account/Branch/TPAID',
			id: 'name',
			sortable: true,
			width: `${TABLE_WIDTH}%`,
			Cell: ({ value = '' }) => <div className={styles.idCell}>{value}</div>,
		},
		{
			Header: 'BFA Status',
			id: 'isEnabled',
			sortable: true,
			width: `${TABLE_WIDTH}%`,
			Cell: ({ value = '' }) => (
				<div className={value ? styles.statusEnabled : styles.statusDisabled}>
					{value ? 'Enabled' : 'Disabled'}
				</div>
			),
		},
		{
			Header: () => (
				<div
					style={{
						marginLeft: '125px',
					}}
				>
					Action
				</div>
			),
			id: 'actions',
			width: `${TABLE_WIDTH}%`,
			Cell: ({ row }) => (
				<div style={{ marginLeft: '40%' }}>
					<ActionCell
						name={row?.values.tpaName}
						initialToggleValue={row?.values.isEnabled ? true : false}
						confirmModalContents={{
							header: 'TPA Account',
							bodySubHeader: 'TPA Account',
							bodyHeader: row?.values.name,
						}}
						isBodySubHeader
						partnerId={row?.original?.partnerId}
						channelId={row?.original?.channelId}
						setRefetchData={setRefetchData}
					/>
				</div>
			),
		},
	];
};

const PartnerBranchTpa: React.FC<PartnerBranchTpaProps> = ({
	productId,
	partnerId,
	refetchData,
	setRefetchData,
	fetchPartnerCounts,
}) => {
	const [partnerDetails, setPartnerDetails] = useState<any>();
	const [filter, setFilter] = useState<any>({});
	const [width, setWidth] = useState<number>(0);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const tableReference = useRef<TableRef>(null);

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const fetchPartnerDetails = async (params) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const [response, fetchCollection, fetchVolume] = await Promise.all([
				client.get(
					`/v2/bfa-admin-portal/products/partner-details/${partnerId}`,
					{
						params: {
							...params,
							id: productId,
						},
					}
				),
				fetchPartnerCounts({ type: 'collection' }),
				fetchPartnerCounts({ type: 'volume' }),
			]);
			if (fetchCollection) {
				throw new Error(fetchCollection);
			}
			if (fetchVolume) {
				throw new Error(fetchVolume);
			}

			setPartnerDetails(response?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchPartnerDetails(params)
			);
		} finally {
			hideLoading();
			setRefetchData(false);
		}
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchPartnerDetails(newFilter);
	};

	const tablePaginationProps = useTablePagination(
		handleTableFetch,
		partnerDetails?.meta
	);

	const tableProps: TableProps = {
		columns: BRANCH_TPA_COLUMNS(setRefetchData, width),
		data: partnerDetails?.data,
		className: styles.tableContainerTabContent,
		isAllRowColorWhite: true,
		ref: tableReference,
		...tablePaginationProps,
	};

	const filterProps = {
		isSearch: true,
		padding: '0 0 1vw 0',
		onSearch: handleTableFetch,
	};

	useEffect(() => {
		fetchPartnerDetails({});
	}, []);

	useEffect(() => {
		if (refetchData) {
			fetchPartnerDetails({});
		}
	}, [refetchData]);

	// This is solely intended to obtain the table width for aligning it with the width of the expandedSection component.
	// This will update width when the screen has changed and set to expandedSection > HeadExpandableRow for component resizing.
	useEffect(() => {
		const updateParentWidth = () => {
			if (tableReference.current) {
				setWidth(tableReference.current.clientWidth);
			}
		};

		window.addEventListener('resize', updateParentWidth);
		updateParentWidth();

		return () => {
			window.removeEventListener('resize', updateParentWidth);
		};
	}, []);

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<PartnerDetailsTable tableProps={tableProps} filterProps={filterProps} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};
export default PartnerBranchTpa;
