import Grid, { TGrid } from 'components/Grid/Grid';
import cx from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './Section.module.css';

type Props = {
	onClick?: () => void;
	title?: string | ReactNode;
	className?: string;
	titleClassName?: string;
	children?: ReactNode;
	required?: boolean;
};

export const SectionTitle: React.FC = ({ children }) => {
	return <div className={styles.title}>{children}</div>;
};

const Section: React.FC<Props> = ({
	onClick,
	title: Title,
	className,
	titleClassName,
	children,
	required = false,
}) => {
	return (
		<div
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			onKeyDown={()=>{return}}
			className={cx(styles.container + ' ' + (className))}
		>
			{Title && (
				<>
					<div className={titleClassName ? titleClassName : styles.title}>
						{typeof Title === 'function' ? <Title /> : Title}
						{required && <span style={{ color: 'red' }}>*</span>}
					</div>
				</>
			)}
			<div>{children}</div>
		</div>
	);
};

export const SectionRow: React.FC<TGrid> = ({
	children,
	className = '',
	...rest
}) => (
	<Grid
		container
		gutters
		verticalAlign="start"
		className={cx(styles.row, className)}
		{...rest}
	>
		{children}
	</Grid>
);

export default Section;
