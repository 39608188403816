import React, { ReactNode, useEffect, useState } from 'react';
import styles from './AuditTrailLogs.module.css';

import HTTP from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import { filterArrayValues, handleArrayWithSpaceValues } from 'utils/filter';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useDispatch } from 'react-redux';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import Table from 'components/CWSTable/Table';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import { Modal } from '@salesforce/design-system-react';

const AUDIT_LOG_COLUMNS = [
	{
		Header: 'Field Name',
		id: 'field_name',
		sortable: false,
		width: '24%',
	},
	{
		Header: 'Old Values',
		id: 'old_values',
		sortable: false,
		width: '38%',
	},
	{
		Header: 'New Values',
		id: 'new_values',
		sortable: false,
		width: '38%',
	},
];

type Props = {
	modalOpen: boolean;
	onClose: () => void;
	logid: string | number;
	reportid: string | number;
	tabName: string;
};

type dataProps = {
	field_name: string;
	old_values: ReactNode;
	new_values: ReactNode;
}[];

const getAuditTrailsLogs = async function (
	reportid: number | string,
	logid: number | string,
	tabName: string
) {
	const result = await HTTP.get(
		`/v2/report-maintenance/${tabName.toLowerCase()}/report-details/${reportid}/audit-trails/${logid}`
	);

	return result.data;
};

const AuditTrailLogs: React.FC<Props> = ({
	modalOpen,
	onClose,
	logid,
	reportid,
	tabName,
}) => {
	const [data, setData] = useState<dataProps>([]);
	const dispatch = useDispatch();
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });

	const errorMessageDuringLoading = 'A problem occurred with loading the data';
	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const viewLogsData = (data) => {
		const detailsViewData = {
			field_name: 'Details',
			old_values: (
				<div>
					{data?.details?.reportName && (
						<div>
							<div className={styles.fieldTitle}>
								Report Name:
								<span className={styles.fieldValue}>
									{data?.details?.reportName?.oldValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.fileNameFormat && (
						<div>
							<div className={styles.fieldTitle}>
								File Name Format:
								<span className={styles.fieldValue}>
									{data?.details?.fileNameFormat?.oldValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.modeOfTransmission && (
						<div>
							<div className={styles.fieldTitle}>
								Mode of Transmission:
								<span className={styles.fieldValue}>
									{data?.details?.modeOfTransmission?.oldValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.businessReportCategory && (
						<div>
							<div className={styles.fieldTitle}>Category:</div>
							{data?.details?.businessReportCategory &&
								data?.details?.businessReportCategory?.oldValue.map(
									({ businessReportCategory }) => (
										<div
											key={businessReportCategory}
											className={styles.listIndent}
										>
											{businessReportCategory}
										</div>
									)
								)}
						</div>
					)}
					{data?.details?.emailRecipients && (
						<div>
							<div className={styles.fieldTitle}>Email Recipients:</div>
							{data?.details?.emailRecipients?.oldValue
								.split(',')
								.map((el: string) => (
									<div key={el} className={styles.listIndent}>
										{el}
									</div>
								))}
						</div>
					)}
					{data?.details?.status && (
						<div>
							<div className={styles.fieldTitle}>
								Status:
								<span className={styles.fieldValue}>
									{data?.details?.status?.oldValue &&
									parseInt(data.details.status.oldValue) === 1
										? 'Enabled'
										: 'Disabled'}
								</span>
							</div>
						</div>
					)}
				</div>
			),
			new_values: (
				<div>
					{data?.details?.reportName && (
						<div>
							<div className={styles.fieldTitle}>
								Report Name:
								<span className={styles.fieldValue}>
									{data?.details?.reportName?.newValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.fileNameFormat && (
						<div>
							<div className={styles.fieldTitle}>
								File Name Format:
								<span className={styles.fieldValue}>
									{data?.details?.fileNameFormat?.newValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.modeOfTransmission && (
						<div>
							<div className={styles.fieldTitle}>
								Mode of Transmission:
								<span className={styles.fieldValue}>
									{data?.details?.modeOfTransmission?.newValue}
								</span>
							</div>
						</div>
					)}
					{data?.details?.businessReportCategory && (
						<div>
							<div className={styles.fieldTitle}>Category:</div>
							{data?.details?.businessReportCategory &&
								data?.details?.businessReportCategory?.newValue.map(
									({ businessReportCategory }) => (
										<div
											key={businessReportCategory}
											className={styles.listIndent}
										>
											{businessReportCategory}
										</div>
									)
								)}
						</div>
					)}
					{data?.details?.emailRecipients && (
						<div>
							<div className={styles.fieldTitle}>Email Recipients:</div>
							{data?.details?.emailRecipients?.newValue
								.split(',')
								.map((el: string) => (
									<div key={el} className={styles.listIndent}>
										{el}
									</div>
								))}
						</div>
					)}
					{data?.details?.status && (
						<div>
							<div className={styles.fieldTitle}>
								Status:
								<span className={styles.fieldValue}>
									{data?.details?.status?.newValue &&
									parseInt(data.details.status.newValue) === 1
										? 'Enabled'
										: 'Disabled'}
								</span>
							</div>
						</div>
					)}
				</div>
			),
		};

		const scheduleViewData = {
			field_name: 'Report Generation Schedule',
			old_values: (
				<>
					{data?.schedule?.frequency && (
						<div>
							<div className={styles.fieldTitle}>
								Frequency:
								<span className={styles.fieldValue}>
									{data?.schedule?.frequency.oldValue}
								</span>
							</div>
						</div>
					)}
					{data?.schedule?.frequencyType && (
						<div>
							<div className={styles.fieldTitle}>
								Frequency Type:
								<span className={styles.fieldValue}>
									{data?.schedule?.frequencyType.oldValue}
								</span>
							</div>
						</div>
					)}
					{data?.schedule?.scheduleTime && (
						<div>
							<div className={styles.fieldTitle}>Schedule Time:</div>
							{data?.schedule?.scheduleTime?.oldValue.map((el) => (
								<div key={el} className={styles.listIndent}>
									{el.dayOfMonth && <span>Day: {el.dayOfMonth} </span>}
									{el.monthOfYear && <span>Month: {el.monthOfYear} </span>}
									{el.time && <span>Time: {el.time} </span>}
								</div>
							))}
						</div>
					)}
				</>
			),
			new_values: (
				<>
					{data?.schedule?.frequency && (
						<div>
							<div className={styles.fieldTitle}>
								Frequency:
								<span className={styles.fieldValue}>
									{data?.schedule?.frequency.newValue}
								</span>
							</div>
						</div>
					)}
					{data?.schedule?.frequencyType && (
						<div>
							<div className={styles.fieldTitle}>
								Frequency Type:
								<span className={styles.fieldValue}>
									{data?.schedule?.frequencyType.newValue}
								</span>
							</div>
						</div>
					)}
					{data?.schedule?.scheduleTime && (
						<div>
							<div className={styles.fieldTitle}>Schedule Time:</div>
							{data?.schedule?.scheduleTime?.newValue.map((el) => (
								<div key={el} className={styles.listIndent}>
									{el.dayOfMonth && <span>Day: {el.dayOfMonth} </span>}
									{el.monthOfYear && <span>Month: {el.monthOfYear} </span>}
									{el.time && <span>Time: {el.time} </span>}
								</div>
							))}
						</div>
					)}
				</>
			),
		};

		const logsdata: dataProps = [];
		if (Object.values(data.details).length > 0) logsdata.push(detailsViewData);
		if (Object.values(data.schedule).length > 0)
			logsdata.push(scheduleViewData);

		return logsdata;
	};

	//old view, do not delete can be use in the future
	const getLogsData_old_backup = (data) => {
		const detailsFields = [
			{ key: 'reportName', label: 'Report Name' },
			{ key: 'fileNameFormat', label: 'File Name Format' },
			{ key: 'modeOfTransmission', label: 'Mode of Transmission' },
			{ key: 'emailRecipients', label: 'Email Recipients' },
			{ key: 'status', label: 'Status' },
		];

		const scheduleFields = [
			{ key: 'frequency', label: 'Frequency' },
			{ key: 'frequencyType', label: 'Frequency Type' },
		];

		const detailsData = detailsFields
			.map((field) => {
				return {
					field_name: field.label,
					old_values:
						field.key === 'status' && data?.details[field.key]
							? parseInt(
									handleArrayWithSpaceValues(
										data?.details[field.key]?.oldValue.replaceAll(',', ',  ')
									)
							  ) === 1
								? 'Enabled'
								: 'Disabled'
							: handleArrayWithSpaceValues(
									data?.details[field.key]?.oldValue.replaceAll(',', ',  ')
							  ),
					new_values:
						field.key === 'status' && data?.details[field.key]
							? parseInt(
									handleArrayWithSpaceValues(
										data?.details[field.key]?.newValue.replaceAll(',', ',  ')
									)
							  ) === 1
								? 'Enabled'
								: 'Disabled'
							: handleArrayWithSpaceValues(
									data?.details[field.key]?.newValue.replaceAll(',', ',  ')
							  ),
				};
			})
			.flat()
			.filter(filterArrayValues);

		const scheduleData = scheduleFields
			.map((field) => {
				return {
					field_name: field.label,
					old_values: handleArrayWithSpaceValues(
						data?.schedule[field.key]?.oldValue
					),
					new_values: handleArrayWithSpaceValues(
						data?.schedule[field.key]?.newValue
					),
				};
			})
			.flat()
			.filter(filterArrayValues);

		const maxLengthScheduleTime = data?.schedule?.scheduleTime
			? Math.max(
					data?.schedule?.scheduleTime?.oldValue.length,
					data?.schedule?.scheduleTime?.newValue.length
			  )
			: 0;

		const maxLengthReportCategory = data?.details?.businessReportCategory
			? Math.max(
					data?.details?.businessReportCategory?.oldValue.length,
					data?.details?.businessReportCategory?.newValue.length
			  )
			: 0;

		const scheduleTimeData: {
			field_name: string;
			new_values: string | ReactNode;
			old_values: string | ReactNode;
		}[] = [];

		const reportCategoryData: {
			field_name: string;
			new_values: string | ReactNode;
			old_values: string | ReactNode;
		}[] = [];

		if (maxLengthScheduleTime > 0) {
			for (let i = 0; i < maxLengthScheduleTime; i++) {
				const dayOfMonth_oldVal =
					data?.schedule?.scheduleTime?.oldValue[i]?.dayOfMonth || 'N/A';
				const monthOfYear_oldVal =
					(data?.schedule?.scheduleTime?.oldValue[i]?.monthOfYear &&
						data?.schedule?.scheduleTime?.oldValue[i]?.monthOfYear
							.toString()
							.charAt(0)
							.toUpperCase() +
							data?.schedule?.scheduleTime?.oldValue[i]?.monthOfYear
								.slice(1)
								.toLowerCase()) ||
					'N/A';
				const time_oldVal =
					data?.schedule?.scheduleTime?.oldValue[i]?.time || 'N/A';

				const dayOfMonth_newVal =
					data?.schedule?.scheduleTime?.newValue[i]?.dayOfMonth || 'N/A';
				const monthOfYear_newVal =
					(data?.schedule?.scheduleTime?.newValue[i]?.monthOfYear &&
						data?.schedule?.scheduleTime?.newValue[i]?.monthOfYear
							.toString()
							.charAt(0)
							.toUpperCase() +
							data?.schedule?.scheduleTime?.newValue[i]?.monthOfYear
								.slice(1)
								.toLowerCase()) ||
					'N/A';
				const time_newVal =
					data?.schedule?.scheduleTime?.newValue[i]?.time || 'N/A';

				scheduleTimeData.push({
					field_name: `Schedule Time (${i + 1}\\${maxLengthScheduleTime})`,
					new_values: data?.schedule?.scheduleTime?.newValue[i]
						? `Day: ${dayOfMonth_newVal}, Month: ${monthOfYear_newVal}, Time: ${time_newVal}`
						: `N/A`,
					old_values: data?.schedule?.scheduleTime?.oldValue[i]
						? `Day: ${dayOfMonth_oldVal}, Month: ${monthOfYear_oldVal}, Time: ${time_oldVal}`
						: `N/A`,
				});
			}
		}
		if (maxLengthReportCategory > 0) {
			for (let i = 0; i < maxLengthReportCategory; i++) {
				reportCategoryData.push({
					field_name: `Category (${i + 1}\\${maxLengthReportCategory})`,
					new_values: data?.details?.businessReportCategory?.newValue[i]
						? data?.details?.businessReportCategory?.newValue[i]
								.businessReportCategory
						: `-`,
					old_values: data?.details?.businessReportCategory?.oldValue[i]
						? data?.details?.businessReportCategory?.oldValue[i]
								.businessReportCategory
						: `-`,
				});
			}
		}

		return [
			detailsData,
			reportCategoryData,
			scheduleData,
			scheduleTimeData.flat(),
		];
	};

	const fetchAuditTrailLogsList = async (retry = false, withLoader = true) => {
		const doRequest = async () => {
			try {
				withLoader &&
					showLoadingMessage(
						'Please wait while audit trail logs are being loaded'
					);
				const result = await getAuditTrailsLogs(reportid, logid, tabName);

				const tabledata = viewLogsData(result.data.auditDetail);
				setData(tabledata);
			} catch (e: any) {
				console.log(e);
				if (typeof e === 'object' && e.includes('403')) {
					dispatch(showAccessDeniedModal());
					return;
				}
				if (withLoader) {
					showErrorMessage(errorMessageDuringLoading);
					setRetryBtnOnClick({
						action: () => {
							fetchAuditTrailLogsList(retry, withLoader);
						},
					});
					return;
				}
				throw e;
			} finally {
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest();
			return;
		}

		await doRequest();
	};

	useEffect(() => {
		fetchAuditTrailLogsList();
	}, [logid]);

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<Modal
				size="medium"
				isOpen={modalOpen}
				ariaHideApp={false}
				onRequestClose={onClose}
				contentClassName={styles.partnerAuditModal}
				appElement={document.getElementById('app')}
				containerClassName={styles.partnerAuditModalContainer}
				headerClassName={styles.partnerAuditModalHeaderContainer}
				heading={
					<div className={styles.header}>
						<div className={styles.headerLeftPanel}>
							<div className={styles.headerTitle}>View Logs</div>
						</div>
						<div className={styles.headerRightPanel}></div>
					</div>
				}
			>
				<Table
					columns={AUDIT_LOG_COLUMNS}
					data={data}
					showPagination={false}
					modalIsOpen={false}
				/>
			</Modal>
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Timeout Error!</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</>
	);
};

export default AuditTrailLogs;
