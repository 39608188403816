import HTTP from 'helpers/ApiClient';
import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { resolveValue } from 'utils/common';

type Location = {
	id: string;
	name: string;
};

export type Country = Location & {
	country_code: string;
};

export type Province = Location & {
	region_id: string;
};

export type City = Location & {
	province_id: string;
};

export type Barangay = Location & {
	city_id: string;
};

export type Region = Location & {
	region_id: string;
};

export type ZipCode = Location & {
	barangay_id: string;
};

function convertArgs(args: unknown[]): (string | undefined)[] {
	return args.map((s) => (s ? (s as string) : undefined));
}

async function getCountries(page?: number, limit?: number): Promise<Country[]> {
	const params = { page: resolveValue(page), limit: resolveValue(limit) };
	const response = await HTTP.get('/v2/countries', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch countries');
	return list;
}

async function getProvinces(
	page?: number,
	limit?: number
): Promise<Province[]> {
	const params = { page: resolveValue(page), limit: resolveValue(limit) };
	const response = await HTTP.get('/v2/provinces', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch provinces');
	return list;
}

async function getProvincesFilter(
	regionId?: string,
	page?: number,
	limit?: number
): Promise<Province[]> {
	const params = {
		page: resolveValue(page),
		limit: resolveValue(limit),
		region_id: resolveValue(regionId),
	};
	const response = await HTTP.get('/v2/provinces', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch provinces');
	return list;
}

async function getCities(
	provinceId?: string,
	page?: number,
	limit?: number
): Promise<City[]> {
	const params = {
		page: resolveValue(page),
		limit: resolveValue(limit),
		province_id: resolveValue(provinceId),
	};
	const response = await HTTP.get('/v2/cities', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch cities');
	return list;
}

async function getBarangays(
	cityId?: string,
	page?: number,
	limit?: number
): Promise<Barangay[]> {
	const params = {
		page: resolveValue(page),
		limit: resolveValue(limit),
		city_id: resolveValue(cityId),
	};
	const response = await HTTP.get('/v2/barangays', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch barangays');
	return list;
}

async function getRegions(
	page?: number, 
	limit?: number
): Promise<Province[]> {
	const params = { page: resolveValue(page), limit: resolveValue(limit) };
	const response = await HTTP.get('/v2/regions', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch regions');

	return list;
}

async function getZipCodes(
	barangayId?: string,
	page?: number, 
	limit?: number): 
Promise<ZipCode[]> {
	const params = { 
		page: resolveValue(page), 
		limit: resolveValue(limit),
		barangay_id: resolveValue(barangayId)
	};
	const response = await HTTP.get('/v2/zipcodes', { params });
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch zip codes');
	return list;
}

export function useCountryQuery(
	queryKey: 'countries' | string = 'countries',
	params?: { page?: number; limit?: number },
	config?: UseQueryOptions<Country[]>
): UseQueryResult<Country[]> {
	return useQuery<Country[]>(
		[queryKey, params?.page, params?.limit],
		(_key, ...rest) => getCountries(...rest),
		config
	);
}

export function useProvinceQuery(
	queryKey: 'provinces' | string = 'provinces',
	params?: { regionId?: number; page?: number; limit?: number },
	config?: UseQueryOptions<Province[]>
): UseQueryResult<Province[]> {
	return useQuery<Province[]>(
		[queryKey, params?.page, params?.limit, params?.regionId],
		(_key, ...rest) => getProvinces(...rest),
		config
	);
}

export function useProvinceFilterQuery(
	queryKey: 'province' | string = 'provinces',
	params?: { regionId?: string; page?: number; limit?: number },
	config?: UseQueryOptions<Province[]>
): UseQueryResult<Province[]> {
	return useQuery<Province[]>(
		[queryKey, params?.regionId, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => {
			return getProvincesFilter(...convertArgs(rest));
		},
		config
	);
}

export function useCityQuery(
	queryKey: 'cities' | string = 'cities',
	params?: { provinceId?: string; page?: number; limit?: number },
	config?: UseQueryOptions<City[]>
): UseQueryResult<City[]> {
	return useQuery<City[]>(
		[queryKey, params?.provinceId, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => {
			return getCities(...convertArgs(rest));
		},
		config
	);
}

export function useCitiesQueries(
	queryKey: 'cities' | string = 'cities',
	params?: { provinceIds?: string[]; page?: number; limit?: number },
	config?: UseQueryOptions<City[]>
):  any[] {
	const queryResults =  useQueries(
		params?.provinceIds?.map((provinceId: string) => ({
			queryKey: [queryKey, provinceId, params?.page, params?.limit],
			queryFn: () => getCities(provinceId, params?.page, params?.limit),
			config
		})) || []
	)

	const isLoading = queryResults.some((result) => result.isLoading);

	return isLoading ? [] : queryResults.map((result) => result.data).flat();
}

export function useBarangayQuery(
	queryKey = 'barangays',
	params?: { cityId?: string; page?: number; limit?: number },
	config?: UseQueryOptions<Barangay[]>
): UseQueryResult<Barangay[]> {
	return useQuery<Barangay[]>(
		[queryKey, params?.cityId, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => {
			return getBarangays(...convertArgs(rest));
		},
		config
	);
}

export function useRegionQuery(
	queryKey: 'regions' | string = 'regions',
	params?: { page?: number; limit?: number },
	config?: UseQueryOptions<Region[]>
): UseQueryResult<Region[]> {
	return useQuery<Region[]>(
		[queryKey, params?.page, params?.limit],
		(_key, ...rest) => getRegions(...rest),
		config
	);
}

export function useZipCodeQuery(
	queryKey: 'zipcodes' | string = 'zipcodes',
	params: { barangayId: string; page?: number; limit?: number },
	config?: UseQueryOptions<ZipCode[]>
): UseQueryResult<ZipCode[]> {
	return useQuery<ZipCode[]>(
		[queryKey, params?.barangayId, params?.page, params?.limit],
		({ queryKey: [_key, ...rest] }) => getZipCodes(...convertArgs(rest)),
		config
	);
}
