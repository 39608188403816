import { Route } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import WalletManagement from './WalletManagement';

const CentralWalletSystem: React.FC = () => {
	return (
		<>
			<Route exact path="/wallet-system/" component={Dashboard} />
			<Route path="/wallet-system/management/" component={WalletManagement} />
		</>
	);
};
export default CentralWalletSystem;
