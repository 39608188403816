
import { CsrReportPreviewProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/types';
import styles from './style.module.css';
import { useSideHeaders } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/hooks';
import Headers from './Headers/Headers';
import SideHeaders from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/SideHeaders/SideHeaders';
import CsrReportTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/CsrReportTable/CsrReportTable';


const CsrReportPreview: React.FC<CsrReportPreviewProps> = ({
	sideHeaders,
	columns,
}) => {
	const sideHeaderValues = useSideHeaders(sideHeaders);

	return (
		<div className={styles.container}>
			<Headers />
			<SideHeaders values={sideHeaderValues} />
			<CsrReportTable entries={columns} />
		</div>
	);
};

export default CsrReportPreview;
