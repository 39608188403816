import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from '@salesforce/design-system-react';
import Label from 'components/Inputs/Label/Label';
import { RadioProps, RadioOption } from './types';

/**
 * RadioGroup Component
 *
 * This component creates a group of radio buttons. It uses react-hook-form
 * for managing form state and validation.
 *
 * @param {RadioProps} props - Properties passed to the component.
 * @param {string} props.name - Name of the radio group. Used for form identification.
 * @param {string} [props.label] - Label for the radio group. Optional.
 * @param {boolean} [props.disabled=false] - Indicates whether the radio group is disabled. Optional. False by default.
 * @param {string} [props.defaultValue=''] - Default value for the radio group. Optional. Empty by default.
 * @param {boolean} [props.required=false] - Indicates whether the radio group is required. Optional. False by default.
 * @param {RadioOption[]} [props.options=[]] - Options for the radio group. Optional. Empty array by default.
 * @returns {JSX.Element} - Rendered radio group component.
 */
const RadioGroup: React.FC<RadioProps> = ({
  name,
  label,
  disabled = false,
  defaultValue = '',
  required = false,
  options = [],
  onChange,
}) => {
  const { control } = useFormContext();

  /**
   * Handle radio button change event.
   *
   * @param {string} value - The selected value of the radio button.
   */
  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  if (control && name) {
    return (
      <>
        {label && <Label required={required}>{label}</Label>}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onChange: fieldOnChange } }) => (
            <>
              {options.map((option: RadioOption) => (
                <Radio
                  key={option.value}
                  labels={{ label: option.label }}
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => {
                    fieldOnChange(option.value);
                    handleChange(option.value);
                  }}
                  disabled={disabled}
                />
              ))}
            </>
          )}
        />
      </>
    );
  } else {
    return (
      <>
        {label && <Label required={required}>{label}</Label>}
        {options.map((option: RadioOption) => (
          <Radio
            key={option.value}
            labels={{ label: option.label }}
            value={option.value}
            checked={defaultValue === option.value}
            onChange={() => handleChange(option.value)}
            disabled={disabled}
          />
        ))}
      </>
    );
  }
};

export default RadioGroup;
