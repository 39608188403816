import moment from 'moment';
import styles from './WalletReplenishment.module.css';
import Table from '../../../../components/CWSTable/Table';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { DataTableProps } from 'types';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { useSingleSelection, useToggle } from 'utils/hooks';
import Button from 'components/Buttons/Button';
import NumberFormatter from 'components/Format/NumberFormatter';
import { capitalize } from 'lodash';
import { WalletReplenishmentServiceTypes } from 'utils/lookup';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import ExpandedSection from 'containers/CentralWalletSystem/WalletManagement/WalletReplenishment/ExpandedSection';

const formatDate = (date) => moment.unix(date).format('MM/DD/YYYY hh:mm:ss A');

export type Props = DataTableProps & {
	shouldAllowApproval: (status: string, amount: number) => boolean;
	hiddenColumns?: string[];
	onApprove: (id: string, wallet_type: string) => Promise<void>;
	onDecline: (id: string, wallet_type: string) => Promise<void>;
	isSearch: boolean;
};

export interface ICell {
	value: any;
	cell?: any;
}

export const Cell: React.FC<{ value: string | number }> = ({
	value,
}: ICell) => {
	return <div className={styles.voidId}>{value}</div>;
};
export type RowSelection = {
	amount: number;
	channelName: string;
	id: string;
	wallet_type: string;
};
const types = {
	cash: 'Cash',
	check: 'Check Deposit',
	online_payment: 'Online Payment',
	online_transfer: 'Online Transfer',
	auto_replenishment: 'Auto Replenishment via Bank',
};
export const WALLET_REPLENISHMENT_COLUMNS = (
	hiddenColumns: string[],
	shouldAllowApproval: Props['shouldAllowApproval'],
	onApprove: (row: RowSelection) => void,
	onDecline: (row: RowSelection) => void
) =>
	[
		{
			Header: '',
			id: 'expand_button',
			width: '3%',
			Cell: ({
				cell: {
					row: { original },
				},
			}: ICell) => {
				return (
					<div className={styles.button}>
						<ExpandedSection
							data={original}
							onApprove={onApprove}
							onDecline={onDecline}
							isAllowedApproval={
								original?.status &&
								!shouldAllowApproval(original.status, original.amount)
							}
							status={original.status}
						/>
					</div>
				);
			},
		},
		{
			Header: 'Date Created',
			accessor: ({ date_created }) => formatDate(date_created),
			id: 'date_created',
			sortable: true,
			Cell,
		},
		{
			Header: 'Wallet ID',
			id: 'wallet_id',
			Cell,
		},
		{
			Header: 'Wallet Type',
			id: 'wallet_type',
			Cell: ({ value }) => {
				const types = {
					cws: 'Product Wallet',
					bonded: 'Channel Wallet - Bonded',
					prefunded: 'Channel Wallet - Prefunded',
				};
				return Cell({ value: types[value] || '-' });
			},
		},
		{
			Header: 'Partner Name',
			id: 'partner_name',
			sortable: true,
			Cell: ({ value }) => {
				return Cell({ value: value || '-' });
			},
			width: '9%',
		},
		{
			Header: 'Request No.',
			id: 'request_number',
			Cell,
		},
		{
			Header: 'Transaction Type',
			id: 'transaction_type',
			accessor: ({ transaction_type }) =>
				WalletReplenishmentServiceTypes.find(
					({ value }) => value === transaction_type
				)?.label || '',
			Cell,
		},
		{
			Header: 'Amount',
			id: 'amount',
			numeric: true,
			Cell: ({ value }) =>
				Cell({ value: TextFieldFormatter.amountFormat(value || 0) }),
		},
		{
			Header: 'Bank Name',
			id: 'bank_name',
			Cell,
		},
		{
			Header: 'Bank Reference No.',
			id: 'bank_ref_no',
			Cell,
		},
		{
			Header: 'Child Account No.',
			id: 'child_account_no',
			Cell,
		},
		{
			Header: 'Mode of Payment',
			id: 'mode_of_payment',
			Cell: ({ value }) => Cell({ value: types[value] || value }),
		},
		{
			Header: 'Created By',
			id: 'created_by',
			width: '8%',
			Cell,
		},
		{
			Header: 'Date Updated',
			accessor: ({ date_updated }) => formatDate(date_updated),
			id: 'date_updated',
			sortable: true,
			Cell,
			width: '10%',
		},
		{
			Header: 'Updated By',
			id: 'updated_by',
			Cell,
			width: '8%',
		},
		{
			Header: 'Status',
			Cell: ({ cell, value }) => {
				const match = STATUS.find(
					({ name }) => name.toLowerCase() === value.toLowerCase()
				);
				const className = match ? match.className : '';

				return (
					<div {...cell.getCellProps()} className={className}>
						{value}
					</div>
				);
			},
			id: 'status',
			sortable: true,
		},
	].map((item) => ({ ...item, hidden: hiddenColumns.includes(item.id) }));

const STATUS = [
	{
		name: 'Pending',
		code: 'PENDING',
		className: styles.statusPending,
	},
	{
		name: 'Approved',
		code: 'APPROVED',
		className: styles.statusApproved,
	},
	{
		name: 'Declined',
		code: 'DECLINED',
		className: styles.statusDeclined,
	},
];

const WalletReplenishmentTable: React.FC<Props> = ({
	data,
	pageSize,
	count,
	onPageChange,
	onSort,
	sortBy,
	sort,
	page,
	shouldAllowApproval,
	onApprove,
	onDecline,
	hiddenColumns = [],
	isSearch,
}) => {
	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();
	const {
		value: selectedRow,
		setValue: selectRow,
		clearValue: clearSelectedRow,
	} = useSingleSelection<
		RowSelection & { confirmBtnLabel: string; action: 'approve' | 'decline' }
	>();

	const onApproveAttempt = (row: RowSelection) => {
		selectRow({ ...row, action: 'approve', confirmBtnLabel: 'Approve' });
		showConfirmModal();
	};
	const onDeclineAttempt = (row: RowSelection) => {
		selectRow({ ...row, action: 'decline', confirmBtnLabel: 'Decline' });
		showConfirmModal();
	};
	const handleConfirm = async () => {
		hideConfirmModal();
		if (!selectedRow) return;

		if (selectedRow.action === 'approve') {
			await onApprove(selectedRow.id, selectedRow.wallet_type);
			return;
		}
		await onDecline(selectedRow.id, selectedRow.wallet_type);
	};
	return (
		<>
			<Table
				columns={WALLET_REPLENISHMENT_COLUMNS(
					hiddenColumns,
					shouldAllowApproval,
					onApproveAttempt,
					onDeclineAttempt
				)}
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onSort={onSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				isSearch={isSearch}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor={true}
			/>
			{selectedRow && (
				<ConfirmModal
					open={isConfirmModalShown}
					disableClose={false}
					onClose={() => {
						clearSelectedRow();
						hideConfirmModal();
					}}
					headerText={`${capitalize(selectedRow.action)} Wallet Replenishment`}
					bodyText={[
						() => (
							<div className={styles.approveModalConfirmBody}>
								<div>
									Are you sure you want to {selectedRow.action}{' '}
									<strong>
										<NumberFormatter
											value={selectedRow.amount}
											hasThousandsSeparator
											decimalPlaces={2}
										/>
									</strong>
								</div>
								<div>
									<strong>Wallet Replenishment</strong> for{' '}
									<strong>{selectedRow.partner_name}</strong>?
								</div>
							</div>
						),
					]}
					confirmButton={{
						name: selectedRow.confirmBtnLabel,
						event: handleConfirm,
					}}
					cancelButton={{
						name: 'Cancel',
						event: hideConfirmModal,
					}}
				/>
			)}
		</>
	);
};

export default WalletReplenishmentTable;
