import { useEffect, useState } from 'react';
import cx from 'classnames';

import { Modal } from '@salesforce/design-system-react';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader } from 'utils/hooks';

import UserDetailsTab from '../UserDetailsTab';
import { UserDetailsModalProps, UserDetailsType } from '../types';
import styles from './UserDetailsModal.module.css';

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
	cellValue,
	isModalOpen,
	onCloseModal,
}) => {
	const [data, setData] = useState<UserDetailsType | undefined>();

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const hideAllModal = () => {
		hideErrorModal();
		onCloseModal?.();
	};

	const fetchUserDetails = async () => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const apiUrl = `v2/bfa-admin-portal/users/${cellValue?.id}`;

			await client.get(apiUrl).then((response) => {
				if (response?.data?.data.length === 0) {
					return;
				}

				const sanitizedData: UserDetailsType = Object.entries(
					response.data.data
				).reduce((acc, [key, value]) => {
					acc[key] = value === null ? '' : value;
					return acc;
				}, {} as UserDetailsType);

				setData(sanitizedData);
			});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchUserDetails()
			);
		} finally {
			hideLoading();
		}
	};

	useEffect(() => {
		fetchUserDetails();
	}, []);

	const header = `${data?.firstName} ${data?.lastName} (${data?.username})`;
	const statusText =
		cellValue?.verifiedDate !== null ? (
			<p className={styles.statusVerifiedText}>Verified User</p>
		) : (
			<p className={styles.statusUnverifiedText}>User is not yet verified</p>
		);
	return (
		<>
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideAllModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => retryBtnOnClick.action()}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			<FullPageLoader open={isLoading} message={loadingMessage} />

			{!isErrorModalShown && !isLoading && isModalOpen && (
				<Modal
					ariaHideApp={false}
					isOpen={isModalOpen}
					size="medium"
					containerClassName={cx(styles.customModal)}
					onRequestClose={onCloseModal}
				>
					<div className={cx(styles.customModalHeader)}>
						<div className={cx(styles.header)}>
							<div className={cx(styles.modalFullName)}>{header}</div>
							<div>{statusText}</div>
						</div>
						<UserDetailsTab cellValue={data} />
					</div>
				</Modal>
			)}
		</>
	);
};

export default UserDetailsModal;
