import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectFieldChannel';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { useBarangayQuery } from 'utils/queries/location';

export type BarangaySelectProps = {
	cityId?: string;
	selectFieldProps?: SelectFieldProps;
	onChange?: (
		selected: SelectOption,
		options: {
			label: string;
			value: {
				id: string;
				city_id: string;
			};
		}[]
	) => void;
};

const BarangaySelect: React.FC<BarangaySelectProps> = ({
	cityId,
	selectFieldProps,
	onChange,
}) => {
	const { data, isLoading } = useBarangayQuery(
		'barangays',
		{ cityId },
		{ enabled: !!cityId }
	);

	const options = useMemo(() => {
		return (
			data?.map(({ id: id, name: label, city_id }) => ({
				label,
				value: {
					id,
					city_id,
				},
			})) || []
		);
	}, [data]);

	return (
		<SelectField
			label="Barangay"
			isLoading={isLoading}
			options={options}
			getOptionValue={(v) => v.value.id}
			getComparator={({ value }, id) => value.id == id}
			{...selectFieldProps}
			onChange={(selected) => {
				onChange?.(selected, options);
				selectFieldProps?.onChange?.(selected);
			}}
		/>
	);
};

export default BarangaySelect;
