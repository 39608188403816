import { TypeNameMetaFieldDef } from 'graphql';
import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';
export const useGetChannelAudit = (
	id: number | undefined,
	type: string | undefined,
	params: any,
	tab: string | undefined
) => {
	return useQuery(
		['audit', id, type, params],
		async () => {
			try {
				if (type == 'terminal' && tab === 'AuditTrail') {
					const response = await client.get(
						`/v2/terminals/${id}/audit-logs`,
						{ params }
						//`/v2/terminals/336/audit-logs`, { params }
					);
					return response.data;
				} else if (type == 'branch' && tab === 'AuditTrail') {
					const response = await client.get(
						`/v2/branches/${id}/audit-logs`,
						{ params }
						//`/v2/branches/196/audit-logs`, { params }
					);
					return response.data;
				} else if (type === 'channel' && tab === 'AuditTrail') {
					const response = await client.get(
						`/v2/channels/${id}/audit-logs`,
						{ params }
						//`/v2/channels/247/audit-logs`, { params }
					);
					return response.data;
				}
			} catch (error) {
				console.error('Error fetching audit data:', error);
				throw error;
			}
		},
		{ staleTime: 100, cacheTime: 100 }
	);
};

export const useGetChannelLogs = (
	id: number | undefined,
	logId: number | undefined,
	type: string | undefined
	//params: any
) => {
	return useQuery(
		['logs', id, logId, TypeNameMetaFieldDef],
		async () => {
			console.log('TYPE OF: ' + typeof logId);
			if (logId != undefined && logId != null) {
				try {
					if (type == 'terminal') {
						const response = await client.get(
							`/v2/terminals/${id}/audit-logs/${logId}`
							//`/v2/terminals/336/audit-logs/${logId}`, { params }
						);
						return response.data;
					} else if (type == 'branch') {
						const response = await client.get(
							`/v2/branches/${id}/audit-logs/${logId}`
							//`/v2/branches/196/audit-logs/${logId}`, { params }
						);
						return response.data;
					} else if (type === 'channel') {
						const response = await client.get(
							`v2/channels/${id}/audit-logs/${logId}`
							//`/v2/channels/247/audit-logs/2126`, { params }
						);
						return response.data;
					}
				} catch (error) {
					console.error('Error fetching audit data:', error);
					return error?.response;
					//throw error;
				}
			}
		},
		{ staleTime: 100, cacheTime: 100 }
	);
};
