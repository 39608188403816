import { useState, useEffect } from 'react';
import { Modal } from '@salesforce/design-system-react/module/components';
import HTTP from 'helpers/ApiClient';
import styles from './Filter.module.css';
import Table from 'components/CWSTable/Table';
import { get as _get, debounce as _debounce } from 'lodash';
import LookupField from 'components/Inputs/LookupField/LookupField';
import { Control, Controller } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import cx from 'classnames';
import { isTruthy, resolveValue } from 'utils/common';
import { WalletListFilterFormData } from '../WalletManagement/WalletList/WalletListFilter';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { callback } from 'utils/callback';

type GetTransactionsArgs = {
	page?: number;
	limit?: number;
	transactionNo?: string;
	walletId?: string;
};

async function getTransactions({
	page = 1,
	limit = 25,
	transactionNo,
	walletId = '',
}: GetTransactionsArgs = {}) {
	const result = await HTTP.get('/v1/transactions/txn-ref-numbers', {
		params: {
			search: resolveValue(transactionNo),
			page,
			limit,
			walletId,
		},
	});
	return result.data;
}

type OnSelect = ({ label, value, hasPending, amount }) => void;
const generateChannelTableColumn = (
	onSelect: OnSelect,
	displayName: string
) => [
	{
		Header: displayName,
		id: 'name',
		width: '100%',
		Cell: ({ cell, value }) => {
			const { id, name, hasPending, amount } = cell.row.original;
			return (
				<div
					{...cell.getCellProps()}
					onClick={() => {
						onSelect({
							label: name,
							value: id,
							hasPending,
							amount,
						});
					}}
					onKeyDown={()=>{return}}
					className={styles.channelRow}
				>
					{value}
				</div>
			);
		},
	},
	{ id: 'id', hidden: true, Header: 'ID' },
];

const TransactionNoModal = ({
	open,
	onClose,
	data,
	count,
	onPageChange,
	pageSize = 25,
	onSelect,
	displayName,
	isLoading,
	page,
	setPage,
	className,
}) => {
	const handlePageChange = (p, l) => {
		onPageChange(p, true);
		setPage(p);
	};

	const handleSelect = (value) => {
		onSelect(value);

		onClose();
	};

	return (
		<>
			<Modal
				containerClassName={styles.transactionNoModalContainer}
				isOpen={open}
				onRequestClose={onClose}
				contentClassName={styles.partnerNameModalContent}
			>
				<div style={{ overflow: 'scroll' }}>
					<Table
						columns={generateChannelTableColumn(handleSelect, displayName)}
						data={data}
						count={count}
						pageSize={pageSize}
						onPageChange={handlePageChange}
						page={page}
						showPageSizeOptions={false}
						tableBodyProps={{
							style: { maxHeight: '425px', overflowY: 'auto' },
						}}
						preHeader={null}
						modalIsOpen={false}
					/>
				</div>
			</Modal>
		</>
	);
};

type TransactionNoFilterProps = {
	control?: Control<any>;
	name: any;
	displayName?: string;
	isSearchable?: boolean;
	walletId: string;
	errors: any;
};

const TransactionNoFilter: React.FC<TransactionNoFilterProps> = ({
	control,
	name,
	displayName = 'Transaction Reference No.',
	isSearchable = true,
	walletId = '',
	errors,
}) => {
	const [page, setPage] = useState(1);
	const [fullOptions, setFullOptions] = useState([]);
	const [options, setOptions] = useState([]);
	const [count, setCount] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const [currentSearch, setCurrentSearch] = useState('');

	const seeMoreThreshold = 10;

	const {
		value: isOptionsShown,
		valueOn: showOptions,
		valueOff: hideOptions,
	} = useToggle();

	const fetchTransactions = async (
		{ transactionNo, page = 1, limit = 25 }: GetTransactionsArgs = {},
		isPageManuallyClicked = false
	) => {
		try {
			if (isPageManuallyClicked) startLoading();

			startDropdownLoading();
			const response = await getTransactions({
				page,
				limit,
				transactionNo,
				walletId,
			});

			const data = response.result || [];

			const _formattedData = data
				.slice()
				.map(({ trn, has_pending_adj, amount }) => ({
					id: trn,
					name: trn,
					hasPending: has_pending_adj ? has_pending_adj : 1,
					amount: amount,
				}));

			const _formattedOptions = _formattedData
				.slice(0, seeMoreThreshold)
				.map(({ id: value, name: label, hasPending, amount }) => ({
					label,
					value,
					hasPending,
					amount,
				}));

			setOptions(_formattedOptions);
			setFullOptions(_formattedData);
			setCount(_get(response, 'meta.total', 0));

			return _formattedOptions;
		} catch (e) {
			setOptions([]);
		} finally {
			stopLoading();
			stopDropdownLoading();
		}
	};

	const {
		value: isLoading,
		valueOn: startLoading,
		valueOff: stopLoading,
	} = useToggle();

	const {
		value: isDropdownLoading,
		valueOn: startDropdownLoading,
		valueOff: stopDropdownLoading,
	} = useToggle();

	const {
		value: isChannelModalOpen,
		valueOn: openChannelModal,
		valueOff: closeChannelModal,
	} = useToggle();

	const handleMenuOpen = async () => {
		if (options.length === 0 || page !== 1) {
			setOptions([]);
			setPage(1);
			fetchTransactions();
		}
	};

	const handlePageChange = (page, isPageManuallyClicked) => {
		fetchTransactions(
			{
				transactionNo: currentSearch,
				page,
			},
			isPageManuallyClicked
		);
	};

	const handleSearchInput = (searchInputValue: string) => {
		if (searchInputValue.length >= 3) {
			setCurrentSearch(searchInputValue);
			return fetchTransactions({ transactionNo: searchInputValue });
		}
	};

	const handleInputChange = (event) => {
		if (event.length >= 3) {
			showOptions();
		} else {
			hideOptions();
		}
	};

	return (
		<>
			<label className={cx(styles.transactionNoLabel)}>{displayName}</label>
			<div
				className={cx(
					styles.transNo,
					styles.transactionNoContainer,
					styles.manualError
				)}
			>
				<Controller
					name={name}
					control={control}
					render={({ field }) => {
						return (
							<div className={styles.transactionNoFilter}>
								<LookupField
									{...field}
									hasError={errors[name]}
									openMenuOnFocus={false}
									openMenuOnClick={false}
									onChange={(v) => {
										field.onChange(v);
									}}
									onFocus={() => {
										field.onChange(null);
									}}
									value={field.value}
									isLoading={false}
									options={isOptionsShown ? options : []}
									loadOptions={
										isOptionsShown
											? handleSearchInput
											: () => {
													return Promise.resolve();
											  }
									}
									hasSeeMore={isOptionsShown ? count > seeMoreThreshold : false}
									placeholder={displayName}
									onInputChange={handleInputChange}
									onMenuOpen={handleMenuOpen}
									seeMoreClick={openChannelModal}
									isSearchable={isSearchable}
									isAsync={true}
									noOptionsMessage={
										isOptionsShown
											? () => {
													return 'No results found.';
											  }
											: () => null
									}
								/>

								{isChannelModalOpen && (
									<TransactionNoModal
										{...field}
										className
										open={true}
										data={fullOptions}
										onClose={closeChannelModal}
										count={count}
										onPageChange={handlePageChange}
										onSelect={(value) => {
											field.onChange(value);
											setInputValue(value.name);
										}}
										displayName={displayName}
										isLoading={isLoading}
										page={page}
										setPage={setPage}
									/>
								)}
							</div>
						);
					}}
				/>
			</div>
		</>
	);
};

export default TransactionNoFilter;
