import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import styles from 'containers/ChannelManagement/Channel/ChannelTable/ChannelTable.module.css';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import { InputIcon } from '@salesforce/design-system-react';
import { ReactComponent as PercentIcon } from 'assets/icons/ic-percent.svg';
import { CHANNEL_TYPES } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import { ReactComponent as PesoIcon } from 'assets/icons/ic-peso.svg';
import AmountCurrencyField from 'components/Inputs/TextField/AmountCurrencyField';
import { isEmpty } from 'lodash';
function ChannelTable({
	itemKey,
	data,
	columnsParam,
	tableClassName,
	headerClassName,
	bodyClassName,
	containerClassName,
	showPagination,
	onChange,
	shares,
	serviceFeeType,
	toEdit,
	nonShareableColumn,
	control,
	permission,
	customStyles,
	channelTypes,
}: {
	itemKey?: string;
	data: any[];
	columnsParam: any[];
	tableClassName?: string;
	headerClassName?: string;
	bodyClassName?: string;
	containerClassName?: string;
	showPagination?: boolean;
	onChange?: (data: any, name: string, rowData: any) => void;
	shares?: any;
	serviceFeeType?: string;
	toEdit?: boolean;
	nonShareableColumn?: any;
	control?: any;
	permission?: boolean;
	customStyles?: any;
	channelTypes?: any;
}) {
	const columns = React.useMemo(() => columnsParam, [columnsParam]);
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
			},
			useSortBy
		);

	const onChangeValue = (value, name, rowData) => {
		// console.log("onChangeValue-value", value)
		if (onChange) {
			onChange(value, name, rowData);
		}
	};

	const posLeft: React.CSSProperties = {
		position: 'absolute',
		top: '50%',
		left: '5px',
		transform: 'translateY(-50%)',
	};

	const amountFormat = (v: any): string => {
		try {
			const convertValue = v.toString();
			const sanitized = convertValue.replaceAll(',', '')?.split('.');
			const _biggo = BigInt(sanitized[0]);

			// Reset to empty string once text field value is  deleted
			if (isEmpty(sanitized[0]) && (!sanitized[1] || sanitized[1] == 0))
				return '';
			return (
				_biggo.toLocaleString('en-US') +
				'.' +
				(sanitized[1] || '00').toString().slice(0, 2).padEnd(2, '0')
			);
		} catch (e) {
			return v;
		}
	};

	const amountCommaOnly = (v: any): string => {
		try {
			const convertValue = v.toString();
			const sanitized = convertValue.replaceAll(',', '')?.split('.');
			const _biggo = BigInt(sanitized[0]);

			// Reset to empty string once text field value is  deleted
			if (isEmpty(sanitized[0]) && (!sanitized[1] || sanitized[1] == 0))
				return '';
			return _biggo.toLocaleString('en-US');
		} catch (e) {
			return v;
		}
	};

	return (
		<div key={itemKey} className={containerClassName}>
			<table {...getTableProps()} className={tableClassName}>
				<thead style={{ maxWidth: '10px' }}>
					{headerGroups.map((headerGroup, i) => (
						<tr key={i} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, j) => (
								<th
									key={j}
									{...column.getHeaderProps()}
									className={headerClassName}
									style={{
										maxWidth: customStyles ? '50px' : column.width,
										minWidth: customStyles ? '20px' : column.width,
										padding: '15px',
									}}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className={bodyClassName}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr key={i} {...row.getRowProps()}>
								{row.cells.map((cell, j) => (
									<td
										key={j}
										{...cell.getCellProps()}
										style={{ padding: '15px' }}
										className={
											cell.column.id.includes('SfShare')
												? styles.shareBgColor
												: ''
										}
									>
										{cell.column.id === 'partnerShare' ||
										cell.column.id === 'bayadShare' ? (
											<AmountCurrencyField
												label=""
												isFieldTable
												value={
													cell.column.id === 'partnerShare'
														? shares.find(
															(share) => share.productId === row.original.productId &&
																		share.id === (row.original.tierNumber || row.original.channelTypeId)
															)?.partnerShare ?? ''
														: shares.find(
															(item) => item.productId === row.original.productId &&
																		item.id === (row.original.tierNumber || row.original.channelTypeId)
															)?.bayadShare ?? ''
												}
												key={row.original.id}
												placeholder={
													cell.column.id === 'partnerShare' ||
													cell.column.id === 'bayadShare'
														? '0.00'
														: ''
												}
												name={cell.column.id}
												onChange={(value) =>
													onChangeValue(value, cell.column.id, row.original)
												}
												// formatValue={(value) => {
												// 	if (value === '' || isNaN(value)) {
												// 		return '';
												// 	}
												// 	const numericValue = parseFloat(value);
												// 	if (!isNaN(numericValue)) {
												// 		return numericValue.toFixed(2);
												// 	}
												// 	return '';
												// }}
												formatValue={TextFieldFormatter.amountFormat}
												disabled={
													!permission ||
													!toEdit ||
													(toEdit && cell.column.id === 'bayadShare')

													// temporarily not use: change request deferred
													// permission ||
													// toEdit ||
													// (!toEdit && cell.column.id === 'bayadShare')
												}
												iconRight={
													nonShareableColumn.isPercentage ||
													serviceFeeType === 'PERCENTAGE' ? (
														<InputIcon
															assistiveText={{
																icon: 'none',
															}}
															name="percent"
															category="utility"
														>
															<PercentIcon />
														</InputIcon>
													) : (
														''
													)
												}
												iconLeft={
													nonShareableColumn.isPercentage ||
													serviceFeeType === 'PERCENTAGE' ? (
														''
													) : (
														<span style={posLeft}>
															<PesoIcon />{' '}
														</span>
													)
												}
												// isCurrency={
												// 	nonShareableColumn.isPercentage ? false : true
												// }
												control={control}
												error={
													cell.column.id === 'partnerShare'
														? shares.find(
																(item) =>
																	item.productId === row.original.productId &&
																	item.id ===
																		(row.original.tierNumber ||
																			row.original.channelTypeId)
														  )?.message || ''
														: ''
												}
											/>
										) : cell.column.id.includes('subsidizedRate') ||
										  cell.column.id.includes('passOnRate') ||
										  cell.column.id.includes('Percentage') ||
										  cell.column.id.includes('SfShareRate') ? (
											`${
												cell.value !== null
													? amountFormat(cell.value)
													: amountFormat(0)
											}%`
										) : cell.value !== null ? (
											cell.column.id === 'channelTypeId' ? (
												channelTypes.find((v) => v.value === cell.value).label
											) : cell.column.id.includes('fee') ||
											  cell.column.id.includes('rate') ? (
												['tierNumber', 'lowerLimit', 'upperLimit'].includes(
													amountCommaOnly(cell.column.id)
												) ? (
													cell.value
												) : (
													amountFormat(cell.value)
												)
											) : ['tierNumber', 'lowerLimit', 'upperLimit'].includes(
													cell.column.id
											  ) ? (
												amountCommaOnly(cell.value)
											) : (
												amountFormat(cell.value)
											)
										) : (
											amountFormat(0)
										)}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{showPagination && <>PAGE HERE</>}
		</div>
	);
}

export default ChannelTable;
