import styles from 'components/ExpandableRow/ExpandableRow.module.css';
import { Checkbox as SCheckbox } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';

const ExpandedRow = (props) => {
	const { checked, disabled, subRow, onChange, ...rest } = props;
	return (
		<div className={styles.subCheckbox}>
			<SCheckbox
				className={cx(styles.checkbox, { [styles.checked]: checked })}
				checked={checked}
				disabled={disabled || undefined}
				onChange={onChange}
				{...rest}
				label={<span className={styles.subtext}>{subRow}</span>}
			/>
		</div>
	);
};

export default ExpandedRow;