export const GET_CHANNEL_DETAILS_PRIMARY_INFORMATION =
	'getChannelDetailsPrimaryInfo';
export const GET_CHANNEL_DETAILS_CONTRACT_INFO =
	'getChannelDetailsContractInfo';
export const GET_CHANNEL_DETAILS_REPORT_INFO = 'getChannelDetailsReportInfo';
export const GET_BRANCH_DETAILS = 'getBranchDetails';
export const GET_TERMINAL_DETAILS = 'getTerminalDetails';
export const GET_CHANNEL_AUTO_SAVE = 'getChannelAutoSave';
export const GET_BRANCH_AUTO_SAVE = 'getBranchAutoSave';
export const GET_TERMINAL_AUTO_SAVE = 'getTerminalAutoSave';
