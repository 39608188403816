import React from 'react';

type ExpandableButtonProps = {
	isOpen?: boolean;
	toggle?: () => void;
	disabled?: boolean;
	className?: string;
};

export const ExpandableButton: React.FC<ExpandableButtonProps> = ({
	isOpen,
	toggle,
	disabled = false,
	className = '',
}) => {
	return (
		<button onClick={toggle} disabled={disabled} className={className}>
			<span
				className="material-symbols-outlined"
				style={{
					transform: `rotate(${!isOpen ? 180 : 0}deg)`,
					transition: 'all 0.25s',
				}}
			>
				expand_less
			</span>
		</button>
	);
};
