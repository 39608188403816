import { SLDSDropdownOptionType } from 'types';

const channelTypes: SLDSDropdownOptionType[] = [
	{ label: 'Center', value: 'CENTER' },
	{ label: 'Partner', value: 'PARTNET' },
	{ label: 'Agent', value: 'AGENT' },
	{ label: 'Machine', value: 'MACHINE' },
	{ label: 'Collect', value: 'COLLECT' },
	{ label: 'Digital', value: 'DIGITAL' },
];

const channelStatuses: SLDSDropdownOptionType[] = [
	{ label: 'Approved for Integration', value: 'APPROVED_FOR_INTEGRATION' },
	{ label: 'On-going Integration', value: 'ONGOING_INTEGRATION' },
	{ label: 'Ready for Activation', value: 'READY_FOR_ACTIVATION' },
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Suspended', value: 'SUSPENDED' },
	{ label: 'Deactivated', value: 'DEACTIVATED' },
	{ label: 'Terminated', value: 'TERMINATED' },
];

const productStatuses: SLDSDropdownOptionType[] = [
	{ label: 'Enabled', value: 'Enabled' },
	{ label: 'Disabled', value: 'Disabled' },
];

export { channelStatuses, channelTypes, productStatuses };
