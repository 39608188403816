import cx from 'classnames';
import folderIcon from '../../assets/icons/ic-folder.svg';
import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import styles from './Checkbox.module.css';
import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import ExpandedCheckbox from './ExpandedCheckbox';
import MultiCheckboxContainer from './MultiCheckboxContainer';
import { useEffect, useMemo, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { FixMeLater } from 'types';

interface Props {
	name?: string;
	control?: Control<any>;
	isShowTooltip?: boolean;
	entries: any;
	onEntryClick: FixMeLater;
	getEntryValue?: (entry) => any;
	disabled?: boolean;
}

const ToolTipContainer = ({ children, disabled, isShowTooltip }) =>
	disabled && isShowTooltip ? (
		<Tooltip
			id="tooltip"
			align="bottom left"
			content={<span>Product type unavailable.</span>}
			triggerClassName={styles.toolTip}
		>
			{children}
		</Tooltip>
	) : (
		children
	);

function ProductTypePortion({
	isShowTooltip,
	entries = [],
	onEntryClick,
	disabled = false,
	getEntryValue,
	onChange,
}) {
	const [isCollapsed, setIsCollapsed] = useState(true); // Add state for collapsed state
	const handleClick = () => {
		setIsCollapsed(!!isCollapsed); // Toggle collapsed state on click
	};
	return (
		<>
			<div onClick={handleClick} onKeyDown={()=>{return}}>
				{entries?.map((entry) => {
					const {
						id,
						checked,
						enabled,
						label,
						isAllowDeduction = false,
					} = entry;
					const _disabled = disabled || !enabled;
					return (
						<ToolTipContainer
							key={id}
							disabled={_disabled}
							isShowTooltip={isShowTooltip}
						>
							<div
								className={cx('slds-col', styles.entry, {
									[styles.disabledChkBox]: _disabled,
								})}
							>
								<ExpandedCheckbox
									entry={entry}
									disabled={_disabled}
									label={label}
									checked={checked}
									isSubOptionChecked={isAllowDeduction}
									onChange={() => {
										const _checked = !checked; //toggle parent
										const result = _checked
											? onEntryClick(entry, _checked)
											: onEntryClick(entry, _checked, true, false); // check parent
										onChange(result);
									}}
									subOnChange={(v: boolean) => {
										const result = v
											? onEntryClick(entry, true, true, v)
											: onEntryClick(entry, checked, true, v);
										onChange(result);
									}}
									subText={'PTCheckbox'}
									subRow={'Allow transaction with deduction'}
									getEntryValue={getEntryValue}
								/>
							</div>
						</ToolTipContainer>
					);
				})}
				{!isCollapsed && (
					<div>
						{entries.map((entry, index) => (
							<div key={index} onClick={() => onEntryClick(entry)} onKeyDown={()=>{return}}>
								{entry}
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
}

const ProductTypeCheckbox: React.FC<Props> = ({
	name = '',
	control,
	isShowTooltip = false,
	entries,
	onEntryClick = (x, y, a, b) => {},
	getEntryValue = (entry) => entry,
	disabled,
}) => {
	const halfIndex = Math.ceil(entries.length) - 1;
	const [selected, setSelected] = useState(false);
	const [filteredEntries, setFilteredEntries] = useState(entries);
	const [search, setSearch] = useState('');
	const handleSearchChange = (e) => setSearch(e);

	const disabledIfAllHasWallet =
		entries.filter((e) => e.has_wallet).length === entries.length;

	const handleSearch = debounce(() => {
		if (search === '') {
			setFilteredEntries(entries);
			return;
		}
		setFilteredEntries(
			entries.filter((product_type) =>
				product_type.label.toLowerCase().includes(search.trim().toLowerCase())
			)
		);
	}, 300);
	useEffect(() => {
		handleSearch();
	}, [search]);

	useEffect(() => {
		isEmpty(search) && setFilteredEntries(() => entries);
		if (
			entries.filter(({ checked }) => checked === false).length ===
			entries.length
		)
			setSelected(false);
	}, [entries]);

	const handleSelectAll = () => {
		setSelected(!selected);
		if (onEntryClick(entries, !selected, true, false, true)) {
			return onEntryClick(entries, !selected, true, false, true);
		}
		return onEntryClick(entries, !selected, null, null, true);
	};

	const allowSelectAll: boolean = useMemo(() => {
		return entries.some((entry) => !!entry.enabled);
	}, [entries]);

	const MainCheckbox = ({ field = { onChange: (v) => {} }, error }) => (
		<MultiCheckboxContainer
			className={cx(error && styles.errorCheckbox)}
			selectAll={selected}
			onSelectAll={() => {
				const toggledDatas = handleSelectAll();
				field?.onChange(toggledDatas);
			}}
			selectAllLabel="Select All Product Type"
			disabled={disabled || disabledIfAllHasWallet || !allowSelectAll}
		>
			<div className={styles.searchProductType}>
				<InputWithIcon
					placeholder="Search Product Type"
					onChange={handleSearchChange}
					icons={[
						{
							path: 'utility/search',
							isLeft: true,
						},
					]}
					disabled={disabled}
				/>
			</div>
			<div className={cx('slds-grid', styles.listBody)}>
				{entries && entries.length > 0 ? (
					<>
						<div className="slds-grid_vertical slds-col slds-col slds-size_1-of-2">
							<ProductTypePortion
								isShowTooltip={isShowTooltip}
								entries={filteredEntries.filter((_, i) => i <= halfIndex)}
								onEntryClick={onEntryClick}
								onChange={(result: Array<unknown>) => field.onChange(result)}
								disabled={disabled}
								getEntryValue={getEntryValue}
							/>
						</div>
					</>
				) : (
					<div className={styles.noResult}>
						<img src={folderIcon} alt="Folder Icon" />
						<span>No Product Type Found.</span>
					</div>
				)}
			</div>
		</MultiCheckboxContainer>
	);

	return name && control ? (
		<Controller
			name={name}
			control={control}
			render={(props) => {
				const {
					field,
					fieldState: { error },
				} = props;
				return (
					<>
						{MainCheckbox({ field, error })}
						{error && (
							<div className={cx('slds-has-error')}>
								<div className={cx(styles.helper, 'slds-form-element__help')}>
									{error.message}
								</div>
							</div>
						)}
					</>
				);
			}}
		/>
	) : (
		MainCheckbox({ error: '' })
	);
};

export default ProductTypeCheckbox;
