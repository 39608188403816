import React, { FC, useState } from 'react';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import styles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import { Modal } from '@salesforce/design-system-react/module/components';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { restrictSpecialChars } from 'utils/common';
import cx from 'classnames';
import { ChannelProductsProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const ChannelProductModal: FC<ChannelProductsProps> = ({
	heading,
	message,
	isOpen,
	isDisabled,
	isPrimaryBtnDisabled,
	onClose,
	onCancelBtnClick,
	onConfirmBtnClick,
	cancelBtnLabel,
	confirmBtnLabel,
	showRemarks,
	data,
	error,
	handleChangeOfRemarks,
	disableOnClick = false,
	showTablefield,
	tableContent,
	headerClassName,
	containerClassName,
	contentClassName,
	removeHeading,
	hasRadioGroup = false,
	control,
	retainLabel,
	cascadeLabel,
	getOption,
	errorOptions,
	remarksInfo = { text: '*Maximum of 1000 characters only.', limit: 1000 },
}) => {
	const [option, setOption] = useState();

	const handleOption = (option) => {
		setOption(option);
	};
	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				disable={isDisabled}
				headerClassName={headerClassName}
				containerClassName={containerClassName}
				contentClassName={contentClassName}
				heading={
					!removeHeading
						? // <h2 className={styles.customHeading}>{heading}</h2>
						  heading
						: ''
				}
				footer={
					!removeHeading ? (
						<div className={styles.rejectionModalFooter}>
							<OutlineButton
								onClick={onCancelBtnClick}
								className={styles.confirmationModalBtn}
							>
								{cancelBtnLabel}
							</OutlineButton>
							<PrimaryButton
								onClick={onConfirmBtnClick}
								className={styles.confirmationModalBtn}
								disabled={isPrimaryBtnDisabled}
							>
								{confirmBtnLabel}
							</PrimaryButton>
						</div>
					) : (
						''
					)
				}
			>
				<div className={styles.bodyHeader}>{message}</div>
				{/* Temporarily removed: change request deferred */}
				{/* {hasRadioGroup && (
					<div className={cx(styles.radioOptions, 'slds-form-element')}>
						<RadioGroup
							control={control}
							name="radioGroup"
							options={[
								{
									label: (
										<>
											<span className={styles.radioOptionsLabel}>
												Retain activation to custom set of {retainLabel}
											</span>
										</>
									),
									value: 'retain',
								},
								{
									label: (
										<>
											<span className={styles.radioOptionsLabel}>
												Mass activate all {cascadeLabel}
											</span>
										</>
									),
									value: 'cascade',
								},
							]}
							isVertical
							value={option}
							indented
							onChange={(v) => {
								handleOption(v);
								getOption(v);
							}}
							error={errorOptions}
						/>
					</div>
				)} */}
				{showRemarks ? (
					<>
						<div className={styles.bodyContent}>
							<div
								className={cx(
									'slds-form-element',
									error.hasError && 'slds-has-error'
								)}
							>
								<label className={styles.remarksLabel}>
									Remarks
									<abbr className="slds-required" title="required">
										*
									</abbr>
								</label>
								<div className="slds-form-element__control">
									<textarea
										className={'slds-textarea' + ' ' + styles.remarks}
										maxLength={remarksInfo.limit}
										onChange={handleChangeOfRemarks}
										placeholder="Remarks"
									></textarea>
								</div>
								{error.hasError && (
									<div className="slds-form-element__help">
										<div>{error.status}</div>
									</div>
								)}
							</div>
							<em className={styles.remarksSubLabel}>{remarksInfo.text}</em>
						</div>
					</>
				) : null}
				{showTablefield ? (
					<div>
						{tableContent}
						<>
							{removeHeading ? (
								<div className={styles.btnStyleClass}>
									<div className={styles.rejectionModalFooter}>
										<OutlineButton
											onClick={onCancelBtnClick}
											className={styles.confirmationModalBtn}
										>
											{cancelBtnLabel}
										</OutlineButton>
										<PrimaryButton
											onClick={onConfirmBtnClick}
											className={styles.confirmationModalBtn}
											disabled={isPrimaryBtnDisabled}
										>
											{confirmBtnLabel}
										</PrimaryButton>
									</div>
								</div>
							) : (
								''
							)}
						</>
					</div>
				) : null}
			</Modal>
		</>
	);
};

export default ChannelProductModal;
