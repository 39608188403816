import HTTP from '../helpers/ApiClient';
import qs from 'qs';

function logOut(isExpired = false) {
	const req = qs.stringify({ token: localStorage.getItem('token') });
	const logOutUrl = process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/logout';
	return HTTP.post(logOutUrl, req)
		.then((data) => {
			localStorage.clear();
			window.location.replace(
				process.env.REACT_APP_URL +
					(isExpired ? '?session=expired' : '')
			);
			console.log('successfully removed');
		})
		.catch((error) => {
			console.error(error);
		});
}

export { logOut };
