import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: {
		oldPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	},
	mask: {
		oldPasswordMask: false,
		newPasswordMask: false,
		confirmNewPasswordMask: false,
	},
	isPasswordMatch: undefined,
	disableSaveButton: false,
	isSaveButtonClicked: false,
	isNewPasswordValid: undefined,
	passwordGuide: {
		display: false,
		hasAlphaCharacter: false,
		hasNumericCharacter: false,
		hasSpecialCharacter: false,
		hasUpperCase: false,
		hasLowerCase: false,
	},
};

const changePassword = createSlice({
	name: 'changePassword',
	initialState,
	reducers: {
		maskPassword: (state, { payload }) => {
			state.mask[payload] = !state.mask[payload];
		},
		onFieldInput: (state, { payload }) => {
			state.data[payload.name] = payload.val;
			state.passwordGuide.display = false;

			if (
				state.data.newPassword !== '' &&
				state.data.confirmNewPassword !== ''
			) {
				if (state.data.newPassword === state.data.confirmNewPassword) {
					state.isPasswordMatch = true;
				} else {
					state.isPasswordMatch = false;
				}
			}
		},
		newPasswordFieldChecker: (state, { payload }) => {
			const val = payload.val;
			const hasAlphaCharacter = new RegExp('(?=.*?[a-zA-Z])').test(val);
			const hasNumericCharacter = new RegExp('(?=.*?[0-9])').test(val);
			const hasSpecialCharacter =
				/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val);
			const hasUpperCase = new RegExp('(?=.*?[A-Z])').test(val);
			const hasLowerCase = new RegExp('(?=.*?[a-z])').test(val);

			state.passwordGuide.hasAlphaCharacter = hasAlphaCharacter;
			state.passwordGuide.hasNumericCharacter = hasNumericCharacter;
			state.passwordGuide.hasSpecialCharacter = hasSpecialCharacter;
			state.passwordGuide.hasUpperCase = hasUpperCase;
			state.passwordGuide.hasLowerCase = hasLowerCase;
			state.passwordGuide.display = true;
		},
		onSaveButtonClick: (state, { payload }) => {
			state.isSaveButtonClicked = !state.isSaveButtonClicked;
		},
		newPasswordValidation: (state, { payload }) => {
			state.isNewPasswordValid = payload;
		},
	},
});

const {
	maskPassword,
	onFieldInput,
	newPasswordFieldChecker,
	onSaveButtonClick,
	newPasswordValidation,
} = changePassword.actions;

export default changePassword.reducer;

export {
	maskPassword,
	onFieldInput,
	newPasswordFieldChecker,
	onSaveButtonClick,
	newPasswordValidation,
};
