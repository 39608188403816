import React, { useCallback } from 'react';
import { debounce } from 'lodash';

import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import Grid from 'components/Grid/Grid';

import { PartnerDetailsToolbarProps } from './types';

const PartnerDetailsToolbar: React.FC<PartnerDetailsToolbarProps> = ({
	filterTitle = '',
	isFilterAvailable = false,
	filterFields,
	isSearch = false,
	padding = '',
	onSearch,
}) => {
	const handleSearch = useCallback(
		debounce((v) => {
			const value = v || '';
			if (value.length >= 3 || value === '') {
				onSearch && onSearch({ search: value });
			}
		}, 500),
		[]
	);
	return (
		<div>
			{isSearch && (
				<Filter title={filterTitle} padding={padding}>
					<FilterOuter>
						<Grid container align="end">
							<Grid
								container
								column
								size={11}
								of={12}
								align="end"
								gutters="x-small"
							>
								<Grid column size={5} of={12}>
									<InputWithIcon
										onChange={handleSearch}
										placeholder="Search"
										icons={[
											{
												path: 'utility/search',
												isLeft: true,
											},
										]}
									/>
								</Grid>
							</Grid>
						</Grid>
					</FilterOuter>
					{isFilterAvailable && <FilterInner>{filterFields}</FilterInner>}
				</Filter>
			)}
		</div>
	);
};

export default PartnerDetailsToolbar;
