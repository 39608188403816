import React, { useState } from 'react';

import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';
import { Switch } from 'components/Switch/';

import client from 'helpers/ApiClient';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import {
	useErrorModal,
	useLoader,
	useSuccessModal,
	useToggle,
} from 'utils/hooks';
import { LOADING_UPDATE_MSG } from 'constants/loading';

import { ActionCellProps, UpdatePartnerResp } from './types';
import styles from './PartnerDetailsBody.module.css';

const ActionCell: React.FC<ActionCellProps> = ({
	name,
	initialToggleValue,
	confirmModalContents,
	isBodySubHeader = false,
	isBodySubSubHeader = false,
	channelId,
	channelName,
	branchId,
	branchName,
	tpaId,
	tpaName,
	partnerId,
	setRefetchData,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [toggleValue, setToggleValue] = useState<boolean>(initialToggleValue);
	const [remarks, setRemarks] = useState<string>('');
	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
	} = useSuccessModal();

	const actionText = initialToggleValue ? 'Disable' : 'Enable';

	const modalHeader = `${actionText} ${confirmModalContents.header}`;
	const bodyHeader = (
		<div>
			Are you sure you want to {actionText.toLowerCase()}{' '}
			<span className={styles.bodyHeaderEmphasis}>
				{confirmModalContents?.bodyHeader}
			</span>
			?
		</div>
	);
	const bodySubHeader = `*This change will be applied to the Branches and TPA IDs under this ${confirmModalContents.bodySubHeader}.`;
	const bodySubSubHeader = `*This change will be applied to the TPA IDs under this Branch.`;

	const errorModalBody = (
		<div>
			A problem occurred while {actionText.slice(0, -1).toLowerCase() + 'ing '}
			<span className={styles.bodyHeaderEmphasis}>
				{confirmModalContents?.bodyHeader}
			</span>
		</div>
	);
	const bfaStatusForBe = toggleValue ? 'ACTIVE' : 'INACTIVE';
	const bfaStatusForFe = toggleValue ? 'Enabled' : 'Disabled';
	const updateChannelApi = `v2/bfa-admin-portal/partner/${partnerId}/channel/${channelId}/${actionText.toLowerCase()}`;
	const updateBranchApi = `v2/bfa-admin-portal/partner/${partnerId}/channel/${channelId}/branch/${branchId}/${actionText.toLowerCase()}`;
	const updateTpaApi = `v2/bfa-admin-portal/partner/${partnerId}/channel/${channelId}/branch/${branchId}/terminal/${tpaId}/${actionText.toLowerCase()}`;

	const checkUpdate = () => {
		if (channelId && branchId && tpaId) return updateTpaApi;
		else if (channelId && branchId && !tpaId) return updateBranchApi;
		else if (channelId && !branchId && !tpaId) return updateChannelApi;

		return '';
	};

	const handleUpdate = async () => {
		hideErrorModal();
		showLoadingMessage(LOADING_UPDATE_MSG);

		try {
			const result: UpdatePartnerResp = await client.put(checkUpdate(), {
				bfaStatus: bfaStatusForBe,
				remarks,
			});

			if (result?.errors) {
				throw new Error(result?.errors?.message);
			}

			showSuccessMessage(
				<div>
					<span className={styles.bodyHeaderEmphasis}>
						{confirmModalContents?.bodyHeader}
					</span>{' '}
					is now {bfaStatusForFe.toLowerCase()}
				</div>
			);
		} catch (error) {
			showErrorMessage('Timeout Error!', errorModalBody, null, () =>
				handleUpdate()
			);
		} finally {
			hideLoading();
		}
	};

	return (
		<div>
			<Switch
				name={name}
				checked={toggleValue}
				onChange={(newValue) => {
					if (
						(toggleValue && hasUserPermission('partner.tpa.disable')) ||
						(!toggleValue && hasUserPermission('partner.tpa.enable'))
					) {
						setToggleValue(newValue);
						showConfirmModal();
					}
				}}
			/>
			<ConfirmModalWithRemarks
				isOpen={isConfirmModalShown}
				onClose={hideConfirmModal}
				onCancel={() => {
					setToggleValue(!toggleValue);
					setToggleValue(!toggleValue);
					hideConfirmModal();
				}}
				modalDisabled={false}
				confirmOnClick={handleUpdate}
				header={modalHeader}
				bodyHeader={bodyHeader}
				bodySubHeader={
					isBodySubHeader
						? isBodySubSubHeader
							? bodySubSubHeader
							: bodySubHeader
						: ''
				}
				remarksOnChange={setRemarks}
				remarksSubLabel="*Maximum of 1000 characters only."
				remarksMaxLength={1000}
			/>
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={() => {
					hideErrorModal();
					setRefetchData && setRefetchData(true);
				}}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<SuccessModalContainer
				isOpen={isSuccessModalOpen}
				onClose={() => {
					hideSuccessModal();
					setRefetchData && setRefetchData(true);
				}}
				successMessage={successMessage}
			/>
			<FullPageLoader open={isLoading} message={loadingMessage} />
		</div>
	);
};

export default ActionCell;
