import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import { Control, Path, PathValue, UseFormSetValue } from 'react-hook-form';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { bond_type } from 'constants/bond_type';
import { rate_type } from 'constants/rate_type';
import { bond_renewal } from 'constants/bond_renewal';
import { connect } from 'react-redux';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { FieldValues, useWatch } from 'react-hook-form';
import { ContractDetailsFormData } from '../../ContractDetails';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';

type Props<T extends FieldValues> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
	product_type_id: number;
	products: Array<Record<string, string | number>>;
};

const Bond = <T extends ContractDetailsFormData>({
	control,
	disabled,
	product_type_id,
	products,
	setValue,
}: Props<T>): JSX.Element => {
	const name = 'product_dsa_bond' as const;
	const currentProduct = products.find(
		(product) => product.id == product_type_id
	);

	const bondType = useWatch({
		control,
		name: `${name}.bond_type` as Path<T>,
	});

	const bondPremiumType = useWatch({
		control,
		name: `${name}.bond_premium_type` as Path<T>,
	});

	const handleChangeValue = () => {
		!disabled &&
			setValue(
				`${name}.bond_premium_rate` as Path<T>,
				'' as PathValue<T, Path<T>>
			);
	};

	return (
		<Section title="Bond">
			<input
				type="hidden"
				name={`${name}.product_type`}
				value={currentProduct?.name}
			/>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Bond Type"
						placeholder="Select Bond Type"
						name={`${name}.bond_type`}
						control={control}
						disabled={disabled}
						options={bond_type}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					{bondType === 'OTHERS' && (
						<TextField
							name={`${name}.other_bond_type`}
							required
							control={control}
							label={'Other Bond Type'}
							disabled={disabled}
							shouldUnregister
						/>
					)}
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<AmountField
						required
						label="Bond Amount"
						placeholder="0.00"
						control={control}
						name={`${name}.bond_amount`}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Bond Premium Type"
						placeholder="Select Bond Premium Type"
						control={control}
						name={`${name}.bond_premium_type`}
						disabled={disabled}
						options={rate_type}
						onChange={handleChangeValue}
					/>
				</Grid>
				{bondPremiumType && (
					<Grid column size={1} of={3}>
						<AmountField
							required
							label="Bond Premium Rate"
							placeholder={bondPremiumType === 'PERCENTAGE' ? '0.00%' : '0.00'}
							control={control}
							name={`${name}.bond_premium_rate`}
							disabled={disabled}
							formatValue={TextFieldFormatter.amountFormat}
						/>
					</Grid>
				)}
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Bond Renewal"
						placeholder="Select Bond Renewal"
						name={`${name}.bond_renewal`}
						control={control}
						disabled={disabled}
						options={bond_renewal}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<AmountField
						optional={currentProduct?.name != 'Buy Load'}
						required={currentProduct?.name == 'Buy Load'}
						label="Prefunded Amount"
						placeholder="0.00"
						control={control}
						name={`${name}.prefunded_amount`}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<AmountField
						optional={currentProduct?.name != 'Buy Load'}
						required={currentProduct?.name == 'Buy Load'}
						label="Prefunded Threshold Amount"
						placeholder="0.00"
						control={control}
						name={`${name}.prefunded_threshold_amt`}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<DatePickerField
						required
						label="Execution Date"
						name={`${name}.execution_date`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<DatePickerField
						required
						label="Termination Date"
						name={`${name}.termination_date`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default connect((state: any) => ({
	product_type_id: state.sidebar.itemId,
	products: state.sidebar.products,
}))(Bond);
