import React from 'react';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import TerminalInformation from 'containers/ChannelManagement/Terminal/Form/TerminalInformation';
import TerminalIntegration from 'containers/ChannelManagement/Terminal/Form/TerminalIntegration';
import FormLogic from 'containers/ChannelManagement/Terminal/Form/FormLogic';

type Props = {
	autoSaveValue?: any;
	isAutoSave?: boolean;
	isModal?: boolean;
	mode?: string;
	disabled?: boolean;
	refetchTerminal?: () => void;
	setCurrentSection?: any;
	setPrevSection?: any;
	updateAutoSave?: any;
};

const TerminalForm: React.FC<Props> = ({
	isModal = true,
	mode,
	disabled,
	refetchTerminal,
	setCurrentSection,
	setPrevSection,
	isAutoSave,
	autoSaveValue,
	updateAutoSave,
}) => {
	return (
		<>
			<TerminalInformation
				mode={mode}
				disabled={disabled}
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={updateAutoSave}
			/>
			<hr className={styles.divider} />
			<TerminalIntegration
				mode={mode}
				disabled={disabled}
				setCurrentSection={setCurrentSection}
				setPrevSection={setPrevSection}
				updateAutoSave={updateAutoSave}
			/>
			<FormLogic
				mode={mode}
				disabled={disabled}
				refetchTerminal={refetchTerminal}
				autoSaveValue={autoSaveValue}
				isAutoSave={isAutoSave}
			/>
		</>
	);
};

export default TerminalForm;
