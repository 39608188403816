import { Route } from 'react-router-dom';
import ChannelProfile from './Channel/ChannelProfile/ChannelProfile';
import Drafts from 'containers/ChannelManagement/Drafts/Drafts';
import ChannelApproval from 'containers/ChannelManagement/Approval/ChannelApproval';

const ChannelManagement: React.FC = () => {
	return (
		<>
			<Route exact path="/channel-management/" component={ChannelProfile} />
			<Route exact path="/channel-management/drafts" component={Drafts} />
			<Route exact path="/channel-management/approvals" component={ChannelApproval} />
		</>
	);
}

export default ChannelManagement;
