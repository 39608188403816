export const handleArrayValues = (value) => {
	return Array.isArray(value) ? value.map((v) => v.trim()).join(', ') : value;
};
export const handleArrayWithSpaceValues = (value) =>
	Array.isArray(value)
		? value
				.map((v) => v.trim())
				.join(', ')
				.replaceAll(',', ', ')
		: typeof value === 'string' || typeof value === 'number'
		? String(value)
				.replace(/[\[\]']/g, '')
				.replaceAll(',', ', ')
		: value;

export const removeCharacterInStringValues = (value) => {
	return value ? value.substring(37) : value;
};

export const filterArrayValues = (v) => {
	if (!v) {
		return false;
	}

	const hasValidNewValue =
		v.new_values &&
		typeof v.new_values === 'string' &&
		v.new_values.trim() !== '';
	const hasValidOldValue =
		v.old_values &&
		typeof v.old_values === 'string' &&
		v.old_values.trim() !== '';

	if (!hasValidNewValue && (!hasValidOldValue || v.old_values === '-')) {
		v.old_values = null;
		v.new_values = null;
	}

	return hasValidNewValue || v.old_values === '-';
};

export const convertUndefinedToNull = (value) => {
	return value === undefined ? null : value;
};

export const convertValue = (value) => {
	return value === '1' || value === 1
		? 'Yes'
		: value === '0' || value === 0
		? 'No'
		: value;
};
export const convertValueToString = (value) => {
	return typeof value === 'number' ? String(value) : value;
};
