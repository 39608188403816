import { useMemo, useState } from 'react';
import { Dropdown } from '@salesforce/design-system-react/module/components';
import styles from './index.module.css';
import cx from 'classnames';

type TOptions = {
	label: string;
	value: string | number;
	extra: { [key: string]: string }
};

type Props = {
	value?: string | number;
	options?: TOptions[];
	onSelect?: any;
};

export default function ReportName(props: Props): JSX.Element {
	const { value, options, onSelect } = props;
	const [dropdownState, setDropdownValue] = useState({
		label: 'Select Report Names',
		value: 0
	});

	const taggedOptions = useMemo(() => {
		return options?.map((opt) => ({
			...opt,
			className: cx(opt?.extra?.editStatus === "new" && styles.isNew, opt?.extra?.editStatus === "updated" && styles.isUpdated),
		}));
	}, [options]);

	return (
		<div className={styles.dropdownContainer}>
			<label>Report Name</label>
			<Dropdown
				width="large"
				iconName="none"
				iconVariant="more"
				buttonVariant="icon"
				iconPosition="right"
				iconCategory="utility"
				options={taggedOptions}
				label={dropdownState.label}
				triggerClassName={
					styles.dropdownTriggerButton
				}
				className={cx(
					'slds-dropdown_large',
					styles.dropdownItem
				)}
				onSelect={(option: any) => {
					setDropdownValue(option);
					if (onSelect instanceof Function) {
						onSelect(option.value);
					}
				}}
				buttonClassName={cx(
					'slds-grid slds-grid_align-spread slds-dropdown_large',
					styles.dropdownButton,
					value
						? styles.dropdownActiveState
						: styles.dropdownInitState,
					styles.dropdownActiveStateIcon
				)}
			/>
		</div>
	);
}