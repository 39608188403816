import dragdrop from '../../assets/icons/img-dragdrop.svg';
import styles from './UploadAttachments.module.css';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import FilePreview from 'components/FilePreview/FilePreview';
import { useToggle } from 'utils/hooks';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useDropzone } from 'react-dropzone';
import ConfirmModal from 'components/Modal/ConfirmModal';
import FilePreviewModal from './FilePreviewModal';
import { allowedFileTypes, checkFileType, getFileType } from 'utils/fileTypes';
import OutlineButton from 'components/Buttons/OutlineButton';
import { CancelTokenSource } from 'axios';
import { resetToken } from 'redux/modules/timeout';
import { useDispatch } from 'react-redux';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

type Props = {
	changeFileList?: (fileList) => void;
	displayError: boolean;
	defaultFiles?: any;
	secondaryLabel?: string;
	uploadUrl?: string;
	required?: boolean;
	// withLoader?: boolean;
	setIsUploadAttachmentReady?: (e: boolean) => void;
};

type FileError = {
	fileName?: string;
	fileType?: string;
	errorType: string;
};

const ErrorMessage = {
	maxLimit:
		'You have reached max no. of accepted attachment. Only 10 files are allowed.',
	maxSize:
		'You have reached max file size of accepted attachment. Only 5 mb per attachment is allowed.',
	fileType: `Failed to upload one or more file/s:`,
	multipleUploadErrors:
		'One or more file/s failed to upload. <br> Would you like to retry?',
};

const UploadAttachments: React.FC<Props> = ({
	changeFileList = () => {},
	defaultFiles,
	displayError = false,
	secondaryLabel,
	uploadUrl = 'v1/void/url',
	required = true,
	// withLoader = false,
	setIsUploadAttachmentReady = () => {},
}) => {
	const [newFiles, setNewFiles] = React.useState<any[]>([]);
	const [selectedFile, setSelectedFile] = React.useState<File | null>();
	const [uploadRequest, setUploadRequest] = React.useState<any>(null);
	const [invalidFilesState, setInvalidFilesState] = React.useState<File[]>([]);
	let invalidFiles: File[] = [];

	const dispatch = useDispatch();
	// const [isScanning, setIsScanning] = React.useState(false);

	const [modalError, setModalError] = React.useState<FileError>({
		errorType: 'maxLimit',
	});
	const [deleteFile, setDeleteFile] = React.useState<any>({
		index: 0,
		file: null,
	});

	const uploadedFiles = useRef<string[]>([]);
	const pendingUploadedFileCount = useRef<number>(0);

	const {
		value: isFileErrorModalOpen,
		valueOn: openFileErrorModal,
		valueOff: closeFileErrorModal,
	} = useToggle();

	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const {
		value: isPreviewModalOpen,
		valueOn: openPreviewModal,
		valueOff: closePreviewModal,
	} = useToggle();

	const onFileChange = (event: any) => {
		addFiles(event.target.files);
	};

	//reset input to  enable uploading the same file
	const clickInput = (event: any) => {
		event.target.value = '';
	};

	const validateFile = (file: any, limitFlag: boolean) => {
		if (newFiles.length >= 10 || limitFlag) {
			setModalError({ errorType: 'maxLimit' });
			openFileErrorModal();
			return false;
		} else if (!checkFileType(file)) {
			setModalError({
				errorType: 'fileType',
				fileType: getFileType(file),
			});
			invalidFiles.push(file);
			openFileErrorModal();
			return false;
		} else if (file.size > 1024 * 1024 * 5) {
			setModalError({ errorType: 'maxSize' });
			openFileErrorModal();
			return false;
		} else {
			return true;
		}
	};

	const addFiles = (files: any) => {
		const _tempArray: any[] = [];
		const currFileLength = newFiles.length;
		invalidFiles = [];

		for (let i = 0; i < files.length && i < 10 - currFileLength; i++) {
			const currFile = files.item(i);
			if (validateFile(currFile, false)) {
				_tempArray.push({
					file: currFile,
					fileName: currFile.name,
					url: URL.createObjectURL(currFile),
					fileKey: '',
					status: 'uploading',
				});
			}
		}

		if (currFileLength + files.length > 10) {
			validateFile(null, true);
			return;
		}

		if (invalidFiles.length > 0) {
			setInvalidFilesState(invalidFiles);
			return;
		}

		pendingUploadedFileCount.current = currFileLength + files.length;

		setIsUploadAttachmentReady(false);

		setNewFiles([...newFiles, ..._tempArray]);
	};

	const onDelete = (
		index: number,
		fileKey: string,
		_uploadRequest: CancelTokenSource
	) => {
		openConfirmDeleteModal();
		setUploadRequest(_uploadRequest);
		updateFileStatus('deleted', index);
		setDeleteFile({
			index: index,
			fileKey: fileKey,
			file: newFiles[index].file,
			status: 'deleted',
		});
	};
	useEffect(() => {
		console.log(newFiles);
	}, [newFiles]);
	const confirmDelete = () => {
		setNewFiles((prevState) => {
			const origFiles = [...prevState];
			origFiles.splice(deleteFile.index, 1);
			return origFiles;
		});

		const _uploadedFiles = uploadedFiles.current.filter((name) => {
			return name != deleteFile.fileKey;
		});

		uploadedFiles.current = _uploadedFiles;

		pendingUploadedFileCount.current = -1;
		if (
			pendingUploadedFileCount.current === 0 ||
			pendingUploadedFileCount.current === uploadedFiles.current.length
		) {
			setIsUploadAttachmentReady(true);
		}

		changeFileList([...uploadedFiles.current]);

		uploadRequest.cancel();
		dispatch(resetToken());

		closeConfirmDeleteModal();
	};

	async function getDroppedFiles(event) {
		const droppedFileList = event.dataTransfer
			? event.dataTransfer.files
			: event.target.files;
		addFiles(droppedFileList);
		return droppedFileList;
	}

	const { getRootProps, getInputProps, open } = useDropzone({
		getFilesFromEvent: (event) => {
			return getDroppedFiles(event);
		},
		noClick: true,
	});

	const onView = (index: number) => {
		setSelectedFile(newFiles[index]);
	};

	const handleClosePreview = () => {
		closePreviewModal();
		setSelectedFile(null);
	};

	useEffect(() => {
		if (selectedFile) {
			openPreviewModal();
		}
	}, [selectedFile]);

	useEffect(() => {
		if (
			!isFileErrorModalOpen &&
			newFiles.filter((f) => f.status === 'failed').length >= 1
		) {
			openFileErrorModal();
			setModalError({ errorType: 'multipleUploadErrors' });
		}
	}, [newFiles]);

	const onUploadSuccess = (file_name: string, index: number) => {
		newFiles[index].fileKey = file_name;
		uploadedFiles.current.push(file_name);

		changeFileList([...uploadedFiles.current]);
		if (uploadedFiles.current.length === pendingUploadedFileCount.current) {
			setIsUploadAttachmentReady(true);
		}
	};

	const updateFileStatus = (status, index) => {
		setNewFiles((prevState) => {
			return prevState.map((f, i) => {
				if (i === index) {
					f.status = status;
				}
				return f;
			});
		});
	};

	const retryFiles = () => {
		closeFileErrorModal();
		setNewFiles((prevState) => {
			return prevState.map((f) => {
				if (f.status === 'failed') {
					f.status = 'retry';
				}
				return f;
			});
		});
	};

	useEffect(() => {
		const _tempArray: any[] = [];

		if (defaultFiles) {
			defaultFiles.map((value) => {
				_tempArray.push({
					file: null,
					fileName: value.file_name,
					url: value.url,
				});
			});
			setNewFiles((prevState) => [...prevState, ..._tempArray]);
		}
	}, []);

	return (
		<>
			<div className={styles.uploadContainer}>
				<h3 className={cx(required && styles.label)}>Upload Attachments</h3>
				{secondaryLabel && (
					<span className={styles.secondLabel}>{secondaryLabel}</span>
				)}
				<div
					className={cx(
						styles.innerContainer,
						displayError ? styles.borderError : styles.border
					)}
				>
					<div className="slds-form-element">
						<div className="slds-form-element__control">
							<div
								className="slds-file-selector slds-file-selector_images"
								{...getRootProps()}
							>
								<div>
									<input
										type="file"
										className="slds-file-selector__input slds-assistive-text"
										accept="*/*"
										id="file-upload-input-107"
										aria-labelledby="file-selector-primary-label-105 file-selector-secondary-label106"
										onChange={onFileChange}
										onClick={clickInput}
										multiple
										{...getInputProps()}
									/>
									<label
										className="slds-file-selector__body"
										id="file-selector-secondary-label106"
									>
										<img src={dragdrop} />
										<span
											className={cx(
												'slds-file-selector__text slds-medium-show',
												styles.spiel
											)}
										>
											Drag and drop files here or click "Choose file" button
										</span>
										<span
											className={cx(
												'slds-file-selector__button slds-button slds-button_neutral',
												styles.outline
											)}
											onClick={open}
											onKeyDown={()=>{return}}
										>
											Choose File
										</span>
									</label>
								</div>
							</div>
							<div className={styles.fileRowContainer}>
								<div className={styles.fileRow}>
									{newFiles.map((fileInfo, i) => {
										return (
											<div key={fileInfo.url}>
												<FilePreview
													index={i}
													file={fileInfo.file}
													url={fileInfo.url}
													fileName={fileInfo.fileName}
													fileKey={fileInfo.fileKey}
													onDelete={(fileKey, uploadRequest) =>
														onDelete(i, fileKey, uploadRequest)
													}
													onView={() => onView(i)}
													emitSuccess={(file_name) => {
														onUploadSuccess(file_name, i);
													}}
													updateFileStatus={updateFileStatus}
													isMainError={isFileErrorModalOpen}
													status={fileInfo.status}
													uploadUrl={uploadUrl}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>

				{displayError && (
					<div className={'slds-has-error'}>
						<div className={cx(styles.helper, 'slds-form-element__help')}>
							At least 1 attachment should be uploaded
						</div>
					</div>
				)}

				<div className={styles.logoSubtext}>
					<em>*Maximum file size per attachment: </em>
					<span className={styles.logoSubtextValue}>5MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum number of attachment: </em>
					<span className={styles.logoSubtextValue}>10</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*Allowed file types </em>
					<span className={styles.logoSubtextValue}>
						{allowedFileTypes.map((v) => '.' + v).join(', ')}
					</span>
				</div>
			</div>

			<ErrorModal open={isFileErrorModalOpen} onClose={closeFileErrorModal}>
				<ErrorModalBody>
					<ErrorText>Failed to Add Attachment/s</ErrorText>
					<ErrorSubText>
						{modalError.errorType != 'fileType' ? (
							<span
								style={{ fontWeight: 'normal' }}
								dangerouslySetInnerHTML={{
									__html: ErrorMessage[modalError.errorType],
								}}
							></span>
						) : (
							<>
								<div className={styles.leftAlign}>
									{ErrorMessage[modalError.errorType]}
									<ul className="slds-list_dotted">
										{invalidFilesState.map((value) => (
											<li>{value.name}</li>
										))}
									</ul>
								</div>
								<br />
								Allowed file types are:{' '}
								<b>{allowedFileTypes.map((v) => '.' + v).join(', ')}</b>
								<br />
							</>
						)}
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					{modalError.errorType === 'multipleUploadErrors' ? (
						<div className={styles.actionsContainer}>
							<OutlineButton
								className={styles.addressErrorCloseBtn}
								onClick={closeFileErrorModal}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								className={styles.addressErrorCloseBtn}
								onClick={retryFiles}
							>
								Retry
							</PrimaryButton>
						</div>
					) : (
						<PrimaryButton
							className={styles.addressErrorCloseBtn}
							onClick={closeFileErrorModal}
						>
							Close
						</PrimaryButton>
					)}
				</ErrorModalActions>
			</ErrorModal>
			<ConfirmModal
				open={isConfirmDeleteModalOpen}
				disableClose={false}
				onClose={() => {
					closeConfirmDeleteModal();
				}}
				headerText="Remove Attachment"
				bodyText={[
					() => (
						<>
							Are you sure you want to remove{' '}
							<span
								style={{
									fontWeight: 'bold',
								}}
							>
								{deleteFile.file?.name}
							</span>
							?
						</>
					),
				]}
				confirmButton={{
					name: 'Confirm',
					event: confirmDelete,
				}}
				cancelButton={{
					name: 'Cancel',
					event: closeConfirmDeleteModal,
				}}
			/>
			{selectedFile && (
				<FilePreviewModal
					open={isPreviewModalOpen}
					onClose={handleClosePreview}
					file={selectedFile}
				/>
			)}
			{/* <FullPageLoader
				message="Scanning in progress."
				open={isScanning && withLoader}
			/> */}
		</>
	);
};

export default UploadAttachments;
