import {
	NAME_OF_CHANNEL_ACCOUNT_CODE,
	NAME_OF_CHANNEL_ACCOUNT_NAME,
	NAME_OF_CHANNEL_TRANSACTION_TYPE,
	NAME_OF_CHANNEL_TYPE,
	NAME_OF_POS_TYPE,
	NAME_OF_SETTLEMENT_SETUP,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import { MeralcoPaymentCenterResponseType } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/types';
import client from 'helpers/ApiClient';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { FixMeLater } from 'types';

export const autoSetDefaults = (
	setValue: FixMeLater,
	channelPrimaryInfo: FixMeLater
): void => {
	setValue(NAME_OF_CHANNEL_ACCOUNT_NAME, channelPrimaryInfo?.name || '');
	setValue(NAME_OF_CHANNEL_ACCOUNT_CODE, channelPrimaryInfo?.tpaAccount || '');
	setValue(NAME_OF_SETTLEMENT_SETUP, channelPrimaryInfo?.settlementSetup || '');
	setValue(
		NAME_OF_CHANNEL_TRANSACTION_TYPE,
		channelPrimaryInfo?.channelTransactionType || ''
	);
	setValue(NAME_OF_CHANNEL_TYPE, String(channelPrimaryInfo?.channelType || ''));
	setValue(NAME_OF_POS_TYPE, channelPrimaryInfo?.posType || '');
};

export function useMeralcoPaymentCenterQuery(
	queryKey: 'meralcoPaymentCenter' | string = 'meralcoPaymentCenter',
	config?: UseQueryOptions<MeralcoPaymentCenterResponseType>
): UseQueryResult<MeralcoPaymentCenterResponseType> {
	return useQuery<MeralcoPaymentCenterResponseType>(
		[queryKey],
		() => getMeralcoPaymentCenter(),
		config
	);
}

async function getMeralcoPaymentCenter(): Promise<MeralcoPaymentCenterResponseType> {
	const response = await client.get('v2/meralco-payment-centers');
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch regions');

	return list;
}
