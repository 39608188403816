import moment from 'moment';

export const formatPayload = (index, forms) => {
	const { primaryForm, contractForm, reportsForm } = forms;
	const primaryInfoValues = primaryForm.getValues();
	const contractValues = contractForm.getValues();
	const reportsValues = reportsForm.getValues();

	const payload = {
		channelPrimaryInfo: {
			...primaryInfoValues.primaryInfo,
		},
		channelContractDetails: {
			...contractValues.contractDetails,
		},
		channelReports: {
			...reportsValues,
		},
	};

	return payload;
};

export const formatPayloadSubmit = (index, forms) => {
	const { primaryForm, contractForm, reportsForm } = forms;
	const primaryInfoValues = primaryForm.getValues();
	const contractValues = contractForm.getValues();
	const reportsValues = reportsForm.getValues();

	const transformCashCheckCollection = (data) => ({
		acctName: data.acctName || null,
		acctNo: data.acctNo || null,
		acctType: data.acctType || null,
		bankBranch: data.bankBranch || null,
		bankId: data.bankId || null,
		id: data.id || null,
	});

	const transformContactDetails = (data) => ({
		name: data.name || null,
		department: data.department || null,
		emailAddress: data.emailAddress
			? data.emailAddress?.length > 0
				? data.emailAddress
				: null
			: null,
		mobileNo: data.mobileNo
			? data.mobileNo?.length > 0
				? data.mobileNo
				: null
			: null,
		position: data.position || null,
		telNo: data.telNo || null,
		id: data.id || null,
	});

	const cashCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
	};

	const checkCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
	};

	const contactDetailsDefault = [
		{
			deparment: null,
			emailAddress: null,
			mobileNo: null,
			position: null,
			telNo: null,
		},
	];

	const cashCollection =
		contractValues.contractDetails.channelCashCollection.map(
			transformCashCheckCollection
		);
	const contactDetails =
		primaryInfoValues.primaryInfo.channelContactDetails.map(
			transformContactDetails
		);
	const checkCollection =
		contractValues.contractDetails.channelCheckCollection.map(
			transformCashCheckCollection
		);

	const payload = {
		channelPrimaryInfo: {
			...primaryInfoValues.primaryInfo,
			settlementSetup: primaryInfoValues
				? primaryInfoValues.primaryInfo.settlementSetup
				: null,
			channelTransactionType: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelTransactionType
				: null,
			logo: primaryInfoValues.primaryInfo.logo
				? primaryInfoValues.primaryInfo.logo
				: {
						objectKey: null,
				  },
			partnershipType: primaryInfoValues
				? primaryInfoValues.primaryInfo.partnershipType
				: null,
			businessType: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessType
				: null,
			businessGroup: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessGroup
				: null,
			posType: primaryInfoValues.primaryInfo.posType
				? primaryInfoValues.primaryInfo.posType
				: undefined,
			channelAddresses: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelAddresses
				: [
						{
							locationBase: null,
							area: null,
						},
				  ],
			channelContactDetails: primaryInfoValues.primaryInfo.channelContactDetails
				? contactDetails
				: contactDetailsDefault,
			channelOfficer: {
				emailAddress:
					primaryInfoValues.primaryInfo.channelOfficer.emailAddress?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.emailAddress
						: null,
				mobileNo:
					primaryInfoValues.primaryInfo.channelOfficer.mobileNo?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.mobileNo
						: null,
				name: primaryInfoValues.primaryInfo.channelOfficer.name || null,
				telNo: primaryInfoValues.primaryInfo.channelOfficer.telNo || null,
			},
		},
		channelContractDetails: {
			...contractValues.contractDetails,

			bondAmount:
				typeof contractValues.contractDetails.bondAmount === 'number' ||
				(typeof contractValues.contractDetails.bondAmount === 'string' &&
					contractValues.contractDetails.bondAmount.trim() !== '') ||
				contractValues.contractDetails.bondAmount === null
					? parseFloat(contractValues.contractDetails.bondAmount).toFixed(2)
					: null,
			thresholdAmount:
				typeof contractValues.contractDetails.thresholdAmount === 'number' ||
				(typeof contractValues.contractDetails.thresholdAmount === 'string' &&
					contractValues.contractDetails.thresholdAmount.trim() !== '') ||
				contractValues.contractDetails.thresholdAmount === null
					? parseFloat(contractValues.contractDetails.thresholdAmount).toFixed(
							2
					  )
					: null,
			accreditationFee:
				typeof contractValues.contractDetails.accreditationFee === 'number' ||
				(typeof contractValues.contractDetails.accreditationFee === 'string' &&
					contractValues.contractDetails.accreditationFee.trim() !== '') ||
				contractValues.contractDetails.accreditationFee === null
					? parseFloat(contractValues.contractDetails.accreditationFee).toFixed(
							2
					  )
					: null,
			annualFee:
				typeof contractValues.contractDetails.annualFee === 'number' ||
				(typeof contractValues.contractDetails.annualFee === 'string' &&
					contractValues.contractDetails.annualFee.trim() !== '') ||
				contractValues.contractDetails.annualFee === null
					? parseFloat(contractValues.contractDetails.annualFee).toFixed(2)
					: null,
			bondStartDate: contractValues.contractDetails.bondStartDate
				? moment(contractValues.contractDetails.bondStartDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			bondEndDate: contractValues.contractDetails.bondEndDate
				? moment(contractValues.contractDetails.bondEndDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			channelCashCollection: contractValues.contractDetails
				.channelCashCollection
				? cashCollection
				: cashCollectionDefault,
			channelCheckCollection: contractValues.contractDetails
				.channelCheckCollection
				? checkCollection
				: checkCollectionDefault,
			channelTermsDuration: contractValues.contractDetails.channelTermsDuration
				? {
						channelId:
							contractValues.contractDetails.channelTermsDuration.channelId,
						executionDate: contractValues.contractDetails.channelTermsDuration
							.executionDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.executionDate
							  ).format('YYYY-MM-DD')
							: null,
						autoRenew:
							contractValues.contractDetails.channelTermsDuration.autoRenew ||
							null,
						renewalFrequency:
							contractValues.contractDetails.channelTermsDuration
								.renewalFrequency || null,
						terminationDate: contractValues.contractDetails.channelTermsDuration
							.terminationDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.terminationDate
							  ).format('YYYY-MM-DD')
							: null,
						frequencyInterval:
							contractValues.contractDetails.channelTermsDuration
								.frequencyInterval || null,
				  }
				: {
						channelId: null,
						executionDate: null,
						autoRenew: null,
						renewalFrequency: null,
						terminationDate: null,
						frequencyInterval: null,
				  },
			otherBusinessLines: contractValues.contractDetails.otherBusinessLines
				? contractValues.contractDetails.otherBusinessLines.toString()
				: null,
			settlementAgreement: contractValues.contractDetails.settlementAgreement
				? contractValues.contractDetails.settlementAgreement
				: null,
			bondType: contractValues.contractDetails.bondType
				? contractValues.contractDetails.bondType
				: null,
			depositConsolidation: contractValues.contractDetails.depositConsolidation
				? contractValues.contractDetails.depositConsolidation
				: null,
			vatRate:
				typeof contractValues.contractDetails.vatRate === 'string'
					? Number(contractValues.contractDetails.vatRate?.replace('%'))
					: 12,
			vatType: contractValues.contractDetails.vatType
				? contractValues.contractDetails.vatType
				: null,
			channelAccreditation: {
				birCertReg:
					contractValues.contractDetails.channelAccreditation.birCertReg ||
					null,
				corpSecCert:
					contractValues.contractDetails.channelAccreditation.corpSecCert ||
					null,
				businessPermit:
					contractValues.contractDetails.channelAccreditation.businessPermit ||
					null,
				companyProfile:
					contractValues.contractDetails.channelAccreditation.companyProfile ||
					null,
				govtIds:
					contractValues.contractDetails.channelAccreditation.govtIds || null,
				genInfoSheets:
					contractValues.contractDetails.channelAccreditation.genInfoSheets ||
					null,
				nonDisclosure:
					contractValues.contractDetails.channelAccreditation.nonDisclosure ||
					null,
				secRegCert:
					contractValues.contractDetails.channelAccreditation.secRegCert ||
					null,
				secArticleIncorp:
					contractValues.contractDetails.channelAccreditation
						.secArticleIncorp || null,
				certAuthority:
					contractValues.contractDetails.channelAccreditation.certAuthority ||
					null,
				signedNda:
					contractValues.contractDetails.channelAccreditation.signedNda || null,
				auditFinancialStmt:
					contractValues.contractDetails.channelAccreditation
						.auditFinancialStmt || null,
				incomeTaxReturn:
					contractValues.contractDetails.channelAccreditation.incomeTaxReturn ||
					null,
				cbciAmlQuestion:
					contractValues.contractDetails.channelAccreditation.cbciAmlQuestion ||
					null,
				secondAuditFinStmt:
					contractValues.contractDetails.channelAccreditation
						.secondAuditFinStmt || null,
				dataPrivacyPia:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPia ||
					null,
				dataPrivacyPat:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPat ||
					null,
				thirdPartySecChecklist:
					contractValues.contractDetails.channelAccreditation
						.thirdPartySecChecklist || null,
			},
		},
		channelReports: {
			// ...reportsValues,
			sftp: {
				...reportsValues?.sftp,
				csrConfig: {
					...reportsValues?.sftp?.csrConfig,
					csrColumns:
						reportsValues?.sftp?.csrConfig?.csrColumns?.length > 0
							? transformTP(reportsValues?.sftp)
							: [],
				},
			},
			smtp: {
				...reportsValues?.smtp,
				csrConfig: {
					...reportsValues?.smtp?.csrConfig,
					csrColumns:
						reportsValues?.smtp?.csrConfig?.csrColumns?.length > 0
							? transformTP(reportsValues?.smtp)
							: [],
				},
			},
		},
	};
	return payload;
};

export const formatPayloadResubmit = (index, forms) => {
	const { primaryForm, contractForm, reportsForm } = forms;
	const primaryInfoValues = primaryForm.getValues();
	const contractValues = contractForm.getValues();
	const reportsValues = reportsForm.getValues();

	const transformCashCheckCollection = (data) => ({
		acctName: data.acctName || null,
		acctNo: data.acctNo || null,
		acctType: data.acctType || null,
		bankBranch: data.bankBranch || null,
		bankId: data.bankId || null,
		id: data.id || null,
	});

	const transformContactDetails = (data) => ({
		name: data.name || null,
		department: data.department || null,
		emailAddress: data.emailAddress
			? data.emailAddress?.length > 0
				? data.emailAddress
				: null
			: null,
		mobileNo: data.mobileNo
			? data.mobileNo?.length > 0
				? data.mobileNo
				: null
			: null,
		position: data.position || null,
		telNo: data.telNo || null,
		id: data.id || null,
	});

	const cashCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
		id: null,
	};

	const checkCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
		id: null,
	};

	const contactDetailsDefault = [
		{
			deparment: null,
			emailAddress: null,
			mobileNo: null,
			position: null,
			telNo: null,
			id: null,
		},
	];

	const cashCollection =
		contractValues.contractDetails.channelCashCollection.map(
			transformCashCheckCollection
		);
	const contactDetails =
		primaryInfoValues.primaryInfo.channelContactDetails.map(
			transformContactDetails
		);
	const checkCollection =
		contractValues.contractDetails.channelCheckCollection.map(
			transformCashCheckCollection
		);

	const payload = {
		channelPrimaryInfo: {
			...primaryInfoValues.primaryInfo,
			settlementSetup: primaryInfoValues
				? primaryInfoValues.primaryInfo.settlementSetup
				: null,
			channelTransactionType: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelTransactionType
				: null,
			logo: primaryInfoValues.primaryInfo.logo
				? primaryInfoValues.primaryInfo.logo
				: {
						objectKey: null,
				  },
			partnershipType: primaryInfoValues
				? primaryInfoValues.primaryInfo.partnershipType
				: null,
			businessType: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessType
				: null,
			businessGroup: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessGroup
				: null,
			posType: primaryInfoValues.primaryInfo.posType
				? primaryInfoValues.primaryInfo.posType
				: undefined,
			channelAddresses: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelAddresses
				: [
						{
							locationBase: null,
							area: null,
						},
				  ],
			channelContactDetails: primaryInfoValues.primaryInfo.channelContactDetails
				? contactDetails
				: contactDetailsDefault,
			channelOfficer: {
				emailAddress:
					primaryInfoValues.primaryInfo.channelOfficer.emailAddress?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.emailAddress
						: null,
				mobileNo:
					primaryInfoValues.primaryInfo.channelOfficer.mobileNo?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.mobileNo
						: null,
				name: primaryInfoValues.primaryInfo.channelOfficer.name || null,
				telNo: primaryInfoValues.primaryInfo.channelOfficer.telNo || null,
			},
		},
		channelContractDetails: {
			...contractValues.contractDetails,

			bondAmount:
				typeof contractValues.contractDetails.bondAmount === 'number' ||
				(typeof contractValues.contractDetails.bondAmount === 'string' &&
					contractValues.contractDetails.bondAmount.trim() !== '') ||
				contractValues.contractDetails.bondAmount === null
					? parseFloat(contractValues.contractDetails.bondAmount).toFixed(2)
					: null,
			thresholdAmount:
				typeof contractValues.contractDetails.thresholdAmount === 'number' ||
				(typeof contractValues.contractDetails.thresholdAmount === 'string' &&
					contractValues.contractDetails.thresholdAmount.trim() !== '') ||
				contractValues.contractDetails.thresholdAmount === null
					? parseFloat(contractValues.contractDetails.thresholdAmount).toFixed(
							2
					  )
					: null,
			accreditationFee:
				typeof contractValues.contractDetails.accreditationFee === 'number' ||
				(typeof contractValues.contractDetails.accreditationFee === 'string' &&
					contractValues.contractDetails.accreditationFee.trim() !== '') ||
				contractValues.contractDetails.accreditationFee === null
					? parseFloat(contractValues.contractDetails.accreditationFee).toFixed(
							2
					  )
					: null,
			annualFee:
				typeof contractValues.contractDetails.annualFee === 'number' ||
				(typeof contractValues.contractDetails.annualFee === 'string' &&
					contractValues.contractDetails.annualFee.trim() !== '') ||
				contractValues.contractDetails.annualFee === null
					? parseFloat(contractValues.contractDetails.annualFee).toFixed(2)
					: null,
			bondStartDate: contractValues.contractDetails.bondStartDate
				? moment(contractValues.contractDetails.bondStartDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			bondEndDate: contractValues.contractDetails.bondEndDate
				? moment(contractValues.contractDetails.bondEndDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			channelCashCollection: contractValues.contractDetails
				.channelCashCollection
				? cashCollection
				: cashCollectionDefault,
			channelCheckCollection: contractValues.contractDetails
				.channelCheckCollection
				? checkCollection
				: checkCollectionDefault,
			channelTermsDuration: contractValues.contractDetails.channelTermsDuration
				? {
						channelId:
							contractValues.contractDetails.channelTermsDuration.channelId,
						executionDate: contractValues.contractDetails.channelTermsDuration
							.executionDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.executionDate
							  ).format('YYYY-MM-DD')
							: null,
						autoRenew:
							contractValues.contractDetails.channelTermsDuration.autoRenew ||
							null,
						renewalFrequency:
							contractValues.contractDetails.channelTermsDuration
								.renewalFrequency || null,
						terminationDate: contractValues.contractDetails.channelTermsDuration
							.terminationDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.terminationDate
							  ).format('YYYY-MM-DD')
							: null,
						frequencyInterval:
							contractValues.contractDetails.channelTermsDuration
								.frequencyInterval || null,
				  }
				: {
						channelId: null,
						executionDate: null,
						autoRenew: null,
						renewalFrequency: null,
						terminationDate: null,
						frequencyInterval: null,
				  },
			otherBusinessLines: contractValues.contractDetails.otherBusinessLines
				? contractValues.contractDetails.otherBusinessLines.toString()
				: null,
			settlementAgreement: contractValues.contractDetails.settlementAgreement
				? contractValues.contractDetails.settlementAgreement
				: null,
			bondType: contractValues.contractDetails.bondType
				? contractValues.contractDetails.bondType
				: null,
			depositConsolidation: contractValues.contractDetails.depositConsolidation
				? contractValues.contractDetails.depositConsolidation
				: null,
			vatRate:
				typeof contractValues.contractDetails.vatRate === 'string'
					? Number(contractValues.contractDetails.vatRate?.replace('%'))
					: 12,
			vatType: contractValues.contractDetails.vatType
				? contractValues.contractDetails.vatType
				: null,
			channelAccreditation: {
				birCertReg:
					contractValues.contractDetails.channelAccreditation.birCertReg ||
					null,
				corpSecCert:
					contractValues.contractDetails.channelAccreditation.corpSecCert ||
					null,
				businessPermit:
					contractValues.contractDetails.channelAccreditation.businessPermit ||
					null,
				companyProfile:
					contractValues.contractDetails.channelAccreditation.companyProfile ||
					null,
				govtIds:
					contractValues.contractDetails.channelAccreditation.govtIds || null,
				genInfoSheets:
					contractValues.contractDetails.channelAccreditation.genInfoSheets ||
					null,
				nonDisclosure:
					contractValues.contractDetails.channelAccreditation.nonDisclosure ||
					null,
				secRegCert:
					contractValues.contractDetails.channelAccreditation.secRegCert ||
					null,
				secArticleIncorp:
					contractValues.contractDetails.channelAccreditation
						.secArticleIncorp || null,
				certAuthority:
					contractValues.contractDetails.channelAccreditation.certAuthority ||
					null,
				signedNda:
					contractValues.contractDetails.channelAccreditation.signedNda || null,
				auditFinancialStmt:
					contractValues.contractDetails.channelAccreditation
						.auditFinancialStmt || null,
				incomeTaxReturn:
					contractValues.contractDetails.channelAccreditation.incomeTaxReturn ||
					null,
				cbciAmlQuestion:
					contractValues.contractDetails.channelAccreditation.cbciAmlQuestion ||
					null,
				secondAuditFinStmt:
					contractValues.contractDetails.channelAccreditation
						.secondAuditFinStmt || null,
				dataPrivacyPia:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPia ||
					null,
				dataPrivacyPat:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPat ||
					null,
				thirdPartySecChecklist:
					contractValues.contractDetails.channelAccreditation
						.thirdPartySecChecklist || null,
			},
		},
		channelReports: {
			// ...reportsValues,
			sftp: {
				...reportsValues?.sftp,
				csrConfig: {
					...reportsValues?.sftp?.csrConfig,
					csrColumns:
						reportsValues?.sftp?.csrConfig?.csrColumns?.length > 0
							? transformTP(reportsValues?.sftp)
							: [],
				},
			},
			smtp: {
				...reportsValues?.smtp,
				csrConfig: {
					...reportsValues?.smtp?.csrConfig,
					csrColumns:
						reportsValues?.smtp?.csrConfig?.csrColumns?.length > 0
							? transformTP(reportsValues?.smtp)
							: [],
				},
			},
		},
	};
	return payload;
};

const transformTP = (value) => {
	return value.csrConfig?.csrColumns?.map((value) => {
		if (typeof value === 'number') {
			return value;
		} else if (typeof value === 'object' && value.id !== undefined) {
			return value.id;
		} else {
			return null; // Handle other cases if needed
		}
	});
};

const replaceUndefinedAndEmptyToNull = (obj) => {
	Object.keys(obj).forEach((key) => {
		if (obj[key] === undefined || obj[key] === '') {
			obj[key] = null;
		}
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			replaceUndefinedAndEmptyToNull(obj[key]);
		}
	});
};

export const formatDraftPayload = (index, forms) => {
	const { primaryForm, contractForm, reportsForm } = forms;
	const primaryInfoValues = primaryForm.getValues();
	const contractValues = contractForm.getValues();
	const reportsValues = structuredClone(reportsForm.getValues());

	const transformCashCheckCollection = (data) => ({
		acctName: data.acctName || null,
		acctNo: data.acctNo || null,
		acctType: data.acctType || null,
		bankBranch: data.bankBranch || null,
		bankId: data.bankId || null,
		id: data.id || null,
	});

	const transformContactDetails = (data) => ({
		name: data.name || null,
		department: data.department || null,
		emailAddress: data.emailAddress
			? data.emailAddress?.length > 0
				? data.emailAddress
				: null
			: null,
		mobileNo: data.mobileNo
			? data.mobileNo?.length > 0
				? data.mobileNo
				: null
			: null,
		position: data.position || null,
		telNo: data.telNo || null,
		id: data.id || null,
	});

	const cashCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
		id: null,
	};

	const checkCollectionDefault = {
		acctName: null,
		acctNo: null,
		acctType: null,
		bankBranch: null,
		bankId: null,
		id: null,
	};

	const contactDetailsDefault = [
		{
			deparment: null,
			emailAddress: null,
			mobileNo: null,
			position: null,
			telNo: null,
			id: null,
		},
	];

	const cashCollection =
		contractValues.contractDetails.channelCashCollection.map(
			transformCashCheckCollection
		);
	const contactDetails =
		primaryInfoValues.primaryInfo.channelContactDetails.map(
			transformContactDetails
		);
	const checkCollection =
		contractValues.contractDetails.channelCheckCollection.map(
			transformCashCheckCollection
		);

	replaceUndefinedAndEmptyToNull(reportsValues);

	const payload = {
		channelPrimaryInfo: {
			...primaryInfoValues.primaryInfo,
			settlementSetup: primaryInfoValues
				? primaryInfoValues.primaryInfo.settlementSetup
				: null,
			channelTransactionType: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelTransactionType
				: null,
			logo: primaryInfoValues.primaryInfo.logo
				? primaryInfoValues.primaryInfo.logo
				: {
						objectKey: null,
				  },
			partnershipType: primaryInfoValues
				? primaryInfoValues.primaryInfo.partnershipType
				: null,
			businessType: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessType
				: null,
			businessGroup: primaryInfoValues
				? primaryInfoValues.primaryInfo.businessGroup
				: null,
			posType: primaryInfoValues.primaryInfo.posType
				? primaryInfoValues.primaryInfo.posType
				: null,
			channelAddresses: primaryInfoValues
				? primaryInfoValues.primaryInfo.channelAddresses
				: [
						{
							locationBase: null,
							area: null,
						},
				  ],
			channelContactDetails: primaryInfoValues.primaryInfo.channelContactDetails
				? contactDetails
				: contactDetailsDefault,
			channelOfficer: {
				emailAddress:
					primaryInfoValues.primaryInfo.channelOfficer.emailAddress?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.emailAddress
						: null,
				mobileNo:
					primaryInfoValues.primaryInfo.channelOfficer.mobileNo?.length > 0
						? primaryInfoValues.primaryInfo.channelOfficer.mobileNo
						: null,
				name: primaryInfoValues.primaryInfo.channelOfficer.name || null,
				telNo: primaryInfoValues.primaryInfo.channelOfficer.telNo || null,
			},
		},
		channelContractDetails: {
			...contractValues.contractDetails,
			bondAmount:
				typeof contractValues.contractDetails.bondAmount === 'number' ||
				(typeof contractValues.contractDetails.bondAmount === 'string' &&
					contractValues.contractDetails.bondAmount.trim() !== '' &&
					contractValues.contractDetails.bondAmount !== null)
					? parseFloat(contractValues.contractDetails.bondAmount).toFixed(2)
					: null,
			thresholdAmount:
				typeof contractValues.contractDetails.thresholdAmount === 'number' ||
				(typeof contractValues.contractDetails.thresholdAmount === 'string' &&
					contractValues.contractDetails.thresholdAmount.trim() !== '' &&
					contractValues.contractDetails.thresholdAmount !== null)
					? parseFloat(contractValues.contractDetails.thresholdAmount).toFixed(
							2
					  )
					: null,
			accreditationFee:
				typeof contractValues.contractDetails.accreditationFee === 'number' ||
				(typeof contractValues.contractDetails.accreditationFee === 'string' &&
					contractValues.contractDetails.accreditationFee.trim() !== '' &&
					contractValues.contractDetails.accreditationFee !== null)
					? parseFloat(contractValues.contractDetails.accreditationFee).toFixed(
							2
					  )
					: null,
			annualFee:
				typeof contractValues.contractDetails.annualFee === 'number' ||
				(typeof contractValues.contractDetails.annualFee === 'string' &&
					contractValues.contractDetails.annualFee.trim() !== '' &&
					contractValues.contractDetails.annualFee !== null)
					? parseFloat(contractValues.contractDetails.annualFee).toFixed(2)
					: null,
			bondStartDate: contractValues.contractDetails.bondStartDate
				? moment(contractValues.contractDetails.bondStartDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			bondEndDate: contractValues.contractDetails.bondEndDate
				? moment(contractValues.contractDetails.bondEndDate).format(
						'YYYY-MM-DD'
				  )
				: null,
			channelCashCollection: contractValues.contractDetails
				.channelCashCollection
				? cashCollection
				: cashCollectionDefault,
			channelCheckCollection: contractValues.contractDetails
				.channelCheckCollection
				? checkCollection
				: checkCollectionDefault,
			channelTermsDuration: contractValues.contractDetails.channelTermsDuration
				? {
						channelId:
							contractValues.contractDetails.channelTermsDuration.channelId,
						executionDate: contractValues.contractDetails.channelTermsDuration
							.executionDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.executionDate
							  ).format('YYYY-MM-DD')
							: null,
						autoRenew:
							contractValues.contractDetails.channelTermsDuration.autoRenew ||
							null,
						renewalFrequency:
							contractValues.contractDetails.channelTermsDuration
								.renewalFrequency || null,
						terminationDate: contractValues.contractDetails.channelTermsDuration
							.terminationDate
							? moment(
									contractValues.contractDetails.channelTermsDuration
										.terminationDate
							  ).format('YYYY-MM-DD')
							: null,
						frequencyInterval:
							contractValues.contractDetails.channelTermsDuration
								.frequencyInterval || null,
				  }
				: {
						channelId: null,
						executionDate: null,
						autoRenew: null,
						renewalFrequency: null,
						terminationDate: null,
						frequencyInterval: null,
				  },
			otherBusinessLines: contractValues.contractDetails.otherBusinessLines
				? contractValues.contractDetails.otherBusinessLines.toString()
				: null,
			settlementAgreement: contractValues.contractDetails.settlementAgreement
				? contractValues.contractDetails.settlementAgreement
				: null,
			bondType: contractValues.contractDetails.bondType
				? contractValues.contractDetails.bondType
				: null,
			depositConsolidation: contractValues.contractDetails.depositConsolidation
				? contractValues.contractDetails.depositConsolidation
				: null,
			vatRate:
				typeof contractValues.contractDetails.vatRate === 'string'
					? Number(contractValues.contractDetails.vatRate?.replace('%'))
					: 12,
			vatType: contractValues.contractDetails.vatType
				? contractValues.contractDetails.vatType
				: null,
			channelAccreditation: {
				birCertReg:
					contractValues.contractDetails.channelAccreditation.birCertReg ||
					null,
				corpSecCert:
					contractValues.contractDetails.channelAccreditation.corpSecCert ||
					null,
				businessPermit:
					contractValues.contractDetails.channelAccreditation.businessPermit ||
					null,
				companyProfile:
					contractValues.contractDetails.channelAccreditation.companyProfile ||
					null,
				govtIds:
					contractValues.contractDetails.channelAccreditation.govtIds || null,
				genInfoSheets:
					contractValues.contractDetails.channelAccreditation.genInfoSheets ||
					null,
				nonDisclosure:
					contractValues.contractDetails.channelAccreditation.nonDisclosure ||
					null,
				secRegCert:
					contractValues.contractDetails.channelAccreditation.secRegCert ||
					null,
				secArticleIncorp:
					contractValues.contractDetails.channelAccreditation
						.secArticleIncorp || null,
				certAuthority:
					contractValues.contractDetails.channelAccreditation.certAuthority ||
					null,
				signedNda:
					contractValues.contractDetails.channelAccreditation.signedNda || null,
				auditFinancialStmt:
					contractValues.contractDetails.channelAccreditation
						.auditFinancialStmt || null,
				incomeTaxReturn:
					contractValues.contractDetails.channelAccreditation.incomeTaxReturn ||
					null,
				cbciAmlQuestion:
					contractValues.contractDetails.channelAccreditation.cbciAmlQuestion ||
					null,
				secondAuditFinStmt:
					contractValues.contractDetails.channelAccreditation
						.secondAuditFinStmt || null,
				dataPrivacyPia:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPia ||
					null,
				dataPrivacyPat:
					contractValues.contractDetails.channelAccreditation.dataPrivacyPat ||
					null,
				thirdPartySecChecklist:
					contractValues.contractDetails.channelAccreditation
						.thirdPartySecChecklist || null,
			},
		},
		channelReports: {
			...reportsValues,
		},
	};

	return payload;
};

export const formatActiveType = (activeType: string) => {
	switch (activeType) {
		case '':
			return 'Channel';

		case 'channel':
			return 'Branch';

		case 'branch':
		case 'terminal':
			return 'Terminal';

		default:
			break;
	}
};
